import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import React, { useEffect, useMemo, useState } from "react";
import { doubleArrowIcon } from "../../images";
import Button from "@speed/common/src/components/Button/Button";
import {
  failed,
  failedSendErrorCodes,
  insufficientFunds,
  lightning,
  min1satRequired,
  minThousandsatRequired,
  onchain,
  paid,
  sats,
  sendViaMethod,
  unpaid,
  lnurl,
  minRequiredSat,
  receiveViaLnUrl,
  receive,
  sendMax,
  sendWithHighFee,
  sendText,
  maximumAmountAllowed,
  ethereum,
  usdt,
  min1usdtRequired,
  send,
  minBtcRequired,
} from "../../messages";
import {
  btcToSatsAmount,
  fetchRawBalanceData,
  getDisplayAmount,
  satsToBtcAmount,
} from "@speed/common/src/components/constants";
import {
  callAPIInterface,
  commonCustomDivider,
  displaySelectedCurrencyAmount,
  generateAmountToPass,
  getCurrencyAmount,
  handleAmountChangeCommon,
  makeBoldString,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import history from "@speed/common/src/components/history";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { app } from "@speed/common/src/util/firebase";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getAccountFirebaseToken } from "../../../redux/auth/actions";
import {
  setShowBTCAmount,
  setWalletSendModalClose,
  showToast,
} from "../../../redux/common/actions";
import Truncate from "react-truncate-inside/es";
import PaymentProcess from "./PaymentProcess";
import PaymentFailed from "./PaymentFailed";
import axios from "axios";
import AmountBox from "./AmountBox";
import SendTotalAmount from "./SendTotalAmount";
import isEmail from "validator/lib/isEmail";
import AccountFees from "../ReceivePayment/AccountFees";
import InsufficientBalanceErrorSection from "./InsufficientBalanceErrorSection";
import RecaptchaModal from "./RecaptchaModal";
import { sessionService } from "redux-react-session";
import { currency } from "@speed/common/src/components/currency";

let unsubscribe, timeoutId;
const SendPayment = (props) => {
  const isWalletSendModalClose = useSelector(
    (state) => state.common.isWalletSendModalClose
  );
  const currentAccount = useSelector((state) => state.auth.currentAccount);
  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);
  const dispatch = useDispatch();

  const [isNetworkFeeLoading, setIsNetworkFeeLoading] = useState(false);
  const [networkFeeAmount, setNetworkFeeAmount] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [withdrawStatus, setWithdrawStatus] = useState("");
  const [withdrawFirebaseData, setWithdrawFirebaseData] = useState({});
  const [switchCurrency, setSwitchCurrency] = useState(false);
  const [note, setNote] = useState("");
  const [isLargeAmount, setIsLargeAmount] = useState(false);
  const [sendMaxAmount, setSendMaxAmount] = useState(null);
  const [speedFees, setSpeedFees] = useState(0);
  const [calculatedSpeedFee, setCalculatedSpeedFee] = useState(0);
  const [isSendWithHighFeeVisible, setIsSendWithHighFeeVisible] =
    useState(false);
  const [openCaptchaModal, setOpenCaptchaModal] = useState(false);
  const [balance, setBalance] = useState(null);

  const {
    decodeResponse,
    amount,
    setAmount,
    exchangeRateLoading,
    fiatAmount,
    setFiatAmount,
    handleModalClose,
    exchangeRate,
    isPaymentFailed,
    isPaymentProcessing,
    setIsPaymentFailed,
    setIsPaymentProcessing,
    setReceiveWithdrawError,
    setErrorMsg,
    errorMsg,
    noteFromURL,
    decodedCurrency,
  } = props;

  const isSats = decodedCurrency === sats;
  const isReceiveWithdrawReq = decodeResponse?.tag === "withdrawRequest";
  const lnUrlMinAmount = isReceiveWithdrawReq
    ? displaySelectedCurrencyAmount(
        decodeResponse?.min_withdrawable,
        showBtcAmount
      )
    : displaySelectedCurrencyAmount(
        decodeResponse?.min_sendable,
        showBtcAmount
      );
  const lnUrlMaxAmount = isReceiveWithdrawReq
    ? displaySelectedCurrencyAmount(
        decodeResponse?.max_withdrawable,
        showBtcAmount
      )
    : displaySelectedCurrencyAmount(
        decodeResponse?.max_sendable,
        showBtcAmount
      );

  const checkMethod = (type) => type === decodeResponse?.withdraw_method;
  const isLightningMethod = checkMethod(lightning);
  const isLnUrlMethod = checkMethod(lnurl);
  const isOnchainMethod = checkMethod(onchain);
  const isEthereumMethod = checkMethod(ethereum.toLowerCase());
  const fiatCurrency = JSON.parse(
    localStorage.getItem("wallet_default_currency")
  );
  const isAmountEditable =
    (isOnchainMethod && decodedCurrency !== usdt) ||
    (isLightningMethod && decodeResponse?.amount === 0) ||
    (isLnUrlMethod && lnUrlMinAmount !== lnUrlMaxAmount) ||
    isEthereumMethod;
  const checkForLightningLn =
    isLightningMethod || (isLnUrlMethod && !isReceiveWithdrawReq);
  const showSendMaxButtonVisible =
    (isSats && isLightningMethod && decodeResponse?.amount === 0) ||
    (isLnUrlMethod && isEmail(decodeResponse?.payee)) ||
    !(
      decodedCurrency === usdt &&
      decodeResponse?.amount === 0 &&
      isLightningMethod
    );
  const toFixedDecimal = showBtcAmount ? 8 : 2;

  useEffect(() => {
    if (showBtcAmount && decodedCurrency !== sats) {
      dispatch(setShowBTCAmount(false));
    }
  }, []);

  useEffect(() => {
    if (isWalletSendModalClose) {
      setIsPaymentProcessing(false);
      setWithdrawStatus("");
      setIsPaymentFailed(false);
      setSwitchCurrency(false);
      setWithdrawFirebaseData({});
      setErrorMsg("");
      dispatch(setWalletSendModalClose(false));
      unsubscribe?.();
    }
  }, [isWalletSendModalClose]);

  useEffect(() => {
    if (withdrawStatus === unpaid) {
      timeoutId = setTimeout(() => {
        unsubscribe();
        setIsPaymentProcessing(true);
        setIsSubmitting(false);
      }, 30000);
    } else if (withdrawStatus === paid.toLowerCase()) {
      unsubscribe();
      clearTimeout(timeoutId);
      setIsSubmitting(false);

      history.push({
        pathname: "/success-page",
        data: { paymentData: withdrawFirebaseData },
      });
    } else if (withdrawStatus === failed.toLowerCase()) {
      unsubscribe();
      clearTimeout(timeoutId);

      const reason =
        failedSendErrorCodes[withdrawFirebaseData?.failure_reason] ||
        withdrawFirebaseData?.failure_reason;

      setErrorMsg(reason);
      setIsSubmitting(false);
      setIsPaymentFailed(true);
    }
  }, [withdrawStatus]);

  useEffect(() => {
    const amt = Number(amount);
    const timer = setTimeout(() => {
      const checkVal = amt <= balance;
      let lightningOnchainValidAmount = false;
      let tapValidAmount = false;
      if (isOnchainMethod) {
        const minRequiredAmount = displaySelectedCurrencyAmount(
          1000,
          showBtcAmount
        );
        lightningOnchainValidAmount =
          isSats && amt >= minRequiredAmount && checkVal;
        tapValidAmount = !isSats && amt > 0 && checkVal;
      } else if (checkForLightningLn) {
        lightningOnchainValidAmount = isSats && amt > 0 && checkVal;
      } else if (isEthereumMethod) {
        tapValidAmount = !isSats && amt > 0 && checkVal;
      }
      if (lightningOnchainValidAmount || tapValidAmount) {
        getEstimateFees(amt);
        if (speedFees && amount) {
          const calculateFee = (amount * speedFees) / 100;

          setCalculatedSpeedFee(
            currency === sats && !showBtcAmount
              ? Math.floor(calculateFee)
              : calculateFee?.toFixed(toFixedDecimal)
          );
        }
      } else {
        setNetworkFeeAmount(null);
        setIsNetworkFeeLoading(false);
        setCalculatedSpeedFee(0);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [amount, balance, speedFees]);

  useEffect(() => {
    exchangeRate > 0 && getBalanceApi();
  }, [exchangeRate]);

  useEffect(() => {
    !isOnchainMethod && !isEthereumMethod && fetchSendMaxAmount();
    fetchAccountFees();
  }, []);

  const getPaymentMethod = () => {
    if (isLightningMethod || isLnUrlMethod) {
      return lightning;
    } else if (isOnchainMethod) {
      return onchain;
    } else if (isEthereumMethod) {
      return ethereum.toLowerCase();
    }
  };

  const fetchAccountFees = async () => {
    try {
      const params = `?payment_method=${getPaymentMethod()}&transaction_code=${
        isReceiveWithdrawReq ? "01" : "04"
      }&target_currency=${decodedCurrency}`;

      const accountFeesRes = await callAPIInterface(
        "GET",
        `/account-fees` + params
      );
      setSpeedFees(accountFeesRes?.fee_percentage);
    } catch (_err) {}
  };

  const fetchSendMaxAmount = async () => {
    try {
      const response = await callAPIInterface(
        "GET",
        "/withdraws/lightning-max-amount"
      );
      setSendMaxAmount(
        getCurrencyAmount(response?.amount, showBtcAmount, decodedCurrency)
      );
    } catch (_err) {}
  };

  const getEstimateFees = (value) => {
    if (value > 0) {
      const data = {
        address_with_amount: {
          [decodeResponse?.withdraw_request]: generateAmountToPass(
            value,
            decodedCurrency,
            showBtcAmount
          ),
        },
        target_confirmation: 3,
      };
      setIsNetworkFeeLoading(true);
      callAPIInterface("POST", "/withdraws/estimate", data)
        .then((res) => {
          setNetworkFeeAmount(
            getCurrencyAmount(res?.fee, showBtcAmount, decodedCurrency)
          );
          setIsNetworkFeeLoading(false);
        })
        .catch(() => {
          setIsNetworkFeeLoading(false);
        });
    } else {
      setIsNetworkFeeLoading(false);
    }
  };

  const getBalanceApi = () => {
    callAPIInterface("GET", "/balances")
      .then((resData) => {
        if (resData) {
          const data = fetchRawBalanceData(resData);
          const targetedBalance = data?.find(
            (bal) => bal.target_currency === decodedCurrency
          );
          setBalance(
            getCurrencyAmount(
              targetedBalance?.amount,
              showBtcAmount,
              decodedCurrency
            ) || 0
          );
        }
      })
      .catch(() => {});
  };

  const getSendPaymentFirestoreData = async (withdrawId) => {
    const db = getFirestore(app);
    const accRef = doc(
      db,
      "account",
      currentAccount?.account?.id,
      "withdraws",
      withdrawId
    );
    try {
      unsubscribe = onSnapshot(accRef, async (querySnapshot) => {
        const data = querySnapshot.data();
        setWithdrawFirebaseData(data);
        setWithdrawStatus(data?.status?.toLowerCase());
      });
    } catch (_err) {}
  };

  const handleWithdraw = () => {
    const withdrawData = {
      amount: generateAmountToPass(amount, decodedCurrency, showBtcAmount),
      currency: decodedCurrency,
      withdraw_request: decodeResponse?.withdraw_request,
      target_currency: decodedCurrency,
      withdraw_method:
        decodeResponse?.withdraw_method === lnurl
          ? lightning
          : decodeResponse?.withdraw_method,
    };
    note && (withdrawData.note = note);

    callAPIInterface("POST", "/send", JSON.stringify(withdrawData))
      .then(async (res) => {
        if (res?.status === paid.toLowerCase()) {
          const data = {
            statement_descriptor: res?.statement_descriptor,
            target_currency: res?.target_currency,
            target_amount_paid: res?.target_amount,
          };
          history.push({
            pathname: "/success-page",
            data: { paymentData: data },
          });
        } else if (res?.status === failed.toLowerCase()) {
          const reason =
            failedSendErrorCodes[res?.failure_reason] || res?.failure_reason;

          setErrorMsg(reason);
          setIsSubmitting(false);
          setIsPaymentFailed(true);
        } else {
          const token = await getAccountFirebaseToken();
          const authObj = getAuth(app);
          token?.firebase_token &&
            signInWithCustomToken(authObj, token.firebase_token)
              .then(() => {
                getSendPaymentFirestoreData(res?.withdraw_id);
              })
              .catch(() => {});
        }
      })
      .catch((e) => {
        dispatch(
          showToast({
            isToastOpen: false,
            toastVariant: "error",
          })
        );
        const msg = e?.response?.data?.errors?.[0]?.message || "";

        setIsPaymentFailed(true);
        setIsSubmitting(false);
        setErrorMsg(msg);
      });
  };

  const handleSendPayment = () => {
    setIsSubmitting(true);
    handleWithdraw();
  };

  const handleAnomaly = async () => {
    try {
      const currentTime = new Date().getTime();
      const session = await sessionService.loadSession();
      if (session?.withdraw_processed) {
        const withdrawProcessedCount = session?.withdraw_processed;
        const prevTime = +session?.last_withdraw_processed_time;
        const elapsedTime = currentTime - prevTime;
        if (elapsedTime < 60000 && withdrawProcessedCount >= 5) {
          setOpenCaptchaModal(true);
        } else if (elapsedTime > 60000) {
          await sessionService.saveSession({
            ...session,
            withdraw_processed: 1,
            last_withdraw_processed_time: currentTime,
          });
          handleSendPayment();
        } else {
          await sessionService.saveSession({
            ...session,
            withdraw_processed: +withdrawProcessedCount + 1,
          });
          handleSendPayment();
        }
      } else {
        await sessionService.saveSession({
          ...session,
          withdraw_processed: 1,
          last_withdraw_processed_time: currentTime,
        });
        handleSendPayment();
      }
    } catch (_e) {
      throw new Error(_e);
    }
  };

  const handleSubmit = async () => {
    if (isReceiveWithdrawReq) {
      setIsSubmitting(true);
      getPaymentInvocie();
    } else {
      try {
        await handleAnomaly();
      } catch (_e) {}
    }
  };

  const handleProcessWithdrawRequest = async (pr) => {
    try {
      let url = decodeResponse?.callback
        ? new URL(decodeResponse.callback)
        : "";

      if (url) {
        url.searchParams.set("k1", decodeResponse?.k1);
        url.searchParams.set("pr", pr);
      }

      const res = await axios.get(url);
      if (res?.data?.status === "OK") {
        const data = {
          target_amount_paid: showBtcAmount ? btcToSatsAmount(amount) : amount,
          target_currency: decodedCurrency,
        };
        note && (data.statement_descriptor = note);

        history.push({
          pathname: "/success-page",
          data: { paymentData: data, fromReceived: true },
        });
      } else if (res?.data?.status?.toLowerCase() === "error" && res?.data) {
        setReceiveWithdrawError(res.data);
      }
    } catch (_err) {}
    setIsSubmitting(false);
  };

  const getPaymentInvocie = async () => {
    setIsSubmitting(true);
    const data = {
      amount: generateAmountToPass(amount, currency, showBtcAmount),
      currency: decodedCurrency,
      ttl: 600,
      target_currency: decodedCurrency,
      payment_methods: ["lightning"],
    };

    note && (data.statement_descriptor = note);
    try {
      const response = await callAPIInterface("POST", "/payments", data);
      const pr = response?.payment_method_options?.lightning?.payment_request;
      await handleProcessWithdrawRequest(pr);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  const handleAmountChange = (amount, switchCurrency) => {
    handleAmountChangeCommon(
      amount,
      switchCurrency,
      exchangeRate,
      currency,
      setAmount,
      setFiatAmount,
      showBtcAmount
    );
  };

  const getTextOfButton = useMemo(() => {
    const numAmount = Number(amount);
    const networkFee = Number(networkFeeAmount);
    const numCalculatedSpeedFee = Number(calculatedSpeedFee);
    const numTotal = numAmount + networkFee + numCalculatedSpeedFee;
    const insufficientAmount = numAmount > balance;
    const checkTotalAmount = numTotal > balance;

    let btnLabel = sendText,
      btnDisable = false,
      isInsufficientBalance = false;

    if (balance || balance === 0) {
      if (checkTotalAmount && !isReceiveWithdrawReq) {
        setIsSendWithHighFeeVisible(false);
        btnLabel = insufficientFunds;
        btnDisable = true;
        isInsufficientBalance = true;
      } else if (
        (decodedCurrency === usdt && isOnchainMethod) ||
        isEthereumMethod
      ) {
        if (!numAmount || numAmount <= 0) {
          btnLabel = min1usdtRequired;
          btnDisable = true;
        } else {
          btnLabel = insufficientAmount ? insufficientFunds : sendText;
          btnDisable = insufficientAmount;
          isInsufficientBalance = insufficientAmount;
        }
      } else if (isLightningMethod) {
        if (showBtcAmount) {
          const minAmountRequired = satsToBtcAmount(1);
          btnLabel =
            numAmount < minAmountRequired
              ? minBtcRequired(minAmountRequired)
              : sendText;
          btnDisable = numAmount < minAmountRequired;
        } else {
          btnLabel = numAmount < 1 ? min1satRequired : sendText;
          btnDisable = numAmount < 1;
        }
      } else if (isOnchainMethod) {
        const calculateFees = (numAmount * 9) / 100;
        if (showBtcAmount) {
          const minRequiredAmount = satsToBtcAmount(1000);
          btnLabel =
            numAmount < minRequiredAmount
              ? minBtcRequired(satsToBtcAmount(1000))
              : send;
          btnDisable =
            !numAmount || numAmount === 0 || numAmount < minRequiredAmount;
        } else if (!numAmount || numAmount === 0 || numAmount < 1000) {
          btnLabel = minThousandsatRequired;
          btnDisable = true;
          setIsSendWithHighFeeVisible(false);
        } else if (networkFee > calculateFees) {
          btnLabel = sendWithHighFee;
          setIsSendWithHighFeeVisible(true);
        } else {
          btnLabel = sendText;
          setIsSendWithHighFeeVisible(false);
        }
      } else if (isLnUrlMethod && isSats) {
        if (numAmount > lnUrlMaxAmount) {
          btnLabel = maximumAmountAllowed(lnUrlMaxAmount, showBtcAmount);
          btnDisable = true;
        } else if (numAmount < lnUrlMinAmount) {
          btnLabel = minRequiredSat(lnUrlMinAmount, showBtcAmount);
          btnDisable = true;
        } else {
          btnLabel = isReceiveWithdrawReq ? receive : sendText;
        }
      }
      return {
        btnLabel,
        btnDisable,
        isInsufficientBalance,
      };
    }
  }, [amount, networkFeeAmount, balance, sendMaxAmount, calculatedSpeedFee]);

  const displayAmount = (showFiat) => {
    return getDisplayAmount(
      showFiat,
      showFiat ? fiatAmount : amount,
      showFiat ? fiatCurrency?.code : decodedCurrency,
      showBtcAmount
    );
  };

  const handleSendMaxAmount = () => {
    handleAmountChange(sendMaxAmount?.toString(), false);
  };

  if (isPaymentFailed) {
    return (
      <PaymentFailed handleModalClose={handleModalClose} errorMsg={errorMsg} />
    );
  }

  if (isPaymentProcessing) {
    return <PaymentProcess handleModalClose={handleModalClose} />;
  }

  const commonProps = {
    switchCurrency,
    amount,
  };

  const amountBoxProps = {
    ...commonProps,
    currency: decodedCurrency,
    setSwitchCurrency,
    setFiatAmount,
    setAmount,
    fiatAmount,
    note,
    setNote,
    exchangeRateLoading,
    exchangeRate,
    displayAmount,
    setIsLargeAmount,
    isLargeAmount,
    isAmountEditable,
  };

  const commonPropsSendAndInsufficient = {
    assetBalance: balance,
    isOnchainMethod,
    exchangeRate,
    calculatedSpeedFee,
    networkFeeAmount,
    decodedCurrency,
  };

  const sendTotalAmountProps = {
    ...commonProps,
    ...commonPropsSendAndInsufficient,
    isNetworkFeeLoading,
    lnUrlMaxAmount,
    lnUrlMinAmount,
    isReceiveWithdrawReq,
    speedFees,
    isEthereumMethod,
    isLightningMethod,
  };

  const insufficientBalanceProps = {
    ...commonProps,
    ...commonPropsSendAndInsufficient,
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        justifyContent={showSendMaxButtonVisible ? "space-between" : "center"}
        className="enter-amount-heading"
      >
        <Text size={20} font="semibold" className="default-text" variant="h6">
          {isReceiveWithdrawReq
            ? receiveViaLnUrl
            : sendViaMethod(
                decodeResponse?.withdraw_method,
                decodeResponse?.withdraw_request
              )}
        </Text>
        {showSendMaxButtonVisible && (
          <Text
            size={16}
            font="semibold"
            sx={{ color: "#2A67FF", cursor: "pointer" }}
            onClick={handleSendMaxAmount}
          >
            {sendMax}
          </Text>
        )}
      </Box>
      {getTextOfButton?.isInsufficientBalance && (
        <InsufficientBalanceErrorSection {...insufficientBalanceProps} />
      )}
      <AmountBox {...amountBoxProps} />
      <Box className="send-payment-request-section">
        {(noteFromURL || decodeResponse?.description) && (
          <Text
            size={16}
            font="regular"
            className="default-text"
            variant="h6"
            sx={{ textAlign: "center", wordBreak: "break-all" }}
          >
            {noteFromURL?.replace(/\+/g, " ") || decodeResponse?.description}
          </Text>
        )}
        <Box className="address-truncate" mb={isReceiveWithdrawReq && "94px"}>
          <img
            style={{ marginTop: "24px", marginBottom: "24px" }}
            src={doubleArrowIcon}
            alt="double-arrow-icon"
          />
          <Truncate
            text={
              decodeResponse?.withdraw_request?.lenght > 40
                ? makeBoldString(decodeResponse?.withdraw_request, 20)
                : decodeResponse?.withdraw_request
            }
            width="380"
            offset={19}
          />
          {isReceiveWithdrawReq && (
            <AccountFees
              accountFees={speedFees}
              isLoading={exchangeRateLoading}
            />
          )}
        </Box>
      </Box>
      <SendTotalAmount {...sendTotalAmountProps} />
      {commonCustomDivider()}
      <Button
        label={getTextOfButton?.btnLabel || sendText}
        onClick={handleSubmit}
        type="submit"
        loading={isSubmitting}
        disabled={getTextOfButton?.btnDisable || isNetworkFeeLoading}
        className={`send-amt-btn ${
          isSendWithHighFeeVisible && "send-amt-btn-red"
        }`}
      />
      {openCaptchaModal && (
        <RecaptchaModal
          openCaptchaModal={openCaptchaModal}
          setOpenCaptchaModal={setOpenCaptchaModal}
        />
      )}
    </Box>
  );
};

export default SendPayment;
