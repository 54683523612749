import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TableCell,
  TableRow,
  Skeleton,
} from "@mui/material";
import {
  clipboardElement,
  getCurrencyObj,
  htmlDecode,
  linkStatus,
  showAmount,
} from "@speed/common/src/components/constants";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsDataAvailable, setIsModalOpen } from "../../redux/common/actions";
import {
  activateDeactivateCheckoutLink,
  activateDeactivatePaymentLink,
  activateDeactivateSession,
  callAPIInterface,
  dateTimeFormatInApp,
  noOfRecords,
} from "../constants";
import { activate, customerChoose, deActivate } from "../messages";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import classNames from "classnames";
import CustomTable from "@speed/common/src/components/Table/Table";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import HorizontalSearchPageTable from "./HorizontalSearchPageTable";
import NoDataAvailable from "@speed/common/src/components/NoDataAvailable";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";

const PaymentAndCheckoutLinkTable = ({
  tableHeader,
  linkPath,
  moduleName,
  tab,
  showHorizontalTable = false,
  searchQueryUrl,
  inputString,
  searchResultData,
  getModuleSearchResultData,
  totalNumOfSearchResults,
  textOnNoData,
  subTextOnNoData,
  noDataImage,
  createButtonText,
}) => {
  const queryParam = "?limit=" + noOfRecords;
  const [currentRow, setCurrentRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [rows, setRows] = useState([]);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [onDeactivateRows, setOnDeactivateRows] = useState([]);
  const [rowId, setRowId] = useState(null);
  const [rowLoader, setRowLoader] = useState(false);
  const [columns, setColumns] = useState(tableHeader); // tem
  const isDataAvailable = useSelector((state) => state.common.isDataAvailable);
  const [loadMoreId, setLoadMoreId] = useState("");

  const dispatch = useDispatch();

  const liveMode = useSelector((state) => state.auth.liveMode);
  const { history, caughtErrorInStrapi } = useSelector((state) => state.common);

  const openPopper = Boolean(anchorEl);

  const fromSearchPage = history.location.pathname === "/search";
  const fromSessionPage = linkPath === "checkout-sessions";
  const fromCLPage = linkPath === "checkout-links";
  const fromPLPage = linkPath === "payment-links";

  useEffect(() => {
    setRows([]);
    setHasMore(true);
    getLinksData([], queryParam);
  }, [liveMode]);

  useEffect(() => {
    !fromSearchPage && setRows(onDeactivateRows);
  }, [onDeactivateRows]);

  useEffect(() => {
    //change condition when active action is available
    if (tab !== "active" && tab !== undefined) {
      const newColumns = columns.filter((column) => column.title !== "Actions");
      setColumns(newColumns);
    } else {
      setColumns(tableHeader);
    }
  }, [tab]);

  const loadMore = useCallback(() => {
    getLinksData(rows, queryParams);
  }, [rows]);

  const handleClick = (event, rowData) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setCurrentRow(rowData);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick(e, currentRow);
    }
  };

  const handleDeactivateAction = (res) => {
    setAnchorEl(null);
    rows.map((row) => {
      if (row.id === currentRow?.id) {
        row.status = res.status;
      }
    });
    const result =
      tab === "active" ? rows.filter((row) => row.id !== currentRow?.id) : rows; // filter out row when deactivating link from active tab
    setOnDeactivateRows(result);
    setRowLoader(false);
  };

  const handleDeactivateActionError = () => {
    setRowLoader(false);
  };
  const handleActiveDeActive = () => {
    if (currentRow?.status === "active") {
      setRowId(currentRow?.id);
      setRowLoader(true);
      let deactivateApiMethod;
      if (fromPLPage) deactivateApiMethod = activateDeactivatePaymentLink;
      else if (fromSessionPage) deactivateApiMethod = activateDeactivateSession;
      else deactivateApiMethod = activateDeactivateCheckoutLink;

      deactivateApiMethod(currentRow?.id)
        .then(handleDeactivateAction)
        .catch(handleDeactivateActionError);
    } else {
      setAnchorEl(null);
    }
  };

  const getLinksData = async (lines, params) => {
    if (fromSearchPage) {
      if (showHorizontalTable) {
        setRows(searchResultData);
      } else {
        setTableRowSkeleton(true);
        await getModuleSearchResultData(searchResultData)
          .then((result) => {
            setTableRowSkeleton(false);
            if (!result.hasMore) {
              setHasMore(false);
            }
            setRows(result?.concatedResultData);
          })
          .catch(() => {
            setTableRowSkeleton(false);
          });
      }
    } else {
      let method,
        data,
        path = "";
      //use method POST when tab is selected other than 'All'
      if (tab) {
        const filterUrl = `/${linkPath}/filter`;
        method = "POST";
        path = fromSessionPage ? filterUrl : filterUrl.concat(params);
        data = fromSessionPage
          ? {
              status: [tab],
              limit: noOfRecords,
              ending_before: loadMoreId,
            }
          : { status: tab };
      } else {
        method = "GET";
        data = {};
        path = `/${linkPath}` + params;
      }

      setTableRowSkeleton(true);
      await callAPIInterface(method, path, data)
        .then((res) => {
          if (res) {
            setTableRowSkeleton(false);
            if (!res.has_more) {
              setHasMore(false);
            } else {
              setQueryParams(
                queryParam +
                  "&ending_before=" +
                  res.data[res.data.length - 1].id
              );
              setLoadMoreId(res.data[res.data.length - 1].id);
            }
            setRows(lines.concat(res.data));
            dispatch(setIsDataAvailable(res?.data?.length > 0));
          }
        })
        .catch(() => {
          setTableRowSkeleton(false);
        });
    }
  };

  let columnsData = columns.map((column, index) => (
    <TableCell
      key={index}
      align={column.align}
      sx={{ minWidth: column.width, paddingLeft: column.paddingLeft }}
    >
      {column.title}{" "}
    </TableCell>
  ));

  const customerChooseCheck = (rowItem) => {
    return moduleName === "payment_link" && rowItem?.amount === 0;
  };
  let rowsData =
    rows &&
    rows.map((rowItem, index) => {
      const urlPath = fromSessionPage ? "sessions" : linkPath;
      return (
        <TableRow
          key={index}
          className="clickable"
          onClick={() => history.push(`/${urlPath}/${rowItem.id}`)}
        >
          {rowItem.id === rowId && rowLoader ? (
            columns.map((column) => {
              return (
                <TableCell
                  sx={{ padding: "18px 16px !important", width: column.width }}
                >
                  <Skeleton />
                </TableCell>
              );
            })
          ) : (
            <>
              {!fromSessionPage && (
                <TableCell sx={{ paddingLeft: "38px !important" }}>
                  {clipboardElement(
                    rowItem.url || rowItem.default_url,
                    rowItem.status == "active",
                    fromCLPage && showHorizontalTable && fromSearchPage
                      ? "search-bar-link"
                      : "",
                    fromCLPage && "inputBox",
                    false
                  )}
                </TableCell>
              )}

              {!fromPLPage && (
                <TableCell
                  sx={fromSessionPage ? { paddingLeft: "38px !important" } : {}}
                >
                  {fromSessionPage
                    ? clipboardElement(
                        rowItem.id,
                        true,
                        "payments-listing-id-clipboard",
                        "inputBox",
                        false
                      )
                    : rowItem.id}
                </TableCell>
              )}
              {fromSessionPage && (
                <TableCell>
                  <TruncatedTextTooltip
                    textValue={htmlDecode(rowItem?.description || "-")}
                    cellWidth="350px"
                  />
                </TableCell>
              )}
              <TableCell style={{ textAlign: "right" }}>
                {!customerChooseCheck(rowItem) &&
                  getCurrencyObj(rowItem.currency).symbol}
                {customerChooseCheck(rowItem)
                  ? customerChoose
                  : showAmount({
                      amount: rowItem.amount,
                      currency: rowItem.currency,
                    })}
              </TableCell>
              {/* { linkPath==="checkout-links"&& <TableCell style={{ textAlign: 'right' }}>{rowItem.price}</TableCell>} */}
              <TableCell> {dateTimeFormatInApp(rowItem.created)}</TableCell>
              <TableCell>
                {
                  <Tag
                    text={linkStatus[rowItem?.status].label}
                    variant={linkStatus[rowItem?.status].variant}
                    className={fromCLPage && "text-capitalize"}
                  />
                }
              </TableCell>
              {(tab === "active" || tab === undefined) && (
                <TableCell>
                  {rowItem?.status === "active" && (
                    <MoreHorizIcon
                      className="horizontal-dots-icon"
                      tabIndex={0}
                      onKeyDown={handleKeyDown}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleClick(event, rowItem);
                      }}
                    />
                  )}
                </TableCell>
              )}
            </>
          )}
        </TableRow>
      );
    });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
    className: "link-table",
    textOnNoData,
    subTextOnNoData,
    noDataImage,
    createButtonText,
    handleCreate: () => dispatch(setIsModalOpen(true)),
  };

  if (fromCLPage) tableProps["loading"] = false;
  return (
    <>
      {showHorizontalTable && fromSearchPage ? (
        <HorizontalSearchPageTable
          moduleName={moduleName}
          columns={columns}
          rows={tableProps.rows}
          searchQueryUrl={searchQueryUrl}
          inputString={inputString}
          totalNumOfSearchResults={totalNumOfSearchResults}
        />
      ) : (
        <Box className="main-content">
          {isDataAvailable ? (
            <CustomTable {...tableProps} />
          ) : (
            (tab || caughtErrorInStrapi) && (
              <Box
                minHeight="calc(100vh - 201px)"
                display="flex"
                justifyContent="center"
              >
                <NoDataAvailable
                  text={textOnNoData}
                  image={noDataImage}
                  subText={subTextOnNoData}
                  buttonText={createButtonText}
                  handleCreate={() => dispatch(setIsModalOpen(true))}
                />
              </Box>
            )
          )}
        </Box>
      )}

      <CustomPopper
        disablePortal={true}
        open={openPopper}
        anchorEl={anchorEl}
        position="bottom"
        handleClose={() => setAnchorEl(null)}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={handleActiveDeActive}>
              <ListItemText
                primary={
                  currentRow && currentRow.status == "active"
                    ? deActivate
                    : activate
                }
                className={classNames(
                  currentRow && currentRow.status == "active"
                    ? "text-danger"
                    : "text-blue"
                )}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </CustomPopper>
    </>
  );
};

export default PaymentAndCheckoutLinkTable;
