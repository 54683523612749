import React from "react";
import GetGuides from "./GetGuides";

const WithdrawalLinkGuide = () => {
  return (
    <GetGuides
      moduleName="withdrawal_link"
      redirectUrl="/withdrawal-links"
      guideApiPath="withdrawal-link-guide"
      getStartedApiPath="Withdrawal"
      needHelpGuidePath=""
    />
  );
};

export default WithdrawalLinkGuide;
