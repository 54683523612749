import CommonSkeleton from "./CommonSkeleton";
import { Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const accountLoader = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box className="content-center">
        <ArrowBackIcon
          sx={{
            height: "24px",
            width: "24px",
            color: "#E4E9EE",
            marginRight: "10.33px",
          }}
        />
        <CommonSkeleton
          variant="circular"
          width={30}
          height={30}
          color="#F2F5F7"
        />
        <CommonSkeleton
          variant="rectangular"
          width={137}
          height={15}
          color="#F4F4F4"
          sx={{ borderRadius: "4px", marginLeft: "11px" }}
          className="acc-name-loader"
        />
      </Box>
      <CommonSkeleton
        variant="rectangular"
        width={73}
        height={15}
        color="#F4F4F4"
        sx={{ borderRadius: "4px" }}
        className="timer-loader"
      />
    </Box>
  );
};

export const headerLoader = ({ classes = "", sx = {} }) => {
  return (
    <Box marginTop="40px" className={classes} sx={sx}>
      <CommonSkeleton
        variant="rectangular"
        width={137}
        height={17}
        color="#F4F4F4"
        sx={{ borderRadius: "4px" }}
      />
      <CommonSkeleton
        variant="rectangular"
        width={237}
        height={27}
        color="#EAEAEA"
        sx={{ borderRadius: "4px", marginTop: "12px" }}
      />
      <CommonSkeleton
        variant="rectangular"
        width={137}
        height={18}
        color="#F4F4F4"
        sx={{ borderRadius: "4px", marginTop: "12px" }}
      />
    </Box>
  );
};

const tabLoader = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <CommonSkeleton
        variant="rectangular"
        width={"49%"}
        height={61}
        color="#F4F4F4"
        sx={{
          borderRadius: "4px 4px 0px 0px",
          marginRight: "10px",
        }}
      />
      <CommonSkeleton
        variant="rectangular"
        width={"49%"}
        height={61}
        color="#F4F4F4"
        sx={{ borderRadius: "4px 4px 0px 0px" }}
      />
    </Box>
  );
};

const QRLoader = ({ size }) => {
  return (
    <Box className="content-center">
      <CommonSkeleton
        variant="rectangular"
        width={size}
        height={size}
        color="rgba(244, 244, 244, 0.50)"
        sx={{ borderRadius: "4px 4px 0px 0px", marginTop: "30px" }}
      />
    </Box>
  );
};

const bottomLoader = () => {
  return (
    <>
      <CommonSkeleton
        variant="rectangular"
        width="100%"
        height={40}
        color="#F4F4F4"
        sx={{ borderRadius: "4px 4px 0px 0px", marginTop: "24px" }}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop="10px"
      >
        <CommonSkeleton
          variant="rectangular"
          width={"49%"}
          height={40}
          color="#F4F4F4"
          sx={{
            borderRadius: "4px 4px 0px 0px",
            marginRight: "10px",
          }}
        />
        <CommonSkeleton
          variant="rectangular"
          width={"49%"}
          height={40}
          color="#F4F4F4"
          sx={{ borderRadius: "4px 4px 0px 0px" }}
        />
      </Box>
    </>
  );
};

export const wholeQrSkeleton = ({ size }) => {
  return (
    <>
      {tabLoader()}
      {QRLoader({ size })}
      {bottomLoader()}
    </>
  );
};
