import React, {
  useLayoutEffect,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { Box } from "@mui/material";
import CommonHeader from "../Common/CommonHeader";
import ManageAppCard from "./ManageAppCard";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import "../../assets/styles/manage-apps.scss";
import { noManageApps } from "../images";
import Button from "@speed/common/src/components/Button/Button";
import { addNewApp, noApps, noAppsAvailable } from "../messages";
import NoDataAvailable from "@speed/common/src/components/NoDataAvailable";
import { useFlags } from "launchdarkly-react-client-sdk";
import { sessionService } from "redux-react-session";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { noOfRecords, callAPIInterface } from "../constants";
import history from "@speed/common/src/components/history";

function ManageApps() {
  const queryParam = "?limit=" + noOfRecords;
  const [isLoading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [hasMore, setHasMore] = useState(true);
  const [listOfApps, setListOfApps] = useState([]);
  const flags = useFlags();

  useEffect(() => {
    fetchManageApps();
  }, []);

  const boxContainerRef = useRef();
  const boxRef = useRef();

  const loadMore = useCallback(() => {
    getListOfInstalledApps(listOfApps, queryParams);
  }, [listOfApps]);

  const fetchManageApps = () => {
    setListOfApps([]);
    setHasMore(true);
    getListOfInstalledApps([], queryParam);
  };

  const scrollElement = window;
  const refElement = window.document.documentElement;

  const scrollListener = () => {
    if (refElement) {
      const distanceBottom = Math.round(
        (refElement.scrollHeight - refElement.clientHeight) * 0.8
      );

      if (
        refElement.scrollTop > distanceBottom &&
        hasMore &&
        !listLoading &&
        loadMore
      ) {
        loadMore();
      }
    }
  };

  useEffect(() => {
    let boxClientHeight, boxContainerClientHeight;
    boxClientHeight = boxContainerRef?.current?.clientHeight;
    boxContainerClientHeight = window.document.documentElement.clientHeight;

    if (
      boxClientHeight < boxContainerClientHeight &&
      hasMore &&
      !listLoading &&
      listOfApps?.length &&
      loadMore
    ) {
      loadMore();
    }
  }, [listOfApps?.length]);

  useLayoutEffect(() => {
    if (scrollElement) {
      scrollElement.addEventListener("scroll", scrollListener);
      return () => {
        scrollElement.removeEventListener("scroll", scrollListener);
      };
    }
  }, [scrollElement, hasMore, loadMore, listLoading]);

  const getListOfInstalledApps = async (apps, params) => {
    setListLoading(true);
    await callAPIInterface("GET", "/manage-app/list-apps" + params)
      .then((response) => {
        if (response) {
          setListLoading(false);
          if (!response.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam +
                "&ending_before=" +
                response.data[response.data.length - 1].id
            );
          }
          setListOfApps(apps.concat(response.data));
        }
      })
      .catch((_error) => {
        setListLoading(false);
      });
  };

  const handleCreateShopifyApp = () => {
    return new Promise((resolve, reject) => {
      callAPIInterface("GET", `/apps/shopify/get-app`)
        .then((response) => {
          resolve(response);
        })
        .catch((_error) => {
          reject(_error);
        });
    });
  };

  const handleAddNewApp = () => {
    setLoading(true);
    handleCreateShopifyApp()
      .then(async (res) => {
        await sessionService
          .loadSession()
          .then(async (session) => {
            if (session) {
              await sessionService.saveSession({
                ...session,
                appState: res?.state,
              });
            }
          })
          .catch((_err) => {});
        setLoading(false);
        history.push(`/oauth/request_grant?app_id=${res?.state}`);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Box className="section-wrapper">
      <CommonHeader />
      <CustomDivider />
      <Box className="action-btn-wrapper mui-fixed">
        {flags.appAddnew && (
          <Button
            icon="addIcon"
            className="add-icon"
            label={addNewApp}
            loading={isLoading}
            variant="outlined"
            color="primary"
            onClick={handleAddNewApp}
          />
        )}
      </Box>
      {flags.appList ? (
        listOfApps.length > 0 ? (
          <Box className="box-container" ref={boxContainerRef}>
            <Box
              gap={2}
              className="manage-apps-wrapper"
              ref={boxRef}
              onScroll={scrollListener}
            >
              {listOfApps?.map((app) => (
                <ManageAppCard key={app?.id} appInfo={app} flags={flags} />
              ))}
            </Box>
          </Box>
        ) : (
          !listLoading && (
            <Box display="flex" justifyContent="center">
              <NoDataAvailable
                text={noApps}
                image={noManageApps}
                subText={noAppsAvailable}
              />
            </Box>
          )
        )
      ) : (
        <AccessDenied />
      )}
    </Box>
  );
}

export default ManageApps;
