import React, { useEffect, useState } from "react";
import CommonSkeleton from "./CommonSkeleton";
import {
  getCurrencyFormat,
  getPreviewTotalAmount,
  getSatsAmount,
  convertExponentialToDecimal,
  removeNumberFormatting,
} from "./constants";
import { Box } from "@mui/material";
import Text from "./Text/Text";
import classNames from "classnames";
import { CountUp } from "use-count-up";
import PresetAmountPreview from "./LatestWebDesign/PresetAmountPreview";
import AmountOptionsPreview from "./AmountOptionsPreview";

const HeaderAmountSection = (props) => {
  const {
    paymentInfo,
    checkoutLoader,
    isMultiPaymentMethods,
    showTransition,
    fontStyle,
    toggleFlag,
    setToggleFlag,
    totalAmount,
    isZeroAmountLink,
    checkoutSessionId,
    isPartialPaymentAvailable,
    resolution, // New design flag
    visibleInPaymentPage,
    displayAmountInCurrency,
    paymentDetails,
    accountAsset,
    selectedAssetCurrency,
    assetAmountLoader,
    paymentDetailsObj,
    selectedPaymentMethod,
  } = props;

  //partial payment states
  const [oldAmount, setOldAmount] = useState();
  const [newAmount, setNewAmount] = useState();
  const [remainingAmountTransition, setRemainingAmountTransition] = useState();
  const [pendingAmountTransition, setPendingAmountTransition] = useState();
  const [pendingAmount, setPendingAmount] = useState();

  const currencyFormatting = getCurrencyFormat(paymentInfo);

  const prevRemainingAmount =
    sessionStorage.getItem("cl_pending_target_amount") &&
    JSON.parse(sessionStorage.getItem("cl_pending_target_amount"));
  const newRemainingAmount = +removeNumberFormatting(totalAmount.split(" ")[0]);
  const remainingAmountCurrency = totalAmount.split(" ")[1];

  const prevCurrencyFormatting =
    paymentInfo &&
    pendingAmount &&
    getCurrencyFormat({
      amount: pendingAmount,
      currency: paymentInfo.currency,
    });

  const setAmountDetails = (oldAmount, newAmount) => {
    setRemainingAmountTransition(false);
    setOldAmount(oldAmount);
    setNewAmount(newAmount);
    sessionStorage.setItem(
      "cl_pending_target_amount",
      JSON.stringify(newAmount)
    );
  };

  useEffect(() => {
    if (visibleInPaymentPage) {
      setRemainingAmountTransition(showTransition);
      setPendingAmountTransition(showTransition);
      if (!showTransition) {
        setAmountDetails(newRemainingAmount, newRemainingAmount);
      }

      setTimeout(() => {
        if (showTransition) {
          setAmountDetails(prevRemainingAmount, newRemainingAmount);
        }
      }, 2200);

      setTimeout(() => {
        setPendingAmountTransition(false);
        if (paymentInfo) {
          setPendingAmount(paymentInfo.amount);
          sessionStorage.setItem(
            "cl_pending_amount",
            JSON.stringify(paymentInfo.amount)
          );
        }
      }, 3500);
    }
  }, [paymentInfo]);

  useEffect(() => {
    if (visibleInPaymentPage && prevRemainingAmount !== oldAmount) {
      setTimeout(() => {
        if (showTransition) {
          setOldAmount(prevRemainingAmount);
        }
      }, 2700);
    }
  }, [prevRemainingAmount, oldAmount, newRemainingAmount]);
  const handleAmountClick = () => {
    if (isMultiPaymentMethods) {
      const toggleTotalAmount = !toggleFlag
        ? getSatsAmount(paymentInfo, visibleInPaymentPage)
        : getPreviewTotalAmount({
            data: paymentInfo,
            paymentDetailsObj,
            visibleInPaymentPage: true,
          });

      const toggleRemainingAmount = +removeNumberFormatting(
        toggleTotalAmount.split(" ")[0]
      );
      sessionStorage.setItem(
        "cl_pending_target_amount",
        JSON.stringify(toggleRemainingAmount)
      );
      setOldAmount(toggleRemainingAmount);
      setNewAmount(toggleRemainingAmount);
    }
    setToggleFlag(!toggleFlag);
  };

  const excludedAmountType = ["amountOptions", "preset"];

  const commonProps = {
    paymentInfo,
    fontStyle,
    visibleInPaymentPage,
    checkoutSessionId,
    displayAmountInCurrency,
    paymentStatus: paymentDetails?.status,
    selectedAssetCurrency,
    assetAmountLoader,
    paymentDetailsObj,
    selectedPaymentMethod,
  };

  const checkPaymentsAvailable = !paymentInfo?.payment?.payments?.length;

  const showMainAmount = assetAmountLoader ? (
    <CommonSkeleton />
  ) : (
    <Box
      onClick={() => {
        accountAsset
          ? selectedAssetCurrency === "SATS" && handleAmountClick()
          : handleAmountClick();
      }}
      marginTop={props.pageTitle && "12px"}
      marginBottom="10px"
    >
      <Text
        className="checkout-acc-amount pointer-cursor"
        size={28}
        sx={{
          lineHeight: "normal !important",
          wordWrap: "break-word",
          width: "unset",
          ...fontStyle,
        }}
        font="regular"
        variant="subtitle1"
      >
        {visibleInPaymentPage &&
        oldAmount &&
        newAmount &&
        oldAmount !== newAmount ? (
          <>
            <CountUp
              isCounting
              start={oldAmount}
              end={newAmount}
              decimalPlaces={
                newAmount - Math.floor(newAmount) !== 0
                  ? convertExponentialToDecimal(+newAmount?.toFixed(16))
                      .toString()
                      .split(".")[1].length
                  : 0
              }
              duration={1}
            />{" "}
            {remainingAmountCurrency}
          </>
        ) : (
          <>
            {remainingAmountTransition
              ? `${oldAmount} ${remainingAmountCurrency}`
              : totalAmount}
          </>
        )}
      </Text>
    </Box>
  );

  const showDefaultType =
    !isPartialPaymentAvailable &&
    checkPaymentsAvailable &&
    paymentInfo?.amountType === "amountOptions" ? (
      <AmountOptionsPreview {...commonProps} />
    ) : (
      showMainAmount
    );

  const renderAmountTypeSection =
    !isPartialPaymentAvailable &&
    checkPaymentsAvailable &&
    paymentInfo?.amountType === "preset" ? (
      <PresetAmountPreview
        {...commonProps}
        totalAmount={totalAmount}
        resolution={resolution}
      />
    ) : (
      showDefaultType
    );

  const checkForAmountTypeExist = checkPaymentsAvailable
    ? !excludedAmountType.includes(paymentInfo?.amountType)
    : true;

  return (
    <Box className="amount-currency-wrapper">
      {checkoutLoader ? (
        <CommonSkeleton
          className="checkout-acc-amount"
          height={15}
          width={200}
          color="#c4ccd2"
          sx={{
            borderRadius: "6px",
            margin:
              resolution === "web"
                ? "23px 0 !important"
                : "15px 95px 15px 95px !important",
          }}
        />
      ) : (
        renderAmountTypeSection
      )}
      {!isZeroAmountLink &&
        (checkForAmountTypeExist || isPartialPaymentAvailable) && (
          <Text
            className={classNames(
              "checkout-acc-currency",
              showTransition &&
                !pendingAmountTransition &&
                "animate-currency-text"
            )}
            size={14}
            font="regular"
            variant="subtitle1"
            sx={{ lineHeight: "normal !important", ...fontStyle }}
          >
            {pendingAmountTransition
              ? prevCurrencyFormatting
              : currencyFormatting}
          </Text>
        )}
    </Box>
  );
};

export default HeaderAmountSection;
