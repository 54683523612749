import { Box } from "@mui/material";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { Input } from "@speed/common/src/components/Input/Input";
import Label from "@speed/common/src/components/Label/Label";
import Text from "@speed/common/src/components/Text/Text";
import React from "react";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import {
  addDetailsToYourStore,
  addStoreDetailsAndAuthenticate,
  enterStoreName,
  enterStoreUrl,
  enterStoreUrlSuch,
  storeName,
  storeUrl,
  urlWithHttpValidate,
} from "../../messages";

const StoreAuthenticate = (props) => {
  const {
    values,
    setFieldValue,
    touched,
    setTouched,
    errors,
    defaultErrorMessageProps,
  } = props;
  return (
    <Box sx={{ mt: "50px" }} display="inline-flex">
      <Box className="grid-box-step">
        <Text style={{ color: "#2a67ff", textAlign: "center" }}>1</Text>
      </Box>
      <Box>
        <Text
          style={{ marginTop: "4px" }}
          className="default-text"
          size={20}
          variant="h2"
        >
          {addStoreDetailsAndAuthenticate}
        </Text>
        <Text
          className="grey-text margin-top15"
          size={20}
          variant="body1"
          font="regular"
        >
          {addDetailsToYourStore}
        </Text>
        <Box sx={{ mt: "50px", alignItems: "center" }} width={600}>
          <Box display="flex" justifyContent="space-between">
            <Label>{storeName}</Label>
          </Box>
          <Input
            name={storeName}
            showLabel={false}
            fullWidth
            label={storeName}
            placeholder={enterStoreName}
            type="text"
            value={values.store_name}
            error={touched.store_name && Boolean(errors.store_name)}
            onBlur={() => setTouched({ ...touched, store_name: true })}
            onChange={(e) => {
              setTouched({ ...touched, store_name: false });
              setFieldValue("store_name", e.target.value);
            }}
          />
          <InputErrorMessage
            {...defaultErrorMessageProps}
            inputName="store_name"
          />
        </Box>
        <Box width={600} className="margin-top30">
          <Box display="flex" justifyContent="space-between">
            <Label>{storeUrl}</Label>
          </Box>
          <Input
            name={storeUrl}
            showLabel={false}
            fullWidth
            label={storeUrl}
            placeholder={enterStoreUrl}
            type="text"
            value={values.store_url}
            error={touched.store_url && Boolean(errors.store_url)}
            onBlur={() => setTouched({ ...touched, store_url: true })}
            onChange={(e) => {
              setTouched({ ...touched, store_url: false });
              setFieldValue("store_url", e.target.value);
            }}
          />
          {touched.store_url && Boolean(errors.store_url) && (
            <AlertMessage
              className="margin-top14"
              severity="error"
              message={urlWithHttpValidate}
            />
          )}
          <Text
            sx={{ marginTop: "13px", display: "flex", gap: 0.5 }}
            className="grey-text"
            size={14}
            variant="body1"
            font="regular"
          >
            <span style={{ color: "#e74c3c" }}>*</span> {enterStoreUrlSuch}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default StoreAuthenticate;
