import React from "react";
import moment from "moment";
import {
  Box,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import WarningIcon from "@mui/icons-material/Warning";

import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Text from "@speed/common/src/components/Text/Text";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import history from "@speed/common/src/components/history";
import NoDataAvailable from "@speed/common/src/components/NoDataAvailable";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { useFlags } from "launchdarkly-react-client-sdk";

import jsonDataToShow from "../jsonLogic";
import {
  ID,
  apiVersion,
  dateCreated,
  ipAddress,
  noPostBody,
  noResponseBody,
  origin,
  reqBodyLabel,
  resBodyLabel,
  source,
  status,
} from "../messages";
import { fullScreen } from "../images";
import { dateTimeFormatInApp, originURL } from "../constants";

const LogJson = ({ selectedLog, statusTag, isLoading = true }) => {
  const { logDetailFe } = useFlags();

  const apiDetailsTableData = () => [
    {
      header: status,
      cell: statusTag(selectedLog),
    },
    {
      header: ID,
      cell: (
        <Text className="default-text" font="regular" size={16} variant="body2">
          {selectedLog?.id}
        </Text>
      ),
    },
    {
      header: dateCreated,
      cell: (
        <Text className="default-text" font="regular" size={16} variant="body2">
          {dateTimeFormatInApp(selectedLog?.request_time)}
        </Text>
      ),
    },
    {
      header: ipAddress,
      cell: (
        <Text className="default-text" font="regular" size={16} variant="body2">
          {selectedLog?.request?.client_ip || "-"}
        </Text>
      ),
    },
    {
      header: apiVersion,
      cell: (
        <CustomLink
          alignIcon="end"
          withIcon={
            <OpenInNewOutlinedIcon
              htmlColor="#2A67FF"
              sx={{ height: 18, width: 18 }}
            />
          }
          target="_blank"
          href={`${process.env.REACT_APP_SPEED_API_URL}reference/versioning#speed-versions`}
        >
          <Text
            className="default-text"
            font="regular"
            size={16}
            variant="body2"
            sx={{ color: "#2A67FF !important" }}
          >
            {moment(
              selectedLog?.request?.request_headers?.speed_version
            ).format("YYYY-MM-DD")}
          </Text>
        </CustomLink>
      ),
    },
    {
      header: source,
      cell: (
        <Box display="flex">
          <Text
            className="default-text"
            font="regular"
            size={16}
            variant="body2"
          >
            {selectedLog?.request?.api_source || "-"}
          </Text>
        </Box>
      ),
    },
    {
      header: origin,
      cell: (
        <CustomLink style={{ cursor: "unset" }}>
          {originURL(
            selectedLog?.request?.api_source,
            selectedLog?.request?.resource_path
          )}
        </CustomLink>
      ),
    },
  ];

  const verticalTableLoader = () => (
    <Box className="vertical-table-wrapper">
      <Table sx={{ display: "flex", justifyContent: "space-between" }}>
        <TableBody>
          {Array(2)
            .fill()
            .map((item) => {
              return (
                <TableRow key={item}>
                  <TableCell variant="head">
                    <Skeleton
                      animation="wave"
                      width={100}
                      height={15}
                      className="get-started-loader"
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton
                      animation="wave"
                      width={190}
                      height={15}
                      className="get-started-loader"
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Box>
  );

  if (isLoading) {
    return (
      <Grid
        item
        xs={5.49}
        bgcolor="#f7fafc"
        height="100vh"
        overflow="auto"
        position="relative"
      >
        <Box display="flex" flexDirection="column" p="36px">
          <Skeleton
            animation="wave"
            width="50%"
            sx={{
              bgcolor: "#eaeef1 !important",
              marginBottom: "24px",
            }}
          />
          {verticalTableLoader()}
        </Box>
      </Grid>
    );
  }

  return (
    <Grid
      item
      xs={5.49}
      bgcolor="#f7fafc"
      height="100%"
      overflow="auto"
      position="relative"
    >
      <Box display="flex" flexDirection="column" p="36px">
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text
              size="20"
              font="regular"
              variant="h1"
              component="h2"
              noWrap
              sx={{ lineHeight: "120% !important", fontWeight: "600" }}
            >
              {`${selectedLog?.request?.method} ${selectedLog?.request?.resource_path}`}
            </Text>
            {logDetailFe && (
              <Box
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={() => history.push(`/logs/${selectedLog?.id}`)}
                className="pointer-cursor"
              >
                <img src={fullScreen} alt="Screen-Enlarge" />
              </Box>
            )}
          </Box>
          <VerticalTable
            rowData={apiDetailsTableData()}
            className="logs-vertical-data"
          />
        </Box>
        <CustomDivider />
        {selectedLog?.response?.response_body?.errors && (
          <Box
            margin="30px 0"
            borderRadius="5px"
            bgcolor="#FFE8E6"
            py={2}
            gap={1}
            width="680px"
            height="84px"
          >
            <Box display="flex" pl="16px">
              <WarningIcon
                sx={{
                  color: "#CE3B2C",
                  height: 18,
                  width: 18,
                }}
              />
              <Text
                font="regular"
                size={14}
                variant="body2"
                color="#CE3B2C"
                paddingLeft="10px"
              >
                {selectedLog?.response?.response_body?.errors?.[0]?.type ||
                  selectedLog?.response?.response_body?.errors?.[0]?.message}
              </Text>
            </Box>
            <Box pl="44px">
              <Text
                className="grey-text"
                font="regular"
                size={14}
                variant="body2"
                sx={{ paddingTop: "8px" }}
              >
                {selectedLog?.response?.response_body?.errors?.[0]?.message}
              </Text>
            </Box>
          </Box>
        )}
        <CustomDivider />

        <Box display="flex" flexDirection="column" pt="30px" pb="30px">
          <Text size={18} variant="h1" component="h2">
            {resBodyLabel}
          </Text>
          <Box>
            {selectedLog?.response?.response_body ? (
              jsonDataToShow(selectedLog?.response?.response_body)
            ) : (
              <NoDataAvailable
                text={noResponseBody}
                className="no-availble-logs-block"
              />
            )}
          </Box>
        </Box>
        {/* <CustomDivider />
        <Box display="flex" pt="30px" pb="30px">
          <Text size={18} variant="h1" component="h2">
            {reqParamLabel}
          </Text>
          <Box>
            {selectedLog?.request?.request_params ? (
              jsonDataToShow(selectedLog?.request?.request_params)
            ) : (
              <NoDataAvailable
                text={noQueryParameters}
                className="no-availble-logs-block"
              />
            )}
          </Box>
        </Box> */}
        <CustomDivider />

        <Box display="flex" flexDirection="column" pt="30px" pb="30px">
          <Text size={18} variant="h1" component="h2">
            {reqBodyLabel(selectedLog?.request?.method)}
          </Text>
          <Box>
            {selectedLog?.request?.request_body ? (
              jsonDataToShow(selectedLog?.request?.request_body)
            ) : (
              <NoDataAvailable
                text={noPostBody(selectedLog?.request?.method)}
                className="no-availble-logs-block"
              />
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default LogJson;
