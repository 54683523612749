import React, { useState, createRef } from "react";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Text from "@speed/common/src/components/Text/Text";
import { Button } from "@speed/common/src/components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  createNew,
  createWithdrawalLinkMsg,
  testModeTextForWithdrawal,
  testModeTitle,
} from "../messages";
import TestModeHeader from "../Payments/TestModeHeader";
import { back, next } from "@speed/common/src/components/messages";
import {
  setIsModalOpen,
  setPaymentConfirmationOption,
  setPaymentRedirectWebPreview,
  updatePaymentInfo,
  updatePaymentMessage,
} from "../../redux/common/actions";
import WithdrawalLinkTable from "../Common/WithdrawalLinkTable";
import { useFlags } from "launchdarkly-react-client-sdk";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { callAPIInterface } from "../constants";
import WithdrawalLinkModal from "./WithdrawalLinkModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LinkList(props) {
  const ref = createRef();
  const confirmRef = createRef();

  const [disabled, setDisabled] = useState(true);
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [disableBackButton, setDisableBackButton] = useState(false);
  const [formDatavalue, setFormDataValue] = useState(null);

  const [isWithdrawalLink, setIsWithdrawalLink] = useState(false);
  const dispatch = useDispatch();

  const liveMode = useSelector((state) => state.auth.liveMode);

  const {
    history,
    paymentConfirmationOption,
    isModalOpen,
    isDataAvailable,
    caughtErrorInStrapi,
  } = useSelector((state) => state.common);

  const {
    wlConfirmwithdrawallink,
    wlListallwithdrawal,
    wlCreatewithdrawal,
    wlDeactivateFe,
    wlDetailpageFe,
    wlGetpasswordFe,
  } = useFlags();

  const handleModalClose = () => {
    dispatch(setIsModalOpen(false));
    setIsWithdrawalLink(false);
    dispatch(updatePaymentMessage(""));
    dispatch(setPaymentRedirectWebPreview(false));
    dispatch(updatePaymentInfo(null));
  };

  const handleNext = () => {
    setFormDataValue(ref.current && ref.current.handleSubmit());
    setIsWithdrawalLink(true);
    if (paymentConfirmationOption == "redirect") {
      dispatch(setPaymentRedirectWebPreview(true));
    }
  };

  const handleBackButton = () => {
    setIsWithdrawalLink(false);
  };

  const handleSubmit = () => {
    // return the values of form while submitting the form

    const confirmData =
      confirmRef.current && confirmRef.current.handleSubmitConfirm();

    const formData = ref.current && ref.current.handleSubmit();

    let withdrawalLinkFormData = null;

    if (wlConfirmwithdrawallink) {
      withdrawalLinkFormData = {
        ...formData,
        ...confirmData,
      };
    } else {
      withdrawalLinkFormData = {
        ...formData,
      };
    }

    if (withdrawalLinkFormData) {
      if (wlConfirmwithdrawallink) {
        setDisableCreateButton(true);
        setDisableBackButton(true);
      } else setDisabled(true);

      callAPIInterface(
        "POST",
        "/withdrawal-links",
        JSON.stringify(withdrawalLinkFormData)
      )
        .then((response) => {
          response && history.push(`/withdrawal-links/${response.id}`);
          dispatch(setPaymentConfirmationOption("confirmation"));
          dispatch(setPaymentRedirectWebPreview(false));
          dispatch(updatePaymentMessage(""));
        })
        .catch((_error) => {
          if (wlConfirmwithdrawallink) {
            setDisableCreateButton(false);
            setDisableBackButton(false);
          } else setDisabled(false);
        });
    }
  };

  const renderCreateLinkButton = () => {
    return (
      <Button
        label={createWithdrawalLinkMsg}
        variant="contained"
        color="primary"
        className="payment-link-btn"
        onClick={handleSubmit}
        disabled={disabled}
      />
    );
  };

  const renderCreateConfirmButtons = () => {
    return !isWithdrawalLink ? (
      <Button
        label={next}
        variant="contained"
        color="primary"
        className="payment-link-btn"
        disabled={disabled}
        onClick={handleNext}
      />
    ) : (
      <React.Fragment>
        <Button
          variant="outlined"
          color="primary"
          label={back}
          onClick={handleBackButton}
          sx={{ marginRight: "18px" }}
          disabled={disableBackButton}
        ></Button>
        <Button
          label={createWithdrawalLinkMsg}
          variant="contained"
          color="primary"
          className="payment-link-btn"
          onClick={handleSubmit}
          disabled={disableCreateButton}
        />
      </React.Fragment>
    );
  };

  const createNewLinkButton = () => {
    return (
      <Box className="action-btn-wrapper mui-fixed">
        <Button
          icon="addIcon"
          className="add-icon"
          label={createNew}
          variant="outlined"
          color="primary"
          onClick={() => {
            dispatch(setIsModalOpen(true));
          }}
        />
      </Box>
    );
  };

  return (
    <>
      {wlCreatewithdrawal &&
        (isDataAvailable || caughtErrorInStrapi || props.tab) &&
        createNewLinkButton()}

      {wlListallwithdrawal ? (
        <WithdrawalLinkTable
          tab={props.tab}
          deactivateLDFlag={wlDeactivateFe}
          detailPageLDFlag={wlDetailpageFe}
          copyPasswordLDFlag={wlGetpasswordFe}
        />
      ) : (
        <AccessDenied />
      )}

      <Dialog
        disableEscapeKeyDown
        fullScreen
        open={isModalOpen}
        TransitionComponent={Transition}
        className="fullscreen-modal"
      >
        {!liveMode && (
          <TestModeHeader
            buttonText={testModeTitle}
            captionText={testModeTextForWithdrawal}
          />
        )}
        <AppBar sx={{ position: "relative" }} className="modal-app-bar">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleModalClose();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Text
              size={20}
              font="semibold"
              sx={{ flex: 1 }}
              className="default-text divider"
              variant="h6"
            >
              {createWithdrawalLinkMsg}
            </Text>

            {wlConfirmwithdrawallink
              ? renderCreateConfirmButtons()
              : renderCreateLinkButton()}
          </Toolbar>
        </AppBar>
        <Box className="fullscreen-modal-box">
          <WithdrawalLinkModal
            ref={ref}
            handleButtonDisable={(value) => setDisabled(value)}
            isPaymentLink={isWithdrawalLink}
            isFromWithdrawalLink={true}
            setDisableCreateButton={setDisableCreateButton}
            confirmRef={confirmRef}
          />
        </Box>
      </Dialog>
    </>
  );
}

export default LinkList;
