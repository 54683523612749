import React, { useState, useEffect } from "react";
import CustomAccordion from "./Accordion/Accordion";
import {
  checkMainMenu,
  displaySidebarItem,
  getMainMenuRoute,
  pathArray,
  walletAllowedSettingsRoute,
} from "./constants";
import {
  Box,
  Drawer,
  List,
  CardContent,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import OutboundOutlinedIcon from "@mui/icons-material/OutboundOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded";
import WebhookIcon from "@mui/icons-material/Webhook";
import DataObjectIcon from "@mui/icons-material/DataObject";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import _ from "lodash";
import history from "@speed/common/src/components/history";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useDispatch, useSelector } from "react-redux";
import {
  sellBitcoin,
  buyBitcoin,
  referAndEarn,
  rewards,
  downloadGo,
  transferIcon,
  withdrawalLinkIcon,
  connectSideBarIcon,
  manageNode,
  promoCodeIcon,
  selectedPromoCodeIcon,
} from "./images";
import classNames from "classnames";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import GroupWorkOutlinedIcon from "@mui/icons-material/GroupWorkOutlined";
import Groups3OutlinedIcon from "@mui/icons-material/Groups3Outlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { getStartedText } from "../messages";
import { Merchant, Wallet } from "./messages";

const Sidebar = ({
  menuItems,
  onClickItem,
  setIsDataAvailable,
  getStartedSubmenu,
  user,
  autoPayoutSchedulingEnabled,
}) => {
  const [privateRoute, setPrivateRoute] = useState({ ...menuItems });
  const { liveMode, currentAccount } = useSelector((state) => state.auth);
  const [selectedItem, setSelectedItem] = useState("");
  const [expanded, setExpanded] = useState("");
  const [locationKeys, setLocationKeys] = useState([]);
  const flags = useFlags();
  const dispatch = useDispatch();
  const accountType = currentAccount?.account?.account_type;

  useEffect(() => {
    const clonedMenuItems = { ...menuItems };
    if (getStartedSubmenu && accountType === Merchant) {
      if (liveMode) {
        delete clonedMenuItems[getStartedText];
        setPrivateRoute({ ...clonedMenuItems });
      } else {
        clonedMenuItems[getStartedText].subMenu = getStartedSubmenu;
        setPrivateRoute({ ...menuItems });
      }
    } else {
      setPrivateRoute({ ...menuItems });
    }
  }, [liveMode, menuItems]);

  const icons = {
    getStarted: <RocketLaunchOutlinedIcon />,
    dashboard: <GridViewOutlinedIcon />,
    receivePayments: <ArrowCircleLeftOutlinedIcon />,
    payments: <PaymentsOutlinedIcon />,
    checkoutLinks: <ShoppingCartCheckoutOutlinedIcon />,
    paymentLinks: <QrCodeScannerIcon />,
    sendPayments: <ArrowCircleRightOutlinedIcon />,
    withdraws: <UnarchiveOutlinedIcon />,
    payouts: <OutboundOutlinedIcon />,
    balances: <AccountBalanceWalletOutlinedIcon />,
    transactions: <ReceiptLongOutlinedIcon />,
    connectIcon: (
      <img
        src={connectSideBarIcon}
        height={24}
        width={24}
        alt="connect-icon"
        style={{
          filter: selectedItem === "/connect" ? "" : "invert(40%)",
        }}
      />
    ),
    manageNode: (
      <img
        src={manageNode}
        height={24}
        width={24}
        className="manage-node-icon"
        alt="manage-node-icon"
        style={{
          filter: selectedItem === "/selector" ? "" : "invert(40%)",
        }}
      />
    ),
    customers: <PeopleAltOutlinedIcon />,
    orders: <ShoppingCartOutlinedIcon />,
    products: <Inventory2OutlinedIcon />,
    manageApps: <DashboardCustomizeOutlinedIcon />,
    oneQr: <QrCode2OutlinedIcon />,
    developers: <DataObjectIcon />,
    key: <VpnKeyOutlinedIcon />,
    sessions: <ScheduleRoundedIcon />,
    webhooks: <WebhookIcon />,
    event: <EventNoteOutlinedIcon />,
    assignment: <AssignmentOutlinedIcon />,
    help: <HelpOutlineOutlinedIcon />,
    testing: <HandymanOutlinedIcon />,
    settings: <SettingsOutlinedIcon />,
    account: <StoreOutlinedIcon />,
    accessTime: <AccessTimeIcon />,
    user: <AccountCircleOutlinedIcon />,
    info: <StorefrontOutlinedIcon />,
    withdrawalLinks: (
      <img
        src={withdrawalLinkIcon}
        alt=""
        style={{
          filter: selectedItem === "/withdrawal-links" ? "" : "invert(40%)",
        }}
      />
    ),
    swaps: <PublishedWithChangesIcon />,
    partners: <GroupWorkOutlinedIcon />,
    affiliatePartners: <Groups3OutlinedIcon />,
    transfers: (
      <img
        src={transferIcon}
        alt=""
        style={{
          filter: selectedItem === "/transfers" ? "" : "invert(40%)",
        }}
      />
    ),
    users: <ContactsOutlinedIcon />,
    manageAccount: <ManageAccountsOutlinedIcon />,
    promoCode: (
      <img
        src={
          selectedItem === "/promo-code" ? selectedPromoCodeIcon : promoCodeIcon
        }
        alt=""
      />
    ),
    reports: <DescriptionOutlinedIcon />,
    myAssets: <AccountBalanceWalletOutlinedIcon />,
    allAssets: <DashboardCustomizeOutlinedIcon />,
    refer: (
      <img
        src={referAndEarn}
        alt=""
        style={{
          filter: selectedItem === "/transfers" ? "" : "invert(40%)",
        }}
      />
    ),
    buy: (
      <img
        src={buyBitcoin}
        alt=""
        style={{
          filter: selectedItem === "/buy-bitcoin" ? "" : "invert(40%)",
        }}
      />
    ),
    sell: (
      <img
        src={sellBitcoin}
        alt=""
        style={{
          filter: selectedItem === "/transfers" ? "" : "invert(40%)",
        }}
      />
    ),
    reward: (
      <img
        src={rewards}
        alt=""
        style={{
          filter: selectedItem === "/transfers" ? "" : "invert(40%)",
        }}
      />
    ),
    download: (
      <img
        src={downloadGo}
        alt=""
        style={{
          filter: selectedItem === "/transfers" ? "" : "invert(40%)",
        }}
      />
    ),
    maintenance: <EngineeringOutlinedIcon />,
  };

  const setLocalStorageMenu = (menuRoute) => {
    let backMenuName = "";
    let resultKey = "";
    const asArray = Object.entries(privateRoute); //convert private route object to array ( it will be the array of array having key and its value)

    const result = asArray.filter((item) => {
      // Filter out the selected route from private route object
      if (_.isArray(item[1]?.subMenu) && item[1]?.subMenu?.length) {
        return item[1].subMenu.find(
          (subMenuItem) => subMenuItem.route === menuRoute
        );
      } else {
        return item[1].route === menuRoute;
      }
    });

    //get name of previous route
    if (result && result.length > 0) {
      if (_.isArray(result[0][1].subMenu) && result[0][1].subMenu.length) {
        // get name of sub menu
        const getRoute = result[0][1].subMenu.find(
          (item) => item.route === menuRoute
        );
        backMenuName = getRoute.header;
        resultKey = result[0][0];
      } else {
        // get name of main menu
        backMenuName = result[0][1].header;
      }
      localStorage.setItem(
        "back_to_menu",
        JSON.stringify({
          menuUrl: menuRoute,
          menuName: backMenuName,
          expandTab: resultKey,
        })
      );
    }
  };

  const setSelectedItemStates = (tabName, tabRoute) => {
    setExpanded(tabName);
    setSelectedItem(tabRoute);
  };

  useEffect(() => {
    //logic for keeping left sidebar item selected/highlighted and accordian open/close for current page route
    if (history) {
      setSelectedItem(history.location.pathname);
      const isMainMenu = checkMainMenu(history.location.pathname);
      if (isMainMenu) {
        const mainMenuRoute = getMainMenuRoute(history.location.pathname);

        //For the Main menu sidebar item: to find and select item for current page route
        Object.entries(privateRoute)?.forEach(([rootKey, rootValue]) => {
          if (rootValue?.subMenu?.length) {
            _.forEach(rootValue?.subMenu, (subMenuChild) => {
              if (
                history.location.pathname.includes(subMenuChild?.route) &&
                rootValue?.isMainHasSubMenu
              ) {
                setExpanded(rootKey);
              }
            });
          }
        });

        setLocalStorageMenu(mainMenuRoute);
        setSelectedItem(mainMenuRoute);
      } else {
        //For the Sub menu sidebar item: to find and select item for current page route
        Object.entries(privateRoute)?.forEach(([rootKey, rootValue]) => {
          Object.entries(rootValue?.subMenu)?.forEach(([key, value]) => {
            if (value?.subMenu?.length > 0) {
              _.forEach(value.subMenu, (subMenuChild) => {
                if (subMenuChild.subMenu) {
                  let item = _.find(subMenuChild.subMenu, {
                    route: history.location.pathname,
                  });
                  if (item) {
                    setSelectedItemStates(key, subMenuChild.route);
                  }
                } else {
                  if (history.location.pathname.includes(subMenuChild.route)) {
                    setSelectedItemStates(key, subMenuChild.route);
                  }
                }
              });
            } else {
              if (history.location.pathname.includes(value.route)) {
                setSelectedItemStates(rootKey, value.route);
              }
            }
          });
        });
      }

      return history.listen((location) => {
        if (history.action === "POP") {
          if (locationKeys[1] === location.key) {
            // Handle back event
            setLocationKeys(([_, ...keys]) => keys);
          } else {
            // Handle forward event
            setLocationKeys((keys) => [location.key, ...keys]);
          }
        }
      });
    }
  }, [history, history && history.location, locationKeys, privateRoute]);

  let sidebarContent = [];

  const handleChange = (title) => (_e, newExpanded) => {
    setExpanded(newExpanded ? title : false);
  };

  const SidebarListItems = (sidebarListItemsProps) => {
    const { accordianPanel, index, item, onClickItem } = sidebarListItemsProps;
    const isWalletAccount = currentAccount?.account?.account_type === Wallet;

    if (isWalletAccount && !walletAllowedSettingsRoute.includes(item.title)) {
      return null;
    }

    return (
      <ListItem key={accordianPanel + index}>
        <ListItemButton
          onClick={() => {
            onClickItem && onClickItem(false);
            if (item.isExternalLink) {
              window.open(item.route, "_blank", "noopener");
            } else {
              setIsDataAvailable && dispatch(setIsDataAvailable(true));
              history.push(item.route);
              setSelectedItem(item.route);
            }
          }}
          selected={selectedItem === item.route}
        >
          <ListItemText
            primary={item.title}
            sx={{ textTransform: "capitalize" }}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const SidebarList = ({ listItems, accordianPanel }) => {
    return (
      listItems.length > 0 &&
      listItems.map((item, index) => {
        return (
          displaySidebarItem(item, flags, currentAccount, user) && (
            <SidebarListItems
              key={item.title}
              index={index}
              accordianPanel={accordianPanel}
              item={item}
              onClickItem={onClickItem}
            />
          )
        );
      })
    );
  };

  const renderAccordianOfSubMenu = (subMenuInfo) => {
    Object.entries(subMenuInfo).forEach(([subMenuKey, subMenuValue]) => {
      const isShow = displaySidebarItem(
        subMenuInfo,
        flags,
        currentAccount,
        user
      );
      if (isShow) {
        if (subMenuValue?.subMenu?.length > 0) {
          sidebarContent.push(
            <CustomAccordion
              className="subMenuAccordion"
              isTitleShown={true}
              key={subMenuKey}
              onChange={(title) => handleChange(title)}
              expanded={expanded === subMenuKey}
              expandIcon="expandMore"
              accordianPanel={subMenuKey}
              customComponent={
                <SidebarList
                  listItems={subMenuValue.subMenu}
                  accordianPanel={subMenuKey}
                />
              }
              title={`${subMenuValue.title}`}
              icon={icons[subMenuValue.icon]}
            />
          );
        } else if (!pathArray.includes(subMenuValue.route)) {
          renderMenuItem(
            subMenuValue.title,
            subMenuValue.route,
            subMenuValue.icon,
            subMenuValue
          );
        }
      }
    });
  };

  let isfirstItemFound = false;
  const recursivelyGetAllSubmenu = (obj) => {
    //to iterate and find first sidebar menu item(e.g. if LD flag is off take next first item) when click on main menu item having sub menu in it.
    if (!isfirstItemFound) {
      for (const key in obj) {
        if (key === "subMenu") {
          if (obj[key]?.length) {
            _.some(obj[key], (subMenuChild) => {
              if (
                displaySidebarItem(subMenuChild, flags, currentAccount, user)
              ) {
                if (subMenuChild?.subMenu) {
                  recursivelyGetAllSubmenu(subMenuChild);
                } else {
                  if (!isfirstItemFound) {
                    isfirstItemFound = true;
                    history.push(subMenuChild.route);
                    return true;
                  }
                }
              }
            });
          } else {
            history.push(obj.route);
            isfirstItemFound = true;
          }
        }
      }
    }
  };

  const renderMenuItem = (item, mainMenuRoute, icon, menuObject) => {
    sidebarContent.push(
      displaySidebarItem(menuObject, flags, currentAccount, user) && (
        <CardContent className="main-menu-item" key={item}>
          <List>
            <ListItem
              className={classNames(
                privateRoute[item]?.isMainHasSubMenu &&
                  "main-menu-accordian-list"
              )}
              disablePadding
            >
              {privateRoute[item]?.isMainHasSubMenu ? (
                <CustomAccordion
                  isTitleShown={true}
                  className="mainMenuAccordion"
                  key={item}
                  onChange={(title) => handleChange(title)}
                  expanded={expanded === item}
                  expandIcon="expandMore"
                  accordianPanel={item}
                  customComponent={
                    <SidebarList
                      listItems={privateRoute[item]?.subMenu}
                      accordianPanel={item}
                    />
                  }
                  title={item}
                  icon={icons[icon]}
                />
              ) : (
                <ListItemButton
                  selected={selectedItem === mainMenuRoute}
                  onClick={() => {
                    setExpanded(false);
                    setSelectedItem(mainMenuRoute);
                    recursivelyGetAllSubmenu(menuObject);
                  }}
                >
                  <ListItemIcon>{icons[icon]}</ListItemIcon>
                  <ListItemText
                    primary={`${item}`}
                    sx={{ textTransform: "capitalize" }}
                  />
                </ListItemButton>
              )}
            </ListItem>
          </List>
        </CardContent>
      )
    );
  };

  if (history && history.location && checkMainMenu(history.location.pathname)) {
    for (let item in privateRoute) {
      let mainMenuRoute = privateRoute[item]?.route;
      renderMenuItem(
        item,
        mainMenuRoute,
        privateRoute[item]?.icon,
        privateRoute[item]
      );
    }
  } else {
    let mainMenuObj = JSON.parse(localStorage.getItem("back_to_menu"));
    if (mainMenuObj && mainMenuObj.menuUrl) {
      sidebarContent.push(
        <CustomLink
          key="back-to-menu"
          bold
          alignIcon="start"
          size="small"
          withIcon={<ArrowBackIosNewIcon />}
          className="back-to-menu-btn"
          onClick={() => {
            setSelectedItem(mainMenuObj.menuUrl);
            setExpanded(mainMenuObj.expandTab);
            onClickItem && onClickItem(false);
            history.push(mainMenuObj.menuUrl);
          }}
        >
          {`Back to ${mainMenuObj.menuName}`}
        </CustomLink>
      );
    }

    Object.entries(privateRoute)?.forEach(([_rootKey, rootValue]) => {
      if (!_.isEmpty(rootValue?.subMenu)) {
        Object.entries(rootValue.subMenu)?.forEach(([_key, value]) => {
          const subMenuObj = _.find(value.subMenu, {
            route: history.location.pathname,
          });
          if (subMenuObj || history.location.pathname.includes(value.route)) {
            renderAccordianOfSubMenu(rootValue.subMenu);
          } else {
            _.forEach(value.subMenu, (subMenuChild) => {
              if (subMenuChild.subMenu) {
                let item = _.find(subMenuChild.subMenu, {
                  route: history.location.pathname,
                });
                if (item) renderAccordianOfSubMenu(rootValue.subMenu);
              }
            });
          }
        });
      }
    });
  }

  return (
    <React.Fragment>
      <Drawer
        variant="permanent"
        className={`
            ${
              (currentAccount?.account?.closure_requested_at ||
                autoPayoutSchedulingEnabled) &&
              "sidebar-closure-box"
            } sidebar-wrapper`}
        sx={{
          display: { xs: "none", sm: "block" },
        }}
        open
      >
        <Box padding="15px">{sidebarContent}</Box>
      </Drawer>
    </React.Fragment>
  );
};

export default Sidebar;
