import CustomDivider from "./Divider/Divider";
import { Box } from "@mui/material";
import Text from "./Text/Text";
import Translation from "./Translation";
import { feedbackIcon, tickGif } from "./images";
import { excludedCode, roundOffAmount, satsToBtcAmount } from "./constants";
import { useEffect, useState } from "react";
import classNames from "classnames";
import Clipboard from "./Clipboard/Clipboard";
import CommonReportFeedbackModal from "./CommonReportFeedbackModal";
import {
  issueWalletLabel,
  submitFeedback,
  thankyouMsg,
  writeFeedback,
  writeHere,
} from "../messages";

function LinkConfirmView(props) {
  const {
    paymentMessage,
    paymentDetails,
    isPartialPayment,
    paymentInfo,
    showConfirmPreview,
    confirmPageProps,
    boxAmountValue,
    isFromInvoice,
    visibleInPaymentPage,
    activeSection,
  } = props;

  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const commonBoxStyle = {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 0",
  };

  const [showLogo, setShowLogo] = useState({
    style: { opacity: 0, transition: "opacity 1.5s ease" },
  });
  const [showText, setShowText] = useState({
    style: { opacity: 0 },
  });
  const [showDetailsBox, setShowDetailsBox] = useState({
    style: { opacity: 0 },
  });

  useEffect(() => {
    if (showConfirmPreview) {
      setShowLogo({ style: { opacity: 1, transition: "opacity 1.5s ease" } });
      setTimeout(() => {
        setShowText({
          style: { opacity: 1, transition: "opacity 1.5s ease" },
        });
      }, 1000);
      setTimeout(() => {
        setShowDetailsBox({
          style: { opacity: 1, transition: "opacity 1.5s ease" },
        });
      }, 1500);
    } else {
      setShowLogo({ style: { opacity: 1 } });
      setShowText({ style: { opacity: 1 } });
      setShowDetailsBox({ style: { opacity: 1 } });
    }
  }, [showConfirmPreview]);

  const staticMainMessage = confirmPageProps?.successMessage;
  const subTitleMessage = confirmPageProps?.confirmMessage;

  const showTitleText = () => {
    return (
      <Text
        size={20}
        className="margin-top30 confirm-page-title"
        align="center"
        font="semibold"
        variant="subtitle1"
        sx={{ wordBreak: "break-word", ...showText?.style }}
      >
        {isPartialPayment ? (
          <Translation value="PartialPaymentThanksMessage" />
        ) : (
          paymentMessage || <Translation value={staticMainMessage} />
        )}
        {isPartialPayment && paymentDetails && (
          <>
            <br />
            {`${satsToBtcAmount(paymentDetails.target_amount_paid)} BTC`}
          </>
        )}
      </Text>
    );
  };

  const wrapperClass = classNames(
    "confirm-payment-preview",
    props.additionalClass
  );
  const showFiatAmount =
    paymentInfo && !excludedCode.includes(paymentInfo.currency?.code);

  const handleFeedback = () => {
    if (paymentDetails?.status === "paid") setOpenFeedbackModal(true);
  };

  return (
    <Box className={wrapperClass} sx={props.sx || {}}>
      <img
        style={showLogo.style}
        src={tickGif}
        alt="confirm-logo"
        className="circle-icon"
      />
      {showTitleText()}
      {confirmPageProps?.shouldShowSubTitle && (
        <>
          <Box sx={{ marginTop: "14px !important" }}>
            <Text
              size={14}
              sx={{ wordBreak: "break-word" }}
              className="grey-text confirm-page-subtitle"
              align="center"
              font="regular"
              variant="subtitle1"
            >
              <Translation value={subTitleMessage} />
            </Text>
          </Box>
        </>
      )}
      <Box className="grand-payment-box" sx={showDetailsBox.style}>
        <Box sx={commonBoxStyle}>
          <Text
            size={14}
            font="regular"
            variant="subtitle1"
            className="grey-text grand-payment-box-text"
          >
            <Translation value={confirmPageProps?.amountTypeTitle} />
          </Text>
          <Text
            size={14}
            font="semibold"
            variant="subtitle1"
            className="grey-text grand-payment-box-text"
          >
            {isFromInvoice
              ? paymentInfo?.payment?.invoice_number
              : boxAmountValue}
            {showFiatAmount && !isFromInvoice && (
              <Box
                sx={{
                  padding: "0px !important",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <Text
                  size={14}
                  font="semibold"
                  variant="subtitle1"
                  className="grey-text grand-payment-box-text"
                >
                  ≈ {paymentInfo.currency?.symbol}
                  {paymentInfo.amount || paymentDetails?.target_amount_paid
                    ? roundOffAmount(
                        paymentDetails?.target_amount_paid
                          ? paymentDetails.target_amount_paid /
                              paymentInfo.payment?.exchange_rate
                          : paymentInfo.amount,
                        2
                      )
                    : "X.XX"}
                </Text>
              </Box>
            )}
          </Text>
        </Box>
        <CustomDivider />
        <Box sx={commonBoxStyle}>
          <Text
            size={14}
            font="regular"
            variant="subtitle1"
            className="grey-text grand-payment-box-text"
          >
            <Translation value={confirmPageProps?.idTitle} />
          </Text>
          <Text
            size={14}
            font="semibold"
            variant="subtitle1"
            className="grey-text grand-payment-box-text"
          >
            {confirmPageProps?.confirmPageLinkID}
            {!isFromInvoice && (
              <Box
                component="span"
                sx={{
                  padding: "0px !important",
                  position: "relative",
                  top: "2px",
                }}
                onClick={(event) => event.stopPropagation()}
              >
                <Clipboard text={confirmPageProps?.confirmPageLinkID} />
              </Box>
            )}
          </Text>
        </Box>
      </Box>
      {visibleInPaymentPage && (
        <Box
          className="submit-feedback-box"
          component="div"
          onClick={handleFeedback}
        >
          <img
            src={feedbackIcon}
            width={20}
            height={20}
            style={{ marginRight: "8px", marginTop: "1px" }}
            alt="feedback-icon"
          />
          <Text
            size={14}
            font="semibold"
            className="grey-text"
            variant="subtitle1"
            sx={{ mb: "2px" }}
          >
            {submitFeedback}
          </Text>
        </Box>
      )}
      {openFeedbackModal && (
        <CommonReportFeedbackModal
          sourceId={paymentInfo?.payment?.id}
          businessName={paymentInfo?.payment?.business_name}
          targetCurrency={paymentInfo?.payment?.target_currency}
          activeSection={activeSection}
          commentInputLabel={writeFeedback}
          commentInputPlaceholder={writeHere}
          from="feedback"
          modalTitle={submitFeedback}
          openModal={openFeedbackModal}
          setOpenModal={setOpenFeedbackModal}
          successMsg={thankyouMsg}
          visibleInPaymentPage={visibleInPaymentPage}
          walletInputLabel={issueWalletLabel}
        />
      )}
    </Box>
  );
}

export default LinkConfirmView;
