import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import WarningIcon from "@mui/icons-material/Warning";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useFlags } from "launchdarkly-react-client-sdk";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import { CustomTooltip } from "@speed/common/src/components/Tooltip/Tooltip";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import EventResponse from "./EventResponse";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import Button from "@speed/common/src/components/Button/Button";
import {
  clipboardElement,
  generateBreadcrumbs,
  linkStatus,
  redirectWhenEntityNotFound,
  selectTabOnSearch,
  setQueryParamsForTab,
} from "@speed/common/src/components/constants";
import Text from "@speed/common/src/components/Text/Text";
import { callAPIInterface, eventStatus, RouteBreadcrumbs } from "../constants";
import {
  accountLabel,
  activate,
  all,
  apiVersion,
  buttonTextForIdNotFound,
  connect,
  deActivate,
  details,
  entityNotFoundMessage,
  eventsText,
  revealText,
  signingSecret,
  status as statusText,
  subtitleForIdNotFound,
  type,
  webhookActivationMessage,
  webhookDeactivationMessage,
} from "../messages";
import { cancel } from "@speed/common/src/components/messages";
import { KeyValueGrid } from "@speed/common/src/components/KeyValueGrid";
import LinkSkeleton from "@speed/common/src/components/Skeleton/LinkSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";

const WebHookDetails = (props) => {
  const search = useLocation().search;
  const { liveMode } = useSelector((state) => state.auth);
  const history = useSelector((state) => state.common.history);
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const urlParams = useParams();
  const [data, setData] = useState(null);
  const [reveal, setReveal] = useState(false);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("1");
  const {
    weListallendpoint,
    weDisableendpoint,
    weEnableendpoint,
    weRetrieveendpoint,
    weEventattemptsendpointwise,
  } = useFlags();

  const shouldShowDetails = weListallendpoint && weRetrieveendpoint;

  const loadDetailPage = () => {
    setData(null);
    setIsShowNotFound(false);
    getWebhookDetail();
  };

  useEffect(
    () => shouldShowDetails && loadDetailPage(),
    [liveMode, shouldShowDetails]
  );

  const getWebhookDetail = async () => {
    await callAPIInterface("GET", `/webhooks/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setData(res);
        }
      })
      .catch((err) => {
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const headerContent = () => {
    return (
      <Box className="header-content" marginTop={3.25}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TruncatedTextTooltip
            cellWidth="60vw"
            textValue={data?.url}
            textProps={{
              size: 24,
              className: "default-text",
              variant: "h1",
              font: "semibold",
            }}
          />
          <Box display="flex" gap={4}>
            {clipboardElement(data?.id, true, "webhook-id-copy", "", true)}
            {(data?.status === linkStatus.active.value
              ? weDisableendpoint
              : weEnableendpoint) && (
              <MoreHorizIcon
                className="horizontal-dots-icon pointer-cursor"
                tabIndex={0}
                onClick={(event) => {
                  event.stopPropagation();
                  setAnchorEl(anchorEl ? null : event.currentTarget);
                }}
              />
            )}
          </Box>
        </Box>
        <Box className="header-price-content" marginTop={2}>
          <Box className="fiat-price" width="100%">
            <Text
              size={16}
              className="grey-text"
              font="medium"
              variant="subtitle1"
              sx={{ wordBreak: "break-word" }}
            >
              {data?.description}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  };

  const tabData = [
    {
      label: all,
      value: "1",
      tabContent: <EventResponse />,
      tabName: "all",
    },
    {
      label: eventStatus.succeeded.label,
      value: "2",
      tabContent: (
        <EventResponse
          param={eventStatus.succeeded.param}
          tab={eventStatus.succeeded.value}
        />
      ),
      tabName: eventStatus.succeeded.value,
    },
    {
      label: eventStatus.failed.label,
      value: "3",
      tabContent: (
        <EventResponse
          param={eventStatus.failed.param}
          tab={eventStatus.failed.value}
        />
      ),
      tabName: eventStatus.failed.value,
    },
  ];

  useEffect(() => {
    const name = new URLSearchParams(search)?.get("result_type");
    selectTabOnSearch(name || null, tabData, setSelectedTab);
    handleSetQueryParam(name || null);
  }, []);

  const handleChange = (_event, newValue) => {
    setSelectedTab(newValue);
    const selectedTabName = setQueryParamsForTab(tabData, newValue);
    handleSetQueryParam(selectedTabName);
  };

  const handleSetQueryParam = (searchValue) => {
    history.push({
      pathname: `/webhooks/${urlParams.id}`,
      search: searchValue ? `?result_type=${searchValue}` : null,
    });
  };

  const confirmationModalContent = (
    <Box>
      {data?.status === linkStatus.active.value ? (
        <Text
          className="default-text"
          size={16}
          font="regular"
          variant="subtitle1"
        >
          {webhookDeactivationMessage()}
        </Text>
      ) : (
        <Text
          className="default-text"
          size={16}
          font="regular"
          variant="subtitle1"
        >
          {webhookActivationMessage()}
        </Text>
      )}
    </Box>
  );

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const enableDisableWebhook = (method) => {
    return new Promise((resolve, reject) => {
      callAPIInterface("POST", `/webhooks/${data?.id}/${method}`, {})
        .then((response) => {
          resolve(response);
        })
        .catch((_error) => {
          reject(_error);
        });
    });
  };

  const handleActiveDeActive = () => {
    setOpenConfirmationModal(false);
    setData(null);
    const method =
      data?.status === linkStatus.active.value ? "disable" : "enable";
    enableDisableWebhook(method)
      .then((res) => {
        res && loadDetailPage();
      })
      .catch((error) => {
        loadDetailPage();
      });
  };

  const confirmationModalFooter = (
    <>
      <Button
        style={{ width: "70%" }}
        label={`${
          data?.status === linkStatus.active.value ? deActivate : activate
        } endpoint`}
        onClick={handleActiveDeActive}
      />
      <Button
        style={{ width: "calc(30% - 16px)", marginLeft: "16px" }}
        label={cancel}
        variant="outlined"
        onClick={closeConfirmationModal}
      />
    </>
  );

  return (
    <>
      {!isShowNotFound ? (
        <Grid
          pt="18px"
          display="table-cell"
          width="100vw !important"
          container
          spacing={1}
          className="payment-links-wrapper"
        >
          <Box className="section-wrapper " position="relative">
            <Breadcrumbs
              component={Link}
              breadcrumbData={breadcrumbs}
              pl="30px"
              pr="16px"
            />
            {shouldShowDetails ? (
              <>
                {data ? (
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      minHeight="calc(100vh - 106px)"
                    >
                      <Box>
                        <Box pl="30px" pr="16px">
                          {headerContent()}
                          <Box className="details-content" marginTop="65px">
                            <Text size={20} className="default-text">
                              {details}
                            </Text>
                          </Box>
                        </Box>
                        <CustomDivider sx={{ my: "12px", ml: "30px" }} />
                        <Box
                          gap={2}
                          display="flex"
                          flexWrap="wrap"
                          justifyContent="space-between"
                          pl="30px"
                          pr="16px"
                        >
                          <KeyValueGrid
                            keyName={statusText}
                            value={
                              <Tag
                                text={linkStatus[data?.status]?.label}
                                variant={linkStatus[data?.status]?.variant}
                              />
                            }
                          />
                          <KeyValueGrid
                            keyName={apiVersion}
                            value={data?.api_version}
                          />
                          <KeyValueGrid
                            keyName={
                              <span style={{ textTransform: "capitalize" }}>
                                {eventsText[1]}
                              </span>
                            }
                            value={
                              <CustomTooltip
                                arrow={true}
                                placement="bottom"
                                className="custom-tooltip code-content"
                                text={
                                  <Box>
                                    {data?.enabled_events?.map((event) => (
                                      <Box
                                        key={event}
                                        sx={{
                                          fontSize: "14px !important",
                                          color: "#848b9e !important",
                                          lineHeight: "2 !important",
                                        }}
                                      >
                                        {event}
                                      </Box>
                                    ))}
                                  </Box>
                                }
                              >
                                <Box
                                  sx={{
                                    width: "fit-content",
                                    boxShadow: "none",
                                  }}
                                >
                                  <Tag
                                    text={`${data?.enabled_events?.length} ${
                                      data?.enabled_events?.length > 1
                                        ? eventsText[1]
                                        : eventsText[0]
                                    }`}
                                    variant="success-res"
                                  />
                                </Box>
                              </CustomTooltip>
                            }
                          />
                          <KeyValueGrid
                            keyName={signingSecret}
                            value={
                              <>
                                {reveal ? (
                                  <>
                                    {clipboardElement(
                                      data?.secret,
                                      true,
                                      "webhook-id-copy",
                                      "",
                                      true
                                    )}
                                  </>
                                ) : (
                                  <Box sx={{ color: "#2a67ff" }}>
                                    <span
                                      className="pointer-cursor"
                                      onClick={() => setReveal((prev) => !prev)}
                                    >
                                      {revealText}
                                    </span>
                                  </Box>
                                )}
                              </>
                            }
                          />
                          <KeyValueGrid
                            keyName={type}
                            value={data?.connect ? connect : accountLabel}
                          />
                        </Box>
                      </Box>
                      {weEventattemptsendpointwise && (
                        <Box
                          className="margin-top30"
                          height="400px"
                          flexGrow={1}
                          display="flex"
                          flexDirection="column"
                        >
                          <CustomTab
                            onChange={handleChange}
                            tabData={tabData}
                            selectedTab={selectedTab}
                            className="webhook-endpoint-attempts-tab"
                          />
                        </Box>
                      )}
                    </Box>
                  </>
                ) : (
                  <Box pl="30px" pr="16px" mt={3.25}>
                    <LinkSkeleton props={props} />
                  </Box>
                )}
              </>
            ) : (
              <Box pl="30px" pr="16px">
                <AccessDenied />
              </Box>
            )}
          </Box>
          <CustomPopper
            disablePortal={true}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            position="bottom"
            handleClose={() => setAnchorEl(null)}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => setOpenConfirmationModal(true)}>
                  <ListItemText
                    primary={
                      data?.status === linkStatus.active.value
                        ? deActivate
                        : activate
                    }
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </CustomPopper>
          <Modal
            maxWidth="xs"
            body={confirmationModalContent}
            footer={confirmationModalFooter}
            handleClose={() => setOpenConfirmationModal(false)}
            open={openConfirmationModal}
            title={`${
              data?.status === linkStatus.active.value ? deActivate : activate
            } a webhook endpoint`}
          />
        </Grid>
      ) : (
        <Box className="not-found-wrapper">
          <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
          <LinkNotFound
            icon={<WarningIcon className="warning-icon" />}
            title={entityNotFoundMessage("Webhook")}
            subTitle={subtitleForIdNotFound("Webhook endpoint", urlParams.id)}
            btnText={buttonTextForIdNotFound("webhooks", liveMode)}
          />
        </Box>
      )}
    </>
  );
};

export default WebHookDetails;
