import { useSpace } from "@flatfile/react";
import { useDispatch } from "react-redux";
import { blueprint } from "./blueprint";
import { listener } from "./listener";
import {
  setFlatfileCurrencyRates,
  setFlatfileName,
} from "../../../redux/common/actions";
import { ratesForCurrencyCode } from "./constants";
import "../../../assets/styles/flatfile.scss";

const FlatfileSpace = ({ setIsLoadingImport, setShowSpace }) => {
  const dispatch = useDispatch();
  const space = useSpace({
    name: "Speed Space",
    environmentId: process.env.REACT_APP_FLAT_FILE_ENV_ID,
    publishableKey: process.env.REACT_APP_FLAT_FILE_PUB_KEY,
    workbook: blueprint,
    listener,
    sidebarConfig: {
      showSidebar: false,
    },
    closeSpace: {
      operation: "submitActionFg",
      onClose: () => {
        dispatch(setFlatfileName(null));
        dispatch(setFlatfileCurrencyRates(ratesForCurrencyCode));
        setIsLoadingImport(false);
        setShowSpace(false);
      },
    },
  });

  return space;
};

export default FlatfileSpace;
