import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Text from "../Text/Text";
import { depositCurrency } from "../../messages";

const AccountAssets = (props) => {
  const {
    selectedAssetCurrency,
    setSelectedAssetCurrency,
    setSelectedCurrencyPaymentMethods,
    accountAsset,
    payment,
    setToggleFlag,
    updateSelectedCurrencyState,
    setSelectedPaymentMethod,
    assetQrLoader,
    fontStyle,
  } = props;

  useEffect(() => {
    if (accountAsset?.length) {
      // payment?.target_currency is for payment page and accountAsset[0]?.uuid is for app
      const initialSelectedCurrency =
        payment?.target_currency || accountAsset[0]?.uuid;
      setSelectedAssetCurrency(initialSelectedCurrency);
      updateSelectedCurrencyState?.(initialSelectedCurrency);
      handleSetCurrencyMethod(initialSelectedCurrency, payment?.payment_method);
    }
  }, [accountAsset]);

  const handleSetCurrencyMethod = (uuid, method) => {
    const toggleFlagValue =
      props?.webViewProps?.paymentPageSettings?.display_amount_in_currency ===
        "sats" && uuid === "SATS";
    setToggleFlag(toggleFlagValue); // set toggle flag to false when selected currency is other than SATS because BTC amount would be shown only in SATS
    const paymentMethods = accountAsset?.find(
      (asset) => asset?.uuid === uuid
    )?.payment_method_details;

    const enabledMethods = paymentMethods
      ?.sort((a, b) => a.weight - b.weight)
      ?.filter((method) => method?.is_payment_method_enabled);
    setSelectedPaymentMethod(method);
    setSelectedCurrencyPaymentMethods(enabledMethods); //set payment methods according to selected asset
  };

  const handleTabSelect = (uuid) => {
    setSelectedAssetCurrency(uuid);
    handleSetCurrencyMethod(uuid);
    updateSelectedCurrencyState?.(uuid);
  };

  return (
    <Box className="account-asset-wrapper">
      <Text
        size={16}
        font="medium"
        variant="inline"
        className="default-text"
        sx={{ ...fontStyle, cursor: "pointer" }}
        withIcon="start"
      >
        {depositCurrency}
      </Text>
      <Box display="flex" mt="14px" width="100%" flexWrap="wrap">
        {accountAsset?.map((asset, index) => {
          return (
            <Box
              className={`asset-tab-section-preview ${
                selectedAssetCurrency === asset?.uuid && "selected"
              }`}
              sx={{
                marginLeft: index !== 0 ? "10px" : "0px",
              }}
              key={asset?.id}
              onClick={() => !assetQrLoader && handleTabSelect(asset?.uuid)}
            >
              <img src={asset?.icon} alt="asset" height="20px" width="20px" />
              <Text
                size={16}
                font="medium"
                variant="inline"
                className="default-text"
                sx={{ ...fontStyle, cursor: "pointer" }}
                withIcon="start"
              >
                {asset?.uuid}
              </Text>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default AccountAssets;
