import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import history from "@speed/common/src/components/history";
import { redirectWhenEntityNotFound } from "@speed/common/src/components/constants";
import { callAPIInterface } from "../constants";
import { showToast } from "../../redux/common/actions";
import {
  partnerInvitationExpired,
  partnerInvitationForDiffAccount,
  redirectingToURL,
} from "../messages";
import { authenticatingURL } from "@speed/common/src/messages";

const AcceptAffiliatePartnerInvite = () => {
  const { aflGetinvitedlinkFe } = useFlags();
  const ldClient = useLDClient();
  const dispatch = useDispatch();
  const [loadingText, setLoadingText] = useState(authenticatingURL);
  const searchParams = {};
  for (const [key, value] of new URLSearchParams(
    history.location.search?.replace(/\+/g, "%2B")
  ).entries()) {
    searchParams[key] = value;
  }

  const { isLoggedIn, user } = useSelector((state) => state.auth);

  let paramForAPI = "";
  if (searchParams?.reference_id) {
    paramForAPI = `id=${searchParams.reference_id}`;
  } else if (searchParams?.account_id) {
    paramForAPI = `account=${searchParams.account_id}`;
  }

  const getVerifyLink = () => {
    return new Promise((resolve, reject) => {
      callAPIInterface("GET", `/affiliate-partner/invite-link?${paramForAPI}`)
        .then((response) => {
          resolve(response);
        })
        .catch((_error) => {
          reject(_error);
        });
    });
  };

  useEffect(() => {
    const ldUser = {
      key: "anonymous-anonymous",
    };
    ldClient.identify(ldUser);
  }, []);

  useEffect(() => {
    aflGetinvitedlinkFe
      ? getVerifyLink()
          .then((res) => {
            if (res) {
              sessionStorage.setItem(
                "affiliate-partner-invitee",
                JSON.stringify(
                  res.is_default
                    ? { ...res, account_id: searchParams?.account_id }
                    : res
                )
              );
              setLoadingText(redirectingToURL);
              if (isLoggedIn) {
                if (user?.email === res.email || res.is_default) {
                  history.push("/partners/affiliate-partners-connect");
                } else if (user?.email !== res.email) {
                  history.push({
                    pathname: "/dashboard",
                    data: {
                      flag: partnerInvitationForDiffAccount,
                      email: res.email,
                      invited_by_email: res.owner_email,
                      account_name: res.account_name,
                    },
                  });
                }
              } else if (res.is_default) {
                history.push("/partners/affiliate-partners/join");
              } else {
                if (res.is_invitee_exist) {
                  history.push("/login");
                } else {
                  history.push("/register");
                }
              }
            }
          })
          .catch((error) => {
            if (redirectWhenEntityNotFound(error)) {
              dispatch(
                showToast({
                  isToastOpen: false,
                })
              );
              if (isLoggedIn) {
                history.push({
                  pathname: "/dashboard",
                  data: {
                    flag: partnerInvitationExpired,
                  },
                });
              } else if (searchParams?.email && !isLoggedIn) {
                dispatch(
                  showToast({
                    isToastOpen: false,
                  })
                );
                history.push({
                  pathname: "/login",
                  data: {
                    flag: partnerInvitationExpired,
                  },
                });
              }
            }
          })
      : history.push("/dashboard");
  }, []);

  return (
    <BackdropLoader
      open={true}
      alt="Loading..."
      customClass="loading-in-auth"
      text={loadingText}
    />
  );
};

export default AcceptAffiliatePartnerInvite;
