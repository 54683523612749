import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import {
  checkoutPaymentLinkTabLabel,
  emailRecieptTabLabel,
  invoicePaymentPage,
  invoicePdf,
  oneQR,
  previewURL,
  withdrawPreviewURL,
  withdrawalLinkTabLabel,
} from "../../messages";
import CheckoutPaymentLink from "./CheckoutPaymentLink";
import { Box } from "@mui/material";
import EmailReceipt from "./EmailReceipt";
import { useFlags } from "launchdarkly-react-client-sdk";
import OneQRBranding from "./OneQRBranding";
import { withdrawalLinkTab } from "@speed/common/src/components/constants";
import InvoicePdf from "./InvoicePdf";
import InvoicePaymentPage from "./InvoicePaymentPage";

const Branding = () => {
  const [selectedTab, setSelectedTab] = useState("1");
  const history = useSelector((state) => state.common.history);
  const currentAccount = useSelector((state) => state.auth.currentAccount);
  const { bdEmailreceipt, bdOneqr, bdWithdrawlink } = useFlags();

  const tabData = [
    {
      label: checkoutPaymentLinkTabLabel,
      value: "1",
      tabContent: currentAccount && (
        <CheckoutPaymentLink linkType="cl-pl-link" previewURL={previewURL} />
      ),
      route: "/settings/checkout-payment",
    },
  ];

  bdEmailreceipt &&
    tabData.push({
      label: emailRecieptTabLabel,
      value: "2",
      tabContent: currentAccount && <EmailReceipt />,
      route: "/settings/email-receipt",
    });

  bdOneqr &&
    tabData.push({
      label: oneQR,
      value: "3",
      tabContent: currentAccount && <OneQRBranding />,
      route: "/settings/one-qr",
    });

  bdWithdrawlink &&
    tabData.push({
      label: withdrawalLinkTabLabel,
      value: "4",
      tabContent: currentAccount && (
        <CheckoutPaymentLink
          linkType={withdrawalLinkTab}
          previewURL={withdrawPreviewURL}
        />
      ),
      route: "/settings/withdrawal-link",
    });

  tabData.push({
    label: invoicePdf,
    value: "5",
    tabContent: currentAccount && <InvoicePdf />,
    route: "/settings/invoice-pdf",
  });

  tabData.push({
    label: invoicePaymentPage,
    value: "6",
    tabContent: currentAccount && <InvoicePaymentPage />,
    route: "/settings/invoice-payment-page",
  });
  const handleChange = (_event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (history.location.pathname) {
      let currentRoute = _.find(tabData, { route: history.location.pathname });
      if (currentRoute) {
        setSelectedTab(currentRoute.value);
      }
    }
  }, [history && history.location.pathname]);

  return (
    <Box>
      <CustomTab
        sx={{ marginTop: "20px" }}
        onChange={handleChange}
        tabData={tabData}
        selectedTab={selectedTab}
        setTabRoute={(routeUrl) => history.push(routeUrl)}
      />
    </Box>
  );
};

export default Branding;
