import { Box, Grid } from "@mui/material";
import { useFormik } from "formik";
import React, {
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
  createRef,
} from "react";
import * as yup from "yup";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import {
  amountTypeOptions,
  getCustomFieldInfo,
  reformCFArr,
  typeOptions,
} from "../constants";
import {
  amountType,
  shipping,
  options,
  selectType,
  statementDescriptor,
  usdt,
} from "../messages";
import {
  allowCashbackFor,
  amountValidationSchema,
  cashbackValidation,
  checkDisableButton,
  commonStyle,
  currencyValidationSchema,
  getExchangeRate,
  handleClick,
  handleClose,
  showSatsValidate,
  updateCheckoutData,
} from "../Common/AllLinkFormConstant";
import CurrencyAndAmountField from "../Common/CurrencyAndAmountField";
import TIDForm from "../CheckoutLinks/TIDForm";
import {
  excludedCode,
  getAccountName,
  showAmount,
  showAmountExceedError,
} from "@speed/common/src/components/constants";
import { useDispatch, useSelector } from "react-redux";
import CurrencyAutoComplete from "../Common/CurrencyAutoComplete";
import Text from "@speed/common/src/components/Text/Text";
import { useFlags } from "launchdarkly-react-client-sdk";
import PresetAmount from "./PresetAmount";
import AmountOptions from "./AmountOptions";
import ActiveCashbackList from "../Cashback/ActiveCashbackList";
import BillingAddress from "../Common/BillingAddress";
import EmailPhoneNumber from "../Common/EmailPhoneNumber";
import { updatePaymentInfo } from "../../redux/common/actions";
import CustomFieldsForm from "../Common/CustomFieldsForm";
import { SET_CUSTOM_FIELD_UPDATED_FROM } from "../../redux/common/types";

const PaymentLinkForm = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const presetRef = createRef();
  const amountOptionRef = createRef();

  const [exchangeRate, setExchangeRate] = useState(false);
  const [amountExceed, setAmountExceed] = useState("");
  const [satsValidate, setSatsValidate] = useState("");
  const [currentExchangeRate, setCurrentExchangeRate] = useState();
  const [isCheckedOtherInfo, setIsCheckedOtherInfo] = useState(false);
  const [CFDisable, setCFDisable] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [customFieldsDetails, setCustomFieldDetails] = useState([]);

  const currentAccount = useSelector((state) => state.auth.currentAccount);
  const { paymentInfo, selectedAssetCurrencyState } = useSelector(
    (state) => state.common
  );

  const accountName = getAccountName(currentAccount);
  const { linkType } = props;
  const amountRef = useRef(null);
  const open = Boolean(anchorEl);

  const {
    plTitleFe,
    cbListallcashbackFe,
    plAmounttypeselFe,
    plEmailFe,
    plPhoneFe,
    plAddressFe,
    plCustomfieldsFe,
  } = useFlags();

  const commonValidation = yup.string().when(["otherInfo"], {
    is: (otherInfo) => otherInfo,
    then: yup.string().required(""),
    otherwise: yup.string().notRequired(),
  });

  const validationSchema = yup.object({
    currency: currencyValidationSchema,
    amount: amountValidationSchema,
    otherInfo: yup.boolean(""),
    pageTitle: commonValidation,
    customFields: yup.boolean(""),
    ...cashbackValidation,
  });

  const initialValuesObj = {
    currency: { name: "US Dollar", code: "USD", symbol: "$" },
    selectType: { label: "", value: "" },
    pageTitle: "",
    pageDescription: "",
    image: "",
    amount: "",
    otherInfo: false,
    amountType: { label: "Any amount", value: "anyAmount" },
    checkedCashback: false,
    isCheckedBilling: false,
    isCheckedEmail: false,
    isCheckedPhone: false,
    addressType: "",
    customerCollections: {},
    cashback: {},
    customFields: false,
  };

  const formik = useFormik({
    initialValues: initialValuesObj,
    validationSchema: validationSchema,
  });

  const { values, errors, isValid, isSubmitting, dirty, setFieldValue } =
    formik;

  const isAppTypeSelected = values.selectType?.value === "app";
  const isCustomerTypeSelected = values.selectType?.value === "customer";

  const getAmountTypesValue = () => {
    const presetAmount = presetRef.current?.getPresetAmount?.();
    const amountOption = amountOptionRef.current?.getAmountOption?.();

    let valuesObj = values;
    if (amountOption)
      valuesObj = { amountOptions: amountOption.amountOptions, ...values };
    if (presetAmount)
      valuesObj = {
        presetAmountData: presetAmount?.presetAmountData,
        ...values,
      };

    return { valuesObj, presetAmount, amountOption };
  };

  const updateTheCheckoutData = () => {
    const { amountOption, presetAmount, valuesObj } = getAmountTypesValue();

    const enteredAmount =
      presetAmount?.presetAmountRef?.current ||
      amountOption?.amountOptionValue?.current ||
      amountRef.current;

    const exchangeRateValue =
      currentExchangeRate ||
      presetAmount?.currentExchangeRate ||
      amountOption?.currentExchangeRate ||
      enteredAmount?.amount;

    // For custom field
    const customFieldsInfo = getCustomFieldInfo(
      customFieldsDetails,
      paymentInfo?.custom_fields
    );

    valuesObj.isCheckedEmail = values.isCheckedEmail;
    valuesObj.isCheckedPhone = values.isCheckedPhone;
    valuesObj.isCheckedBilling = values.isCheckedBilling;
    valuesObj.addressType = values.addressType;
    valuesObj.customerCollections = values.customerCollections;
    valuesObj.customFieldsDetails = customFieldsInfo;

    updateCheckoutData(
      parseFloat(exchangeRateValue) || 0.0,
      parseFloat(enteredAmount?.amount) || 0.0,
      linkType,
      valuesObj,
      selectedAssetCurrencyState
    );
  };

  const updatePaymentInfoFn = (removeKeys) => {
    if (paymentInfo) {
      const info = { ...paymentInfo };
      removeKeys.forEach((key) => {
        delete info["customer_collections"][key];
      });
      dispatch(updatePaymentInfo(info));
    }
  };

  // For custom field
  useEffect(() => {
    customFieldsDetails.length > 0 &&
      dispatch({ type: SET_CUSTOM_FIELD_UPDATED_FROM, payload: "form-side" });
  }, [customFieldsDetails]);

  useEffect(() => {
    if (!values.isCheckedEmail) {
      updatePaymentInfoFn(["email"]);
    }

    if (!values.isCheckedPhone) {
      updatePaymentInfoFn(["phone"]);
    }

    if (!values.isCheckedBilling) {
      updatePaymentInfoFn(["billing_address", "shipping_address"]);
    }

    updateTheCheckoutData();
  }, [
    values.pageTitle,
    values.pageDescription,
    values.image,
    values.otherInfo,
    values.amountType,
    values.isCheckedEmail,
    values.isCheckedPhone,
    values.isCheckedBilling,
    values.addressType,
    exchangeRate,
    customFieldsDetails,
  ]);

  useEffect(() => {
    const { amountOption, presetAmount, valuesObj } = getAmountTypesValue();
    const amountRefValue =
      presetAmount?.presetAmountRef ||
      amountOption?.amountOptionValue ||
      amountRef;

    const timeOut = setTimeout(() => {
      if (values) {
        getExchangeRate({
          amountRef: amountRefValue,
          values: valuesObj,
          errors,
          setCurrentExchangeRate,
          setExchangeRate,
          linkType,
          targetCurrency: selectedAssetCurrencyState,
        });
      }
    }, 500);
    return () => clearTimeout(timeOut);
  }, [
    values.amount,
    values.currency,
    errors.amount,
    errors.currency,
    isAppTypeSelected && selectedAssetCurrencyState,
  ]);

  const defaultCheck = !(isValid && dirty) || isSubmitting;

  useEffect(() => {
    checkDisableButton({
      amountExceed,
      satsValidate,
      values,
      exchangeRate,
      handleButtonDisable: props.handleButtonDisable,
      from: "pl-form",
      defaultCheck: defaultCheck || CFDisable,
      cbListallcashbackFe,
    });
  }, [
    dirty,
    isValid,
    isSubmitting,
    values,
    exchangeRate,
    isCheckedOtherInfo,
    CFDisable,
  ]);

  useEffect(() => {
    showAmountExceedError(
      values,
      setAmountExceed,
      currentExchangeRate,
      linkType
    );
  }, [values, currentExchangeRate]);

  useEffect(() => {
    showSatsValidate(values, setSatsValidate);
  }, [values]);

  useEffect(() => {
    if (paymentInfo?.customer_collections) {
      setFieldValue("customerCollections", paymentInfo.customer_collections);
    }
  }, [paymentInfo]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      const presetPayload = presetRef.current?.getPresetPayload?.();
      const optionPayload = amountOptionRef.current?.getOptionPayload?.();
      const currencyCode = values.currency.code;
      let params = {
        currency: currencyCode,
        amount:
          values?.selectType?.value === "customer"
            ? 0
            : showAmount({
                amount: values.amount,
                currency: currencyCode,
                divideAmount: currencyCode === usdt,
              }),
        statement_descriptor: statementDescriptor(accountName),
      };
      if (isCheckedOtherInfo) {
        params["title"] = values.pageTitle;
        if (values.pageDescription)
          params["title_description"] = values.pageDescription;
        if (values.image) params["title_image"] = values.image;
      }
      if (!excludedCode.includes(values.currency.code)) {
        params.ttl = 600;
      }
      if (
        cbListallcashbackFe &&
        values?.checkedCashback &&
        values?.cashback &&
        Object.keys(values.cashback)?.length
      ) {
        params.cashback = { id: values.cashback?.id };
      }
      if (values?.amountType?.value === "preset") {
        params = { ...params, ...presetPayload };
      }
      if (values?.amountType?.value === "amountOptions") {
        params = { ...params, ...optionPayload };
      }
      const customerCollectionsStatus = {
        is_email_enabled: values.isCheckedEmail,
        is_phone_enabled: values.isCheckedPhone,
        is_billing_address_enabled: values.isCheckedBilling,
        is_shipping_address_enabled:
          values.isCheckedBilling && values.addressType === shipping,
      };
      const customerCollectionAvailable = Object.values(
        customerCollectionsStatus
      ).includes(true);
      if (customerCollectionAvailable)
        params["customer_collections_status"] = customerCollectionsStatus;
      if (customFieldsDetails.length > 0)
        params["custom_fields"] = reformCFArr(customFieldsDetails);
      return params;
    },
  }));

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      setIsCheckedOtherInfo(!isCheckedOtherInfo);
    }
  };

  const commonProps = {
    ...formik,
    commonStyle,
    handleClick: (e) => handleClick(e, setAnchorEl, anchorEl),
    handleClose: () => handleClose(setAnchorEl),
    open,
    anchorEl,
    handleKeyDown,
  };

  const handleSelectType = (_e, value) => {
    setFieldValue("selectType", value);
    setFieldValue("amount", "");
    setFieldValue("currency", {
      name: "US Dollar",
      code: "USD",
      symbol: "$",
    });
    setFieldValue("amountType", { label: "Any amount", value: "anyAmount" });
  };

  const getComponentProps = () => {
    return {
      formikValues: { ...values, customFieldsDetails },
      linkType: linkType,
      isCheckedOtherInfo: isCheckedOtherInfo,
      handleButtonDisable: props.handleButtonDisable,
      parentDisable: defaultCheck || CFDisable,
      selectedAssetCurrencyState,
    };
  };
  const componentProps = getComponentProps();

  const marginTopValue = plTitleFe ? "margin-top20" : "";

  return (
    <Box
      className="apply-auto-scroll"
      component="form"
      autoComplete="off"
      sx={props.isPaymentLink ? { display: "none" } : { display: "block" }}
    >
      <Box sx={{ width: "480px" }}>
        <Grid marginLeft="2px">
          <CustomAutoComplete
            name="selectType"
            options={typeOptions}
            getOptionLabel={(value) => {
              return `${value.label}`;
            }}
            onChange={handleSelectType}
            value={values.selectType}
            showLabel
            label={selectType}
            sx={{
              marginBottom: "30px",
              ...commonStyle,
            }}
            disableClearable
            fullWidth
            placeholder={selectType}
          />
          {isAppTypeSelected && (
            <CurrencyAndAmountField
              amountExceed={amountExceed}
              amountRef={amountRef}
              errors={errors}
              satsValidate={satsValidate}
              setExchangeRate={setExchangeRate}
              setFieldValue={setFieldValue}
              values={values}
            />
          )}
          {isCustomerTypeSelected && plAmounttypeselFe && (
            <>
              {values.amountType?.value !== "anyAmount" && (
                <CurrencyAutoComplete
                  currencyValue={values.currency}
                  setExchangeRate={setExchangeRate}
                  setFieldValue={setFieldValue}
                  commonStyle={commonStyle}
                />
              )}
              <CustomAutoComplete
                name="amountType"
                options={amountTypeOptions}
                getOptionLabel={(value) => {
                  return `${value.label}`;
                }}
                onChange={(_e, value) => {
                  setFieldValue("amountType", value);
                }}
                value={values.amountType}
                showLabel
                label={amountType}
                sx={{
                  marginTop: "30px",
                  ...commonStyle,
                }}
                disableClearable
                fullWidth
              />
              {values.amountType?.value === "preset" && (
                <PresetAmount {...componentProps} ref={presetRef} />
              )}
              {values.amountType?.value === "amountOptions" && (
                <AmountOptions {...componentProps} ref={amountOptionRef} />
              )}
            </>
          )}
          {(plTitleFe || cbListallcashbackFe) && (
            <Text
              size={18}
              className="default-text"
              variant="subtitle1"
              sx={{ marginBottom: "20px", marginTop: "30px" }}
            >
              {options}
            </Text>
          )}
          <TIDForm
            {...commonProps}
            setIsCheckedOtherInfo={setIsCheckedOtherInfo}
            isCheckedOtherInfo={isCheckedOtherInfo}
            ldKey={plTitleFe}
          />
          {cbListallcashbackFe && allowCashbackFor.includes(linkType) && (
            <Box
              className={isCheckedOtherInfo ? "margin-top30" : marginTopValue}
            >
              <ActiveCashbackList {...commonProps} />
            </Box>
          )}
          {plAddressFe && (
            <BillingAddress
              values={values}
              setFieldValue={setFieldValue}
              handleKeyDown={handleKeyDown}
            />
          )}
          <EmailPhoneNumber
            emailFlag={plEmailFe}
            handleKeyDown={handleKeyDown}
            phoneFlag={plPhoneFe}
            setFieldValue={setFieldValue}
            values={values}
          />
          {plCustomfieldsFe && (
            <CustomFieldsForm
              parentsFormik={{
                values,
                setFieldValue,
              }}
              handleButtonDisable={props.handleButtonDisable}
              parentDisable={defaultCheck}
              setCFDisable={setCFDisable}
              setCustomFieldDetails={setCustomFieldDetails}
            />
          )}
        </Grid>
      </Box>
    </Box>
  );
});

export default PaymentLinkForm;
