import { Box, Grid } from "@mui/material";
import { useFormik } from "formik";
import React, {
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from "react";
import * as yup from "yup";
import {
  shipping,
  checkoutLink,
  statementDescriptor,
  withdrawalLink,
  invalidLabel,
  name,
  usdt,
} from "../messages";
import { useDispatch, useSelector } from "react-redux";
import {
  char8,
  generatePassword,
  getCustomFieldInfo,
  isAlphabet,
  reformCFArr,
} from "../constants";
import {
  excludedCode,
  getAccountName,
  showAmount,
  showAmountExceedError,
} from "@speed/common/src/components/constants";
import WithdrawalForm from "./WithdrawalForm";
import {
  allowCashbackFor,
  cashbackValidation,
  checkDisableButton,
  commonStyle,
  getExchangeRate,
  handleClick,
  handleClose,
  showSatsValidate,
  updateCheckoutData,
} from "../Common/AllLinkFormConstant";
import CurrencyAndAmountField from "../Common/CurrencyAndAmountField";
import {
  amountValidate,
  min8Char,
  validAmount,
} from "@speed/common/src/messages";
import { useFlags } from "launchdarkly-react-client-sdk";
import ActiveCashbackList from "../Cashback/ActiveCashbackList";
import BillingAddress from "../Common/BillingAddress";
import EmailPhoneNumber from "../Common/EmailPhoneNumber";
import CustomFieldsForm from "../Common/CustomFieldsForm";
import { updatePaymentInfo } from "../../redux/common/actions";
import { SET_CUSTOM_FIELD_UPDATED_FROM } from "../../redux/common/types";
import { isEmpty } from "lodash";
import TIDForm from "./TIDForm";
import { invalidEmail } from "@speed/common/src/components/messages";

const CommonLinkForm = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [exchangeRate, setExchangeRate] = useState(false);
  const [amountExceed, setAmountExceed] = useState("");
  const [satsValidate, setSatsValidate] = useState("");
  const [currentExchangeRate, setCurrentExchangeRate] = useState();
  const [isPasswordProtected, setIsPasswordProtected] = useState(false);
  const [sendViaEmail, setSendViaEmail] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [emailAnchorEl, setEmailAnchorEl] = useState(null);
  const [isCheckedOtherInfo, setIsCheckedOtherInfo] = useState(false);
  const [customFieldsDetails, setCustomFieldDetails] = useState([]);
  const [CFDisable, setCFDisable] = useState(false);

  const { paymentInfo, selectedAssetCurrencyState } = useSelector(
    (state) => state.common
  );

  const currentAccount = useSelector((state) => state.auth.currentAccount);

  const { clEmailFe, clPhoneFe, clAddressFe, clCustomfieldsFe, clTitleFe } =
    useFlags();

  const accountName = getAccountName(currentAccount);
  const { linkType, isFromWithdrawalLink } = props;
  const amountRef = useRef(null);

  const passwordValidation = isPasswordProtected && {
    password: yup.string().matches(char8, min8Char).required(""),
  };

  const emailValidation = sendViaEmail && {
    email: yup.string().email(invalidEmail).required(""),
    name: yup.string().matches(isAlphabet, invalidLabel(name)).trim(),
  };
  const commonValidation = yup.string().when(["otherInfo"], {
    is: (otherInfo) => otherInfo,
    then: yup.string().required(""),
    otherwise: yup.string().notRequired(),
  });

  const validationSchema = yup.object({
    currency: yup.mixed().required(),
    amount: yup
      .number()
      .typeError(validAmount)
      .moreThan(0, amountValidate)
      .required(""),
    ...passwordValidation,
    ...emailValidation,
    ...cashbackValidation,
    customFields: yup.boolean(""),
    otherInfo: yup.boolean(""),
    pageTitle: commonValidation,
  });

  const initialValuesObj = {
    currency: { name: "US Dollar", code: "USD", symbol: "$" },
    amount: "",
    password: "",
    email: "",
    name: "",
    checkedCashback: false,
    cashback: {},
    isCheckedEmail: false,
    isCheckedBilling: false,
    isCheckedPhone: false,
    addressType: "",
    customerCollections: {},
    customFields: false,
  };

  const formik = useFormik({
    initialValues:
      linkType === "checkout link"
        ? {
            ...initialValuesObj,
            otherInfo: false,
            pageTitle: "",
            pageDescription: "",
            image: "",
          }
        : initialValuesObj,
    validationSchema: validationSchema,
  });

  const { values, errors, isValid, isSubmitting, dirty, setFieldValue } =
    formik;

  const updatePaymentInfoFn = (removeKeys) => {
    if (paymentInfo) {
      const info = { ...paymentInfo };
      removeKeys.forEach((key) => {
        delete info["customer_collections"][key];
      });
      dispatch(updatePaymentInfo(info));
    }
  };

  // For custom field
  useEffect(() => {
    customFieldsDetails.length > 0 &&
      dispatch({ type: SET_CUSTOM_FIELD_UPDATED_FROM, payload: "form-side" });
  }, [customFieldsDetails]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (values) {
        getExchangeRate({
          amountRef,
          values,
          errors,
          setCurrentExchangeRate,
          setExchangeRate,
          linkType,
          targetCurrency:
            linkType === "checkout link" ? selectedAssetCurrencyState : "SATS",
        });
      }
    }, 500);
    return () => clearTimeout(timeOut);
  }, [
    values.amount,
    values.currency,
    errors.amount,
    errors.currency,
    selectedAssetCurrencyState,
  ]);

  const updateTheCheckoutData = () => {
    // For custom field
    const customFieldsInfo = getCustomFieldInfo(
      customFieldsDetails,
      paymentInfo?.custom_fields
    );

    const enteredAmount = amountRef.current;
    const exchangeRateValue = currentExchangeRate || enteredAmount?.amount;
    values.customFieldsDetails = customFieldsInfo;

    updateCheckoutData(
      parseFloat(exchangeRateValue) || 0.0,
      parseFloat(enteredAmount?.amount) || 0.0,
      linkType,
      values,
      linkType === "checkout link" ? selectedAssetCurrencyState : "SATS"
    );
  };

  useEffect(() => {
    if (!values.isCheckedEmail) {
      updatePaymentInfoFn(["email"]);
    }
    if (!values.isCheckedPhone) {
      updatePaymentInfoFn(["phone"]);
    }
    if (!values.isCheckedBilling) {
      updatePaymentInfoFn(["billing_address", "shipping_address"]);
    }
    updateTheCheckoutData();
  }, [
    values.isCheckedEmail,
    values.isCheckedPhone,
    values.isCheckedBilling,
    values.addressType,
    exchangeRate,
    customFieldsDetails,
    values.pageTitle,
    values.pageDescription,
    values.image,
    values.otherInfo,
  ]);

  const defaultCheck = !(isValid && dirty) || isSubmitting;
  const sendViaEmailError = sendViaEmail && !values.email;

  useEffect(() => {
    checkDisableButton({
      amountExceed,
      satsValidate,
      values,
      exchangeRate,
      handleButtonDisable: props.handleButtonDisable,
      from: "common-form",
      defaultCheck: defaultCheck || CFDisable || sendViaEmailError,
    });
  }, [
    dirty,
    isValid,
    isSubmitting,
    values,
    exchangeRate,
    CFDisable,
    isCheckedOtherInfo,
    sendViaEmail,
  ]);

  useEffect(() => {
    showAmountExceedError(
      values,
      setAmountExceed,
      currentExchangeRate,
      linkType
    );
  }, [values, currentExchangeRate]);

  useEffect(() => {
    if (!isPasswordProtected) {
      setFieldValue("password", "");
    }
  }, [isPasswordProtected]);

  useEffect(() => {
    if (!sendViaEmail) {
      setFieldValue("email", "");
      setFieldValue("name", "");
    }
  }, [sendViaEmail]);

  useEffect(() => {
    showSatsValidate(values, setSatsValidate);
  }, [values]);

  useEffect(() => {
    if (!isEmpty(paymentInfo?.customer_collections)) {
      setFieldValue("customerCollections", paymentInfo.customer_collections);
    }
  }, [paymentInfo?.customer_collections]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      const currencyCode = values.currency.code;
      const params = {
        currency: currencyCode,
        amount: showAmount({
          amount: values.amount,
          currency: currencyCode,
          divideAmount: currencyCode === usdt,
        }),
        statement_descriptor: statementDescriptor(
          accountName,
          isFromWithdrawalLink
        ),
      };
      if (isCheckedOtherInfo) {
        params["title"] = values.pageTitle;
        if (values.pageDescription)
          params["title_description"] = values.pageDescription;
        if (values.image) params["title_image"] = values.image;
      }
      if (!excludedCode.includes(values.currency.code)) {
        params.ttl = 600;
      }
      if (values.password) {
        params["password"] = values.password;
      }
      if (values.email) {
        params["email"] = values.email;
      }
      if (values.name) {
        params["name"] = values.name;
      }

      if (
        values?.checkedCashback &&
        values?.cashback &&
        Object.keys(values.cashback)?.length
      ) {
        params.cashback = { id: values.cashback?.id };
      }
      const customerCollectionsStatus = {
        is_email_enabled: values.isCheckedEmail,
        is_phone_enabled: values.isCheckedPhone,
        is_billing_address_enabled: values.isCheckedBilling,
        is_shipping_address_enabled:
          values.isCheckedBilling && values.addressType === shipping,
      };
      const customerCollectionAvailable = Object.values(
        customerCollectionsStatus
      ).includes(true);
      if (customerCollectionAvailable)
        params["customer_collections_status"] = customerCollectionsStatus;
      if (customFieldsDetails.length > 0)
        params["custom_fields"] = reformCFArr(customFieldsDetails);
      return params;
    },
  }));

  const handleChangePasswordProtected = (value) => {
    setFieldValue("password", value);
  };

  const handleOnChange = (type) => {
    if (type === "password") {
      setIsPasswordProtected(!isPasswordProtected);
      setFieldValue("password", generatePassword());
    } else {
      setSendViaEmail(!sendViaEmail);
    }
  };

  const open = Boolean(anchorEl);
  const openEmailInfo = Boolean(emailAnchorEl);
  const linkTypeArr = ["checkout link", withdrawalLink];
  const checkLinkType = linkTypeArr.includes(linkType);

  const commonProps = {
    ...formik,
    linkType,
    commonStyle,
    handleClick: (e, type) =>
      handleClick(
        e,
        type === "email" ? setEmailAnchorEl : setAnchorEl,
        type === "email" ? emailAnchorEl : anchorEl
      ),
    handleClose: (type) =>
      handleClose(type === "email" ? setEmailAnchorEl : setAnchorEl),
    open,
    anchorEl,
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      setIsCheckedOtherInfo(!isCheckedOtherInfo);
    }
  };

  return (
    <Box
      className="apply-auto-scroll"
      component="form"
      autoComplete="off"
      sx={props.isPaymentLink ? { display: "none" } : { display: "block" }}
    >
      <Box sx={{ width: "480px" }}>
        <Grid marginLeft="2px">
          {checkLinkType && (
            <>
              <CurrencyAndAmountField
                amountExceed={amountExceed}
                amountRef={amountRef}
                errors={errors}
                satsValidate={satsValidate}
                setExchangeRate={setExchangeRate}
                setFieldValue={setFieldValue}
                values={values}
              />
              <WithdrawalForm
                {...commonProps}
                isPasswordProtected={isPasswordProtected}
                sendViaEmail={sendViaEmail}
                openEmailInfo={openEmailInfo}
                emailAnchorEl={emailAnchorEl}
                handleOnChange={handleOnChange}
                handleChangePasswordProtected={handleChangePasswordProtected}
              />
            </>
          )}
          {linkType === checkoutLink.toLowerCase() && (
            <TIDForm
              {...commonProps}
              setIsCheckedOtherInfo={setIsCheckedOtherInfo}
              isCheckedOtherInfo={isCheckedOtherInfo}
              ldKey={clTitleFe}
            />
          )}
          {allowCashbackFor.includes(linkType) && (
            <Box
              className={isCheckedOtherInfo ? "margin-top30" : "margin-top20"}
            >
              <ActiveCashbackList {...commonProps} />
            </Box>
          )}
          {clAddressFe && linkType === checkoutLink.toLowerCase() && (
            <BillingAddress
              values={values}
              setFieldValue={setFieldValue}
              handleKeyDown={handleKeyDown}
            />
          )}
          {linkType === checkoutLink.toLowerCase() && (
            <EmailPhoneNumber
              emailFlag={clEmailFe}
              handleKeyDown={handleKeyDown}
              phoneFlag={clPhoneFe}
              setFieldValue={setFieldValue}
              values={values}
            />
          )}
          {linkType === "checkout link" && clCustomfieldsFe && (
            <CustomFieldsForm
              parentsFormik={{
                values,
                setFieldValue,
              }}
              handleButtonDisable={props.handleButtonDisable}
              parentDisable={defaultCheck}
              setCFDisable={setCFDisable}
              setCustomFieldDetails={setCustomFieldDetails}
            />
          )}
        </Grid>
      </Box>
    </Box>
  );
});

export default CommonLinkForm;
