import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import {
  RouteBreadcrumbs,
  callAPIInterface,
  activateDeactivateSession,
  updateMetadata,
  moduleMetaDataRows,
  getCashbackDetails,
  getTypeOfCashback,
  detailPageCFRender,
  renderStatus,
  dateTimeFormatInApp,
} from "../constants";
import {
  clipboardElement,
  linkStatus,
  getCurrencyObj,
  redirectWhenEntityNotFound,
  getAmountByCurrency,
  generateBreadcrumbs,
  satsToBtcAmount,
} from "@speed/common/src/components/constants";
import { Grid, TableCell, TableRow } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import {
  deActivate,
  entityNotFoundMessage,
  events as eventsText,
  metaDataText,
  edit,
  add,
  cashbackLabel,
  cashbackIDLabel,
  billingAndShipping,
  billingText,
  yes,
  detailCustomFieldLabel,
  pageDescLabel,
  imageLabel,
  session,
  status,
  dateCreated,
  collectAddresses,
  collectEmailAddress,
  collectPhoneNumber,
  payment,
} from "../messages";
import { useSelector } from "react-redux";
import "../../assets/styles/payment-links.scss";
import Button from "@speed/common/src/components/Button/Button";
import WarningIcon from "@mui/icons-material/Warning";
import { events } from "../Events/Events";
import { useFlags } from "launchdarkly-react-client-sdk";
import MetaDataForm from "../MetaDataForm";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import DetailPagePaymentSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import LinkSkeleton from "@speed/common/src/components/Skeleton/LinkSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";
import { pageTitleLabel } from "@speed/common/src/messages";
import PaymentDetailTitle from "@speed/common/src/components/PaymentDetailTitle";
import PaymentDetailDescription from "@speed/common/src/components/PaymentDetailDescription";
import AccessDenied from "@speed/common/src/components/AccessDenied";

const sessionPaymentColumnData = [
  { title: "Payment ID", width: "480px" },
  { title: "Amount (BTC)", width: "135px" },
  { title: "Date", width: "230px" },
  { title: "Status", width: "120px" },
];

const SessionsDetails = (props) => {
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const history = useSelector((state) => state.common.history);
  const liveMode = useSelector((state) => state.auth.liveMode);

  const [isSessionActive, setIsSessionActive] = useState(null);
  const [data, setData] = useState(null);
  const [sessionEventsList, setSessionEventsList] = useState([]);
  const [sessionPaymentList, setSessionPaymentList] = useState([]);
  const [sessionPaymentSkeleton, setSessionPaymentSkeleton] = useState(false);
  const [sessionEventSkeleton, setSessionEventSkeleton] = useState(true);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [sessionMetaDataSkeleton, setSessionMetaDataSkeleton] = useState(true);
  const [isMetaDataEditable, setIsMetaDataEditable] = useState(false);
  const [sessionMetaDataList, setSessionMetaDataList] = useState([]);
  const [cashbackData, setCashbackData] = useState([]);
  const [sessionCashbackLoader, setSessionCashbackLoader] = useState(false);
  const [disableActionButton, setDisableActionButton] = useState(false);

  const urlParams = useParams();
  const { csEventFe, csDetailFe } = useFlags();

  const loadSessionDetailPage = () => {
    setData(null);
    setIsShowNotFound(false);
    getSessionDetail();
  };

  useEffect(() => {
    csDetailFe && loadSessionDetailPage();
  }, [liveMode, urlParams?.id, csDetailFe, csEventFe]);

  useEffect(() => {
    if (data?.metadata) {
      const convertedMetaDataToArray = Object.entries(data?.metadata)?.map(
        (e) => ({
          key: e[0],
          value: e[1],
        })
      );
      setSessionMetaDataList(convertedMetaDataToArray);
      setSessionMetaDataSkeleton(false);
    }
  }, [data?.metadata]);

  const getSessionPaymentList = () => {
    callAPIInterface("GET", `/checkout-sessions/${urlParams?.id}/payments`)
      .then((res) => {
        if (res) {
          setSessionPaymentSkeleton(false);
          setSessionPaymentList(res?.data);
        }
      })
      .catch(() => {
        setSessionPaymentSkeleton(false);
      });
  };

  const getSessionEventsList = () => {
    setSessionEventSkeleton(true);
    const filterEvents = {
      ids: [urlParams?.id],
    };
    callAPIInterface("POST", "/events/filters", JSON.stringify(filterEvents))
      .then((res) => {
        if (res) {
          setSessionEventSkeleton(false);
          setSessionEventsList(res?.data);
        }
      })
      .catch(() => {
        setSessionEventSkeleton(false);
      });
  };

  const getSessionCashbackData = (cashbackId) => {
    setSessionCashbackLoader(true);
    getCashbackDetails(cashbackId)
      .then((res) => {
        setCashbackData(res);
        setSessionCashbackLoader(false);
      })
      .catch(() => {
        setSessionCashbackLoader(false);
      });
  };

  const getSessionDetail = () => {
    setSessionMetaDataSkeleton(true);
    setSessionPaymentSkeleton(true);
    callAPIInterface("GET", `/checkout-sessions/${urlParams.id}`)
      .then((res) => {
        if (res) {
          if (res?.cashback?.id) {
            getSessionCashbackData(res.cashback.id);
          }

          csEventFe && getSessionEventsList();
          getSessionPaymentList();
          setData(res);
          setIsSessionActive(res?.status === "active" || false);
        }
      })
      .catch((err) => {
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const handleDeActive = () => {
    setDisableActionButton(true);
    activateDeactivateSession(urlParams.id).then((res) => {
      if (res) {
        setDisableActionButton(false);
        loadSessionDetailPage();
      }
    });
  };

  const detailsTableLeftSideData = () => {
    const { title, title_description } = data;
    const arr = [
      {
        header: status,
        cell: (
          <Tag
            text={linkStatus[data?.status].label}
            variant={linkStatus[data?.status].variant}
          />
        ),
      },
      {
        header: dateCreated,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {dateTimeFormatInApp(data?.created)}
          </Text>
        ),
      },
    ];

    (data?.customer_collections_status?.is_shipping_address_enabled ||
      data?.customer_collections_status?.is_billing_address_enabled) &&
      arr.push({
        header: collectAddresses,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <Text
            size={16}
            className="default-text"
            variant="subtitle1"
            font="regular"
          >
            {data?.customer_collections_status?.is_shipping_address_enabled
              ? billingAndShipping
              : billingText}
          </Text>
        ),
      });

    title &&
      arr.push({
        header: pageTitleLabel,
        headerStyle: { verticalAlign: "top" },
        cell: <PaymentDetailTitle pageTitle={title} fromDetails={true} />,
      });

    title_description &&
      arr.push({
        header: pageDescLabel,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <PaymentDetailDescription
            pageDescription={title_description}
            isFromDetail={true}
            boxStyle={{ maxHeight: "120px !important" }}
          />
        ),
      });

    data?.customer_collections_status?.is_email_enabled &&
      arr.push({
        header: collectEmailAddress,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <Text
            size={16}
            className="default-text"
            variant="subtitle1"
            font="regular"
          >
            {yes}
          </Text>
        ),
      });

    data?.customer_collections_status?.is_phone_enabled &&
      arr.push({
        header: collectPhoneNumber,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <Text
            size={16}
            className="default-text"
            variant="subtitle1"
            font="regular"
          >
            {yes}
          </Text>
        ),
      });

    // custom field details
    data?.custom_fields?.length > 0 &&
      arr.push({
        header: detailCustomFieldLabel,
        headerStyle: { verticalAlign: "top" },
        cell: detailPageCFRender(data?.custom_fields),
      });

    return arr;
  };

  const detailsTableRightSideData = () => {
    let arr = [];
    if (data?.cashback?.id) {
      const typeOfCashback = getTypeOfCashback(cashbackData);
      arr.push(
        {
          header: cashbackIDLabel,
          cell: clipboardElement(data.cashback.id, true, "", "text", true),
        },
        {
          header: cashbackLabel,
          cell:
            !sessionCashbackLoader && cashbackData?.name ? (
              <TruncatedTextTooltip
                textValue={`${cashbackData.name} - (${typeOfCashback})`}
                cellWidth="300px"
                textProps={{ className: "default-text" }}
              />
            ) : (
              <CommonSkeleton width="100%" sx={{ borderRadius: "8px" }} />
            ),
        }
      );
    }

    data?.title_image &&
      arr.push({
        header: imageLabel,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <img
            src={data.title_image}
            className="pl-preview-image link-detail"
            alt="page-image"
          />
        ),
      });
    return arr;
  };

  const headerContent = () => {
    return (
      <Box className="header-content">
        <Text className="default-text" size={28}>
          {session}
        </Text>
        <Box className="header-price-content">
          <Box className="fiat-price">
            <Text className="default-text" size={20}>
              {getCurrencyObj(data.currency).symbol}
              {getAmountByCurrency(data.amount, data.currency)}
            </Text>
          </Box>
        </Box>

        <Box sx={{ marginTop: "15px" }}>
          {clipboardElement(
            data?.default_url,
            isSessionActive,
            "branding-detail",
            "",
            true
          )}
        </Box>
      </Box>
    );
  };

  const paymentsRows = sessionPaymentList?.map((rowItem) => (
    <TableRow
      key={rowItem.id}
      className="clickable"
      onClick={() => history.push(`/payments/${rowItem?.id}`)}
    >
      <TableCell>
        {clipboardElement(rowItem?.id, true, "branding-detail", "text", true)}
      </TableCell>
      <TableCell align="center">
        {satsToBtcAmount(rowItem.target_amount_paid)}
      </TableCell>
      <TableCell align="left">
        {dateTimeFormatInApp(rowItem?.created)}
      </TableCell>
      <TableCell align="left">{renderStatus("paid")}</TableCell>
    </TableRow>
  ));

  const sessionEventRows = sessionEventsList?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/events/${rowItem?.id}`)}
      >
        <TableCell>{events(rowItem)}</TableCell>
        <TableCell align="right" sx={{ color: "#848b9e" }}>
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
      </TableRow>
    );
  });

  const onSubmit = (payload) => {
    setSessionMetaDataSkeleton(true);
    const finalPayload = { metadata: payload };
    updateMetadata(finalPayload, `/checkout-sessions/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setData(res);
        }
        setSessionMetaDataSkeleton(false);
        setIsMetaDataEditable(false);
      })
      .catch(() => {
        setSessionMetaDataSkeleton(false);
        setIsMetaDataEditable(false);
      });
  };

  const onCancel = () => setIsMetaDataEditable(false);

  const renderSessionDetailContent = () => {
    const verticalTableData = {
      rowData: detailsTableLeftSideData(),
      rightSideRowData: detailsTableRightSideData(),
    };

    return (
      <Box className="details-content" marginTop="60px">
        <VerticalTable label="Details" {...verticalTableData} />
      </Box>
    );
  };

  const renderActivateDeActivateButton = () => {
    if (isSessionActive) {
      return (
        <Button
          icon="closeIcon"
          label={deActivate}
          variant="outlined"
          color="error"
          onClick={handleDeActive}
          disabled={disableActionButton}
        />
      );
    }
  };

  const renderSessionPaymentData = () =>
    !sessionPaymentSkeleton ? (
      <Box className="product-content">
        <HorizontalTable
          label={payment}
          isColumnShown={paymentsRows.length > 0}
          isShowButton={paymentsRows.length > 3}
          columns={sessionPaymentColumnData}
          rows={paymentsRows}
          rowsPerPage={3}
          isShowPagination={false}
          tableBodyClassName="border-none"
          handleViewAllClick={() => {
            history.push(`/sessions/${data?.id}/payments`);
          }}
        />
      </Box>
    ) : (
      <DetailPagePaymentSkeleton />
    );

  const renderSessionMetaData = () =>
    !sessionMetaDataSkeleton ? (
      <Box className="product-content meta-data-content">
        {isMetaDataEditable ? (
          <MetaDataForm
            metaDataList={sessionMetaDataList}
            setIsMetaDataEditable={setIsMetaDataEditable}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        ) : (
          <HorizontalTable
            label={metaDataText}
            rows={moduleMetaDataRows(sessionMetaDataList)}
            rowsPerPage={51}
            isColumnShown={moduleMetaDataRows(sessionMetaDataList)?.length > 0}
            tableBodyClassName="border-none"
            isShowButton={!isMetaDataEditable && isSessionActive}
            displayShowButton={true}
            viewAllButtonLabel={sessionMetaDataList?.length > 0 ? edit : add}
            viewAllButtonIcon="editIcon"
            handleViewAllClick={() => setIsMetaDataEditable(true)}
          />
        )}
      </Box>
    ) : (
      <DetailPagePaymentSkeleton />
    );

  const renderSessionEventData = () =>
    csEventFe && (
      <>
        {!sessionEventSkeleton ? (
          <Box className="product-content">
            <HorizontalTable
              label={eventsText}
              isColumnShown={sessionEventRows?.length > 0}
              rows={sessionEventRows}
              rowsPerPage={3}
              handleViewAllClick={() => {
                history.push(`/sessions/${urlParams?.id}/cs-events`);
              }}
              isShowButton={sessionEventRows?.length > 3}
              tableBodyClassName="border-none"
            />
          </Box>
        ) : (
          <DetailPagePaymentSkeleton />
        )}
      </>
    );

  return !isShowNotFound ? (
    <Grid className="payment-link-detail-wrapper" container spacing={1}>
      <Box className="section-wrapper" sx={{ position: "relative" }}>
        {data && (
          <Box
            className="action-btn-wrapper"
            style={{ top: "23px", right: "2px" }}
          >
            {renderActivateDeActivateButton()}
          </Box>
        )}
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        {csDetailFe ? (
          <>
            {data ? (
              <>
                {headerContent()}
                {renderSessionDetailContent()}
              </>
            ) : (
              <LinkSkeleton props={props} />
            )}
            {renderSessionPaymentData()}
            {renderSessionMetaData()}
            {renderSessionEventData()}
          </>
        ) : (
          <AccessDenied />
        )}
      </Box>
    </Grid>
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("Session")}
        subTitle={`Session ${urlParams.id} is not found.`}
        btnText={`View ${(liveMode && "live") || "test"} mode sessions`}
      />
    </Box>
  );
};

export default SessionsDetails;
