import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import { currency } from "@speed/common/src/components/currency";
import React from "react";
import { currencyLabel } from "../messages";
import { renderCurrencyOption } from "../constants";

const CurrencyAutoComplete = ({
  setExchangeRate,
  handleOnchange,
  setFieldValue,
  currencyValue,
  commonStyle = {},
  placeholder = "",
  isDisabled = false,
}) => {
  return (
    <CustomAutoComplete
      name="currency"
      options={currency}
      getOptionLabel={(value) => {
        return `${value.code}`;
      }}
      onChange={(_e, value) => {
        setExchangeRate?.(true);
        handleOnchange?.(value);
        setFieldValue("currency", value);
      }}
      value={currencyValue}
      showLabel
      label={currencyLabel}
      sx={commonStyle}
      disableClearable
      fullWidth
      placeholder={placeholder}
      renderOption={renderCurrencyOption}
      disabled={isDisabled}
    />
  );
};

export default CurrencyAutoComplete;
