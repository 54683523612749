import React, { createRef, useState, useEffect } from "react";
import { AppBar, Box, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import Button from "@speed/common/src/components/Button/Button";
import {
  createCashbackMsg,
  createNew,
  testModeTextForCashback,
  testModeTitle,
} from "../messages";
import CloseIcon from "@mui/icons-material/Close";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import CashbackTable from "../Common/CashbackTable";
import { useFlags } from "launchdarkly-react-client-sdk";
import TestModeHeader from "../Payments/TestModeHeader";
import { useSelector, useDispatch } from "react-redux";
import Text from "@speed/common/src/components/Text/Text";
import CreateCashback from "./CreateCashback";
import { sessionService } from "redux-react-session";
import {
  callAPIInterface,
  idealTimeLimitReached,
  setShowCrucialActionModal,
  updateSessionLastActionTime,
} from "../constants";
import {
  setExecuteAPICall,
  setIsModalOpen,
  setLoading,
  setSettingFormSubmit,
} from "../../redux/common/actions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CashbackList = (props) => {
  const ref = createRef();
  const [session, setSession] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const { liveMode, user } = useSelector((state) => state.auth);

  const {
    history,
    executeAPICall,
    settingFormSubmit,
    isDataAvailable,
    isModalOpen,
    caughtErrorInStrapi,
  } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const { cbCreatecashbackFe, cbListallcashbackFe } = useFlags();

  const handleModalClose = () => {
    dispatch(setIsModalOpen(false));
  };

  const resetActions = () => {
    setDisabled(false);
    dispatch(setLoading(false));
    setShowCrucialActionModal(user, false);
    dispatch(setSettingFormSubmit(false));
  };

  const createNewCashback = () => {
    // return the values of form while submitting the form
    const cashbackFormData = ref?.current?.handleSubmit();
    if (cashbackFormData) {
      setDisabled(true);
      callAPIInterface("POST", "/cashbacks", JSON.stringify(cashbackFormData))
        .then((response) => {
          response && history.push(`/cashbacks/${response.id}`);
          dispatch(setIsModalOpen(false));
          resetActions();
        })
        .catch(() => {
          resetActions();
        });
    }
  };
  const handleOpenCashbackModal = () => {
    dispatch(setIsModalOpen(true));
  };

  const handleCreateCashback = () => {
    dispatch(setSettingFormSubmit(true));
  };

  useEffect(() => {
    if (history) {
      const openCreateCashbackModal =
        history?.location?.state?.openCreateCashbackModal;
      if (openCreateCashbackModal) {
        handleOpenCashbackModal();
      }
    }
  }, [history]);

  useEffect(() => {
    sessionService.loadSession().then((newSession) => {
      setSession(newSession);
    });
  }, []);

  useEffect(() => {
    if (executeAPICall) {
      const newSession = updateSessionLastActionTime();
      setSession(newSession);
      createNewCashback();
      dispatch(setExecuteAPICall(false));
    }
  }, [executeAPICall]);

  useEffect(() => {
    if (settingFormSubmit) {
      if (idealTimeLimitReached(session?.last_action_time)) {
        dispatch(setSettingFormSubmit(false));
        setShowCrucialActionModal(user, true);
      } else {
        createNewCashback();
      }
    }
  }, [settingFormSubmit]);

  const createCashbackButton = () => {
    return (
      cbCreatecashbackFe && (
        <Box className="action-btn-wrapper mui-fixed">
          <Button
            icon="addIcon"
            className="add-icon"
            label={createNew}
            variant="outlined"
            color="primary"
            onClick={handleOpenCashbackModal}
          />
        </Box>
      )
    );
  };

  return (
    <>
      {(isDataAvailable ||
        (!isDataAvailable && props.tab) ||
        caughtErrorInStrapi) &&
        createCashbackButton()}

      {cbListallcashbackFe ? (
        <CashbackTable tab={props.tab} />
      ) : (
        <AccessDenied />
      )}

      <Dialog
        disableEscapeKeyDown
        fullScreen
        open={isModalOpen}
        TransitionComponent={Transition}
        className="fullscreen-modal"
      >
        {!liveMode && (
          <TestModeHeader
            buttonText={testModeTitle}
            captionText={testModeTextForCashback}
          />
        )}
        <AppBar sx={{ position: "relative" }} className="modal-app-bar">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleModalClose();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Text
              size={20}
              font="semibold"
              sx={{ flex: 1 }}
              className="default-text divider"
              variant="h6"
            >
              {createCashbackMsg}
            </Text>

            <Button
              label={createCashbackMsg}
              variant="contained"
              color="primary"
              className="payment-link-btn"
              onClick={handleCreateCashback}
              disabled={disabled}
            />
          </Toolbar>
        </AppBar>
        <Box className="fullscreen-modal-box">
          <CreateCashback
            ref={ref}
            handleButtonDisable={(value) => setDisabled(value)}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default CashbackList;
