import React from "react";
import { Box } from "@mui/material";
import { commonStyle } from "./AllLinkFormConstant";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { amount } from "@speed/common/src/components/messages";
import InputMask from "@speed/common/src/components/MaskInput";
import CurrencyAutoComplete from "./CurrencyAutoComplete";
import { validationCheck } from "@speed/common/src/components/constants";

const CurrencyAndAmountField = ({
  setExchangeRate,
  setFieldValue,
  amountRef,
  errors,
  satsValidate,
  amountExceed,
  values,
}) => {
  const errorCheck = validationCheck(
    "amount",
    satsValidate,
    amountExceed,
    errors
  );

  return (
    <>
      <CurrencyAutoComplete
        currencyValue={values.currency}
        setExchangeRate={setExchangeRate}
        setFieldValue={setFieldValue}
        commonStyle={commonStyle}
      />
      <Box
        className="margin-top30"
        sx={{ marginBottom: "30px", ...commonStyle }}
      >
        <InputMask
          label={amount}
          onChange={(e) => {
            setExchangeRate(true);
            amountRef.current.amount = e.target.value;
            setFieldValue("amount", e.target.value);
          }}
          ref={amountRef}
          value={values.amount}
          error={errorCheck}
        />
        {errorCheck && (
          <AlertMessage
            message={errors.amount || satsValidate || amountExceed}
            className="margin-top15"
            severity="error"
          />
        )}
      </Box>
    </>
  );
};

export default CurrencyAndAmountField;
