import React, { useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import { Box, Skeleton, TableCell } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Info } from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useFlags } from "launchdarkly-react-client-sdk";

import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { Button } from "@speed/common/src/components/Button/Button";
import Text from "@speed/common/src/components/Text/Text";
import CustomTable from "@speed/common/src/components/Table/Table";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import { emptySubText } from "@speed/common/src/components/messages";
import history from "@speed/common/src/components/history";
import AccessDenied from "@speed/common/src/components/AccessDenied";

import {
  createRestrictedKey,
  emptyRestrictedKeysSubText,
  restrictedKeys,
  restrictedType,
} from "../messages";
import "../../assets/styles/developers.scss";
import { callAPIInterface, dateTimeFormatInApp } from "../constants";
import { emptyDeveloperKeys } from "../images";
import { setOpenRestrictedKeyCreateModal } from "../../redux/common/actions";
import CommonHeader from "../Common/CommonHeader";
import RestrictedKeysCustomPopper from "./RestrictedKeysCustomPopper";
import CreateRestrictedKeyModal from "./CreateRestrictedKeyModal";

const columnNames = [
  {
    id: "1",
    title: "Name",
    width: "260px",
    paddingLeft: "38px !important",
  },
  { id: "2", title: "Token", width: "500px" },
  { id: "3", title: "Created On", width: "235px" },
  { id: "4", title: "Actions", width: "120px" },
];

const allColumnData = columnNames.map((column) => (
  <TableCell
    key={column.id}
    align={column.align}
    sx={{ minWidth: column.width, paddingLeft: column.paddingLeft }}
  >
    {column?.title}
  </TableCell>
));

const Restricted = () => {
  const liveMode = useSelector((state) => state.auth.liveMode);
  const dispatch = useDispatch();
  const { dkRemoveactionFe, dkRestrictedKeyCreate, dkRestrictedkeyList } =
    useFlags();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowId, setRowId] = useState(null);
  const [currentRowData, setCurrentRowData] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [rowLoader, setRowLoader] = useState(false);

  useEffect(() => {
    setRows([]);
    getListKeys();
  }, [liveMode]);

  const openDescription = Boolean(anchorEl);
  const openPopper = Boolean(anchorE2);

  const getListKeys = () => {
    setTableRowSkeleton(true);
    callAPIInterface("GET", "/api-key/list-keys")
      .then((response) => {
        if (response) {
          setTableRowSkeleton(false);
          const restrictedKeys =
            response?.data?.filter((key) => key?.type === restrictedType) || [];
          setRows(restrictedKeys);
        }
      })
      .catch((_e) => {
        setTableRowSkeleton(false);
      })
      .finally(() => {
        dispatch(setOpenRestrictedKeyCreateModal(false));
      });
  };

  const handleClick = (event, id) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setRowId(id);
  };

  const handleClickHoriz = (event, rowData) => {
    setAnchorE2(anchorE2 ? null : event.currentTarget);
    setCurrentRowData(rowData);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const rowsData = rows?.map((rowItem) => (
    <TableRow
      key={rowItem?.id}
      className="clickable"
      onClick={() => {
        history.push(`/apikeys/restricted-keys/${rowItem.id}`);
      }}
    >
      {rowItem?.id === rowId && rowLoader ? (
        columnNames.map((column) => {
          return (
            <TableCell
              key={column.id}
              sx={{
                minWidth: column?.width,
                padding: "10px 21px !important",
                height: "50px",
                paddingLeft: column?.paddingLeft,
              }}
            >
              <Skeleton />
            </TableCell>
          );
        })
      ) : (
        <>
          {" "}
          <TableCell sx={{ paddingLeft: "38px !important" }}>
            <Box className="flex-inline">
              <Box>{rowItem?.name}</Box>

              <Box
                className="info-icon"
                onClick={(event) => event.stopPropagation()}
              >
                <Info
                  className="label-with-icon"
                  onClick={(e) => handleClick(e, rowItem?.id)}
                  style={{
                    height: 16,
                    width: 16,
                    color: "#848b9e",
                    marginTop: "5px",
                  }}
                />
                {rowId === rowItem?.id && (
                  <CustomPopper
                    id={rowItem?.id}
                    disablePortal={true}
                    open={openDescription}
                    anchorEl={anchorEl}
                    position="top"
                    handleClose={handleClose}
                  >
                    <Text variant="inherit" font="regular" size={16}>
                      {rowItem?.description}
                    </Text>
                  </CustomPopper>
                )}
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            <Box className="flex-inline">
              <Box sx={{ paddingRight: "15px", margin: "2.5px 0px" }}>
                {rowItem?.api_key}
              </Box>
              <Box onClick={(event) => event.stopPropagation()}>
                <Clipboard text={rowItem?.api_key} />
              </Box>
            </Box>
          </TableCell>
          <TableCell>{dateTimeFormatInApp(rowItem?.created)}</TableCell>
          <TableCell>
            <MoreHorizIcon
              className="horizontal-dots-icon pointer-cursor"
              tabIndex={0}
              onClick={(event) => {
                event.stopPropagation();
                if (dkRemoveactionFe) {
                  handleClickHoriz(event, rowItem);
                }
              }}
            />
          </TableCell>
        </>
      )}
    </TableRow>
  ));

  const tableProps = {
    columns: allColumnData,
    rows: rowsData,
    className: "link-table",
    tableRowSkeleton: tableRowSkeleton,
    textOnNoData: emptySubText(liveMode, restrictedKeys),
    subTextOnNoData: emptyRestrictedKeysSubText,
    noDataImage: emptyDeveloperKeys,
  };

  return (
    <Box className="section-wrapper payment-links-wrapper">
      <CommonHeader />
      <CustomDivider />
      {!tableRowSkeleton && rows.length !== 3 && (
        <Box className="action-btn-wrapper mui-fixed">
          {dkRestrictedKeyCreate && (
            <Button
              icon="addIcon"
              className="add-icon"
              label={createRestrictedKey}
              variant="outlined"
              color="primary"
              onClick={() => {
                setCurrentRowData(null);
                dispatch(setOpenRestrictedKeyCreateModal(true));
              }}
            />
          )}
        </Box>
      )}
      <Box className="main-content">
        {dkRestrictedkeyList ? (
          <CustomTable {...tableProps} />
        ) : (
          <AccessDenied />
        )}
      </Box>
      <CreateRestrictedKeyModal
        isUpdating={isUpdating}
        setIsUpdating={setIsUpdating}
        restrictedKeyDetails={currentRowData}
        fromDetailPage={false}
        getListKeys={getListKeys}
      />
      <RestrictedKeysCustomPopper
        setAnchor={setAnchorE2}
        anchor={anchorE2}
        openPopper={openPopper}
        setIsUpdating={setIsUpdating}
        id={currentRowData?.id}
        setRowId={setRowId}
        setRowLoader={setRowLoader}
        setRows={setRows}
        rows={rows}
        fromDetailPage={false}
        restrictedKeyDetails={currentRowData}
        setRestrictedKeyDetails={setCurrentRowData}
      />
    </Box>
  );
};

export default Restricted;
