import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import Button from "@speed/common/src/components/Button/Button";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import "../../assets/styles/manage-apps.scss";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import { fullScreenBlue } from "../images";
import {
  completeInstallation,
  contactSupport,
  remove,
  removeApp,
  RemoveAppMsg,
  unInstalled,
} from "../messages";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import { manageAppsStatus, manageAppIcon } from "../constants";
import { useFlags } from "launchdarkly-react-client-sdk";

const ManageAppCard = ({ appInfo }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openRemoveAppModal, setOpenRemoveAppModal] = useState(false);

  const flags = useFlags();

  const openPopper = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <Card className="manage-app-main-card">
      <Grid item lg={10}>
        <CardContent className="card-body">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Tag
              text={manageAppsStatus[appInfo?.status].label}
              variant={manageAppsStatus[appInfo?.status].variant}
            />
            <MoreHorizIcon
              className="horizontal-dots-icon pointer-cursor"
              tabIndex={0}
              onClick={(event) => {
                handleClick(event);
              }}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <img
              className="card-icon"
              src={manageAppIcon(appInfo?.app_id?.display_name)}
              alt="icons-clear"
            />
            <Text className="card-text" size={14} font="bold">
              {appInfo?.app_id?.display_name}
            </Text>
          </Box>
          <Box className="app-name">
            <TruncatedTextTooltip
              textValue={appInfo?.app_id?.description ?? "Speed"}
              cellWidth="170px"
            />
          </Box>
          <Box className="contact-support">
            <CustomLink
              sx={{ span: { display: "flex", alignItems: "center" } }}
              size="small"
              href="#"
              target="_blank"
            >
              {appInfo?.app_id?.app_url}
              <img
                src={fullScreenBlue}
                alt="Screen-Enlarge"
                width={12}
                height={12}
                style={{ margin: "0 0 0 6px" }}
              />
            </CustomLink>
          </Box>
          <Box className="manage-app-content">
            {appInfo?.status === "active" && flags.appInstall ? (
              <Button className="error" variant="default" label={unInstalled} />
            ) : (
              appInfo?.status === "draft" &&
              flags.appInstallation && (
                <Button
                  color="primary"
                  variant="outlined"
                  label={completeInstallation}
                />
              )
            )}
          </Box>
        </CardContent>
        <CustomPopper
          disablePortal={true}
          open={openPopper}
          anchorEl={anchorEl}
          position="bottom-end"
          handleClose={() => setAnchorEl(null)}
        >
          <List disablePadding>
            <ListItem disablePadding>
              {flags.appContactsupport && (
                <ListItemButton>
                  <CustomLink
                    size="small"
                    href={process.env.REACT_APP_PROMO_WEBSITE}
                    target="_blank"
                    color="#848b9e"
                  >
                    {contactSupport}
                  </CustomLink>
                </ListItemButton>
              )}
            </ListItem>
            <ListItem disablePadding>
              {flags.appRemove && (
                <ListItemButton>
                  <Text
                    variant="subtitle1"
                    size={14}
                    className="error"
                    onClick={() => setOpenRemoveAppModal(true)}
                  >
                    {remove}
                  </Text>
                </ListItemButton>
              )}
            </ListItem>
          </List>
        </CustomPopper>
      </Grid>
      <Modal
        maxWidth="xs"
        body={<RemoveAppMsg />}
        footer={<Button label={removeApp} color="error" />}
        handleClose={() => setOpenRemoveAppModal(false)}
        open={openRemoveAppModal}
        title={removeApp}
        closeIcon={true}
      />
    </Card>
  );
};

export default ManageAppCard;
