import { Box, Grid, Skeleton, TableCell, TableRow } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CommonHeader from "../Common/CommonHeader";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { BTC, emptySubText } from "@speed/common/src/components/messages";
import CustomTable from "@speed/common/src/components/Table/Table";
import {
  transactionPending,
  lnaddressSetupComplete,
  emptyTransaction,
  calendarTime,
} from "../images";
import Text from "@speed/common/src/components/Text/Text";
import {
  callAPIInterface,
  currencySupportedByWalletAssets,
  noOfRecords,
} from "../constants";
import {
  recentTransactions,
  currencyLabel,
  viewAll,
  sats,
  pendingTransactions,
  assetsCreateLn,
  setupText,
  yourLnAddress,
  loadingMsg,
  btc,
} from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import history from "@speed/common/src/components/history";
import {
  convertExponentialToDecimal,
  fetchRawBalanceData,
  getDefaultWalletCurrencyObj,
  removeTrailingZeros,
} from "@speed/common/src/components/constants";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import WalletTransactionsList from "../WalletTransaction/WalletTransactionsList";
import { ArrowForward } from "@mui/icons-material";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import CreatePaymentAddressModal from "./CreatePaymentAddressModal";
import {
  setDefaultSelectedAsset,
  setShowBTCAmount,
  setShowMaintenanceBar,
  setWalletPaymentAddress,
  setWalletSelectedTransaction,
} from "../../redux/common/actions";
import JoinInvite from "../Common/JoinInvite";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import PaymentAddressModal from "./PaymentAddress/index";
import AssetsAmount from "./AssetsAmount";
import SelectCountry from "./SelectCountry";
import { sessionService } from "redux-react-session";
import { HelloBar } from "@speed/common/src/components/HelloBar/HelloBar";
import CancelIcon from "@mui/icons-material/Cancel";
import MaintenanceHelloBar from "./MaintenanceHelloBar";

let fiatCurrency;
const columns = [{ id: "1", title: currencyLabel }];

const MyAssets = () => {
  const queryParam = "?limit=" + noOfRecords;
  const liveMode = useSelector((state) => state.auth.liveMode);
  const showMaintenanceBar = useSelector(
    (state) => state.common.showMaintenanceBar
  );
  const {
    walletPaymentAddress,
    defaultSelectedAsset,
    paymentProcessingRecallAPis,
  } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const currentAccount = useSelector((state) => state.auth.currentAccount);
  const isCountryAvailable = currentAccount?.account?.country;

  const [currency, setCurrency] = useState("");
  const [inviteLoading, setInviteLoading] = useState(false);
  const [selectedCurrencyType, setSelectedCurrencyType] =
    useState(defaultSelectedAsset);
  const [isOpenPaymentAddressModal, setIsOpenPaymentAddressModal] =
    useState(false);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [isAssetsLoading, setIsAssetsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [assetsData, setAssetsData] = useState([]);
  const [targetAmountLoading, setTargetAmountLoading] = useState(true);
  const [exchangeRateAmount, setExchangeRateAmount] = useState(null);
  const [currencySupported, setCurrencySupported] = useState(
    currencySupportedByWalletAssets
  );
  const [balanceData, setBalanceData] = useState(null);
  const [totalUnconfirmed, setTotalUnconfirmed] = useState(0);
  const [isSettingupLnAddress, setIsSettingupLnAddress] = useState(false);
  const [isPaymentAddressLoading, setIsPaymentAddressLoading] = useState(false);
  const [openCountryModal, setOpenCountryModal] = useState(false);
  const [isNoTransactionPerformedInSats, setIsNoTransactionPerformedInSats] =
    useState(false);
  const [currentSession, setCurrentSession] = useState(null);

  useEffect(() => {
    if (!isCountryAvailable) {
      setOpenCountryModal(true);
    }
  }, []);

  useEffect(() => {
    getCurrentSession();
  }, [liveMode]);

  useEffect(() => {
    const defaultCurrency = JSON.parse(
      localStorage.getItem("wallet_default_currency")
    );

    fiatCurrency =
      defaultCurrency ||
      getDefaultWalletCurrencyObj(currentAccount?.account?.country);
  }, []);

  useEffect(() => {
    if (isCountryAvailable || paymentProcessingRecallAPis) {
      fetchAssets();
      getPaymentAddress();
    }
  }, [liveMode, paymentProcessingRecallAPis, isCountryAvailable]);

  useEffect(() => {
    if (
      (isCountryAvailable && selectedCurrencyType?.uuid && balanceData) ||
      paymentProcessingRecallAPis
    ) {
      getExchangeRate();
    }
  }, [
    balanceData,
    selectedCurrencyType,
    paymentProcessingRecallAPis,
    isCountryAvailable,
  ]);

  useEffect(() => {
    let uuid = [];
    const selectedCurrencyUuid = selectedCurrencyType?.uuid;
    if (selectedCurrencyUuid) {
      setIsNoTransactionPerformedInSats(false);
      getTransaction(selectedCurrencyUuid);
      if (selectedCurrencyUuid === sats) {
        uuid = currencySupportedByWalletAssets;
        setCurrency(currentSession?.showBtcAmount ? btc : sats);
        currentSession?.showBtcAmount && setBtcFlag(sats);
      } else {
        uuid.push(selectedCurrencyUuid);
        setCurrency(uuid?.[0]);
      }
      setCurrencySupported(uuid);
    }
  }, [liveMode, selectedCurrencyType, paymentProcessingRecallAPis]);

  const getCurrentSession = async () => {
    const session = await sessionService.loadSession();
    if (selectedCurrencyType?.uuid === sats && session?.showBtcAmount) {
      dispatch(
        setShowBTCAmount(currency === sats ? session?.showBtcAmount : false)
      );
      setCurrency(btc);
    }
    setCurrentSession(session);
  };

  const setBtcFlag = (value) => {
    dispatch(
      setShowBTCAmount(value === sats ? currentSession?.showBtcAmount : false)
    );
  };

  const getTransaction = (currency) => {
    const reqObj = {
      limit: 5,
      target_currency: { in: [currency] },
    };
    callAPIInterface("POST", "/balance-transactions/filter", reqObj)
      .then((res) => {
        setIsNoTransactionPerformedInSats(res?.data?.length === 0);
      })
      .catch((_err) => {});
  };

  const getPaymentAddress = () => {
    dispatch(setWalletPaymentAddress(null));
    fetchPaymentAddress();
  };

  const fetchPaymentAddress = () => {
    setIsPaymentAddressLoading(true);
    callAPIInterface("GET", "/payment-addresses?limit=1")
      .then((res) => {
        if (res) {
          dispatch(setWalletPaymentAddress(res?.data?.[0]?.address));
          setIsPaymentAddressLoading(false);
        }
      })
      .catch((_e) => {
        setIsPaymentAddressLoading(false);
      });
  };

  const fetchAssets = () => {
    getAssets([], queryParam);
  };

  const getSortedPaymentMethod = (myassets) => {
    const sortedPaymentMethodAssetArr = [];
    myassets.forEach((asset) => {
      asset?.payment_method_details?.sort((a, b) => a.weight - b.weight);
      sortedPaymentMethodAssetArr.push(asset);
    });
    return sortedPaymentMethodAssetArr;
  };

  const getSortedAssets = (myassets) => {
    const sortedArr = [];
    if (myassets?.[0]?.uuid !== sats) {
      myassets.forEach((asset) => {
        if (asset.uuid !== sats) {
          sortedArr.push(asset);
        } else {
          sortedArr.unshift(asset);
        }
      });
    } else {
      return myassets;
    }
    return sortedArr;
  };

  const getAssets = (lines, params) => {
    setIsAssetsLoading(true);
    callAPIInterface("GET", "/assets-account" + params, {})
      .then((res) => {
        if (res?.data) {
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1]?.id
            );
          }
          const sortedPaymentMethodAsset = getSortedPaymentMethod(res.data);
          const sortedAsset = getSortedAssets(sortedPaymentMethodAsset);
          setAssetsData(sortedAsset);
          if (!defaultSelectedAsset) {
            setSelectedCurrencyType(sortedAsset?.[0]);
            dispatch(setDefaultSelectedAsset(sortedAsset?.[0]));
          }
          getBalanceData();
        }
      })
      .catch(() => {
        setIsAssetsLoading(false);
      });
  };

  const getBalance = (uuid) => {
    const currencyData = balanceData?.find(
      (bal) => bal?.target_currency === uuid
    );
    return (
      Number(
        uuid === sats ? currencyData?.amount : currencyData?.amount / 100
      ) || 0
    );
  };

  const getAmount = (uuid) => {
    const amount = getBalance(uuid);
    return removeTrailingZeros(
      (uuid === sats ? Math.floor(amount) : amount) || 0,
      currency
    );
  };

  const getExchangeRate = () => {
    const targetCurrency = selectedCurrencyType?.uuid || sats;

    setTargetAmountLoading(true);

    const enteredAmount = getBalance(targetCurrency) || 0;
    const data = {
      currency: fiatCurrency?.code ?? "USD",
      target_currency: targetCurrency,
    };
    callAPIInterface("POST", "/utility/exchange-rate", data)
      .then((res) => {
        setTargetAmountLoading(false);
        const exchangeRate = res?.target_lowest_rate;
        const targetAmount = Number(enteredAmount) / exchangeRate;
        setExchangeRateAmount(targetAmount ?? 0);
      })
      .catch(() => {
        setTargetAmountLoading(false);
      });
  };

  const getBalanceData = () => {
    callAPIInterface("GET", "/balances")
      .then((resData) => {
        if (resData) {
          const data = fetchRawBalanceData(resData);
          setBalanceData(data);
          setIsAssetsLoading(false);
        }
      })
      .catch(() => {
        setIsAssetsLoading(false);
      });
  };

  let columnsData = columns.map((column) => (
    <TableCell style={{ paddingLeft: "36px" }} colSpan="3" key={column.id}>
      {column.title}
    </TableCell>
  ));

  const handleSelectCurrencyType = (rowItem) => {
    setTotalUnconfirmed(0);
    setSelectedCurrencyType(rowItem);
    dispatch(setDefaultSelectedAsset(rowItem));
  };

  const getBtcAmount = (uuid) => {
    const amount = getBalance(uuid);
    const btcAmount = amount ? (Math.floor(amount) / 100000000).toFixed(8) : 0;
    return convertExponentialToDecimal(btcAmount);
  };

  const rowsData =
    !isAssetsLoading &&
    assetsData?.map((rowItem) => (
      <TableRow
        key={rowItem?.id}
        className="clickable"
        style={{
          backgroundColor:
            defaultSelectedAsset?.id === rowItem?.id && "#EFF6FF",
          marginRight: "24px",
        }}
        onClick={() => handleSelectCurrencyType(rowItem)}
      >
        <TableCell sx={{ width: "500px", paddingLeft: "36px !important" }}>
          <Text font="semibold" size={16} withIcon="start">
            <img src={rowItem?.icon} className="asset-icon" alt="icon" />
            {rowItem?.name}
          </Text>
        </TableCell>
        <TableCell width="150px">
          <Box display="flex" justifyContent="flex-end">
            <Box className="asset-amount">
              <Text font="semibold" size={16} className="grey-text">
                {rowItem?.uuid === sats && currentSession?.showBtcAmount
                  ? getBtcAmount(rowItem?.uuid)
                  : getAmount(rowItem?.uuid)}
              </Text>
              <span className="asset-currency">
                {currentSession?.showBtcAmount && rowItem?.uuid === sats
                  ? BTC
                  : rowItem?.uuid}
              </span>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
    ));

  const loadMore = useCallback(() => {
    getAssets(assetsData, queryParams);
  }, [assetsData]);

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    hasMore: hasMore,
    tableRowSkeleton: isAssetsLoading,
    className: "logs-table assets-table",
    noDataImage: emptyTransaction,
    textOnNoData: emptySubText(liveMode, "My assets"),
    loadMore: loadMore,
  };

  const getTxnCurrency = () => {
    let txnCurrency = currency;
    if (currency === BTC) {
      txnCurrency = sats;
    }
    return txnCurrency;
  };

  const handlePendingTransaction = () => {
    dispatch(setWalletSelectedTransaction(null));
    history.push(
      `/wallet-transactions?status=pending&target_currency=${getTxnCurrency()}`
    );
  };

  const showUnconfirmedTransaction = () => (
    <Box onClick={handlePendingTransaction} className="unconfirmed-transaction">
      <img src={transactionPending} alt="" style={{ marginRight: "4px" }} />
      <Text
        className="default-text"
        font="regular"
        size={16}
        variant="h6"
        sx={{
          color: "#d88a2c !important",
          marginRight: "4px",
        }}
      >
        {`${
          totalUnconfirmed > 10 ? "10+" : totalUnconfirmed
        } ${pendingTransactions}`}
      </Text>
      <ArrowForwardIcon className="unconfirmed-arrow" />
    </Box>
  );

  if (openCountryModal && !isCountryAvailable) {
    return (
      <SelectCountry
        openCountryModal={openCountryModal}
        setOpenCountryModal={setOpenCountryModal}
      />
    );
  }
  return (
    <>
      <JoinInvite setLoading={setInviteLoading} />
      {isCountryAvailable && (
        <Box className="section-wrapper">
          <BackdropLoader
            open={inviteLoading}
            alt="Loading..."
            text={loadingMsg}
            customClass="loading-in-auth"
          />
          {showMaintenanceBar && (
            <HelloBar
              id="maintenance-hello-bar"
              hide={!showMaintenanceBar}
              color="#F1C40F"
              descriptionText={<MaintenanceHelloBar />}
              besideComponent={
                <CancelIcon
                  sx={{
                    height: 20,
                    width: 20,
                    cursor: "pointer",
                    color: "#fff !important",
                  }}
                  onClick={() => dispatch(setShowMaintenanceBar(false))}
                />
              }
              startIcon={<img src={calendarTime} alt="calendar-time" />}
            />
          )}
          <CommonHeader />
          <CustomDivider />
          <Box className="assets-container">
            <Grid container height="100%">
              <Grid item xs={assetsData?.length > 0 ? 5.49 : 12} height="100%">
                <CustomTable {...tableProps} />
              </Grid>
              {assetsData?.length > 0 && (
                <>
                  <CustomDivider flexItem orientation="vertical" />
                  <Grid
                    item
                    mt="16px"
                    xs={6.49}
                    height="100%"
                    position="relative"
                  >
                    {isPaymentAddressLoading || !selectedCurrencyType
                      ? currency === sats && (
                          <Skeleton
                            style={{
                              height: "100px",
                              marginLeft: "40px",
                              marginRight: "40px",
                            }}
                          />
                        )
                      : selectedCurrencyType?.uuid === sats && (
                          <Box
                            className="assets-right-container"
                            justifyContent={`${
                              walletPaymentAddress ? "center" : "space-between"
                            }`}
                          >
                            <Box display="flex" alignItems="center">
                              <img
                                src={lnaddressSetupComplete}
                                alt="at-the-rate"
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "36px",
                                }}
                              />
                              {walletPaymentAddress ? (
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                >
                                  <Text
                                    size={18}
                                    className="default-text"
                                    variant="h6"
                                    font="regular"
                                    style={{ marginRight: "8px" }}
                                  >
                                    {yourLnAddress} :{" "}
                                  </Text>
                                  <TruncatedTextTooltip
                                    textProps={{
                                      size: 18,
                                      variant: "h6",
                                      className: "default-text pointer-cursor",
                                      font: "semibold",
                                      style: {
                                        marginRight: "8px",
                                        color: "#2A67FF",
                                      },
                                    }}
                                    onClick={() => {
                                      setIsOpenPaymentAddressModal(true);
                                    }}
                                    textValue={walletPaymentAddress}
                                    cellWidth="200px"
                                  />
                                  <Clipboard text={walletPaymentAddress} />
                                </Box>
                              ) : (
                                <Text
                                  font="regular"
                                  variant="subtitle1"
                                  size={16}
                                  className="default-text"
                                >
                                  {assetsCreateLn}
                                </Text>
                              )}
                            </Box>
                            {!walletPaymentAddress && (
                              <Box
                                className="pointer-cursor"
                                display="flex"
                                flexDirection="row"
                                mr="18px"
                                onClick={() => {
                                  setIsSettingupLnAddress(true);
                                }}
                              >
                                <Text
                                  font="semibold"
                                  variant="subtitle1"
                                  size={16}
                                  className="default-text"
                                  style={{
                                    color: "#2A67FF",
                                    marginRight: "4px",
                                  }}
                                >
                                  {setupText}
                                </Text>
                                <ArrowForward style={{ color: "#2A67FF" }} />
                              </Box>
                            )}
                          </Box>
                        )}
                    <AssetsAmount
                      isAssetsLoading={isAssetsLoading}
                      selectedCurrencyType={selectedCurrencyType}
                      targetAmountLoading={targetAmountLoading}
                      setCurrency={setCurrency}
                      currencySupported={currencySupported}
                      fiatCurrency={fiatCurrency}
                      exchangeRateAmount={exchangeRateAmount}
                      getAmount={getAmount}
                      currency={currency}
                      accountAssets={assetsData}
                      isNoTransactionPerformedInSats={
                        isNoTransactionPerformedInSats
                      }
                      currentSession={currentSession}
                      setCurrentSession={setCurrentSession}
                      getBtcAmount={getBtcAmount}
                    />
                    {totalUnconfirmed > 0 && showUnconfirmedTransaction()}
                    <CustomDivider style={{ marginTop: "30px" }} />
                    {!isNoTransactionPerformedInSats && selectedCurrencyType && (
                      <Box p="24px 40px">
                        <Box
                          mb="22px"
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Text font="regular" size={20}>
                            {recentTransactions}
                          </Text>
                          <Button
                            icon="visibilityIcon"
                            withIcon="start"
                            variant="outlined"
                            style={{ fontSize: "14px" }}
                            label={viewAll}
                            disabled={!selectedCurrencyType}
                            onClick={() => {
                              history.push(
                                `/wallet-transactions?status=confirm&target_currency=${getTxnCurrency()}`
                              );
                            }}
                          />
                        </Box>
                        <CustomDivider />
                        {currency && (
                          <WalletTransactionsList
                            fromMyAssets={true}
                            currency={currency === btc ? sats : currency}
                            setTotalUnconfirmed={setTotalUnconfirmed}
                          />
                        )}
                      </Box>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          <PaymentAddressModal
            isOpenPaymentAddressModal={isOpenPaymentAddressModal}
            setIsOpenPaymentAddressModal={setIsOpenPaymentAddressModal}
          />
          <CreatePaymentAddressModal
            isSettingupLnAddress={isSettingupLnAddress}
            setIsSettingupLnAddress={setIsSettingupLnAddress}
            fetchPaymentAddress={fetchPaymentAddress}
          />
        </Box>
      )}
    </>
  );
};

export default MyAssets;
