import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, TableCell, TableRow } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import * as yup from "yup";
import WarningIcon from "@mui/icons-material/Warning";
import { useFormik } from "formik";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import Button from "@speed/common/src/components/Button/Button";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import Text from "@speed/common/src/components/Text/Text";
import {
  generateBreadcrumbs,
  getCurrencyObj,
  integerWithCurrency,
  linkStatus,
  maxSat,
  redirectWhenEntityNotFound,
} from "@speed/common/src/components/constants";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import InputMask from "@speed/common/src/components/MaskInput";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import { cancel } from "@speed/common/src/components/messages";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import {
  accountID,
  accountOwnerEmail,
  amountExceedPartnerFee,
  changeCommission,
  commission,
  commissionWithPercentage,
  details,
  endDate,
  enterAmountPlaceholder,
  entityNotFoundMessage,
  fixedFee,
  latestTransfersIn,
  partnerCommissionPercentageValidation,
  startDate,
  status,
  subtitleForIdNotFound,
  totalCommissionEarned,
  viewPartners,
} from "../messages";
import {
  RouteBreadcrumbs,
  callAPIInterface,
  dateFromTimestampInApp,
  dateTimeFormatInApp,
  maximumCommissionAmountForAffiliatePartner,
  minimumCommissionAmountForAffiliatePartner,
} from "../constants";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import { KeyValueGrid } from "@speed/common/src/components/KeyValueGrid";
import DetailPagePaymentSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import LinkSkeleton from "@speed/common/src/components/Skeleton/LinkSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";
import { save } from "@speed/common/src/messages";

const MerchantDetail = (props) => {
  const {
    aflGetlistofinvitedmemberFe,
    aflGetmemberdetailbyidFe,
    aflUpdatememberbyidFe,
    aflListoftransferlnsFe,
  } = useFlags();
  const commissionRef = useRef(null);
  const commissionFixedAmountRef = useRef(null);
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [data, setData] = useState(null);
  const [transferSkeleton, setTransferSkeleton] = useState(false);
  const [transferList, setTransferList] = useState([]);
  const [openCommissionModal, setOpenCommissionModal] = useState(false);

  const urlParams = useParams();
  const history = useSelector((state) => state.common.history);
  const liveMode = useSelector((state) => state.auth.liveMode);
  const loadDetailPage = () => {
    setData(null);
    setIsShowNotFound(false);
    getPartnerDetail();
  };

  const getTransferList = () => {
    callAPIInterface("GET", `/affiliate-partner/${urlParams?.id}/transfers`)
      .then((res) => {
        if (res) {
          setTransferSkeleton(false);
          setTransferList(res?.data);
        }
      })
      .catch(() => {
        setTransferSkeleton(false);
      });
  };

  const getPartnerDetail = () => {
    setTransferSkeleton(true);
    callAPIInterface("GET", `/affiliate-partner/${urlParams?.id}`)
      .then((res) => {
        if (res) {
          aflListoftransferlnsFe
            ? getTransferList()
            : setTransferSkeleton(false);
          setData(res);
        }
      })
      .catch((err) => {
        setTransferSkeleton(false);
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  useEffect(
    () =>
      aflGetlistofinvitedmemberFe &&
      aflGetmemberdetailbyidFe &&
      loadDetailPage(),
    [aflGetlistofinvitedmemberFe, aflGetmemberdetailbyidFe, liveMode]
  );

  const initialValues = {
    amount: data?.commission?.percentage,
    fixed_amount: data?.commission?.fixed_amount || "",
  };

  const validationMessageForAmount = partnerCommissionPercentageValidation(
    minimumCommissionAmountForAffiliatePartner,
    maximumCommissionAmountForAffiliatePartner
  );

  const validationSchema = yup.object({
    amount: yup
      .number()
      .min(
        minimumCommissionAmountForAffiliatePartner,
        validationMessageForAmount
      )
      .max(
        maximumCommissionAmountForAffiliatePartner,
        validationMessageForAmount
      ),
    fixed_amount: yup.number().max(maxSat, amountExceedPartnerFee),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (value) => {
      const commissionData = {
        commission: {},
      };
      if (initialValues?.amount != values?.amount) {
        commissionData["commission"]["percentage"] = value?.amount || 0;
      }
      if (initialValues?.fixed_amount != values?.fixed_amount) {
        commissionData["commission"]["fixed_amount"] = value?.fixed_amount || 0;
      }
      callAPIInterface(
        "POST",
        `/affiliate-partner/${urlParams?.id}`,
        JSON.stringify(commissionData)
      )
        .then(() => {
          loadDetailPage();
          closeModal();
        })
        .catch(() => {
          closeModal();
        });
    },
  });

  const {
    values,
    setFieldValue,
    resetForm,
    touched,
    errors,
    setTouched,
    isValid,
    dirty,
    handleSubmit,
    isSubmitting,
  } = formik;

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const headerContent = () => {
    return (
      <Box className="header-content">
        <Text className="default-text" size={24}>
          {data?.account_name}
        </Text>
        <Box className="header-price-content">
          <Box mt={1}>
            <Text className="grey-text" font="regular" size={14}>
              {totalCommissionEarned}
            </Text>
            <Box mt="13px" className="fiat-price">
              <Text className="default-text" size={20}>
                {data?.total_commission_earned > 0
                  ? integerWithCurrency("SATS", data?.total_commission_earned)
                  : "-"}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const transferColumnData = [
    { title: "Transfer ID", width: "520px" },
    { title: "Amount", width: "175px", align: "right" },
    { title: "Date", width: "270px" },
  ];

  const rows = transferList?.map((rowItem) => (
    <TableRow
      key={rowItem.id}
      className="clickable"
      onClick={() => history.push(`/transfers/${rowItem?.id}`)}
    >
      <TableCell>{rowItem?.id}</TableCell>
      <TableCell>
        <Box display="flex" justifyContent="end" gap={0.6}>
          {integerWithCurrency(rowItem?.currency, rowItem?.amount)}
        </Box>
      </TableCell>
      <TableCell>{dateTimeFormatInApp(rowItem?.created)}</TableCell>
    </TableRow>
  ));

  const handleChangeAmount = (e, ref, name) => {
    setTouched({ ...touched, name: false });
    ref.current.amount = e.target.value;
    setFieldValue(name, e.target.value);
  };

  const commissionModalContent = (
    <>
      <Box mt={0.75}>
        <InputMask
          label={commissionWithPercentage}
          onChange={(e) => handleChangeAmount(e, commissionRef, "amount")}
          ref={commissionRef}
          value={values.amount}
          error={errors.amount && Boolean(errors.amount)}
          onBlur={() => setTouched({ ...touched, amount: true })}
          placeholder={enterAmountPlaceholder}
          numOfAllowedDigits={2}
        />
        <InputErrorMessage {...defaultErrorMessageProps} inputName="amount" />
      </Box>
      <Box className="margin-top30" mb={2}>
        <InputMask
          label={`${fixedFee}(SATS)`}
          onChange={(e) =>
            handleChangeAmount(e, commissionFixedAmountRef, "fixed_amount")
          }
          ref={commissionFixedAmountRef}
          value={values.fixed_amount}
          error={errors.fixed_amount && Boolean(errors.fixed_amount)}
          onBlur={() => setTouched({ ...touched, fixed_amount: true })}
          placeholder={enterAmountPlaceholder}
          allowDecimal={false}
        />
        <InputErrorMessage
          {...defaultErrorMessageProps}
          inputName="fixed_amount"
        />
      </Box>
    </>
  );

  const commissionModalFooter = (
    <>
      {initialValues?.amount != values?.amount ||
      initialValues?.fixed_amount != values?.fixed_amount ? (
        <>
          <Button
            variant="outlined"
            style={{ width: "80px" }}
            label={cancel}
            onClick={() => resetForm(initialValues)}
            disabled={isSubmitting}
          />
          <Button
            style={{ width: "80px", marginLeft: "20px" }}
            label={save}
            onClick={handleSubmit}
            disabled={
              !(isValid && dirty) ||
              (values?.amount === "" && values?.fixed_amount === "") ||
              isSubmitting
            }
          />
        </>
      ) : (
        <Box height="42px" />
      )}
    </>
  );

  const closeModal = () => {
    setOpenCommissionModal(false);
    resetForm(initialValues);
  };

  const detailKeyValueData = [
    {
      key: status,
      value: (
        <Tag
          text={linkStatus[data?.status?.toLowerCase()]?.label}
          variant={linkStatus[data?.status?.toLowerCase()]?.variant}
        />
      ),
    },
    {
      key: fixedFee,
      value: data?.commission?.fixed_amount ? (
        <>
          {getCurrencyObj("SATS").symbol}
          {data?.commission?.fixed_amount}
        </>
      ) : (
        "-"
      ),
    },
    {
      key: accountID,
      value: data?.account_id,
    },
    {
      key: startDate,
      value: dateFromTimestampInApp(data?.start_date),
    },
    {
      key: accountOwnerEmail,
      value: data?.owner_email,
    },
    {
      key: endDate,
      value: dateFromTimestampInApp(data?.end_date),
    },
    {
      key: commission,
      value: `${data?.commission?.percentage}%`,
    },
  ];

  return !isShowNotFound ? (
    <Grid className="payment-link-detail-wrapper" container spacing={1}>
      <Box className="section-wrapper" sx={{ position: "relative" }}>
        {data && (
          <Box
            className="action-btn-wrapper"
            style={{ top: "23px", right: "2px" }}
          >
            {aflGetlistofinvitedmemberFe &&
              aflGetmemberdetailbyidFe &&
              aflUpdatememberbyidFe &&
              data?.status?.toLowerCase() === linkStatus.active.value && (
                <Button
                  icon="editIcon"
                  label={changeCommission}
                  variant="outlined"
                  onClick={() => setOpenCommissionModal(true)}
                />
              )}
          </Box>
        )}
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        {aflGetlistofinvitedmemberFe && aflGetmemberdetailbyidFe ? (
          <>
            {data ? (
              <>
                {headerContent()}
                <Box className="details-content">
                  <Text size={20} className="default-text">
                    {details}
                  </Text>
                </Box>
                <CustomDivider sx={{ my: "12px" }} />
                <Box
                  gap={2.75}
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-between"
                >
                  {detailKeyValueData?.map((detailData) => (
                    <KeyValueGrid
                      key={detailData?.key}
                      keyName={detailData?.key}
                      value={detailData?.value}
                    />
                  ))}
                </Box>
                {aflUpdatememberbyidFe && (
                  <Modal
                    maxWidth="sm"
                    body={commissionModalContent}
                    footer={commissionModalFooter}
                    handleClose={closeModal}
                    open={openCommissionModal}
                    title={changeCommission}
                  />
                )}
              </>
            ) : (
              <LinkSkeleton props={props} />
            )}
            {aflListoftransferlnsFe && (
              <>
                {!transferSkeleton ? (
                  <Box className="product-content">
                    <HorizontalTable
                      label={latestTransfersIn}
                      isColumnShown={rows.length > 0}
                      isShowButton={rows.length > 3}
                      columns={transferColumnData}
                      rows={rows}
                      rowsPerPage={3}
                      isShowPagination={false}
                      tableBodyClassName="border-none"
                      handleViewAllClick={() =>
                        history.push(
                          `/partners/affiliate-partners/${data?.id}/transfers`
                        )
                      }
                    />
                  </Box>
                ) : (
                  <DetailPagePaymentSkeleton />
                )}
              </>
            )}
          </>
        ) : (
          <AccessDenied />
        )}
      </Box>
    </Grid>
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("Partner")}
        subTitle={subtitleForIdNotFound("Partner", urlParams.id)}
        btnText={viewPartners}
        redirect={() => history.push("/partners/affiliate-partners")}
      />
    </Box>
  );
};

export default MerchantDetail;
