import {
  TableCell,
  TableRow,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useParams } from "react-router";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import WarningIcon from "@mui/icons-material/Warning";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import {
  clipboardElement,
  generateBreadcrumbs,
  getAmountByCurrency,
  getCurrencyObj,
  importWLStatus,
  redirectWhenEntityNotFound,
  selectTabOnSearch,
  setQueryParamsForTab,
} from "@speed/common/src/components/constants";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  noOfRecords,
  reSendWithdrawalLinkEmail,
  RouteBreadcrumbs,
} from "../constants";
import CustomTable from "@speed/common/src/components/Table/Table";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import { emptySubText } from "@speed/common/src/components/messages";
import { loaderSpinner } from "@speed/common/src/components/images";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import {
  all,
  entityNotFoundMessage,
  failed,
  processingLabel,
  sentLabel,
} from "../messages";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import { resendMsg } from "@speed/common/src/messages";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { emptyCheckoutLink } from "../images";

const WLSingleFilesImportList = (props) => {
  const { id: urlParamsId } = useParams();
  const { search } = useLocation();

  const statusName = new URLSearchParams(search).get("status");
  const statusQueryParam = statusName ? `&status=${statusName}` : "";
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const baseUrl = "/withdrawal-links/import";
  const resendWlEmail = !statusName || statusName === "SENT";
  let columns = [
    {
      title: "Links",
      width: "350px",
      paddingLeft: "38px !important",
    },
    { title: "Withdraw ID", width: "200px" },
    { title: "Email", width: "200px" },
    { title: "Amount", width: "200px" },
    { title: "Failure Reason", width: "200px" },
    { title: "Created on", width: "250px" },
    { title: "Status", width: "100px" },
  ];

  if (resendWlEmail) {
    columns.push({ title: "Action", width: "100px" });
  }

  const [currentRow, setCurrentRow] = useState(null);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [isResendingEmail, setIsResendingEmail] = useState(false);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [rows, setRows] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("1");
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopper = Boolean(anchorEl);

  const history = useSelector((state) => state.common.history);
  const liveMode = useSelector((state) => state.auth.liveMode);
  const { wlImportedlinksFe } = useFlags();

  useEffect(() => {
    if (urlParamsId) {
      selectTabOnSearch(statusName || null, tabData, setSelectedTab);
      handleSetQueryParam(statusName || null);
    }
  }, []);

  const getWLImportList = (type = "first-load") => {
    let endingBeforeParam = "";
    setTableRowSkeleton(true);
    if (type === "first-load") {
      setRows([]);
    } else {
      endingBeforeParam = "&ending_before=" + rows[rows.length - 1].id;
    }

    callAPIInterface(
      "GET",
      `/withdrawal-links/file-links/${urlParamsId}?limit=${noOfRecords}${statusQueryParam}${endingBeforeParam}`,
      {}
    )
      .then((res) => {
        if (res) {
          const linkResponse = res.links;
          setTableRowSkeleton(false);
          setFileData(res);
          if (!linkResponse.has_more) {
            setHasMore(false);
          }
          setRows((pre) => pre.concat(linkResponse.data));
        }
      })
      .catch((err) => {
        setTableRowSkeleton(false);
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  useEffect(() => {
    if (wlImportedlinksFe) {
      setHasMore(true);
      getWLImportList();
    }
    setIsShowNotFound(false);
  }, [liveMode, statusName, wlImportedlinksFe]);

  const loadMore = useCallback(() => {
    getWLImportList("load-more");
  }, [rows]);

  const handleClick = (event, rowData) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setCurrentRow(rowData);
  };

  const columnsData = columns?.map((column) => (
    <TableCell
      key={column.title}
      sx={{ paddingLeft: column.paddingLeft, minWidth: column.width }}
    >
      {column.title}
    </TableCell>
  ));

  const rowsData = rows?.map((rowItem) => {
    const { failure_reason, created, status, reference_details = {} } = rowItem;
    const { currency, amount, email, id, url } = reference_details;
    const lowerCaseStatus = status?.toLowerCase();

    return (
      <TableRow key={id}>
        <TableCell sx={{ paddingLeft: "38px !important" }}>
          <Box display="flex" alignItems="center">
            {url ? clipboardElement(url, true, "", "inputBox", false) : "-"}
          </Box>
        </TableCell>
        <TableCell>{id || "-"}</TableCell>
        <TableCell>
          <TruncatedTextTooltip textValue={email || "-"} cellWidth="250px" />
        </TableCell>
        <TableCell>
          {getCurrencyObj(currency)?.symbol}
          {getAmountByCurrency(amount, currency)}
        </TableCell>
        <TableCell>
          <TruncatedTextTooltip
            textValue={failure_reason || "-"}
            cellWidth="250px"
          />
        </TableCell>
        <TableCell> {dateTimeFormatInApp(created)}</TableCell>
        <TableCell>
          <Tag
            text={importWLStatus[lowerCaseStatus].label}
            variant={importWLStatus[lowerCaseStatus].variant}
            deleteIcon={importWLStatus[lowerCaseStatus].icon}
            onDelete={() => {}}
          />
        </TableCell>

        {resendWlEmail && (
          <TableCell>
            {status === "SENT" && (
              <MoreHorizIcon
                sx={{ cursor: "pointer" }}
                className="horizontal-dots-icon"
                onClick={(event) => {
                  event.stopPropagation();
                  handleClick(event, rowItem);
                }}
              />
            )}
          </TableCell>
        )}
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton,
    hasMore,
    loadMore,
    className: "link-table",
    textOnNoData: emptySubText(
      liveMode,
      "Withdrawal links",
      statusName?.toLocaleLowerCase()
    ),
    noDataImage: emptyCheckoutLink,
  };

  const tabData = [
    {
      label: all,
      value: "1",
      tabContent: <CustomTable {...tableProps} />,
      tabName: "ALL",
    },
    {
      label: processingLabel,
      value: "2",
      tabContent: <CustomTable {...tableProps} />,
      tabName: "PROCESSING",
    },
    {
      label: sentLabel,
      value: "3",
      tabContent: <CustomTable {...tableProps} />,
      tabName: "SENT",
    },
    {
      label: failed,
      value: "4",
      tabContent: <CustomTable {...tableProps} />,
      tabName: "FAILED",
    },
  ];

  const handleSetQueryParam = (searchValue) => {
    history.push({
      pathname: `${baseUrl}/${urlParamsId}`,
      search: searchValue ? `?status=${searchValue}` : null,
    });
  };

  const handleChange = (_event, newValue) => {
    setSelectedTab(newValue);
    const selectedTabName = setQueryParamsForTab(tabData, newValue);
    handleSetQueryParam(selectedTabName);
  };

  const handleResendWL = () => {
    setIsResendingEmail(true);
    if (currentRow) {
      reSendWithdrawalLinkEmail(currentRow?.reference_details?.id)
        .then((res) => {
          if (res) {
            setIsResendingEmail(false);
            setAnchorEl(null);
          }
        })
        .catch(() => {
          setIsResendingEmail(false);
          setAnchorEl(null);
        });
    }
  };

  return !isShowNotFound ? (
    <>
      <Box
        className="payment-link-detail-wrapper"
        paddingBottom="22px !important"
      >
        <Box className="section-wrapper" sx={{ position: "relative" }}>
          <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
          {wlImportedlinksFe && (
            <Box className="header-content">
              {fileData.filename ? (
                <TruncatedTextTooltip
                  textProps={{
                    className: "default-text",
                    size: 24,
                    variant: "h1",
                    font: "semibold",
                  }}
                  textValue={fileData.filename}
                  cellWidth="500px"
                />
              ) : (
                <Box height="36px" alignContent="center">
                  <Skeleton animation="wave" width="300px" height="20px" />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {wlImportedlinksFe ? (
        <Box className="payment-links-wrapper">
          <CustomTab
            onChange={handleChange}
            tabData={tabData}
            selectedTab={selectedTab}
            noScroll={true}
          />
          <CustomPopper
            disablePortal={true}
            open={openPopper}
            anchorEl={anchorEl}
            position="bottom"
            handleClose={() => setAnchorEl(null)}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ gap: 1 }}
                  disabled={isResendingEmail}
                  onClick={handleResendWL}
                >
                  <ListItemText primary={resendMsg} />
                  {isResendingEmail && (
                    <img
                      src={loaderSpinner}
                      alt="Loader"
                      width={15}
                      height={15}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            </List>
          </CustomPopper>
        </Box>
      ) : (
        <AccessDenied />
      )}
    </>
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("Imported File")}
        subTitle={`Imported file ${urlParamsId} is not found.`}
        btnText={`View ${(liveMode && "live") || "test"} mode files`}
        redirect={() => history.push(baseUrl)}
      />
    </Box>
  );
};

export default WLSingleFilesImportList;
