import Text from "@speed/common/src/components/Text/Text";
import { Grid, Box } from "@mui/material";
import { Input } from "@speed/common/src/components/Input/Input";
import { send, subResetPasswordMsg, resetPasswordMsg } from "../messages";
import { Button } from "@speed/common/src/components/Button/Button";
import {
  invalidEmail,
  loadingMsg,
  emailLabel,
  emailPlaceholder,
} from "@speed/common/src/components/messages";
import { useFormik } from "formik";
import * as yup from "yup";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../redux/auth/actions";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { useState, useEffect } from "react";
import EmailSent from "./EmailSent";
import MainLogoLogin from "../Common/MainLogoLogin";
import { goBack } from "@speed/common/src/messages";
import { redirectToLogin } from "../constants";
import TurnstileRecaptcha from "@speed/common/src/components/Turnstile";
import { useFlags } from "launchdarkly-react-client-sdk";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { history, isMobile } = useSelector((state) => state.common);
  const [isLoading, setLoading] = useState(false);
  const [isResetEmailSuccess, setIsResetEmailSuccess] = useState(false);
  const [token, setToken] = useState("");
  const { turnstileFe } = useFlags();
  const isFromLoginPage = history?.location?.state?.isFromLoginScreen;
  const validationSchema = yup.object({
    email: yup.string().email(invalidEmail),
  });

  useEffect(() => {
    if (!isFromLoginPage) {
      redirectToLogin();
      return;
    }
  }, []);

  const handleResetPassword = () => {
    setLoading(true);
    const forgotPasswordData = {
      email: values.email,
      turnstileToken: token,
    };
    dispatch(forgotPassword(forgotPasswordData)).then((res) => {
      setLoading(false);
      if (!res) {
        setSubmitting(false);
        setToken("");
      } else setIsResetEmailSuccess(true);
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleResetPassword,
  });

  const {
    values,
    touched,
    errors,
    setTouched,
    setFieldValue,
    isValid,
    isSubmitting,
    dirty,
    handleSubmit,
    setSubmitting,
  } = formik;

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  return isResetEmailSuccess ? (
    <EmailSent email={values.email} />
  ) : (
    <>
      <MainLogoLogin />
      <Box className="container-layout">
        <Grid
          container
          className="common-grid-wrapper common-mobile-grid signin-signup-container-layout"
        >
          <BackdropLoader
            open={isLoading}
            alt="loading..."
            text={loadingMsg}
            customClass="loading-in-auth"
          />
          <Grid
            item
            className="email-password-grid"
            component="form"
            onSubmit={handleSubmit}
          >
            <Text
              size={isMobile ? 22 : 30}
              font="bold"
              className="default-text text"
            >
              {resetPasswordMsg}
            </Text>
            <Text
              font="regular"
              className="margin-top10"
              size={isMobile ? 14 : 18}
              variant="subtitle1"
              sx={{ lineHeight: "140% !important" }}
            >
              {subResetPasswordMsg}
            </Text>
            <Input
              fullWidth
              customClass="margin-top36"
              label={emailLabel}
              placeholder={emailPlaceholder}
              name="email"
              value={values.email}
              type="email"
              onBlur={() => setTouched({ ...touched, email: true })}
              onChange={(e) => {
                setTouched({ ...touched, email: false });
                setFieldValue("email", e.target.value);
              }}
            />
            <InputErrorMessage
              {...defaultErrorMessageProps}
              inputName="email"
            />
            {turnstileFe && (
              <TurnstileRecaptcha token={token} setToken={setToken} />
            )}
            <Button
              fullWidth
              className="submit-signin-btn margin-top30"
              label={send}
              iconPosition="end"
              icon="rightArrowIcon"
              type="submit"
              disabled={
                !(isValid && dirty) ||
                isSubmitting ||
                (turnstileFe && !token.length)
              }
            />
            <Button
              fullWidth
              className="margin-top30 goback-btn"
              label={goBack}
              type="button"
              variant="default"
              onClick={() => history.push("/login")}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ForgotPassword;
