import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import { Text } from "../Text/Text";
import {
  Box,
  CircularProgress,
  InputBase,
  CardContent,
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  CardHeader,
  ClickAwayListener,
} from "@mui/material";
import { Wallet } from "@speed/common/src/components/messages";
import {
  noResultfound,
  searchPlaceholder,
  searchTipText,
  showAllResult,
  suggestedFilterHeaderText,
  tryAgainText,
} from "../messages";
import "./search-bar.scss";
import CloseIcon from "@mui/icons-material/Close";
import { CustomLink } from "../Link/Link";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tag } from "../Tag/Tag";
import globalSearchFilterableData from "./GlobalSearchFilters.json";
import history from "../history";
import { searchbarIcons } from "../images";
import _ from "lodash";
import {
  dateTimeFormat,
  getAmountByCurrency,
  getCurrencyObj,
  linkStatus,
} from "../constants";
import TruncatedTextTooltip from "../TruncatedTextTooltip";

const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  };

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};

export const SearchBar = ({
  isLoading,
  resultData,
  onChangeInput,
  component,
  setResultData,
  user,
  currentAccount,
  timezone,
  liveMode,
  webAppSearchResults,
  clearMemoizedCache,
  searchTipsUrl,
  ldFlags,
}) => {
  const lastAPICallPromise = useRef();
  const [inputString, setInputString] = useState("");
  const [loading, setLoading] = useState(isLoading);
  const [cursor, setCursor] = useState();
  const [showResultMenu, setShowResultMenu] = useState(false);
  const [filterContent, setFilterContent] = useState();
  const [selectedFilterData, setSelectedFilterData] = useState();
  const [resultContent, setResultContent] = useState();
  const [wordToBeSearch, setWordToBeSearch] = useState("");
  const [selectedParentFilter, setSelectedParentFilter] = useState(false);
  const [searchResultData, setSearchResultData] = useState();
  const [onFocusSearch, setOnFocusSearch] = useState(false);
  const [objectType, setObjectType] = useState();
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const escapePress = useKeyPress("Escape");
  const detailSearchPageURL = `/search?query=${encodeURIComponent(
    inputString
  )}`;
  const minSearchStringChar = 3;
  const searchQueryString = new URLSearchParams(history.location.search).get(
    "query"
  );
  const maxNumWebPageResults = 3;
  const maxNumMongoResults =
    10 - (resultData ? resultData.slice(0, maxNumWebPageResults)?.length : 0);

  const numOfSearchResultsItems =
    (filterContent && filterContent.length > 0 ? filterContent.length : 0) +
    (inputString && resultData
      ? resultData.length > maxNumWebPageResults
        ? maxNumWebPageResults
        : resultData.length
      : 0) +
    (inputString && searchResultData
      ? searchResultData.length > maxNumMongoResults
        ? maxNumMongoResults
        : searchResultData.length
      : 0) +
    (!filterContent ? 1 : 0);

  const searchFilterObject = Object.fromEntries(
    Object.entries(globalSearchFilterableData).filter((item) => {
      let value = item[1];
      return !(value.ldFlagName && ldFlags && !ldFlags[value.ldFlagName]);
    })
  );

  const getLastSearchInputString = (inputString) => {
    let inputStringArray = inputString.split(" ");
    return inputStringArray[inputStringArray.length - 1];
  };

  const setFilterSuggestion = (searchInputString) => {
    const isMatchMainField =
      Object.keys(searchFilterObject).includes(searchInputString);
    const parentFilters = Object.values(searchFilterObject);
    const childFilterContent = _.filter(parentFilters, (elem) => {
      const children = _.filter(elem.filterValues, (child) => {
        if (child.filterCategory === searchInputString) return;
        return child.filterCategory.startsWith(searchInputString);
      });

      if (children?.length > 0) return elem;
    });
    const childFilters =
      childFilterContent?.length > 0
        ? childFilterContent[0].filterValues
        : null;

    const isChildFiltersAvailable = Object.entries(searchFilterObject).filter(
      (x) => x[0]?.startsWith(searchInputString)
    );

    const hasChild =
      isChildFiltersAvailable?.length > 0
        ? isChildFiltersAvailable[0][1].filterValues?.length > 0
        : false;

    if (hasChild && !isMatchMainField) {
      setSelectedFilterData(parentFilters);
    } else if (isMatchMainField || (!hasChild && !isMatchMainField)) {
      setSelectedFilterData(childFilters);
    }
  };

  const handleChangeInput = (event) => {
    let lastSearchingString = getLastSearchInputString(event.target.value);
    setFilterSuggestion(lastSearchingString);
    setSelectedParentFilter(false);
    setInputString(event.target.value);
    setWordToBeSearch(lastSearchingString);
    onChangeInput &&
      onChangeInput(lastSearchingString.trim().replace(/\s\s+/g, " "));
    setShowResultMenu(true);
    setCursor();
  };

  const handleBlurInput = () => {
    let targetElement = document.getElementById("global-searchbox");
    targetElement && targetElement.blur();
    showResultMenu && setShowResultMenu(false);
    setCursor();
  };

  const checkIsField = (searchStr) => {
    const matchArr = searchStr.match(/(^is:)/gi);
    const matchedStr = matchArr ? matchArr[0] || "" : "";

    if (matchedStr === "is:") {
      const splitSearchStr = searchStr.split(/(:)(.*)/g).filter(Boolean);
      if (splitSearchStr?.length === 3) {
        setObjectType(splitSearchStr[2] || "");
      }
    }
  };

  const handleClickMenuItem = (item) => {
    let targetElement = document.getElementById("global-searchbox");
    targetElement && targetElement.focus();

    onChangeInput &&
      onChangeInput(item.filterCategory.trim().replace(/\s\s+/g, " "));
    const isMultipleFilters = selectedFilterData?.filter((x) => {
      return x.filterCategory.startsWith(item.filterCategory);
    });

    if (item.filterValues) {
      setSelectedParentFilter(true);
      setSelectedFilterData(item.filterValues);
    } else {
      setSelectedParentFilter(false);
      isMultipleFilters?.length === 1 && setSelectedFilterData();
    }

    checkIsField(item.filterCategory);
    setCursor();
    setWordToBeSearch(item.filterCategory);
    setInputString(
      inputString.slice(0, -wordToBeSearch.length) + item.filterCategory
    );
  };

  const handleClickShowAllResults = (e) => {
    e.preventDefault();
    setResultData && setResultData(resultData?.slice(0, maxNumWebPageResults));
    history.push(detailSearchPageURL);
    setShowResultMenu(false);
    setCursor();
  };

  useEffect(() => {
    if (downPress && showResultMenu) {
      setCursor((prevState) => {
        return prevState < numOfSearchResultsItems - 1 ? prevState + 1 : 0;
      });
    }
  }, [downPress]);

  useEffect(() => {
    if (upPress && showResultMenu) {
      setCursor(
        (prevState) =>
          (prevState > 0 && prevState - 1) ||
          (prevState === 0 && numOfSearchResultsItems - 1) ||
          0
      );
    }
  }, [upPress]);

  useEffect(() => {
    if (enterPress && showResultMenu) {
      handleBlurInput();
      let pressEnterForAllResults =
        cursor === undefined && inputString.length > 0;
      if (pressEnterForAllResults) {
        setResultData &&
          setResultData(resultData?.slice(0, maxNumWebPageResults));
        history.push(detailSearchPageURL);
        setShowResultMenu(false);
        setCursor();
      } else {
        let targetElement = document.getElementById("search-item-" + cursor);
        targetElement && targetElement.click();
      }
    }
  }, [enterPress]);

  useEffect(() => {
    if (escapePress && showResultMenu) {
      handleBlurInput();
    }
  }, [escapePress]);

  useEffect(() => {
    if (clearMemoizedCache) clearMemoizedCache();
  }, [liveMode]);

  useEffect(() => {
    setOnFocusSearch(false);
    setShowResultMenu(false);
    handleBlurInput();
  }, [searchQueryString, liveMode]);

  useEffect(() => {
    if (
      inputString?.length >= minSearchStringChar &&
      showResultMenu &&
      !selectedParentFilter &&
      webAppSearchResults
    ) {
      setLoading(true);
      setSearchResultData();
      const apiDelayFunction = setTimeout(() => {
        // this function used to send Search API call after 500ms user typing
        const currentAPICallPromise = webAppSearchResults(inputString).then(
          (resultArray) => {
            return resultArray;
          }
        );

        lastAPICallPromise.current = currentAPICallPromise;

        currentAPICallPromise.then((resultArray) => {
          if (currentAPICallPromise === lastAPICallPromise.current) {
            setLoading(false);
            setSearchResultData(resultArray?.data);
          }
        });
      }, 500);
      return () => clearTimeout(apiDelayFunction);
    } else {
      setLoading(false);
      setSearchResultData();
    }
  }, [inputString, onFocusSearch, liveMode]);

  useEffect(() => {
    if (searchQueryString) {
      setSelectedParentFilter(false);
      let lastSearchingString = getLastSearchInputString(searchQueryString);
      let queryResultData =
        onChangeInput &&
        onChangeInput(lastSearchingString.trim().replace(/\s\s+/g, " "));
      queryResultData &&
        setResultData &&
        setResultData(queryResultData.slice(0, maxNumWebPageResults));
      setWordToBeSearch(lastSearchingString);
      setInputString(searchQueryString);
      setFilterSuggestion(lastSearchingString);
    } else {
      setInputString("");
      setWordToBeSearch("");
      setSelectedFilterData();
      setObjectType();
    }
  }, [searchQueryString, history && history.location.pathname]);

  useEffect(() => {
    let suggestedFilterContent;
    if ((!inputString && !wordToBeSearch) || (inputString && wordToBeSearch)) {
      let filterdDataObj = selectedFilterData
        ? selectedFilterData
        : Object.values(searchFilterObject);

      let suggestedFilterData = wordToBeSearch
        ? filterdDataObj.filter((x) => {
            if (objectType && x.allowedObject) {
              return (
                x.allowedObject.includes(objectType) &&
                x.filterCategory.startsWith(wordToBeSearch)
              );
            }

            return x.filterCategory.startsWith(wordToBeSearch);
          })
        : filterdDataObj;

      if (suggestedFilterData && suggestedFilterData.length > 0)
        suggestedFilterContent = suggestedFilterData
          .slice(0, 7)
          .map((item, index) => {
            return (
              <List key={index}>
                <ListItem disablePadding>
                  <ListItemButton
                    id={"search-item-" + index}
                    key={index}
                    className={`${index === cursor ? "active" : ""}`}
                    onMouseEnter={() => {
                      setCursor(index);
                    }}
                    onClick={() => handleClickMenuItem(item)}
                  >
                    <Tag
                      className="search-suggested-filter"
                      variant="success-res"
                      text={item.filterCategory}
                    />
                    {item.filterDescription && (
                      <ListItemText
                        className="category-description"
                        primary={
                          <Text
                            variant="h4"
                            className="grey-text"
                            component="span"
                            font="regular"
                            size={12}
                          >
                            {item.filterDescription}
                          </Text>
                        }
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              </List>
            );
          });
      setFilterContent(suggestedFilterContent);
    } else {
      setFilterContent();
    }
  }, [
    inputString,
    wordToBeSearch,
    cursor,
    history && history.location.pathname,
  ]);

  const moduleIconObj = {
    "checkout.link": {
      iconName: "checkoutLinks",
      urlModule: "checkout-links",
    },
    "checkout.session": {
      iconName: "checkoutSessions",
      urlModule: "sessions",
    },
    "payment.link": {
      iconName: "paymentLinks",
      urlModule: "payment-links",
    },
    payment: {
      iconName: "payments",
      urlModule: "payments",
    },
    withdraw: {
      iconName: "withdraws",
      urlModule: "withdraws",
    },
    customer: {
      iconName: "customers",
      urlModule: "customers",
    },
    product: {
      iconName: "products",
      urlModule: "products",
    },
  };

  const prepareSearchResultLine = (item) => {
    const objectType = item.object;
    switch (objectType) {
      case "payment":
      case "checkout.link":
      case "checkout.session":
      case "payment.link":
      case "withdraw":
        return (
          <>
            <ListItemIcon>
              {searchbarIcons[moduleIconObj[item.object]?.iconName]}
            </ListItemIcon>
            <ListItemText
              sx={{ minWidth: "170px" }}
              primary={
                <TruncatedTextTooltip
                  textValue={item.id}
                  textProps={{
                    variant: "h5",
                    size: 14,
                  }}
                />
              }
            />
            <Text
              font="regular"
              className="grey-text"
              variant="h3"
              component="span"
              size={14}
              sx={{ margin: "0px 10px" }}
            >
              {getCurrencyObj(item.currency).symbol}
              {getAmountByCurrency(item.amount, item.currency)}
            </Text>

            <Tag
              text={linkStatus[item?.status].label}
              variant={linkStatus[item?.status].variant}
              sx={{ margin: "0px 10px" }}
            />

            <Text
              font="regular"
              className="grey-text"
              variant="h3"
              component="span"
              size={14}
              sx={{ minWidth: "max-content" }}
            >
              {dateTimeFormat(item.created, user?.date_format, timezone)}
            </Text>
          </>
        );
      case "customer":
      case "product":
        return (
          <>
            <ListItemIcon>
              {searchbarIcons[moduleIconObj[item.object]?.iconName]}
            </ListItemIcon>
            <ListItemText
              sx={{ minWidth: "170px" }}
              primary={
                <TruncatedTextTooltip
                  textValue={item.name}
                  textProps={{
                    variant: "h5",
                    size: 14,
                  }}
                />
              }
            />
            <Text
              font="regular"
              className="grey-text"
              variant="h3"
              component="span"
              size={14}
              sx={{ minWidth: "max-content" }}
            >
              {dateTimeFormat(item.created, user?.date_format, timezone)}
            </Text>
          </>
        );
    }
  };

  const prepareSearchResultData = () => {
    return searchResultData?.map((item, index) => {
      if (index < maxNumMongoResults) {
        const numOfFilteredResults =
          filterContent?.length > 0 ? filterContent.length : 0;
        const numOfStaticWebResults = resultData?.length
          ? resultData.length >= 3
            ? 3
            : resultData.length
          : 0;
        const numOfMongoSearchResults =
          filterContent && filterContent.length > 0 ? index : index + 1;

        const customIndex =
          numOfFilteredResults +
          numOfStaticWebResults +
          numOfMongoSearchResults;

        return (
          <List key={customIndex}>
            <ListItem disablePadding>
              <ListItemButton
                id={"search-item-" + customIndex}
                key={customIndex}
                className={`${customIndex === cursor ? "active" : ""}`}
                onMouseEnter={() => {
                  setCursor(customIndex);
                }}
                component={component}
                to={`/${moduleIconObj[item.object]?.urlModule}/${item.id}`}
                onClick={() => {
                  setInputString("");
                  setWordToBeSearch("");
                  setShowResultMenu(false);
                  setCursor();
                  setSelectedFilterData();
                }}
              >
                {prepareSearchResultLine(item)}
              </ListItemButton>
            </ListItem>
          </List>
        );
      }
    });
  };

  useEffect(() => {
    let resultContent;
    if (
      inputString &&
      (resultData?.length > 0 || searchResultData?.length > 0)
    ) {
      resultContent = [
        filterContent && (
          <Box key="result-header" className="result-header">
            <Text variant="h4" size={14}>
              {`Result${resultData?.length > 1 ? "s" : ""}`}
            </Text>
          </Box>
        ),
        resultData?.map((item, index) => {
          if (index < maxNumWebPageResults) {
            const customIndex =
              filterContent && filterContent.length > 0
                ? filterContent.length + index
                : index + 1;
            const listButtonProps = {
              id: "search-item-" + customIndex,
              key: customIndex,
              className: `${customIndex === cursor ? "active" : ""}`,
              onMouseEnter: () => {
                setCursor(customIndex);
              },
              onClick: () => {
                setInputString("");
                setWordToBeSearch("");
                setShowResultMenu(false);
                setCursor();
                item.isExternalLink
                  ? window.open(item.href, "_blank", "noopener")
                  : history.push(item.href);
              },
            };
            if (!item.isExternalLink) {
              listButtonProps.to = item.href;
              listButtonProps.component = component;
            }

            return (
              <List key={customIndex}>
                <ListItem disablePadding>
                  <ListItemButton {...listButtonProps}>
                    <ListItemIcon>{searchbarIcons[item.icon]}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              </List>
            );
          }
        }),
        !loading && prepareSearchResultData(),
      ];
      setResultContent(resultContent);
    } else {
      setResultContent();
    }
  }, [
    searchResultData,
    resultData,
    inputString,
    filterContent,
    cursor,
    history && history.location.pathname,
  ]);

  let noResultfoundContent;
  if (
    !loading &&
    !filterContent &&
    !resultContent &&
    !searchResultData?.length > 0
  ) {
    noResultfoundContent = (
      <React.Fragment>
        <SearchIcon className="no-result-search-icon" />
        <Text size={16} align="center" className="grey-text">
          {noResultfound}
        </Text>
        <Text
          size={14}
          variant="h4"
          className="grey-text"
          align="center"
          font="regular"
        >
          {tryAgainText}
        </Text>
      </React.Fragment>
    );
  }

  const searchbarInputElement = (
    <InputBase
      spellCheck="false"
      id="global-searchbox"
      autoComplete="off"
      value={inputString}
      className="global-search-input"
      placeholder={searchPlaceholder}
      inputProps={{ "aria-label": searchPlaceholder }}
      onChange={handleChangeInput}
      onFocus={() => {
        setShowResultMenu(true);
        setOnFocusSearch(true);
      }}
      startAdornment={
        <>
          <InputAdornment className="global-search-icon" position="start">
            {loading ? (
              <CircularProgress className="icon-spin" />
            ) : (
              <SearchIcon />
            )}
          </InputAdornment>
          {inputString && (
            <CloseIcon
              className="clear-search-icon"
              onClick={() => {
                setInputString("");
                setWordToBeSearch("");
                setSelectedFilterData();
                setResultContent();
                setCursor();
                setObjectType();
              }}
            />
          )}
        </>
      }
    />
  );

  const searchbarResultMenu = (
    <Card
      className={`${
        filterContent ? "suggested-filters-wrapper" : "search-result-wrapper"
      } ${noResultfoundContent ? "no-result" : ""}`}
    >
      {filterContent ? (
        <CardHeader
          id="filter-suggestion-header"
          disableTypography={true}
          title={
            <>
              <Text variant="h4" size={14}>
                {suggestedFilterHeaderText}
              </Text>
              <CustomLink
                href={searchTipsUrl}
                className="search-tips-link"
                size="small"
                target="_blank"
                alignIcon="start"
                withIcon={
                  <HelpOutlineIcon
                    style={{ width: 20, height: 20, color: "#848b9e" }}
                  />
                }
              >
                {searchTipText}
              </CustomLink>
            </>
          }
        />
      ) : (
        <List className="show-all-results" key={0}>
          <ListItem disablePadding>
            <ListItemButton
              id="search-item-0"
              className={`${cursor === 0 ? "active" : ""}`}
              onMouseEnter={() => setCursor(0)}
              onMouseLeave={() => setCursor()}
              component="a"
              href={detailSearchPageURL}
              onClick={handleClickShowAllResults}
            >
              <Text variant="h4" size={14} className="grey-text" font="regular">
                {showAllResult}
                {"\u00A0"}&quot;
                <Text
                  component="span"
                  size={14}
                  className="grey-text input-string"
                  font="semibold"
                  noWrap
                >
                  {inputString}
                </Text>
                &quot;
              </Text>
            </ListItemButton>
          </ListItem>
        </List>
      )}
      <CardContent className="result-item" onMouseLeave={() => setCursor()}>
        {filterContent}
        {resultContent}
        {noResultfoundContent}
      </CardContent>
    </Card>
  );

  return (
    currentAccount?.account?.account_type !== Wallet && (
      <ClickAwayListener
        onClickAway={() => {
          setShowResultMenu(false);
          setCursor();
        }}
      >
        <Box className="global-search-wrapper">
          {searchbarInputElement}
          {showResultMenu && searchbarResultMenu}
        </Box>
      </ClickAwayListener>
    )
  );
};

export const propTypes = {
  isLoading: PropTypes.bool,
  resultData: PropTypes.array,
  onChangeInput: PropTypes.func,
};

const defaultProps = {
  isLoading: false,
  resultData: null,
};

SearchBar.propTypes = propTypes;
SearchBar.defaultProps = defaultProps;
