import { Input } from "@speed/common/src/components/Input/Input";
import React, { useEffect } from "react";
import {
  LNAddress,
  addressPlaceholder,
  getAddressName,
  type,
  wallet,
  walletNameLabel,
  walletNamePlaceholder,
} from "../../messages";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import CustomSelect from "@speed/common/src/components/Select/Select";
import Label from "@speed/common/src/components/Label/Label";
import { Box, MenuItem } from "@mui/material";

const PayoutWalletFormCommon = (props) => {
  const { formik, currencies } = props;

  const { values, setFieldValue, errors, touched, setTouched, resetForm } =
    formik;

  const onSelect = (e) => {
    const currencySelected = currencies?.find(
      (currency) => currency?.code === e.target.value
    );
    setFieldValue("currency", currencySelected);
    setFieldValue("wallet_address", "");
  };

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const walletType = values.currency?.code === LNAddress ? LNAddress : wallet;

  useEffect(() => {
    return () => resetForm({});
  }, []);

  return (
    <>
      <Input
        type="text"
        name="wallet_name"
        value={values.wallet_name}
        showLabel
        label={walletNameLabel}
        fullWidth
        inputProps={{ maxLength: 100 }}
        placeholder={walletNamePlaceholder}
        error={Boolean(errors.wallet_name)}
        onBlur={() => setTouched({ ...touched, wallet_name: true })}
        onChange={(e) => {
          setTouched({ ...touched, wallet_name: false });
          setFieldValue("wallet_name", e.target.value);
        }}
      />
      <InputErrorMessage
        {...defaultErrorMessageProps}
        inputName="wallet_name"
      />
      <Label className="margin-top30">{type}</Label>
      <Box width="100%">
        <CustomSelect
          value={values?.currency?.code}
          MenuProps={{
            id: "payout-wallet-address",
            disableScrollLock: true,
          }}
          renderValue={(_value) => {
            if (values?.currency) {
              return values?.currency?.name;
            }
          }}
          onChange={onSelect}
        >
          {currencies?.map((dropDownValue, dropDownIndex) => {
            return (
              <MenuItem
                value={dropDownValue?.code}
                key={`${dropDownValue?.code}_${dropDownIndex}`}
              >
                <Box>{dropDownValue?.name}</Box>
              </MenuItem>
            );
          })}
        </CustomSelect>
      </Box>
      <Input
        type="text"
        name="wallet_address"
        value={values.wallet_address}
        customClass="margin-top30"
        showLabel
        label={getAddressName(walletType)}
        fullWidth
        placeholder={addressPlaceholder(walletType)}
        error={Boolean(errors.wallet_address)}
        onBlur={() => setTouched({ ...touched, wallet_address: true })}
        onChange={(e) => {
          setTouched({ ...touched, wallet_address: false });
          setFieldValue("wallet_address", e.target.value);
        }}
      />
      <InputErrorMessage
        {...defaultErrorMessageProps}
        inputName="wallet_address"
      />
    </>
  );
};

export default PayoutWalletFormCommon;
