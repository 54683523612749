export const SET_SETTING_FORM_VALID = "SET_SETTING_FORM_VALID";
export const SET_SETTING_FORM_SUBMIT = "SET_SETTING_FORM_SUBMIT";
export const SET_SETTING_FORM_CHANGE = "SET_SETTING_FORM_CHANGE";
export const SET_SETTING_FROM_CLOSE_ACCOUNT = "SET_SETTING_FROM_CLOSE_ACCOUNT";
export const SET_SETTING_FORM_CANCEL = "SET_SETTING_FORM_CANCEL";
export const SHOW_TOAST_MESSAGE = "SHOW_TOAST_MESSAGE";
export const HIDE_TOAST_MESSAGE = "HIDE_TOAST_MESSAGE";
export const SET_ROUTE_DETAILS = "SET_ROUTE_DETAILS";
export const SET_LOADING = "SET_LOADING";
export const SET_CONFIRM_ACTION_MODAL = "SET_CONFIRM_ACTION_MODAL";
export const SET_VERIFY_OTP_MODAL = "SET_VERIFY_OTP_MODAL";
export const SET_EXECUTE_API = "SET_EXECUTE_API";
export const UPDATE_PAYMENT_INFO = "UPDATE_PAYMENT_INFO";
export const UPDATE_PAYMENT_MESSAGE = "UPDATE_PAYMENT_MESSAGE";
export const SET_REDIRECT_TO_WEBSITE_PREVIEW =
  "SET_REDIRECT_TO_WEBSITE_PREVIEW";
export const SET_BRANDING_PAGE_UI = "SET_BRANDING_PAGE_UI";
export const SET_BRANDING_VIEW = "SET_BRANDING_VIEW";
export const SET_PAYMENT_CONFIRMATION_OPTION =
  "SET_PAYMENT_CONFIRMATION_OPTION";
export const SET_CHECKOUT_LOADER = "SET_CHECKOUT_LOADER";
export const LOGIN_SESSION_FILTER = "LOGIN_SESSION_FILTER";
export const SET_WEB_PAGE_SEARCH_RESULTS = "SET_WEB_PAGE_SEARCH_RESULTS";
export const SET_SEARCHED_MODULE_NAME = "SET_SEARCHED_MODULE_NAME";
export const SET_PROFILE_MFA_AUTH_DETAILS = "SET_PROFILE_MFA_AUTH_DETAILS";
export const SET_ONCHAIN_PAYMENT_METHOD = "SET_ONCHAIN_PAYMENT_METHOD";
export const SET_ONCHAIN_PAID_TOLERENCE = "SET_ONCHAIN_PAID_TOLERENCE";
export const SET_IS_DATA_AVAILABLE = "SET_IS_DATA_AVAILABLE";
export const SET_IS_MODAL_OPEN = "SET_IS_MODAL_OPEN";
export const SET_CAUGHT_ERROR_IN_STRAPI = "SET_CAUGHT_ERROR_IN_STRAPI";
export const SET_OPEN_RESTRICTED_KEY_CREATE_MODAL =
  "SET_OPEN_RESTRICTED_KEY_CREATE_MODAL";
export const SET_LAST_INVOICE_SAVED_IN = "SET_LAST_INVOICE_SAVED_IN";
export const SET_INVOICE_PREVIEW_DATA = "SET_INVOICE_PREVIEW_DATA";
export const SET_PAYMENT_PAGE_SETTINGS = "SET_PAYMENT_PAGE_SETTINGS";
export const SET_ACCOUNT_PUBLIC_INFO = "SET_ACCOUNT_PUBLIC_INFO";
export const SET_CONNECT_BRANDING_DETAILS = "SET_CONNECT_BRANDING_DETAILS";
export const SET_PLATFORM_CONNECT_BRANDING = "SET_PLATFORM_CONNECT_BRANDING";
export const SET_CUSTOM_FIELD_UPDATED_FROM = "SET_CUSTOM_FIELD_UPDATED_FROM";
export const SET_PATH_NAME = "SET_PATH_NAME";
export const SET_WALLET_SELECTED_TRANSACTION =
  "SET_WALLET_SELECTED_TRANSACTION";
export const SET_WALLET_HANDLE_MODAL_CLOSE = "SET_WALLET_HANDLE_MODAL_CLOSE";
export const SET_PAYOUT_MODAL_OPEN = "SET_PAYOUT_MODAL_OPEN";
export const SET_PAYOUT_MODAL_DATA = "SET_PAYOUT_MODAL_DATA";
export const SET_PAYOUT_SCHEDULING_ENABLED = "SET_PAYOUT_SCHEDULING_ENABLED";
export const SET_WALLET_PAYMENT_ADDRESS = "SET_WALLET_PAYMENT_ADDRESS";
export const SET_IS_WALLET_TRANSACTION_DRAWER_OPEN =
  "SET_IS_WALLET_TRANSACTION_DRAWER_OPEN";
export const SET_WALLET_TRANSACTION_FILTER = "SET_WALLET_TRANSACTION_FILTER";
export const SET_AUTOMATIC_USER_LOGOUT_MODAL =
  "SET_AUTOMATIC_USER_LOGOUT_MODAL";
export const SET_WALLET_ASSET = "SET_WALLET_ASSET";
export const SET_ACCOUNT_RESTRICTED_MODAL_DATA =
  "SET_ACCOUNT_RESTRICTED_MODAL_DATA";
export const SET_INSTANT_SEND_NEXT_CLICKED = "SET_INSTANT_SEND_NEXT_CLICKED";
export const SET_INSTANT_SEND_BUTTON_CLICKED =
  "SET_INSTANT_SEND_BUTTON_CLICKED";
export const SET_DEFAULT_SELECTED_ASSET = "SET_DEFAULT_SELECTED_ASSET;";
export const SET_PAYMENT_PROCESSING_RECALL_APIS =
  "SET_PAYMENT_PROCESSING_RECALL_APIS;";
export const SET_SHOW_BTC_AMOUNT = "SET_SHOW_BTC_AMOUNT";
export const SET_MAINTENANCE_DETAILS = "SET_MAINTENANCE_DETAILS ";
export const SET_SHOW_UNDER_MAINTENANCE_BAR = "SET_SHOW_UNDER_MAINTENANCE_BAR";
