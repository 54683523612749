export const brokenImage =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/broken-img.jpg";
export const defaultUser =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/default-user.svg";
export const googleIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/google-icon.svg";
export const signupGraphic =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/signup-graphic.svg";
export const checkEmail =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/check-email.svg";
export const QRLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/QR-logo.svg";
export const handSvg =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/hand-svg.svg";
export const emptyCheckoutLink =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty_checkoutlink.svg";
export const emptySession =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty_session.svg";
export const emptyPaymentLink =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty_paymentlink.svg";
export const emptyPayment =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty_payments.svg";
export const emptyWithdraw =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty-withdraws.svg";
export const emptyPayoutWallets =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty_payout_wallets.svg";
export const emptyTransaction =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty_transactions.svg";
export const emptyProduct =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty_products.svg";
export const emptyLog =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty_logs.svg";
export const appStoreBadge =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/appStoreBadge.png";
export const googlePlayBadge =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/googlePlayBadge.png";
export const emptySearchResult =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty_search_result.svg";
export const fullScreen =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/icons-full-screen.svg";
export const fullScreenBlue =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/icons-full-screen-blue.svg";
export const leftOrnaments =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/left-ornaments.png";
export const rightOrnaments =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/right-ornaments.png";
export const clearIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/icons-clear.png";
export const emptyWebhook =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty_webhook.svg";
export const shopifyIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/logo-shopify.svg";
export const wooCommerceIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/logo-woocommerce.svg";
export const noManageApps =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/no-manage-apps.svg";
export const icLighting =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/ic-lightning.png";
export const icOnchain =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/ic-onchain.png";
export const emptyOneQR =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/emptyOneQR.svg";
export const bitCoinLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/bitCoinLogo.svg";
export const foxHubLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/foxhub.jpg";
export const upArrowCircle =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/up-arrow-circle.svg";
export const downArrowCircle =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/down-arrow-circle.svg";
export const errorIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/icons-error.svg";
export const signoutIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/icons-signout.svg";
export const transactionChartImg =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/transaction-chart.svg";
export const speedIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/logo-speed.svg";
export const emailIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/icons-email.svg";
export const emptyDeveloperKeys =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/no-restricted-keys.svg";
export const emptyCashback =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty-cashback.svg";
export const emptyPaymentAddresses =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty-payment-addresses.svg";
export const logoWhite =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/logo-white.svg";
export const linkNotFoundImg =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/link-not-found.png";
export const connectImg =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/connect-icon.svg";
export const currencyBTC =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/BTC.svg";
export const lnAddressSetup =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/ln-address-setup.svg";
export const webStoreAvailable =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/web-store-available.svg";
export const currencyExchange =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/currency-exchange.svg";
export const bitCoinSymbol =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/BitCoin.svg";
export const paymentFailedImg =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/payment-failed.svg";
export const paymentProcessingImg =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/payment-processing.svg";
export const filledGray =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/fill-gray.svg";
export const bitCoinSymbolGray =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/bitcoin-gray.svg";
export const transactionPending =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/pending-transaction.svg";
export const calenderSvg =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/Calendar.svg";
export const personCancelSvg =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/person-cancel.svg";
export const lnaddressSetup =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/lnaddress-setup.svg";
export const lnaddressSetupComplete =
  process.env.REACT_APP_IMAGE_ICON_S3_URL +
  "/app/lnaddress-setup-completed.svg";
export const doubleArrowIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/double-arrow-down.svg";
export const attentionIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/attention-icon.svg";
export const errorImg =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/error-img.svg";
export const banxaLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/Banxa-logo.svg";
export const noInstantSendSvg =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/no-instant-sends.svg";
export const infoIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/info-icon.svg";
export const speedQrLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/speed-qr-logo.png";
export const usdtSymbol =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/usdt-icon.svg";
export const syncArrow =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/sync-arrow.svg";
export const walletBtcNoTransaction =
  process.env.REACT_APP_IMAGE_ICON_S3_URL +
  "/app/wallet-btc-no-transaction.png";
export const oneClickSuccessAnimation =
  process.env.REACT_APP_IMAGE_ICON_S3_URL +
  "/app/one-click-success-animation.gif";
export const calendarTime =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/Calendartime.svg";
export const usdtLightning =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/USDT-L.png";
