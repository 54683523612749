import React, { useEffect, useState } from "react";
import { Box, MenuItem } from "@mui/material";
import RightSideCommonPreview from "../../Common/RightSideCommonPreview";
import CustomSelect from "@speed/common/src/components/Select/Select";
import {
  content,
  getTimeZoneAndDateFormat,
  paymentSettingsOptions,
} from "../../constants";
import RenderLinkWebPreview from "../../Common/RenderLinkWebPreview";
import RenderLinkMobilePreview from "../../Common/RenderLinkMobilePreview";
import OneQRBranding from "../Branding/OneQRBranding";
import {
  lightningLabel,
  lnUrlPay,
} from "@speed/common/src/components/messages";
import {
  dummyLightningAddress,
  dummyLnurl,
  dummyOnchainAddress,
  staticInvoiceData,
  staticPaymentCheckoutData,
} from "@speed/common/src/components/constants";
import {
  checkoutPaymentLinkTabLabel,
  invoice,
  invoicePreviewURL,
  oneQR,
  previewURL,
  withdrawPreviewURL,
  withdrawalLinkTabLabel,
} from "../../messages";
import { useSelector } from "react-redux";

const PaymentPageSettingsDisplay = () => {
  const [settingsValue, setSettingsValue] = useState(paymentSettingsOptions[0]);
  const [dummyDomain, setDummyDomain] = useState("");
  const [linkBasedProps, setLinkBasedProps] = useState({});
  const [selected, setSelected] = useState("laptop");

  const paymentPageSettings = useSelector(
    (state) => state.common.paymentPageSettings
  );

  const handleChange = (_e, value) => {
    if (value) {
      setSelected(value);
    }
  };

  const commonLinkProps = {
    onChainAvailable: true,
    lightningLabel: lightningLabel,
    dummyLightningAddress,
    dummyOnchainAddress,
    confirmPageProps: null,
  };
  useEffect(() => {
    switch (settingsValue.name) {
      case checkoutPaymentLinkTabLabel:
        setDummyDomain(previewURL);
        setLinkBasedProps({
          TIDVisible: true,
          ...commonLinkProps,
        });
        break;

      case withdrawalLinkTabLabel:
        setDummyDomain(withdrawPreviewURL);
        setLinkBasedProps({
          onChainAvailable: false,
          lightningLabel: (
            <>
              {lightningLabel} {lnUrlPay}
            </>
          ),
          dummyLightningAddress: dummyLnurl,
        });
        break;

      case invoice:
        setDummyDomain(invoicePreviewURL);
        setLinkBasedProps({
          ...commonLinkProps,
          isShowInvoiceInfo: true,
          invoicePreviewData: staticInvoiceData,
          isShowProductsList: true,
          productsData: {
            products: staticInvoiceData?.payment?.invoice_line_items || [],
            isShowViewMoreDetails:
              staticInvoiceData?.payment?.invoice_line_items?.length > 2,
          },
          timezone: getTimeZoneAndDateFormat()?.timezone,
        });
        break;
    }
  }, [settingsValue]);

  const selectComponent = (
    <CustomSelect
      name="settings_options"
      value={settingsValue.name}
      MenuProps={{
        id: "settings_options",
      }}
      onChange={(e) => {
        const value = paymentSettingsOptions.find(
          (item) => item.name === e.target.value
        );
        setSettingsValue(value);
      }}
      sx={{ width: "402px !important" }}
      renderValue={(value) => value}
    >
      {paymentSettingsOptions.map((option) => (
        <MenuItem key={option.id} value={option.name}>
          {option.name}
        </MenuItem>
      ))}
    </CustomSelect>
  );

  const respectedLinkWebProps = {
    ...linkBasedProps,
    paymentPageSettings,
  };

  const respectedLinkMobileProps = {
    ...linkBasedProps,
    paymentPageSettings,
  };

  const renderProps = {
    dummyDomain: dummyDomain,
    paymentInfo: staticPaymentCheckoutData,
    marginTop: "24px",
    marginBottom: "80px",
  };

  const renderContent = () => {
    return selected === "laptop" ? (
      <RenderLinkWebPreview
        {...renderProps}
        respectedLinksProps={respectedLinkWebProps}
      />
    ) : (
      <RenderLinkMobilePreview
        {...renderProps}
        respectedLinksProps={respectedLinkMobileProps}
      />
    );
  };

  return (
    <RightSideCommonPreview
      handleChange={handleChange}
      showPriceMsg={false}
      content={content}
      selected={selected}
      brandingURL="/settings/checkout-payment"
      customSelectComponent={selectComponent}
      showWebMobilePreviewButton={settingsValue.name !== oneQR}
    >
      {settingsValue.name === oneQR ? (
        <Box marginTop="31px" ml="5px">
          <OneQRBranding />
        </Box>
      ) : (
        renderContent()
      )}
    </RightSideCommonPreview>
  );
};

export default PaymentPageSettingsDisplay;
