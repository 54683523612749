import React from "react";
import GetGuides from "./GetGuides";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Box } from "@mui/material";

const AffiliatePartnersGuide = () => {
  const { obAffiliatepartnerguideFe } = useFlags();

  return obAffiliatepartnerguideFe ? (
    <GetGuides
      moduleName="affiliate_partners"
      redirectUrl="/partners/affiliate-partners"
      guideApiPath="affiliate-partners-guide"
      getStartedApiPath="Affiliate"
      needHelpGuidePath="docs/business-operations/affiliate-partners"
    />
  ) : (
    <Box height="70vh" display="flex" alignItems="center">
      <AccessDenied />
    </Box>
  );
};

export default AffiliatePartnersGuide;
