import { Box } from "@mui/material";
import { Input } from "@speed/common/src/components/Input/Input";
import Label from "@speed/common/src/components/Label/Label";
import Text from "@speed/common/src/components/Text/Text";
import React from "react";
import {
  addDetailsToYourStore,
  addWebhookSecretKey,
  enterWebhookSecretKey,
  webhookSecretKey,
} from "../../messages";

const AddWebhookSecretKey = (props) => {
  const { values, setFieldValue, touched, setTouched, errors } = props;
  return (
    <Box sx={{ mt: "50px" }} display="inline-flex">
      <Box className="grid-box-step">
        <Text style={{ color: "#2a67ff", textAlign: "center" }}>3</Text>
      </Box>
      <Box>
        <Text
          style={{ marginTop: "4px" }}
          className="default-text"
          size={20}
          variant="h2"
        >
          {addWebhookSecretKey}
        </Text>
        <Text
          className="grey-text margin-top15"
          size={20}
          variant="body1"
          font="regular"
        >
          {addDetailsToYourStore}
        </Text>
        <Box sx={{ mt: "50px", alignItems: "center" }} width={600}>
          <Box display="flex" justifyContent="space-between">
            <Label>{webhookSecretKey}</Label>
          </Box>
          <Input
            name="webhookSecretKey"
            showLabel={false}
            fullWidth
            label={webhookSecretKey}
            placeholder={enterWebhookSecretKey}
            type="text"
            value={values.webhook_secret_key}
            error={
              touched.webhook_secret_key && Boolean(errors.webhook_secret_key)
            }
            onBlur={() => setTouched({ ...touched, webhook_secret_key: true })}
            onChange={(e) => {
              setTouched({ ...touched, webhook_secret_key: false });
              setFieldValue("webhook_secret_key", e.target.value);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddWebhookSecretKey;
