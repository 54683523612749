import React, { useEffect, useState } from "react";
import Turnstile from "react-turnstile";

const TurnstileRecaptcha = ({ token, setToken }) => {
  const [boundRef, setBoundRef] = useState(null);
  useEffect(() => {
    if (boundRef && !token) {
      boundRef.reset();
    }
  }, [token]);

  return (
    <Turnstile
      size="flexible"
      style={{ marginTop: "10px" }}
      sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
      onVerify={(token) => {
        setToken(token);
      }}
      onError={() => setToken("")}
      onExpire={() => setToken("")}
      onLoad={(_widgetId, bound) => {
        setBoundRef(bound);
      }}
    />
  );
};

export default TurnstileRecaptcha;
