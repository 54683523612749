import React from "react";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Label from "@speed/common/src/components/Label/Label";
import _ from "lodash";
import {
  close,
  checkRecords,
  name,
  recordsNotConfirmed,
  recordsNotConfirmedSubMsg,
  type,
  value,
  verifyDomainHeader,
  instructionForProviderUrl,
} from "../../messages";
import { clipboardElement } from "@speed/common/src/components/constants";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import Button from "@speed/common/src/components/Button/Button";

const VerifyDnsRecordsModal = ({
  isValidateRecords,
  isDisplayContentHeader,
  handleOnClickContinue,
  setOpenAddRecordModal,
  modalLoader,
  isError,
  openAddRecordModal,
  currentRow,
  failedValidationCount,
  dnsRecords,
  errorObj,
  cedVerify,
}) => {
  const renderDnsRecords = (dns) => {
    return (
      !_.isEmpty(dns) &&
      Object.entries(dns).map(([key, record], index) => {
        return (
          <Box key={key}>
            <Grid sx={{ padding: "9px 0px" }}>
              <Box className="domain-content">
                <Label className="add-domain-label">{type}</Label>
                <Label className="domain-type">{record.type}</Label>
              </Box>
              <Box className="domain-content">
                <Label className="add-domain-label">{name}</Label>
                {clipboardElement(
                  record.host,
                  true,
                  `domain-txt ${
                    isValidateRecords && !errorObj[key] && "error-border"
                  }`,
                  "",
                  true
                )}
              </Box>
              <Box className="domain-content">
                <Label className="add-domain-label">{value}</Label>
                {clipboardElement(
                  record.data,
                  true,
                  `domain-txt ${
                    isValidateRecords && !errorObj[key] && "error-border"
                  }`,
                  "",
                  true
                )}
              </Box>
            </Grid>
            {index !== Object.keys(dns).length - 1 && (
              <CustomDivider className="modal-divider" />
            )}
          </Box>
        );
      })
    );
  };

  //dns record domain modal footer
  const addDomainFooter = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        display="flex"
      >
        <Button
          style={{ width: "175px" }}
          label={isDisplayContentHeader ? checkRecords : close}
          onClick={() =>
            isDisplayContentHeader
              ? handleOnClickContinue()
              : setOpenAddRecordModal(false)
          }
          loading={modalLoader}
        />
      </Grid>
    );
  };

  const addDomainContent = (dnsRecords) => {
    return (
      <Box className="add-domain-modal-wrapper">
        {renderDnsRecords(dnsRecords)}
      </Box>
    );
  };

  return (
    <Modal
      className="add-domain"
      maxWidth="sm"
      body={addDomainContent(dnsRecords?.dns_records)}
      footer={cedVerify ? addDomainFooter() : null}
      handleClose={() => setOpenAddRecordModal(false)}
      open={openAddRecordModal}
      title={verifyDomainHeader(currentRow?.domain)}
      closeIcon={true}
      isStickyContent={isDisplayContentHeader}
      isError={isError}
      errorMsg={recordsNotConfirmed(
        failedValidationCount,
        <a
          href={instructionForProviderUrl}
          target="_blank"
          className="instruction-msg"
        >
          {recordsNotConfirmedSubMsg}
        </a>
      )}
    />
  );
};

export default VerifyDnsRecordsModal;
