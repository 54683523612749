import { getFlatfileCurrencyOptions } from "./constants";

export const blueprint = {
  name: "Speed Workbook",
  namespace: "speedWorkbook",
  sheets: [
    {
      name: "Withdrawal via template",
      description: "Shows withdrawal link fields",
      slug: "links",
      fields: [
        {
          key: "email",
          type: "string",
          label: "Email",
          constraints: [{ type: "required" }],
        },
        {
          key: "name",
          type: "string",
          label: "Name",
        },
        {
          key: "currency",
          type: "enum",
          label: "Currency",
          config: {
            options: getFlatfileCurrencyOptions(),
          },
          constraints: [{ type: "required" }],
        },
        {
          key: "amount",
          type: "number",
          label: "Amount",
          constraints: [{ type: "required" }],
        },
        {
          key: "password",
          type: "string",
          label: "Password",
        },
        {
          key: "success_message",
          type: "string",
          label: "Success Message",
        },
        {
          key: "success_url",
          type: "string",
          label: "Redirect to website",
        },
      ],
    },
  ],
  actions: [
    {
      operation: "submitActionFg",
      mode: "foreground",
      label: "Submit",
      description: "Submit data to speed.com",
      primary: true,
      constraints: [{ type: "hasAllValid" }, { type: "hasData" }],
    },
  ],
  settings: {
    trackChanges: true,
  },
};
