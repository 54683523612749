import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonHeader from "../../Common/CommonHeader";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Button from "@speed/common/src/components/Button/Button";
import { createConnectLabel, createNew } from "../../messages";
import { continueBtn } from "@speed/common/src/messages";
import ConnectModalBody from "./ConnectModalBody";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import "./connect.scss";
import { callAPIInterface, callStrapiAPIInterface } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import GetStartedHeader from "@speed/common/src/components/GetStartedHeader/GetStartedHeader";
import {
  setCaughtErrorInStrapi,
  setIsDataAvailable,
  setIsModalOpen,
} from "../../../redux/common/actions";
import ConnectedAccountTable from "./ConnectedAccountTable";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";

const ConnectAccounts = () => {
  const dispatch = useDispatch();
  const [isModalLoader, setIsModalLoader] = useState(false);
  const [typeSelected, setTypeSelected] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");
  const [selectedValue, setSelectedValue] = useState("Standard");
  const [isGetHeaderInfoLoading, setGetHeaderInfoLoading] = useState(true);
  const [connectGuide, setConnectGuide] = useState(null);

  const liveMode = useSelector((state) => state.auth.liveMode);
  const { isModalOpen, isDataAvailable, caughtErrorInStrapi } = useSelector(
    (state) => state.common
  );

  const { caConnectaccFe, caPlatformacclistFe } = useFlags();

  const getConnectGuide = () => {
    setGetHeaderInfoLoading(true);
    callStrapiAPIInterface(
      "GET",
      "/api/connect-guide?populate[header][populate][use_cases][populate]=*"
    )
      .then((response) => {
        setConnectGuide(response?.data?.attributes?.header);
        setGetHeaderInfoLoading(false);
      })
      .catch(() => {
        dispatch(setCaughtErrorInStrapi(true));
        setGetHeaderInfoLoading(false);
      });
  };

  useEffect(() => {
    dispatch(setIsDataAvailable(true));
    getConnectGuide();
    return () => {
      dispatch(setIsModalOpen(false));
      dispatch(setCaughtErrorInStrapi(false));
    };
  }, [liveMode]);

  const setDataToInitial = () => {
    dispatch(setIsModalOpen(false));
    setTypeSelected(false);
    setGeneratedLink("");
  };
  const handleContinue = () => {
    if (typeSelected) {
      setDataToInitial();
    } else {
      setIsModalLoader(true);
      const data = {
        account_type: selectedValue,
      };
      callAPIInterface("POST", "/connect/generate/account-link", data)
        .then((res) => {
          if (res) {
            setGeneratedLink(res?.link);
            setTypeSelected(true);
            setIsModalLoader(false);
          }
        })
        .catch((_err) => {
          setTypeSelected(false);
          setIsModalLoader(false);
        });
    }
  };

  const modalFooter = () => {
    return (
      !generatedLink && (
        <Button
          label={continueBtn}
          loading={isModalLoader}
          variant="contained"
          onClick={handleContinue}
        />
      )
    );
  };

  const bodyParams = {
    typeSelected,
    selectedValue,
    generatedLink,
    setSelectedValue,
  };

  const handleModalClose = () => {
    setDataToInitial();
  };

  return (
    <Box
      className="section-wrapper payment-links-wrapper"
      bgcolor={!isDataAvailable && !caughtErrorInStrapi ? "#f7fafc" : ""}
    >
      {isDataAvailable || caughtErrorInStrapi ? (
        <>
          <CommonHeader />
          <CustomDivider />
        </>
      ) : (
        <GetStartedHeader
          setIsModalOpen={setIsModalOpen}
          loading={isGetHeaderInfoLoading}
          getHeaderInfo={connectGuide}
        />
      )}
      {caConnectaccFe && (isDataAvailable || caughtErrorInStrapi) && (
        <Box className="action-btn-wrapper mui-fixed">
          <Button
            icon="addIcon"
            className="add-icon"
            label={createNew}
            variant="outlined"
            color="primary"
            onClick={() => dispatch(setIsModalOpen(true))}
          />
        </Box>
      )}
      <Modal
        maxWidth="sm"
        body={<ConnectModalBody {...bodyParams} />}
        handleClose={handleModalClose}
        open={isModalOpen}
        title={createConnectLabel}
        footer={modalFooter()}
      />
      {caPlatformacclistFe ? <ConnectedAccountTable /> : <AccessDenied />}
    </Box>
  );
};

export default ConnectAccounts;
