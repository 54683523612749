import React from "react";
import GetGuides from "./GetGuides";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Box } from "@mui/material";

const TransferGuide = () => {
  const { obTransferguideFe } = useFlags();

  return obTransferguideFe ? (
    <GetGuides
      moduleName="transfer"
      redirectUrl="/transfers"
      guideApiPath="transfer-guide"
      getStartedApiPath="Transfer"
      needHelpGuidePath="docs/send-payments/transfers"
    />
  ) : (
    <Box height="70vh" display="flex" alignItems="center">
      <AccessDenied />
    </Box>
  );
};

export default TransferGuide;
