import * as Types from "./types";
import { isMobile } from "react-device-detect";
import history from "@speed/common/src/components/history";

const initialState = {
  history: history,
  settingFormValid: false,
  settingFormSubmit: false,
  settingFormCloseAccount: false,
  settingFormChange: false,
  settingFormCancel: false,
  isMobile: isMobile,
  toastVariant: "success",
  toastMessage: "",
  toastAutoHideDuration: 4000,
  isToastOpen: false,
  isLoading: false,
  notificationClass: "",
  currentRouteDetails: null,
  openConfirmActionModal: false,
  openVerifyOTPModal: false,
  executeAPICall: false,
  paymentInfo: null,
  paymentMessage: "",
  redirectToWebsitePreview: false,
  brandingPageUI: null,
  brandingSelectedView: "laptop",
  paymentConfirmationOption: "confirmation",
  checkoutLoader: false,
  webPageSearchResults: null,
  searchedModuleName: null,
  profileMfaAuthDetails: [],
  onChainPaymentMethodOn: "",
  onChainPaidTolerence: "",
  isDataAvailable: true,
  isModalOpen: false,
  caughtErrorInStrapi: false,
  openRestrictedKeyCreateModal: false,
  lastSavedInvoiceIn: null,
  invoicePreviewData: null,
  paymentPageSettings: null,
  accountPublicInfo: null,
  connectBrandingDetails: null,
  platformConnectBranding: null,
  customFieldUpdatedFrom: "form-side",
  pathname: "",
  walletSelectedTransaction: null,
  isWalletSendModalClose: false,
  payoutModalOpen: false,
  payoutModalData: null,
  isPayoutSchedulingEnabled: false,
  walletPaymentAddress: null,
  isWalletTransactionDrawerOpen: false,
  walletTransactionAppliedFilterObj: null,
  showAutomaticUserLogoutModal: false,
  accountRestrictedModalData: false,
  walletAssets: [],
  defaultSelectedAsset: null,
  paymentProcessingRecallAPis: false,
  showBtcAmount: false,
  maintenanceDetails: null,
  showMaintenanceBar: false,
  accountAsset: [],
  flatfileName: null,
  flatfileCurrencyRates: { SATS: 1, BTC: 100000000 },
  flatfileWorkbookSheetData: null,
  SendViaEmailModalAPIDetails: null,
  openSendViaEmailModal: false,
  selectedAssetCurrencyState: "SATS",
};

const common = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_SETTING_FORM_VALID:
      return {
        ...state,
        settingFormValid: action.payload,
      };
    case Types.SET_SETTING_FORM_CHANGE:
      return {
        ...state,
        settingFormChange: action.payload,
      };
    case Types.SET_SETTING_FORM_SUBMIT:
      return {
        ...state,
        settingFormSubmit: action.payload,
      };
    case Types.SET_SETTING_FROM_CLOSE_ACCOUNT:
      return {
        ...state,
        settingFormCloseAccount: action.payload,
      };
    case Types.SET_SETTING_FORM_CANCEL:
      return {
        ...state,
        settingFormCancel: action.payload,
      };
    case Types.SHOW_TOAST_MESSAGE:
      const { isToastOpen, toastMessage, toastVariant, toastAutoHideDuration } =
        action.payload;
      return {
        ...state,
        isToastOpen: isToastOpen,
        toastMessage: toastMessage,
        toastVariant: toastVariant,
        toastAutoHideDuration: toastAutoHideDuration || 4000,
        notificationClass: action.payload.className,
      };
    case Types.HIDE_TOAST_MESSAGE:
      return {
        ...state,
        isToastOpen: false,
      };
    case Types.SET_ROUTE_DETAILS:
      return {
        ...state,
        currentRouteDetails: action.payload,
      };
    case Types.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case Types.SET_CONFIRM_ACTION_MODAL:
      return {
        ...state,
        openConfirmActionModal: action.payload,
      };
    case Types.SET_VERIFY_OTP_MODAL:
      return {
        ...state,
        openVerifyOTPModal: action.payload,
      };
    case Types.SET_EXECUTE_API:
      return {
        ...state,
        executeAPICall: action.payload,
      };
    case Types.UPDATE_PAYMENT_INFO:
      return {
        ...state,
        paymentInfo: action.payload,
      };
    case Types.UPDATE_PAYMENT_MESSAGE:
      return {
        ...state,
        paymentMessage: action.payload,
      };
    case Types.SET_REDIRECT_TO_WEBSITE_PREVIEW:
      return {
        ...state,
        redirectToWebsitePreview: action.payload,
      };
    case Types.SET_PAYMENT_CONFIRMATION_OPTION:
      return {
        ...state,
        paymentConfirmationOption: action.payload,
      };
    case Types.SET_BRANDING_PAGE_UI:
      return {
        ...state,
        brandingPageUI: action.payload,
      };
    case Types.SET_BRANDING_VIEW:
      return {
        ...state,
        brandingSelectedView: action.payload,
      };
    case Types.SET_CHECKOUT_LOADER:
      return {
        ...state,
        checkoutLoader: action.payload,
      };
    case Types.LOGIN_SESSION_FILTER:
      return {
        ...state,
        loginSessionFilter: action.payload,
      };
    case Types.SET_WEB_PAGE_SEARCH_RESULTS:
      return {
        ...state,
        webPageSearchResults: action.payload,
      };
    case Types.SET_SEARCHED_MODULE_NAME:
      return {
        ...state,
        searchedModuleName: action.payload,
      };
    case Types.SET_PROFILE_MFA_AUTH_DETAILS:
      return {
        ...state,
        profileMfaAuthDetails: action.payload,
      };
    case Types.SET_ONCHAIN_PAYMENT_METHOD:
      return {
        ...state,
        onChainPaymentMethodOn: action.payload,
      };
    case Types.SET_ONCHAIN_PAID_TOLERENCE:
      return {
        ...state,
        onChainPaidTolerence: action.payload,
      };
    case Types.SET_IS_DATA_AVAILABLE:
      return {
        ...state,
        isDataAvailable: action.payload,
      };
    case Types.SET_IS_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: action.payload,
      };
    case Types.SET_CAUGHT_ERROR_IN_STRAPI:
      return {
        ...state,
        caughtErrorInStrapi: action.payload,
      };
    case Types.SET_OPEN_RESTRICTED_KEY_CREATE_MODAL:
      return {
        ...state,
        openRestrictedKeyCreateModal: action.payload,
      };
    case Types.SET_PAYMENT_PAGE_SETTINGS:
      return {
        ...state,
        paymentPageSettings: action.payload,
      };
    case Types.SET_LAST_INVOICE_SAVED_IN:
      return {
        ...state,
        lastSavedInvoiceIn: action.payload,
      };
    case Types.SET_INVOICE_PREVIEW_DATA:
      return {
        ...state,
        invoicePreviewData: action.payload,
      };
    case Types.SET_ACCOUNT_PUBLIC_INFO:
      return {
        ...state,
        accountPublicInfo: action.payload,
      };
    case Types.SET_CONNECT_BRANDING_DETAILS:
      return {
        ...state,
        connectBrandingDetails: action.payload,
      };
    case Types.SET_PLATFORM_CONNECT_BRANDING:
      return {
        ...state,
        platformConnectBranding: action.payload,
      };
    case Types.SET_CUSTOM_FIELD_UPDATED_FROM:
      return {
        ...state,
        customFieldUpdatedFrom: action.payload,
      };
    case Types.SET_PATH_NAME:
      return {
        ...state,
        pathname: action.payload,
      };
    case Types.SET_WALLET_SELECTED_TRANSACTION:
      return {
        ...state,
        walletSelectedTransaction: action.payload,
      };
    case Types.SET_WALLET_HANDLE_MODAL_CLOSE:
      return {
        ...state,
        isWalletSendModalClose: action.payload,
      };
    case Types.SET_PAYOUT_MODAL_OPEN:
      return {
        ...state,
        payoutModalOpen: action.payload,
      };
    case Types.SET_PAYOUT_MODAL_DATA:
      return {
        ...state,
        payoutModalData: action.payload,
      };
    case Types.SET_PAYOUT_SCHEDULING_ENABLED:
      return {
        ...state,
        isPayoutSchedulingEnabled: action.payload,
      };
    case Types.SET_WALLET_PAYMENT_ADDRESS:
      return {
        ...state,
        walletPaymentAddress: action.payload,
      };
    case Types.SET_IS_WALLET_TRANSACTION_DRAWER_OPEN:
      return {
        ...state,
        isWalletTransactionDrawerOpen: action.payload,
      };
    case Types.SET_WALLET_TRANSACTION_FILTER:
      return {
        ...state,
        walletTransactionAppliedFilterObj: action.payload,
      };
    case Types.SET_AUTOMATIC_USER_LOGOUT_MODAL:
      return {
        ...state,
        showAutomaticUserLogoutModal: action.payload,
      };
    case Types.SET_WALLET_ASSET:
      return {
        ...state,
        walletAssets: action.payload,
      };
    case Types.SET_ACCOUNT_RESTRICTED_MODAL_DATA:
      return {
        ...state,
        accountRestrictedModalData: action.payload,
      };
    case Types.SET_INSTANT_SEND_NEXT_CLICKED:
      return {
        ...state,
        instantSendNextClicked: action.payload,
      };
    case Types.SET_INSTANT_SEND_BUTTON_CLICKED:
      return {
        ...state,
        instantSendButtonClicked: action.payload,
      };
    case Types.SET_DEFAULT_SELECTED_ASSET:
      return {
        ...state,
        defaultSelectedAsset: action.payload,
      };
    case Types.SET_PAYMENT_PROCESSING_RECALL_APIS:
      return {
        ...state,
        paymentProcessingRecallAPis: action.payload,
      };
    case Types.SET_SHOW_BTC_AMOUNT:
      return {
        ...state,
        showBtcAmount: action.payload,
      };
    case Types.SET_MAINTENANCE_DETAILS:
      return {
        ...state,
        maintenanceDetails: action.payload,
      };
    case Types.SET_SHOW_UNDER_MAINTENANCE_BAR:
      return {
        ...state,
        showMaintenanceBar: action.payload,
      };
    case Types.SET_ACCOUNT_ASSETS:
      return {
        ...state,
        accountAsset: action.payload,
      };
    case Types.SET_FLATFILE_NAME:
      return {
        ...state,
        flatfileName: action.payload,
      };
    case Types.SET_FLATFILE_CURRENCY_RATES:
      return {
        ...state,
        flatfileCurrencyRates: action.payload,
      };
    case Types.SET_FLATFILE_SHEET_DATA:
      return {
        ...state,
        flatfileWorkbookSheetData: action.payload,
      };
    case Types.SET_SEND_VIA_EMAIL_MODAL_API_DETAILS:
      return {
        ...state,
        SendViaEmailModalAPIDetails: action.payload,
      };
    case Types.SET_OPEN_SEND_VIA_EMAIL_MODAL:
      return {
        ...state,
        openSendViaEmailModal: action.payload,
      };
    case Types.SET_SELECTED_ASSET_CURRENCY:
      return {
        ...state,
        selectedAssetCurrencyState: action.payload,
      };

    default:
      return { ...state };
  }
};

export default common;
