import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import AccountInfo from "./AccountInfo";
import CloseAccount from "./CloseAccount";
import PublicInfo from "./PublicInfo";
import { find } from "lodash";
import {
  accountInfoLabel,
  publicInfoLabel,
  closeAccountLabel,
} from "../../messages";
import { Wallet, loadingMsg } from "@speed/common/src/components/messages";
import {
  setSettingFormChange,
  setSettingFormCloseAccount,
} from "../../../redux/common/actions";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { useFlags } from "launchdarkly-react-client-sdk";

function AccountDetails() {
  const [selectedTab, setSelectedTab] = useState("1");
  const dispatch = useDispatch();
  const history = useSelector((state) => state.common.history);
  const currentAccount = useSelector((state) => state.auth.currentAccount);
  const { adCloseaccountFe } = useFlags();
  const isWalletAccount = currentAccount?.account?.account_type === Wallet;

  let tabData = [
    {
      label: accountInfoLabel,
      value: "1",
      tabContent: currentAccount ? (
        <AccountInfo accountData={currentAccount?.account} />
      ) : (
        <BackdropLoader
          open={!currentAccount}
          alt="loading..."
          text={loadingMsg}
          customClass="loading-in-auth inside-loader"
        />
      ),
      route: "/settings/account-info",
    },
  ];

  !isWalletAccount &&
    tabData.push({
      label: publicInfoLabel,
      value: "2",
      tabContent: (
        <PublicInfo publicData={currentAccount.account.account_public_info} />
      ),
      route: "/settings/public-info",
    });

  adCloseaccountFe &&
    currentAccount?.role?.id === "role_owner" &&
    !currentAccount?.account?.closure_requested_at &&
    tabData.push({
      label: closeAccountLabel,
      value: 3,
      tabContent: <CloseAccount />,
      route: isWalletAccount
        ? "/settings/close-wallet-account"
        : "/settings/close-account",
    });

  const handleChange = (_event, newValue) => {
    dispatch(setSettingFormChange(false));
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (history) {
      let currentRoute = find(
        tabData,
        (tab) => tab.route === history.location.pathname
      );
      if (currentRoute) {
        setSelectedTab(currentRoute.value);
        dispatch(setSettingFormCloseAccount(currentRoute.value === 3));
      }
    }
  }, [history, history && history.location.pathname]);

  return (
    <CustomTab
      onChange={handleChange}
      tabData={tabData}
      selectedTab={selectedTab}
      setTabRoute={(routeUrl) => history.push(routeUrl)}
      sx={{ position: "relative" }}
      height={"45px"}
    />
  );
}

export default AccountDetails;
