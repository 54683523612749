import React from "react";
import { Box } from "@mui/system";
import "../../../assets/styles/manage-apps.scss";
import TestConnection from "./TestConnection";

const ShopifyInstallationStep5 = ({ stepOneVerified, verifySteps }) => {
  return (
    <Box className="fullscreen-modal-box">
      <Box className="modal-container-grid grid-box">
        <Box className="inner-grid" style={{ overflow: "auto" }}>
          <TestConnection
            stepOneVerified={stepOneVerified}
            verifySteps={verifySteps}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ShopifyInstallationStep5;
