import React, { forwardRef, useState, useEffect } from "react";
import Text from "@speed/common/src/components/Text/Text";
import { Grid } from "@mui/material";
import { Input } from "@speed/common/src/components/Input/Input";
import { dontHaveAccountMsg, or, signup } from "../messages";
import { Button } from "@speed/common/src/components/Button/Button";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { useFormik } from "formik";
import * as yup from "yup";
import Collapse from "@mui/material/Collapse";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { verifyUser } from "../../redux/auth/actions";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { serverErrorStatusCodes } from "../constants";
import {
  invalidEmail,
  loadingMsg,
  emailLabel,
  emailPlaceholder,
  next,
} from "@speed/common/src/components/messages";
import LoginWithSocialMedia from "../Common/LoginWithSocialMedia";
import { handleSSOLoginRedirection } from "@speed/common/src/components/constants";
import TurnstileRecaptcha from "@speed/common/src/components/Turnstile";
import { useFlags } from "launchdarkly-react-client-sdk";

const EmailScreen = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const { turnstileFe } = useFlags();

  const history = useSelector((state) => state.common.history);
  // const { ssoGoogleauthFe, ssoAppleauthFe, localhostSsoFe } = useFlags();

  // const onlyLdEmailLoginEnabled = !(ssoGoogleauthFe || ssoAppleauthFe);
  const onlyLdEmailLoginEnabled = false;
  const validationSchema = yup.object({
    email: yup.string().email(invalidEmail),
  });
  useEffect(() => {
    if (props.email) setFieldValue("email", props.email);
  }, [props.email]);

  const handleEmail = (emailValues) => {
    setLoading(true);
    const verifyUserData = {
      email: emailValues.email,
      turnstileToken: token,
    };
    dispatch(verifyUser(verifyUserData)).then((res) => {
      !res && setToken("");
      if (res && res.signup_method !== "email") {
        const socialMediaLoginRedirection = {
          google: () => handleSSOLoginRedirection("google", {}, false),
          apple: () => handleSSOLoginRedirection("apple", {}, false),
          // google: () => handleSSOLoginRedirection("google", {}, localhostSsoFe),
          // apple: () => handleSSOLoginRedirection("apple", {}, localhostSsoFe),
        };
        socialMediaLoginRedirection?.[res.signup_method]?.();
        return;
      }
      setLoading(false);
      if (serverErrorStatusCodes.includes(res)) resetForm();
      else if (res) {
        props.setVerifiedUserData(res);
        props.handleEmailScreen(emailValues.email);
      }
      setSubmitting(false);
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleEmail,
  });

  const {
    values,
    touched,
    errors,
    setTouched,
    setFieldValue,
    isValid,
    isSubmitting,
    dirty,
    setSubmitting,
    handleSubmit,
    resetForm,
  } = formik;

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  return (
    <Collapse orientation="horizontal" in={props.checked} ref={ref}>
      <Grid
        item
        className="email-password-grid sign-in-grid"
        component="form"
        onSubmit={handleSubmit}
      >
        <BackdropLoader
          open={isLoading}
          alt="loading..."
          text={loadingMsg}
          customClass="loading-in-auth"
        />
        {!onlyLdEmailLoginEnabled && (
          <Grid>
            <LoginWithSocialMedia
              ldFlagForGoogle={true}
              ldFlagForApple={true}
              // ldFlagForGoogle={ssoGoogleauthFe}
              // ldFlagForApple={ssoAppleauthFe}
            />
            <Text
              size={16}
              sx={{
                margin: "25px 0px",
                color: "#B5B9C5",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {or}
            </Text>
          </Grid>
        )}
        <Input
          fullWidth
          {...(onlyLdEmailLoginEnabled && {
            customClass: "margin-top36",
          })}
          label={emailLabel}
          placeholder={emailPlaceholder}
          name="email"
          value={values.email}
          type="email"
          onBlur={() => setTouched({ ...touched, email: true })}
          onChange={(e) => {
            setSubmitting(false);
            setTouched({ ...touched, email: false });
            setFieldValue("email", e.target.value);
          }}
          error={touched.email && Boolean(errors.email)}
        />
        <InputErrorMessage {...defaultErrorMessageProps} inputName="email" />
        {turnstileFe && (
          <TurnstileRecaptcha setToken={setToken} token={token} />
        )}
        <Button
          fullWidth
          className="submit-signin-btn margin-top30"
          label={next}
          iconPosition="end"
          icon="rightArrowIcon"
          type="submit"
          disabled={
            !(isValid && dirty) ||
            isSubmitting ||
            (turnstileFe && !token.length)
          }
        />

        <Text
          size={16}
          font="semibold"
          align="center"
          className="default-text margin-top30 margin-bottom20"
        >
          {dontHaveAccountMsg}
          <CustomLink
            size="large"
            bold
            className="margin-left10"
            onClick={() => history.push("/register")}
          >
            {signup}
          </CustomLink>
        </Text>
      </Grid>
    </Collapse>
  );
});

export default EmailScreen;
