import GroupIcon from "@mui/icons-material/Group";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";
import InfoIcon from "@mui/icons-material/Info";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddIcon from "@mui/icons-material/Add";
import AutoFixHighSharpIcon from "@mui/icons-material/AutoFixHighSharp";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import PersonIcon from "@mui/icons-material/Person";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import NotesIcon from "@mui/icons-material/Notes";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SendIcon from "@mui/icons-material/Send";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DataObjectIcon from "@mui/icons-material/DataObject";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import EditIcon from "@mui/icons-material/Edit";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import EmailIcon from "@mui/icons-material/Email";
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GroupWorkOutlinedIcon from "@mui/icons-material/GroupWorkOutlined";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import DescriptionIcon from "@mui/icons-material/Description";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import StoreIcon from "@mui/icons-material/Store";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export const defaultUser =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/default-user.svg";
export const brokenImage =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/broken-img.jpg";
export const QRLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/QR-logo.svg";
export const googleIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/google-icon.svg";
export const appleIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/apple-icon.svg";
export const speedPreloader =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/speed-preloader.gif";
export const retryImage =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/retry-img.svg";
export const cashbackRedeemImage =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/cashback-redeem.svg";
export const cashbackSuccessRedeemGif =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/cashback-success-redeem.gif";
export const tickGif =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/tick.gif";
export const speedLogoOnboarding =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/speed-logo-onboarding.svg";
export const tickIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/tick.svg";
export const errorGif =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/error.gif";
export const notFoundGif =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/404.gif";
export const secretKey =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/secret-key.png";
export const mainLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/logo.svg";
export const whiteLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/logo-white.svg";
export const emptyPayout =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty_payouts.svg";
export const emptySwap =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty-swaps.svg";
export const emptyPartner =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty-partners.svg";
export const bottomCircle =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/bottom-circle.png";
export const leftCircle =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/left-circles.png";
export const rightCircle =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/right-circle.png";
export const underMaintenance =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/under-maintanance.svg";
export const accessDenied =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/access-denied.svg";
export const emptyInvite =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty_invite.png";
export const emptyEvents =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty_events.png";
export const withdrawalLinkIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/icons-withdrawal-link.svg";
export const lnDevLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/lndev/lndev-logo.svg";
export const twitterLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/lndev/twitter.svg";
export const slackLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/lndev/slack.svg";
export const satoshiLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/lndev/ic-satoshi.svg";
export const lightningLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/lndev/lightning.svg";
export const btnIcQrCodeLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/lndev/btn-ic-qr-code.svg";
export const addQrCodeLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/lndev/ic-add-qr-code.svg";
export const warningLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/lndev/ic-warning.svg";
export const bitCoinLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/bitCoinLogo.svg";
export const foxHubLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/foxhub.jpg";
export const downArrowIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/icons-down-arrow.svg";
export const rightArrowIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/icons-right-arrow.svg";
export const usdcLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/usdc-logo.svg";
export const peopleLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/people.png";
export const posMobileLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/posMobile.png";
export const onboardingVectorBgImage =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/vector.png";

export const onChainBtcWithColor =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/bitcoin-btc-logo-color.png";
export const onChainBtc =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/bitcoin-btc-logo.png";
export const lightningLogoWithColor =
  process.env.REACT_APP_IMAGE_ICON_S3_URL +
  "/app/bitcoin-lightning-logo-color.png";
export const lightningPaymentPageLogo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/bitcoin-lightning-logo.png";
export const vectorImage =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/vector.png";
export const transferIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/icons-transfer.svg";
export const emptyTransfers =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/empty-transfers.svg";
export const loaderSpinner =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/loader-spinner";
export const linkLoader =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/link-loader.gif";
export const dummyImage =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/dummy-image.png";
export const limitedOfferImage =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/limitedTimeOfferLabel.png";
export const sellBitcoin =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/sell-bitcoin.svg";
export const buyBitcoin =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/buy-bitcoin.svg";
export const rewards =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/rewards.svg";
export const referAndEarn =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/refer-and-earn.svg";
export const downloadGo =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/download-go.svg";
export const connectSideBarIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/Connet.svg";
export const manageNode =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/manage-node.svg";
export const feedbackIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/feedback-icon.png";
export const noFeedbackImage =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/no-feedbacks.svg";
export const promoLogsIcons =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/promo-logs.svg";

export const buttonIcons = {
  magicLink: <AutoFixHighSharpIcon />,
  help: <HelpOutlineIcon />,
  google: <img src={googleIcon} height={20} width={20} alt="google-icon" />,
  apple: <img src={appleIcon} height={20} width={20} alt="apple-icon" />,
  signoutIcon: <RemoveCircleOutlinedIcon />,
  rightArrowIcon: <KeyboardArrowRightIcon height={24} width={24} />,
  closeIcon: <CancelIcon />,
  addIcon: <AddIcon sx={{ width: "25px", height: "25px" }} />,
  checkIcon: <CheckCircleIcon />,
  filterIcon: <FilterAltIcon />,
  visibilityIcon: <VisibilityIcon />,
  sendIcon: <SendIcon />,
  editIcon: <EditIcon />,
  chatIcon: <ChatBubbleOutlineIcon />,
  calenderIcon: <CalendarTodayIcon />,
  arrowDownIcon: <ArrowDownwardIcon />,
  downloadIcon: <DownloadForOfflineIcon />,
  filterList: <FilterListIcon />,
  unarchiveIcon: <UnarchiveIcon />,
  arrowOutwardIcon: <ArrowOutwardIcon />,
  emailIcon: <EmailIcon />,
  reportIcon: <DescriptionIcon />,
  expandCircleIcon: <ExpandCircleDownOutlinedIcon />,
  uploadIcon: <UploadFileIcon />,
};
export const accordionIcons = {
  groupUsers: <GroupIcon />,
  expandMore: <ExpandMoreIcon />,
  calenderIcon: <CalendarTodayIcon />,
  filterList: <FilterListIcon />,
  info: <InfoIcon />,
  key: <VpnKeyOutlinedIcon />,
  user: <AccountCircleIcon />,
  editIcon: (
    <EditSharpIcon
      sx={{ height: "15px", width: "15px", color: "#848B9E !important" }}
    />
  ),
  addIcon: <AddIcon />,
  removeIcon: <RemoveIcon />,
  downArrowIcon: (
    <img src={downArrowIcon} height={24} width={24} alt="down-arrow-icon" />
  ),
  rightArrowIcon: (
    <img src={rightArrowIcon} height={24} width={24} alt="down-arrow-icon" />
  ),
  rightArrow: <KeyboardArrowDownIcon />,
};

export const accountIcons = (iconSx = {}) => {
  return {
    Merchant: <StoreIcon sx={iconSx} />,
    Wallet: <AccountBalanceWalletIcon sx={iconSx} />,
    Business: <StoreIcon sx={iconSx} />,
    Joint: <PeopleAltRoundedIcon />,
    Individual: <PersonIcon />,
  };
};

export const timelineIcons = {
  noteIcon: <NotesIcon />,
  paymentsOutlined: <PaymentsOutlinedIcon />,
  radioButtonCheckedIcon: <RadioButtonCheckedIcon />,
};

export const searchbarIcons = {
  getStarted: <RocketLaunchOutlinedIcon />,
  dashboard: <GridViewOutlinedIcon />,
  receivePayments: <ArrowCircleLeftOutlinedIcon />,
  sendPayments: <ArrowCircleRightOutlinedIcon />,
  payments: <PaymentsOutlinedIcon />,
  checkoutLinks: <ShoppingCartCheckoutOutlinedIcon />,
  checkoutSessions: <ScheduleRoundedIcon />,
  paymentLinks: <QrCodeScannerIcon />,
  balances: <AccountBalanceWalletOutlinedIcon />,
  withdraws: <ArrowOutwardIcon />,
  swaps: <PublishedWithChangesIcon />,
  transactions: <ReceiptLongOutlinedIcon />,
  customers: <PeopleAltOutlinedIcon />,
  orders: <ShoppingCartOutlinedIcon />,
  products: <Inventory2OutlinedIcon />,
  manageApps: <DashboardCustomizeOutlinedIcon />,
  oneQr: <QrCode2OutlinedIcon />,
  developers: <DataObjectIcon />,
  settings: <SettingsOutlinedIcon />,
  partners: <GroupWorkOutlinedIcon />,
  transfers: (
    <img
      style={{ filter: "invert(40%)", height: "20px", width: "20px" }}
      src={transferIcon}
      alt="transfer-icon"
    />
  ),
  connectIcon: (
    <img
      src={connectSideBarIcon}
      style={{ filter: "invert(40%)", height: "20px", width: "20px" }}
      alt="connect-icon"
    />
  ),
};

export const sortingIcons = {
  asc: <ArrowUpwardIcon className="filter-icon" />,
  desc: <ArrowDownwardIcon className="filter-icon" />,
};

// Svg images
export const checkBoxOn = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M18 .5c.966 0 1.841.392 2.475 1.025A3.489 3.489 0 0 1 21.5 4v14c0 .966-.392 1.841-1.025 2.475A3.489 3.489 0 0 1 18 21.5H4a3.489 3.489 0 0 1-2.475-1.025A3.489 3.489 0 0 1 .5 18V4c0-.966.392-1.841 1.025-2.475A3.489 3.489 0 0 1 4 .5z"
        stroke="#2C61E4"
        fill="#2A67FF"
      />
      <path
        d="M10.634 14.44a.978.978 0 0 1-1.386 0l-2.961-2.97a.984.984 0 0 1 0-1.388.978.978 0 0 1 1.385 0l2.268 2.275 4.388-4.4a.978.978 0 0 1 1.385 0 .984.984 0 0 1 0 1.39l-5.08 5.094z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export const checkBoxOff = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 .5c.966 0 1.841.392 2.475 1.025A3.489 3.489 0 0 1 21.5 4v14c0 .966-.392 1.841-1.025 2.475A3.489 3.489 0 0 1 18 21.5H4a3.489 3.489 0 0 1-2.475-1.025A3.489 3.489 0 0 1 .5 18V4c0-.966.392-1.841 1.025-2.475A3.489 3.489 0 0 1 4 .5z"
      fill="#FFF"
      stroke="#848B9E"
      fillRule="evenodd"
      strokeOpacity=".5"
    />
  </svg>
);

export const checkBoxOnDisabled = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M18 .5c.966 0 1.841.392 2.475 1.025A3.489 3.489 0 0 1 21.5 4v14c0 .966-.392 1.841-1.025 2.475A3.489 3.489 0 0 1 18 21.5H4a3.489 3.489 0 0 1-2.475-1.025A3.489 3.489 0 0 1 .5 18V4c0-.966.392-1.841 1.025-2.475A3.489 3.489 0 0 1 4 .5z"
        stroke="#DBE5FF"
        fill="#E8EFFF"
      />
      <path
        d="M10.634 14.44a.978.978 0 0 1-1.386 0l-2.961-2.97a.984.984 0 0 1 0-1.388.978.978 0 0 1 1.385 0l2.268 2.275 4.388-4.4a.978.978 0 0 1 1.385 0 .984.984 0 0 1 0 1.39l-5.08 5.094z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export const checkBoxOffDisabled = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 .5c.966 0 1.841.392 2.475 1.025A3.489 3.489 0 0 1 21.5 4v14c0 .966-.392 1.841-1.025 2.475A3.489 3.489 0 0 1 18 21.5H4a3.489 3.489 0 0 1-2.475-1.025A3.489 3.489 0 0 1 .5 18V4c0-.966.392-1.841 1.025-2.475A3.489 3.489 0 0 1 4 .5z"
      fill="#FAFAFA"
      stroke="#848B9E"
      fillRule="evenodd"
      strokeOpacity=".1"
    />
  </svg>
);

export const fill = process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/Fill.svg";

export const imagePlaceholder = `${process.env.REACT_APP_IMAGE_ICON_S3_URL}/app/node-logo-placeholder.png`;
export const maintenanceInProgress =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/maintenance-in-progress.png";
export const promoCodeIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/promo-code.svg";
export const selectedPromoCodeIcon =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/selected-promo-code.svg";
export const usdtSymbol =
  process.env.REACT_APP_IMAGE_ICON_S3_URL + "/app/usdt-icon.svg";
