import { Box, Grid, MenuItem, Skeleton, TextField } from "@mui/material";
import {
  addItemLabel,
  addInvoiceNewProduct,
  addOneTimeItem,
  itemDetailLabel,
  itemLabel,
  itemPlaceHolder,
  priceLabel,
  productLabel,
  qtyLabel,
  saveAndAdd,
  itemRequireMsg,
  invoiceAmountExceededMsg,
} from "../messages";
import { useCallback, useEffect, useState } from "react";
import Text from "@speed/common/src/components/Text/Text";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ChangeItemCurrencyModal from "./ChangeItemCurrencyModal";
import {
  getAmountByCurrency,
  getCurrencyObj,
  getFormTotalAmount,
  getTotalAmount,
} from "@speed/common/src/components/constants";
import { FieldArray, FormikProvider } from "formik";
import Label from "@speed/common/src/components/Label/Label";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import _ from "lodash";
import classNames from "classnames";
import { Input } from "@speed/common/src/components/Input/Input";
import InputMask from "@speed/common/src/components/MaskInput";
import Button from "@speed/common/src/components/Button/Button";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { cancel } from "@speed/common/src/components/messages";
import CustomSelect from "@speed/common/src/components/Select/Select";
import MoreActionButton from "./MoreActionButton";
import { useSelector } from "react-redux";
import { callAPIInterface, getWebSearchAPIResults } from "../constants";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { save } from "@speed/common/src/messages";

function InvoiceItems({
  saveLastSavedTime,
  formik,
  labelElement,
  createNewElement,
  disableEditFormElement,
  setDisableEditFormElement,
  invoiceAmountExceeded,
  setInvoiceAmountExceeded,
}) {
  const { values, setFieldValue, setTouched, touched, errors } = formik;
  const maxNumOfItems = 20;
  const productLength = values?.products?.length;
  const searchLineItemString = `is:product sort:-created active:true`;

  const [itemName, setItemName] = useState("");
  const [openCurrencyModal, setOpenCurrencyModal] = useState(false);
  const [editProductIndex, setEditProductIndex] = useState();
  const [addNewItem, setAddNewItem] = useState(false);

  const [lineItems, setLineItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchNextPage, setSearchNextPage] = useState(null);
  const [lineItemsFromResult, setLineItemsFromResult] = useState([]);
  const [hasMoreLineItemFromResult, setHasMoreLineItemsFromResult] =
    useState(true);
  const [lineItemSkeleton, setLineItemSkeleton] = useState(false);
  const [hasMoreLineItems, setHasMoreLineItems] = useState(true);

  const hasMoreItem = searchQuery
    ? hasMoreLineItemFromResult
    : hasMoreLineItems;
  const liveMode = useSelector((state) => state.auth.liveMode);

  const addNewLineItemButtonLabel = `${addOneTimeItem[0]} ${
    itemName ? `'${itemName}' as ` : ""
  } ${addOneTimeItem[1]}`;
  const addNewProductButtonLabel = `${addInvoiceNewProduct[0]} ${
    itemName ? `'${itemName}' as ` : ""
  } ${addInvoiceNewProduct[1]}`;

  const resetItemStates = () => {
    setFieldValue("selectedItem", null);
    setFieldValue("singleProduct", null);
    setFieldValue("selectedItemPrice", null);
    setItemName("");
    setSearchQuery("");
    setDisableEditFormElement(false);
    setAddNewItem(false);
    saveLastSavedTime();
  };

  const renderItemHeaderSection = () => (
    <Box
      className={classNames(
        "invoice-items-header",
        disableEditFormElement && "disable-edit-section"
      )}
    >
      {labelElement(itemLabel)}
      {values?.customer && (
        <>
          <Box
            className="select-item-currency"
            onClick={() => {
              setOpenCurrencyModal(true);
              saveLastSavedTime();
            }}
          >
            <Text className="default-text" size={14}>
              {values?.currency?.code} ({values?.currency?.symbol})
            </Text>
            <KeyboardArrowDownIcon className="select-currency-icon" />
          </Box>
          {openCurrencyModal && (
            <ChangeItemCurrencyModal
              formik={formik}
              openModal={openCurrencyModal}
              setOpenModal={(value) => setOpenCurrencyModal(value)}
            />
          )}
        </>
      )}
    </Box>
  );

  const renderAutoCompleteElement = () => {
    const commonAddItemValues = () => {
      setFieldValue("singleProduct", {
        name: itemName || "",
        quantity: 1,
        price: 0,
      });
      setDisableEditFormElement(true);
      setAddNewItem(true);
    };

    const handleAddOneTimeItem = () => {
      saveLastSavedTime();
      commonAddItemValues();
    };

    const handleAddProduct = () => {
      saveLastSavedTime();
      commonAddItemValues();
    };

    return (
      <>
        <CustomAutoComplete
          {...(addNewItem && {
            open: true,
          })}
          disablePortal
          disableClearable
          customClass="invoice-item-selection-menu"
          showLabel={false}
          options={searchQuery ? lineItemsFromResult : lineItems}
          onChange={(_e, value) => {
            saveLastSavedTime();
            setDisableEditFormElement(true);
            setSearchQuery("");
            setFieldValue("selectedItem", value);
            setFieldValue("selectedItemPrice", value.prices[0]);
            setFieldValue("singleProduct", {
              name: value.name,
              quantity: 1,
              price: value.prices[0]?.unit_amount,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className="invoice-item-input"
              placeholder={itemPlaceHolder}
              value={searchQuery}
              onFocus={() => {
                if (!lineItems?.length) {
                  fetchLineItems();
                }
              }}
              onChange={(e) => {
                const inputValue = e.target.value;
                setItemName(inputValue);
                setSearchQuery(inputValue);
              }}
              onBlur={() => {
                setItemName("");
                setSearchQuery("");
                setAddNewItem(false);
                setTouched({ ...touched, selectedItem: true });
                setTouched({ ...touched, products: true });
              }}
            />
          )}
          noOptionsText={
            <>
              {createNewElement(
                addNewLineItemButtonLabel,
                handleAddOneTimeItem
              )}
              {/* {createNewElement(addNewProductButtonLabel, handleAddProduct)} */}
              {lineItemSkeleton && (
                <Box marginY={1} padding={1} borderRadius={1}>
                  <Skeleton
                    animation="wave"
                    width="50%"
                    sx={{ bgcolor: "#eaeef1 !important" }}
                  />
                </Box>
              )}
            </>
          }
          value={values.selectedItem}
          getOptionLabel={(option) => option.name ?? ""}
          renderOption={(optionProps, option, state) => {
            const index = state.index;
            const lineItemResults = searchQuery
              ? lineItemsFromResult
              : lineItems;
            const priceArray = option?.prices || [];
            return (
              <>
                {!index && (
                  <>
                    {createNewElement(
                      addNewLineItemButtonLabel,
                      handleAddOneTimeItem
                    )}
                    {/* {createNewElement(
                      addNewProductButtonLabel,
                      handleAddProduct
                    )} */}
                    <CustomDivider sx={{ margin: "5px 0px" }} />
                  </>
                )}

                <Box
                  {...optionProps}
                  key={option?.id}
                  className="pointer-cursor invoice-items-menu-box"
                >
                  <Text
                    className="default-text item-name"
                    font="regular"
                    size={14}
                    variant="body1"
                  >
                    {option.name}
                  </Text>
                  <Text
                    className="grey-text"
                    font="regular"
                    size={14}
                    variant="body1"
                  >
                    {priceArray?.length > 1 ? (
                      <>{`${priceArray?.length} prices`}</>
                    ) : (
                      <>
                        {getCurrencyObj(priceArray?.[0]?.currency)?.symbol}
                        {getAmountByCurrency(
                          priceArray?.[0]?.unit_amount,
                          priceArray?.[0]?.currency
                        )}
                      </>
                    )}
                  </Text>
                </Box>
                {lineItemSkeleton && index === lineItemResults.length - 1 && (
                  <Box
                    sx={{
                      margin: "10px",
                      borderRadius: "4px",
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      width="50%"
                      sx={{ bgcolor: "#eaeef1 !important" }}
                    />
                  </Box>
                )}
              </>
            );
          }}
          ListboxProps={{
            role: "list-box",
            onScroll: (event) => {
              const listboxNode = event.currentTarget;
              if (
                listboxNode.scrollTop + listboxNode.clientHeight >=
                listboxNode.scrollHeight - 10
              ) {
                if (hasMoreItem) {
                  searchQuery
                    ? loadMoreLineItemsFromResult()
                    : loadMoreLineItems();
                }
              }
            },
          }}
        />
        {touched.products && Boolean(errors.products) && (
          <AlertMessage
            className="margin-top14"
            severity="error"
            message={itemRequireMsg}
          />
        )}
      </>
    );
  };

  const renderItemPriceSection = ({ isMenuSelect }) => {
    return isMenuSelect ? (
      <CustomSelect
        showLabel={false}
        className="invoice-item-details-selection-menu"
        displayEmpty
        value={values.selectedItemPrice}
        MenuProps={{
          id: "invoice-item-price-select",
        }}
        onChange={(e) => {
          const value = e.target.value;
          setFieldValue("selectedItemPrice", value);
          setFieldValue("singleProduct", {
            ...values.singleProduct,
            price: value?.unit_amount,
          });
        }}
        renderValue={(value) => {
          if (value) {
            let typeObj = _.find(values?.selectedItem?.prices, {
              unit_amount:
                typeof value === "object" ? value.unit_amount : value,
            });
            return getAmountByCurrency(typeObj?.unit_amount, typeObj?.currency);
          }
        }}
        setCustomIcon={(iconProps) => <UnfoldMoreIcon {...iconProps} />}
      >
        {values?.selectedItem?.prices?.map((option) => {
          return (
            <MenuItem
              className="invoice-details-items-menu-box"
              key={option.id}
              value={option}
            >
              {getAmountByCurrency(option?.unit_amount, option?.currency)}
            </MenuItem>
          );
        })}
      </CustomSelect>
    ) : (
      <InputMask
        onChange={(e) => {
          setFieldValue("singleProduct", {
            ...values.singleProduct,
            price: e.target.value,
          });
        }}
        value={values.singleProduct?.price}
        placeholder="0.00"
      />
    );
  };

  const renderCommonItemDetailSection = ({
    isFromAddClick = false,
    handleCancel,
    handleSaveAndAdd,
    handleSave,
  }) => {
    const fromEditSection = values.selectedItem || !isFromAddClick;
    const disableSaveAction =
      !values.singleProduct.name ||
      values.singleProduct.quantity <= 0 ||
      values.singleProduct.price <= 0;
    return (
      <FormikProvider value={formik}>
        <Box display="flex" justifyContent="space-between">
          <Text className="default-text" size={16}>
            {itemDetailLabel}
          </Text>
          <Text className="default-text" size={16}>
            {values?.currency?.symbol}
            {(values.singleProduct &&
              getAmountByCurrency(
                values.singleProduct.price * values.singleProduct.quantity,
                values?.currency?.code
              )) ||
              "0.00"}
          </Text>
        </Box>

        <Box className="item-details">
          <FieldArray
            name="products"
            render={() => (
              <>
                <Grid
                  container
                  justifyContent="space-between"
                  className="margin-top25"
                >
                  <Grid item lg={5.6} md={5.6}>
                    <Label className="invoice-product-label">
                      {productLabel}
                    </Label>
                  </Grid>
                  <Grid item lg={3} md={3}>
                    <Label className="invoice-product-label">{qtyLabel}</Label>
                  </Grid>
                  <Grid item lg={3} md={3}>
                    <Label className="invoice-product-label">
                      {priceLabel}
                    </Label>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between" height="42px">
                  <Grid item lg={5.5} md={5.6}>
                    <Input
                      disabled={fromEditSection}
                      className="invoice-item-details-product-input"
                      fullWidth
                      value={values?.singleProduct?.name}
                      showLabel={false}
                      onChange={(e) => {
                        setFieldValue("singleProduct", {
                          ...values?.singleProduct,
                          name: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item lg={3} md={3}>
                    <Input
                      value={values?.singleProduct?.quantity}
                      onChange={(e) => {
                        setFieldValue("singleProduct", {
                          ...values.singleProduct,
                          quantity: +e.target.value,
                        });
                      }}
                      showLabel={false}
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 4);
                      }}
                    />
                  </Grid>
                  <Grid item lg={3} md={3}>
                    {renderItemPriceSection({
                      isMenuSelect: values.selectedItem,
                    })}
                  </Grid>
                </Grid>
              </>
            )}
          />

          <Box
            className={classNames(
              "item-save-section",
              values.products.length > 0 && "border-bottom-none"
            )}
          >
            <Button
              disableRipple
              className="cancel-btn"
              variant="outlined"
              label={cancel}
              onClick={handleCancel}
            />
            {productLength <
              (isFromAddClick ? maxNumOfItems - 1 : maxNumOfItems) && (
              <Button
                disabled={disableSaveAction}
                variant="outlined"
                label={saveAndAdd}
                onClick={handleSaveAndAdd}
              />
            )}
            <Button
              disabled={disableSaveAction}
              className="save-btn"
              variant="contained"
              label={save}
              onClick={handleSave}
            />
          </Box>
        </Box>
      </FormikProvider>
    );
  };

  const renderAddItemDetailSection = () => {
    const handleCancel = () => {
      resetItemStates();
    };

    const handleNewItemSaveAndAdd = async () => {
      await handleAddItemSave();
      setAddNewItem(true);
    };

    const handleAddItemSave = () => {
      resetItemStates();
      saveLastSavedTime(true);
      if (values.singleProduct) {
        const commonObj = {
          name: values.singleProduct.name,
          prices: values.selectedItemPrice || {
            currency: values.currency.code,
            unit_amount: +values.singleProduct.price,
          },
        };

        const productsArray = [
          ...values.products,
          {
            ...commonObj,
            quantity: values.singleProduct.quantity,
          },
        ];
        showInvoiceAmountExceededMsg(productsArray);
        setFieldValue("products", productsArray);
      }
    };

    return (
      <>
        {renderCommonItemDetailSection({
          isFromAddClick: true,
          handleCancel,
          handleSaveAndAdd: handleNewItemSaveAndAdd,
          handleSave: handleAddItemSave,
        })}
      </>
    );
  };

  const showInvoiceAmountExceededMsg = (productsArray) => {
    const totalAmount = getTotalAmount(productsArray, "api-data");
    setFieldValue("invoiceTotalAmount", totalAmount);

    const paramsValues = {
      amount: totalAmount,
      currency: values?.currency,
    };
    const totalAmountInBTC = getFormTotalAmount(
      paramsValues,
      values?.currentExchangeRate
    )?.split(" ")?.[0];

    setInvoiceAmountExceeded(totalAmountInBTC > 5);
  };

  const renderItemListingSection = ({ product, index }) => {
    const handleClickDeleteItem = () => {
      values.products.splice(index, 1);
      setFieldValue("products", values.products);
      saveLastSavedTime(true);
      showInvoiceAmountExceededMsg(values.products);
    };

    const handleClickEditItem = () => {
      const selectedProduct = lineItems.find(
        (item) => item.name === product.name
      );
      setFieldValue("selectedItem", selectedProduct);
      setFieldValue("selectedItemPrice", product.prices);
      setFieldValue("singleProduct", {
        name: product?.name,
        quantity: product?.quantity,
        price: +product?.prices?.unit_amount,
      });
      setEditProductIndex(index);
      setDisableEditFormElement(true);
      saveLastSavedTime();
    };

    const buttonData = [
      {
        iconElement: <DeleteIcon />,
        onClick: handleClickDeleteItem,
      },
      {
        iconElement: <ModeEditIcon />,
        onClick: handleClickEditItem,
      },
    ];

    return (
      <Box
        className={classNames(
          "added-product-details",
          disableEditFormElement && "disable-edit-section"
        )}
      >
        <Box className="product-name-quantity">
          <Text size={16} variant="h4" className="default-text" font="regular">
            {product.name} X {product.quantity}
          </Text>
        </Box>
        <Box className="invoice-product-price">
          <Text size={16} variant="h4" className="default-text" font="regular">
            {values.currency.symbol}
            {getAmountByCurrency(
              product?.prices?.unit_amount * product.quantity,
              values.currency.code
            ) || "0.00"}
          </Text>
          <Box className="invoice-product-action-box">
            <MoreActionButton buttonData={buttonData} />
          </Box>
        </Box>
      </Box>
    );
  };

  const renderEditItemDetailSection = ({ index }) => {
    const handleCancel = () => {
      resetItemStates();
      setEditProductIndex();
    };

    const handleEditItemSaveAndAdd = () => {
      handleEditItemSave();
      setAddNewItem(true);
    };

    const handleEditItemSave = () => {
      if (values.singleProduct) {
        const price = values.selectedItem
          ? values.selectedItemPrice
          : {
              currency: values.currency.code,
              unit_amount: +values.singleProduct.price,
            };
        values.products[index] = {
          name: values.singleProduct.name,
          quantity: values.singleProduct.quantity,
          prices: price,
        };
      }
      resetItemStates();
      setEditProductIndex();
      saveLastSavedTime(true);
      showInvoiceAmountExceededMsg(values.products);
    };

    return (
      <>
        {renderCommonItemDetailSection({
          handleCancel,
          handleSaveAndAdd: handleEditItemSaveAndAdd,
          handleSave: handleEditItemSave,
        })}
      </>
    );
  };

  const getPriceFilterProducts = (productsArray) => {
    const filterNewProducts = productsArray
      ?.map((product) => {
        const filteredPriceArray = product?.prices?.data?.filter(
          (price) => price?.currency === values?.currency?.code
        );
        return (
          filteredPriceArray?.length > 0 && {
            ...product,
            prices: filteredPriceArray,
          }
        );
      })
      .filter(Boolean);
    return filterNewProducts;
  };

  const getAutocompleteInputElement = () =>
    document.querySelector(".invoice-item-input input");

  const getNormalInputElement = () =>
    document.querySelector(".invoice-item-details-product-input input");

  const getLineItems = async (lines) => {
    setLineItemSkeleton(true);
    await getWebSearchAPIResults(searchLineItemString, searchNextPage)
      .then((res) => {
        setLineItemSkeleton(false);
        if (!res.has_more) {
          setHasMoreLineItems(false);
          setSearchNextPage(null);
        } else {
          setSearchNextPage(res?.next_page);
        }
        const filteredProductsWithSelectedPrice = getPriceFilterProducts(
          res.data
        );
        setLineItems(lines.concat(filteredProductsWithSelectedPrice));
      })
      .catch(() => {
        setLineItemSkeleton(false);
      });
  };

  const fetchLineItems = () => {
    setLineItems([]);
    setHasMoreLineItems(true);
    getLineItems([]);
  };

  const lineItemSearchResults = () => {
    return getWebSearchAPIResults(
      `${searchLineItemString} ${searchQuery}`,
      searchNextPage
    );
  };

  const getLineItemsFromResult = (lines) => {
    setLineItemSkeleton(true);
    lineItemSearchResults()
      .then((res) => {
        setLineItemSkeleton(false);
        if (!res.has_more) {
          setHasMoreLineItemsFromResult(false);
          setSearchNextPage(null);
        } else {
          setSearchNextPage(res?.next_page);
        }
        const filteredProductsWithSelectedPrice = getPriceFilterProducts(
          res.data
        );
        setLineItemsFromResult(lines.concat(filteredProductsWithSelectedPrice));
      })
      .catch(() => {
        setLineItemSkeleton(false);
      });
  };

  const clearMemoizedCache = () => {
    getWebSearchAPIResults?.cache?.clear();
  };

  const loadMoreLineItems = useCallback(() => {
    getLineItems(lineItems);
  }, [lineItems]);

  const loadMoreLineItemsFromResult = useCallback(() => {
    getLineItemsFromResult(lineItemsFromResult);
  }, [lineItemsFromResult]);

  useEffect(() => {
    if (values?.customer && values?.currency) {
      const data = {
        currency: values?.currency?.code,
        target_currency: "SATS",
      };
      callAPIInterface("POST", "/utility/exchange-rate", data).then((res) => {
        const targetAmount = res ? res.target_highest_rate : 0.0;
        setFieldValue("currentExchangeRate", targetAmount);
      });
    }
    setFieldValue("products", []);
    setFieldValue("invoiceTotalAmount", 0);
  }, [values?.customer, values?.currency]);

  useEffect(() => {
    setLineItems([]);
  }, [values?.currency?.code]);

  useEffect(() => {
    if (searchQuery) {
      setLineItemSkeleton(true);
      setSearchNextPage(null);
      setLineItemsFromResult([]);
      setHasMoreLineItemsFromResult(true);
      const apiDelayFunction = setTimeout(() => {
        // this function used to send Search API call after 500ms user typing
        getLineItemsFromResult([]);
      }, 500);
      return () => clearTimeout(apiDelayFunction);
    } else {
      setLineItemSkeleton(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (clearMemoizedCache) clearMemoizedCache();
  }, [liveMode]);

  useEffect(() => {
    if (addNewItem) {
      //to apply initial focus to input element while adding new item click
      const autocompleteInput = getAutocompleteInputElement();
      const normalInputElement = getNormalInputElement();
      autocompleteInput?.focus();
      normalInputElement?.focus();
    }
  }, [addNewItem, getAutocompleteInputElement(), getNormalInputElement()]);

  const renderAddItemElement = () =>
    productLength > 0 &&
    productLength < maxNumOfItems && (
      <Box
        className={classNames(
          "add-item-box",
          disableEditFormElement && "disable-edit-section"
        )}
        onClick={() => setAddNewItem(true)}
      >
        <Text
          className="default-text"
          size={16}
          variant="body1"
          component="span"
        >
          {addItemLabel}
        </Text>
      </Box>
    );

  return (
    <Box
      className={classNames(
        disableEditFormElement && !values.customer && "disable-edit-section",
        "invoice-items margin-top30"
      )}
    >
      {renderItemHeaderSection()}

      {values?.products?.map((product, index) => {
        return (
          <Box key={product.id} className="product-row">
            {editProductIndex !== index
              ? renderItemListingSection({ product, index })
              : renderEditItemDetailSection({ index })}
          </Box>
        );
      })}

      {(addNewItem || !productLength) && ( //onclick additem button or when there is no product added
        <Box className={classNames(values?.products?.length && "product-row")}>
          {values.selectedItem || values.singleProduct
            ? renderAddItemDetailSection()
            : renderAutoCompleteElement()}
        </Box>
      )}
      {invoiceAmountExceeded ? (
        <AlertMessage
          className="margin-top14"
          severity="error"
          message={invoiceAmountExceededMsg}
        />
      ) : (
        renderAddItemElement()
      )}
    </Box>
  );
}

export default InvoiceItems;
