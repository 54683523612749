import { Grid } from "@mui/material";
import {
  dummyLightningAddress,
  dummyLnurl,
  dummyOnchainAddress,
  staticPaymentCheckoutData,
  withdrawalLinkTab,
} from "@speed/common/src/components/constants";
import { useSelector } from "react-redux";
import {
  lightningLabel,
  lnUrlPay,
} from "@speed/common/src/components/messages";
import RenderLinkWebPreview from "../../Common/RenderLinkWebPreview";
import RenderLinkMobilePreview from "../../Common/RenderLinkMobilePreview";

const CheckoutPaymentLink = ({ linkType, previewURL }) => {
  const { brandingSelectedView } = useSelector((state) => state.common);

  const commonInBothViewProps = {
    visibleInPaymentPage: false,
    headerSectionCustomClass: "branding-header-section",
    confirmPageProps: null,
  };
  const propsBasedOnLinkType =
    linkType === withdrawalLinkTab
      ? {
          onChainAvailable: false,
          lightningLabel: (
            <>
              {lightningLabel} {lnUrlPay}
            </>
          ),
          dummyLightningAddress: dummyLnurl,
        }
      : {
          TIDVisible: true,
          onChainAvailable: true,
          lightningLabel: lightningLabel,
          dummyLightningAddress,
          dummyOnchainAddress,
        };

  const webViewProps = {
    boxSx: {
      padding: "0 30px",
      alignItems: "unset",
      borderBottomLeftRadius: "10px",
    },
    rightBoxWidth: "0 20px",
    commonWidth: "unset",
    qrActionBtnWidth: "41.5% !important",
    ...commonInBothViewProps,
    ...propsBasedOnLinkType,
  };

  const mobileViewProps = {
    ...commonInBothViewProps,
    ...propsBasedOnLinkType,
  };

  const renderProps = {
    dummyDomain: previewURL,
    paymentInfo: staticPaymentCheckoutData,
    showPageLoader: true,
    marginTop: 0,
    marginBottom: "80px",
  };

  return (
    <>
      <Grid item lg={8} className="payment-checkout">
        {brandingSelectedView === "laptop" ? (
          <RenderLinkWebPreview
            respectedLinksProps={webViewProps}
            {...renderProps}
          />
        ) : (
          <RenderLinkMobilePreview
            {...renderProps}
            respectedLinksProps={mobileViewProps}
          />
        )}
      </Grid>
    </>
  );
};

export default CheckoutPaymentLink;
