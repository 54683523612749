import React from "react";
import GetGuides from "./GetGuides";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Box } from "@mui/material";

const CashbackGuide = () => {
  const { obCashbackguideFe } = useFlags();

  return obCashbackguideFe ? (
    <GetGuides
      moduleName="cashbacks"
      redirectUrl="/cashbacks"
      guideApiPath="cashbacks-guide"
      getStartedApiPath="Cashback"
      needHelpGuidePath="docs/send-payments/cashback"
    />
  ) : (
    <Box height="70vh" display="flex" alignItems="center">
      <AccessDenied />
    </Box>
  );
};

export default CashbackGuide;
