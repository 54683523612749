import { AppBar, Box, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Text from "@speed/common/src/components/Text/Text";
import React, { forwardRef, useState, useEffect } from "react";
import { Input } from "@speed/common/src/components/Input/Input";
import Button from "@speed/common/src/components/Button/Button";
import {
  enterValidAddress,
  lightning,
  pay,
  send,
  sendAddressInputText,
  getCurrencyName,
  sats,
  lnurl,
  onchain,
  paymentFailedHeading,
  paymentProcessingHeading,
  invalidAddressInvoice,
  invalidAddressMsg,
  invalidQrCode,
  ethereum,
  usdt,
  redemptionFailed,
  btc,
  eth,
} from "../../messages";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  callAPIInterface,
  commonCustomDivider,
  verifyAddress,
  handleSplitWithdrawRequest,
  displaySelectedCurrencyAmount,
  getCurrencyAmount,
  BTCToFiatAmount,
  getExchangeRate,
} from "../../constants";
import SendPayment from "./SendPayment";
import {
  setPaymentProcessingRecallApis,
  setWalletSendModalClose,
} from "../../../redux/common/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  btcToSatsAmount,
  fetchPrecisedInteger,
  validAddress,
} from "@speed/common/src/components/constants";
import SendUploadImage from "./SendUploadImage";
import PaymentModeModal from "./PaymentModeModal";
import RedemptionFailed from "./RedemptionFailed";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import InvaliAddressOrQrcode from "./InvaliAddressOrQrcode";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SendModal = ({
  openSendModal,
  setOpenSendModal,
  selectedCurrencyType,
}) => {
  const dispatch = useDispatch();
  const walletDefaultCurrency = JSON.parse(
    localStorage.getItem("wallet_default_currency")
  );
  const liveMode = useSelector((state) => state.auth.liveMode);
  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);

  const [isPayClicked, setIsPayClicked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [decodeResponse, setDecodeResponse] = useState(null);
  const [amount, setAmount] = useState(null);
  const [fiatAmount, setFiatAmount] = useState(null);
  const [exchangeRateLoading, setExchangeRateLoading] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [isOpenPaymentModeModal, setIsOpenPaymentModeModal] = useState(false);
  const [selectedMode, setSelectedMode] = useState("");
  const [secondBitcoinAddress, setSecondBitcoinAddress] = useState("");
  const [secondLightningAddress, setSecondLightningAddress] = useState("");
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [isPaymentFailed, setIsPaymentFailed] = useState(false);
  const [isErrorDecode, setIsErrorDecode] = useState(false);
  const [receiveWithdrawError, setReceiveWithdrawError] = useState("");
  const [imageError, setImageError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorSubMessage, setErrorSubMessage] = useState("");
  const [noteFromURL, setNoteFromURL] = useState(null);
  const [decodedCurrency, setDecodedCurrency] = useState(null);

  useEffect(() => {
    if (selectedMode !== "") {
      const selectedAddress =
        selectedMode === "bitcoin"
          ? secondBitcoinAddress
          : secondLightningAddress;

      handleDecode(selectedAddress, amount);
    }
  }, [selectedMode]);

  const handleModalClose = () => {
    dispatch(setWalletSendModalClose(true));
    setIsPayClicked(false);
    setIsSubmitting(false);
    setOpenSendModal(false);
    setDecodeResponse(null);
    setExchangeRateLoading(false);
    setAmount(null);
    setFiatAmount(null);
    resetForm();
    setExchangeRate(1);
    setIsPaymentFailed(false);
    setIsErrorDecode(false);
    setIsPaymentProcessing(false);
    setReceiveWithdrawError("");
    setErrorMsg("");
    setErrorSubMessage("");
    setSelectedMode("");
    setImageError("");
    setNoteFromURL(null);
    dispatch(setPaymentProcessingRecallApis(true));
  };

  const validationSchema = yup.object({
    withdraw_request: yup.string().required(enterValidAddress),
  });

  const formik = useFormik({
    initialValues: {
      withdraw_request: "",
    },
    validationSchema: validationSchema,
  });

  const sendPaymentProps = {
    decodeResponse,
    amount,
    fiatAmount,
    exchangeRateLoading,
    selectedCurrencyType,
    setAmount,
    setFiatAmount,
    handleModalClose,
    exchangeRate,
    isPaymentFailed,
    isPaymentProcessing,
    setIsPaymentFailed,
    setIsPaymentProcessing,
    setReceiveWithdrawError,
    setErrorMsg,
    errorMsg,
    noteFromURL,
    decodedCurrency,
  };

  const {
    values,
    isValid,
    dirty,
    setFieldValue,
    resetForm,
    errors,
    touched,
    setTouched,
    setErrors,
  } = formik;

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const handleAddressVerification = (res) => {
    let isAllowed = true;
    const invalidAddress = !values.withdraw_request
      ? invalidQrCode
      : invalidAddressInvoice;
    let message = invalidAddress,
      subMessage = "";

    // Helper function to check address expiration and env checks with network and selected mode i.e [test/live]
    const {
      isAllowed: isValid,
      message: mainMsg,
      subMsg,
    } = verifyAddress(res, liveMode, "send_modal", invalidAddress);
    if (!isValid && (mainMsg || subMsg)) {
      isAllowed = isValid;
      message = mainMsg;
      subMessage = subMsg;
    }
    return { isAllowed, message, subMessage };
  };

  const getAmount = ({ method, decodeAmt, amountFromUrl, decodeCurrency }) => {
    switch (method) {
      case onchain: {
        if (decodeCurrency === sats) {
          return showBtcAmount
            ? amountFromUrl
            : btcToSatsAmount(amountFromUrl) || decodeAmt;
        } else if (decodeCurrency === usdt) {
          return getCurrencyAmount(decodeAmt, showBtcAmount, decodeCurrency);
        }
        break;
      }
      case ethereum.toLowerCase():
        return Number(amountFromUrl);
      case lightning:
        return displaySelectedCurrencyAmount(decodeAmt, showBtcAmount);
      default:
        return decodeAmt || amountFromUrl;
    }
  };

  const getLnUrlAmount = (tag, res) => {
    return displaySelectedCurrencyAmount(
      tag === "withdrawRequest" ? res?.min_withdrawable : res?.min_sendable,
      showBtcAmount
    );
  };

  const handleDecode = (address, amountFromUrl) => {
    setIsOpenPaymentModeModal(false);
    const isValid = validAddress.test(address);
    if (isValid) {
      const data = { withdraw_request: address };

      callAPIInterface("POST", "/withdraws/decode", JSON.stringify(data))
        .then(async (res) => {
          const { isAllowed, message, subMessage } =
            handleAddressVerification(res);
          if (isAllowed) {
            const {
              withdraw_method: method,
              amount: decodeAmt,
              tag,
              currency: decodedCurrency,
            } = res;
            const lightningMethodCurrency =
              selectedCurrencyType?.uuid === sats ? sats : usdt;
            const defaultMethodsCurrency =
              decodedCurrency === eth ? usdt : decodedCurrency;
            const decodeCurrency =
              method === lightning
                ? lightningMethodCurrency
                : defaultMethodsCurrency;
            setDecodedCurrency(decodeCurrency);
            const amt =
              method === lnurl
                ? getLnUrlAmount(tag, res)
                : getAmount({
                    method,
                    decodeAmt,
                    amountFromUrl,
                    decodeCurrency,
                  });

            const usdtLightningCondtion =
              method === lightning && lightningMethodCurrency === usdt;

            let usdtConvertedAmount = 0;

            setExchangeRateLoading(true);
            // exchange rate api call
            if (usdtLightningCondtion) {
              await getExchangeRate(sats, decodeCurrency)
                .then((res) => {
                  usdtConvertedAmount = amt * res?.target_lowest_rate;
                  setExchangeRateLoading(false);
                })
                .catch((_e) => setExchangeRateLoading(false));
            }

            await getExchangeRate(
              walletDefaultCurrency?.code || "USD",
              decodeCurrency
            )
              .then((res) => {
                setExchangeRate(res?.target_lowest_rate);
                const fiatAmt =
                  (usdtLightningCondtion ? usdtConvertedAmount : amt) /
                  res?.target_lowest_rate;
                let formatedFiatAmount = fetchPrecisedInteger(fiatAmt, 2);
                if (formatedFiatAmount === "0") {
                  formatedFiatAmount = fiatAmt.toFixed(8);
                }
                const btcToFiatAmt = BTCToFiatAmount(
                  usdtLightningCondtion ? usdtConvertedAmount : amt,
                  res?.target_lowest_rate
                );
                setFiatAmount(
                  showBtcAmount ? btcToFiatAmt : formatedFiatAmount
                );
                setExchangeRateLoading(false);
              })
              .catch((_e) => setExchangeRateLoading(false));
            setAmount(
              usdtLightningCondtion ? usdtConvertedAmount.toFixed(2) : amt
            );
            setDecodeResponse(res);
            setIsSubmitting(false);
            setIsPayClicked(true);
          } else {
            setIsSubmitting(false);
            setIsErrorDecode(true);
            setErrorMsg(message);
            setErrorSubMessage(subMessage);
          }
        })
        .catch((_err) => {
          setErrors({ withdraw_request: enterValidAddress });
          setIsSubmitting(false);
          setIsErrorDecode(true);
          setErrorMsg(
            !values.withdraw_request ? invalidQrCode : invalidAddressInvoice
          );
          setErrorSubMessage(invalidAddressMsg);
        });
    } else {
      setIsErrorDecode(true);
      setErrorMsg(
        !values.withdraw_request ? invalidQrCode : invalidAddressInvoice
      );
      setErrorSubMessage(invalidAddressMsg);
      setIsSubmitting(false);
    }
  };

  const handleSubmit = (invoiceUrl) => {
    setIsSubmitting(true);
    const { amount, addresses, note } = handleSplitWithdrawRequest(invoiceUrl);

    setNoteFromURL(note);
    setSecondLightningAddress(addresses?.lightning || addresses?.default);
    setSecondBitcoinAddress(addresses?.bitcoin || addresses?.default);
    if (Object.keys(addresses).length > 1) {
      setIsOpenPaymentModeModal(true);
    } else {
      const extractedAddress = Object.values(addresses)[0];
      handleDecode(extractedAddress, amount);
    }
  };

  const getHeaderTitle = (uuid) => {
    return `${send} ${uuid === sats ? btc : usdt}`;
  };

  const modalHeaderText = () => {
    if (isErrorDecode) {
      return "";
    } else if (isPaymentFailed) {
      return paymentFailedHeading;
    } else if (isPaymentProcessing) {
      return paymentProcessingHeading;
    } else if (receiveWithdrawError) {
      return redemptionFailed;
    } else return getHeaderTitle(decodedCurrency || selectedCurrencyType?.uuid);
  };

  const sendPaymentRender = () => {
    return isPayClicked ? (
      <SendPayment {...sendPaymentProps} />
    ) : (
      <>
        <img
          src={selectedCurrencyType?.icon}
          alt="currency-btc-icon"
          style={{
            width: "54px",
            height: "54px",
          }}
        />
        <Text
          size={20}
          font="semibold"
          className="default-text"
          variant="h6"
          sx={{ pt: "14px", mb: "6px" }}
        >
          {getCurrencyName(selectedCurrencyType?.name)}
        </Text>
        <Input
          fullWidth
          placeholder={sendAddressInputText}
          onChange={(e) => setFieldValue("withdraw_request", e.target.value)}
          value={values.withdraw_request}
          type="text"
          className="send-input-address"
          label={false}
          onBlur={() => setTouched({ ...touched, withdraw_request: true })}
          error={touched.withdraw_request && Boolean(errors.withdraw_request)}
        />
        <InputErrorMessage
          {...defaultErrorMessageProps}
          inputName="withdraw_request"
          sx={{ width: "520px" }}
        />
        <Text
          size={16}
          font="regular"
          className="grey-text"
          variant="h6"
          sx={{ pt: "24px", pb: "30px" }}
        >
          or
        </Text>
        <SendUploadImage
          handleSubmit={handleSubmit}
          setImageError={setImageError}
          imageError={imageError}
        />
        {commonCustomDivider("40px")}
        <Button
          label={pay}
          className="send-amt-btn"
          onClick={() => {
            handleSubmit(values.withdraw_request);
          }}
          type="submit"
          loading={isSubmitting}
          disabled={!(isValid && dirty) || isSubmitting}
        />
      </>
    );
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={openSendModal}
      TransitionComponent={Transition}
      className="fullscreen-modal send-modal-dialog send-modal-dialog-section"
      onClose={handleModalClose}
      sx={{ overflow: "unset" }}
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {modalHeaderText()}
          </Text>
        </Toolbar>
      </AppBar>
      <Box className="send-modal-container">
        <Box className="send-modal-box">
          {receiveWithdrawError ? (
            <RedemptionFailed
              receiveWithdrawError={receiveWithdrawError}
              handleModalClose={handleModalClose}
            />
          ) : (
            <Box
              className="send-modal-icon-text"
              pt={isPayClicked ? "" : "50px"}
            >
              {isErrorDecode ? (
                <InvaliAddressOrQrcode
                  handleModalClose={handleModalClose}
                  errorMsg={errorMsg}
                  errorSubMessage={errorSubMessage}
                />
              ) : (
                sendPaymentRender()
              )}
            </Box>
          )}
        </Box>
      </Box>
      {isOpenPaymentModeModal && (
        <PaymentModeModal
          handleModalClose={() => setIsOpenPaymentModeModal(false)}
          openModal={isOpenPaymentModeModal}
          setSelectedMode={setSelectedMode}
        />
      )}
    </Dialog>
  );
};

export default SendModal;
