import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "@speed/common/src/components/Modal/Modal";
import {
  setOpenSendViaEmailModal,
  setSendViaEmailModalAPIDetails,
} from "../../redux/common/actions";
import {
  checkStatusLabel,
  okay,
  sendViaEmailDescriptionMsg,
  sendViaEmailTitle,
} from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import Text from "@speed/common/src/components/Text/Text";

const SendViaEmailModal = () => {
  const { history, SendViaEmailModalAPIDetails } = useSelector(
    (state) => state.common
  );
  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch(setOpenSendViaEmailModal(false));
    dispatch(setSendViaEmailModalAPIDetails(null));
  };

  const renderModalContent = () => (
    <Text variant="body1" size={18} font="regular" className="default-text">
      {sendViaEmailDescriptionMsg}
    </Text>
  );

  const renderModalFooter = () => (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        variant="outlined"
        type="submit"
        label={okay}
        onClick={handleModalClose}
        disabled={false}
      />
      <Button
        sx={{ marginLeft: "15px" }}
        type="submit"
        label={checkStatusLabel}
        onClick={() => {
          history.push(
            `/withdrawal-links/import/${SendViaEmailModalAPIDetails?.id}`
          );
          handleModalClose();
        }}
      />
    </Box>
  );

  return (
    <Modal
      maxWidth="sm"
      open
      body={renderModalContent()}
      footer={renderModalFooter()}
      handleClose={handleModalClose}
      title={sendViaEmailTitle}
    />
  );
};
export default SendViaEmailModal;
