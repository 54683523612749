import React, { useEffect } from "react";
import { Box } from "@mui/system";
import "../../../assets/styles/manage-apps.scss";
import { shopifyGoToNotification } from "../../messages";
import SubscribeWebhook from "./SubscribeWebhook";
import VideoSection from "./VideoSection";

const ShopifyInstallationStep2 = ({
  handleSubscribeWebhook,
  webhookDetails,
}) => {
  useEffect(() => {
    handleSubscribeWebhook();
  }, []);
  return (
    <Box className="fullscreen-modal-box">
      <Box className="modal-container-grid grid-box" sx={{ display: "flex" }}>
        <VideoSection headerText={shopifyGoToNotification} />
        <Box className="inner-grid" sx={{ maxWidth: "50%", overflow: "auto" }}>
          <SubscribeWebhook webhookDetails={webhookDetails} />
        </Box>
      </Box>
    </Box>
  );
};

export default ShopifyInstallationStep2;
