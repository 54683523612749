import React from "react";
import { Box } from "@mui/system";
import "../../../assets/styles/manage-apps.scss";
import StoreAuthenticate from "./StoreAuthenticate";
import { shopifyAdminToSettingDirection } from "../../messages";
import VideoSection from "./VideoSection";

const ShopifyInstallationStep1 = (props) => {
  const {
    values,
    setFieldValue,
    touched,
    errors,
    setTouched,
    defaultErrorMessageProps,
  } = props;

  return (
    <Box className="fullscreen-modal-box">
      <Box className="modal-container-grid grid-box" sx={{ display: "flex" }}>
        <VideoSection headerText={shopifyAdminToSettingDirection} />
        <Box className="inner-grid" sx={{ maxWidth: "50%", overflow: "auto" }}>
          <StoreAuthenticate
            values={values}
            setFieldValue={setFieldValue}
            defaultErrorMessageProps={defaultErrorMessageProps}
            errors={errors}
            touched={touched}
            setTouched={setTouched}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ShopifyInstallationStep1;
