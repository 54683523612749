import {
  TableCell,
  TableRow,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import { generateBreadcrumbs } from "@speed/common/src/components/constants";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  noOfRecords,
  RouteBreadcrumbs,
} from "../constants";
import Text from "@speed/common/src/components/Text/Text";
import CustomTable from "@speed/common/src/components/Table/Table";
import { emptySubText } from "@speed/common/src/components/messages";
import { loaderSpinner } from "@speed/common/src/components/images";
import {
  importedFiles,
  resendAllLabel,
  resendAllWLSuccessMsg,
} from "../messages";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import { store } from "../../redux/store";
import { showToast } from "../../redux/common/actions";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { emptyCheckoutLink } from "../images";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";

const columns = [
  { title: "Uploaded On", width: "250px", paddingLeft: "38px !important" },
  {
    title: "File Name",
    width: "300px",
  },
  { title: "Uploaded By", width: "200px" },
  { title: "Total Links", width: "125px" },
  { title: "Processing", width: "125px" },
  { title: "Sent", width: "125px" },
  { title: "Failed", width: "125px" },
  { title: "Action", width: "100px" },
];

const WLAllFilesImportList = (props) => {
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const baseUrl = "/withdrawal-links/import";

  const [currentRow, setCurrentRow] = useState(null);
  const [isResendingEmail, setIsResendingEmail] = useState(false);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopper = Boolean(anchorEl);

  const history = useSelector((state) => state.common.history);
  const liveMode = useSelector((state) => state.auth.liveMode);

  const { wlImportedlinksFe } = useFlags();

  const getWLImportList = (type = "first-load") => {
    let endingBeforeParam = "";
    setTableRowSkeleton(true);
    if (type === "first-load") {
      setRows([]);
    } else {
      endingBeforeParam = "&ending_before=" + rows[rows.length - 1].id;
    }
    callAPIInterface(
      "GET",
      `/withdrawal-links/files?limit=${noOfRecords}${endingBeforeParam}`,
      {}
    )
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          }
          setRows((pre) => pre.concat(res.data));
        }
      })
      .catch(() => {
        setTableRowSkeleton(false);
      });
  };

  const handleClick = (event, rowData) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setCurrentRow(rowData);
  };

  useEffect(() => {
    if (wlImportedlinksFe) {
      setHasMore(true);
      getWLImportList();
    }
  }, [liveMode, wlImportedlinksFe]);

  const loadMore = useCallback(() => {
    getWLImportList("load-more");
  }, [rows]);

  const columnsData = columns?.map((column) => (
    <TableCell
      key={column.title}
      sx={{ paddingLeft: column.paddingLeft, minWidth: column.width }}
    >
      {column.title}
    </TableCell>
  ));

  const rowsData = rows?.map((rowItem) => (
    <TableRow
      key={rowItem.id}
      className="clickable"
      onClick={() => {
        history.push(`${baseUrl}/${rowItem.id}`);
      }}
    >
      <TableCell sx={{ paddingLeft: "38px !important" }}>
        {dateTimeFormatInApp(rowItem.created)}
      </TableCell>
      <TableCell>
        <TruncatedTextTooltip textValue={rowItem.filename} cellWidth="250px" />
      </TableCell>
      <TableCell>
        <TruncatedTextTooltip
          textValue={rowItem.uploaded_by}
          cellWidth="250px"
        />
      </TableCell>
      <TableCell>{rowItem.total_links}</TableCell>
      <TableCell>{rowItem.processing_links}</TableCell>
      <TableCell>{rowItem.sent_links}</TableCell>
      <TableCell>{rowItem.failed_links}</TableCell>
      <TableCell>
        {rowItem.sent_links > 0 && (
          <MoreHorizIcon
            className="horizontal-dots-icon"
            onClick={(event) => {
              event.stopPropagation();
              handleClick(event, rowItem);
            }}
          />
        )}
      </TableCell>
    </TableRow>
  ));

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton,
    hasMore,
    loadMore,
    className: "link-table",
    textOnNoData: emptySubText(liveMode, importedFiles),
    noDataImage: emptyCheckoutLink,
  };

  const resendAllWL = () => {
    setIsResendingEmail(true);
    if (currentRow) {
      callAPIInterface(
        "POST",
        `/withdrawal-links/file-links/${currentRow.id}/resend-email`,
        {}
      )
        .then(() => {
          store.dispatch(
            showToast({
              isToastOpen: true,
              toastMessage: resendAllWLSuccessMsg,
              toastVariant: "success",
            })
          );
          setIsResendingEmail(false);
          setAnchorEl(null);
        })
        .catch(() => {
          setIsResendingEmail(false);
          setAnchorEl(null);
        });
    }
  };

  return (
    <>
      <Box
        className="payment-link-detail-wrapper"
        paddingBottom="22px !important"
      >
        <Box className="section-wrapper" sx={{ position: "relative" }}>
          <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
          <Box className="header-content">
            <Text className="default-text" size={24}>
              {importedFiles}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box className="payment-links-wrapper">
        <Box className="section-wrapper">
          <Box className="payment-wrapper">
            <Box className="main-content">
              {wlImportedlinksFe ? (
                <CustomTable {...tableProps} />
              ) : (
                <AccessDenied />
              )}
            </Box>
          </Box>
        </Box>
        <CustomPopper
          disablePortal={true}
          open={openPopper}
          anchorEl={anchorEl}
          position="bottom"
          handleClose={() => setAnchorEl(null)}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ gap: 1 }}
                disabled={isResendingEmail}
                onClick={resendAllWL}
              >
                <ListItemText primary={resendAllLabel} />
                {isResendingEmail && (
                  <img
                    src={loaderSpinner}
                    alt="Loader"
                    width={15}
                    height={15}
                  />
                )}
              </ListItemButton>
            </ListItem>
          </List>
        </CustomPopper>
      </Box>
    </>
  );
};

export default WLAllFilesImportList;
