import React from "react";
import GetGuides from "./GetGuides";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Box } from "@mui/material";

const OneQRGuide = () => {
  const { obOneqrguideFe } = useFlags();

  return obOneqrguideFe ? (
    <GetGuides
      moduleName="one_qr"
      redirectUrl="/one-qr"
      guideApiPath="one-qr-guide"
      getStartedApiPath="One"
      needHelpGuidePath="docs/receive-payments/one-qrs"
    />
  ) : (
    <Box height="70vh" display="flex" alignItems="center">
      <AccessDenied />
    </Box>
  );
};

export default OneQRGuide;
