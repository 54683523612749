import React, { useEffect, useState } from "react";
import { Box, MenuItem } from "@mui/material";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import TransactionTable from "../Common/TransactionTable";
import { useSelector } from "react-redux";
import CustomSelect from "@speed/common/src/components/Select/Select";
import { callAPIInterface, currencySupportedByTransaction } from "../constants";
import CommonHeader from "../Common/CommonHeader";
import { useFlags } from "launchdarkly-react-client-sdk";

const Transactions = () => {
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [currency, setCurrency] = useState("BTC");
  const liveMode = useSelector((state) => state.auth.liveMode);
  const { txnUsdctransfilterFe } = useFlags();

  const handleChangeCurrency = (e) => {
    setCurrency(
      currencySupportedByTransaction?.find(
        (currency) => currency === e.target.value
      )
    );
  };

  const getUSDCTransaction = () => {
    setShowCurrencyDropdown(false);
    callAPIInterface(
      "GET",
      "/balance-transactions?limit=1&target_currency=USDC"
    )
      .then((res) => {
        if (res) {
          setShowCurrencyDropdown(res?.data?.length > 0);
        }
      })
      .catch((_err) => {});
  };
  useEffect(() => {
    setCurrency("BTC");
    txnUsdctransfilterFe && getUSDCTransaction();
  }, [liveMode, txnUsdctransfilterFe]);

  return (
    <Box className="section-wrapper">
      <CommonHeader
        childrenWithHeader={
          txnUsdctransfilterFe &&
          showCurrencyDropdown && (
            <CustomSelect
              showLabel={false}
              customClass="transaction-custom-currency-dropdown"
              value={currency}
              MenuProps={{
                id: "payout-wallet-address",
                disableScrollLock: true,
              }}
              onChange={handleChangeCurrency}
              renderValue={() => currency}
            >
              {currencySupportedByTransaction?.map(
                (dropDownValue, dropDownIndex) => (
                  <MenuItem value={dropDownValue} key={dropDownIndex}>
                    <Box>{dropDownValue}</Box>
                  </MenuItem>
                )
              )}
            </CustomSelect>
          )
        }
      />
      <CustomDivider />
      <TransactionTable currency={currency} />
    </Box>
  );
};

export default Transactions;
