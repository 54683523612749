import React from "react";
import ConnectedExtensionTable from "./ConnectedExtensionTable";
import { useFlags } from "launchdarkly-react-client-sdk";
import AccessDenied from "@speed/common/src/components/AccessDenied";

const ConnectedExtensions = () => {
  const { caConnectedacclistFe } = useFlags();

  return caConnectedacclistFe ? <ConnectedExtensionTable /> : <AccessDenied />;
};

export default ConnectedExtensions;
