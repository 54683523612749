import React, { useState } from "react";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import { addNewWallet } from "../../messages";
import { useFormik } from "formik";
import {
  addWalletButton,
  addWalletSubmitFunc,
  payoutWalletCurrencies,
  payoutWalletValidationSchema,
} from "../../constants";

import PayoutWalletFormCommon from "./PayoutWalletFormCommon";

const PayoutWalletFormModal = (props) => {
  const { fetchWallets, closeModal, isModalOpen } = props;
  const [disableAddWallet, setDisableAddWallet] = useState(false);

  const formik = useFormik({
    initialValues: {
      wallet_name: "",
      currency: payoutWalletCurrencies[0],
      wallet_address: "",
    },
    validationSchema: payoutWalletValidationSchema,
    onSubmit: (values) =>
      addWalletSubmitFunc({
        values,
        closeModal,
        fetchWallets,
        setDisableAddWallet,
      }),
  });

  const { isValid, touched, handleSubmit } = formik;

  const disableCheck =
    !isValid ||
    (Object.keys(touched).length === 0 && touched.constructor === Object) ||
    disableAddWallet;

  return (
    <Modal
      maxWidth="xs"
      body={
        <PayoutWalletFormCommon
          formik={formik}
          currencies={payoutWalletCurrencies}
        />
      }
      footer={addWalletButton(handleSubmit, disableCheck)}
      handleClose={() => {
        closeModal();
      }}
      open={isModalOpen}
      title={addNewWallet}
    />
  );
};

export default PayoutWalletFormModal;
