import React, { useEffect, useState } from "react";
import { Box, Skeleton, createFilterOptions } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import {
  addDescription,
  balanceAvailableForModule,
  description,
  destinationAccount,
  enterAmountPlaceholder,
  fromAccount,
  minimumAmountLabel,
  selectAccount,
  transferAmount,
  transferDetailsText,
} from "../messages";
import Label from "@speed/common/src/components/Label/Label";
import InputMask from "@speed/common/src/components/MaskInput";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import { Input } from "@speed/common/src/components/Input/Input";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import { fetchPrecisedInteger } from "@speed/common/src/components/constants";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import { useSelector } from "react-redux";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import { getAccNameFromId } from "../constants";

const TransferRequestDetails = (props) => {
  const {
    touched,
    setTouched,
    amountRef,
    values,
    setFieldValue,
    errors,
    defaultErrorMessageProps,
    balanceLoading,
    currentAccountId,
    minimumAmount,
  } = props;

  const [noAccountFound, setNoAccountFound] = useState(false);
  const { user, currentAccount } = useSelector((state) => state.auth);

  const destinationAccountList = user?.accounts_details?.filter(
    (accountData) => accountData.account?.id !== currentAccount?.account?.id
  );

  const autoCompleteStyle = {
    dropDownSx: {
      "&.auto-complete .MuiAutocomplete-root .MuiFormControl-root": {
        height: "42px",

        ".MuiAutocomplete-input": {
          paddingLeft: "0px",
        },
      },
    },
    optionMainBoxSx: {
      margin: "0px !important",
      padding: "10px !important",
      backgroundColor: "#FFF !important",
      maxHeight: "42px !important",
      display: "flex",
      alignItems: "center !important",
      justifyContent: "space-between !important",
      ":hover": {
        bgcolor: "#f1f4f6 !important",
      },
    },
    accountNameTextSx: {
      fontFamily: "Inter-SemiBold",
      fontSize: "14px !important",
    },
  };

  const findExistedAssociatedAccount = (destinationAccount) =>
    destinationAccountList?.filter(
      (accounts) =>
        accounts.account?.id === destinationAccount ||
        accounts.account?.name === destinationAccount
    )?.[0];

  const setDestinationFormikValue = (
    destinationAccount, // can be accountName ex: "Bussiness Name" or accountNumber/id ex: "acct_l5rsts3bnwIWO08M"
    fromInputChange = false
  ) => {
    const isExistAssociatedAcc =
      findExistedAssociatedAccount(destinationAccount);
    if (isExistAssociatedAcc) {
      setNoAccountFound(false);
      (!fromInputChange ||
        isExistAssociatedAcc?.account?.id !== destinationAccount) &&
        setFieldValue("destination_account", isExistAssociatedAcc?.account?.id);
    } else {
      setFieldValue("destination_account", destinationAccount);
    }
  };

  useEffect(() => {
    //To hide the no option dropdown When there is no account found from search result
    const noDestinationAccFound = document.querySelector(
      "#create-transfer-destination-account .MuiAutocomplete-paper"
    );
    noDestinationAccFound &&
      setNoAccountFound(!noDestinationAccFound.childNodes?.length);
  }, [values]);

  useEffect(() => {
    !destinationAccountList?.length && setNoAccountFound(true);
  }, []);

  return (
    <Box className="margin-top30" display="flex" justifyContent="center">
      <Box>
        <Text className="default-text" size={20} variant="h2">
          {transferDetailsText}
        </Text>
        <Box width={600} className="margin-top30">
          <Input
            type="text"
            disabled
            value={currentAccountId}
            customClass="margin-top30"
            showLabel
            label={fromAccount}
            fullWidth
          />
        </Box>
        <Box width={600} className="margin-top30">
          <CustomAutoComplete
            showLabel
            freeSolo
            disableClearable
            name="destination_account"
            label={destinationAccount}
            placeholder={selectAccount}
            sx={autoCompleteStyle.dropDownSx}
            value={values?.destination_account}
            options={destinationAccountList?.map(
              (option) => option?.account?.id
            )}
            filterOptions={createFilterOptions({
              stringify: (id) =>
                `${getAccNameFromId(id, true, destinationAccountList)} ${id}`, //searchable for both acc name and id
            })}
            disabled={values?.balance ? values.balance < minimumAmount : true}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => {
              return (
                getAccNameFromId(
                  option || values?.destination_account,
                  true,
                  destinationAccountList
                ) || ""
              );
            }}
            componentsProps={{
              popper: {
                id: "create-transfer-destination-account",
                sx: noAccountFound && {
                  display: "none",
                },
              },
            }}
            onInputChange={(_e, value) => {
              setDestinationFormikValue(value, true);
            }}
            onBlur={(event) => {
              setTouched({ ...touched, destination_account: true });
              setDestinationFormikValue(event.target.value);
            }}
            onChange={(_e, value) => {
              setTouched({ ...touched, destination_account: false });
              setFieldValue("destination_account", value);
            }}
            renderOption={(optionProps, option) => {
              return (
                <Box
                  {...optionProps}
                  key={option}
                  sx={autoCompleteStyle.optionMainBoxSx}
                >
                  <TruncatedTextTooltip
                    textValue={`${getAccNameFromId(
                      option,
                      true,
                      destinationAccountList
                    )}`}
                    cellWidth="350px"
                    textProps={{
                      sx: autoCompleteStyle.accountNameTextSx,
                    }}
                  />
                  <Box>
                    <Text className="grey-text" size={14} variant="body1">
                      {option}
                    </Text>
                  </Box>
                </Box>
              );
            }}
          />
          <InputErrorMessage
            {...defaultErrorMessageProps}
            inputName="destination_account"
          />
        </Box>
        <Box width={600} className="margin-top30">
          <TextAreaComponent
            showLabel
            label={description}
            maxLength={255}
            minRows={3}
            maxRows={3}
            name="description"
            placeholder={addDescription}
            fullWidth
            value={values.description}
            onChange={(e) => {
              setTouched({ ...touched, description: false });
              setFieldValue("description", e.target.value);
            }}
            onBlur={() => setTouched({ ...touched, description: true })}
          />
        </Box>
        <Box width={600} className="margin-top30">
          <Box display="flex" justifyContent="space-between">
            <Label>{transferAmount}</Label>
            <Label>
              {minimumAmountLabel(
                "transfer",
                minimumAmount,
                values?.currency?.code
              )}
            </Label>
          </Box>
          <InputMask
            label={""}
            onChange={(e) => {
              setTouched({ ...touched, amount: false });
              amountRef.current.amount = e.target.value;
              setFieldValue("amount", e.target.value);
            }}
            ref={amountRef}
            value={values.amount}
            error={errors.amount && Boolean(errors.amount)}
            onBlur={() => setTouched({ ...touched, amount: true })}
            placeholder={enterAmountPlaceholder}
            disabled={values?.balance ? values.balance < minimumAmount : true}
          />
          <InputErrorMessage {...defaultErrorMessageProps} inputName="amount" />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          width={600}
        >
          <Text
            sx={{
              mt: "15px",
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              width: "75%",
            }}
            className="grey-text"
            size={14}
            variant="body1"
          >
            {balanceAvailableForModule("transfer")}
            <Text className="default-text" size={14} variant="caption">
              {balanceLoading ? (
                <Skeleton width={100} />
              ) : (
                `${fetchPrecisedInteger(values?.balance, 8)} ${
                  values?.currency?.code
                }`
              )}
            </Text>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default TransferRequestDetails;
