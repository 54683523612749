import React, { useEffect, useState } from "react";
import {
  RouteBreadcrumbs,
  callAPIInterface,
  dateTimeFormatInApp,
} from "../../constants";
import {
  capitalizeFirstChar,
  generateBreadcrumbs,
  redirectWhenEntityNotFound,
  truncateStringWithTooltip,
} from "@speed/common/src/components/constants";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";
import {
  accountID,
  dateCreated,
  entityNotFoundMessage,
  feesReceivedMSg,
  removeAccountLabel,
  status,
  type,
} from "../../messages";
import WarningIcon from "@mui/icons-material/Warning";
import { Link, useParams } from "react-router-dom";
import LinkSkeleton from "@speed/common/src/components/Skeleton/LinkSkeleton";
import Button from "@speed/common/src/components/Button/Button";
import Text from "@speed/common/src/components/Text/Text";
import { currency } from "@speed/common/src/components/currency";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import { email } from "@speed/common/src/components/messages";
import RemoveAccountModal from "../RemoveAccountModal";
import history from "@speed/common/src/components/history";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import { countryLabel } from "@speed/common/src/messages";

const ConnectAccountDetails = (props) => {
  const [isLinkNotFound, setIsLinkNotFound] = useState(false);
  const [connectAccountsData, setConnectAccountsData] = useState(null);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const urlParams = useParams();

  const liveMode = useSelector((state) => state.auth.liveMode);

  const getConnectAccountDetail = () => {
    callAPIInterface("GET", `/connect/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setConnectAccountsData(res);
        }
      })
      .catch((err) => {
        setIsLinkNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const loadDetailPage = () => {
    setConnectAccountsData(null);
    setIsLinkNotFound(false);
    getConnectAccountDetail();
  };

  useEffect(() => loadDetailPage(), [liveMode, urlParams?.id]);

  const headerContent = () => {
    return (
      <Box className="header-content">
        {truncateStringWithTooltip({
          maxLength: 50,
          str: connectAccountsData?.account_name,
          textProps: {
            className: "default-text",
            size: 24,
            variant: "h3",
            font: "medium",
          },
        })}
        {connectAccountsData?.total_fee_collected?.toString() && (
          <>
            <Text
              className="grey-text"
              size={14}
              variant="subtitle1"
              font="regular"
              sx={{ margin: "18px 0" }}
            >
              {feesReceivedMSg}
            </Text>
            <Text
              size={20}
              variant="subtitle1"
              font="medium"
              sx={{ color: "#2A67FF !important" }}
            >
              {currency[1].symbol} {connectAccountsData?.total_fee_collected}
            </Text>
          </>
        )}
      </Box>
    );
  };

  const pushDataToArr = (arr, label, value) => {
    return (
      value &&
      arr.push({
        header: label,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {value}
          </Text>
        ),
      })
    );
  };

  const detailsTableLeftSideData = () => {
    const { country, created, owner_email } = connectAccountsData;
    const resultArr = [];

    pushDataToArr(resultArr, dateCreated, dateTimeFormatInApp(created));
    pushDataToArr(resultArr, countryLabel, country);
    pushDataToArr(resultArr, email, owner_email);
    return resultArr;
  };

  const detailsTableRightSideData = () => {
    const {
      account_id,
      status: connectStatus,
      type: connectType,
    } = connectAccountsData;
    const resultArr = [];

    pushDataToArr(resultArr, accountID, account_id);
    pushDataToArr(
      resultArr,
      status,
      <Tag text={connectStatus} variant="primary" />
    );
    pushDataToArr(resultArr, type, capitalizeFirstChar(connectType));
    return resultArr;
  };

  const renderDetailContent = () => {
    const verticalTableDataObj = {
      rowData: detailsTableLeftSideData(),
      rightSideRowData: detailsTableRightSideData(),
    };
    return (
      <Box className="details-content">
        <VerticalTable label="Details" {...verticalTableDataObj} />
      </Box>
    );
  };

  const renderActionButton = () => {
    return (
      <Box className="action-btn-wrapper">
        <Button
          label={removeAccountLabel}
          variant="outlined"
          color="error"
          onClick={() => {
            setOpenRemoveModal(true);
          }}
        />
      </Box>
    );
  };

  const detailPage = () => {
    return !isLinkNotFound ? (
      <Grid className="connect-detail-wrapper" container spacing={1}>
        <Box className="section-wrapper">
          <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
          {connectAccountsData ? (
            <>
              {renderActionButton()}
              {headerContent()}
              {renderDetailContent()}
            </>
          ) : (
            <LinkSkeleton props={props} from="connect" />
          )}
        </Box>
        <RemoveAccountModal
          apiPath="/connect"
          callBackFn={() => history.push("/connect")}
          handleClose={() => setOpenRemoveModal(false)}
          idToRemove={urlParams?.id}
          open={openRemoveModal}
        />
      </Grid>
    ) : (
      <Box className="not-found-wrapper">
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        <LinkNotFound
          icon={<WarningIcon className="warning-icon" />}
          title={entityNotFoundMessage("Connected account")}
          subTitle={`Connected account ${urlParams.id} is not found.`}
          btnText={`View ${liveMode ? "live" : "test"} mode accounts`}
        />
      </Box>
    );
  };

  return detailPage();
};

export default ConnectAccountDetails;
