import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { Box, Grid, List, ListItem, MenuItem } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import {
  turnPaymentMethod,
  paymentMethod,
  turnOn,
  turnOff,
  yes,
  no,
  tolerence,
  btcToleranceTooltipContent,
} from "../messages";
import InfoIcon from "@mui/icons-material/Info";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import Button from "@speed/common/src/components/Button/Button";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useDispatch, useSelector } from "react-redux";
import {
  btcOnchainTolerenceArray,
  callAPIInterface,
  paymentMethodStatus,
} from "../constants";
import React, { useState, useEffect } from "react";
import { Skeleton } from "@mui/lab";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import {
  getPaymentMethodsList,
  setOnchainTolerance,
} from "../../redux/common/actions";
import { SET_ONCHAIN_PAYMENT_METHOD } from "../../redux/common/types";
import classNames from "classnames";
import CustomSelect from "@speed/common/src/components/Select/Select";
import { useFormik } from "formik";
import _ from "lodash";
import { save } from "@speed/common/src/messages";

const PaymentMethod = () => {
  const { liveMode, user } = useSelector((state) => state.auth);
  const onChainPaidTolerence = useSelector(
    (state) => state.common.onChainPaidTolerence
  );
  const [gridData, setGridData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [rowId, setRowId] = useState(null);
  const { pmListpayment, pmPaymentmode, pmTolerance } = useFlags();
  const [toleranceLoading, setTolerenceLoading] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    resetForm();
    setGridData([]);
    getPaymentMethods();
  }, [liveMode, user]);

  const formik = useFormik({
    initialValues: {
      amount_paid_tolerance: onChainPaidTolerence ? onChainPaidTolerence : "1",
    },
    enableReinitialize: true,
  });

  const { setFieldValue, resetForm, values, isValid, dirty, isSubmitting } =
    formik;

  const handleChange = (status, id, updateTolerence = false) => {
    const submitData = {
      status: status,
    };

    if (updateTolerence) {
      setTolerenceLoading(true);
      submitData.amount_paid_tolerance = values.amount_paid_tolerance;
    } else {
      setRowId(id);
      resetForm();
    }

    callAPIInterface("POST", `/payment-methods/${id}`, submitData)
      .then((response) => {
        if (response) {
          const btcTolerence = response.amount_paid_tolerance?.toString();
          if (
            id === "pm_2" &&
            btcTolerence &&
            response.status === yes.toLowerCase()
          )
            dispatch(setOnchainTolerance(btcTolerence));
          dispatch({
            type: SET_ONCHAIN_PAYMENT_METHOD,
            payload: response.status,
          });
          gridData.filter((grid) => {
            if (grid.id == response.id) grid.status = response.status;
          });
          updateTolerence ? setTolerenceLoading(false) : setRowId(null);
        }
      })
      .catch((_e) => {
        updateTolerence ? setTolerenceLoading(false) : setRowId(null);
        setLoader(false);
      });
  };

  const getPaymentMethods = () => {
    setLoader(true);
    dispatch(getPaymentMethodsList())
      .then((response) => {
        if (response) {
          setLoader(false);
          setGridData(response.data);
        }
      })
      .catch((_e) => {
        setLoader(false);
      });
  };

  const paymentMethodLoader = (showTolerence = false) => {
    return (
      <Box
        className={classNames(
          "custom-domain-wrapper",
          showTolerence && "tolerence-section-wrapper"
        )}
      >
        <Grid display="flex" alignItems="center">
          <Box
            className={classNames(showTolerence && "on-chain-section-img")}
            sx={{ paddingLeft: "20px", top: "5px !important" }}
          >
            <Skeleton
              height="100px"
              width="60px"
              sx={{ borderRadius: "60%" }}
            />
          </Box>
          <Box width="100%">
            <Box
              display="flex"
              justifyContent="space-between"
              paddingRight="20px"
            >
              <List disablePadding>
                <ListItem disablePadding className="loader-wrapper">
                  <Box>
                    <Box display="flex" sx={{ paddingBottom: "16px" }}>
                      <Text
                        className="card-text"
                        size={18}
                        font="bold"
                        align="left"
                        sx={{ paddingRight: "10px" }}
                      >
                        <Skeleton
                          height="21px"
                          width="211.3px"
                          sx={{ backgroundColor: "#c4ccd2 !important" }}
                        />
                      </Text>
                    </Box>
                    <Text
                      className="grey-text"
                      size={16}
                      font="regular"
                      variant="subtitle1"
                    >
                      <Skeleton height="16px" width="317px" />
                    </Text>
                  </Box>
                </ListItem>
              </List>
            </Box>
            {showTolerence && (
              <Box className="tolerence-main-box">
                <Box className="form-section">
                  <Box>
                    <Skeleton
                      height="16px"
                      width="100px"
                      sx={{ marginBottom: "17px" }}
                    />
                    <Skeleton height="40px" width="350px" />
                  </Box>
                  <Box className="save-tolerence-btn">
                    <Skeleton height="40px" width="100px" />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Box>
    );
  };

  const labelInfoTooltipContent = (
    <Box className="btc-paid-tolerence-text-box">
      <Text variant="subtitle1" size={14} font="regular">
        {btcToleranceTooltipContent[0]}
      </Text>
      <Text
        className="grey-text"
        size={12}
        font="regular"
        variant="subtitle1"
        sx={{ marginTop: "13px" }}
      >
        {btcToleranceTooltipContent[1]}
      </Text>
    </Box>
  );

  const gridsWrapper = gridData?.map((gridItem) => {
    const isAvailableTolerence =
      pmTolerance && paymentMethodStatus[gridItem.name]?.showTolerence;

    const showTolerence =
      isAvailableTolerence && gridItem.status === yes.toLowerCase();

    const showTolerenceLoader =
      isAvailableTolerence && gridItem.status === no.toLowerCase();

    return gridItem.id == rowId ? (
      paymentMethodLoader(showTolerenceLoader)
    ) : (
      <Box
        className={classNames(
          "custom-domain-wrapper",
          showTolerence && "tolerence-section-wrapper"
        )}
        key={gridItem.id}
      >
        <Grid display="flex" alignItems="center">
          <Box
            className={classNames(showTolerence && "on-chain-section-img")}
            sx={{ paddingLeft: "20px" }}
          >
            <img
              src={paymentMethodStatus[gridItem.name]?.imageURL}
              alt={gridItem.name}
            />
          </Box>
          <Box width="100%">
            <Box
              display="flex"
              justifyContent="space-between"
              paddingRight="20px"
            >
              <List disablePadding>
                <ListItem disablePadding>
                  <Box>
                    <Box display="flex">
                      <Text
                        className="card-text"
                        size={18}
                        font="bold"
                        align="left"
                        sx={{ paddingRight: "10px" }}
                      >
                        {paymentMethodStatus[gridItem.name]?.label}
                      </Text>
                      {gridItem.is_default_payment_method == 1 && (
                        <Box>
                          <Tag
                            text="Default"
                            variant="success"
                            deleteIcon={
                              <InfoIcon className="custom-domain-active-icon" />
                            }
                            onDelete={() => {}}
                          />
                        </Box>
                      )}
                    </Box>

                    <Text
                      className="grey-text"
                      size={16}
                      font="regular"
                      variant="subtitle1"
                    >
                      {paymentMethodStatus[gridItem.name]?.description}
                    </Text>
                  </Box>
                </ListItem>
              </List>

              {gridItem.is_default_payment_method == 0 && pmPaymentmode && (
                <Box display="flex" alignItems="center" minWidth="115px">
                  <Button
                    disabled={toleranceLoading}
                    variant="contained"
                    label={
                      gridItem.status === no.toLowerCase() ? turnOn : turnOff
                    }
                    onClick={() => {
                      handleChange(
                        gridItem.status === no.toLowerCase()
                          ? yes.toLowerCase()
                          : no.toLowerCase(),
                        gridItem.id
                      );
                    }}
                    className="payout-button"
                  />
                </Box>
              )}
            </Box>
            {showTolerence && (
              <Box className="tolerence-main-box">
                <Box className="form-section">
                  <CustomSelect
                    showLabelInfoIcon
                    labelInfoTooltipContent={labelInfoTooltipContent}
                    customClass="btc-tolerance-autocomplete"
                    name="amount_paid_tolerance"
                    displayEmpty
                    showLabel
                    label={tolerence}
                    value={values.amount_paid_tolerance}
                    MenuProps={{
                      id: "tolerence-select",
                    }}
                    onChange={(e) => {
                      setFieldValue("amount_paid_tolerance", e.target.value);
                    }}
                    renderValue={(value) => {
                      if (value) {
                        let typeObj = _.find(btcOnchainTolerenceArray, {
                          value: typeof value === "object" ? value.id : value,
                        });
                        return typeObj?.label;
                      }
                    }}
                  >
                    {btcOnchainTolerenceArray.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                  <Box className="save-tolerence-btn">
                    <Button
                      loading={toleranceLoading}
                      type="submit"
                      disabled={!(isValid && dirty) || isSubmitting}
                      variant="contained"
                      label={save}
                      onClick={() => {
                        handleChange(yes.toLowerCase(), gridItem.id, true);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Box>
    );
  });

  return (
    <>
      {pmListpayment ? (
        <>
          <CustomDivider />
          <Box className="box-container">
            <Text size={20} font="semibold" variant="subtitle1">
              {paymentMethod}
            </Text>
            <Text
              className="grey-text"
              sx={{ marginTop: "10px" }}
              size={16}
              font="regular"
              variant="subtitle1"
            >
              {turnPaymentMethod}
            </Text>
            {gridsWrapper}
            {loader && paymentMethodLoader()}
          </Box>
        </>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};
export default PaymentMethod;
