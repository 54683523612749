import React, { forwardRef, createRef, useState } from "react";
import { AppBar, Box, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import InvoiceTable from "./InvoiceTable";
import Button from "@speed/common/src/components/Button/Button";
import {
  createNew,
  createNewInvoice,
  testModeTextForInvoice,
  testModeTitle,
} from "../messages";
import { useDispatch, useSelector } from "react-redux";
import {
  setInvoicePreviewData,
  setIsModalOpen,
} from "../../redux/common/actions";
import TestModeHeader from "../Payments/TestModeHeader";
import CloseIcon from "@mui/icons-material/Close";
import Text from "@speed/common/src/components/Text/Text";
import CreateInvoice from "./CreateInvoice";
import { removeAllEmptyFieldsFromNestedObj } from "@speed/common/src/components/constants";
import { callAPIInterface, sendInvoiceAndEmail } from "../constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import AccessDenied from "@speed/common/src/components/AccessDenied";

// import { invoiceTimeAgo } from "../constants";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AllInvoices(props) {
  const ref = createRef();
  const dispatch = useDispatch();
  const liveMode = useSelector((state) => state.auth.liveMode);
  const [isDisabled, setIsDisabled] = useState(false);
  const { invListFe, invCreateFe } = useFlags();

  // const lastSavedInvoiceIn = useSelector(
  //   (state) => state.common.lastSavedInvoiceIn
  // );

  const { isDataAvailable, caughtErrorInStrapi, isModalOpen, history } =
    useSelector((state) => state.common);

  const createNewInvoiceButton = () =>
    invCreateFe && (
      <Box className="action-btn-wrapper mui-fixed">
        <Button
          icon="addIcon"
          className="add-icon"
          label={createNew}
          variant="outlined"
          color="primary"
          onClick={() => {
            dispatch(setIsModalOpen(true));
          }}
        />
      </Box>
    );

  const handleModalClose = () => {
    dispatch(setIsModalOpen(false));
    dispatch(setInvoicePreviewData(null));
  };

  const generateInvoiceRequest = (invoiceFormData) => {
    const {
      customer,
      currency,
      products,
      includeMemo,
      memoMessage,
      includeFooter,
      footerMessage,
    } = invoiceFormData;

    const productArray = products?.map((product) => {
      const obj = product?.prices?.id
        ? {
            type: "line_item",
            price_id: product?.prices?.id,
          }
        : {
            name: product?.name,
            unit_amount: product?.prices?.unit_amount,
            type: "custom_line_item",
          };

      return {
        ...obj,
        quantity: product?.quantity,
      };
    });

    const requestObj = {
      customer_id: customer?.id,
      currency: currency?.code,
      memo: includeMemo && memoMessage,
      footer: includeFooter && footerMessage,
      invoice_line_items: productArray,
    };

    return removeAllEmptyFieldsFromNestedObj(requestObj);
  };

  const handleSubmit = () => {
    const invoiceFormData = ref?.current?.handleSubmit();
    const requestPayload = generateInvoiceRequest(invoiceFormData);
    setIsDisabled(true);
    callAPIInterface("POST", "/invoices", JSON.stringify(requestPayload))
      .then((response) => {
        response?.id &&
          sendInvoiceAndEmail(response?.id)
            .then((invoiceData) => {
              invoiceData && history.push(`/invoices/${invoiceData?.id}`);
              handleModalClose();
              setIsDisabled(false);
            })
            .catch(() => {
              setIsDisabled(false);
            });
      })
      .catch(() => {
        setIsDisabled(false);
      });
  };

  return (
    <Box className="section-wrapper">
      <Box className="payment-wrapper">
        {isDataAvailable
          ? createNewInvoiceButton()
          : ((!isDataAvailable && props.tab) || caughtErrorInStrapi) &&
            createNewInvoiceButton()}

        {invListFe ? <InvoiceTable tab={props.tab} /> : <AccessDenied />}
      </Box>
      <Dialog
        disableEscapeKeyDown
        fullScreen
        open={isModalOpen}
        TransitionComponent={Transition}
        className="fullscreen-modal"
      >
        {!liveMode && (
          <TestModeHeader
            buttonText={testModeTitle}
            captionText={testModeTextForInvoice}
          />
        )}
        <AppBar sx={{ position: "relative" }} className="modal-app-bar">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleModalClose();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Text
              size={20}
              font="semibold"
              sx={{ flex: 1 }}
              className="default-text divider"
              variant="h6"
            >
              {createNewInvoice(true)}
            </Text>
            {/* {lastSavedInvoiceIn && (
              <Text
                sx={{ marginRight: "20px" }}
                size={14}
                className="default-text"
                variant="h6"
              >
                {`Draft saved ${invoiceTimeAgo(lastSavedInvoiceIn)}`}
              </Text>
            )} */}
            <Button
              label={createNewInvoice()}
              variant="contained"
              color="primary"
              className="payment-link-btn"
              onClick={handleSubmit}
              disabled={isDisabled}
            />
          </Toolbar>
        </AppBar>
        {isModalOpen && (
          <Box className="fullscreen-modal-box">
            <CreateInvoice
              ref={ref}
              handleButtonDisable={(value) => setIsDisabled(value)}
            />
          </Box>
        )}
      </Dialog>
    </Box>
  );
}

export default AllInvoices;
