import React from "react";
import GetGuides from "./GetGuides";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Box } from "@mui/material";

const SessionsGuide = () => {
  const { obSessionsguideFe } = useFlags();

  return obSessionsguideFe ? (
    <GetGuides
      moduleName="sessions"
      redirectUrl=""
      guideApiPath="sessions-guide"
      showSteps={false}
      needHelpGuidePath="docs/receive-payments/checkout-session"
    />
  ) : (
    <Box height="70vh" display="flex" alignItems="center">
      <AccessDenied />
    </Box>
  );
};

export default SessionsGuide;
