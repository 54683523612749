import { Box } from "@mui/material";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import { Input } from "@speed/common/src/components/Input/Input";
import Label from "@speed/common/src/components/Label/Label";
import Text from "@speed/common/src/components/Text/Text";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import React from "react";
import {
  addDetailsToYourStore,
  additionalDetails,
  additionalDetailsOnManualPaymentsHint,
  additionDetailsOnManualPayments,
  addPaymentMethod,
  customPaymentMethodName,
  enterPaymentMethodName,
  paymentInstructions,
  paymentInstructionsOnManualPayments,
  paymentInstructionsOnManualPaymentsHint,
  paymentMethodNameText,
} from "../../messages";

const AddPaymentMethod = (props) => {
  const { values, setFieldValue, touched, setTouched, errors } = props;
  return (
    <Box sx={{ mt: "50px" }} display="inline-flex">
      <Box className="grid-box-step">
        <Text style={{ color: "#2a67ff", textAlign: "center" }}>4</Text>
      </Box>
      <Box>
        <Text
          style={{ marginTop: "4px" }}
          className="default-text"
          size={20}
          variant="h2"
        >
          {addPaymentMethod}
        </Text>
        <Text
          className="grey-text margin-top15"
          size={20}
          variant="body1"
          font="regular"
        >
          {addDetailsToYourStore}
        </Text>
        <Box sx={{ mt: "50px", alignItems: "center" }} width={570}>
          <Box display="flex" justifyContent="space-between">
            <Label>{customPaymentMethodName}</Label>
          </Box>
          <Box
            className="shopify-disabled-input"
            sx={{
              display: "flex",
              alignItems: "center",
              "& input": { opacity: "1" },
            }}
          >
            <Input
              name="customPaymentMethodName"
              showLabel={false}
              fullWidth
              type="text"
              label={customPaymentMethodName}
              value={values.custom_payment_method_name}
              error={
                touched.custom_payment_method_name &&
                Boolean(errors.custom_payment_method_name)
              }
              placeholder={enterPaymentMethodName}
              onBlur={() =>
                setTouched({ ...touched, custom_payment_method_name: true })
              }
              onChange={(e) => {
                setTouched({ ...touched, custom_payment_method_name: false });
                setFieldValue("custom_payment_method_name", e.target.value);
              }}
            />
            <Clipboard
              style={{ marginLeft: "10px", cursor: "pointer" }}
              text={values.custom_payment_method_name}
            />
          </Box>
          <Text
            sx={{ marginTop: "13px", display: "flex", gap: 0.5 }}
            className="grey-text"
            size={14}
            variant="body1"
            font="regular"
          >
            <span style={{ color: "#e74c3c" }}>*</span> {paymentMethodNameText}
          </Text>
        </Box>
        <Box width={570} className="margin-top30">
          <Box display="flex" justifyContent="space-between">
            <Label>{additionalDetails}</Label>
          </Box>
          <Box sx={{ display: "flex", alignItems: "end" }}>
            <TextAreaComponent
              maxLength={255}
              minRows={4}
              maxRows={4}
              name="additional details"
              placeholder=""
              fullWidth
              value={additionDetailsOnManualPayments(
                values.custom_payment_method_name
              )}
              disabled={true}
            />
            <Clipboard
              style={{ marginLeft: "10px", cursor: "pointer" }}
              text={additionDetailsOnManualPayments(
                values.custom_payment_method_name
              )}
            />
          </Box>
          <Text
            sx={{ marginTop: "10px", display: "flex", gap: 0.5 }}
            className="grey-text"
            size={14}
            variant="body1"
            font="regular"
          >
            <span style={{ color: "#e74c3c" }}>*</span>{" "}
            {additionalDetailsOnManualPaymentsHint}
          </Text>
        </Box>
        <Box width={570} className="margin-top30">
          <Box display="flex" justifyContent="space-between">
            <Label>{paymentInstructions}</Label>
          </Box>
          <Box sx={{ display: "flex", alignItems: "end" }}>
            <TextAreaComponent
              maxLength={255}
              minRows={4}
              maxRows={4}
              name="payment instructions"
              placeholder=""
              fullWidth
              value={paymentInstructionsOnManualPayments}
              disabled={true}
            />
            <Clipboard
              style={{ marginLeft: "10px", cursor: "pointer" }}
              text={paymentInstructionsOnManualPayments}
            />
          </Box>
          <Text
            sx={{ marginTop: "10px", display: "flex", gap: 0.5 }}
            className="grey-text"
            size={14}
            variant="body1"
            font="regular"
          >
            <span style={{ color: "#e74c3c" }}>*</span>{" "}
            {paymentInstructionsOnManualPaymentsHint}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default AddPaymentMethod;
