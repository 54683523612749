import { Box, TableCell, TableRow } from "@mui/material";
import {
  fetchPrecisedInteger,
  satsToBtcAmount,
} from "@speed/common/src/components/constants";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  noOfRecords,
} from "../constants";
import { emptyTransactionSubText } from "../messages";
import { emptySubText } from "@speed/common/src/components/messages";
import { emptyTransaction } from "../images";
import CustomTable from "@speed/common/src/components/Table/Table";
import HorizontalSearchPageTable from "./HorizontalSearchPageTable";

const TransactionTable = ({
  showHorizontalTable = false,
  searchQueryUrl,
  inputString,
  currency,
}) => {
  const columns = [
    { title: "Type", paddingLeft: "38px !important", width: 167 },
    { title: `Net (${currency})`, align: "right", width: 113 },
    { title: `Amount  (${currency})`, align: "right", width: 144 },
    { title: `Fee  (${currency})`, align: "right", width: 105 },
    { title: "Description", width: 229 },
    { title: "Date", width: 100 },
  ];

  const searchTransactionsRowData = [
    {
      amount: 12,
      created: 1657523272595,
      fee: 0,
      id: "txn_l5gek6fnBxlILlKS",
      net: 12,
      object: "balance_transaction",
      source: "pi_l3xvpqesawJ6VkhR",
      target_currency: "SATS",
      transaction_type: "credit",
      type: "Payment",
    },
    {
      amount: 100,
      created: 1657170638911,
      fee: 0,
      id: "txn_l5akm0jj2MDA6RDF",
      net: 100,
      object: "balance_transaction",
      source: "pi_l5akkzuqGKCSAsac",
      target_currency: "SATS",
      transaction_type: "credit",
      type: "Payment",
    },
    {
      amount: 4722,
      created: 1656310338419,
      fee: 0,
      id: "txn_l4wcesfnlGds3RHl",
      net: 4722,
      object: "balance_transaction",
      source: "pi_l4wcb11oV3TOldQY",
      target_currency: "SATS",
      transaction_type: "credit",
      type: "Payment",
    },
  ];

  const queryParam =
    "?limit=" +
    noOfRecords +
    (currency === "USDT" ? "&target_currency=" + currency : "");

  const [tableRowSkeleton, setTableRowSkeleton] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [rows, setRows] = useState([]);
  const [queryParams, setQueryParams] = useState(queryParam);

  const liveMode = useSelector((state) => state.auth.liveMode);
  const { history } = useSelector((state) => state.common);
  const fromSearchPage = history.location.pathname === "/search";
  useEffect(() => {
    const timer = setTimeout(() => {
      setRows([]);
      setHasMore(true);
      getTransactionsList([], queryParam);
    }, 500);
    return () => clearTimeout(timer);
  }, [currency, liveMode]);

  const getTransactionsList = (lines, params) => {
    if (fromSearchPage) {
      setRows(searchTransactionsRowData);
      setTableRowSkeleton(false);
      setHasMore(false);
    } else {
      setTableRowSkeleton(true);
      callAPIInterface("GET", `/balance-transactions${params}`)
        .then((res) => {
          if (res) {
            setTableRowSkeleton(false);
            if (!res.has_more) {
              setHasMore(false);
            } else {
              setQueryParams(
                queryParam +
                  "&ending_before=" +
                  res.data[res.data.length - 1].id
              );
            }
            setRows(lines.concat(res.data));
          }
        })
        .catch((_err) => {
          setTableRowSkeleton(false);
        });
    }
  };

  let columnsData = columns.map((column, index) => (
    <TableCell
      key={index}
      align={column.align}
      sx={{ width: column.width, paddingLeft: column.paddingLeft }}
    >
      {column.title}
    </TableCell>
  ));
  const showUsdcAmount = (amountFromProps) =>
    fetchPrecisedInteger(amountFromProps, 8);

  let rowsData = rows?.map((rowItem, index) => {
    return (
      <TableRow key={index}>
        <TableCell sx={{ paddingLeft: "38px !important" }}>
          {rowItem?.type}
        </TableCell>
        <TableCell style={{ textAlign: "right" }}>
          {rowItem?.target_currency === "USDT"
            ? showUsdcAmount(rowItem?.net)
            : satsToBtcAmount(rowItem?.net)}
        </TableCell>
        <TableCell style={{ textAlign: "right" }}>
          {rowItem?.target_currency === "USDT"
            ? showUsdcAmount(rowItem?.amount)
            : satsToBtcAmount(rowItem?.amount)}
        </TableCell>
        <TableCell style={{ textAlign: "right" }}>
          {rowItem?.target_currency === "USDT"
            ? showUsdcAmount(rowItem?.fee)
            : satsToBtcAmount(rowItem?.fee)}
        </TableCell>
        <TableCell style={{ textAlign: "left" }}>{rowItem?.source}</TableCell>
        <TableCell>{dateTimeFormatInApp(rowItem?.created)}</TableCell>
      </TableRow>
    );
  });

  const loadMore = useCallback(() => {
    getTransactionsList(rows, queryParams);
  }, [rows]);

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
    textOnNoData: emptySubText(liveMode, "transactions"),
    subTextOnNoData: emptyTransactionSubText,
    noDataImage: emptyTransaction,
  };

  return (
    <>
      {showHorizontalTable && fromSearchPage ? (
        <HorizontalSearchPageTable
          moduleName="balance_transaction"
          columns={columns}
          rows={tableProps.rows}
          searchQueryUrl={searchQueryUrl}
          inputString={inputString}
        />
      ) : (
        <Box className="payment-links-wrapper main-content">
          <CustomTable {...tableProps} />
        </Box>
      )}
    </>
  );
};

export default TransactionTable;
