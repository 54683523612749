import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import Button from "@speed/common/src/components/Button/Button";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import {
  RouteBreadcrumbs,
  renderStatus,
  activateDeactivatePaymentLink,
  callAPIInterface,
  updateMetadata,
  moduleMetaDataRows,
  getCashbackDetails,
  getTypeOfCashback,
  detailPageCFRender,
  dateTimeFormatInApp,
} from "../constants";
import {
  clipboardElement,
  getCurrencyObj,
  linkStatus,
  redirectWhenEntityNotFound,
  generateBreadcrumbs,
  showAmount,
} from "@speed/common/src/components/constants";
import { Grid, TableCell, TableRow } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import {
  deActivate,
  latestPaymentLabel,
  status,
  dateCreated,
  entityNotFoundMessage,
  paymentLink,
  events as eventsText,
  metaDataText,
  edit,
  customerChoose,
  pageDescLabel,
  imageLabel,
  add,
  amountType,
  amountOptions,
  presetAmountLabel,
  amountLabel,
  cashbackIDLabel,
  cashbackLabel,
  minimumPresetLabel,
  maximumPresetLabel,
  billingAndShipping,
  yes,
  billingText,
  detailCustomFieldLabel,
  collectAddresses,
  collectEmailAddress,
  collectPhoneNumber,
  sats,
  btc,
} from "../messages";
import { useSelector } from "react-redux";
import WarningIcon from "@mui/icons-material/Warning";
import { events } from "../Events/Events";
import { useFlags } from "launchdarkly-react-client-sdk";
import MetaDataForm from "../MetaDataForm";
import { anyAmount, pageTitleLabel } from "@speed/common/src/messages";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import { isEmpty } from "lodash";
import PaymentDetailDescription from "@speed/common/src/components/PaymentDetailDescription";
import PaymentDetailTitle from "@speed/common/src/components/PaymentDetailTitle";
import DetailPagePaymentSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import LinkSkeleton from "@speed/common/src/components/Skeleton/LinkSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";

const latestPaymentColumnData = [
  { title: "Payment Id", width: "400px" },
  { title: "Amount", align: "right", width: "150px" },
  { title: "Date", width: "200px" },
  { title: "Status", width: "150px" },
];

const HeaderContent = ({ paymentLinkDetails, isLinkActive }) => {
  const { currency, url, amount } = paymentLinkDetails;
  return (
    <Box className="header-content">
      <Text className="default-text" size={28}>
        {paymentLink}
      </Text>

      <Box className="header-price-content">
        <Box className="fiat-price">
          <Text className="default-text" size={20}>
            {amount !== 0 && getCurrencyObj(currency).symbol}
            {amount === 0
              ? customerChoose
              : showAmount({
                  amount,
                  currency,
                })}
          </Text>
        </Box>
      </Box>

      <Box sx={{ marginTop: "15px" }}>
        {clipboardElement(url, isLinkActive, "branding-detail", "", true)}
      </Box>
    </Box>
  );
};

const PaymentLinkDetails = (props) => {
  const urlParams = useParams();

  // States
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const liveMode = useSelector((state) => state.auth.liveMode);
  const history = useSelector((state) => state.common.history);
  const [latestPaymentRowData, setLatestPaymentData] = useState([]);
  const [paymentLinkDetails, setPaymentLinkDetails] = useState(null);
  const [isLinkNotFound, setIsLinkNotFound] = useState(false);
  const [paymentSkeleton, setPaymentSkeleton] = useState(false);
  const [isLinkActive, setIsLinkActive] = useState(null);
  const [eventsList, setEventsList] = useState([]);
  const [eventSkeleton, setEventSkeleton] = useState(true);
  const [metaDataSkeleton, setMetaDataSkeleton] = useState(true);
  const [isMetaDataEditable, setIsMetaDataEditable] = useState(false);
  const [metaDataList, setMetaDataList] = useState([]);
  const [cashbackData, setCashbackData] = useState([]);
  const [paymentLinkCashbackLoader, setPaymentLinkCashbackLoader] =
    useState(false);
  const [disableActionButton, setDisableActionButton] = useState(false);

  const { plEventFe, plPaymentlistFe, plMetadataFe, plCustomfieldsFe } =
    useFlags();

  // Redux states

  const getPaymentLinkCashbackData = (cashbackId) => {
    setPaymentLinkCashbackLoader(true);
    getCashbackDetails(cashbackId)
      .then((res) => {
        setCashbackData(res);
        setPaymentLinkCashbackLoader(false);
      })
      .catch(() => {
        setPaymentLinkCashbackLoader(false);
      });
  };

  const getLatestPayment = (id) => {
    callAPIInterface("GET", `/payment-links/${id}/payments`)
      .then((res) => {
        if (res) {
          setPaymentSkeleton(false);
          setLatestPaymentData(res.data);
        }
      })
      .catch(() => {
        setPaymentSkeleton(false);
      });
  };

  const getEventsList = () => {
    setEventSkeleton(true);
    const filterEvents = {
      ids: [urlParams?.id],
    };
    callAPIInterface("POST", "/events/filters", JSON.stringify(filterEvents))
      .then((res) => {
        if (res) {
          setEventSkeleton(false);
          setEventsList(res?.data);
        }
      })
      .catch(() => {
        setEventSkeleton(false);
      });
  };

  const getPaymentLinkDetails = () => {
    setPaymentSkeleton(true);
    setMetaDataSkeleton(true);
    callAPIInterface("GET", `/payment-links/${urlParams.id}`)
      .then((res) => {
        if (res) {
          if (res?.cashback?.id) {
            getPaymentLinkCashbackData(res.cashback.id);
          }

          plEventFe && getEventsList();
          getLatestPayment(res.id);
          setPaymentLinkDetails(res);
          setIsLinkActive(res?.status === "active" || false);
        }
      })
      .catch((err) => {
        setPaymentSkeleton(false);
        setIsLinkNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const loadDetailPage = () => {
    setPaymentLinkDetails(null);
    setIsLinkNotFound(false);
    getPaymentLinkDetails();
  };

  useEffect(() => {
    loadDetailPage();
  }, [liveMode, urlParams?.id, plEventFe]);

  useEffect(() => {
    if (plMetadataFe && paymentLinkDetails?.metadata) {
      const convertedMetaDataToArray = Object.entries(
        paymentLinkDetails?.metadata
      )?.map((e) => ({
        key: e[0],
        value: e[1],
      }));
      setMetaDataList(convertedMetaDataToArray);
      setMetaDataSkeleton(false);
    }
  }, [plMetadataFe, paymentLinkDetails?.metadata]);

  const handleActiveDeActive = (status) => {
    if (!status) {
      setDisableActionButton(true);
      activateDeactivatePaymentLink(urlParams.id).then((res) => {
        if (res) {
          setDisableActionButton(false);
          loadDetailPage();
        }
      });
    }
  };

  const latestPaymentRows =
    latestPaymentRowData &&
    latestPaymentRowData.map((rowItem) => {
      return (
        <TableRow
          key={rowItem.id}
          className="clickable"
          onClick={() => history.push(`/payments/` + rowItem.id)}
        >
          <TableCell>{rowItem.id}</TableCell>
          <TableCell align="right">
            {showAmount({
              amount: rowItem.target_amount_paid,
              currency: rowItem.target_currency,
              targetedCurrency:
                rowItem.target_currency === sats
                  ? btc
                  : rowItem.target_currency,
              appendCurrency: true,
            })}
          </TableCell>
          <TableCell align="left">
            {dateTimeFormatInApp(rowItem.created)}
          </TableCell>
          <TableCell align="left">{renderStatus(rowItem.status)}</TableCell>
        </TableRow>
      );
    });

  const eventRows = eventsList?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/events/${rowItem?.id}`)}
      >
        <TableCell>{events(rowItem)}</TableCell>
        <TableCell align="right" sx={{ color: "#848b9e" }}>
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
      </TableRow>
    );
  });

  const commonDetailsData = [
    {
      header: status,
      cell: (
        <Tag
          text={linkStatus[paymentLinkDetails?.status]?.label}
          variant={linkStatus[paymentLinkDetails?.status]?.variant}
        />
      ),
    },
    {
      header: dateCreated,
      cell: (
        <Text variant="h4" size={16} className="default-text" font="regular">
          {dateTimeFormatInApp(paymentLinkDetails?.created)}
        </Text>
      ),
    },
  ];

  const detailsTableRightSideCashbackData = () => {
    const typeOfCashback = getTypeOfCashback(cashbackData);

    return [
      {
        header: cashbackIDLabel,
        cell: clipboardElement(
          paymentLinkDetails?.cashback?.id,
          true,
          "",
          "text",
          true
        ),
      },
      {
        header: cashbackLabel,
        cell:
          !paymentLinkCashbackLoader && cashbackData?.name ? (
            <TruncatedTextTooltip
              textValue={`${cashbackData.name} - (${typeOfCashback})`}
              cellWidth="300px"
              textProps={{ className: "default-text" }}
            />
          ) : (
            <CommonSkeleton width="100%" sx={{ borderRadius: "8px" }} />
          ),
      },
    ];
  };

  const renderMinMaxAmount = (resultArr, label, amount) => {
    resultArr.push({
      header: label,
      headerStyle: { verticalAlign: "top" },
      cell: (
        <Text
          size={16}
          className="default-text"
          variant="subtitle1"
          font="regular"
        >
          {getCurrencyObj(paymentLinkDetails?.currency).symbol}
          {amount}
        </Text>
      ),
    });
  };

  const detailsTableLeftSideData = () => {
    const {
      title,
      title_description,
      type,
      amount,
      currency,
      preset,
      options,
      cashback,
      customer_collections_status,
    } = paymentLinkDetails;
    const resultArr = [commonDetailsData[0]];
    title &&
      resultArr.push({
        header: pageTitleLabel,
        headerStyle: { verticalAlign: "top" },
        cell: <PaymentDetailTitle pageTitle={title} fromDetails={true} />,
      });
    if (cashback?.id)
      resultArr.splice(1, 0, detailsTableRightSideCashbackData()[0]);

    title_description &&
      resultArr.push({
        header: pageDescLabel,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <PaymentDetailDescription
            pageDescription={title_description}
            isFromDetail={true}
            boxStyle={{ maxHeight: "120px !important" }}
          />
        ),
      });

    const amountTypeLabel =
      type === "preset" ? presetAmountLabel : amountOptions;

    if (type && (type !== "fixed" || amount === 0))
      resultArr.push({
        header: amountType,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <Text
            size={16}
            className="default-text"
            variant="subtitle1"
            font="regular"
          >
            {amount === 0 ? anyAmount : amountTypeLabel}
          </Text>
        ),
      });

    if (
      customer_collections_status?.is_shipping_address_enabled ||
      customer_collections_status?.is_billing_address_enabled
    ) {
      resultArr.push({
        header: collectAddresses,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <Text
            size={16}
            className="default-text"
            variant="subtitle1"
            font="regular"
          >
            {customer_collections_status?.is_shipping_address_enabled
              ? billingAndShipping
              : billingText}
          </Text>
        ),
      });
    }
    if (customer_collections_status?.is_email_enabled) {
      resultArr.push({
        header: collectEmailAddress,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <Text
            size={16}
            className="default-text"
            variant="subtitle1"
            font="regular"
          >
            {yes}
          </Text>
        ),
      });
    }
    if (customer_collections_status?.is_phone_enabled) {
      resultArr.push({
        header: collectPhoneNumber,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <Text
            size={16}
            className="default-text"
            variant="subtitle1"
            font="regular"
          >
            {yes}
          </Text>
        ),
      });
    }

    if (type === "preset")
      resultArr.push({
        header: presetAmountLabel,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <Text
            size={16}
            className="default-text"
            variant="subtitle1"
            font="regular"
          >
            {getCurrencyObj(currency).symbol}
            {showAmount({ amount, currency })}
          </Text>
        ),
      });

    if (type === "preset" && !isEmpty(preset)) {
      preset.min_amount &&
        renderMinMaxAmount(
          resultArr,
          minimumPresetLabel,
          showAmount({ amount: preset.min_amount, currency })
        );
      preset.max_amount &&
        renderMinMaxAmount(
          resultArr,
          maximumPresetLabel,
          showAmount({ amount: preset.max_amount, currency })
        );
    }

    if (type === "options" && options?.length)
      resultArr.push({
        header: amountLabel,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <Text
            size={16}
            className="default-text"
            variant="subtitle1"
            font="regular"
          >
            {options.map((item, index) => {
              return (
                <>
                  {getCurrencyObj(currency).symbol}
                  {showAmount({ amount: item, currency })}
                  {options.length - 1 > index ? " - " : ""}
                </>
              );
            })}
          </Text>
        ),
      });

    // custom field details
    if (paymentLinkDetails?.custom_fields?.length > 0 && plCustomfieldsFe) {
      resultArr.push({
        header: detailCustomFieldLabel,
        headerStyle: { verticalAlign: "top" },
        cell: detailPageCFRender(paymentLinkDetails?.custom_fields),
      });
    }

    return resultArr;
  };

  const detailsTableRightSideData = () => {
    const { title_image } = paymentLinkDetails;
    const resultArr = [commonDetailsData[1]];
    if (paymentLinkDetails?.cashback?.id)
      resultArr.splice(1, 0, detailsTableRightSideCashbackData()[1]);
    title_image &&
      resultArr.push({
        header: imageLabel,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <img src={title_image} className="pl-preview-image link-detail" />
        ),
      });

    return resultArr;
  };

  const onSubmit = (payload) => {
    setMetaDataSkeleton(true);
    const finalPayload = { metadata: payload };
    updateMetadata(finalPayload, `/payment-links/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setPaymentLinkDetails(res);
        }
        setMetaDataSkeleton(false);
        setIsMetaDataEditable(false);
      })
      .catch((err) => {
        setMetaDataSkeleton(false);
        setIsMetaDataEditable(false);
      });
  };

  const onCancel = () => setIsMetaDataEditable(false);

  const renderActionButton = () => {
    return isLinkActive ? (
      <Button
        icon="closeIcon"
        label={deActivate}
        variant="outlined"
        color="error"
        onClick={() => {
          handleActiveDeActive(false);
        }}
        disabled={disableActionButton}
      />
    ) : null;
  };

  const getCommonVerticalDataObj = () => {
    const tableData = {
      rowData: commonDetailsData,
    };
    if (paymentLinkDetails?.cashback?.id)
      tableData.rightSideRowData = detailsTableRightSideCashbackData();

    return tableData;
  };

  const renderPaymentLinkDetailContent = () => {
    const isCustomAvailable =
      paymentLinkDetails?.customer_collections_status &&
      Object.values(paymentLinkDetails.customer_collections_status).includes(
        true
      );

    let verticalTableDataObj = {};
    const shouldPartitionVisible =
      paymentLinkDetails?.title ||
      paymentLinkDetails?.title_description ||
      paymentLinkDetails?.title_image ||
      paymentLinkDetails?.amount === 0 ||
      (paymentLinkDetails?.type && paymentLinkDetails?.type !== "fixed") ||
      paymentLinkDetails?.cashback ||
      isCustomAvailable ||
      (paymentLinkDetails?.custom_fields?.length > 0 && plCustomfieldsFe);

    if (shouldPartitionVisible) {
      verticalTableDataObj = {
        rowData: detailsTableLeftSideData(),
        rightSideRowData: detailsTableRightSideData(),
      };
    } else {
      verticalTableDataObj = getCommonVerticalDataObj();
    }

    return (
      <Box className="details-content">
        <VerticalTable label="Details" {...verticalTableDataObj} />
      </Box>
    );
  };

  return !isLinkNotFound ? (
    <Grid className="payment-link-detail-wrapper" container spacing={1}>
      <Box className="section-wrapper" sx={{ position: "relative" }}>
        {paymentLinkDetails && (
          <Box
            className="action-btn-wrapper"
            style={{ top: "23px", right: "2px" }}
          >
            {renderActionButton()}
          </Box>
        )}

        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />

        {paymentLinkDetails ? (
          <>
            <HeaderContent
              isLinkActive={isLinkActive}
              paymentLinkDetails={paymentLinkDetails}
            />
            {renderPaymentLinkDetailContent()}
          </>
        ) : (
          <LinkSkeleton props={props} />
        )}

        {plPaymentlistFe && (
          <>
            {!paymentSkeleton ? (
              <Box className="product-content latest-payment-content">
                <HorizontalTable
                  tableBodyClassName="pointer-cursor"
                  label={latestPaymentLabel}
                  columns={latestPaymentColumnData}
                  rows={latestPaymentRows}
                  rowsPerPage={3}
                  isColumnShown={latestPaymentRows.length > 0 ? true : false}
                  handleViewAllClick={() => {
                    history.push(
                      `/payment-links/${paymentLinkDetails?.id}/payments`
                    );
                  }}
                  isShowButton={latestPaymentRows.length > 3}
                />
              </Box>
            ) : (
              <DetailPagePaymentSkeleton />
            )}
          </>
        )}
        {plMetadataFe && (
          <>
            {!metaDataSkeleton ? (
              <Box className="product-content meta-data-content">
                {isMetaDataEditable ? (
                  <MetaDataForm
                    metaDataList={metaDataList}
                    setIsMetaDataEditable={setIsMetaDataEditable}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                  />
                ) : (
                  <HorizontalTable
                    label={metaDataText}
                    rows={moduleMetaDataRows(metaDataList)}
                    rowsPerPage={51}
                    isColumnShown={moduleMetaDataRows(metaDataList)?.length > 0}
                    tableBodyClassName="border-none"
                    isShowButton={!isMetaDataEditable}
                    displayShowButton={true}
                    viewAllButtonLabel={metaDataList?.length > 0 ? edit : add}
                    viewAllButtonIcon="editIcon"
                    handleViewAllClick={() => setIsMetaDataEditable(true)}
                  />
                )}
              </Box>
            ) : (
              <DetailPagePaymentSkeleton />
            )}
          </>
        )}
        {plEventFe && (
          <>
            {!eventSkeleton ? (
              <Box className="product-content">
                <HorizontalTable
                  label={eventsText}
                  isColumnShown={eventRows?.length > 0}
                  rows={eventRows}
                  rowsPerPage={3}
                  handleViewAllClick={() => {
                    history.push(`/payment-links/${urlParams?.id}/events`);
                  }}
                  isShowButton={eventRows?.length > 3}
                  tableBodyClassName="border-none"
                />
              </Box>
            ) : (
              <DetailPagePaymentSkeleton />
            )}
          </>
        )}
      </Box>
    </Grid>
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("Link")}
        subTitle={`Payment link ${urlParams.id} is not found.`}
        btnText={`View ${(liveMode && "live") || "test"} mode links`}
      />
    </Box>
  );
};

export default PaymentLinkDetails;
