import React, { useState, createRef, useMemo } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Slide from "@mui/material/Slide";
import Text from "@speed/common/src/components/Text/Text";
import { Button } from "@speed/common/src/components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import { saveAs } from "file-saver";

import {
  createNew,
  createWithdrawalLinkMsg,
  downloadTemplateLabel,
  importFileLabel,
  importLabel,
  testModeTextForWithdrawal,
  testModeTitle,
  uploadFileLabel,
} from "../messages";
import TestModeHeader from "../Payments/TestModeHeader";
import { back, next } from "@speed/common/src/components/messages";
import {
  setIsModalOpen,
  setPaymentConfirmationOption,
  setPaymentRedirectWebPreview,
  updatePaymentInfo,
  updatePaymentMessage,
} from "../../redux/common/actions";
import WithdrawalLinkTable from "../Common/WithdrawalLinkTable";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { callAPIInterface } from "../constants";
import WithdrawalLinkModal from "./WithdrawalLinkModal";
import FlatfileSpace from "./Flatfile/FlatfileSpace";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import SendViaEmailModal from "./SendViaEmailModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LinkList(props) {
  const ref = createRef();
  const confirmRef = createRef();

  const [disabled, setDisabled] = useState(true);
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [disableBackButton, setDisableBackButton] = useState(false);
  const [isWithdrawalLink, setIsWithdrawalLink] = useState(false);
  const [isLoadingImport, setIsLoadingImport] = useState(false);
  const [showSpace, setShowSpace] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();

  const liveMode = useSelector((state) => state.auth.liveMode);
  const openPopper = Boolean(anchorEl);

  const {
    history,
    paymentConfirmationOption,
    isModalOpen,
    isDataAvailable,
    caughtErrorInStrapi,
    openSendViaEmailModal,
  } = useSelector((state) => state.common);

  const {
    wlConfirmwithdrawallink,
    wlListallwithdrawal,
    wlCreatewithdrawal,
    wlDeactivateFe,
    wlDetailpageFe,
    wlGetpasswordFe,
    wlImportlinksFe,
    wlImportedlinksFe,
    wlDownloadtemplateFe,
  } = useFlags();

  const showImportBtn =
    liveMode && (wlImportedlinksFe || wlDownloadtemplateFe || wlImportlinksFe);

  const handleModalClose = () => {
    dispatch(setIsModalOpen(false));
    setIsWithdrawalLink(false);
    dispatch(updatePaymentMessage(""));
    dispatch(setPaymentRedirectWebPreview(false));
    dispatch(updatePaymentInfo(null));
  };

  const handleNext = () => {
    setIsWithdrawalLink(true);
    if (paymentConfirmationOption == "redirect") {
      dispatch(setPaymentRedirectWebPreview(true));
    }
  };

  const handleBackButton = () => {
    setIsWithdrawalLink(false);
  };

  const handleSubmit = () => {
    // return the values of form while submitting the form

    const confirmData = confirmRef?.current?.handleSubmitConfirm();

    const formData = ref?.current?.handleSubmit();

    let withdrawalLinkFormData = null;

    if (wlConfirmwithdrawallink) {
      withdrawalLinkFormData = {
        ...formData,
        ...confirmData,
      };
    } else {
      withdrawalLinkFormData = {
        ...formData,
      };
    }

    if (withdrawalLinkFormData) {
      if (wlConfirmwithdrawallink) {
        setDisableCreateButton(true);
        setDisableBackButton(true);
      } else setDisabled(true);

      callAPIInterface(
        "POST",
        "/withdrawal-links",
        JSON.stringify(withdrawalLinkFormData)
      )
        .then((response) => {
          response && history.push(`/withdrawal-links/${response.id}`);
          dispatch(setPaymentConfirmationOption("confirmation"));
          dispatch(setPaymentRedirectWebPreview(false));
          dispatch(updatePaymentMessage(""));
        })
        .catch((_error) => {
          if (wlConfirmwithdrawallink) {
            setDisableCreateButton(false);
            setDisableBackButton(false);
          } else setDisabled(false);
        });
    }
  };

  const renderCreateLinkButton = () => {
    return (
      <Button
        label={createWithdrawalLinkMsg}
        variant="contained"
        color="primary"
        className="payment-link-btn"
        onClick={handleSubmit}
        disabled={disabled}
      />
    );
  };

  const renderCreateConfirmButtons = () => {
    return !isWithdrawalLink ? (
      <Button
        label={next}
        variant="contained"
        color="primary"
        className="payment-link-btn"
        disabled={disabled}
        onClick={handleNext}
      />
    ) : (
      <React.Fragment>
        <Button
          variant="outlined"
          color="primary"
          label={back}
          onClick={handleBackButton}
          sx={{ marginRight: "18px" }}
          disabled={disableBackButton}
        ></Button>
        <Button
          label={createWithdrawalLinkMsg}
          variant="contained"
          color="primary"
          className="payment-link-btn"
          onClick={handleSubmit}
          disabled={disableCreateButton}
        />
      </React.Fragment>
    );
  };

  const handleClosePopper = () => setAnchorEl(null);

  const onOpenSpace = () => {
    setIsLoadingImport(true);
    setShowSpace(true);
  };

  const importButtonAction = useMemo(() => {
    const actions = [];
    if (wlImportlinksFe)
      actions.push({
        actionName: uploadFileLabel,
        onClickAction: () => {
          onOpenSpace();
        },
      });

    if (wlImportedlinksFe)
      actions.push({
        actionName: importFileLabel,
        onClickAction: () => {
          history.push("/withdrawal-links/import");
        },
      });

    if (wlDownloadtemplateFe)
      actions.push({
        actionName: downloadTemplateLabel,
        onClickAction: () => {
          saveAs(
            process.env.REACT_APP_WL_FLAT_FILE_EXAMPLE_URL,
            "withdrawallink_import_template.xlsx"
          );
        },
      });

    return actions.map((action) => (
      <ListItem key={action.actionName} disablePadding>
        <ListItemButton
          onClick={() => {
            action.onClickAction();
            handleClosePopper();
          }}
        >
          <ListItemText sx={{ color: "#0A193E" }} primary={action.actionName} />
        </ListItemButton>
      </ListItem>
    ));
  }, [wlImportlinksFe, wlImportedlinksFe, wlDownloadtemplateFe]);

  const importBtnElement = (
    <Box className="expand-icon">
      {importLabel}
      {openPopper ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </Box>
  );

  const headerActionButtons = () => (
    <Box className="action-btn-wrapper mui-fixed wl-actions">
      {showImportBtn && (
        <Button
          className="import-wl-btn"
          loading={isLoadingImport}
          icon="uploadIcon"
          label={importBtnElement}
          variant="outlined"
          color="primary"
          onClick={(event) =>
            setAnchorEl(anchorEl ? null : event.currentTarget)
          }
        />
      )}
      <CustomPopper
        disablePortal={true}
        open={openPopper}
        anchorEl={anchorEl}
        position="bottom"
        handleClose={handleClosePopper}
      >
        <List>{importButtonAction}</List>
      </CustomPopper>

      <Button
        sx={{ marginLeft: "10px" }}
        icon="addIcon"
        className="add-icon"
        label={createNew}
        variant="outlined"
        color="primary"
        onClick={() => {
          dispatch(setIsModalOpen(true));
        }}
      />
    </Box>
  );

  return (
    <>
      {wlCreatewithdrawal &&
        (isDataAvailable || caughtErrorInStrapi || props.tab) &&
        headerActionButtons()}

      {wlListallwithdrawal ? (
        <>
          <WithdrawalLinkTable
            tab={props.tab}
            deactivateLDFlag={wlDeactivateFe}
            detailPageLDFlag={wlDetailpageFe}
            copyPasswordLDFlag={wlGetpasswordFe}
          />
          {showSpace && (
            <FlatfileSpace
              setIsLoadingImport={setIsLoadingImport}
              setShowSpace={setShowSpace}
            />
          )}
          {openSendViaEmailModal && <SendViaEmailModal />}
        </>
      ) : (
        <AccessDenied />
      )}

      <Dialog
        disableEscapeKeyDown
        fullScreen
        open={isModalOpen}
        TransitionComponent={Transition}
        className="fullscreen-modal"
      >
        {!liveMode && (
          <TestModeHeader
            buttonText={testModeTitle}
            captionText={testModeTextForWithdrawal}
          />
        )}
        <AppBar sx={{ position: "relative" }} className="modal-app-bar">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleModalClose();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Text
              size={20}
              font="semibold"
              sx={{ flex: 1 }}
              className="default-text divider"
              variant="h6"
            >
              {createWithdrawalLinkMsg}
            </Text>

            {wlConfirmwithdrawallink
              ? renderCreateConfirmButtons()
              : renderCreateLinkButton()}
          </Toolbar>
        </AppBar>
        <Box className="fullscreen-modal-box">
          <WithdrawalLinkModal
            ref={ref}
            handleButtonDisable={(value) => setDisabled(value)}
            isPaymentLink={isWithdrawalLink}
            isFromWithdrawalLink={true}
            setDisableCreateButton={setDisableCreateButton}
            confirmRef={confirmRef}
          />
        </Box>
      </Dialog>
    </>
  );
}

export default LinkList;
