import React, { useState, useEffect } from "react";
import { Paper, Box, Grid, CssBaseline } from "@mui/material";
import Button from "@speed/common/src/components/Button/Button";
import { Input } from "@speed/common/src/components/Input/Input";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import { Text } from "@speed/common/src/components/Text/Text";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import {
  firstNameLabel,
  welcomeMsg,
  agreeMsg,
  termsAndPrivacyMsg,
  invite,
  inviteRevokeMsg,
  lastNameLabel,
  firstNamePlaceholder,
  lastNamePlaceholder,
  haveAccountMsg,
  invalidLabel,
  inviteRedeem,
  inviteInvalidForEmailMsg,
  invitation,
  invitationRevoke,
  invitationRedeem,
  emailAlreadyExist,
  inviteInvalid,
  inviteeRedeem,
  signUpDescription,
  affiliateInvitationMessage,
  loginAffiliateNotFoundMessage,
  registerLabel,
  backButtonTxt,
} from "../messages";
import InfoIcon from "@mui/icons-material/Info";
import { useFormik } from "formik";
import * as yup from "yup";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { signUp } from "../../redux/auth/actions";
import { Countries } from "@speed/common/src/components/country";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import _ from "lodash";
import {
  affiliatePartnerData,
  affiliatePartnerDataNotAvailable,
  handleSessionSharingRedirection,
  isAffiliatePartnerDataAvailable,
  isAlphabet,
  passwordValidationSchema,
  sourceLnDev,
  sourceWebApp,
  whiteLogoImage,
} from "../constants";
import { getCountryObj } from "@speed/common/src/components/constants";
import { showToast } from "../../redux/common/actions";
import {
  invalidEmail,
  loadingMsg,
  emailLabel,
  emailPlaceholder,
  passwordLabel,
  passwordPlaceholder,
  signIn,
} from "@speed/common/src/components/messages";
import { useLocation } from "react-router-dom";
import { limitedOfferImage } from "@speed/common/src/components/images";
import WelcomeBox from "../Common/WelcomeBox";
import WarningIcon from "@mui/icons-material/Warning";
import PasswordPolicy from "@speed/common/src/components/PasswordPolicy";
import { countryLabel, countryPlaceholder } from "@speed/common/src/messages";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// import { useFlags } from "launchdarkly-react-client-sdk";
import LoginWithSocialMedia from "../Common/LoginWithSocialMedia";

const SignUp = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isMobile, history } = useSelector((state) => state.common);
  // const { ssoGoogleauthFe, ssoAppleauthFe } = useFlags();

  const [invited, setInvited] = useState(false);
  const [inviteRevoke, setInviteRevoke] = useState(false);
  const [redeemInvite, setRedeemInvite] = useState(false);
  const [inviteInvalidForEmail, setInviteInvalidForEmail] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [invitedByEmail, setInvitedByEmail] = useState("");
  const fromPartnerDataAvailable = isAffiliatePartnerDataAvailable();
  const noDataFromPartner = affiliatePartnerDataNotAvailable(location);
  const [signupWithEmailPassword, setSignupWithEmailPassword] = useState(false);
  const [socialMediaAuthInviteState, setSocialMediaAuthInviteState] =
    useState();
  const showLoginWithSocialMediaScreen = !signupWithEmailPassword;
  // const showLoginWithSocialMediaScreen =
  //   (ssoGoogleauthFe || ssoAppleauthFe) && !signupWithEmailPassword;

  const flagManagement = () => {
    const locationData =
      location.data ||
      (sessionStorage.getItem("inviteLocationData") &&
        JSON.parse(sessionStorage.getItem("inviteLocationData")));

    if (locationData) {
      location.data &&
        sessionStorage.setItem(
          "inviteLocationData",
          JSON.stringify(location.data)
        );
      const { flag, account_name, invited_by_email, email, invitee_id } =
        locationData;
      setAccountName(account_name);
      setInvitedByEmail(invited_by_email);
      if (flag === invitation) {
        setFieldValue("email", email);
        setFieldValue("invitee_id", invitee_id);
        setInvited(true);
      }
      flag === invitationRevoke && setInviteRevoke(true);
      flag === invitationRedeem && setRedeemInvite(true);
    }
  };

  useEffect(() => {
    flagManagement();
  }, [location.data]);

  useEffect(() => {
    handleSessionSharingRedirection(location.search);
  }, []);

  const getSource = () => {
    const redirectUrl = sessionStorage.getItem("redirect");
    if (redirectUrl?.includes(sourceLnDev)) {
      return sourceLnDev;
    }

    return sourceWebApp;
  };

  const inviteFlags = [invitationRedeem, emailAlreadyExist, inviteInvalid];
  const isDefaultAffiliate = affiliatePartnerData()?.is_default;
  const handleSubmit = (submitValues) => {
    let params = submitValues;
    params.first_name = submitValues.first_name.trim();
    params.last_name = submitValues.last_name.trim();
    params.source = getSource();
    if (fromPartnerDataAvailable) {
      if (isDefaultAffiliate) {
        params["account"] = affiliatePartnerData()?.account_id;
        delete params["invitee_id"];
      } else {
        params["invitee_id"] = affiliatePartnerData()?.id;
      }
    }
    setLoading(true);
    dispatch(signUp(_.omit(params, "termsAndPrivacy"))).then((res) => {
      setLoading(false);
      if (!res) {
        setSubmitting(false);
      } else {
        if (!fromPartnerDataAvailable && res && inviteFlags.includes(res)) {
          res == inviteeRedeem && setRedeemInvite(true);
          (res == emailAlreadyExist || res == inviteInvalid) &&
            setInviteInvalidForEmail(true);
          dispatch(
            showToast({
              isToastOpen: false,
            })
          );
        }
        setInvited(false);
      }
    });
  };

  const validationSchema = yup.object({
    first_name: yup
      .string()
      .required("")
      .matches(isAlphabet, invalidLabel(firstNameLabel))
      .trim(),
    last_name: yup
      .string()
      .required("")
      .matches(isAlphabet, invalidLabel(lastNameLabel))
      .trim(),
    email: yup.string().email(invalidEmail).required(""),
    password: passwordValidationSchema,
    country: yup.mixed().required(),
    termsAndPrivacy: yup.bool().oneOf([true]),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      country: null,
      password: "",
      termsAndPrivacy: false,
      invitee_id: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    setFieldValue,
    setTouched,
    setSubmitting,
  } = formik;

  const getInviteeAuthObj = () => {
    let inviteeAuthState = { fromSignUpPage: true };
    if (fromPartnerDataAvailable) {
      const affiliateObj = {
        key: isDefaultAffiliate ? "account" : "invitee_id",
        value:
          affiliatePartnerData()?.[
            affiliatePartnerData().is_default ? "account_id" : "id"
          ],
      };
      inviteeAuthState = {
        ...inviteeAuthState,
        affiliate_invite: {
          [affiliateObj.key]: affiliateObj.value,
        },
      };
    } else {
      inviteeAuthState = {
        ...inviteeAuthState,
        ...(values?.invitee_id && { invitee_id: values.invitee_id }),
      };
    }
    return inviteeAuthState;
  };

  useEffect(() => {
    fromPartnerDataAvailable &&
      affiliatePartnerData()?.email &&
      setFieldValue("email", affiliatePartnerData().email);
  }, [fromPartnerDataAvailable]);

  useEffect(() => {
    setSocialMediaAuthInviteState(getInviteeAuthObj());
  }, [values?.invitee_id]);

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const checkIfSameEmailIsDifferent =
    values?.email !== affiliatePartnerData()?.email && !isDefaultAffiliate;

  const hasDifferentEmailAndNoPartnerData =
    noDataFromPartner || checkIfSameEmailIsDifferent;

  return (
    <Grid
      className="sign-up"
      container
      component="main"
      direction={isMobile ? "column" : "row"}
      sx={{
        width: "auto",
      }}
    >
      <CssBaseline />
      <BackdropLoader
        open={isLoading}
        alt="loading..."
        text={loadingMsg}
        customClass="loading-in-auth"
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: !isMobile ? "space-between" : "center",
          width: "100%",
        }}
      >
        {!isMobile && (
          <Box sx={{ paddingLeft: "150px", paddingTop: "60px" }}>
            <WelcomeBox />
          </Box>
        )}
        <Box
          sx={
            !isMobile
              ? {
                  paddingRight: "150px",
                  marginBottom: "50px",
                  ...(showLoginWithSocialMediaScreen && { marginTop: "85px" }),
                }
              : {}
          }
        >
          {isMobile && (
            <Box className="welcome-box logo-signup">{whiteLogoImage}</Box>
          )}
          <Grid
            item
            elevation={4}
            className={!isMobile ? "signup-form" : "signup-mobile-form"}
            padding={isMobile ? "0px 37px" : ""}
          >
            <form
              id="speed-signup-form"
              noValidate
              onSubmit={formik.handleSubmit}
            >
              {(fromPartnerDataAvailable || noDataFromPartner) && (
                <Box
                  bgcolor={
                    hasDifferentEmailAndNoPartnerData ? "#F8F3DF" : "#F7FAFC"
                  }
                  border={
                    (!noDataFromPartner ||
                      (values?.email === affiliatePartnerData()?.email &&
                        !isDefaultAffiliate)) &&
                    "1px solid #E4E9EE"
                  }
                  borderRadius="5px"
                  py={2}
                  px={hasDifferentEmailAndNoPartnerData ? 1 : 3}
                  mb="30px"
                  display="flex"
                  alignItems="center"
                  gap={1}
                >
                  {hasDifferentEmailAndNoPartnerData && (
                    <WarningIcon
                      sx={{ color: "#F1C40F", height: 18, width: 18 }}
                    />
                  )}
                  <Text
                    className="grey-text"
                    font="regular"
                    size={14}
                    variant="body2"
                  >
                    {noDataFromPartner
                      ? loginAffiliateNotFoundMessage
                      : checkIfSameEmailIsDifferent
                      ? inviteInvalidForEmailMsg
                      : affiliateInvitationMessage(affiliatePartnerData())}
                  </Text>
                </Box>
              )}
              {signupWithEmailPassword && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#2a67ff",
                    margin: "10px 0px",
                    width: "fit-content",
                  }}
                  onClick={() => setSignupWithEmailPassword(false)}
                  className="pointer-cursor"
                >
                  <KeyboardBackspaceIcon htmlColor="#2a67ff" />
                  <Text sx={{ marginLeft: "10px" }} size={16} variant="body1">
                    {backButtonTxt}
                  </Text>
                </Box>
              )}
              <Text size={isMobile ? 22 : 30} font="bold">
                {welcomeMsg}
              </Text>
              <Box sx={{ padding: "10px 0 24px 0" }}>
                <Text
                  size={18}
                  font="regular"
                  className="default-text text margin-top10 lineHeight"
                  variant="inline"
                >
                  {signUpDescription()}
                </Text>
                <img
                  src={limitedOfferImage}
                  alt="limitedOffer"
                  className="sign-up-offer-img"
                />
              </Box>
              {invited && (
                <Paper className="sign-up-invite-box" elevation={0}>
                  <Text
                    font="regular"
                    size={14}
                    variant="caption"
                    className="grey-text"
                    sx={{ wordBreak: "break-word" }}
                  >
                    {invite(invitedByEmail, accountName)}
                  </Text>
                </Paper>
              )}
              {(!showLoginWithSocialMediaScreen || signupWithEmailPassword) &&
                (inviteRevoke || redeemInvite || inviteInvalidForEmail) && (
                  <AlertMessage
                    message={
                      (inviteRevoke && inviteRevokeMsg) ||
                      (redeemInvite && inviteRedeem) ||
                      (inviteInvalidForEmail && inviteInvalidForEmailMsg)
                    }
                    severity="warning"
                    className="margin-top14"
                  />
                )}
              {/* <Button
                    variant="default"
                    fullWidth
                    label={googleSignup}
                    onClick={() => console.log(googleSignup)}
                    icon='google'
                />
                <CustomDivider text="OR" className="custom-divider" /> */}
              {showLoginWithSocialMediaScreen ? (
                <LoginWithSocialMedia
                  ldFlagForGoogle={true}
                  ldFlagForApple={true}
                  // ldFlagForGoogle={ssoGoogleauthFe}
                  // ldFlagForApple={ssoAppleauthFe}
                  inviteeAuthState={socialMediaAuthInviteState}
                  forSignUp
                  setSignupWithEmailPassword={setSignupWithEmailPassword}
                />
              ) : (
                <>
                  <Input
                    customClass="margin-top15"
                    showLabel
                    label={firstNameLabel}
                    type="text"
                    value={values.first_name}
                    fullWidth
                    inputProps={{ maxLength: 250 }}
                    name="first_name"
                    placeholder={firstNamePlaceholder}
                    error={touched.first_name && Boolean(errors.first_name)}
                    onBlur={() => setTouched({ ...touched, first_name: true })}
                    onChange={(e) => {
                      setTouched({ ...touched, first_name: false });
                      setFieldValue("first_name", e.target.value);
                    }}
                  />
                  <InputErrorMessage
                    {...defaultErrorMessageProps}
                    inputName="first_name"
                  />
                  <Input
                    customClass="margin-top30"
                    showLabel
                    label={lastNameLabel}
                    type="text"
                    value={values.last_name}
                    fullWidth
                    name="last_name"
                    inputProps={{ maxLength: 250 }}
                    error={touched.last_name && Boolean(errors.last_name)}
                    placeholder={lastNamePlaceholder}
                    onBlur={() => setTouched({ ...touched, last_name: true })}
                    onChange={(e) => {
                      setTouched({ ...touched, last_name: false });
                      setFieldValue("last_name", e.target.value);
                    }}
                  />
                  <InputErrorMessage
                    {...defaultErrorMessageProps}
                    inputName="last_name"
                  />
                  <Input
                    customClass="margin-top30"
                    type="email"
                    showLabel
                    label={emailLabel}
                    onBlur={() => setTouched({ ...touched, email: true })}
                    onChange={(e) => {
                      setTouched({ ...touched, email: false });
                      setFieldValue("email", e.target.value);
                    }}
                    value={values.email}
                    name="email"
                    error={touched.email && Boolean(errors.email)}
                    fullWidth
                    placeholder={emailPlaceholder}
                  />
                  <InputErrorMessage
                    {...defaultErrorMessageProps}
                    inputName="email"
                  />
                  <CustomAutoComplete
                    allowedAutoComplete={true}
                    name="country"
                    options={Countries}
                    getOptionLabel={(value) => {
                      let country = getCountryObj(
                        "short_name",
                        typeof value === "object" ? value.short_name : value
                      );

                      if (country) return country.short_name;
                    }}
                    onChange={(_e, value) =>
                      setFieldValue(
                        "country",
                        value && typeof value === "object"
                          ? value.short_name
                          : value
                      )
                    }
                    value={values.country}
                    customClass="margin-top30"
                    placeholder={countryPlaceholder}
                    showLabel
                    label={countryLabel}
                  />
                  <Input
                    customClass="margin-top30"
                    type="password"
                    isPasswordVisible={true}
                    showLabel
                    label={passwordLabel}
                    value={values.password}
                    name="password"
                    error={touched.password && Boolean(errors.password)}
                    onBlur={() => setTouched({ ...touched, password: true })}
                    onChange={(e) => {
                      setTouched({ ...touched, password: true });
                      setFieldValue("password", e.target.value);
                    }}
                    fullWidth
                    inputProps={{ maxLength: 64 }}
                    placeholder={passwordPlaceholder}
                    isIcon={true}
                    iconContent={
                      <PasswordPolicy from="signup" isMobile={isMobile} />
                    }
                    icon={
                      <InfoIcon
                        style={{
                          height: 14,
                          width: 14,
                          color: "#848b9e",
                          marginLeft: "7px",
                        }}
                      />
                    }
                  />
                  <InputErrorMessage
                    {...defaultErrorMessageProps}
                    inputName="password"
                  />
                  <Box display="flex" alignItems="center">
                    <Box className="checkbox">
                      <CustomCheckbox
                        onChange={(_e, checked) =>
                          setFieldValue("termsAndPrivacy", checked)
                        }
                        checked={values.termsAndPrivacy}
                        name="termsAndPrivacy"
                        label=""
                      />
                    </Box>
                    <Box sx={{ marginTop: "25px" }}>
                      <Text
                        className="privacy-policy-text grey-text"
                        font="regular"
                        size={14}
                        variant="caption"
                      >
                        {agreeMsg[0]}
                        <CustomLink
                          size="small"
                          href={process.env.REACT_APP_PROMO_WEBSITE + "terms"}
                          target="_blank"
                        >
                          {termsAndPrivacyMsg[0]}
                        </CustomLink>
                        {agreeMsg[1]}
                        <CustomLink
                          size="small"
                          target="_blank"
                          href={process.env.REACT_APP_PROMO_WEBSITE + "privacy"}
                        >
                          {termsAndPrivacyMsg[1]}
                        </CustomLink>
                      </Text>
                    </Box>
                  </Box>

                  <Button
                    type="submit"
                    className="submit-signin-btn margin-top30"
                    color="primary"
                    fullWidth
                    label={registerLabel}
                    iconPosition="end"
                    icon="rightArrowIcon"
                    variant="contained"
                    disabled={
                      !(isValid && dirty) || redeemInvite || noDataFromPartner
                    }
                  />
                </>
              )}
              <Text
                size={16}
                font="semibold"
                align="center"
                className="default-text margin-top30"
              >
                {haveAccountMsg}
                <CustomLink
                  size="large"
                  bold
                  className="margin-left10"
                  style={{ whiteSpace: "nowrap" }}
                  onClick={() => history.push("/login")}
                >
                  {signIn}
                </CustomLink>
              </Text>
            </form>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

export default SignUp;
