import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { RouteBreadcrumbs, callAPIInterface } from "../constants";
import { useSelector } from "react-redux";
import { emptySubText } from "@speed/common/src/components/messages";
import { emptyTransfers } from "@speed/common/src/components/images";
import { Link } from "react-router-dom";
import { Box, TableCell, TableRow } from "@mui/material";
import {
  fetchPrecisedInteger,
  generateBreadcrumbs,
  getCurrencyObj,
} from "@speed/common/src/components/constants";
import CustomTable from "@speed/common/src/components/Table/Table";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";

const PaymentDetailTransfers = (props) => {
  const urlParams = useParams();
  const { pyTransferFe } = useFlags();
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const [tableRowSkeleton, setTableRowSkeleton] = useState(true);
  const [rows, setRows] = useState([]);

  const history = useSelector((state) => state.common.history);
  const { liveMode, currentAccount } = useSelector((state) => state.auth);

  const getPaymentTransfers = () => {
    setTableRowSkeleton(true);
    callAPIInterface("GET", `/payments/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          res?.transfers?.length > 0 && setRows(res.transfers);
        }
      })
      .catch(() => {
        setTableRowSkeleton(false);
      });
  };

  useEffect(() => {
    if (pyTransferFe) {
      setRows([]);
      getPaymentTransfers();
    }
  }, [liveMode, pyTransferFe]);

  const columns = [
    { title: "Transfer ID", width: "500px", paddingLeft: "38px !important" },
    { title: "From Account", width: "200px" },
    { title: "To Account", width: "200px" },
    { title: "Transfer Amount", align: "right", width: "250px" },
  ];

  let columnsData = columns?.map((column) => (
    <TableCell
      key={column.title}
      sx={{ paddingLeft: column.paddingLeft, minWidth: column.width }}
      align={column?.align}
    >
      {column.title}
    </TableCell>
  ));

  const rowsData = rows?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem?.transfer_id}
        className="clickable"
        onClick={() => history.push(`/transfers/${rowItem?.transfer_id}`)}
      >
        <TableCell sx={{ paddingLeft: "38px !important" }}>
          {rowItem?.transfer_id}
        </TableCell>
        <TableCell>{currentAccount?.account?.id}</TableCell>
        <TableCell>{rowItem?.destination_account}</TableCell>
        <TableCell align="right">
          {getCurrencyObj("SATS")?.symbol}
          {fetchPrecisedInteger(rowItem?.amount, 8)}
        </TableCell>
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    className: "link-table",
    textOnNoData: emptySubText(liveMode, "transfers"),
    noDataImage: emptyTransfers,
  };
  return (
    <Box className="section-wrapper">
      <Box className="payment-link-header-wrapper">
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      </Box>
      <CustomDivider />
      <Box className="payments-wrapper">
        <Box className="section-wrapper">
          <Box className="payment-wrapper">
            <Box className="main-content">
              {pyTransferFe ? (
                <CustomTable {...tableProps} />
              ) : (
                <AccessDenied />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentDetailTransfers;
