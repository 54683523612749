import React, { useContext, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import {
  checkLinkOptionsAvailable,
  getFormattedWalletsData,
  getPreviewTotalAmount,
  satsToBtcAmount,
} from "../constants";
import { wholeQrSkeleton } from "../CommonSkeletonComponent";
import LinkConfirmView from "../LinkConfirmView";
import QRCodePreview from "./QRCodePreview";
import { PaymentPageContext, WebPreviewContext } from "../../contexts/contexts";
import PayViaOptions from "../PayViaOptions";
import RightSideDetailsSection from "./RightSideDetailsSection";

const RightSection = (props) => {
  const {
    paymentDetails,
    paymentInfo,
    paymentMessage,
    confirmPayment,
    totalAmount,
    checkedBillingAddress,
    checkedEmailAddress,
    checkedPhoneAddress,
    checkedShippingAddress,
  } = props;
  const [showEditedContactInfo, setShowEditedContactInfo] = useState(false);
  const [dynamicWalletData, setDynamicWalletData] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [activeSection, setActiveSection] = useState("1");

  // Get context value using consumer
  const paymentPageData = useContext(PaymentPageContext);
  const WebPreviewProps = useContext(WebPreviewContext);

  useEffect(() => {
    if (WebPreviewProps?.visibleInPaymentPage) getWalletsDataFromStrapi();
  }, [WebPreviewProps?.paymentPageSettings]);

  useEffect(() => {
    setSubmitClicked(false);
    if (submitClicked && paymentInfo?.custom_fields?.length > 0) {
      const isAllOptional = paymentInfo.custom_fields.map(
        (item) => item.is_optional
      );
      setSubmitClicked(isAllOptional?.includes(true));
    }
  }, [
    checkedBillingAddress,
    checkedEmailAddress,
    checkedPhoneAddress,
    checkedShippingAddress,
    paymentInfo?.custom_fields,
  ]);

  // Helper function: Responsible for show/hide details and form in the preview of Right side section
  const {
    contactFormExist,
    customFieldExist,
    isDetailsSectionVisible,
    visibleDetailForm,
    isContactInfoFormVisible,
    isCustomFieldVisible,
    showCustomerCollections,
    checkedLengthKeys,
  } = checkLinkOptionsAvailable({
    paymentInfo,
    checkedBillingAddress,
    checkedEmailAddress,
    checkedPhoneAddress,
    checkedShippingAddress,
    paymentPageData,
    showEditedContactInfo,
    submitClicked,
  });

  const getWalletsDataFromStrapi = async () => {
    const walletNames = WebPreviewProps?.paymentPageSettings?.wallets;
    const formattedWalletsObj = await getFormattedWalletsData(walletNames);
    setDynamicWalletData(formattedWalletsObj);
  };

  useEffect(() => {
    if (
      !paymentInfo?.custom_fields &&
      checkedLengthKeys.every((item) => item === false)
    ) {
      setShowEditedContactInfo(false);
    }
  }, [paymentInfo?.custom_fields, checkedLengthKeys]);

  const showMainContent = () => {
    return !visibleDetailForm ? (
      <Box>
        <QRCodePreview
          {...props}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          amount={getPreviewTotalAmount(paymentInfo, true, true)}
          visibleInPaymentPage={WebPreviewProps?.visibleInPaymentPage}
          boxWidth={WebPreviewProps?.commonWidth}
          onChainAvailable={WebPreviewProps?.onChainAvailable}
          lightningLabel={WebPreviewProps?.lightningLabel}
          qrActionBtnWidth={WebPreviewProps?.qrActionBtnWidth}
          dummyLightningAddress={WebPreviewProps?.dummyLightningAddress}
          dummyOnchainAddress={WebPreviewProps?.dummyOnchainAddress}
          lightningPaymentAddress={WebPreviewProps?.lightningPaymentAddress}
          onChainPaymentAddress={WebPreviewProps?.onChainPaymentAddress}
          confirmPageProps={WebPreviewProps?.confirmPageProps}
          isFromInvoice={WebPreviewProps?.isFromInvoice}
          boxAmountValue={
            (paymentDetails &&
              `${satsToBtcAmount(paymentDetails.target_amount_paid)} BTC`) ||
            totalAmount
          }
          isDetailsSectionVisible={isDetailsSectionVisible}
          showCustomerCollections={showCustomerCollections}
          setShowEditedContactInfo={setShowEditedContactInfo}
        />
      </Box>
    ) : (
      <RightSideDetailsSection
        {...props}
        showEditedContactInfo={showEditedContactInfo}
        setShowEditedContactInfo={setShowEditedContactInfo}
        showCustomerCollections={showCustomerCollections}
        isContactInfoFormVisible={isContactInfoFormVisible}
        isCustomFieldVisible={isCustomFieldVisible}
        isWebPreview={true}
        updatePaymentInfoFn={WebPreviewProps?.updatePaymentInfoFn}
        visibleInPaymentPage={WebPreviewProps?.visibleInPaymentPage}
        checkoutSessionId={WebPreviewProps?.checkoutSessionId}
        setSubmitClicked={setSubmitClicked}
        contactFormExist={contactFormExist}
        customFieldExist={customFieldExist}
        setCustomFiledUpdatedFrom={WebPreviewProps?.setCustomFiledUpdatedFrom}
      />
    );
  };

  const skeletonLoader = () => {
    return WebPreviewProps?.visibleInPaymentPage &&
      paymentPageData?.qrCodeLoader
      ? // Show loader while payment page API is calling
        wholeQrSkeleton({ size: "398px" })
      : showMainContent();
  };

  return (
    <Box
      component="div"
      padding={WebPreviewProps?.rightBoxWidth}
      className="right-side-section"
    >
      <Box className="right-container-box" width={WebPreviewProps?.commonWidth}>
        {confirmPayment ? (
          <Grid className="confirm-payment-wrapper" item lg={12}>
            <LinkConfirmView
              activeSection={activeSection}
              visibleInPaymentPage={WebPreviewProps?.visibleInPaymentPage}
              paymentMessage={paymentMessage}
              paymentDetails={paymentDetails}
              paymentInfo={paymentInfo}
              additionalClass="latest-confirm-preview"
              confirmPageProps={WebPreviewProps?.confirmPageProps}
              isFromInvoice={WebPreviewProps?.isFromInvoice}
              boxAmountValue={
                (paymentDetails &&
                  `${satsToBtcAmount(
                    paymentDetails.target_amount_paid
                  )} BTC`) ||
                totalAmount
              }
            />
          </Grid>
        ) : (
          skeletonLoader()
        )}
      </Box>
      <Box
        marginTop="auto"
        width={WebPreviewProps?.commonWidth}
        className="bottom-footer"
      >
        <PayViaOptions
          from=""
          className="pay-via-options-payment"
          paymentPageSettings={WebPreviewProps?.paymentPageSettings}
          dynamicWalletData={dynamicWalletData}
        />
      </Box>
    </Box>
  );
};

export default RightSection;
