import React, { useState } from "react";
import { Box, MenuItem, Skeleton } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import {
  receive,
  send,
  sats,
  usdt,
  newToSpeedWallet,
  getStartedWithSpeedWallet,
} from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import { getNumberFormatAccordingToCurrency } from "@speed/common/src/components/constants";
import CustomSelect from "@speed/common/src/components/Select/Select";
import { BTC } from "@speed/common/src/components/messages";
import ReceiveModal from "./ReceivePayment/ReceiveModal";
import SendModal from "./SendPayment/SendModal";
import {
  setPaymentProcessingRecallApis,
  setShowBTCAmount,
} from "../../redux/common/actions";
import { useDispatch, useSelector } from "react-redux";
import { walletBtcNoTransaction } from "../images";
import { sessionService } from "redux-react-session";

const AssetsAmount = ({
  isAssetsLoading,
  selectedCurrencyType,
  targetAmountLoading,
  setCurrency,
  currencySupported,
  fiatCurrency,
  exchangeRateAmount,
  getAmount,
  currency,
  accountAssets,
  isNoTransactionPerformedInSats,
  setCurrentSession,
  currentSession,
  getBtcAmount,
}) => {
  const dispatch = useDispatch();
  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);
  const [openReceiveModal, setOpenReceiveModal] = useState(false);
  const [openSendModal, setOpenSendModal] = useState(false);

  const currencyDropdown = () => {
    return selectedCurrencyType?.uuid === sats ? (
      <Box width="max-content">
        <CustomSelect
          showLabel={false}
          customClass="transaction-custom-currency-dropdown assets-currency-dropdown"
          value={currency}
          MenuProps={{
            id: "payout-wallet-address",
            disableScrollLock: true,
          }}
          onChange={handleChangeCurrency}
          renderValue={() => currency}
        >
          {currencySupported?.map((dropDownValue) => (
            <MenuItem value={dropDownValue} key={dropDownValue}>
              <Box>{dropDownValue}</Box>
            </MenuItem>
          ))}
        </CustomSelect>
      </Box>
    ) : (
      <Text size={20} variant="h6" font="semibold" sx={{ color: "#2A67FF" }}>
        {currency}
      </Text>
    );
  };

  const showAssetAmount = () => {
    return selectedCurrencyType?.uuid === sats && currency === BTC
      ? getBtcAmount(selectedCurrencyType?.uuid)
      : getAmount(selectedCurrencyType?.uuid);
  };

  const handleChangeSatsToBtc = async () => {
    dispatch(setShowBTCAmount(!showBtcAmount));
    const sessionToUpdate = {
      ...currentSession,
      showBtcAmount: !showBtcAmount,
    };
    currentSession && (await sessionService.saveSession(sessionToUpdate));
    setCurrentSession(sessionToUpdate);
  };

  const handleChangeCurrency = (e) => {
    const result =
      currencySupported?.find((currency) => currency === e.target.value) || "";
    setCurrency(result);
    handleChangeSatsToBtc();
  };

  let sendReceiveClassName = null;
  let amountSectionClassName = null;
  if (selectedCurrencyType) {
    sendReceiveClassName = currency === usdt ? "btn-usdt" : "btn-btc";
    amountSectionClassName =
      currency === usdt
        ? "assets-amount-section-usdt"
        : "assets-amount-section-btc";
  }

  const sendReceiveBtnJsx = (applyMargin) => {
    return (
      <Box
        display="flex"
        m="10px 40px 40px 40px"
        sx={{
          maxWidth: "600px",
          pl: applyMargin && "20px",
          pr: applyMargin && "20px",
        }}
      >
        <Button
          icon="expandCircleIcon"
          className={`receive-btn receive-${sendReceiveClassName}`}
          label={receive}
          variant="outlined"
          color="primary"
          onClick={() => {
            dispatch(setPaymentProcessingRecallApis(false));
            setOpenReceiveModal(true);
          }}
          disabled={!selectedCurrencyType || targetAmountLoading}
        />
        <Button
          icon="expandCircleIcon"
          className={`send-btn send-${sendReceiveClassName}`}
          variant="contained"
          label={send}
          onClick={() => {
            dispatch(setPaymentProcessingRecallApis(false));
            setOpenSendModal(true);
          }}
          disabled={!selectedCurrencyType || targetAmountLoading}
        />
      </Box>
    );
  };

  return (
    <Box className={`${amountSectionClassName} assets-amount-section`}>
      {isNoTransactionPerformedInSats ? (
        <>
          <Box
            pt="44px"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <img src={walletBtcNoTransaction} alt="Welcome-to-speed-img" />
            <Text
              font="semibold"
              size={22}
              className="default-text"
              sx={{ mt: "24px" }}
            >
              {newToSpeedWallet}
            </Text>
            <Text font="regular" variant="h6" size={16} className="grey-text">
              {getStartedWithSpeedWallet(currency)}
            </Text>
          </Box>
          <Box
            mt="24px"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            {sendReceiveBtnJsx(true)}
          </Box>
        </>
      ) : (
        <>
          <Box display="flex" alignItems="center" p="20px">
            {isAssetsLoading ? (
              <Skeleton
                animation="wave"
                variant="circular"
                className="assets-amount-currency-icon"
                sx={{
                  bgcolor: "#EAEEF1 !important",
                }}
              />
            ) : (
              <img
                src={selectedCurrencyType?.icon}
                className="assets-amount-currency-icon"
                alt="currency-btc"
              />
            )}
            <Box display="flex" flexDirection="column">
              {isAssetsLoading ? (
                <Skeleton animation="wave" width={80} height={30} />
              ) : (
                currencyDropdown()
              )}

              <Text font="semibold" size={36}>
                {isAssetsLoading ? (
                  <Skeleton animation="wave" width={180} height={54} />
                ) : (
                  showAssetAmount()
                )}
              </Text>

              <Text
                font="regular"
                variant="subtitle1"
                size={24}
                className="grey-text"
              >
                {isAssetsLoading || targetAmountLoading ? (
                  <Skeleton animation="wave" height={36} />
                ) : (
                  <>
                    {fiatCurrency?.symbol}
                    {getNumberFormatAccordingToCurrency(
                      exchangeRateAmount?.toFixed(2),
                      fiatCurrency?.code
                    )}
                  </>
                )}
              </Text>
            </Box>
          </Box>
          {sendReceiveBtnJsx(false)}
        </>
      )}
      <SendModal
        setOpenSendModal={setOpenSendModal}
        openSendModal={openSendModal}
        selectedCurrencyType={selectedCurrencyType}
      />
      <ReceiveModal
        setOpenReceiveModal={setOpenReceiveModal}
        openReceiveModal={openReceiveModal}
        selectedCurrencyType={selectedCurrencyType}
        accountAssets={accountAssets}
      />
    </Box>
  );
};

export default AssetsAmount;
