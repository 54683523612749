import React, { useEffect, useState, useRef } from "react";
import { Box, Grid } from "@mui/material";
import { onChainLabel } from "../messages";
import Text from "../Text/Text";
import {
  lightningPaymentPageLogo,
  lightningLogoWithColor,
  onChainBtc,
  onChainBtcWithColor,
} from "../images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  dummyEthereumAddress,
  dummyLightningUSDTAddress,
  getOpenWalletURL,
  reportIssueTextComponent,
} from "../constants";
import PaymentLinkQRCode from "../PaymentLinkQRCode";
import LinkConfirmView from "../LinkConfirmView";
import {
  issueWalletLabel,
  paymentMethod,
  reasonLabel,
  reasonPlaceholder,
  reportIssue,
  submitIssueMsg,
} from "../../messages";
import PreviewDetailsSection from "./PreviewDetailsSection";
import CommonReportFeedbackModal from "../CommonReportFeedbackModal";
import AccountAssets from "./AccountAssets";
import AssetPaymentMethods from "./AssetPaymentMethods";
import { qrAndBottomLoader } from "../CommonSkeletonComponent";
import { isEmpty } from "lodash";
const QRCodePreview = (props) => {
  const {
    paymentDetails,
    paymentInfo,
    showConfirmPreview,
    showQRPaymentPreview,
    fontStyle,
    fadeOut,
    amount,
    isMultiPaymentMethods,
    onchainPaymentMethod,
    confirmPayment,
    totalAmount,
    paymentMessage,
    onChainAvailable,
    lightningLabel,
    qrActionBtnWidth,
    lightningPaymentAddress,
    onChainPaymentAddress,
    dummyLightningAddress,
    dummyOnchainAddress,
    confirmPageProps,
    boxAmountValue,
    isFromInvoice,
    visibleInPaymentPage,
    isDetailsSectionVisible,
    showCustomerCollections,
    setShowEditedContactInfo,
    activeSection,
    setActiveSection,
    accountAsset,
    paymentDetailsObj,
    selectedAssetCurrency = "SATS",
    selectedPaymentMethod,
    assetQrLoader,
    setSelectedPaymentMethod,
    webViewProps,
    mobileViewProps,
  } = props;

  const [mainBoxClass, setMainBoxClass] = useState("");
  const [lightningObj, setLightningObj] = useState({
    textColor: "default-text",
    logo: lightningLogoWithColor,
    borderColor: "#2A67FF",
    bgColor: "#F7FAFC",
  });
  const [onchainObj, setOnchainObj] = useState({
    textColor: "grey-text",
    logo: onChainBtc,
    borderColor: "#D8DCE1",
    bgColor: "#fff",
  });
  const [innerBoxStyle, setInnerBoxStyle] = useState({});
  const [childBoxStyle, setChildBoxStyle] = useState({});
  const [reportIssueModal, setReportIssueModal] = useState(false);
  const [qrCodeArray, setQrCodeArray] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const excludeKeys = ["target_amount", "exchange_rate"];

  const sliderRef = useRef();

  const nameOfSelectedLink =
    webViewProps?.selectedMenuFromPaymentPageSetting ||
    mobileViewProps?.selectedMenuFromPaymentPageSetting;

  useEffect(() => {
    if (fadeOut) {
      setInnerBoxStyle({ opacity: 0, transition: "opacity 1s ease" });
    } else {
      !showConfirmPreview && setInnerBoxStyle({ opacity: 1 });
    }
  }, [fadeOut]);

  useEffect(() => {
    if (showQRPaymentPreview) {
      setMainBoxClass("transition-on");
      setInnerBoxStyle({
        opacity: 0,
        transition: "opacity 2s ease",
      });
      setChildBoxStyle({
        display: "none",
      });
    } else {
      setMainBoxClass("");
      setInnerBoxStyle({ opacity: 1, transition: "opacity 2s ease" });
      setTimeout(() => {
        setInnerBoxStyle({
          opacity: 1,
          transition: "opacity 2s ease",
        });
      }, 500);
      setChildBoxStyle({});
    }
  }, [showQRPaymentPreview]);

  useEffect(() => {
    if (!isEmpty(paymentDetailsObj)) {
      let copyQrCodeArray = [];
      //created array of methods to render QR code for selected currency
      Object.entries(paymentDetailsObj?.[selectedAssetCurrency]).map(
        ([key, val]) => {
          if (
            !excludeKeys.includes(key) &&
            paymentDetailsObj?.[selectedAssetCurrency]?.[key]
          ) {
            copyQrCodeArray.push({
              tab: copyQrCodeArray.length + 1,
              currency: selectedAssetCurrency,
              method: key,
              address: paymentDetailsObj?.[selectedAssetCurrency]?.[key],
            });
          }
        }
      );

      setQrCodeArray(copyQrCodeArray);
    }
  }, [paymentDetailsObj, selectedAssetCurrency]);

  const handleGotoSelectedTab = (id) => {
    sliderRef.current?.slickGoTo(id === 1 ? 0 : id - 1);
    setSelectedTab(id);
  };

  useEffect(() => {
    const resSelectedTab = qrCodeArray?.find(
      (qr) => qr.method === selectedPaymentMethod
    )?.tab;
    handleGotoSelectedTab(resSelectedTab);
  }, [selectedPaymentMethod, qrCodeArray]);

  const paymentData = paymentInfo?.payment;

  const isPaymentOptionAvailable =
    isMultiPaymentMethods && paymentData?.payment_methods?.length > 1;

  const lightningPaymentData = !isMultiPaymentMethods
    ? paymentData
    : lightningPaymentAddress;

  const onChainPaymentData = !isMultiPaymentMethods
    ? onchainPaymentMethod === "yes" && paymentData
    : onChainPaymentAddress;

  let onChainRequestWithAmount;
  if (onChainPaymentData) {
    onChainRequestWithAmount = getOpenWalletURL({
      walletType: "bitcoin",
      walletAddress: onChainPaymentAddress || dummyOnchainAddress,
      amountToPay: amount,
    });
  }

  const getCurrentSelectedTab = (currentSection) => {
    if (currentSection === "1") {
      setLightningObj({
        textColor: "default-text",
        logo: lightningLogoWithColor,
        borderColor: "#2A67FF",
        bgColor: "#F7FAFC",
      });
      setOnchainObj({
        textColor: "grey-text",
        logo: onChainBtc,
        borderColor: "#D8DCE1",
        bgColor: "#fff",
      });
    } else {
      setLightningObj({
        textColor: "grey-text",
        logo: lightningPaymentPageLogo,
        borderColor: "#D8DCE1",
        bgColor: "#fff",
      });
      setOnchainObj({
        textColor: "default-text",
        logo: onChainBtcWithColor,
        borderColor: "#2A67FF",
        bgColor: "#F7FAFC",
      });
    }
  };

  useEffect(() => {
    if (lightningPaymentAddress || !isMultiPaymentMethods) {
      setActiveSection("1");
      getCurrentSelectedTab("1");
    } else {
      setActiveSection("2");
      getCurrentSelectedTab("2");
    }
  }, [lightningPaymentData]);

  const handleSection = (currentSection) => {
    sliderRef.current?.slickGoTo(currentSection === "1" ? 0 : 1);
    setActiveSection(currentSection);
    getCurrentSelectedTab(currentSection);
  };

  const tabContentText = (textColorClass, logo, label) => {
    return (
      <>
        {/* <img
          src={logo}
          style={{
            height: "20px",
            width: "20px",
            marginRight: "10px",
          }}
        /> */}
        <Text
          size={16}
          font="medium"
          variant="inline"
          className={textColorClass}
          // sx={{ cursor: "pointer", mt: "6px" }}
          sx={{ cursor: "pointer" }}
          withIcon="start"
        >
          {label}
        </Text>
      </>
    );
  };

  const walletURLData =
    activeSection === "1"
      ? {
          walletType: "lightning",
          walletAddress: lightningPaymentAddress,
        }
      : {
          walletType: "bitcoin",
          walletAddress: onChainPaymentAddress,
          amountToPay: amount,
          paymentDescription: paymentData?.statement_descriptor,
        };

  const customStyle = !isPaymentOptionAvailable
    ? {
        width: "100% !important",
        justifyContent: "center !important",
      }
    : {};

  const carouselProps = {
    dots: false,
    touchMove: true,
    infinite: false,
    arrows: false,
    onSwipe: (value) => {
      handleSetSelectedTab(value);
      setActiveSection(value === "left" ? "2" : "1");
      getCurrentSelectedTab(value === "left" ? "2" : "1");
    },
  };

  const handleSetSelectedTab = (value) => {
    //handle selected tab and method on swipe
    let tab;
    if (value === "left") {
      tab = qrCodeArray.length > selectedTab ? selectedTab + 1 : selectedTab;
    } else {
      tab = selectedTab > 1 ? selectedTab - 1 : selectedTab;
    }
    const res = qrCodeArray?.find((qr) => qr.tab === tab)?.method;
    setSelectedTab(tab);
    setSelectedPaymentMethod(res);
  };

  const displayQRCode = () => {
    let type, paymentAddressValue, paymentRequestWithAmount;

    //address selection for new design
    switch (selectedPaymentMethod) {
      case "lightning":
        if (selectedAssetCurrency === "SATS") {
          type = "btcLightning";
          paymentAddressValue =
            lightningPaymentAddress || dummyLightningAddress;
        } else {
          type = "usdtLightning";
          paymentAddressValue =
            paymentDetailsObj?.[selectedAssetCurrency]?.[
              selectedPaymentMethod
            ] || dummyLightningUSDTAddress;
        }
        break;
      case "onchain":
        type = "btcOnChain";
        paymentAddressValue = onChainPaymentAddress || dummyOnchainAddress;
        paymentRequestWithAmount = onChainRequestWithAmount;
        break;
      case "ethereum":
        type = "ethereum";
        paymentAddressValue =
          paymentDetailsObj?.[selectedAssetCurrency]?.[selectedPaymentMethod] ||
          dummyEthereumAddress;
        break;
      default:
        //address selection for old design
        if (activeSection === "1") {
          type = "btcLightning";
          paymentAddressValue =
            lightningPaymentAddress || dummyLightningAddress;
        } else {
          type = "btcOnChain";
          paymentAddressValue = onChainPaymentAddress || dummyOnchainAddress;
          paymentRequestWithAmount = onChainRequestWithAmount;
        }
    }
    return (
      <PaymentLinkQRCode
        paymentRequestWithAmount={paymentRequestWithAmount}
        paymentLinkMethodTitle={type}
        fontStyle={fontStyle}
        isMobile={props.isMobile}
        openToWalletUrl={getOpenWalletURL(walletURLData)}
        qrActionBtnWidth={qrActionBtnWidth}
        paymentAddress={paymentAddressValue}
        visibleInPaymentPage={visibleInPaymentPage}
      />
    );
  };

  const linkConfirmViewProps = confirmPayment
    ? {
        paymentMessage: paymentMessage,
        amount: totalAmount,
        paymentInfo: paymentInfo,
      }
    : {
        isPartialPayment: true,
        showConfirmPreview: showConfirmPreview,
        showQRPaymentPreview: showQRPaymentPreview,
        paymentInfo: paymentInfo,
        isFromInvoice: isFromInvoice,
      };

  const tabSection = (styleObj, label, customStyle, activeIndex) => {
    return (
      <Box
        sx={{
          borderColor: `${styleObj?.borderColor} !important`,
          backgroundColor: styleObj?.bgColor,
          marginLeft: label === onChainLabel && "10px",
          ...customStyle,
        }}
        className="tab-section-preview"
        onClick={() => {
          handleSection(activeIndex);
        }}
      >
        {tabContentText(styleObj?.textColor, styleObj?.logo, label)}
      </Box>
    );
  };

  const isOnchainAvailable =
    nameOfSelectedLink !== "Withdrawal link" &&
    ((!isMultiPaymentMethods &&
      onchainPaymentMethod === "yes" &&
      onChainAvailable) ||
      onChainPaymentAddress);

  const handleMultiAssetQrRender = () => {
    return !isMultiPaymentMethods
      ? displayQRCode()
      : qrCodeArray?.map((qr) => {
          return qr.address && displayQRCode();
        });
  };

  return (
    <Box className="payment-page-qr-section">
      {showConfirmPreview || confirmPayment ? (
        <Grid className="confirm-payment-wrapper" item lg={12}>
          <LinkConfirmView
            visibleInPaymentPage={visibleInPaymentPage}
            {...linkConfirmViewProps}
            paymentDetails={paymentDetails}
            additionalClass="latest-confirm-preview"
            confirmPageProps={confirmPageProps}
            boxAmountValue={boxAmountValue}
            selectedPaymentMethod={selectedPaymentMethod}
          />
        </Grid>
      ) : (
        <>
          <Box
            className={`tab-qr-wrapper ${mainBoxClass}`}
            sx={innerBoxStyle}
            width={props.boxWidth}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb="10px"
            >
              {
                <Text
                  size={16}
                  font="medium"
                  variant="subtitle1"
                  className="default-text payment-method-text"
                >
                  {!accountAsset && paymentMethod}
                </Text>
              }
              {reportIssueTextComponent({
                setReportIssueModal,
                shouldModalOpen: visibleInPaymentPage,
              })}
            </Box>

            <Box sx={childBoxStyle}>
              {accountAsset ? (
                <>
                  <AccountAssets
                    {...props}
                    accountAsset={accountAsset}
                    payment={paymentInfo?.payment?.payment}
                  />
                  <AssetPaymentMethods
                    {...props}
                    payment={paymentInfo?.payment?.payment}
                  />
                  <Box className="qr-preview-section">
                    <Slider {...carouselProps} ref={sliderRef}>
                      {assetQrLoader
                        ? qrAndBottomLoader({ size: "385px" })
                        : handleMultiAssetQrRender()}
                    </Slider>
                  </Box>
                </>
              ) : (
                <>
                  <Box display="flex">
                    {(!isMultiPaymentMethods || lightningPaymentAddress) &&
                      tabSection(
                        lightningObj,
                        lightningLabel,
                        (onchainPaymentMethod === "yes" && onChainAvailable) ||
                          isPaymentOptionAvailable
                          ? {}
                          : {
                              ...customStyle,
                            },
                        "1"
                      )}

                    {isOnchainAvailable &&
                      tabSection(
                        onchainObj,
                        onChainLabel,
                        onchainPaymentMethod === "yes" ||
                          isPaymentOptionAvailable
                          ? {}
                          : {
                              ...customStyle,
                              paddingRight: "0 !important",
                            },
                        "2"
                      )}
                  </Box>
                  <Box className="qr-preview-section">
                    {!isMultiPaymentMethods ? (
                      displayQRCode()
                    ) : (
                      <Slider {...carouselProps} ref={sliderRef}>
                        {lightningPaymentAddress && displayQRCode()}
                        {onChainPaymentAddress && displayQRCode()}
                      </Slider>
                    )}
                  </Box>
                </>
              )}
            </Box>
            {isDetailsSectionVisible && (
              <PreviewDetailsSection
                setShowEditedContactInfo={setShowEditedContactInfo}
                customerCollections={showCustomerCollections}
                customFieldsDetails={paymentInfo?.custom_fields}
              />
            )}
            {reportIssueModal && (
              <CommonReportFeedbackModal
                sourceId={paymentInfo?.payment?.id}
                businessName={paymentInfo?.payment?.business_name}
                targetCurrency={
                  selectedAssetCurrency || paymentInfo?.payment?.target_currency
                }
                isOnchainAvailable={Boolean(isOnchainAvailable)}
                activeSection={activeSection}
                commentInputLabel={reasonLabel}
                commentInputPlaceholder={reasonPlaceholder}
                from="report"
                modalTitle={reportIssue}
                openModal={reportIssueModal}
                setOpenModal={setReportIssueModal}
                successMsg={submitIssueMsg}
                visibleInPaymentPage={visibleInPaymentPage}
                walletInputLabel={issueWalletLabel}
                selectedPaymentMethod={selectedPaymentMethod}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default QRCodePreview;
