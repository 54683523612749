import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import Text from "../Text/Text";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  btcToSatsAmount,
  convertExponentialToDecimal,
  excludedCode,
  handleUpdateCheckoutSession,
  satsToBtcAmount,
  satsToFiatAmount,
  showAmountExceedError,
} from "../constants";
import {
  amountExceedMsg,
  amountValidate,
  presetAmount,
  presetAmountGreaterInvalid,
  presetAmountLesserInvalid,
} from "../../messages";
import CustomNotification from "../Notification/Notification";
import InputMask from "../MaskInput";
import Label from "../Label/Label";
import { PaymentPageContext } from "../../contexts/contexts";

let timeout;
const PresetAmountPreview = ({
  paymentInfo,
  totalAmount,
  fontStyle,
  visibleInPaymentPage,
  checkoutSessionId,
  resolution,
  paymentStatus,
}) => {
  const [presetAmountValue, setPresetAmountValue] = useState(0);
  const [totalSatsAmount, setTotalSatsAmount] = useState();
  const [isSwapCurrencySATS, setIsSwapCurrencySATS] = useState(true);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [fiatValue, setFiatValue] = useState();
  const [apiCalled, setApiCalled] = useState(false);

  // Get context value using consumer
  const paymentPageData = useContext(PaymentPageContext);

  const getSwapCurrencyFormatting = () => {
    return (
      <>
        (≈{" "}
        <span
          style={{
            fontFamily: "Satoshi-Symbol",
            height: "24px",
            paddingTop: "1.5px",
          }}
        >
          !
        </span>
        {totalSatsAmount || 0})
      </>
    );
  };

  const setDefaultValue = () => {
    const splittedAmount = totalAmount.split(" ");
    const btcAmount = splittedAmount[0];
    const checkForBtcAmount =
      splittedAmount[1] === "BTC" ? btcToSatsAmount(btcAmount) : btcAmount;

    const satsAmount =
      paymentInfo?.currency?.code === "SATS"
        ? paymentInfo?.presetDetails?.presetAmount || 0
        : checkForBtcAmount;
    setTotalSatsAmount(satsAmount);
    return satsAmount;
  };

  useEffect(() => {
    if (!apiCalled && paymentInfo) {
      const { presetDetails } = paymentInfo;
      setIsSwapCurrencySATS(true);
      const satsAmount = setDefaultValue();
      setPresetAmountValue(satsAmount);
      setFiatValue(presetDetails?.presetAmount);
    }
  }, [paymentInfo, apiCalled]);

  const handleSwapCurrency = () => {
    isSwapCurrencySATS
      ? setPresetAmountValue(fiatValue)
      : setPresetAmountValue(totalSatsAmount || 0);
    setIsSwapCurrencySATS(!isSwapCurrencySATS);
  };

  const calculateSatsFromFiat = (amount) => {
    return Math.ceil(paymentInfo?.payment?.exchange_rate * parseFloat(amount));
  };

  const setValuesAfterError = () => {
    const satsAmount = setDefaultValue();
    const fiatAmountOfSats = satsToFiatAmount(
      satsAmount,
      paymentInfo?.payment?.exchange_rate
    );
    if (!isSwapCurrencySATS) {
      setPresetAmountValue(paymentInfo?.presetDetails?.presetAmount);
    } else {
      setPresetAmountValue(satsAmount);
      setFiatValue(fiatAmountOfSats);
    }
  };
  const updateCheckoutSession = (amount) => {
    if (amount != paymentInfo?.amount) {
      handleUpdateCheckoutSession(amount, paymentPageData, checkoutSessionId)
        .then(() => {
          const satsAmount = calculateSatsFromFiat(amount);
          setTotalSatsAmount(satsAmount);
          if (!isSwapCurrencySATS) {
            setPresetAmountValue(amount);
            setFiatValue(amount);
            setIsSwapCurrencySATS(false);
          } else {
            // set SATS value
            setPresetAmountValue(satsAmount);
            setIsSwapCurrencySATS(true);
          }
          setApiCalled(true);
        })
        .catch((errorMessage) => {
          if (errorMessage) {
            setToastMessage(errorMessage);
            setIsToastOpen(true);
          }
          setValuesAfterError();
          setApiCalled(false);
        });
    }
  };

  const makeAPICall = (value) => {
    if (visibleInPaymentPage) {
      // Api call for entered preset amount on payment page
      updateCheckoutSession(value);
    }
  };

  const helperSetMessage = (errorMsg, value) => {
    if (errorMsg) {
      setIsToastOpen(true);
      setToastMessage(errorMsg);
      setValuesAfterError();
    } else {
      setIsToastOpen(false);
      makeAPICall(value);
    }
  };

  const setErrorMessage = (errorMsg, value) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      helperSetMessage(errorMsg, value);
    }, 1500);
  };

  const getCurrentSatsAmount = (value) =>
    isSwapCurrencySATS ? value : calculateSatsFromFiat(value);

  const checkMinMaxValidation = (value) => {
    const presetData = paymentInfo?.presetDetails;
    if (presetData && value) {
      const { limits, minimumAmount, maximumAmount } = presetData;
      let errorMsg = "";
      const fiatAmountOfSats = satsToFiatAmount(
        value,
        paymentInfo?.payment?.exchange_rate || 0.0
      );
      const amountForAPI = isSwapCurrencySATS ? fiatAmountOfSats : value;
      if (limits) {
        const isOnlyFiat = !excludedCode.includes(paymentInfo?.currency?.code);
        const minAmount =
          isOnlyFiat && isSwapCurrencySATS
            ? calculateSatsFromFiat(minimumAmount)
            : minimumAmount;
        const maxAmount =
          isOnlyFiat && isSwapCurrencySATS
            ? calculateSatsFromFiat(maximumAmount)
            : maximumAmount;
        if (parseFloat(value) < minAmount || parseFloat(value) > maxAmount) {
          errorMsg =
            parseFloat(value) < minAmount
              ? presetAmountGreaterInvalid(
                  minimumAmount,
                  paymentInfo?.currency?.code
                )
              : presetAmountLesserInvalid(
                  maximumAmount,
                  paymentInfo?.currency?.code
                );
        }
        setErrorMessage(errorMsg, amountForAPI);
      } else {
        if (isSwapCurrencySATS) {
          const btcValue = satsToBtcAmount(value);
          errorMsg =
            parseFloat(btcValue) > 5 ? amountExceedMsg(presetAmount) : "";
        } else {
          const values = {
            currency: paymentInfo?.currency,
            amount: value,
          };
          errorMsg = showAmountExceedError(
            values,
            undefined,
            paymentInfo?.payment?.exchange_rate,
            presetAmount,
            "checkout"
          );
        }
        setErrorMessage(errorMsg, amountForAPI);
      }
      const currentSatsAmount = getCurrentSatsAmount(value);
      setPresetAmountValue(value);
      setTotalSatsAmount(currentSatsAmount);
      isSwapCurrencySATS && setFiatValue(fiatAmountOfSats);
      !visibleInPaymentPage && !isSwapCurrencySATS && setFiatValue(value);
    }
  };

  const handleChangeInput = (e) => {
    const value = e.target.value;
    const currentSatsAmount = getCurrentSatsAmount(value);
    if (timeout) clearTimeout(timeout);

    if (value <= 0) {
      setPresetAmountValue(value);
      setTotalSatsAmount(currentSatsAmount);
      setFiatValue(value);

      timeout = setTimeout(() => {
        setIsToastOpen(true);
        setToastMessage(amountValidate);
        setValuesAfterError();
      }, 1500);
    } else {
      checkMinMaxValidation(value);
    }
  };

  return (
    <>
      <CustomNotification
        open={isToastOpen}
        onClose={(_event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setIsToastOpen(false);
        }}
        severity="error"
        message={toastMessage}
        className=""
        autoHideDuration={4000}
      />
      <Box
        className={`preset-amount-preview ${
          resolution !== "web" && "content-center"
        }`}
      >
        <Label
          data-currency={
            isSwapCurrencySATS ? "SATS" : paymentInfo?.currency?.code
          }
          className={`label-type ${
            paymentStatus === "paid" && "mask-label-disabled"
          }`}
        >
          <InputMask
            extraClass="input-adornment"
            onChange={handleChangeInput}
            name="presetAmount"
            value={presetAmountValue}
            onKeyDown={(event) => {
              isSwapCurrencySATS && event.key === "." && event.preventDefault();
            }}
            disabled={
              paymentStatus === "paid" || !paymentInfo?.payment?.exchange_rate
            }
          />
        </Label>
        {!excludedCode.includes(paymentInfo?.currency?.code) && (
          <SwapVertIcon
            onClick={handleSwapCurrency}
            sx={{
              width: "34px",
              height: "34px",
              color: fontStyle?.color,
              marginLeft: "14px",
            }}
          />
        )}
      </Box>
      <Text
        className="checkout-acc-currency"
        size={18}
        font="regular"
        // align="center"
        variant="subtitle1"
        sx={fontStyle}
      >
        {isSwapCurrencySATS
          ? !excludedCode.includes(paymentInfo?.currency?.code) && (
              <>
                (≈ {paymentInfo.currency.symbol}
                {(fiatValue &&
                  convertExponentialToDecimal(
                    +parseFloat(fiatValue).toFixed(16)
                  )) ||
                  0}
                )
              </>
            )
          : getSwapCurrencyFormatting()}
      </Text>
    </>
  );
};

export default PresetAmountPreview;
