import React from "react";
import { Box } from "@mui/system";
import "../../../assets/styles/manage-apps.scss";
import { shopifyAdminToPaymentsViaSettings } from "../../messages";
import AddPaymentMethod from "./AddPaymentMethod";
import VideoSection from "./VideoSection";

const ShopifyInstallationStep4 = (props) => {
  const { values, setFieldValue, touched, errors, setTouched } = props;
  return (
    <Box className="fullscreen-modal-box">
      <Box className="modal-container-grid grid-box" sx={{ display: "flex" }}>
        <VideoSection headerText={shopifyAdminToPaymentsViaSettings} />
        <Box className="inner-grid" sx={{ maxWidth: "50%", overflow: "auto" }}>
          <AddPaymentMethod
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            setTouched={setTouched}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ShopifyInstallationStep4;
