import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Slide,
  TableCell,
  TableRow,
  Toolbar,
  Skeleton,
} from "@mui/material";
import React, { useState, useEffect, createRef, useCallback } from "react";
import CustomTable from "@speed/common/src/components/Table/Table";
import {
  clipboardElement,
  getAmountByCurrency,
  getCurrencyObj,
  linkStatus,
} from "@speed/common/src/components/constants";
import { useDispatch, useSelector } from "react-redux";
import { emptyProduct } from "../images";
import {
  addNewProduct,
  addProduct,
  emptyProductSubText,
  noPrices,
  testModeTextForProduct,
  testModeTitle,
} from "../messages";
import {
  back,
  emptySubText,
  next,
} from "@speed/common/src/components/messages";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import ProductForm from "./ProductForm";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@speed/common/src/components/Button/Button";
import TestModeHeader from "../Payments/TestModeHeader";
import Text from "@speed/common/src/components/Text/Text";
import CommonActionPopper from "./CommonActionPopper";
import {
  callAPIInterface,
  checkStatus,
  dateTimeFormatInApp,
  noOfRecords,
} from "../constants";
import { setIsDataAvailable } from "../../redux/common/actions";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import ImageIcon from "@mui/icons-material/Image";
import { useFlags } from "launchdarkly-react-client-sdk";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import HorizontalSearchPageTable from "../Common/HorizontalSearchPageTable";

const tableHeader = [
  { title: "Product ID", width: "170px", paddingLeft: "38px !important" },
  { title: "Product Name", width: "300px" },
  { title: "Price", width: "150px" },
  { title: "Created on", width: "200px" },
  { title: "Updated on", width: "200px" },
  { title: "Status", width: "200px" },
  { title: "Actions", width: "80px" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// product links table
const ProductsList = ({
  isOpen,
  setOpenModal,
  tab,
  getModuleSearchResultData,
  inputString,
  searchResultData,
  searchQueryUrl,
  showHorizontalTable = false,
  totalNumOfSearchResults,
}) => {
  const ref = createRef();
  const queryParam = { limit: noOfRecords };
  const pricesRef = createRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [rows, setRows] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isCreateProduct, setIsCreateProduct] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [disableBackButton, setDisableBackButton] = useState(false);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [currentRow, setCurrentRow] = useState(null);
  const [onArchiveRows, setOnArchiveRows] = useState([]);
  const [rowId, setRowId] = useState(null);
  const [rowLoader, setRowLoader] = useState(false);

  const liveMode = useSelector((state) => state.auth.liveMode);
  const history = useSelector((state) => state.common.history);

  const { prodListFe, prodArchiveFe, prodAddpricesFe } = useFlags();

  const dispatch = useDispatch();

  const tabName = ["available", "archived"];
  const fromSearchPage = history.location.pathname === "/search";

  useEffect(() => {
    setRows([]);
    if (prodListFe) {
      setHasMore(true);
      getProductList([], queryParam);
    } else {
      setHasMore(false);
    }
  }, [liveMode, prodListFe]);

  useEffect(() => {
    if (!isOpen) {
      setIsCreateProduct(false);
    }
  }, [isOpen]);

  useEffect(() => {
    !fromSearchPage && setRows(onArchiveRows);
  }, [onArchiveRows]);

  const loadMore = useCallback(() => {
    getProductList(rows, queryParams);
  }, [rows]);

  const getProductList = (lines, params) => {
    if (fromSearchPage) {
      if (showHorizontalTable) {
        setRows(searchResultData);
      } else {
        setTableRowSkeleton(true);
        getModuleSearchResultData(searchResultData)
          .then((result) => {
            setTableRowSkeleton(false);
            if (!result.hasMore) {
              setHasMore(false);
            }
            setRows(result?.concatedResultData);
          })
          .catch(() => {
            setTableRowSkeleton(false);
          });
      }
    } else {
      let method,
        data = {},
        path = "";

      method = "POST";
      path = `/products/prices`;

      if (tab) {
        data = { active: tab === "available" };
      }

      setTableRowSkeleton(true);
      callAPIInterface(method, path, { ...data, ...params })
        .then((res) => {
          if (res) {
            setTableRowSkeleton(false);
            if (!res.has_more) {
              setHasMore(false);
            } else {
              setQueryParams({
                ...queryParam,
                ending_before: res.data[res.data.length - 1].id,
              });
            }
            setRows(lines.concat(res.data));
            dispatch(setIsDataAvailable(res?.data?.length > 0));
          }
        })
        .catch(() => {
          setTableRowSkeleton(false);
        });
    }
  };

  let columnsData = tableHeader.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{ minWidth: column.width, paddingLeft: column.paddingLeft }}
    >
      {column.title}
    </TableCell>
  ));

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleAfterArchiveAction = (res) => {
    setAnchorEl(null);
    const updatedRows = rows.map((row) => {
      if (row.id === currentRow?.id) {
        row.active = res.active;
      }
      return row;
    });
    let result = [];
    // filter out row when archive from available tab or unarchive form archived tab
    if (tabName.includes(tab)) {
      result = updatedRows.filter((row) => row.id !== currentRow?.id);
    } else {
      result = updatedRows;
    }
    setRowLoader(false);
    setOnArchiveRows(result);
  };

  const renderListPrice = (rowItem) => {
    if (rowItem?.prices?.total_count > 1) {
      return `${rowItem?.prices?.total_count} prices`;
    } else if (rowItem?.prices?.data?.length === 0) {
      return noPrices;
    } else if (rowItem?.prices?.data?.length === 1) {
      return (
        <>
          {getCurrencyObj(rowItem?.prices?.data?.[0]?.currency)?.symbol}
          {getAmountByCurrency(
            rowItem?.prices?.data?.[0]?.unit_amount,
            rowItem?.prices?.data?.[0]?.currency
          )}
        </>
      );
    } else if (rowItem?.prices?.data?.length > 1) {
      return `${rowItem?.prices?.data?.length} prices`;
    }
  };

  let rowsData = rows?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem?.id}
        className="clickable"
        onClick={() =>
          history.push({
            pathname: `/products/${rowItem.id}`,
            state: { total_count: rowItem?.prices?.total_count },
          })
        }
      >
        {rowItem.id === rowId && rowLoader ? (
          tableHeader.map((column) => {
            return (
              <TableCell
                sx={{ padding: "18px 16px !important", width: column.width }}
                key={column.title}
              >
                <Skeleton />
              </TableCell>
            );
          })
        ) : (
          <>
            <TableCell sx={{ paddingLeft: "38px !important" }}>
              {clipboardElement(
                rowItem?.id,
                true,
                "payments-listing-id-clipboard",
                "inputBox",
                false
              )}
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {rowItem?.images?.[0] ? (
                  <img
                    src={rowItem?.images?.[0]}
                    width="40px"
                    height="40px"
                    style={{ marginRight: "15px" }}
                  />
                ) : (
                  !fromSearchPage && (
                    <Box
                      height="40px"
                      width="40px"
                      className="content-center"
                      sx={{
                        color: "#b8bfc7",
                        marginRight: "15px",
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      <ImageIcon className="image-icon" />
                    </Box>
                  )
                )}
                <TruncatedTextTooltip
                  textValue={rowItem.name}
                  cellWidth="300px"
                />
              </Box>
            </TableCell>
            <TableCell>{renderListPrice(rowItem)}</TableCell>
            <TableCell>{dateTimeFormatInApp(rowItem.created)}</TableCell>
            <TableCell>{dateTimeFormatInApp(rowItem.modified)}</TableCell>
            <TableCell>
              <Tag
                text={linkStatus[checkStatus(rowItem)].label}
                variant={linkStatus[checkStatus(rowItem)].variant}
              />
            </TableCell>
            <TableCell>
              <MoreHorizIcon
                className="horizontal-dots-icon pointer-cursor"
                tabIndex={0}
                onClick={(event) => {
                  event.stopPropagation();
                  setCurrentRow(rowItem);
                  setRowId(rowItem?.id);
                  handleClick(event);
                }}
              />
            </TableCell>
          </>
        )}
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    textOnNoData: emptySubText(liveMode, "product"),
    hasMore: hasMore,
    loadMore: loadMore,
    subTextOnNoData: emptyProductSubText,
    noDataImage: emptyProduct,
    createButtonText: addProduct,
    handleCreate: () => setOpenModal(true),
  };

  const handleNext = () => {
    setIsCreateProduct(true);
  };

  const handleBackButton = () => {
    setIsCreateProduct(false);
  };

  const actionAfterProductCreation = (isCloseModal, id = null) => {
    if (prodAddpricesFe) {
      setDisableCreateButton(false);
      setDisableBackButton(false);
    } else setDisabled(false);
    if (id) {
      history.push(`/products/${id}`);
      isCloseModal && setOpenModal(false);
    }
  };

  const handleSubmit = () => {
    const productTID = ref?.current?.handleSubmit();
    const pricingData = pricesRef?.current?.handleCreateProduct();
    const params = {
      name: productTID?.name,
      description: productTID?.description,
      images: productTID?.image ? [productTID?.image] : [],
    };

    const filteredData = pricingData?.filter((item) => {
      return item.modal !== "" && item.amount !== "";
    });

    if (prodAddpricesFe) {
      setDisableCreateButton(true);
      setDisableBackButton(true);
    } else setDisabled(true);
    callAPIInterface("POST", "/products", params)
      .then((res) => {
        if (res) {
          if (filteredData?.length) {
            filteredData.map((item, index) => {
              const priceParams = {
                currency: item.currency.code,
                product: res?.id,
                unit_amount: item.amount,
              };
              callAPIInterface("POST", "/prices", priceParams)
                .then(() => {
                  if (filteredData?.length - 1 === index) {
                    actionAfterProductCreation(true, res?.id);
                  }
                })
                .catch(() => {
                  actionAfterProductCreation(false);
                });
            });
          } else {
            actionAfterProductCreation(true, res?.id);
          }
        }
      })
      .catch((error) => {
        actionAfterProductCreation(false);
      });
  };

  const renderNextAndCreateButton = () => {
    return !isCreateProduct ? (
      <Button
        label={next}
        variant="contained"
        color="primary"
        className="payment-link-btn"
        disabled={disabled}
        onClick={handleNext}
      />
    ) : (
      <>
        <Button
          variant="outlined"
          color="primary"
          label={back}
          onClick={handleBackButton}
          sx={{ marginRight: "18px" }}
          disabled={disableBackButton}
        />
        <Button
          label={addProduct}
          variant="contained"
          color="primary"
          className="payment-link-btn"
          onClick={handleSubmit}
          disabled={disableCreateButton}
        />
      </>
    );
  };

  const renderCreateButton = () => {
    return (
      <Button
        label={addProduct}
        variant="contained"
        color="primary"
        className="payment-link-btn"
        onClick={handleSubmit}
        disabled={disabled}
      />
    );
  };

  const productTable = () => {
    return showHorizontalTable && fromSearchPage ? (
      <HorizontalSearchPageTable
        moduleName="product"
        columns={tableHeader}
        rows={tableProps.rows}
        searchQueryUrl={searchQueryUrl}
        inputString={inputString}
        totalNumOfSearchResults={totalNumOfSearchResults}
      />
    ) : (
      <CustomTable {...tableProps} />
    );
  };

  return (
    <Box className="main-content">
      {prodListFe ? productTable() : <AccessDenied />}
      {prodArchiveFe && (
        <CommonActionPopper
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          handleEdit={() => setOpenModal(true)}
          from="product"
          currentRow={currentRow}
          setRowLoader={(val) => setRowLoader(val)}
          afterArchiveAction={(res) => handleAfterArchiveAction(res)}
        />
      )}
      <Dialog
        disableEscapeKeyDown
        fullScreen
        open={isOpen}
        TransitionComponent={Transition}
        className="fullscreen-modal"
      >
        {!liveMode && (
          <TestModeHeader
            buttonText={testModeTitle}
            captionText={testModeTextForProduct}
          />
        )}
        <AppBar sx={{ position: "relative" }} className="modal-app-bar">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpenModal(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Text
              size={20}
              font="semibold"
              sx={{ flex: 1 }}
              className="default-text divider"
              variant="h6"
            >
              {addNewProduct}
            </Text>
            {prodAddpricesFe
              ? renderNextAndCreateButton()
              : renderCreateButton()}
          </Toolbar>
        </AppBar>
        <Box className="fullscreen-modal-box product-modal">
          <ProductForm
            isCreateProduct={isCreateProduct}
            ref={ref}
            pricesRef={pricesRef}
            setDisabled={setDisabled}
            setDisableCreateButton={setDisableCreateButton}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

export default ProductsList;
