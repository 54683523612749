import { Grid, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sessionService } from "redux-react-session";
import {
  checkLinkStatus,
  resendVerification,
  verifyEmail,
} from "../../redux/auth/actions";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import LinkStatus from "../Common/LinkStatus";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import Text from "@speed/common/src/components/Text/Text";
import { checkEmail } from "../images";
import {
  verifyEmailMsg,
  verifyEmailSubMsg1,
  verifyEmailSubMsg2,
  checkEmailLine3Msg,
} from "../messages";
import { loadingMsg } from "@speed/common/src/components/messages";
import WelcomeBox from "../Common/WelcomeBox";
import {
  isOneClickIntegrationAvailable,
  sourceWalletApp,
  whiteLogoImage,
} from "../constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import { resendMsg } from "@speed/common/src/messages";

const VerifyEmail = () => {
  const dispatch = useDispatch();

  const [isLink, setIsLink] = useState(false);
  const [verifyEmailScreen, setVerifyEmailScreen] = useState(false);
  const [linkStatus, setLinkStatus] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const { history, isMobile } = useSelector((state) => state.common);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const { obCustprofilingquestionFe } = useFlags();

  const getLinkFromURL = async (user) => {
    const searchURL = history.location.search.replace("+", "%2B");
    const data = new URLSearchParams(searchURL);
    const link = data.get("id") || (user && user.verification_link);
    const userEmail = data.get("email") || (user && user.email);
    if (link) {
      setIsLink(true);
      setLoading(true);
      const signUpSource = data.get("signup_source");
      if (signUpSource === sourceWalletApp && !isMobile)
        window.location = `${process.env.REACT_APP_CHROME_EXT_ENDPOINT}#/verify-email?id=${link}&email=${userEmail}`;
      else {
        await sessionService
          .saveUser({ verification_link: link, email: userEmail })
          .then((_res) => history.push("/verify-email"));
        dispatch(checkLinkStatus(link))
          .then((res) => {
            if (res) {
              setEmail(res.new_email);
              dispatch(verifyEmail({ verification_link: link })).then(
                (response) => {
                  if (response) {
                    const fromOneClickDataAvailable =
                      isOneClickIntegrationAvailable();

                    if (fromOneClickDataAvailable) {
                      history.push("/authorize/connect");
                      return;
                    }
                    obCustprofilingquestionFe && history.push("/onboarding");
                    isLoggedIn && history.push("/dashboard");
                  }
                  setLoading(false);
                }
              );
            }
          })
          .catch((e) => {
            const errors = e?.response?.data?.errors;
            setEmail(userEmail);
            const errorType = errors?.[0]?.type?.toLowerCase();
            if (errorType === "link_expired") setLinkStatus("expired");
            else if (errorType === "link_already_used") setLinkStatus("used");
            else history.push("/dashboard");
            setLoading(false);
          });
      }
    } else {
      setIsLink(false);
      if (!isLoggedIn && history && history.location.state) {
        setVerifyEmailScreen(true);
        setEmail(history.location.state.email);
      } else history.push("/login");
    }
  };

  useEffect(async () => {
    const user = await sessionService
      .loadUser()
      .then((res) => res)
      .catch((_e) => {});
    getLinkFromURL(user);
  }, []);

  const handleResend = () => {
    dispatch(resendVerification({ email }));
  };

  return isLink ? (
    <>
      <BackdropLoader
        open={isLoading}
        alt="loading..."
        text={loadingMsg}
        customClass="loading-in-auth"
      />
      {!isLoading && (
        <LinkStatus from="verifyEmail" type={linkStatus} email={email} />
      )}
    </>
  ) : (
    verifyEmailScreen && (
      <Grid
        container
        component="main"
        className="sign-up"
        direction={isMobile ? "column" : "row"}
        sx={{
          width: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: !isMobile ? "space-between" : "center",
            width: "100%",
          }}
        >
          {!isMobile && (
            <Box sx={{ paddingLeft: "150px", paddingTop: "85px" }}>
              <WelcomeBox />
            </Box>
          )}
          <Box sx={!isMobile ? { paddingRight: "150px" } : {}}>
            {isMobile && (
              <Box className="welcome-box logo-signup">{whiteLogoImage}</Box>
            )}
            <Grid
              item
              className={`check-email-box  ${
                !isMobile ? "verify-email-box" : "signup-mobile-form"
              }`}
              padding={isMobile ? "0px 37px" : "40px 30px 70px 30px !important"}
              sx={{ width: isMobile ? "auto" : "500px" }}
            >
              <img
                src={checkEmail}
                className={!isMobile ? "check-email-icon" : "email-sent-icon"}
                alt="check-email"
              />

              <Text
                size={isMobile ? 22 : 30}
                align="center"
                font="bold"
                className="default-text"
              >
                {verifyEmailMsg}
              </Text>
              <Text
                size={isMobile ? 14 : 18}
                font="regular"
                align="center"
                className="default-text text email-verify-text"
                variant="subtitle1"
              >
                {verifyEmailSubMsg1}
              </Text>
              <Text
                size={isMobile ? 14 : 18}
                font="regular"
                align="center"
                className="default-text text email-verify-text"
                variant="subtitle1"
              >
                {verifyEmailSubMsg2}
              </Text>
              <Text
                size={isMobile ? 14 : 18}
                font="semibold"
                align="center"
                className="default-text text"
                variant="subtitle1"
              >
                {checkEmailLine3Msg}
                <CustomLink bold size="large" onClick={handleResend}>
                  &nbsp;{resendMsg}
                </CustomLink>
              </Text>
            </Grid>
          </Box>
        </Box>
      </Grid>
    )
  );
};

export default VerifyEmail;
