import React, { useState, createRef, useEffect } from "react";
import { AppBar, Box, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CommonHeader from "../Common/CommonHeader";
import OneQRTable from "../Common/OneQRTable";
import "../../assets/styles/oneQR.scss";
import {
  createNew,
  createOneQRMsg,
  statementDescriptor,
  testModeTextForOneQR,
  testModeTitle,
} from "../messages";
import { Button } from "@speed/common/src/components/Button/Button";
import TestModeHeader from "../Payments/TestModeHeader";
import CloseIcon from "@mui/icons-material/Close";
import Text from "@speed/common/src/components/Text/Text";
import { useDispatch, useSelector } from "react-redux";
import CreateOneQRLink from "../Common/CreateOneQRLink";
import { back, next } from "@speed/common/src/components/messages";
import { useFlags } from "launchdarkly-react-client-sdk";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import history from "@speed/common/src/components/history";
import {
  callAPIInterface,
  callStrapiAPIInterface,
  QRTypeArray,
} from "../constants";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { getAccountName } from "@speed/common/src/components/constants";
import GetStartedHeader from "@speed/common/src/components/GetStartedHeader/GetStartedHeader";
import {
  setCaughtErrorInStrapi,
  setIsDataAvailable,
  setIsModalOpen,
} from "../../redux/common/actions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OneQR = () => {
  const ref = createRef();
  const confirmRef = createRef();
  const [isOneQRLink, setIsOneQRLink] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [disableBackButton, setDisableBackButton] = useState(false);
  const [formDataValues, setFormDataValues] = useState(null);
  const [isGetHeaderInfoLoading, setGetHeaderInfoLoading] = useState(true);
  const [oneQRGuide, setOneQRGuide] = useState(null);

  const { liveMode, currentAccount } = useSelector((state) => state.auth);
  const { brandingPageUI, isDataAvailable, isModalOpen, caughtErrorInStrapi } =
    useSelector((state) => state.common);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsDataAvailable(true));
    getOneQRGuide();
    return () => {
      dispatch(setIsModalOpen(false));
      dispatch(setCaughtErrorInStrapi(false));
    };
  }, [liveMode]);

  const accountName = getAccountName(currentAccount);

  const { oqrCreateoneqr, oqrListalloneqr } = useFlags();

  const handleModalClose = () => {
    dispatch(setIsModalOpen(false));
    setIsOneQRLink(false);
  };

  const handleNext = () => {
    setIsOneQRLink(true);
    setFormDataValues(ref.current && ref.current.handleSubmit());
  };

  const handleBackButton = () => {
    setDisabled(false);
    setIsOneQRLink(false);
  };

  const returnData = (data) => {
    return {
      name: data.name,
      description: data.description,
      oneqr_method: data.oneqr_method,
    };
  };
  const handleSubmit = () => {
    // return the values of form while submitting the form
    let qr_msg,
      qr_website_url,
      formData = {};

    if (brandingPageUI?.qr_type === "On-chain QR code") {
      const data = ref.current && ref.current.handleSubmit();
      formData = returnData(data);
    } else {
      const oneQRFormData =
        confirmRef.current && confirmRef.current.handleSubmitConfirm();
      const { custom_message, website_url } = oneQRFormData;
      formData = returnData(formDataValues);
      qr_msg = custom_message;
      qr_website_url = website_url;
    }
    const { name, description, oneqr_method } = formData;

    const createQRData = {
      name,
      oneqr_method: QRTypeArray.find((item) => item.value === oneqr_method)
        .apiValue,
      statement_descriptor: statementDescriptor(accountName),
    };
    if (description) {
      createQRData.description = description;
    }

    if (qr_msg) {
      createQRData.success_message = qr_msg;
    }

    if (qr_website_url) {
      createQRData.success_url = qr_website_url;
    }

    if (createQRData) {
      setDisableCreateButton(true);
      setDisableBackButton(true);
      callAPIInterface("POST", "/oneqrs", JSON.stringify(createQRData))
        .then((response) => {
          response && history.push(`/one-qr/${response.id}`);
        })
        .catch((_error) => {
          setDisableCreateButton(false);
          setDisableBackButton(false);
        });
    }
  };

  const getOneQRGuide = () => {
    setGetHeaderInfoLoading(true);
    callStrapiAPIInterface(
      "GET",
      "/api/one-qr-guide?populate[header][populate][use_cases][populate]=*"
    )
      .then((response) => {
        setOneQRGuide(response?.data?.attributes?.header);
        setGetHeaderInfoLoading(false);
      })
      .catch((error) => {
        dispatch(setCaughtErrorInStrapi(true));
        setGetHeaderInfoLoading(false);
      });
  };

  return (
    <Box
      bgcolor={!isDataAvailable && !caughtErrorInStrapi ? "#f7fafc" : ""}
      className="section-wrapper"
    >
      {isDataAvailable || caughtErrorInStrapi ? (
        <>
          <CommonHeader />
          <CustomDivider />
        </>
      ) : (
        <GetStartedHeader
          setIsModalOpen={setIsModalOpen}
          loading={isGetHeaderInfoLoading}
          getHeaderInfo={oneQRGuide}
        />
      )}
      <Box className="one-qr-wrapper">
        {oqrCreateoneqr
          ? (isDataAvailable || caughtErrorInStrapi) && (
              <Box className="action-btn-wrapper mui-fixed">
                <Button
                  icon="addIcon"
                  className="add-icon"
                  label={createNew}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    dispatch(setIsModalOpen(true));
                  }}
                />
              </Box>
            )
          : null}

        {oqrListalloneqr ? <OneQRTable /> : <AccessDenied />}

        <Dialog
          disableEscapeKeyDown
          fullScreen
          open={isModalOpen}
          TransitionComponent={Transition}
          className="fullscreen-modal"
        >
          {!liveMode && (
            <TestModeHeader
              buttonText={testModeTitle}
              captionText={testModeTextForOneQR}
            />
          )}
          <AppBar sx={{ position: "relative" }} className="modal-app-bar">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  handleModalClose();
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Text
                size={20}
                font="semibold"
                sx={{ flex: 1 }}
                className="default-text divider"
                variant="h6"
              >
                {createOneQRMsg}
              </Text>
              {brandingPageUI?.qr_type === "Lightning QR code" ? (
                <>
                  {!isOneQRLink ? (
                    <Button
                      label={next}
                      variant="contained"
                      color="primary"
                      className="payment-link-btn"
                      disabled={disabled}
                      onClick={handleNext}
                    />
                  ) : (
                    <React.Fragment>
                      <Button
                        variant="outlined"
                        color="primary"
                        label={back}
                        onClick={handleBackButton}
                        sx={{ marginRight: "18px" }}
                        disabled={disableBackButton}
                      />
                      <Button
                        label={createOneQRMsg}
                        variant="contained"
                        color="primary"
                        className="payment-link-btn"
                        onClick={handleSubmit}
                        disabled={disableCreateButton}
                      />
                    </React.Fragment>
                  )}
                </>
              ) : (
                <Button
                  label={createOneQRMsg}
                  variant="contained"
                  color="primary"
                  className="payment-link-btn"
                  onClick={handleSubmit}
                  disabled={disabled || disableBackButton}
                />
              )}
            </Toolbar>
          </AppBar>
          <Box className="fullscreen-modal-box">
            <CreateOneQRLink
              ref={ref}
              confirmRef={confirmRef}
              handleButtonDisable={(value) => setDisabled(value)}
              isOneQRLink={isOneQRLink}
              linkType="oneQR"
              handleDisableConfirmButton={(value) =>
                setDisableCreateButton(value)
              }
            />
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
};

export default OneQR;
