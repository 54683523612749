import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TableCell,
  TableRow,
  Skeleton,
} from "@mui/material";
import {
  linkStatus,
  clipboardElement,
} from "@speed/common/src/components/constants";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsDataAvailable } from "../../redux/common/actions";
import {
  callAPIInterface,
  dateFromTimestampInApp,
  deactivateCashback,
  getTypeOfCashback,
  noOfRecords,
} from "../constants";
import { activate, deActivate, emptyCashbackSubText } from "../messages";
import { emptySubText } from "@speed/common/src/components/messages";
import { emptyCashback } from "../images";
import CustomTable from "@speed/common/src/components/Table/Table";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import classNames from "classnames";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import NoDataAvailable from "@speed/common/src/components/NoDataAvailable";

const CashbackTable = ({ tab }) => {
  const liveMode = useSelector((state) => state.auth.liveMode);
  const { history, isDataAvailable, caughtErrorInStrapi } = useSelector(
    (state) => state.common
  );
  const { cbDeactivatecashbackFe } = useFlags();

  const tableHeader = [
    { title: "Cashback ID", width: "200px", paddingLeft: "38px !important" },
    {
      title: "Cashback Name",
      width: "300px",
      align: "left",
    },
    { title: "Type", width: "200px", align: "left" },
    { title: "Start Date", width: "200px", align: "left" },
    { title: "End Date", width: "200px", align: "left" },
    { title: "Status", width: "150px", align: "left" },
    { title: "Actions", width: "125px", align: "center" },
  ];

  const queryParam = "?limit=" + noOfRecords;
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [rows, setRows] = useState([]);
  const [rowId, setRowId] = useState(null);
  const [rowLoader, setRowLoader] = useState(false);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [columns, setColumns] = useState(tableHeader);

  const dispatch = useDispatch();

  const openPopper = Boolean(anchorEl);

  useEffect(() => {
    setRows([]);
    setHasMore(true);
    getCashbacksList([], queryParam);
  }, [liveMode]);

  const getCashbacksList = (lines, params) => {
    let method,
      data,
      path = "";
    //use method POST when tab is selected other than 'All'
    if (tab) {
      method = "POST";
      data = {
        status: tab,
      };
      path = "/cashbacks/filter";
    } else {
      method = "GET";
      data = {};
      path = "/cashbacks";
    }

    setTableRowSkeleton(true);
    callAPIInterface(method, path + params, data)
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1].id
            );
          }
          setRows(lines.concat(res.data));
          dispatch(setIsDataAvailable(res?.data?.length > 0));
        }
      })
      .catch((_err) => {
        setTableRowSkeleton(false);
      });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick(e, currentRow);
    }
  };

  const handleClick = (event, rowData) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setCurrentRow(rowData);
  };

  const handleDeactivateActionError = () => {
    setRowLoader(false);
  };

  const handleDeactivateAction = (res) => {
    setAnchorEl(null);
    for (let row of rows) {
      if (row.id === currentRow?.id) {
        row.status = res.status;
        break;
      }
    }

    const result =
      tab === "active" ? rows.filter((row) => row.id !== currentRow?.id) : rows; // filter out row when deactivating link from active tab
    setRows(result);
    setRowLoader(false);
  };

  const handleDeActiveCashback = () => {
    if (currentRow?.status === "active") {
      setRowId(currentRow?.id);
      setRowLoader(true);
      deactivateCashback(currentRow?.id)
        .then(handleDeactivateAction)
        .catch(handleDeactivateActionError);
    } else {
      setAnchorEl(null);
    }
  };

  const handleActionClick = () => {
    setAnchorEl(null);
    handleDeActiveCashback();
  };

  useEffect(() => {
    //change condition when active action is available
    if ((tab !== "active" && tab !== undefined) || !cbDeactivatecashbackFe) {
      const newColumns = columns.filter((column) => column.title !== "Actions");
      setColumns(newColumns);
    } else {
      setColumns(tableHeader);
    }
  }, [tab, cbDeactivatecashbackFe]);

  const loadMore = useCallback(() => {
    getCashbacksList(rows, queryParams);
  }, [rows]);

  let columnsData = columns.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{ width: column.width, paddingLeft: column.paddingLeft }}
    >
      {column.title}
    </TableCell>
  ));

  let rowsData = rows?.map((rowItem) => {
    const typeOfCashback = getTypeOfCashback(rowItem);
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/cashbacks/${rowItem.id}`)}
      >
        {rowItem.id === rowId && rowLoader ? (
          columns.map((column) => {
            return (
              <TableCell
                key={column.title}
                sx={{ padding: "18px 16px !important", width: column.width }}
              >
                <Skeleton />
              </TableCell>
            );
          })
        ) : (
          <>
            <TableCell sx={{ paddingLeft: "38px !important" }}>
              {clipboardElement(
                rowItem?.id,
                true,
                "payments-listing-id-clipboard",
                "inputBox",
                false
              )}
            </TableCell>
            <TableCell>
              <TruncatedTextTooltip
                textValue={rowItem.name}
                boxProps={{
                  sx: {
                    display: "grid",
                  },
                }}
              />
            </TableCell>
            <TableCell>{typeOfCashback}</TableCell>
            <TableCell style={{ textAlign: "left" }}>
              {rowItem?.start_at && dateFromTimestampInApp(rowItem.start_at)}
            </TableCell>
            <TableCell style={{ textAlign: "left" }}>
              {rowItem?.end_at && dateFromTimestampInApp(rowItem.end_at)}
            </TableCell>
            <TableCell>
              <Tag
                text={linkStatus[rowItem?.status]?.label}
                variant={linkStatus[rowItem?.status]?.variant}
                className="text-capitalize"
              />
            </TableCell>
            {cbDeactivatecashbackFe && (tab === "active" || tab === undefined) && (
              <TableCell style={{ textAlign: "center" }}>
                {rowItem?.status === "active" && (
                  <MoreHorizIcon
                    className="horizontal-dots-icon"
                    tabIndex={0}
                    onKeyDown={handleKeyDown}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClick(event, rowItem);
                    }}
                  />
                )}
              </TableCell>
            )}
          </>
        )}
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
    className: "payment-table",
    textOnNoData: emptySubText(liveMode, "Cashbacks", tab),
    subTextOnNoData: emptyCashbackSubText,
    noDataImage: emptyCashback,
  };

  return (
    <>
      <Box className="main-content">
        {isDataAvailable ? (
          <CustomTable {...tableProps} />
        ) : (
          (tab || caughtErrorInStrapi) && (
            <Box
              minHeight="calc(100vh - 201px)"
              display="flex"
              justifyContent="center"
            >
              <NoDataAvailable
                text={emptySubText(liveMode, "Cashbacks", tab)}
                image={emptyCashback}
                subText={emptyCashbackSubText}
              />
            </Box>
          )
        )}
      </Box>

      <CustomPopper
        disablePortal={true}
        open={openPopper}
        anchorEl={anchorEl}
        position="bottom"
        handleClose={() => setAnchorEl(null)}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleActionClick()}>
              <ListItemText
                primary={
                  currentRow && currentRow.status === linkStatus.active.value
                    ? deActivate
                    : activate
                }
                className={classNames(
                  currentRow &&
                    currentRow.status === linkStatus.active.value &&
                    "text-danger"
                )}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </CustomPopper>
    </>
  );
};

export default CashbackTable;
