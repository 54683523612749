import { Box } from "@mui/system";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import {
  clipboardElement,
  linkStatus,
  redirectWhenEntityNotFound,
  satsToBtcAmount,
  generateBreadcrumbs,
} from "@speed/common/src/components/constants";
import { Link, useParams } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import {
  callAPIInterface,
  dateFromTimestampInApp,
  dateTimeFormatInApp,
  deactivateCashback,
  getCashbackDetails,
  getTypeOfCashback,
  renderStatus,
  RouteBreadcrumbs,
} from "../constants";
import {
  cashbackEndDateLabel,
  cashbackExpireInLabel,
  cashbackStartDateLabel,
  dateCreated,
  deActivate,
  details,
  entityNotFoundMessage,
  status as statusText,
  type,
  withdraw,
} from "../messages";
import { useEffect, useState } from "react";
import Button from "@speed/common/src/components/Button/Button";
import { useSelector } from "react-redux";
import { Grid, TableCell, TableRow } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import { useFlags } from "launchdarkly-react-client-sdk";
import { KeyValueGrid } from "@speed/common/src/components/KeyValueGrid";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import DetailPagePaymentSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import LinkSkeleton from "@speed/common/src/components/Skeleton/LinkSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";

const withdrawColumnData = [
  { title: "Withdraw ID", width: "480px" },
  { title: "Amount (BTC)", width: "135px" },
  { title: "Date", width: "230px" },
  { title: "Status", width: "120px" },
];

const CashbackDetails = (props) => {
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const urlParams = useParams();
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [data, setData] = useState(null);
  const [isLinkActive, setLinkActive] = useState(null);
  const [withdrawList, setWithdrawList] = useState([]);
  const [withdrawSkeleton, setWithdrawSkeleton] = useState(true);
  const [disableActionButton, setDisableActionButton] = useState(false);

  const liveMode = useSelector((state) => state.auth.liveMode);
  const history = useSelector((state) => state.common.history);
  const { cbDeactivatecashbackFe } = useFlags();

  const getWithdrawsList = () => {
    setWithdrawSkeleton(true);
    callAPIInterface("POST", `/cashbacks/${urlParams?.id}/withdraws`, {})
      .then((res) => {
        if (res) {
          setWithdrawSkeleton(false);
          setWithdrawList(res?.data);
        }
      })
      .catch(() => {
        setWithdrawSkeleton(false);
      });
  };

  const rows = withdrawList?.map((rowItem) => (
    <TableRow
      key={rowItem.id}
      className="clickable"
      onClick={() => history.push(`/withdraws/${rowItem?.id}`)}
    >
      <TableCell>
        {clipboardElement(rowItem?.id, true, "branding-detail", "text", true)}
      </TableCell>
      <TableCell align="center">
        {satsToBtcAmount(rowItem.target_amount_paid)}
      </TableCell>
      <TableCell align="left">
        {dateTimeFormatInApp(rowItem?.created)}
      </TableCell>
      <TableCell align="left">{renderStatus(rowItem?.status)}</TableCell>
    </TableRow>
  ));

  const getCashbackDetail = () => {
    setWithdrawSkeleton(true);
    getCashbackDetails(urlParams.id)
      .then((res) => {
        if (res) {
          getWithdrawsList();
          setData(res);
          setLinkActive(res?.status === "active");
        }
      })
      .catch((err) => {
        setWithdrawSkeleton(false);
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const loadDetailPage = () => {
    setData(null);
    setIsShowNotFound(false);
    getCashbackDetail();
  };

  const handleActiveDeActive = async (status) => {
    if (!status) {
      setDisableActionButton(true);
      try {
        await deactivateCashback(urlParams.id);
        setDisableActionButton(false);
        loadDetailPage();
      } catch (e) {
        console.log(e);
        setDisableActionButton(false);
      }
    }
  };

  const renderActivateDeActivateButton = () =>
    isLinkActive && (
      <Button
        icon="closeIcon"
        label={deActivate}
        variant="outlined"
        color="error"
        onClick={async () => {
          await handleActiveDeActive(false);
        }}
        disabled={disableActionButton}
      />
    );

  const typeOfCashback = getTypeOfCashback(data);
  const headerContent = () => {
    const cashbackNameTextProps = {
      sx: {
        fontSize: "28px !important",
        color: "#0a193e",
        fontFamily: "Inter-SemiBold",
      },
    };

    return (
      <Box
        className="header-content"
        display="flex"
        justifyContent="space-between"
      >
        <Box>
          <TruncatedTextTooltip
            textValue={data.name}
            cellWidth="750px"
            textProps={cashbackNameTextProps}
          />
          <Box className="header-price-content">
            <Box className="fiat-price">
              <Text className="default-text" size={20}>
                {typeOfCashback}
              </Text>
            </Box>
          </Box>
          {data?.description && (
            <Text
              sx={{
                marginTop: "10px",
                maxWidth: "750px",
                wordBreak: "break-word",
              }}
              variant="h4"
              className="grey-text"
              size={14}
              font="regular"
            >
              {data.description}
            </Text>
          )}
        </Box>

        <Box sx={{ marginTop: "35px" }}>
          {clipboardElement(
            data?.id,
            isLinkActive,
            "clipboard-text",
            "text",
            true
          )}
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    loadDetailPage();
  }, [liveMode, urlParams?.id]);

  const renderCashbackDetails = () =>
    data ? (
      <>
        {headerContent()}
        <Box className="details-content" marginTop="60px">
          <Text size={20} className="default-text">
            {details}
          </Text>
        </Box>
        <CustomDivider sx={{ my: "12px" }} />
        <Box
          gap={2}
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          pr="16px"
        >
          <KeyValueGrid keyName={type} value={typeOfCashback} />
          {data.start_at && (
            <KeyValueGrid
              keyName={cashbackStartDateLabel}
              value={dateFromTimestampInApp(data.start_at)}
            />
          )}
          <KeyValueGrid
            keyName={dateCreated}
            value={dateTimeFormatInApp(data?.created)}
          />
          {data.end_at && (
            <KeyValueGrid
              keyName={cashbackEndDateLabel}
              value={dateFromTimestampInApp(data.end_at)}
            />
          )}
          <KeyValueGrid
            keyName={statusText}
            value={
              <Tag
                text={linkStatus[data?.status]?.label}
                variant={linkStatus[data?.status]?.variant}
              />
            }
          />
          <KeyValueGrid
            keyName={cashbackExpireInLabel}
            value={data.expire_in_days}
          />
        </Box>
      </>
    ) : (
      <LinkSkeleton from="cashback" props={props} />
    );

  const renderWithdrawDetails = () =>
    !withdrawSkeleton ? (
      <Box className="product-content">
        <HorizontalTable
          label={withdraw}
          isColumnShown={rows.length > 0}
          isShowButton={rows.length > 3}
          columns={withdrawColumnData}
          rows={rows}
          rowsPerPage={3}
          isShowPagination={false}
          tableBodyClassName="border-none"
          handleViewAllClick={() => {
            history.push(`/cashbacks/${data?.id}/withdraws`);
          }}
        />
      </Box>
    ) : (
      <DetailPagePaymentSkeleton />
    );

  return !isShowNotFound ? (
    <Grid className="payment-link-detail-wrapper" container spacing={1}>
      <Box className="section-wrapper" sx={{ position: "relative" }}>
        {data && cbDeactivatecashbackFe && (
          <Box
            className="action-btn-wrapper"
            style={{ top: "23px", right: "2px" }}
          >
            {renderActivateDeActivateButton()}
          </Box>
        )}
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        {renderCashbackDetails()}
        {renderWithdrawDetails()}
      </Box>
    </Grid>
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("Link")}
        subTitle={`Cashback ${urlParams.id} is not found.`}
        btnText={`View ${liveMode ? "live" : "test"} mode links`}
      />
    </Box>
  );
};

export default CashbackDetails;
