// Password policy
export const min8Char = "Password must be at least 8 characters";
export const letter = "Have at least 1 letter ( a,b,c,d…)";
export const oneNumber = "Have at least 1 number ( 1,2,3…)";
export const letterAndNumber = "Include both uppercase and lowercase letters";
export const passwordPolicy = [min8Char, letter, oneNumber, letterAndNumber];

// Labels
export const networkRoutingFee = "Network/routing fees";
export const sendMax = "Send Max";
export const fiat = "Fiat";
export const connectedExtensions = "Connected Extensions";
export const addAmountOptions = "Add Amount Options";
export const registerLabel = "Register";
export const firstNameLabel = "First Name";
export const emailAddressLabel = "Enter email addresses";
export const deliverTo = "Deliver to";
export const lastNameLabel = "Last Name";
export const changeEmailLabel = "Change Email";
export const mobileNoLabel = "Mobile Number";
export const languageLabel = "Language";
export const timezoneLabel = "Timezone";
export const genderLabel = "Gender";
export const dateFormatLabel = "Date format";
export const newPasswordLabel = "New Password";
export const confirmPasswordLabel = "Confirm Password";
export const businessAddressLabel = "Business Address";
export const accountNameLabel = "Account Name";
export const accountInfoLabel = "Account Info";
export const permissionLabel = "Permission";
export const publicInfoLabel = "Public Info";
export const closeAccountLabel = "Close Account";
export const publicBusinessNameLabel = "Public Business Name";
export const supportPhoneNumberLabel = "Support Phone Number";
export const supportAddressLabel = "Support Address";
export const businessWebsiteLabel = "Business Website";
export const supportWebsiteLabel = "Support Website";
export const privacyPolicyLabel = "Privacy Policy";
export const termsOfServiceLabel = "Terms of Services";
export const notificationEmailLabel = "Email";
export const notificationLabel = "Notifications";
export const paymentLinkCreate = "Create Payment Link";
export const createKey = "Create Key";
export const apiKeyLabel = "API Key";
export const apiEndpoint = "API endpoint";
export const noteLabel = "Add a note";
export const addMemberLabel = "Add Member";
export const createSecretKey = "Create Secret Key";
export const newApiKey = "Your new API key";
export const webhookEndpointURLabel = "Endpoint URL";
export const description = "Description";
export const versionLabel = "API Version";
export const revealSecretKey = (mode) =>
  `Reveal ${mode ? "Live" : "Test"} Secret Key`;
export const revealPublishableKey = (mode) =>
  `Reveal ${mode ? "Live" : "Test"} Publishable Key`;
export const membersLabel = "Members";
export const productLabel = "Products";
export const qtyLabel = "Qty";
export const priceLabel = "Price";
export const pricesLabel = "Prices";
export const payoutWalletLabel = "Payout Wallet";
export const payoutScheduleLabel = "Payout Schedule";
export const walletNameLabel = "Wallet Name";
export const wallet = "Wallet";
export const LNAddress = "LN";
export const getAddressName = (address) => `${address} Address`;
export const currencyLabel = "Currency";
export const showConfirmationPreviewLabel = "Show confirmation page";
export const redirectToWebsite = "Redirect to my website";
export const websiteLabel = "Website";
export const customMessageLabel = "Use custom message";
export const checkoutPaymentLinkTabLabel = "Checkout & Payment link";
export const emailRecieptTabLabel = "Email Reciept";
export const fontsLabel = "Fonts:";
export const inviteSentLabel = "Invitation Sent";
export const invitationSuccess = (email) =>
  `Invitation to ${email} has been sent successfully`;
export const latestPaymentLabel = "Latest Payment";
export const availableBalance = "Available Balance";
export const balanceExceeded =
  "The account balance is less than the specified amount.";
export const currentBalance = "Current Balance";
export const unclearedBalance = "Uncleared Balance";
export const available = "Available";
export const archived = "Archived";
export const payout = "Payout";
export const transfer = "Transfer";
export const pending = "pending";
export const accepted = "accepted";
export const loadMore = "Load more";
export const paymentMethod = "Manage your payment methods";
export const btcToleranceTooltipContent = [
  "We have set a 1% threshold by default for accepting and receiving underpayments, it can be updated up to 5% or less.",
  "Eg: Tolerance is set up to 5%, the expected amount is 10,000 SATS, and the amount received ≥ 9500 SATs will be considered fully paid.",
];
export const availableBalanceText =
  "Your available balance is the amount of money you can withdraw right away";
export const currentBalanceText =
  "Your current balance is the total amount of money available plus any uncleared funds. It is used for budgeting";
export const unclearedBalanceText =
  "Your uncleared balance is the total amount of money that is on its way but has not yet been confirmed";
export const learnMore = "Learn more";
export const allPayments = "All Payments";
export const succeeded = "Succeeded";
export const incomplete = "Incomplete";
export const refunded = "Refunded";
export const all = "All";
export const active = "Active";
export const payment = "Payment";
export const checkoutLink = "Checkout Link";
export const session = "Session";
export const paymentLink = "Payment Link";
export const withdrawalLink = "withdrawal link";
export const log = "Logs";
export const eventsText = ["event", "events"];
export const events = "Events";
export const unpaid = "unpaid";
export const eventData = "Event data";
export const webhookAttempts = "Webhook attempts";
export const webhookAttemptsLabel = "Webhook attempts - for your";
export const webhookSubheader =
  "Attempts to send this event to your webhook endpoints in the past 15 days.";
export const fiatCurrency = "FIAT CURRENCY";
export const noDataAvailable = "No data available";
export const emptyCheckoutLinkSubText =
  "Generate checkout link to collect one-time payment";
export const emptySessionSubText = "Check here for all of your sessions";
export const emptyWithdrawalLinkSubText =
  "Generate Withdraw link to make one-time payment.";
export const emptyEventsSubText = "No events here to show you.";
export const emptyWebhookSubText = "No webhook attempts here to show you.";
export const emptyPaymentLinkSubText =
  "Generate a payment link to collect multiple payments on one link";
export const emptyPaymentSubText =
  "Check here for all of your received payments";
export const emptyWithdrawSubText = "Check here for all of your withdraws.";
export const emptyPayoutSubText =
  "Check the amount withdrawn from your Speed balance into your wallet here";
export const emptyTransactionSubText =
  "Check the transaction into or out of your account here";
export const emptyOneQRSubText =
  "Generate a One QR to collect multiple payments for your store";
export const emptyConnectedExtSubText =
  "You haven't give permission to any Speed platform account so far.";
export const emptyTransferSubText =
  "Generate a Transfer to send any amount to any existing Speed account";
export const createTransfer = "Create Transfer";
export const emptySwapSubText =
  "Generate a Swap to maintain  balance in other supported currency.";
export const verified = "Verified";
export const emptyProductSubText =
  "Create products with one-time or recurring prices that are compatible with Checkout links, payment links and invoices.";
export const failed = "Failed";
export const success = "success";
export const fail = "fail";
export const emptyLogsSubText = "No Logs Found";
export const emptyWebhookText = "Let's create a first webhook for your project";
export const reqBodyLabel = (method = "POST") => `Request ${method} Body`;
export const reqParamLabel = "Request query parameters";
export const resBodyLabel = "Response body";
export const HttpStatusCodeLabel = "HTTP status code";
export const RequestEndpointLabel = "Request to your endpoint";
export const type = "Type";
export const name = "Name";
export const value = "Value";
export const createNew = "Create New";
export const checkout = "Checkout";
export const submit = "Submit";
export const subdomainName = "Subdomain Name";
export const domainNameText = "Domain Name";
export const addWalletAddress = "Add Wallet Address";
export const addWallet = "Add Wallet";
export const addNewWallet = "Add New Wallet";
export const addNewWalletSmall = "Add new wallet";
export const receiptFrom = "Receipt from";
export const receipt = "Receipt";
export const amountPaid = "AMOUNT PAID";
export const datePaid = "DATE PAID";
export const paymentDescription = "Payment Decription";
export const chargedAmount = "Charged Amount";
export const summary = "SUMMARY";
export const createPayoutRequest = "Create Payout Request";
export const createInstantPayout = "Create Instant Payout";
export const confirmPayoutRequest = "Confirm Payout Request";
export const proceedBtn = "Proceed";
export const selectWallet = "Select Wallet";
export const verifyWalletAddress = "Verify Wallet Address";
export const walletAddress = "Wallet Address";
export const amountBTC = "Amount (BTC)";
export const revoke = "Revoke";
export const minimumAmountLabel = (module, minimumAmount, currency) =>
  `Minimum ${module} ${minimumAmount} ${currency}`;
export const testEmailReceipt = "Test Email Receipt";
export const supportEmail = "Support Email";
export const emailSpecified = (email) => (
  <>
    Email replies will go to <b style={{ color: "#0a193e" }}>{email}</b> based
    on your
  </>
);
export const quickQuestion = "Quick Questions";
export const littleBitAboutYou = "Tell us a bit about you";
export const skipQuestion = "Skip this question";
export const domains = "Domains";
export const preferences = "Preferences";
export const publicSetting = "Public Settings";
export const fewDetailsQnA =
  "Please provide a few details to help us customize your experience";
export const revokeInvite = "The link has expired";
export const inviteInvalidForEmailMsg =
  "The invite is not valid for this email address.";
export const inviteRevokeMsg =
  "You can no longer join the team on Speed because your invitation has been revoked by your Administrator. If this is a mistake, contact your Administrator and ask them to send you a new invitation.";
export const invitationDiffAccount = (
  inviterEmail,
  email,
  accountName,
  fromAffiliatePartner = false
) => (
  <>
    {inviterEmail} has invited {email} to join the <b>{accountName}</b> team.
    <br />
    However you're currently logged in with a different Speed user.
    <br />
    <br />
    To join the <b>{accountName}</b> team, sign out,
    {fromAffiliatePartner && " and"} login with {email}
    {fromAffiliatePartner
      ? "."
      : ", and re-click the invite link from your invitation email."}
  </>
);
export const updateEmailAddress = "Update email address";
export const requestAccepted = "Request accepted";
export const closureRequestMsg =
  "Your account closure request has been revoked.";
export const dateCreated = "Date created";
export const collectAddresses = "Collect addresses";
export const collectEmailAddress = "Collect email address";
export const collectPhoneNumber = "Collect phone number";
export const dateUpdated = "Date updated";
export const webPageResultLabel = "Web app pages";
export const moreResultsText = "More results";
export const viewAllResultsLabel = ["View", "result", "results"];
export const viewAllLinesLabel = "View all lines";
export const hideLinesLabel = "Hide lines";
export const addNewAccountText = "You can add new account.";
export const chatText = "Chat";
export const scheduleMeetingText = "Schedule a meeting";
export const callText = "Call";
export const emailText = "Email";
export const contactSupportTeam = "Contact support team";
export const contactSuccessManager = "Contact customer success manager";
export const contactUs = "Contact Us";
export const emptyEventsFromSearch =
  "There are no events that match your query";
export const metaDataText = "Metadata";
export const selectType = "Select Type";
export const amountType = "Amount Type";
export const customerChoose = "Customer Choose";
export const addOtherInfoLabel = "Add page title, image and description";
export const pageImageLabel = "Page Image";
export const pageDescLabel = "Page Description";
export const imageLabel = "Image";
export const swapText = "Swap";
export const from = "From";
export const youGot = "You Got";
export const youGet = "You Get";
export const to = "To";
export const exchangeRate = "Exchange rate";
export const swapAmount = "Swap Amount";
export const confirmSwap = "Confirm Swap";
export const merchants = "Merchants";
export const changeCommission = "Change Commission";
export const commission = "Commission";
export const accountID = "Account ID";
export const ID = "ID";
export const startDate = "Start Date";
export const endDate = "End Date";
export const accountOwnerEmail = "Account owner email";
export const totalCommissionEarned = "Total commission earned";
export const latestTransfersIn = "Latest transfers(in)";
export const connect = "Connect";
export const fixedFee = "Fixed Fee";
export const options = "Options";
export const setLimitsLabel = "Set limits";
export const minimumPresetLabel = "Minimum Amount";
export const maximumPresetLabel = "Maximum Amount";
export const confirmTransfer = "Confirm Transfer";
export const transferAmount = "Transfer Amount";
export const paymentAddressText = ["Payment Address", "Payment Addresses"];
export const transfersText = "Transfers";
export const amountOptions = "Amount Options";
export const presetAmountLabel = "Preset Amount";
export const amountLabel = "Amount";
export const detailCustomFieldLabel = "Custom fields";
export const provideEmail = "Require customer to provide email";
export const phoneNumberLabel = "Require customer to provide phone number";
export const collectBillingAddress = "Collect customers' billing address";
export const billingAddressOnly = "Billing addresses only";
export const billingAndShippingAddress = "Billing and shipping addresses";
export const billingText = "Billing";
export const searchByResourceId = "Search by resource ID..";
export const markAsOptional = "Mark as optional";
export const addOptions = "Add Options";
export const editOptions = "Edit Options";
export const addOptionsLower = "Add options";
export const editOptionsLower = "Edit options";
export const optionsLeft = ["option left", "options left"];
export const removeAccount = "Remove Account";
export const removeAccountLabel = "Remove account";
export const accountLabel = "Account";

// Placeholder

export const valuePlaceHolder = "Value";
export const pageDescPlaceholder = "Type description";
export const pageTitlePlaceholder = "Enter title";
export const firstNamePlaceholder = "Enter first name";
export const versionPlaceHolder = "Select Version";
export const lastNamePlaceholder = "Enter last name";
export const multiEmailPlaceholder = "ada@tryspeed.com andy@example.com etc.";
export const languagePlaceholder = "Select Language";
export const timezonePlaceholder = "Select Timezone";
export const dateFormatPlaceholder = "Select Date format";
export const genderPlaceholder = "Select Gender";
export const addressLine1Placeholder = "Address line 1";
export const addressLine2Placeholder = "Address line 2";
export const cityPlaceholder = "City";
export const statePlaceholder = "State";
export const postalCodePlaceholder = "Zip code";
export const enterWebsiteURLPlaceholder = "Enter website url";
export const enterURLPlaceholder = "Enter url";
export const currencyPlaceholder = "Select Currency";
export const messagePlaceholder = "Enter your message";
export const walletNamePlaceholder = "Enter wallet name";
export const addressPlaceholder = (address) => `Enter ${address} address`;
export const secretKeyPlaceholder = "Enter key name";
export const apiEndpointPlaceholder = "e.g. /checkout-sessions";
export const notePlaceholder =
  "Describe this key and the location where it's stored.";
export const turnPaymentMethod =
  "Turn payment methods on or off with one click";
export const subdomainPlaceholder = "checkout.yourdomain.com";
export const searchWalletPlaceholder = "Search wallet";
export const enterAmountPlaceholder = "Enter amount";
export const endpointURLPlaceholder = "https://";
export const webhookDescriptionPlaceholder =
  "An optional description of what this webhook endpoint will used for";
export const searchEvents = "Search events";
export const enterTermsUrlPlaceholder = "Enter terms url";
export const enterPrivacyPolicyUrl = "Enter policy url";
export const key = "Key";
export const domainPlaceholder = "abc.com";
export const select = "Select";

// Button Labels
export const disconnectLabel = "Disconnect";
export const useBrandIcon = "Use logo/icon";
export const brandColor = "Brand Color:";
export const accentColor = "Accent Color:";
export const createCheckoutLinkMsg = "Create Checkout Link";
export const createWithdrawalLinkMsg = "Create Withdrawal Link";
export const addProduct = "Add Product";
export const keySafeText = "Keep your key safe";

export const signup = "Sign up";
export const googleSignup = "Signup Using Google";
export const magicLink = "Magic Link";
export const google = "Google";
export const continueWithGoogle = "Continue with Google";
export const continueWithApple = "Continue with Apple";
export const continueWithEmail = "Continue with Email";
export const backButtonTxt = "BACK";
export const verifyItsYouTitleLabel = "Verify it’s you";
export const verifyItsYouTextMsg = [
  "Enter the 6-digit code sent to your email ",
  "Please use the same 6-digit code, which we have already sent to your email ",
];
export const tryAgainOtpMessage =
  "Error message: We're experiencing temporary technical difficulties with our email OTP service. Please try again in a few minutes.";
export const signInMagicLink = "Use magic link to sign in";
export const turnOn = "Turn on";
export const turnOff = "Turn off";
export const tolerence = "Tolerance";
export const signOutLockedIn = "Sign out and log back in";
export const viewDashboard = "View Dashboard";
export const send = "Send";
export const sendMagicLink = "Send Magic Link";
export const usePassword = "Use Password Instead";
export const useAnotherApp = "Use your authenticator app";
export const useMobile = "Use mobile number";
export const doneBtn = "Done";
export const removeMemberBtn = "Remove Member";
export const sendCode = "Send Code";
export const verify = "Verify";
export const activate = "Activate";
export const deActivate = "Deactivate";
export const deActivated = "Deactivated";
export const paid = "Paid";
export const remove = "Remove";
export const sendReceipt = "Send receipt";
export const sendTestReceipt = "Send test receipt";
export const sendEmails = "Send Emails";

export const editPreference = "Edit Preference";
export const transferOwnershipLabel = "Transfer Ownership";
export const transferAccountOwnership = "Transfer account ownership";
export const paymentFailure = "Payment failure possible";
export const accountOwnershipTransferAlert =
  "Only the account owner can transfer ownership";
export const transferOwnershipMsg = (url) => (
  <>
    You have a verified phone number on file, but the new owner does not. This
    means that any unsafe payments made following the ownership transfer will
    fail. Please have the new owner verify their phone number at{" "}
    <b style={{ color: "#2a67ff" }}>{url} </b>
    to avoid payment failures.
  </>
);
export const invited = "Invited";
export const invitation = "invitation";
export const acceptInvitation = "acceptInvitation";
export const invitationForDiffAccount = "invitationForDiffAccount";
export const invitationExpired = "invitationExpired";
export const acceptedByOther = "acceptedByOther";
export const login = "login";
export const register = "register";
export const invitationRedeem = "invitationRedeem";
export const invitationRedeemForDiffAccount = "invitationRedeemForDiffAccount";
export const invitationRevoke = "invitationRevoke";
export const inviteForDiffAccount = "Invitation for user";
export const welcomeTeam = "Welcome to the team";
export const confirmBtn = "Confirm";
export const addSMSAuth = "Add SMS Authentication";
export const addAppAuth = "Add Authenticator App";
export const closeAccount = "Close Account";
export const upload = "Upload";
export const sendVerification = "Send Verification";
export const yes = "Yes";
export const no = "No";
export const hide = "Hide";
export const reveal = "Reveal Key";
export const secret = "Secret key";
export const publishable = "Publishable key";
export const publishableType = "publishable";
export const secretType = "secret";
export const restrictedType = "restricted";
export const continueDashboard = "Continue to Dashboard";
export const resettingPassword = "Try resetting your password again";
export const preview = "Preview";
export const previewSubMsg = [
  "Change how this page looks in ",
  "Branding settings",
];
export const priceMsg =
  "Price in btc may vary as per exchange rate when customer is paying. This is as per current rate.";
export const previewURL = "checkout.tryspeed.com";
export const viewMore = "View More";
export const viewLess = "View Less";
export const viewAll = "View all";
export const terms = "Terms";
export const privacy = "Privacy";
export const btcAddress = "BTC Address";
export const uploadBrandIcon = "Upload Icon";
export const uploadBrandLogo = "Upload Logo";
export const filter = "Filter";
export const clearFilter = "Clear Filter";
export const clear = "Clear";
export const clearAll = "Clear All";
export const apply = "Apply";
export const addPayoutWalletAddress = "Add payout wallet address";
export const editEvents = "Edit events";
export const copyEventID = "Copy event ID";
export const liveChat = "Live Chat";
export const buttonTextForIdNotFound = (module, liveMode) =>
  `View ${(liveMode && "live") || "test"} mode ${module}`;
export const addAnotherItem = "Add another item";
export const createSwap = "Create Swap";
export const inviteMerchant = "Invite Merchant";
export const commissionWithPercentage = "Commission(%)";
export const fromAccount = "From Account";
export const destinationAccount = "Destination Account";
export const enterAccount = "Enter account";
export const selectAccount = "Select Account";
export const viewPartners = "View partners";
export const createPaymentAddress = "Create Payment Address";
export const addCustomField = "Add Custom Field";
export const accountType = "Account Type";
export const completeSetup = "Complete the setup";
export const setupPW = "Setup payout wallet";
export const verifyPW = "Verify payout wallet";
export const payoutScheduling = "Payout scheduling";
export const setupPayoutScheduling = "Setup payout scheduling";
export const completion = "Completion";
export const setupPWSubtext =
  "Add your onchain or lightning wallet to schedule payout.";
export const verifyPWSubtext = "Manage all of your payout wallets here.";
export const walletAdded = "Wallet added";
export const payoutSchSubText =
  "Easily withdraw your balance by enabling payout scheduling.";
export const startJourney = "Start Speed bitcoin journey";
export const unverified = "Unverified";
// Messages

export const verifyMessage = "Verify your wallet using your registered email";
export const resendVerificationMail = "Resend verification email";
export const readyToFly = "Ready to Fly: Your Setup is Completed!";
export const autoPayoutComplete =
  "You've successfully completed the entire setup process of speed platform.";
export const autoPayoutSetupMsg =
  "Before you start using Speed in live mode, setup";
export const walletAddedSuccessMsg =
  "Your wallet has been added successfully. please check you registered email to verify your wallet.";
export const feesReceivedMSg = "Total application fees received.";
export const staticTextConnect =
  "If you'd like to onboard more users, create more links and share.";
export const staticText2 =
  'Send this standard onboarding link to your user. Once they complete the flow, their account will be connected and will show up in your "Connect" accounts list.';
export const createConnectLabel = "Create a connected account";
export const removeAccountMsg =
  "Are you sure you want to remove this connected account permanently?";
export const customFieldsPopperMsg =
  "Add custom fields to collect additional information from your customers on the payment page.";
export const welcomeMsg = "Welcome to Speed";
export const signUpDescription = () => (
  <>
    Register now & enjoy <b>0% transaction fee</b> for up to 0.5 Bitcoin.
  </>
);
export const signUpText = () => (
  <>
    Unlock the potential of <b style={{ color: "#F9DF00" }}>fast and secure</b>{" "}
    bitcoin payment today!
  </>
);
export const welcomeSubText = () => (
  <>
    Say goodbye to slow and expensive fiat payment processing and hello to
    Bitcoin's <b>Speed</b> and efficiency
  </>
);
export const welcomeSubMsg =
  "Secure bitcoin processing infrastructure for your business";
export const agreeMsg = ["I agree with ", " and "];
export const termsAndPrivacyMsg = ["Terms of service", "Privacy Policy"];
export const invite = (email, teamName) => (
  <>
    You've been invited by <b style={{ color: "#0a193e" }}>{email}</b> to join{" "}
    <b style={{ color: "#848b9e" }}>{teamName}</b> team on Speed. Create an
    account to log in to your new dashboard.
  </>
);
export const dontHaveAccountMsg = "Don't have an account?";
export const haveAccountMsg = "Do you already have an account?";
export const forgotPasswordMsg = "Forgot your password?";
export const resetMsg = "Reset now";
export const newPasswordMsg = "New Password";
export const newPasswordSubMsg =
  "To change your password, enter the new password below.";
export const resetPasswordMsg = "Reset Password";
export const confirmEmailMsg = "Verify your new email";
export const confirmEmailSubMsg = (oldEmail, newEmail) => (
  <>
    We've received a request to change your email address from {oldEmail} to{" "}
    {newEmail}
  </>
);
export const googlePlayStore = "google play store";
export const appStore = "app store";
export const customers = "Customers";
export const customerRequiredMsg = "Please select a customer";
export const posMobile = "posMobile";
export const hiddenText = "Hidden text";
export const tenThousandPlus = "10K+";
export const alreadyDownloadedMsg = "People have already downloaded";
export const confirmEmailSubMsg2 =
  "Please enter your password to confirm this change";
export const subResetPasswordMsg =
  "Enter the email address associated with your account, and we'll send you a link to reset your password";
export const checkEmailMsg = "Check your email";
export const checkEmailLine1Msg =
  "We've sent an email with reset password instruction";
export const checkEmailLine2Msg =
  "If haven't received an email within few minutes then check your spam folder or Resend";
export const checkEmailLine3Msg = "Didn't receive an email?";
export const magicLinkMsg = "Long password? Hard to type?";
export const availableOn = "Available on";
export const subMagicLinkMsg =
  "Enter the email address and we'll send you a magic link to sign in without entering password";
export const walletAdMsg =
  "Embrace the ease of making Bitcoin payments. It's time to experience the true potential of Bitcoin beyond just an investment.";
export const magicLinkSubMsg = (email) => (
  <>
    We've sent an email containing a magic login link to {email} This link can
    only be used once and will expire in an hour
  </>
);
export const emailVerificationMsg = "Email Verification";

export const emailVerifyMsg = (email) => (
  <>
    Please enter 6 digit verification code we've sent you on your registered
    email “{email}”
  </>
);
export const RemoveMemberMsg = (email) => (
  <>
    <b>{email}</b> will no longer be able to access this account.
  </>
);
export const RemoveInvitedMemberMsg = (email) => (
  <>
    This invitation for <b>{email}</b> will be deleted, and they will no longer
    be able to connect to this Speed account.
  </>
);
export const dontAskMsg = "This is trusted device, don't ask again";
export const closureInProgress =
  "Your account closure has been requested and is currently being processed.";
export const passwordConfirmMsg =
  "We need to confirm your password to perform this action.";
export const ownershipTransferMsg = (email) =>
  `Ownership of this speed account will be permanently transferred to ${email}`;
export const setup2faMsg = "Setup Two-Factor Authentication";
export const setup2faSubMsgMobile =
  "We'll text a verification code to this mobile device whenever you sign in to your account.";
export const carrierRateMsg = "Carrier rates may apply";
export const smsAuthSuccessMsg = (actionType) =>
  `Two Factor Authentication ${actionType} Successfully.`;
export const setup2faSubMsgQR = (byQRCode) => [
  "Download the free ",
  ` app, add a new account, ${
    byQRCode
      ? "then scan this barcode to set up your account."
      : "then enter this code to set up your account."
  }`,
];
export const appAuthMsg = [
  "Please enter your 6-digit authentication code from the ",
  "  app",
];
export const googleAuthenticator = "Google Authenticator";
export const smsAuthentication = "SMS Authentication";
export const enterCodeMsg = "Enter code manually";
export const scanQRMsg = "Scan QR Code";
export const userInviteMsg = (org) => (
  <>
    You've been invited to join “<b>{org}</b>” team by{" "}
  </>
);
export const acceptMsg = (accountName, accountCount, link) => (
  <>
    You're now viewing details for <b>{accountName}</b>
    {accountCount > 1 ? (
      <>
        , but have access to {accountCount - 1} other {link}
      </>
    ) : (
      <>.</>
    )}
  </>
);
export const joinTeam = (org) => `Join ${org || ""} Team`;
export const notReceivedEmail =
  "If haven't received an email within few minutes then check your spam folder or";
export const newApiKeyMsg =
  "Save and store this new key to a secure place, such as a password manager or secret store. You won't be able to see it again.";
export const resendVerificationCodeMsg =
  "Verification code sent to your email address";
export const resendForgotPwdLinkMsg =
  "Check your email for instructions to reset your password";
export const accountInfoMsg = "Manage your Speed account information.";
export const accountRestrictionMsg = [
  "Your account permissions are listed here. If you find any restricted modules,",
  "please contact Speed Support to restore access.",
];
export const accountRestrictedTitle = "Account Restricted";
export const checkAccountStatusLabel = "Check Account Status";
export const publicInfoMsg =
  "Manage your Speed public information visible on payment page, invoice etc.";
export const publicInfoUrlErrorMsg = "Please enter valid URL";
export const showPhoneNumberMsg = "Show phone number on receipts";
export const publicBusinessNameInfoMsg =
  "Public Business name may be used on invoices and receipts";
export const activate2FAMsg = "Activate 2FA";
export const activate2FASubMsg =
  "Two-factor authentication significantly improves the security of your account. After you turn it on, signing into your account will require both your password and access to your trusted devices or trusted phone number";
export const team2faTitleMsg = "Require two-step authentication for your team";
export const team2faSubTitleMsg =
  "This will require any team member without two-step authentication to enable it the next time they sign in.";
export const emailAlreadyExist =
  "User already exists. Your request cannot be completed.";
export const inviteInvalid = "This invite is not valid for this email address.";
export const inviteeRedeem = "Invitation is already redeemed.";
export const useSMSMsg = "Use SMS";
export const useAppMsg = "Use an Authenticator App";
export const useSMSAuthMsg = "Use SMS authentication";
export const useAppAuthMsg = "Use an authenticator app";
export const confirm2faMsg = (selectedType) => {
  let sendToText;
  switch (selectedType) {
    case "sms":
      sendToText = "sent to your phone number.";
      break;
    case "authenticator":
      sendToText = "generated by Google Authenticator.";
      break;
    default:
      return;
  }
  return (
    <>
      From now on, whenever you sign in to your account, you'll need to enter
      both your password and also an authentication code {sendToText}
    </>
  );
};
export const closeAccountCheckAcceptMsg =
  "I accept the terms and conditions stated above.";
export const enabled2faMsg = "Successfully enabled";
export const verificationCode = "Verification code";
export const passwordSuccess = "Your password has been successfully changed";
export const closeAccountTitleMsg =
  "Are you sure you want to close the account?";
export const eventBtnText = (liveMode) =>
  `View ${(liveMode && "live") || "test"} mode events`;
export const eventSubTitleMsg = (id) => `Event ${id} is not found.`;
export const updateSuccessMsg = (pageName, isMultiDetailPage = false) =>
  `Your ${pageName} ${
    isMultiDetailPage ? "have" : "has"
  } been saved successfully`;
export const changeEmailTitle = "Change Email Address";
export const AddMemberTitle = "Invite member";
export const editMemberTitle = (name) => `Edit Role for "${name}"`;
export const removeMemberTitle = "Remove member from team";
export const removeInvitee = "Remove Invitee";
export const invitee = "invitee";
export const resendInvitee = "Resend Invitation";
export const changeEmailText =
  "Enter new email address that you want to associated with your account";
export const verificationEmailSentTitle = "Verification email sent";
export const verificationEmailSentText = (resendLink) => (
  <>
    {" "}
    If haven't received an email within few minutes then check your spam folder
    or {resendLink}{" "}
  </>
);
export const testModeText =
  "You're in test mode, so your customer won't be charged for this payment link";
export const testModeTextForCheckout =
  "You're in test mode, so your customer won't be charged for this checkout link";
export const testModeTextForWithdrawal =
  "You're in test mode, so your customer won't receive any amount for this withdraw link.";
export const testModeTextForPayout =
  "Your payout request won't be processed because you are in test mode.";
export const testModeTextForProduct =
  "You're in test mode, so your customer won't see this product";
export const testModeTitle = "Test Data";
export const signOutAllSessionTitle = "Sign out all sessions";
export const verifyItsYouTitle = "Make sure it's you";
export const verifyItsYouText =
  "We need to confirm your password to perform this action.";
export const confirmationPageTitle = "Confirmation Page";
export const customMessageError =
  "Enter a valid custom message or use the default confirmation page";
export const verifyEmailMsg = "Verify your email";
export const verifyEmailSubMsg1 =
  "Your email address is not verified, please verify your email before sign in to you Speed account";
export const verifyEmailSubMsg2 =
  "If haven't received an email within few minutes then check your spam folder or resend it.";
export const successMsg =
  "Congratulations, We have successfully verified your email address.";
export const resendVerificationMsg =
  "Verification link sent to your email address";
export const expiredLinkMsg = "This email verification link has expired";
export const inviteRedeem = "The link has already been redeemed.";
export const invalidUser = "Invalid User";
export const userRemovedSuccessMsg =
  "User has been removed from account successfully.";
export const expiredLinkSubMsg =
  "If you haven't been able to verify your email, you can try resending again";
export const linkAlreadyVerifiedMsg = "Email Address Verified";
export const linkAlreadyVerifiedSubMsg = [
  "You are all set, Your email ",
  "is already verified",
];
export const passwordLinkExpired = "This password reset link has expired";
export const passwordLinkExpiredSubMsg =
  "If you haven't been able to recover your password, you can try resetting your password again";
export const priceDisclaimerText =
  "BTC rates are calculated in realtime and may vary";
export const paymentConfirmationMessage =
  "A payment to Speed will appear on your statement";
export const ThanksPaymentMessage = "Thanks for your payment";
export const statementDescriptor = (
  accountName,
  isFromWithdrawalLink = false
) => `Payment ${isFromWithdrawalLink ? "from" : "to"} ${accountName}`;
export const entityNotFoundMessage = (module) => `${module} not found`;
export const addDomain = "Add Domain";
export const verifyDomainHeader = (domainName) =>
  `Domain verification for "${domainName}"`;
export const checkRecords = "Check Records";
export const instructionForProviderUrl = `${process.env.REACT_APP_SPEED_DEV_URL}docs/business-operations/custom-domain/custom-email-domain#instructions-for-popular-providers`;
export const close = "Close";
export const removeDomain = (domain_name) => `Remove "${domain_name}"`;
export const sendingDomain = (domain_name) =>
  `Start sending emails from "${domain_name}"`;
export const removeDomainBtn = "Remove Domain";
export const changeDomainBtn = "Change Domain";
export const sending = "Sending";
export const domain = "Domain";
export const testEmailSent = "Test email sent";
export const domainVerified = "Domain Verified";
export const testEmailSuccess = "Test email has been successfully sent to";
export const domainVerifySuccess =
  "Your domain has now been validated. Begin by sending a test email and set it as your sending domain in order to send payment receipts to customers using this domain!";
export const sendTestEmail = "Send Test Email";
export const emailAddress = "Email Address";
export const sendTestEmailTitle = "Send a test email from";
export const domainVerification = "Domain verification for";
export const viewDnsRecords = "View DNS Records";
export const setAsSendingDomain = "Set As Sending Domain";
export const removeDomainContentMsg =
  "If you remove this domain, you will no longer need the associated DNS records. You can remove them from your domain provider.";
export const sendingDomainMsg = (currentDomain, domainToBeSet) => (
  <>
    All customer emails will be sent from <b>{domainToBeSet}</b> instead of{" "}
    <b>{currentDomain}</b>. The email address prefix will remain the same. For
    example, emails previously sent from <b>receipts@{currentDomain}</b> will
    now send from <b>receipts@{domainToBeSet}</b>
    <br />
    <br />
    Replies will still go to <b>{domainToBeSet}</b> based on your
  </>
);
export const accountSetting = " Account Settings";
export const recordsNotConfirmedSubMsg = `instructions for your provider`;
export const recordsNotConfirmed = (noOfRecords, component) => (
  <>
    {noOfRecords} records have not been confirmed. If you just added these
    records, it might take a few minutes for your changes to take effect. If you
    continue having issues, review the {component} and make sure the DNS records
    are entered as shown.
  </>
);

export const domainNameInvalid = "Please enter valid domain name";
export const validationMsgHeading =
  "Trying to add a domain name similar to any of the following invalid domain names?";
export const validationMsgArr = [
  "With numbers in its suffix or with suffix only - e.g. example.co12m, example.",
  "With special characters or hyphen - e.g. exampl#e.com",
  "With hyphen at the start - e.g. -example.com",
  "With more than 3 levels - e.g. example.co.com.gov",
];
export const closeAccountSubtitles = [
  "You are requesting the closure of your account. The Speed team will get in touch with you for a fund transfer and other feedback, and the procedure could take up to 15 days.",
  "You can revoke an account closure request any time.",
  "You can still send and receive payments, even after requesting account closure.",
  "Once an account is marked as \"closed\", you will no longer be able to access any of its information, as Speed doesn't keep any copies or backups of your account's data afterwards",
  "Also, you won't be able to send or receive any messages that are already in progress and create new requests.",
];
export const validationMsg =
  "To proceed further, please add a valid domain name.";
export const emailPlaceholder = "and@example.com";

export const addMoreDomainValidation =
  "You can have only 3 domains at a time. Please remove unused domain to add new one.";
export const domainDisconnectMsg = (domainName) => (
  <>
    This means you can no longer use <b>{domainName}</b> for payment pages on
    Speed. If you want to use it again, you'll need to repeat all the steps to
    connect it.
  </>
);
export const disconnectModalHeader = "Disconnect custom domain?";
export const disconnectDomain = "Disconnect Domain";
export const connectDomain = "Connect Domain";
export const dnsRecordModalHeader = "Instructions to connect your domain";
export const defaultDomain = "Default domain";
export const setupDNSRecord =
  "Set up your DNS records (may take up to 1 hour to verify)";
export const customDomainConnected =
  "Your custom domain is connected and ready to use.";
export const customDomainActive =
  "Your customer sees this domain during checkout";
export const verifyDomain = "Verify Domain";
export const readInstruction = "Read Instruction";
export const connectCustomDomain = "Connect Custom Domain";
export const addCustomDomain = "Add Custom Domain";
export const step1 = ["Sign in to ", " (your domain provider)"];
export const step1Msg = "Sign in to your domain provider";
export const step2 = "Go to your DNS settings";
export const instructions = "Read full Instruction";
export const addFollowingRecords =
  "Add the following records to your DNS settings:";
export const payoutWalletDescription =
  "Please add wallet addresses for payout.";
export const payoutReqDetails = "Payout Request Details";
export const payoutDetails = "Payout Details";
export const balanceAvailableForModule = (module) =>
  `Balance available for ${module} is`;
export const payoutReqSummary = "Payout Request Summary (BTC)";
export const reqPayoutAmount = "Requested send amount";
export const approxNetFee = "Approximate network fee";
export const approxNetPayout = "Approximate net payout";
export const importantNote = "Important note";
export const payoutConfirmationNote =
  "Transaction fees would be deducted from your speed account balance after payout.";
export const agreement = "I agree with the";
export const netPayoutAmountNote = "Net Payout Amount is approximate";
export const searchResultForText = (isModuleResultPage = false) =>
  `Search results${isModuleResultPage ? ":" : " for"}`;
export const noSearchResultfound = "No search results found";
export const addWebhookEndpoint = "Add Endpoint";
export const selectEvents = "Select events";
export const confirmDetails = "Confirm Details";
export const selectEventsToSend = "Select events to send";
export const selectAllEvents = "Select all events";
export const listenToSpeedEvents = "Listen to Speed events";
export const webhookDeactivationMessage = () => (
  <>
    This webhook endpoint may be temporarily deactivated so that it will not
    receive notifications until it is activated again.
    <br /> Speed will not retry any notifications that are generated while the
    endpoint is deactivated.
  </>
);
export const webhookActivationMessage = () => (
  <>
    This webhook is deactivated and no longer receives notifications. You can
    re-activate the webhook endpoint here.
    <br /> Speed will not retry any notifications that were generated in the
    intervening period.
  </>
);
export const noWebhookSubText = "Let’s create a first webhook for your project";
export const noWebhookText = "No Webhooks";
export const details = "Details";
export const signingSecret = "Signing Secret";
export const revealText = "Reveal";
export const webhookSetupMessage =
  "Set up your webhook endpoint to receive live events from Speed or";
export const learnAboutWebhookMessage = "learn more about webhooks.";
export const shareSuggestionMsg = [
  "Share a suggestion",
  "Have a suggestion on how to improve Speed?",
];
export const connectingMsg = "Connecting...";
export const subtitleForIdNotFound = (module, id) =>
  `${module} ${id} is not found.`;
export const swapDetailsText = "Swap Details";
export const swapSummary = "Swap Summary";
export const inviteText = "Invite";
export const memberEmail = "Member Email";
export const transferDetailsText = "Transfer Details";
export const transferSummary = "Transfer Summary";

// Validation Messages
export const onchainEstimateMsg = `We are unable to process your "onchain" instant payout temporarily. Please try after sometime.`;
export const sendMaxMsg =
  "Transaction cannot proceed due to fees. Please keep some SATS for routing fees.";
export const imageIconMsg = "Upload a JPEG or PNG image that is less than 2MB";
export const limitForMultiEmail = (limit = 5) =>
  `You can only invite a maximum of ${limit} emails at once`;
export const upperValidate = "Must contain one uppercase letter";
export const lowerValidate = "Must contain one lowercase letter";
export const numberValidate = "Must contain one number";
export const confirmPasswordValidate =
  "This password doesn't match. Please try again";
export const postalCodeValidate = "This postal code is invalid";
export const urlValidate = "It must be a valid URL";
export const domainValidate = "It must be a valid domain";
export const urlWithHttpValidate =
  "It must be a valid URL, such as http:// or https://";
export const productNameLength =
  "Product name can't be more than 500 characters";
export const productQty = "Product quantity should be between 1-999";
export const productPrice = "Product price should be above 0";
export const keyLength = "Key can't be more than 255 characters";
export const descriptionLength =
  "Description can't be more than 255 characters";
export const qtyLimit = "You can't add more than 25 items";
export const invalidWalletAddress =
  "Wallet address is invalid. Please enter the correct address.";
export const validPrice = "Please enter valid price";
export const invalidLabel = (label) =>
  `Please enter valid ${label.toLowerCase()}`;
export const invalidAmount =
  "Integers and fractions can have up to 16 digits value only";
export const invalidExchangeRate = (currencyCode, linkType) =>
  `${currencyCode} currency support is currently unavailable, you can still create a ${linkType} using another supported currency including BTC or SATS`;
export const customDomainSubMsg = [
  "Custom domains only work for ",
  "server-side ",
  "redirect integrations.",
];
export const subdomainContentMsg =
  "If you have your own domain, enter the specific subdomain you want to use for Checkout. For example, if you own powdur.com, your subdomain could be checkout.powdur.com";
export const paymentDomainContentMsg =
  "If you have your own domain, enter the specific subdomain you want to use for Payment Address.";
export const subdomainContentLink = [
  "Your custom domain may be rejected if it violates our ",
  "Prohibited and Restricted Businesses ",
  "terms.",
];
export const domainNameValid =
  "Your subdomain must have at least 3 parts (ex: checkout.yourdomain.com)";
export const moduleAmountQty = (minimumAmount, maxAmount, module, currency) =>
  `${module} amount should be between ${minimumAmount} - ${maxAmount} ${currency}`;
export const payoutWalletDeactivation =
  "There could be some payout requests associated with the given wallet address. So right now, we can not fulfill your request.";
export const satsValidationMsg =
  "Decimal are not allowed when selected currency is SATS";
export const emptyMetaDataKeyValue = (key) => `Metadata ${key} cannot be blank`;
export const uniqueMetaDataKey = "Metadata keys must be unique";
export const swapFullAmount = "Swap full amount";
export const transferUniqueDestinationAccount =
  "The destination account must be different from the sender's account";
export const transferValidDestinationAccount =
  "The destination account must be valid";
export const accountAlreadyAffiliated =
  "Another affiliate partner is already connected to this account.";
export const affiliatePartnerBenefitsHeader =
  "We're excited to have you join Speed through our valued affiliate partner. As you already have a Speed account, we want to highlight the benefits you'll enjoy by affiliating with our partner:";
export const affiliatePartnerBenefits = "Affiliate partner program Benefits";
export const minLessThanMax = "Minimum amount must be less than maximum.";
export const uniqueAmountValue =
  "Invalid value. Please ensure all amount values are unique.";
export const uniqueOptionsValue =
  "Invalid value. Please ensure all values are unique.";
export const partnerCommissionPercentageValidation = (min, max) =>
  `The commission percentage must fall within the range of ${min} to ${max}.`;
export const maxOptionLengthValidation =
  "Option label must be at most 50 characters.";

// Hello Bar Messages
export const helloBarDescription =
  "Claim your FREE invoice generator tools for your business today";
export const helloBarBtnText = "Claim Now";

// Header Account Selection Messages
export const newAccountText = "New Account";
export const switchingAccountText = "Switching accounts...";
export const transferingOwnershipText = "Transferring Ownership...";

export const signoutAllText =
  "This action will log you out of your Speed account on all devices where you are currently logged in. Are you certain you want to sign out of all sessions, including the current one?";
export const cancelInviteRequestText = (email) => (
  <>
    This invitation for <u>{email}</u> will be deleted, and they will no longer
    be able to connect to this Speed account.
  </>
);

export const moduleAmountMoreThanBalance = (module) =>
  `Available balance is less than ${module} amount`;

// Log API Details label

export const status = "Status";
export const account = "account";
export const apiVersion = "API Version";
export const reqId = "Request ID";
export const source = "Source";
export const time = "Time";
export const ipAddress = "IP Address";
export const origin = "Origin";
export const date = "Date";
export const method = "Method";
export const receivedOnLabel = "Received On";

// Restricted Keys
export const yourNewApiKey = "Your new API key";
export const viewDocs = "View docs";
export const keyNameError = "Key Name is required";
export const selectOneResourceType = "You must select at least one permission";
export const keyName = "Key name";
export const create = "Create";
export const createRestrictedKey = "Create Restricted Key";
export const editRestrictedKey = "Edit Restricted Key";
export const keyDetails = "Key Details";
export const detailsLabel = "Details";
export const editKey = "Edit Key";
export const deleteKey = "Delete Key";
export const restrictedKeys = "Restricted Keys";
export const emptyRestrictedKeysSubText = "No keys available";
export const deleteApiKeyText =
  "You will be unable to perform any more actions with this key.";
export const deleteApiKeyTitle = "Delete API Key";
export const dateCreatedOn = "Date created on";
export const readAndWrite = "Read and Write";
export const readWriteTextSymbol = "Read & Write";
export const noPostBody = (method) => `No ${method} body`;
export const noQueryParameters = "No query parameters";
export const noResponseBody =
  "We do not save the response body for successful GET requests.";

// Product
export const edit = "Edit";
export const deleteText = "Delete";
export const archive = "Archive";
export const unArchive = "Unarchive";
export const cancel = "Cancel";
export const deleteProduct = "Delete Product";
export const deletePrice = "Delete Price";
export const deleteModalMessage =
  "Are you sure you want to delete this product? This can't be undone.";
export const deletePriceModalMessage =
  "Are you sure you want to delete this price? This can't be undone and may cause issues with existing integrations.";
export const deleteProductErrorMessage =
  "This product cannot be deleted because it has one or more user created prices associated to it.";
export const noProductFound = "No such product found ";
export const archiveProduct = "Archive Product";
export const unarchiveProduct = "Unarchive Product";
export const archivePrice = "Archive Price";
export const unarchivePrice = "Unarchive Price";
export const archiveModalMessage =
  "Archiving will hide this product from new purchases. Are you sure you want to archive this product?";
export const archivePriceModalMessage =
  "Archiving will hide this price from new purchases. Are you sure you want to archive this price?";
export const addNewProduct = "Add New Product";
export const productDetails = "Product Details";
export const productPhoto = "Product Photo";
export const uploadImageInfo =
  "Upload JPEG or PNG Image should be less than 2 mb";
export const productName = "Product Name";
export const productNamePlaceHolder = "Enter product name";
export const productDescription = "Product Description";
export const pricingInfo = "Pricing Information ";
export const pricingDetail = "Pricing Details";
export const pricingModal = "Pricing Model";
export const duplicate = "Duplicate";
export const selectModalPlaceholder = "Select Model";
export const addAnotherPrice = "Add Another Price";
export const editPrice = "Edit Price";
export const pricingType = "Pricing Type";
export const product = "Product";
export const noPrices = "No Prices";
export const productLinkNotFoundSubtitle = (id) =>
  `Product ${id} is not found.`;
export const productLinkNotFoundBtnTxt = (liveMode) =>
  `View ${(liveMode && "live") || "test"} mode products`;
export const priceNotFound = (id) => `Price ${id} is not found.`;

// Manage apps
export const removeApp = "Remove App";
export const RemoveAppMsg = () => (
  <>
    If you are removing this app, you will no longer be able to acess data from
    this app. Are you sure you want to remove this app?
  </>
);
export const addStoreDetailsAndAuthenticate =
  "Add Store Details & Authenticate";
export const addDetailsToYourStore =
  "Add following details to your shopify store";
export const storeName = "Store Name";
export const storeUrl = "Store URL";
export const enterStoreUrlSuch =
  "Enter your store URL such as {yourstore}.myshopify.com.";
export const accessToken = "Access Token";
export const enterAccessToken = "Enter Access Token";
export const enterStoreName = "Enter Store Name";
export const enterStoreUrl = "Enter Store Url";
export const shopifyInstallation = "Shopify Installation";
export const shopify = "Shopify";
export const wooCommerce = "WooCommerce";
export const installed = "Installed";
export const unInstalled = "Uninstalled";
export const yetToBeInstalled = "Yet to be installed";
export const addNewApp = "Add New App";
export const contactSupport = "Contact Support";
export const completeInstallation = "Complete Installation";
export const goToYourText = "Go to your";
export const shopifyAdminToSettingDirection = "Shopify admin › Settings";
export const shopifyGoToNotification =
  "Shopify admin › Settings › Notifications";
export const subscribeWebhook = "Subscribe Webhook";
export const selectEventCreation = "Select “Order creation” in event dropdown.";
export const selectJsonFromDropdown = "Select “JSON” in format dropdown.";
export const copyUrlForShopifyStoreMsg =
  "Copy the above URL and add to your shopify store";
export const eventField = "Event";
export const format = "Format";
export const enterFormat = "Enter Format";
export const url = "URL";
export const webhookApiVersion = "Webhook API version";
export const enterEventName = "Enter Event Name";
export const orderCreation = "Order creation";
export const json = "JSON";
export const apiVersionText = "YYYY-MM (Latest)";
export const addWebhookSecretKey = "Add Webhook Secret Key";
export const webhookSecretKey = "Webhook secret key";
export const enterWebhookSecretKey = "Enter webhook secret key";
export const shopifyAdminToPaymentsViaSettings =
  "Shopify admin › Settings › Payments";
export const addPaymentMethod = "Add Payment Method";
export const customPaymentMethodName = "Custom payment method name";
export const speedPayment = "Bitcoin via Speed";
export const paymentMethodNameText =
  "You can change the name of payment method, but it should be same as what you have mentioned in Shopify manual payment method. Payment email will only go if payment method name is same.";
export const additionalDetails = "Additional details";
export const additionDetailsOnManualPayments = (paymentMethodName) => {
  return `When you use "${paymentMethodName}", a link to make payment will be sent to your email address.`;
};
export const additionalDetailsOnManualPaymentsHint =
  "Displays to customers when they're choosing a payment method.";
export const paymentInstructions = "Payment instructions";
export const paymentInstructionsOnManualPayments =
  "You must have received an email with a payment link in order to make the payment. Once you make the payment, your order will be confirmed.";
export const paymentInstructionsOnManualPaymentsHint =
  "Displays to customers after they place an order with this payment method.";
export const add = "Add";
export const testConnection = "Test Connection";
export const finishInstallation = "Finish Installation";
export const storeDetailsMsg =
  "Store details like name, url & authentication token";
export const finalInstallationHoverText =
  "Before enabling the store to verify integration, test the connection first.";
export const enterPaymentMethodName = "Enter Payment Method Name";
export const noApps = "No Apps";
export const noAppsAvailable = "No Apps Available";
export const ccdDNSName = "Name";
export const ccdDNSTtl = "TTL";
export const ccdDNSType = "Type";
export const ccdDNSValue = "Value";
export const viewOneQR = "View One QR";
export const download = "Download";
export const latestPayment = "Latest payment";
export const testModeTextForOneQR =
  "You're in test mode, so your customer won't be charged for this One QR";
export const createOneQRMsg = "Create One QR";
export const QRTypeLabel = "One QR Type";
export const oneQRLabel = "One QR Name";
export const oneQRDescription = "One QR description";
export const oneQRDescriptionLabel = "Description(optional)";
export const showPayViaLabel = "Show Pay Via";
export const payVia = "Pay via";
export const viewOneQRTitle = "View One QR";
export const oneQR = "One QR";
export const deActivateOneQR = "Deactivate One QR";
export const deactivateModalBodyText =
  "If you deactivate this One QR, you will lose the same QR and will need to create a new One QR. Are you sure you want to deactivate?";
export const defaultLnValue =
  "lntb80n1p32rte6pp5wkyjvhy94v276upew5te9csxjw5raw83wjderehffdj99898dfsdfdfdsfewrp28y72nwpjk2epqf35k66t5v4jzqcm0d4cxzmnecqzpgxqzjcsp5my747celzphtw7udwlmfzt64ny0fpmpvy76jpqw2qh7kpku07t4s9qyyssq8vgfzewtdsjau0tqgqfvfauju7n9hynchw0felv77d8z9h7nk69shlc0n2yh3yd3xkj44xx8h3t9u6femfe45dvrr6md6k60zsj2gfsq36y6ty";
export const defaultOnchainValue =
  "tb1qzt5lnfd22yc7czrel3c567rndq4vkgzg97swa8h";
export const showSuccessMessage = "Show success message";
export const successMessage = "Success Message";
export const successURL = "Success URL";
export const defaultSuccessMessage =
  "Your payment via lightning network is done successfully.";
export const websiteURLError = "Enter website URL";
export const oneQRNameRequired = "One QR Name is a required field";
export const viewLabel = "View";
export const redirectionWebsiteInfoText =
  "(Upon successful payment, this URL will be displayed, but the user won't be actually redirected there.)";
export const viewBalance = "View Balance";
export const recentPayments = "Recent Payments";
export const recentPayouts = "Recent Payouts";
export const recentWithdrawals = "Recent Withdraws";
export const recentTransactions = "Recent Transactions";
export const accountBalance = "Account Balance";
export const transactionChart = "Day-wise Transactions";
export const payments = "payments";
export const payouts = "payouts";
export const transactions = "transactions";
export const withdraws = "withdraws";
export const pendingPayoutsBalance = "pendingPayoutsBalance";
export const balance = "balance";
export const transactionSummary = "transactionSummary";
export const passwordProtected = "Make this link password protected";
export const sendViaEmailLabel = "Send withdraw link via email";
export const enterYourNamePlaceholder = "Enter your name";
export const passwordProtectedInfo =
  "This will be your default password. Click to change it.";
export const sendViaEmailInfo =
  "The withdrawal link will be sent to the email address you provided.";
export const enterPassword = "Enter Password";
export const withdrawPreviewURL = "withdraw.tryspeed.com";
export const withdrawalLinkFormInfo =
  "Withdrawals are subject to a lightning network fee, which is deducted from your balance.";
export const withdrawalLinkPriceMsg =
  "Price in btc may vary as per exchange rate when customer is receiving. This is as per current rate.";
export const withdrawalLinkHeading = "Withdrawal Link";
export const withdrawalLinkTabLabel = "Withdrawal link";
export const copyPassword = "Copy Password";
export const copied = "Copied";
export const password = "Password";
export const setPassword = "Set Password";
export const notStartedYet = "not started yet";
export const loadingMsg = "loading";
export const allowInstallApp = "Do you want to install the app?";
export const allow = "Allow";
export const allowAppInstallation = "Allow App Installation";
export const verfiying = "Verifying..";
export const resolveIssue = "Resolve issue";
export const shopifyPermissionOauthText =
  "Speed-Shopify is requesting permission to access the Speed account.";
export const downArrowIcon = "downArrowIcon";
export const rightArrowIcon = "rightArrowIcon";
export const authorizeAppWithSpeedText = "Authorize your app with Speed";
export const requestGrantPaths = [
  "/oauth/request_grant",
  "/affiliate-partners/accept-invite",
];
export const walletPath = ["/my-assets"];
export const autoGeneratePasswordText = "Auto-generate password";
export const modules = "Modules";
export const write = "Write";
export const read = "Read";
export const none = "None";
export const _read = "_read";
export const _write = "_write";
export const bucketText = "bucket";
export const resourceText = "resource";
export const addDescription = "Add description";
export const descriptionError = "Description is required";
export const currentVersionText = "Your current version";
export const latestVersionText = "Latest API version";
export const withdraw = "Withdraw";
export const captchaCodeLength = 6;
export const captchaBlockBGColor = "#f7fafc";
export const captchaCodeLabel = "Captcha Code";
export const captchaCodePlaceholder = "Enter Captcha Code";
export const invalidCaptchaCode = "Invaid Captcha Code";
export const paymentDomainPreviewURL =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "tryspeed.com"
    : "tryspeed.dev";
export const copyAndShareInviteLink = "Copy and share invite link";
export const progressText = "Progress:";

//invoices
export const createNewInvoice = (forHeader = false) =>
  `Create ${forHeader ? "New" : ""} Invoice`;
export const testModeTextForInvoice =
  "You're in test mode, so your customer won't be charged for this invoice";
export const emptyInvoiceSubText = "Check here for all of your invoices";
export const updateInvoiceStatus = "Update Status";
export const addInternalNote = "Add internal note";
export const changeInvoiceStatusLabel = "Change Invoice Status";
export const sendInvoice = "Send Invoice";
export const emailSentToastMsg = "Email has been sent!";
export const finalizeInvoiceMsg = "Finalize Invoice";
export const continueEditingLabel = "Continue Editing";
export const timelineLabel = "Timeline";
export const addNotes = "Add notes";
export const addNote = "Add Note";
export const addNotePlaceholder = "Add your note";
export const invoiceNotePlaceholder = "Note here";
export const invoicePdf = "Invoice PDF";
export const billToTitle = "Bill to";
export const billFromTitle = "Bill From";
export const viewPriceDetails = "View Price Details";
export const viewProductDetails = "View Product Details";
export const dateFinalisedTitle = "Date finalised";
export const paymentPageTitle = "Payment page";
export const idTitle = "ID";
export const memoTitle = "Memo";
export const memoPlaceHolder = "Enter Memo Message";
export const invoice = "Invoice";
export const invoiceNumber = "Invoice number";
export const dateOfIssue = "Date of issue";
export const dateOfDue = "Date of due";
export const payOnline = "Pay Online >";
export const billFrom = "BILL FROM";
export const billTo = "BILL TO";
export const shipTo = "SHIP TO";
export const historyLabel = "History";
export const paymentsLabel = "Payments";
export const customerLabel = "Customers";
export const customerPlaceHolder = "Find or add a Customer...";
export const addNewCustomer = "Add new customer";
export const addNewCustomerModal = "Add New Customer";
export const addAdditionalDetails = "Add Additional Details";
export const saveCustomerLabel = "Save Customer";
export const switchCustomer = "Switch Customer";
export const switchingCustomerCautionMsg =
  "Switching customer will remove all items from the invoice. You will have to manually add them again.";
export const itemLabel = "Items";
export const itemRequireMsg = "Please add at least one line item";
export const invoiceAmountExceededMsg =
  "An invoice for an amount more than 5 BTC cannot be created.";
export const addItemLabel = "Add Item";
export const itemPlaceHolder = "Find or add an Item";
export const addOneTimeItem = ["Add", "one-time line item"];
export const addInvoiceNewProduct = ["Create", "new product"];
export const itemDetailLabel = "Item Details";
export const itemOptionLabel = "Item Options";
export const updateLabel = "Update";
export const switchingCurrencyCautionMsg =
  "Switching currency will remove all items from the invoice. You will have to manually add them again.";
export const saveAndAdd = "Save and add another";
export const dueDateLabel = "Due Date";
export const deliveryLabel = "Delivery";
export const includeLinkMsg = "Include link to online payment page";
export const includePaymentPageTooltipMsg =
  "The payment page allows your customer to pay using their preferred payment methods.";
export const includeRecipientsMsg = "Include additional recipients";
export const doNotEmailInstructionMsg =
  "A PDF and online payment page will be generated in the system, and the user can then share these manually with customer as needed.";
export const addEmailLabel = "Add Email";
export const additionalOptionLabel = "Additional Options";
export const footerPlaceHolder = "Enter Footer Message";
export const customFieldLabel = "Custom fields";
export const customFieldTooltipMsg =
  "Custom fields let you include extra information, such as a purchase order number (PO), in the header of an invoice PDF.";
export const doNotSendRemindersLabel = "Do not send reminders";
export const doNotSendRemindersTooltipMsg =
  "Customers will not receive any reminders for this invoice.";
export const customFieldKeyPlaceholder = "Field name (ex. GST number)";
export const customFieldValuePlaceholder = "Field value";
export const emptyCustomFieldKeyValue = (type) =>
  `Custom Field ${type} cannot be blank`;
export const uniqueInvpoceCustomField = "Custom fields keys must be unique";
export const addCustomFieldLabel = "Add Custom Field";
export const deleteDraftLabel = "Delete Draft";
export const deleteDraftContentMsg = [
  `Delete Draft Invoice`,
  `Are you sure you want to permanently delete this draft invoice?
This can't be undo.`,
];
export const invoiceFrom = (accountName) => `New invoice from ${accountName}`;
export const payThisInvoice = "Pay this invoice  > ";
export const due = "Due";
export const totalExcludingTax = "Total excluding tax";
export const learnAbout = "Learn more about";
export const speedCryptoInvoicing = "Speed Crypto Invoicing";
export const invoicePaymentPage = "Invoice payment page";
export const branding = "Branding";
export const invoiceBrandingMsg =
  "Change how your invoice emails, receipts, and payment page look in ";
export const businessInfo = "Business Information";
export const businessInfoMSg = [
  "Change the contact information that shows up on your invoices and receipts in ",
  "Business settings.",
];
export const invoiceNumbering = "Invoice Numbering";
export const invoiceRadioButtonMsg = "Sequentially across your account";
export const invoiceRadioButtonMsg1 = "Sequentially for each customer";
export const invoiceRadioButtonMsg2 = "Sequentially across your account";
export const customerRadioBtnValue = "customer";
export const accountRadioBtnValue = "account";
export const invoicePrefix = "Invoice prefix";
export const invoiceLiveSequence = "Next live invoice sequence";
export const invoiceTestSequence = "Next test invoice sequence";
export const invoicePDFs = "Invoice PDFs";
export const invoicePDFMsg =
  "Include PDF links and attachments on invoice emails and payment page";
export const invoicePDFSubMsg =
  "Customers can click these links to download PDFs of invoices, receipts, and credit notes.";
export const defaultMemo = "Default Memo";
export const defaultMemoSub =
  "This will appear in the invoice email, PDF, and payment page.";
export const defaultFooter = "Default Footer";
export const defaultFooterSub =
  "This will appear in the invoice and receipt PDFs.";
export const defaultPaymentTerms = "Default Payment Terms";
export const defaultReminderTitle = "Default Reminder";
export const addReminder = "Add reminder";
export const daysAfterInvoiceSent = "days after invoice is sent";
export const daysAllow = (days, operator) =>
  `Days can't be ${operator} than ${days}`;
export const emptyDays = "Days cannot be blank";
export const invoicePreviewURL = "invoice.tryspeed.com";
//cashbacks
export const emptyCashbackSubText = "No cashback available";
export const cashbackNameLabel = "Cashback Name";
export const cashbackStartDateLabel = "Start Date";
export const cashbackEndDateLabel = "End Date";
export const cashbackAmountLabel = "Cashback Amount";
export const cashbackPercentageLabel = "Cashback Percentage";
export const selectCashbackLabel = "Select cashback";
export const createNewCashback = "Create new cashback";
export const cashbackExpireInLabel = "Expire in days";
export const cashbackDescriptionPlaceholder = "Type description here";
export const cashbackExpireInPlaceholder = "Select days";
export const cashbackAmountPlaceholder = (isPercentage = false) =>
  isPercentage ? "Percentage" : "Amount";
export const cashbackDatePlaceholder = (isStartDate = false) =>
  `Select ${isStartDate ? "start" : "end"} date`;
export const cashbackNameRequired = "Cashback Name is a required field";
export const cashbackAmountRequired = (isPercentage = false) =>
  `Cashback ${isPercentage ? "Percentage" : "Amount"} is a required field`;
export const cashbackAmountRangevalidation = (isPercentage = false) =>
  `Cashback ${isPercentage ? "Percentage" : "Amount"} should be between ${
    isPercentage ? "0.01%" : "1 SATS"
  } to ${isPercentage ? "98.99%" : "1 BTC"}`;
export const cashbackDateRequired = (isEndDate = false) =>
  `Cashback ${isEndDate ? "End" : "Start"} Date is a required field`;
export const addCashbackMsg = "Add cashback";
export const expireInCashbackMsg =
  "Once created, cashback is valid by default for 7 days or until specified days.";
export const enterValidDateMsg = (isEndDate = false) =>
  `Please select a valid ${isEndDate ? "End" : "Start"} Date`;
export const enterValidPastDateMsg = (isEndDate = false) =>
  `Please select a ${
    isEndDate ? "End" : "Start"
  } Date greater than or equal to Today`;
export const greaterEndDateMsg =
  "Please select a End date greater than Start date.";
export const startDateRequiredMsg = `Start Date is a required field`;
export const cashbackInvalidExpire = "Days should be between 1 to 30";
export const createCashbackMsg = "Create Cashback";
export const testModeTextForCashback =
  "You're in test mode, so your customer won't receive any amount for this Cashback.";

//payment page customization
export const customizePaymentPage = "Customize your payment page";
export const displayAmountIn = "Display amount in";
export const showWalletLabel = "Show recommended pay via wallets";
export const speedBranding = "Speed Branding";
export const removeSpeedBrandLabel = "Remove Speed branding";

export const connectWithAffiliatePartner = "Connect with affiliate partner";
export const cashbackIDLabel = "Cashback ID";
export const cashbackLabel = "Cashback";
export const loginAffiliateNotFoundMessage =
  "You can no longer connect with the Speed account because your invitation has been revoked by your affiliate partner. If this is a mistake, contact your affiliate partner and ask them to send you a new invitation.";
export const affiliateInvitationMessage = (data, fromWelcome = false) => (
  <>
    You've been invited by{" "}
    <span style={{ color: !fromWelcome && "#0A193E" }}>
      {data?.owner_name} ({data?.account_name})
    </span>{" "}
    to connect your account on Speed.
  </>
);
export const affiliateInvitationBy = (account) => `Invitation by ${account} `;
export const alreadyHaveAccount = "I have an account";
export const createANewAccount = "Create a new account";
export const affiliateSignInSubtext = (account) =>
  `Signing in will affiliate your existing account with ${account}.`;
export const affiliateSignUpSubtext = (account) =>
  `By creating a new account, it will affiliate with ${account}.`;
export const partnerInvitationForDiffAccount =
  "partnerInvitationForDiffAccount";
export const partnerInvitationExpired = "partnerInvitationExpired";
export const associateWithAccount = (account) => `Associate with ${account}`;
export const invitationExpiredMessage = "Invitation Expired";
export const partnerInvitationExpiredMessage = (
  <>
    You can no longer connect with the Speed account because your invitation has
    been revoked by your affiliate partner.
    <br />
    <br />
    If this is a mistake, contact your affiliate partner and ask them to send
    you a new invitation.
  </>
);
export const partnerConnectForbiddenMessage = (action) =>
  `We apologise, but your current role does not allow you to ${action} any merchant to join your affiliate partner programme.`;
export const amountExceedPartnerFee = "Fee cannot be more than 5 BTC.";
export const doneText = "Done";
export const skipText = "Skip";
export const undoSkip = "Undo Skip";
export const skipForNow = "Skip for now";
export const completedText = "Completed";
export const redirectingToURL = "Redirecting to URL";
export const or = "OR";
export const moduleNameIntialSetup = "initial_setup";
export const payoutScheduleSubMsg =
  "Easily withdraw your balance via instant payout manually or set up automated payouts using a bitcoin wallet or Lightning address.";
export const automaticPayout = "Automatic Payout";
export const automaticSetupSubText = "Please setup your automatic payout.";
export const setUpText = "Setup";
export const setupAutomaticPayout = "Setup Automatic Payout";
export const needHelpTxt = "Need Help? ";
export const selectPayoutType = "Select payout type";
export const selectFeesType = "Select Fees type";
export const fees = "Fees";
export const selectWalletAddress = (type) => {
  return `Find or add an new ${type} address`;
};
export const selectAddress = (type) => {
  return `Select ${type} address`;
};
export const onchainWallet = "onchain wallet";
export const ln = "LN";
export const maintainMinimumBalance = "Maintain minimum balance";
export const maintainMinumumPayout = "~Minimum payout amount";
export const enterTheBalance = "Enter balance amount";
export const enterMinimumPayout = "Enter minimum payout Amount";
export const onchain = "onchain";
export const lightning = "lightning";
export const addNewLnAddress = "Add new LN address";
export const addNewOnchainAddress = "Add new onchain wallet address";
export const minimumPayout = "Minimum payout amount : ";
export const minimumBalancePayout = "Minimum balance :  ";
export const walletAddressOnchainInfoMsg =
  "Select either added on-chain address or add a new on-chain address from the payout wallets.";
export const walletAddressLNInfoMsg =
  "Select either added LN address or add a new LN address from the payout wallets.";
export const minimumPayoutAmountMsg =
  "Speed recommends keeping 0.0005 BTC to avoid high on-chain network fees.";
export const lightningMinimumPayoutAmountMsg =
  "Specify the minimum amount required to initiate the payout";
export const minimumBalanceMsg =
  "Balance you want to set aside to account for cashbacks and withdrawals";
export const emptyPaymentAddressSubText =
  "Create Payment address to accept payments of various amount.";
export const username = "Username";
export const enterYourUsername = "Enter your username";
export const addNewDomain = "Add new domain";
export const paymentDomainPlaceholder = "Select or Add custom root domain";
export const alreadyTakenUsername = "This username is taken, Try another.";
export const reconnectVerifyDomain = "Reconnect & Verify Domain";
export const paymentDomainInstructionHeader =
  "We have identify that you are using other domain provider please select respective and do the steps mentioned there.";
export const connectViaAPIKey = "Connect via API Key";
export const confirmationMessageLabel = "Show confirmation message";
export const enterSuccessMessage = "Enter success message";
export const enterSuccessUrl = "Enter success url";
export const noteForAddingSuccessUrlInPaymentAddress =
  "(Upon successful payment, this URL will be displayed, but the user won't be actually redirected there.)";
export const configureDomainTitle = "Configure your custom payment domain";
export const cloudFareSubTitle = [
  "We have identify that you are using",
  "Cloudflare",
  "(your domain provider)",
];
export const apiTokenLabel = "API Token";
export const zoneLabel = "Zone";
export const addApiToken = "Add API Token";
export const addZone = "Add Zone";
export const emptyKey = (key) => `${key} cannot be blank`;

export const validateUsername =
  "Only letters and digits are permitted. Any one of the special characters; underscore (_), hyphen (-), and dot (.) can be used only once and not at the start or end";
export const validateUsernameLength =
  "The length must be between 5 and 32 characters";
export const onlyWalletAccountAffiliateError = `You only have a wallet account, thus in order to connect with an affiliate partner, you must first create a Speed "Merchant Account" by clicking on "Add Account" and then  select and connect it with an affiliate partner.`;
export const resendEmail = "Resend Email";
export const removePayoutWallet = "Remove Payout Wallet";
export const removePayoutMsg =
  "Are you sure you wants to remove your payout wallet?";
export const emailSent = "Email Sent";
export const emailVerifictionLinkSent =
  "Wallet address verification link has been sent to your registered email address.";
export const walletVerficationEmailTitle = "Wallet Verification Email";
export const walletVerficationTitle = "Wallet Verification";
export const verificationSuccessful = "Verified Successfully ";
export const verificationSuccessfulMsg =
  "Your wallet address has been successfully verified.";
//Customer
export const addCustomerBtnLabel = "Add Customer";
export const testModeTextForCustomer =
  "You're in test mode, these customers won't actually pay you.";
export const headerCustomerMsg = "Add New Customer";
export const accountInfoFormLabel = "Account Information";
export const billingInfoFormLabel = "Billing Information";
export const addAddressLabel = "Add Address";
export const shippingInfoLabel = "Shipping Information";
export const noCustomerFoundMsg = "No such customer found";
export const enterNamePlaceHolder = "Enter Name";
export const customerNameRequired = "Customer Name is a required field";
export const customerEmailRequired = "Customer Email is a required field";
export const enterEmailPlaceHolder = "Enter email";
export const customerDescriptionPlaceHolder = "Customer Description";
export const billingEmailLabel = "Billing Email";
export const billingEmailCheckboxLabel = "Same as account email";
export const addMoreRecipientsText = "Add more recipients";
export const emailCCLabel = "Emails to CC";
export const enterCityPlaceHolder = "Enter city";
export const enterStatePlaceHolder = "Enter state";
export const enterZIPCodePlaceHolder = "Enter zipcode";
export const sameInfoAsBillingText = "Same as billing information";
export const shippingNameLabel = "Shipping Name";
export const shippingMobileNumberLabel = "Shipping Mobile Number";
export const deleteCustomerText = "Delete Customer";
export const deleteCustomerInstructions = [
  "This will permanently remove the customer's Invoice information.",
  "Past payments or invoices associated with the customer will still remain. This action cannot actions Insights be undone.",
];
export const reminderUnique = "Reminder must be unique";
export const invoicePrefixMessage =
  "The invoice number for all future invoices will begin with prefix updated here. It must be 1-12 letters or numbers.";
export const defaultPaymentTermsMessage =
  "Payment terms in invoices specify the payment timeframe in days, ranging from 0 to 365 days.";
export const defaultReminderMessage =
  "Set automatic reminders option if an invoice hasn't been paid yet.";
export const invoicePrefixValidation = "Invoice prefix cannot be blank";
export const goToPayoutScheduling = "Go to Payout Scheduling";
export const deactivateWalletAccountText =
  "This wallet address is associated with payout scheduling for automated payout, So it can't be deactivated.";
export const automatePayout = "Automate Payout";
export const hellobarTextAutoPayout =
  "Automatic payout processing failed due to technical issue. Please contact support for more clarification.";
export const deactivatePayoutWallet = "Deactivate Payout Wallet";
export const phonePopperText =
  "Enabling this option will make it necessary to provide a phone number to complete the transaction.";
export const emailPopperText =
  "Enabling this option will make it necessary to provide an email address to complete the transaction.";
export const unableToVerifyPaymentDomain = "Unable to verify custom domain.";
export const shipping = "shipping";
export const billing = "billing";
export const billingAndShipping = "Billing and Shipping";
export const optionsLabel = "OPTIONS";
export const checkoutSummary = "Checkout Summary";
export const paymentDetails = "Payment Details";
export const linkNotFoundTitle = "Link Not Found";
export const linkNotFoundDesc =
  "The link you trying to access for the payout wallet couldn't be found.";
export const automaticText = "Automatic";
export const instantText = "Instant";
export const onchainAddressWallet = "On-chain Address";
export const lnAddressWallet = "LN Address";
export const minimumPayoutAmountGreaterErrorMsg =
  "minimum payout amount should be greater than or equal to 0.0005 BTC or 50000 SATS";
export const minimumPayoutAmountLightningGreaterErrorMsg =
  "minimum payout amount should be greater than or equal to 0.00000001 BTC or 1 SATS";
export const minimumPayoutAmountLessThanErr =
  "minimum payout amount should be less than or equal to 5 BTC or 500000000 SATS";
export const minimumBalanceLessThanErr =
  "minimum balance should be less than 5 BTC or 500000000 SATS";
export const minBalanceGreaterError =
  "minimum balance should be greater than or equal to 0";
export const cantGreaterMsg = "Can't greater than";
export const payoutTypeErrorMsg = "Payout type is required";
export const feesTypeErrorMsg = "Fees type is required";
export const walletAddressErrorMsg = "Wallet address is required";
export const alreadyVerified = "Already Verfied";
export const payoutWalletAlreadyVerified = "Payout wallet is already verified";
export const decimalLimitError = "You can enter a fraction up to 8 digit";
export const cantLessThanMsg = (currencyType, type) => {
  const isOnchain = type === onchain;
  let value;
  if (isOnchain) {
    value = currencyType ? "50000" : "0.0005";
  } else {
    value = currencyType ? "1" : "0.00000001";
  }
  return `Can't less than ${value}`;
};
export const error = "Error";
export const okay = "Okay";
export const apiKeyNotExist = "This API key does not exist.";
export const businessDetailsLabel = "Business details";
export const copyBrand = ["Copy your ", "brand settings"];
export const appearance = "Appearance";
export const requestSubmitted = "Request submitted ";
export const reqSubmittedText = (
  <>
    Your request to close the account was sent, and we have received it. Our
    team will look into it further, and within 7 days your account will be
    deleted from our portal. If you need to get in touch with us, email us at{" "}
    <span style={{ color: "#2A67FF", fontWeight: "600" }}>
      support@tryspeed.com
    </span>{" "}
    going forward.
  </>
);
export const assetsCreateLn = "You can create your own Speed LN address";
export const setupText = "Setup now";
export const receive = "Receive";
export const note = "Note";
export const failureReason = "Failure Reason";
export const transactionId = "Transaction id";
export const confirmationCount = "Confirmation count";
export const assetsSearchBar = "Search assets";
export const requestAssets = "Request assets";
export const alreadyAdded = "Already Added";
export const sendAddressInputText = "Add address or Invoice";
export const enterCustomNotePlaceholder = "Enter custom note";
export const sendViaMethod = (method, req) => {
  let methodType = "Send via";
  switch (method) {
    case "onchain":
      methodType += " Onchain";
      break;
    case "lightning":
      methodType += " Lightning";
      break;
    case "lnurl":
      methodType += `${req?.includes(lnurl) ? " LN URL" : " LN Address"}`;
      break;
    case "ethereum":
      methodType += " Ethereum";
      break;
    default:
      methodType += "";
      break;
  }
  return methodType;
};
export const sats = "SATS";
export const getCurrencyName = (currencyName) => `Send ${currencyName}`;
export const pay = "Pay";
export const estimatedNetworkFees = "Estimated network fees";
export const estimatedRoutingFees = "Estimated routing fees";
export const feedDeclaration =
  " *Fees are subject to change based on the current Bitcoin market rate.";
export const totalPayoutDeduction = "Total amount to be deducted";
export const totalAmount = "Total amount";
export const totalPayout = "Total payout";
export const amountText = "Amount";
export const showBalance = "Balance:";
export const unconfirmedPaymentDescription = (
  <>On-chain payment awaiting confirmation.</>
);
export const unconfirmed = "Unconfirmed";
export const explorerLink = "Explorer link";
export const customField = "Custom field";
export const enterCustomAmount = "Enter Custom Amount";
export const uploadImageText = ["Drop your QR image here, or ", "Browse"];
export const imageSizeMsg = "max file size 2mb";
export const customAmount = "Custom amount";
export const bitcoin = "Bitcoin";
export const enterAmount = "Enter amount";
export const addAmountOrNote = "Add amount/note";
export const addAmount = "Add amount";
export const lightningCaps = "Lightning";
export const invoiceReadyToPaidMsg =
  "Inbound invoice was generated, waiting for it to be paid";
export const paymentSent = "PAYMENT SENT";
export const paymentReceived = "PAYMENT RECEIVED";
export const min1satRequired = "MINIMUM 1 SAT REQUIRED";
export const maxUsdtAllowed = "MAXIMUM 1,00,000 USDT ALLOWED";
export const min1usdtRequired = "MINIMUM 0.01 USDT REQUIRED";
export const minThousandsatRequired = "MINIMUM 1000 SATS REQUIRED";
export const minRequiredSat = (amount, showBtcAmount) => {
  const satsCase = amount > 1 ? "SATS" : "SAT";
  return `Minimum ${amount} ${showBtcAmount ? "BTC" : satsCase} Required`;
};
export const insufficientFunds = "Insufficient Funds";
export const networkFees = "Network fee";
export const enterValidAddress = "Please enter valid address";
export const copiedToClipboard = "Copied to clipboard";
export const backToDashboard = "Back to home";
export const notificationMsg =
  "These notification preference settings will only apply in a live environment.";
export const applyToAllText = "Apply to all accounts";
export const selectEmailTriggerFrequency = "Select Email trigger frequency";
export const lnurl = "lnurl";
export const allAssets = "All assets";
export const paymentFailed = "Payment failed";
export const paymentFailedError =
  "There was a problem routing this payment on the network.";
export const helpUs = "Help us";
export const helpGuideText =
  "There could be several reasons for a payment failure. Would you be willing to provide us with additional information to help us resolve the payment issue?";
export const paymentProcessingUpdate = "Payment processing...";
export const paymentProcessingUpdateSubText =
  "Your payment is in process. It may take time. Check transaction history for updates. If it fails, you'll get a refund.";

export const lnInfoText = (lightningMaxLimit) =>
  `You can take a lightning payout of a minimum of 1 SATS and a maximum of ${lightningMaxLimit} SATS.`;
export const onchainInfoText = (maxLimit) =>
  `You can take an on-chain payout of a minimum of 0.00001 BTC and a maximum of ${maxLimit} BTC.`;
export const tryAgain = "Try Again";
export const insufficientFund = "Insufficient fund";
export const alert = "Alert";
export const increasedFeesMsg = (from, to) => (
  <>
    The fees have been increased from <b>{from} BTC</b> to <b>{to} BTC</b>. Do
    you still wish to proceed?
  </>
);
export const insufficientBalanceMsgPayout = (amount, type) => (
  <>
    The {type} of <b>{amount} BTC</b> cannot be processed due to insufficient
    funds. Please try again.
  </>
);

export const failedSendErrorCodes = {
  FAILURE_REASON_NO_ROUTE:
    "We are unable to carry out the transaction because we couldn't locate a suitable route.",
  FAILURE_REASON_INSUFFICIENT_BALANCE:
    "We are unable to carry out the intended activity due to an internal error.",
  FAILURE_REASON_TIMEOUT:
    "We are unable to carry out the intended activity as it took longer than anticipated.",
  FAILURE_REASON_INCORRECT_PAYMENT_DETAILS:
    "We are unable to carry out the intended activity as payment is considered invalid and can no longer be claimed by the recipient.",
  FAILURE_REASON_ERROR: "FAILURE_REASON_ERROR",
};
export const pendingTransactions = "Pending transactions";
export const dummyConnectDomain = "connect.tryspeed.com";
export const payoutAmount = "Payout amount";
export const payoutID = "Payout ID";
export const payoutTypeLightning = "lightning";
export const payoutTypeOnchain = "onchain";
export const listenTo = "Listen to";
export const yourAccEvent = "Events on your account";
export const connectAccEvent = "Events on your connected account";
export const listenToWHType = ["your_account", "connected_account"];
export const connectedAccountLabel = "connected account";
export const confirmed = "Confirmed";
export const setupLnAddress = "Setup your LN Address";
export const setupLn = "Setup LN Address";
export const lnAddressDetails = [
  "Generate LN address using your desired username to accept payment.",
  "One-time creation of LN address, can't be updated or deleted.",
];

export const joinNowLnAddressText =
  "Join now and experience lightning-fast transactions.";
export const yourLnAddress = "Your LN Address";
export const setYourUsername = "Set your username";
export const usernameAlreadyTakenErrMsg =
  "This username is already taken. Try a different name.";
export const successfullyClaimedText =
  "You have successfully claimed your LN Address.";
export const setupCompleted = "Setup Completed";
export const congratulationText = "Congratulations";
export const enterValidUsername = "Please enter valid username.";
export const usernameMustBeLong = "Username must be at least 5 characters long";
export const sendMaxAmountText = "Send MAX amount";
export const insufficientFundsToPayRoutingFee =
  "Insufficient balance to pay routing fee";
export const insufficientBalanceMsg = (fee, balance) => (
  <>
    Please keep <span style={{ fontWeight: "600" }}>{fee} SATS</span> in Speed
    wallet for routing fees. Send up to{" "}
    <span style={{ fontWeight: "600" }}>{balance} SATS</span> .
  </>
);
export const networkFeeInfo =
  "This transaction fee, called a “network fee,” goes to Bitcoin miners, not Speed. These fees incentivize miners to process your transaction and add it to the permanent blockchain.";
export const amountAndNetworkFeeHigh =
  "The amount and network fee are more than you have in your wallet balance.";
export const attention = "Attention ";
export const payingHighFee = "You are paying high fee";
export const payingHighFeeInfo = (
  <>
    <span style={{ color: "#848B9E", fontWeight: "600" }}>Tip:</span> For
    payments like this, Lightning offers a great alternative. It's fast, cheap,
    and avoids high miner fees like the{" "}
    <span style={{ color: "#848B9E", fontWeight: "600" }}>9%</span> on this
    transaction.
  </>
);
export const noQrCodeFoundErr = "No QR code found.";
export const invalidImageErrorDownloading =
  "Invalid image, or error downloading";
export const selectPaymentMode = "Select payment mode";
export const choosePaymentMode =
  "Please choose your preferred payment mode to complete the transaction";
export const paymentProcessingHeading = "Payment processing update";
export const paymentFailedHeading = "Payment failed";
export const maximum = "Maximum";
export const receiveViaLnUrl = "Receive via LN URL";
export const redemptionFailed = "Redemption failed";
export const redemptionFailedSubText =
  "Please reach out to your Lightning URL service provider for further assistance.";
export const createNewAccount = "Create new account";
export const newBusinessPlaceholder = "New Business";
export const signedOutFromAllAccountMsg =
  "You have been removed from all accounts. To continue using Speed, please create a new account.";
export const automaticLogoutCautionMessage = [
  "You were automatically signed out because your session expired for security reasons.",
  "Please sign in again to continue using the Speed web application.",
];
export const automaticLogoutModalHeader = "Sign in to your account again";
export const httpStatusCode = "Http status code";
export const paymentPreimage = "Payment preimage";
export const paidTo = "Paid to";
export const reset = "RESET";
export const maxPaymentMsg = "You can create maximum payment of 5 BTC.";
export const maxPaymentMsgUsdt =
  "You can create maximum payment of 1,00,000 USDT.";
export const oneClickIntegrationMessage = (
  platformAccountName,
  speedAccountName
) => (
  <>
    {platformAccountName} is requesting permission to access your speed{" "}
    <span style={{ color: "#0A193E", fontWeight: "600" }}>
      {speedAccountName}
    </span>{" "}
    account
  </>
);
export const permissionDetails = "Permission Details";
export const connectionProgress = "Connection progress";
export const confirmation = "Confirmation";
export const yesGoBack = "Yes Go Back";
export const oneClickCancellationWarningMessage = (name) =>
  `Please note your speed account will no longer be connected with your ${name} plugin.`;
export const oneClickCancellationMessage = (name) =>
  `Are you sure you want to redirect back to your ${name} plugin ?`;
export const connectionValidationFailed = "Connection validation failed";
export const oneClickInActiveAppMessage =
  "Your app is currently inactive. Please contact Speed Support for assistance.";
export const oneClickInValidAppMessage =
  "We are unable to find the relevant app you have requested. Please verify the client ID and try again.";
export const oneClickInvalidParamsTitle = "Access Denied : authorization error";
export const oneClickInvalidParamsMessage =
  "Access is blocked due to missing required parameters: client_id, redirect_url.";
export const oneClickInvalidParamURLMessage =
  "Access is blocked due to invalid parameter: redirect_url.";
export const connectWithApp = "Connect with app";
export const uploadValidImage = "Please upload valid image";
export const lightningNetworkFeeInfo =
  "A small fee may apply to certain transactions to keep Speed running smoothly. You'll always see the fee upfront, so there are no surprises.";
export const speedFee = "Speed fee";
export const mainnetErrorMSg =
  "Required mainnet id or address to perform transaction.";
export const testnetErrorMsg =
  "Required testnet id or address to perform transaction.";
export const invalidAddressInvoice = "Invalid Address/Invoice";
export const invalidLnUrl = "Invalid LN URL";
export const invalidQrCode = "Invalid QR code";
export const invalidAddressMsg =
  "Required valid address or invoice to perform transaction.";
export const invalidLnUrlErrMsg = "Invalid LN URL tag type provided.";
export const buyBitcoin = "Buy Bitcoin";
export const redirectingToProvider = (name) => `Redirecting to ${name}`;

//Instant Send
export const withdrawAddressMsg =
  "Invalid lightning or a onchain address. Please use valid lightning invoice / address or LNURL or onchain address.";
export const recipientLabel = "Recipient";
export const enterInvoicePlaceholder = "Enter address or invoice";
export const maxAllowed = (max, sats) => `Maximum ${max} ${sats} is allowed`;
export const minAllowed = (min, sats) => `Minimum ${min} ${sats} is required`;
export const keepSatsMsg = (fee) =>
  `Please keep ${fee} SATS in Speed account for fees.`;
export const addressDesc = "Lightning Invoice, URL, Address or Onchain Address";
export const amountPlaceholder = "Enter Amount";
export const balancePreText = "Available balance is";
export const instantSendLabel = "Instant Send";
export const instantSendsLabel = "Instant Sends";
export const createdOn = "Created on";
export const sendTo = "Send to";
export const instantSendNotFoundMsg = (id) =>
  `Instant send ${id} is not found.`;
export const instantSendNotFoundSubMsg = (liveMode) =>
  `View ${(liveMode && "live") || "test"} mode instant sends`;
export const sendPaymentLabel = "Send Payment";
export const sendInstantly = "Send Instantly";

export const invalidEthAddress = "Invalid Ethereum Address";
export const insufficientBalance = "Insufficient Balance";
export const totalAmountToSend = "Total amount to send";
export const routingFeeInfo =
  "This transaction fee, called a “routing fee,” goes to node processing your transaction, not Speed. These fees encourage nodes to handle transactions quickly";
export const networkFeeHighTip = (
  <>
    <span style={{ fontWeight: "600" }}>Tip:</span> Choose Lightning transfer!
    Lightning transactions are instant, cost a fraction of the above onchain
    network fees and are super secure.
  </>
);
export const routingFeeText = "Routing fee";
export const sendWithHighFee = "SEND WITH HIGH FEE";
export const sendText = "SEND";
export const maximumAmountAllowed = (amt, showBtcAmount) => {
  const satsCase = amt > 1 ? "SATS" : "SAT";
  return `MAXIMUM ${amt} ${showBtcAmount ? "BTC" : satsCase} ALLOWED`;
};
export const minAmount = "Min amount";
export const maxAmount = "Max amount";
export const captch = "Captcha";
export const captchaProblemMsg =
  "There was a problem routing this payment on the network.";
export const enterValidCaptchErrMsg = "Please enter valid captcha.";
export const verifyHuman = "Verify you’re human";
export const selectYourCountry = "Select your country ";
export const selectCountryDesc =
  "We'll need your country information to enhance the security of your account";
export const ethereum = "Ethereum";
export const ethereumLabel = "ethereum";
export const gasFees = "Gas Fee";
export const usdt = "USDT";
export const eth = "ETH";
export const on_chain = "on_chain";
export const invoiceExpired =
  "This invoice is already expired. Please request a new one.";
export const gasFeeMsg = `This transaction fee, called a "gas fee", goes to the miners, not Speed. These fees incentivize miners to process your transaction and add it to the permanent blockchain.`;
export const viewInEtherscan = "VIEW IN ETHERSCAN";
export const viewInMemepool = "VIEW IN MEMEPOOL";
export const newToSpeedWallet = "New to Speed wallet?";
export const getStartedWithSpeedWallet = (currency) =>
  `Get started by receiving ${
    currency === sats ? "bitcoins" : "USDT"
  } to your wallet.`;
export const receiveNow = "Receive Now";
export const disclaimer = "Disclaimer";
export const closeBanxaNote = [
  <>
    You are now leaving speed wallet for Banxa (<u>https://banxa.com</u>).
    Services related to card payments are provided by Banxa, a separate
    third-party platform. By proceeding and procuring services from Banxa, you
    acknowledge that you have read and agreed to Banxa's
  </>,
  "Terms of Use",
  "and",
  "Privacy and Cookies Policy",
  <>
    . <br />
    For any questions related to Banxa's services, please contact Banxa at{" "}
    <u>support.banxa.com</u>.
  </>,
];
export const btc = "BTC";
export const minBtcRequired = (amount) => `Minimum ${amount} BTC required`;
export const insufficientBalanceMessage = (
  fee,
  showBtcAmount = false
) => `Please keep
${fee} ${showBtcAmount ? "BTC" : "SATS"} in
 Speed wallet for fees.`;
export const viewDetails = "View details";
export const upcomingMaintenanceScheduledText =
  "Upcoming maintenance schedule on ";
export const maxSettlementTime = "Max settlement time";
export const settlementFeeInfo =
  "Your transaction will be settled within this timeframe. Otherwise, the debited amount will be reimbursed back to your account.";
export const unableToProcess = "Unable to process";

export const adjustLimitMsg =
  "Adjust your instant send transaction limits according to your preference";
export const transactionLimitTitle = "Instant send transaction limit";
export const minSendLimit =
  "Amount should be greater than or equal to 0.00000001 BTC or 1 SATS.";
export const maxSendLimit =
  "Amount should be less than or equal to 5 BTC or 500000000 SATS.";
export const dailySendLimit =
  "Amount should be greater than or equal to sum of methods per transaction limit.";
export const successSendLimit = "Instant Send limits updated successfully.";
export const fromDate = "From Date";
export const toDate = "To Date";
