export const walletRoute = {
  "My Assets": {
    icon: "myAssets",
    route: "/my-assets",
    header: "My Assets",
    subHeader: "All your assets with its balance",
    isMainHasSubMenu: false,
    mode: true,
    subMenu: [],
  },
  "All Assets": {
    icon: "allAssets",
    route: "/assets",
    header: "All Assets",
    subHeader:
      "This is a list of all assets available for your speed wallet account.",
    isMainHasSubMenu: false,
    mode: true,
    subMenu: [],
    ldFlagName: "mnAllassetsFe",
  },
  Transactions: {
    icon: "transactions",
    route: "/wallet-transactions",
    header: "Transactions",
    subHeader: "This is a list of your wallet account transactions.",
    isMainHasSubMenu: false,
    mode: true,
    subMenu: [],
  },
  // "Refer & Earn": {
  //   icon: "refer",
  //   route: "/refer-and-earn",
  //   header: "Refer & Earn",
  //   subHeader: "Manage all account transactions here.",
  //   isMainHasSubMenu: false,
  //   mode: true,
  //   subMenu: [],
  // },
  "Buy Bitcoin": {
    icon: "buy",
    route: "/buy-bitcoin",
    header: "Buy Bitcoin",
    subHeader: "List of providers who can help you buy bitcoin.",
    isMainHasSubMenu: false,
    mode: false,
    ldFlagName: "mnBuybitcoinFe",
    subMenu: [],
  },
  // "Sell Bitcoin": {
  //   icon: "sell",
  //   route: "/sell-bitcoin",
  //   header: "Sell Bitcoin",
  //   subHeader: "Manage all account transactions here.",
  //   isMainHasSubMenu: false,
  //   mode: true,
  //   subMenu: [],
  // },
  // Rewards: {
  //   icon: "reward",
  //   route: "/rewards",
  //   header: "Rewards",
  //   subHeader: "Manage all account transactions here.",
  //   isMainHasSubMenu: false,
  //   mode: true,
  //   subMenu: [],
  // },
  Settings: {
    icon: "settings",
    route: "/settings/profile",
    isMainHasSubMenu: false,
    mode: false,
    subMenu: [
      {
        icon: "user",
        title: "Profile Settings",
        subMenu: [
          {
            title: "Profile Info",
            route: "/settings/profile",
            header: "Profile",
            subHeader: "Manage your Speed account's profile information.",
          },
          {
            title: "Change Password",
            route: "/settings/change-password",
            header: "Change Password",
            subHeader: "Change the password on your Speed account.",
            restrictionForSocialMediaLoginUser: true,
          },
          {
            title: "2FA",
            route: "/settings/two-factor-auth",
            header: "Two-factor Authentication",
            subHeader:
              "Manage the Two-factor authentication on your Speed account.",
          },
          {
            title: "Login Sessions",
            route: "/settings/login-sessions",
            header: "Login Sessions",
            subHeader:
              "The following is a list of login sessions in your Speed account from various devices.",
          },
          {
            title: "Associated Accounts",
            route: "/settings/associated-accounts",
            header: "Associated Accounts",
            subHeader:
              "The list of accounts associated with your Speed profile.",
          },
        ],
      },
      {
        icon: "info",
        title: "Business Settings",
        subMenu: [
          {
            title: "Account Details",
            route: "/settings/account-info",
            header: "Account Details",
            subHeader: "Manage your Speed business account information.",
            subMenu: [
              {
                title: "Account Info",
                route: "/settings/account-info",
              },
              {
                title: "Close Wallet Account",
                route: "/settings/close-wallet-account",
              },
            ],
          },
          {
            title: "Account Status",
            route: "/settings/permission",
            header: "Account Status",
            subHeader: "Manage your account status from here.",
            subMenu: [
              {
                title: "Permission",
                route: "/settings/permission",
              },
            ],
          },
        ],
      },
    ],
  },
  "Download & Go": {
    icon: "download",
    route: "/download",
    header: "Download & Go",
    isMainHasSubMenu: false,
    mode: true,
    subMenu: [],
  },
};

// When new route added in main menu then also add that route name into "pathArray" array in constant.js file.
export const privateRoute = {
  "Get Started": {
    icon: "getStarted",
    route: "/initial-setup-guide",
    isMainHasSubMenu: false,
    mode: false,
    subMenu: [],
    ldFlagName: "obGetstartedFe",
  },
  Dashboard: {
    icon: "dashboard",
    route: "/dashboard",
    header: "Dashboard",
    subHeader: "A summary of your speed account.",
    isMainHasSubMenu: false,
    mode: true,
    subMenu: [],
  },
  "Receive Payments": {
    route: "/payments",
    icon: "receivePayments",
    isMainHasSubMenu: true,
    subMenu: [
      //for accordion in main menu
      {
        title: "Payments",
        route: "/payments",
        header: "Payments",
        subHeader: "Your Speed account's payment transaction list.",
        mode: true,
        subMenu: [],
      },
      {
        title: "Checkout Links",
        route: "/checkout-links",
        header: "Checkout Links",
        subHeader:
          "Create a link to accept one-time payments of various amounts.",
        mode: true,
        subMenu: [],
      },
      {
        title: "Payment Links",
        route: "/payment-links",
        header: "Payment Links",
        subHeader:
          "All payment links are listed below. Copy and share them to receive payments.",
        mode: true,
        subMenu: [],
      },
      {
        title: "Invoices",
        route: "/invoices",
        header: "Invoice",
        subHeader:
          "This is a list of all the invoices that have been created to your Speed account.",
        mode: true,
        subMenu: [],
        ldFlagName: "mnInvoiceFe",
      },
      {
        title: "Payment Addresses",
        route: "/payment-addresses",
        header: "Payment Addresses",
        subHeader:
          "Create a payment address to accept multiple payments of various amount.",
        mode: true,
        subMenu: [],
        ldFlagName: "mnPaymentAddressFe",
      },
      {
        title: "One QR",
        route: "/one-qr",
        header: "One QR",
        subHeader: `Create a "One QR" for your store to collect payments from customers for an multiple number of times.`,
        isMainHasSubMenu: false,
        mode: true,
        subMenu: [],
      },
      {
        title: "Point of Sale",
        route: "/speed-point-of-sale",
        header: "Point of Sale",
        subHeader: "Your Speed account's Point of Sale overview.",
        mode: true,
        subMenu: [],
        ldFlagName: "mnPointOfSaleFe",
      },
    ],
  },
  "Send Payments": {
    icon: "sendPayments",
    route: "/withdraws",
    isMainHasSubMenu: true,
    subMenu: [
      //for accordion in main menu
      {
        title: "Instant Send",
        route: "/instant-sends",
        header: "Instant Send",
        subHeader:
          "Instantly Send Payments with On-chain address and Lightning Invoice, URL, Address.",
        mode: true,
        subMenu: [],
        ldFlagName: "mnInstantsendFe",
      },
      {
        title: "Withdraws",
        route: "/withdraws",
        header: "Withdraws",
        subHeader:
          "This is a list of all the withdraws that have been made to your Speed account.",
        mode: true,
        subMenu: [],
      },
      {
        title: "Withdrawal Links",
        route: "/withdrawal-links",
        header: "Withdrawal Links",
        subHeader:
          "Create a Withdraw link to make one-time payment of fix amount.",
        mode: true,
        ldFlagName: "mnSpWithdrawlinkFe",
        subMenu: [],
      },
      {
        title: "Payouts",
        route: "/payouts",
        header: "Payouts",
        subHeader: "This is a list of Speed payouts made to your wallet.",
        mode: true,
        subMenu: [],
      },
      {
        title: "Cashback",
        route: "/cashbacks",
        header: "Cashbacks",
        subHeader:
          "This is a list of all the cashbacks that have been made to your Speed account.",
        mode: true,
        ldFlagName: "mnSpCashbacksFe",
        subMenu: [],
      },
    ],
  },
  Balances: {
    icon: "balances",
    route: "/balances",
    header: "Balances",
    subHeader: "Your Speed account's balance overview.",
    isMainHasSubMenu: false,
    mode: true,
    subMenu: [],
  },
  Swaps: {
    icon: "swaps",
    route: "/swaps",
    header: "Swaps",
    subHeader:
      "Create a Swap to maintain a balance in other supported currency.",
    isMainHasSubMenu: false,
    mode: true,
    subMenu: [],
    ldFlagName: "mnSwapFe",
  },
  Transfers: {
    icon: "transfers",
    route: "/transfers",
    header: "Transfers",
    subHeader:
      "Create a Transfer to send any amount to any existing Speed account.",
    isMainHasSubMenu: false,
    mode: true,
    subMenu: [],
    ldFlagName: "mnTransferFe",
  },
  Transactions: {
    icon: "transactions",
    route: "/transactions",
    header: "Transactions",
    subHeader: "This is a list of your Speed account transactions.",
    isMainHasSubMenu: false,
    mode: true,
    subMenu: [],
  },
  Connect: {
    icon: "connectIcon",
    route: "/connect",
    header: "Connect",
    subHeader:
      "Create a connection with a speed account to take payments on their behalf and collect your fees.",
    isMainHasSubMenu: false,
    mode: true,
    subMenu: [],
    ldFlagName: "mnConnectFe",
  },
  Customers: {
    icon: "customers",
    route: "/customers",
    header: "Customers",
    subHeader:
      "Create a customer for every new user or business you want to bill.",
    isMainHasSubMenu: false,
    mode: true,
    subMenu: [],
    ldFlagName: "mnCustomersFe",
  },
  Products: {
    icon: "products",
    route: "/products",
    header: "Products",
    subHeader:
      "Create products with one-time or recurring prices that are compatible with Checkout links, payment links and invoices.",
    isMainHasSubMenu: false,
    mode: true,
    ldFlagName: "mnProductsFe",
    subMenu: [],
  },
  Partners: {
    icon: "partners",
    route: "/partners/affiliate-partners",
    isMainHasSubMenu: false,
    mode: true,
    ldFlagName: "mnAffiliatePartnerFe",
    subMenu: [
      {
        icon: "affiliatePartners",
        title: "Affiliate Partners",
        route: "/partners/affiliate-partners",
        header: "Affiliate Partners",
        subHeader:
          "Invite your prospective customers to become your affiliate partner member account.",
        mode: true,
        ldFlagName: "mnAffiliatePartnerFe",
        subMenu: [],
      },
    ],
  },
  "Manage Apps": {
    icon: "manageApps",
    route: "/manage-apps",
    header: "Manage Apps",
    subHeader: "These are the list of app installed with your speed account",
    isMainHasSubMenu: false,
    mode: true,
    ldFlagName: "mnManageappFe",
    subMenu: [],
  },
  Developers: {
    icon: "developers",
    route: "/apikeys",
    isMainHasSubMenu: false,
    mode: true,
    subMenu: [
      {
        icon: "key",
        title: "API Keys",
        header: "Standard Keys",
        subHeader: "These keys will allow you to authenticate API requests",
        mode: true,
        route: "/apikeys",
        subMenu: [
          {
            title: "Standard Keys",
            route: "/apikeys",
            header: "Standard Keys",
            subHeader: "These keys will allow you to authenticate API requests",
            mode: true,
          },
          {
            title: "Restricted Keys",
            route: "/apikeys/restricted-keys",
            header: "Restricted Keys",
            subHeader: "These keys will allow you to authenticate API requests",
            ldFlagName: "mnDkApikeyrestrictedkeysFe",
            mode: true,
          },
        ],
      },
      {
        icon: "webhooks",
        title: "Webhooks",
        route: "/webhooks",
        header: "Webhooks",
        subHeader: "Subscribe webhook and get notification about events.",
        mode: true,
        subMenu: [],
      },
      {
        icon: "event",
        title: "Events",
        route: "/events",
        header: "Events",
        subHeader: "This is a list of various events of your Speed account.",
        mode: true,
        subMenu: [],
      },
      {
        icon: "assignment",
        title: "Logs",
        route: "/logs",
        header: "Logs",
        subHeader: "This is a list of various logs of your Speed account.",
        subMenu: [],
        mode: true,
        ldFlagName: "mnLogFe",
      },
      {
        icon: "sessions",
        title: "Sessions",
        route: "/sessions",
        header: "Sessions",
        subHeader:
          "This is a list of checkout sessions created for your account.",
        mode: true,
        ldFlagName: "mnSessionsFe",
        subMenu: [],
      },
      {
        icon: "help",
        title: "Help",
        subMenu: [
          {
            title: "Developer guide",
            route: `${process.env.REACT_APP_SPEED_DEV_URL}docs`,
            isExternalLink: true,
            subMenu: [],
          },
          {
            title: "API Docs",
            route: `${process.env.REACT_APP_SPEED_API_URL}reference/introduction`,
            isExternalLink: true,
            subMenu: [],
          },
          {
            title: "Change Log",
            route: `${process.env.REACT_APP_SPEED_API_URL}changelog`,
            isExternalLink: true,
            subMenu: [],
          },
        ],
      },
      {
        icon: "testing",
        title: "Testing",
        subMenu: [
          {
            title: "Docs",
            route: `${process.env.REACT_APP_SPEED_API_URL}reference/test-mode-payments`,
            isExternalLink: true,
            subMenu: [],
          },
          {
            title: "Tools",
            route: `${process.env.REACT_APP_SPEED_API_URL}reference/test-mode-payments#test-payments`,
            isExternalLink: true,
            subMenu: [],
          },
        ],
      },
    ],
  },
  Settings: {
    icon: "settings",
    route: "/settings/profile",
    isMainHasSubMenu: false,
    mode: false,
    subMenu: [
      {
        icon: "user",
        title: "Profile Settings",
        subMenu: [
          {
            title: "Profile Info",
            route: "/settings/profile",
            header: "Profile",
            subHeader: "Manage your Speed account's profile information.",
          },
          {
            title: "Change Password",
            route: "/settings/change-password",
            header: "Change Password",
            subHeader: "Change the password on your Speed account.",
            restrictionForSocialMediaLoginUser: true,
          },
          {
            title: "2FA",
            route: "/settings/two-factor-auth",
            header: "Two-factor Authentication",
            subHeader:
              "Manage the Two-factor authentication on your Speed account.",
          },
          {
            title: "Notifications",
            route: "/settings/notifications",
            restrictedRoles: ["role_developer"],
            header: "Notification Preferences",
            subHeader: "Your email notification preference will only apply to",
            ldFlagName: "mnNotificationprefFe",
          },
          {
            title: "Login Sessions",
            route: "/settings/login-sessions",
            header: "Login Sessions",
            subHeader:
              "The following is a list of login sessions in your Speed account from various devices.",
          },
          {
            title: "Associated Accounts",
            route: "/settings/associated-accounts",
            header: "Associated Accounts",
            subHeader:
              "The list of accounts associated with your Speed profile.",
          },
        ],
      },
      {
        icon: "info",
        title: "Business Settings",
        subMenu: [
          {
            title: "Account Details",
            route: "/settings/account-info",
            header: "Account Details",
            subHeader: "Manage your Speed business account information.",
            subMenu: [
              {
                title: "Account Info",
                route: "/settings/account-info",
              },
              {
                title: "Public Info",
                route: "/settings/public-info",
              },
              {
                title: "Close Account",
                route: "/settings/close-account",
              },
              {
                title: "Close Wallet Account",
                route: "/settings/close-wallet-account",
              },
            ],
          },
          {
            title: "Account Status",
            route: "/settings/permission",
            header: "Account Status",
            subHeader: "Manage your account status from here.",
            ldFlagName: "mnAccountStatusFe",
            subMenu: [
              {
                title: "Permission",
                route: "/settings/permission",
              },
            ],
          },
          // {
          //   title: "Business Details",
          //   route: "/settings/business-details",
          //   header: "Business Details",
          //   subHeader:
          //     "Manage your company's information in your Speed account.",
          // },
          {
            title: "Payout Wallets",
            route: "/settings/payout-wallets",
            header: "Payout Wallets",
            subHeader: "Control your payouts from your Speed business account.",
            mode: true,
          },
          {
            title: "Payout Scheduling",
            route: "/settings/payout-scheduling",
            header: "Payout Scheduling",
            subHeader:
              "Easily withdraw your balance via instant payout manually or set up automated payouts using a bitcoin wallet or Lightning address.",
            mode: true,
            ldFlagName: "mnPayoutSchedulingFe",
          },
          {
            title: "Connect",
            route: "/settings/connect-branding",
            header: "Connect",
            subHeader:
              "The following are the basic account information collected from the account's owner. ",
            mode: true,
            ldFlagName: "mnSettingsConnectFe",
            subMenu: [
              {
                title: "Connect Branding",
                route: "/settings/connect-branding",
              },
              {
                title: "Connected Extensions",
                route: "/settings/connected-extensions",
              },
            ],
          },
          {
            title: "Branding",
            route: "/settings/checkout-payment",
            header: "Branding",
            subHeader:
              "Customise the branding on external pages and emails that your customers will see.",
            subMenu: [
              {
                title: " Checkout & payment link",
                route: "/settings/checkout-payment",
              },
              { title: "Invoice Page", route: "/settings/invoice-page" },
              { title: "Email Receipt", route: "/settings/email-receipt" },
              { title: "One QR", route: "/settings/one-qr" },
              { title: "Withdrawal link", route: "/settings/withdrawal-link" },
              { title: "Invoice PDF", route: "/settings/invoice-pdf" },
              {
                title: "Invoice payment page",
                route: "/settings/invoice-payment-page",
              },
            ],
          },
          {
            title: "Payment Page",
            route: "/settings/payment-page",
            header: "Payment Page",
            subHeader:
              "Customize the payment page that your consumer sees as per your business requirements.",
          },
          {
            title: "Emails",
            route: "/settings/emails",
            header: "Emails",
            subHeader:
              "For sending emails, configure your email address and domain.",
            subMenu: [
              { title: "Emails", route: "/settings/emails" },
              { title: "Preferences", route: "/settings/email-preferences" },
            ],
          },
          {
            title: "Invoice",
            route: "/settings/invoice",
            header: "Invoice",
            subHeader: "These settings will be applied to new invoices.",
            ldFlagName: "mnSettingsInvoiceFe",
          },
          {
            title: "Users & Teams",
            route: "/settings/team-members",
            header: "Users & Teams",
            subHeader:
              "Allow your team members access to your Speed business account.",
            subMenu: [
              {
                title: "Account Info",
                route: "/settings/team-members",
              },
              { title: "Public Info", route: "/settings/invite-members" },
            ],
          },
          {
            title: "Custom Domain",
            route: "/settings/custom-domain",
            header: "Custom Domain",
            subHeader: "Set up the URL that your customers see on pages.",
            ldFlagName: "mnCustomDomainFe",
            subMenu: [
              {
                title: "Custom Domain",
                route: "/settings/custom-domain",
              },
              {
                title: "Custom Payment Domain",
                route: "/settings/custom-payment-domain",
              },
            ],
          },
          // { title: 'Security History', route: '/settings/security-history', header: 'Security History', subHeader: "Investigate your Speed business account's security history." }
          {
            title: "Payment Method",
            route: "/settings/payment-method",
            header: "Payment Method",
            subHeader:
              "Manage the payment method according to your business requirements.",
          },
          {
            title: "Instant Send",
            route: "/settings/instant-send",
            header: "Instant Send",
            subHeader: "Manage your Instant send limits from here.",
            ldFlagName: "mnInstantSendSettingsFe",
          },
        ],
      },
      // {
      //   icon: "groupUsers",
      //   title: "Product Settings",
      //   subMenu: [
      //     {
      //       title: "Payment Links",
      //       route: "/settings/payment-links",
      //       header: "Payment Links",
      //       subHeader:
      //         "The following are the basic account information collected from the account's owner. ",
      //     },
      //     {
      //       title: "Invoice Template",
      //       route: "/settings/invoice-template",
      //       header: "Invoice Template",
      //       subHeader:
      //         "The following are the basic account information collected from the account's owner. ",
      //     },
      //   ],
      // },
    ],
  },
};

export const publicRoute = [
  {
    title: "Speed: Sign in",
    route: "/login",
  },
  {
    title: "Speed: Sign in",
    route: "/documentation",
  },
  {
    title: "Speed: Sign in",
    route: "/login-success",
  },
  {
    title: "Speed: Register",
    route: "/register",
  },
  {
    title: "Speed: Confirm Email",
    route: "/confirm-email",
  },
  {
    title: "Speed: Verify Email",
    route: "/verify-email",
  },
  {
    title: "Speed: Reset Password",
    route: "/reset-password",
  },
  {
    title: "Speed: Reset Password",
    route: "/new-password",
  },
  {
    title: "Speed: Accept Invite",
    route: "/accept-invite",
  },
  {
    title: "Speed: Setup Two Factor Authentication",
    route: "/two-factor-setup",
  },
  {
    title: "Speed: Two Factor Authentication",
    route: "/two-factor",
  },
  {
    title: "Speed: Customer Profiling",
    route: "/onboarding",
  },
  {
    title: "Speed: Affiliate Partner Accept Invite",
    route: "/affiliate-partners/accept-invite",
  },
  {
    title: "Speed: Affiliate Partner Join",
    route: "/partners/affiliate-partners/join",
  },
  {
    title: "Speed: One Click",
    route: "/authorize",
  },
];
