import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Text from "@speed/common/src/components/Text/Text";
import { Box, Card, CardContent, CircularProgress, Grid } from "@mui/material";
import "../../../assets/styles/manage-apps.scss";
import { resolveIssue, verfiying } from "../../messages";

const ShowStepIcon = ({ status }) => {
  return status === "success" ? (
    <CheckCircleIcon
      style={{
        color: "#2ecc71",
        height: "30px",
        width: "30px",
      }}
    />
  ) : status === "fail" ? (
    <Text className="default-text" size={12} variant="body" font="regular">
      <Box display="flex" alignItems="center" color="#e74c3c">
        {resolveIssue}
        <CancelIcon
          style={{
            color: "#e74c3c",
            height: "30px",
            width: "30px",
          }}
        />
      </Box>
    </Text>
  ) : status === "loading" ? (
    <Text className="default-text" size={12} variant="body" font="regular">
      <Box display="flex" alignItems="center">
        <CircularProgress
          style={{
            width: "15px",
            height: "15px",
            marginRight: "5px",
            color: "#2a67ff",
          }}
        />
        {verfiying}
      </Box>
    </Text>
  ) : null;
};
const VerifySteps = (props) => {
  return (
    <Card
      className={`manage-card-test-connection ${
        props.verifyStatus === "success"
          ? "success-border"
          : props.verifyStatus === "fail" && "error-border"
      }`}
    >
      <Grid item lg={10}>
        <CardContent
          style={{ display: "flex", alignItems: "center" }}
          className="card-body"
        >
          <Box
            sx={{
              background: "#f7fafc",
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              textAlign: "center",
              margin: "30px 20px",
            }}
          >
            <Text
              style={{
                color: "#2a67ff",
                textAlign: "center",
              }}
            >
              {props.stepNo}
            </Text>
          </Box>
          <Box
            display="flex"
            flexGrow="1"
            flexDirection="column"
            padding="20px 0"
          >
            <Text
              style={{ marginBottom: "10px" }}
              size={20}
              className="default-text"
              variant="h2"
            >
              {props.stepName}
            </Text>
            <Text
              size={16}
              className="grey-text"
              variant="body1"
              font="regular"
            >
              {props.text}
            </Text>
          </Box>
          <Box marginRight="25px">
            <ShowStepIcon status={props.verifyStatus} />
          </Box>
        </CardContent>
      </Grid>
    </Card>
  );
};

export default VerifySteps;
