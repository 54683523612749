import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  callAPIInterface,
  getManageAppFireStoreAllowData,
  manageAppIcon,
} from "../constants";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import Text from "@speed/common/src/components/Text/Text";
import "../../assets/styles/manage-apps.scss";
import Button from "@speed/common/src/components/Button/Button";
import {
  allow,
  authorizeAppWithSpeedText,
  rightArrowIcon,
  shopifyPermissionOauthText,
} from "../messages";
import { emailIcon, speedIcon } from "../images";
import CustomAccordion from "@speed/common/src/components/Accordion/Accordion";
import { sessionService } from "redux-react-session";
import history from "@speed/common/src/components/history";
import { setPageTitle } from "@speed/common/src/components/constants";
import PageLoader from "@speed/common/src/components/PageLoader";
import { cancel } from "@speed/common/src/components/messages";

const ShopifyOAuth = () => {
  const [expanded, setExpanded] = useState("");
  const [allowAppLoading, setAllowAppLoading] = useState(false);
  const [clientAuthRes, setClientAuthRes] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    clientAuthorization()
      .then((res) => {
        setClientAuthRes(res?.app?.client_id);
        setIsLoading(false);
      })
      .catch((_err) => {
        setIsLoading(false);
      });
  }, []);

  const accordianData = [
    {
      heading: "What will Speed-Shopify be able to view?",
      accordianChild: [
        {
          title:
            "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet?",
          customComponent:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
        },
        {
          title:
            "Nequea porro quisquam est qui dolorem ipsum quia dolor sit amet?",
          customComponent:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
        },
        {
          title:
            "Nequeb porro quisquam est qui dolorem ipsum quia dolor sit amet?",
          customComponent:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
        },
        {
          title:
            "Neques porro quisquam est qui dolorem ipsum quia dolor sit amet?",
          customComponent:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
        },
      ],
    },
    {
      heading: "What will Speed-Shopify be able to do?",
      accordianChild: [
        {
          title:
            "Nequep porro quisquam est qui dolorem ipsum quia dolor sit amet?",
          customComponent:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
        },
        {
          title:
            "Nequeq porro quisquam est qui dolorem ipsum quia dolor sit amet?",
          customComponent:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
        },
        {
          title:
            "Nequer porro quisquam est qui dolorem ipsum quia dolor sit amet?",
          customComponent:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
        },
      ],
    },
  ];

  const handleChange = (title) => (_e, newExpanded) => {
    setExpanded(newExpanded ? title : false);
  };

  const clientAuthorization = () => {
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      callAPIInterface("GET", `/manage-app/oauth/authorize?client_id=12345`)
        .then((response) => {
          resolve(response);
        })
        .catch((_error) => {
          reject(_error);
        });
    });
  };

  const handleSpeedCallback = async (code) => {
    await sessionService
      .loadSession()
      .then(async (session) => {
        if (session?.appState) {
          callAPIInterface(
            "GET",
            `/apps/speed/callback?state=${session.appState}&code=${code}`
          )
            .then(async (result) => {
              setAllowAppLoading(false);
              getManageAppFireStoreAllowData(session.appState);
            })
            .catch((error) => {
              setAllowAppLoading(false);
            });
        }
      })
      .catch((_err) => {});
  };

  const handleAllowApp = () => {
    setAllowAppLoading(true);
    const allowApplicationData = {
      client_id: clientAuthRes,
    };
    callAPIInterface(
      "POST",
      "/manage-app/oauth/allow",
      JSON.stringify(allowApplicationData)
    )
      .then((result) => {
        handleSpeedCallback(result?.authentication_code);
      })
      .catch((error) => {
        setAllowAppLoading(false);
      });
  };

  return (
    <Box className="oauth-allow-app-container">
      {setPageTitle(authorizeAppWithSpeedText)}
      {isLoading && (
        <PageLoader
          open={isLoading}
          alt="Loading..."
          customClass="page-loader preview-loader"
          sx={{ position: "absolute", margin: "auto" }}
        />
      )}
      {!isLoading && (
        <>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin="60px 0 0 0"
          >
            <img
              height={40}
              width={35}
              className="card-icon"
              src={manageAppIcon("Shopify")}
              alt="icons-clear"
            />
            <SyncAltIcon sx={{ color: "#848b9e", margin: "0 16px" }} />
            <img
              src={speedIcon}
              height={40}
              width={35}
              className="card-icon"
              alt="icons-clear"
            />
          </Box>
          <Box width="270px" height="44px" margin="16px 0 30px 0">
            <Text
              size={14}
              className="grey-text"
              variant="body1"
              font="regular"
            >
              {shopifyPermissionOauthText}
            </Text>
          </Box>
          <Box className="app-access-description" width={800}>
            {accordianData?.map((accordianItem, index) => (
              <Box key={index} marginBottom="60px">
                <Text size={24} className="default-text text-bold" variant="h2">
                  {accordianItem?.heading}
                </Text>
                {accordianItem?.accordianChild?.map((accChild, index) => {
                  return (
                    <CustomAccordion
                      key={index}
                      className={`support-center-accordian ${
                        expanded !== accChild.title
                          ? "border-accordian"
                          : "rotate-icon"
                      }`}
                      isTitleShown={true}
                      expandIcon={rightArrowIcon}
                      onChange={() => handleChange(accChild?.title)}
                      expanded={expanded === accChild.title}
                      accordianPanel={accChild}
                      icon={
                        <img
                          src={emailIcon}
                          height={30}
                          width={30}
                          style={{ marginRight: "10px" }}
                          alt="email-icon"
                        />
                      }
                      title={accChild?.title}
                      customComponent={<li>{accChild.customComponent}</li>}
                    />
                  );
                })}
              </Box>
            ))}
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              sx={{ marginRight: "20px" }}
              label={cancel}
              color="primary"
              variant="outlined"
              onClick={() => history.push("/manage-apps")}
            />
            <Button
              label={allow}
              color="primary"
              loading={allowAppLoading}
              onClick={handleAllowApp}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ShopifyOAuth;
