import { Box, TableCell, TableRow } from "@mui/material";
import CustomTable from "@speed/common/src/components/Table/Table";
import {
  capitalizeFirstChar,
  removeTrailingZeros,
} from "@speed/common/src/components/constants";

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import NorthWestIcon from "@mui/icons-material/NorthWest";
import { emptySubText } from "@speed/common/src/components/messages";
import {
  emptyTransactionSubText,
  pending,
  currencyLabel,
  withdraw,
  sats,
  usdt,
  btc,
} from "../messages";
import { emptyTransaction } from "../images";
import {
  callAPIInterface,
  clickableTxnType,
  dateTimeFormatInApp,
  getCurrencyForSatsAndBtc,
  getShowAmountBtcToSats,
  noOfRecords,
  noOfRecordsWalletTransactionInAssets,
  transactionTypeValues,
} from "../constants";
import history from "@speed/common/src/components/history";
import { useLocation } from "react-router-dom";
import {
  setIsWalletTransactonDrawerOpen,
  setShowBTCAmount,
  setWalletAppliedTransactonFiltersObj,
  setWalletSelectedTransaction,
} from "../../redux/common/actions";
import TransactionFilterWallet from "./TransactionFilterWallet";
import { sessionService } from "redux-react-session";

const columns = [
  { title: "Type", paddingLeft: "36px" },
  {
    title: "Currency",
  },
  { title: "Amount", align: "right" },
  { title: "Date", paddingLeft: "60px" },
];

const WalletTransactionsList = ({
  fromMyAssets,
  currency = null,
  setTotalUnconfirmed = null,
  tab = "",
}) => {
  const search = useLocation().search;
  const targetCurrency =
    new URLSearchParams(search).get("target_currency") || currency;
  const sourceType = new URLSearchParams(search).get("source_type");
  const gteDateCreated = new URLSearchParams(search).get("created_gte");
  const lteDateCreated = new URLSearchParams(search).get("created_lte");

  const liveMode = useSelector((state) => state.auth.liveMode);
  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);
  const {
    walletSelectedTransaction,
    isWalletTransactionDrawerOpen,
    walletTransactionAppliedFilterObj,
  } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [unconfirmedTransactions, setUnconfirmedTransactions] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [filteredEndingBefore, setFilteredEndingBefore] = useState("");
  const [isAppliedFilter, setIsAppliedFilter] = useState(false);

  const apiData = {
    target_currency: {
      in: fromMyAssets ? [currency] : [sats, usdt],
    },
  };

  useEffect(() => {
    if (fromMyAssets) {
      setInitialState();
      getFilteredTransactionList([], apiData);
      getUnconfirmedTransaction(true);
    }
  }, [currency, liveMode]);

  useEffect(() => {
    if (!fromMyAssets) {
      let requestObj = {};
      if (targetCurrency) {
        requestObj.target_currency = {
          in: targetCurrency.split(),
        };
      }
      if (sourceType) {
        requestObj.type = {
          in: sourceType.split(","),
        };
      }
      if (gteDateCreated) {
        requestObj.created = {
          ...requestObj.created,
          gte: gteDateCreated,
        };
      }
      if (lteDateCreated) {
        requestObj.created = {
          ...requestObj.created,
          lte: lteDateCreated,
        };
      }
      dispatch(
        setWalletAppliedTransactonFiltersObj(
          Object.keys(requestObj).length > 0 ? requestObj : null
        )
      );
      setInitialState();
      if (tab === pending) {
        fetchUnconfirmedTransactions();
      } else if (Object.keys(requestObj).length) {
        getFilteredTransactionList(
          [],
          {
            ...requestObj,
          },
          false,
          true
        );
      } else {
        getFilteredTransactionList([], apiData, false, true);
      }
    }

    return () => {
      !fromMyAssets && dispatch(setWalletSelectedTransaction(null));
      dispatch(setWalletAppliedTransactonFiltersObj(null));
    };
  }, [liveMode, tab]);

  useEffect(() => {
    if (
      !fromMyAssets &&
      !walletTransactionAppliedFilterObj &&
      isAppliedFilter
    ) {
      setIsAppliedFilter(false);
      setInitialState();
      getFilteredTransactionList([], apiData, false, true);
    }
  }, [walletTransactionAppliedFilterObj]);

  useEffect(() => {
    getCurrentSession();
  }, [liveMode]);

  const getCurrentSession = async () => {
    const session = await sessionService.loadSession();
    session?.showBtcAmount &&
      dispatch(setShowBTCAmount(session?.showBtcAmount));
  };

  const setInitialState = () => {
    setRows([]);
    setHasMore(true);
    setFilteredEndingBefore("");
  };

  const fetchUnconfirmedTransactions = () => {
    setUnconfirmedTransactions([]);
    getUnconfirmedTransaction();
  };

  const prepareUnconfirmedData = (data) => {
    return data.map((d) => {
      const object = capitalizeFirstChar(d.object);
      const speedFee = d.speed_fee?.amount || 0;
      const amount = d.target_amount_paid || d.target_amount;

      return {
        id: d.id,
        type: object,
        target_currency: d.target_currency,
        target_amount:
          object === withdraw ? amount + speedFee : amount - speedFee,
        amount: amount,
        fee: speedFee,
        transaction_type: object === withdraw ? "debit" : "credit",
        created: d.created,
      };
    });
  };

  const getUnconfirmedTransaction = async (setCount = false) => {
    setIsLoading(true);

    const paymentFilter = new Promise((resolve, reject) => {
      const paymentData = {
        limit: noOfRecords,
        confirmations: { eq: 0 },
        status: ["paid"],
      };
      if (targetCurrency) {
        paymentData.target_currency = [targetCurrency];
      }
      callAPIInterface("POST", "/payments/filter", paymentData)
        .then((resPayment) => {
          resolve(resPayment);
        })
        .catch((e) => reject(e));
    });

    const withdrawFilter = new Promise((resolve, reject) => {
      const withdrawData = {
        limit: noOfRecords,
        confirmations: { eq: 0 },
        status: ["unpaid"],
      };
      if (targetCurrency) {
        withdrawData.target_currency = [targetCurrency];
      }
      callAPIInterface("POST", "/withdraws/filter", withdrawData)
        .then((resWithdraw) => {
          resolve(resWithdraw);
        })
        .catch((e) => reject(e));
    });

    Promise.all([paymentFilter, withdrawFilter])
      .then((res) => {
        const concatedData = res?.[0]?.data?.concat(res?.[1]?.data);

        if (setCount && setTotalUnconfirmed) {
          setTotalUnconfirmed(concatedData?.length);
          return;
        }

        const sortedData = concatedData.sort((a, b) => {
          return b.created - a.created;
        });

        const preparedData = prepareUnconfirmedData(sortedData);
        setUnconfirmedTransactions(preparedData);
        dispatch(setWalletSelectedTransaction(preparedData?.[0] || null));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFilteredTransactionList = (
    lines,
    reqObj,
    loadmore = false,
    toUpdateTransaction = false
  ) => {
    if (reqObj) {
      reqObj.limit = fromMyAssets
        ? noOfRecordsWalletTransactionInAssets
        : noOfRecords;
    }
    if (loadmore && filteredEndingBefore) {
      reqObj.ending_before = filteredEndingBefore;
    }
    setIsLoading(true);
    callAPIInterface("POST", "/balance-transactions/filter", reqObj)
      .then((res) => {
        if (res) {
          setIsLoading(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setFilteredEndingBefore(res?.data?.[res?.data?.length - 1]?.id);
          }
          const concatedData = lines.concat(res?.data);
          setRows(concatedData);
          const transaction = getTransaction(concatedData);
          if (
            !fromMyAssets &&
            (!walletSelectedTransaction ||
              (walletSelectedTransaction && toUpdateTransaction))
          ) {
            dispatch(setWalletSelectedTransaction(transaction));
          }
          setIsAppliedFilter(true);
        }
      })
      .catch((_err) => {
        setIsLoading(false);
      });
  };

  const getTransaction = (transactions) => {
    return transactions?.find((t) => clickableTxnType.includes(t.type));
  };

  const loadMore = useCallback(() => {
    if (
      walletTransactionAppliedFilterObj &&
      Object.keys(walletTransactionAppliedFilterObj).length
    ) {
      getFilteredTransactionList(rows, walletTransactionAppliedFilterObj, true);
    } else {
      if (filteredEndingBefore) {
        apiData.ending_before = filteredEndingBefore;
      }
      getFilteredTransactionList(rows, apiData, true);
    }
  }, [rows]);

  const columnFinalData = fromMyAssets
    ? columns.filter((column) => column.title !== currencyLabel)
    : columns;

  const visibleCurrency = showBtcAmount && currency === sats ? btc : currency;

  let columnsData = columnFinalData.map((column) =>
    fromMyAssets && column?.title === currencyLabel ? null : (
      <TableCell
        style={{
          paddingLeft:
            (!fromMyAssets || column.title === "Date") && column?.paddingLeft,
        }}
        className="transaction-table-head"
        key={column?.title}
        align={column?.align}
      >
        {`${column.title} ${
          fromMyAssets && column.title === "Amount"
            ? `(${visibleCurrency})`
            : ""
        }`}
      </TableCell>
    )
  );

  const transactionType = (type) => {
    if (tab === pending) {
      type = `Pending_${type}`;
    }
    return transactionTypeValues[type] ?? type;
  };

  const handleTransactionClick = (rowItem) => {
    if (clickableTxnType.includes(rowItem?.type)) {
      dispatch(setWalletSelectedTransaction(rowItem));
    }

    fromMyAssets &&
      clickableTxnType.includes(rowItem?.type) &&
      history.push(
        `/wallet-transactions?status=confirm&target_currency=${currency}`
      );
  };

  const transactionList = tab === pending ? unconfirmedTransactions : rows;

  const rowsData = transactionList?.map((rowItem) => {
    let amount = rowItem?.net || rowItem?.target_amount;
    amount = Math.floor(amount);

    return (
      <TableRow
        onClick={() => handleTransactionClick(rowItem)}
        className={
          clickableTxnType.includes(rowItem?.type)
            ? "clickable wallet-transaction-list"
            : ""
        }
        style={{
          backgroundColor:
            walletSelectedTransaction?.id === rowItem?.id && "#EFF6FF",
        }}
        key={rowItem?.id}
      >
        <TableCell style={{ paddingLeft: !fromMyAssets && "36px" }}>
          <Box display="flex" alignItems="center">
            {rowItem?.transaction_type === "credit" ? (
              <SouthEastIcon
                className="send-receive-icon"
                style={{
                  color: "#0CAD62",
                  background: "#BDF5DB",
                }}
              />
            ) : (
              <NorthWestIcon
                className="send-receive-icon"
                style={{
                  color: "#FD371E",
                }}
              />
            )}
            {transactionType(rowItem?.type)}
          </Box>
        </TableCell>
        {!fromMyAssets && (
          <TableCell>
            {getCurrencyForSatsAndBtc(rowItem?.target_currency, showBtcAmount)}
          </TableCell>
        )}
        <TableCell align="right">
          {rowItem?.target_currency === sats
            ? getShowAmountBtcToSats(
                amount,
                showBtcAmount,
                rowItem?.target_currency
              )
            : removeTrailingZeros(amount / 100, rowItem?.target_currency)}
        </TableCell>
        <TableCell style={{ paddingLeft: "60px" }}>
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: isLoading,
    hasMore: tab !== pending && hasMore,
    loadMore: tab !== pending && loadMore,
    textOnNoData: emptySubText(liveMode, "transactions"),
    subTextOnNoData: emptyTransactionSubText,
    noDataImage: emptyTransaction,
    isScrollTableElement: true,
    className: "logs-table wallet-table",
  };

  return (
    <>
      <CustomTable {...tableProps} />
      <TransactionFilterWallet
        targetCurrency={targetCurrency || []}
        anchor={isWalletTransactionDrawerOpen}
        handleDrawer={() => dispatch(setIsWalletTransactonDrawerOpen(false))}
        getFilteredTransactionList={getFilteredTransactionList}
        setRows={setRows}
        setHasMore={setHasMore}
        setFilteredEndingBefore={setFilteredEndingBefore}
      />
    </>
  );
};

export default WalletTransactionsList;
