import React from "react";
import { Box } from "@mui/system";
import "../../../assets/styles/manage-apps.scss";
import { shopifyGoToNotification } from "../../messages";
import AddWebhookSecretKey from "./AddWebhookSecretKey";
import VideoSection from "./VideoSection";

const ShopifyInstallationStep3 = (props) => {
  const { values, setFieldValue, touched, errors, setTouched } = props;

  return (
    <Box className="fullscreen-modal-box">
      <Box className="modal-container-grid grid-box" sx={{ display: "flex" }}>
        <VideoSection headerText={shopifyGoToNotification} />
        <Box className="inner-grid" sx={{ maxWidth: "50%", overflow: "auto" }}>
          <AddWebhookSecretKey
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            setTouched={setTouched}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ShopifyInstallationStep3;
