import {
  getAmountByCurrency,
  showAmount,
} from "@speed/common/src/components/constants";
import { getAccNameFromId, getEventId } from "../constants";
import { customerChoose, usdt } from "../messages";

export function events(eventDetails) {
  const eventObject = eventDetails?.data?.object;
  const {
    currency,
    amount,
    target_amount_paid,
    target_amount,
    target_currency: targetCurrency,
    min_amount,
    max_amount,
    destination_account,
    amount_paid,
  } = { ...eventObject };
  const eventType = eventDetails?.event_type;
  const id = getEventId(eventType, eventDetails);

  const handleAmount = (amount) => {
    return showAmount({
      amount,
      currency: currency || targetCurrency,
    });
  };

  const currencyAmount = amount && handleAmount(amount);
  const currencyAmountPaid = amount && handleAmount(amount_paid);
  const targetAmountPaid = handleAmount(target_amount_paid || target_amount);
  const minAmount = min_amount && handleAmount(min_amount);
  const maxAmount = max_amount && handleAmount(max_amount);
  const destinationAccount = getAccNameFromId(destination_account);
  const amountToBeShow =
    currencyAmount === 0
      ? `${customerChoose.toLowerCase()} to pay`
      : `${currency} ${currencyAmount}`;

  switch (eventType) {
    case "payment.created":
      return `The payment ${id} for ${amountToBeShow} created.`;
    case "payment.paid":
      return `The payment ${id} paid for ${targetCurrency} ${targetAmountPaid}.`;
    case "payment.expired":
      return `The payment ${id} for ${amountToBeShow} expired.`;
    case "payment.cancelled":
      return `The payment ${id} for ${amountToBeShow} cancelled.`;
    case "payment.confirmed":
      return `The payment for ${id} has been confirmed.`;
    case "checkout_session.created":
      return `The checkout session ${id} for ${amountToBeShow} created.`;
    case "checkout_session.paid":
      return `The checkout session ${id} paid for ${currency} ${currencyAmountPaid}.`;
    case "checkout_session.deactivated":
      return `The checkout session ${id} for ${amountToBeShow} deactivated.`;
    case "checkout_session.payment_paid":
      return `The payment for checkout session ${id} received for ${targetCurrency} ${targetAmountPaid}.`;
    case "checkout_link.created":
      return `The checkout link ${id} for ${amountToBeShow} created.`;
    case "checkout_link.deactivated":
      return `The checkout link ${id} for ${amountToBeShow} deactivated.`;
    case "checkout_link.paid":
      return `The checkout link ${id} paid for ${amountToBeShow}.`;
    case "payment_link.created":
      return `The payment link ${id} for ${amountToBeShow} created.`;
    case "payment_link.deactivated":
      return `The payment link ${id} for ${amountToBeShow} deactivated.`;
    case "payment_link.paymentreceived":
      return `The payment link ${id} paid for ${amountToBeShow}.`;
    case "withdrawal_link.created":
      return `The withdrawal link ${id} for ${currency} ${currencyAmount} created.`;
    case "withdrawal_link.deactivated":
      return `The withdrawal link ${id} for ${currency} ${currencyAmount} deactivated.`;
    case "withdrawal_link.paid":
      return `The withdrawal link ${id} paid for ${targetCurrency} ${targetAmountPaid}.`;
    case "withdraw.created":
      return `The withdraw ${id} for ${currency} ${currencyAmount} created.`;
    case "withdraw.paid":
      return `The withdraw ${id} paid for ${targetCurrency} ${targetAmountPaid}.`;
    case "withdraw.failed":
      return `The withdraw ${id} for ${currency} ${currencyAmount} failed.`;
    case "withdraw_session.created":
      return `The withdraw session ${id} for ${currency} ${currencyAmount} created.`;
    case "withdraw_session.deactivated":
      return `The withdraw session ${id} for ${currency} ${currencyAmount} deactivated.`;
    case "withdraw_session.paid":
      return `The withdraw session ${id} paid for ${targetCurrency} ${targetAmountPaid}.`;
    case "withdraw_request.created":
      if (minAmount === maxAmount)
        return `The withdraw request ${id} for ${currency} ${minAmount} created.`;
      else return `The withdraw request ${id} created.`;
    case "withdraw_request.deactivated":
      if (minAmount === maxAmount)
        return `The withdraw request ${id} for ${currency} ${minAmount} deactivated.`;
      else return `The withdraw request ${id} deactivated.`;
    case "withdraw_request.paid": {
      const {
        target_min_amount: targetMinAmountPaid,
        target_max_amount: targetMaxAmountPaid,
      } = { ...eventObject };
      if (targetMinAmountPaid === targetMaxAmountPaid)
        return `The withdraw request ${id} paid for ${targetCurrency} ${targetMinAmountPaid}.`;
      else return `The withdraw request ${id} paid.`;
    }
    case "invoice.paid":
      return `The invoice ${id} paid for ${targetCurrency} ${targetAmountPaid}`;
    case "transfer.out": {
      const { source, source_type: sourceType } = { ...eventObject };
      return `The transfer of ${targetCurrency} ${targetAmountPaid} sent ${
        sourceType && source ? `for ${sourceType} reference ${source}` : ""
      } to account ${destinationAccount}.`;
    }
    case "transfer.in":
      return `The transfer of ${targetCurrency} ${targetAmountPaid} received from account ${destinationAccount}.`;
    case "payrequest.payment_paid":
      return `The payment for payrequest ${id} received for ${targetCurrency} ${targetAmountPaid}.`;
    case "payment_address.paymentreceived":
      return `The payment for payment address ${id} received for ${targetCurrency} ${targetAmountPaid}.`;
    case "payment_address.deactivated": {
      const address = eventObject?.address;
      return `The payment address ${address} is deactivated.`;
    }
    default:
      return `New event ${eventType}`;
  }
}
