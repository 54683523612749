import React, { useState } from "react";
import { Box, MenuItem } from "@mui/material";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import TransactionTable from "../Common/TransactionTable";
import CustomSelect from "@speed/common/src/components/Select/Select";
import { currencySupportedByTransaction } from "../constants";
import CommonHeader from "../Common/CommonHeader";

const Transactions = () => {
  const [currency, setCurrency] = useState("BTC");

  const handleChangeCurrency = (e) => {
    setCurrency(
      currencySupportedByTransaction?.find(
        (currency) => currency === e.target.value
      )
    );
  };

  return (
    <Box className="section-wrapper">
      <CommonHeader
        childrenWithHeader={
          <CustomSelect
            showLabel={false}
            customClass="transaction-custom-currency-dropdown"
            value={currency}
            MenuProps={{
              id: "payout-wallet-address",
              disableScrollLock: true,
            }}
            onChange={handleChangeCurrency}
            renderValue={() => currency}
          >
            {currencySupportedByTransaction?.map(
              (dropDownValue, dropDownIndex) => (
                <MenuItem value={dropDownValue} key={dropDownIndex}>
                  <Box>{dropDownValue}</Box>
                </MenuItem>
              )
            )}
          </CustomSelect>
        }
      />
      <CustomDivider />
      <TransactionTable targetCurrency={currency} />
    </Box>
  );
};

export default Transactions;
