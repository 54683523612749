import React from "react";
import GetGuides from "./GetGuides";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Box } from "@mui/material";

const SwapsGuide = () => {
  const { obSwapsguideFe } = useFlags();

  return obSwapsguideFe ? (
    <GetGuides
      moduleName="swaps"
      redirectUrl="/swaps"
      guideApiPath="swaps-guide"
      getStartedApiPath="Swaps"
      needHelpGuidePath="docs/after-the-payment/swaps"
    />
  ) : (
    <Box height="70vh" display="flex" alignItems="center">
      <AccessDenied />
    </Box>
  );
};

export default SwapsGuide;
