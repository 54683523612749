import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import {
  RouteBreadcrumbs,
  renderStatus,
  callAPIInterface,
  noOfRecords,
  dateTimeFormatInApp,
} from "./constants";
import {
  showAmount,
  generateBreadcrumbs,
} from "@speed/common/src/components/constants";
import { TableCell, TableRow, Box } from "@mui/material";
import { useSelector } from "react-redux";
import CustomTable from "@speed/common/src/components/Table/Table";
import "../assets/styles/payment-links.scss";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { emptySubText } from "@speed/common/src/components/messages";
import { emptyPayment } from "./images";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { btc, sats } from "./messages";

const ModulePaymentList = (props) => {
  const urlParams = useParams();

  const { module, ldFlag = true } = props;
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const queryParam = "?limit=" + noOfRecords;

  const [tableRowSkeleton, setTableRowSkeleton] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [rows, setRows] = useState([]);
  const [queryParams, setQueryParams] = useState(queryParam);

  const history = useSelector((state) => state.common.history);
  const liveMode = useSelector((state) => state.auth.liveMode);

  const getAllPayment = (lines, params) => {
    setTableRowSkeleton(true);
    const url = `/${module}/${urlParams.id}/payments${params}`;
    callAPIInterface("GET", url)
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1].id
            );
          }

          let data = res.data;
          if (module === "checkout-sessions") {
            data = data.map((d) => {
              d.status = "paid";
              return d;
            });
          }
          setRows(lines.concat(data));
        }
      })
      .catch(() => {
        setTableRowSkeleton(false);
      });
  };

  useEffect(() => {
    if (ldFlag) {
      setRows([]);
      setHasMore(true);
      getAllPayment([], queryParams);
    }
  }, [liveMode, ldFlag]);

  const columns = [
    { title: "Payment Id", width: "236px", paddingLeft: "35px !important" },
    { title: "Amount", width: "50px" },
    { title: "Date", width: "200px" },
    { title: "Status", width: "50px" },
  ];

  let columnsData = columns.map((column, index) => (
    <TableCell
      key={index}
      align={index === 1 ? "right" : ""}
      sx={{ paddingLeft: column.paddingLeft, minWidth: column.width }}
    >
      {column.title}
    </TableCell>
  ));

  const loadMore = useCallback(() => {
    getAllPayment(rows, queryParams);
  }, [rows]);

  const rowsData = rows?.map((rowItem) => (
    <TableRow
      key={rowItem.id}
      className="clickable"
      onClick={() => history.push(`/payments/` + rowItem.id)}
    >
      <TableCell sx={{ paddingLeft: "35px !important" }}>
        {rowItem.id}
      </TableCell>
      <TableCell align="right">
        {showAmount({
          amount:
            rowItem.status === "paid"
              ? rowItem.target_amount_paid
              : rowItem.target_amount,
          currency: rowItem.target_currency,
          targetedCurrency:
            rowItem.target_currency === sats ? btc : rowItem.target_currency,
          appendCurrency: true,
        })}
      </TableCell>
      <TableCell>{dateTimeFormatInApp(rowItem.created)}</TableCell>
      <TableCell>{renderStatus(rowItem.status)}</TableCell>
    </TableRow>
  ));

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
    className: "link-table",
    textOnNoData: emptySubText(liveMode, "payments"),
    noDataImage: emptyPayment,
  };

  return (
    <Box className="section-wrapper">
      <Box className="payment-link-header-wrapper">
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      </Box>
      <CustomDivider />
      <Box className="payments-wrapper">
        <Box className="section-wrapper">
          <Box className="payment-wrapper">
            <Box className="main-content">
              {ldFlag ? <CustomTable {...tableProps} /> : <AccessDenied />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ModulePaymentList;
