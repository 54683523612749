import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  TableCell,
  TableRow,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch, useSelector } from "react-redux";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import classNames from "classnames";

import {
  clipboardElement,
  getAmountByCurrency,
  getCurrencyObj,
  linkStatus,
} from "@speed/common/src/components/constants";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import { setIsDataAvailable, setIsModalOpen } from "../../redux/common/actions";
import { emptySubText } from "@speed/common/src/components/messages";
import { emptyCheckoutLink } from "../images";
import CustomTable from "@speed/common/src/components/Table/Table";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import HorizontalSearchPageTable from "./HorizontalSearchPageTable";
import {
  activate,
  copied,
  copyPassword,
  createWithdrawalLinkMsg,
  deActivate,
  emptyWithdrawalLinkSubText,
  resendEmail,
  withdrawalLink,
} from "../messages";
import {
  activateDeactivateWithdrawalLink,
  callAPIInterface,
  dateTimeFormatInApp,
  noOfRecords,
  reSendWithdrawalLinkEmail,
  showPassword,
} from "../constants";
import { CustomTooltip } from "@speed/common/src/components/Tooltip/Tooltip";
import { loaderSpinner } from "@speed/common/src/components/images";
import NoDataAvailable from "@speed/common/src/components/NoDataAvailable";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";

const tableHeader = [
  { title: "Links", width: "446px", paddingLeft: "38px !important" },
  { title: "Withdrawal Id", width: "245px" },
  { title: "Email", width: "230px" },
  { title: "Amount", width: "230px", align: "right" },
  // { title: "Price (BTC)", width: '160px', align: 'right' },
  { title: "Created on", width: "235px" },
  { title: "Status", width: "138px" },
  { title: "Actions", width: "25px" },
];

function WithdrawalLinkTable({
  tab,
  showHorizontalTable = false,
  searchQueryUrl,
  inputString,
  deactivateLDFlag,
  detailPageLDFlag,
  copyPasswordLDFlag,
}) {
  const queryParam = "?limit=" + noOfRecords;
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [rows, setRows] = useState([]);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [onDeactivateRows, setOnDeactivateRows] = useState([]);
  const [rowId, setRowId] = useState(null);
  const [rowLoader, setRowLoader] = useState(false);
  const [columns, setColumns] = useState(tableHeader);
  const [copyText, setCopyText] = useState(false);
  const [passwordLoader, setPasswordLoader] = useState(false);
  const [isResendingEmail, setIsResendingEmail] = useState(false);

  const isStatusActive = currentRow?.status === "active";
  const dispatch = useDispatch();
  const { liveMode, currentAccount } = useSelector((state) => state.auth);
  const { history, isDataAvailable, caughtErrorInStrapi } = useSelector(
    (state) => state.common
  );

  const openPopper = Boolean(anchorEl);
  let actions = [];
  const fromSearchPage = history.location.pathname === "/search";

  useEffect(() => {
    setRows([]);
    setHasMore(true);
    getWithdrawalLinks([], queryParam);
  }, [liveMode]);

  useEffect(() => {
    !fromSearchPage && setRows(onDeactivateRows);
  }, [onDeactivateRows]);

  useEffect(() => {
    //change condition when active action is available
    if (tab !== "active" && tab !== undefined) {
      const newColumns = columns.filter((column) => column.title !== "Actions");
      setColumns(newColumns);
    } else {
      setColumns(tableHeader);
    }
  }, [tab]);

  useEffect(() => {
    if (actions.length === 0) {
      setAnchorEl(null);
    }
  }, [openPopper, deactivateLDFlag, copyPasswordLDFlag]);

  const loadMore = useCallback(() => {
    getWithdrawalLinks(rows, queryParams);
  }, [rows]);

  const getWithdrawalLinks = (lines, params) => {
    setTableRowSkeleton(true);
    //remove commented code at the time of implementation of global search
    // if (fromSearchPage) {
    //   setRows(searchWithdrawalLinkRowData);
    //   setTableRowSkeleton(false);
    //   setHasMore(false);
    // } else {
    let method,
      data,
      path = "";
    //use method POST when tab is selected other than 'All'
    if (tab) {
      method = "POST";
      data = { status: tab };
      path = "/withdrawal-links/filter";
    } else {
      method = "GET";
      data = {};
      path = "/withdrawal-links";
    }

    callAPIInterface(method, path + params, data)
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1].id
            );
          }
          setRows(lines.concat(res.data));
          dispatch(setIsDataAvailable(res?.data?.length > 0));
        }
      })
      .catch(() => {
        setTableRowSkeleton(false);
      });
    // }
  };

  const handleClick = (event, rowData) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setCurrentRow(rowData);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick(e, currentRow);
    }
  };

  const columnsData = columns.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{ minWidth: column.width, paddingLeft: column.paddingLeft }}
    >
      {column.title}
    </TableCell>
  ));

  let rowsData = rows?.map((rowItem) => (
    <TableRow
      key={rowItem.id}
      className="clickable"
      onClick={() =>
        detailPageLDFlag && history.push(`/withdrawal-links/${rowItem.id}`)
      }
    >
      {rowItem.id === rowId && rowLoader ? ( // remove condition if action will not be there
        columns.map((column) => {
          return (
            <TableCell
              key={column.title}
              sx={{ padding: "18px 16px !important", width: column.width }}
            >
              <Skeleton />
            </TableCell>
          );
        })
      ) : (
        <>
          <TableCell sx={{ paddingLeft: "38px !important" }}>
            <Box display="flex" alignItems="center">
              {clipboardElement(
                rowItem.url,
                rowItem.status == "active",
                "",
                "inputBox",
                false
              )}
              {rowItem?.is_secured && <LockIcon className="pass-lock-icon" />}
            </Box>
          </TableCell>
          <TableCell>{rowItem.id}</TableCell>
          <TableCell>
            <TruncatedTextTooltip
              textValue={rowItem.email || "-"}
              cellWidth="250px"
            />
          </TableCell>
          <TableCell style={{ textAlign: "right" }}>
            {getCurrencyObj(rowItem.currency)?.symbol}
            {getAmountByCurrency(rowItem.amount, rowItem.currency)}
          </TableCell>
          <TableCell> {dateTimeFormatInApp(rowItem.created)}</TableCell>
          <TableCell>
            <Tag
              text={linkStatus[rowItem?.status].label}
              variant={linkStatus[rowItem.status].variant}
            />
          </TableCell>
          {(tab === "active" || tab === undefined) && (
            <TableCell>
              {rowItem?.status === "active" && (
                <MoreHorizIcon
                  className="horizontal-dots-icon"
                  tabIndex={0}
                  onKeyDown={handleKeyDown}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClick(event, rowItem);
                  }}
                />
              )}
            </TableCell>
          )}
        </>
      )}
    </TableRow>
  ));

  const handleResendEmail = () => {
    setIsResendingEmail(true);
    reSendWithdrawalLinkEmail(currentRow?.id)
      .then((res) => {
        if (res) {
          setIsResendingEmail(false);
          setAnchorEl(null);
        }
      })
      .catch(() => {
        setIsResendingEmail(false);
        setAnchorEl(null);
      });
  };

  const handleActiveDeActive = () => {
    if (!fromSearchPage && isStatusActive) {
      setRowId(currentRow?.id);
      setRowLoader(true);
      activateDeactivateWithdrawalLink(currentRow?.id)
        .then((res) => {
          setAnchorEl(null);
          rows.map((row) => {
            if (row.id === currentRow?.id) {
              row.status = res.status;
            }
          });
          const result =
            tab === "active"
              ? rows.filter((row) => row.id !== currentRow?.id)
              : rows; // filter out row when deactivating link from active tab
          setOnDeactivateRows(result);
          setRowLoader(false);
        })
        .catch((_err) => {
          setRowLoader(false);
        });
    } else {
      setAnchorEl(null);
    }
  };

  const handleCopyPassword = (id) => {
    setPasswordLoader(true);
    showPassword(id, currentAccount?.account?.id).then(async (res) => {
      setPasswordLoader(false);
      setCopyText(true);
      await navigator.clipboard.writeText(res);
      setTimeout(() => {
        setCopyText(false);
      }, 1000);
    });
  };

  const renderAction = () => {
    const actionsObj = {
      copy_password: (
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleCopyPassword(currentRow?.id)}>
            <ListItemText
              primary={copyPassword}
              className={classNames("copy-password")}
            />

            <CustomTooltip
              arrow={false}
              open={copyText}
              placement="top"
              disableFocusListener
              disableHoverListener
              disableTouchListener
              text={
                <>
                  <CheckCircleSharpIcon />
                  <span className="copied-clipboard-text">{copied}</span>
                </>
              }
            >
              <FileCopyIcon
                className="file-copy-icon display-none"
                sx={{ color: "transparent !important" }}
              />
            </CustomTooltip>
            {passwordLoader && (
              <img src={loaderSpinner} alt="Loader" width={15} height={15} />
            )}
          </ListItemButton>
        </ListItem>
      ),
      resend_email: (
        <ListItem disablePadding>
          <ListItemButton
            sx={{ gap: 1 }}
            disabled={isResendingEmail}
            onClick={handleResendEmail}
          >
            <ListItemText primary={resendEmail} />
            {isResendingEmail && (
              <img src={loaderSpinner} alt="Loader" width={15} height={15} />
            )}
          </ListItemButton>
        </ListItem>
      ),
      deactivate_link: (
        <ListItem disablePadding>
          <ListItemButton onClick={handleActiveDeActive}>
            <ListItemText
              primary={isStatusActive ? deActivate : activate}
              className={classNames(
                isStatusActive ? "text-danger" : "text-blue"
              )}
            />
          </ListItemButton>
        </ListItem>
      ),
    };

    if (isStatusActive && currentRow?.email) {
      actions.push(actionsObj.resend_email);
    }

    if (copyPassword && currentRow?.is_secured && copyPasswordLDFlag) {
      actions.push(actionsObj.copy_password);
    }
    if (isStatusActive && deactivateLDFlag) {
      actions.push(actionsObj.deactivate_link);
    }
    return actions.map((action) => {
      return action;
    });
  };

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
    className: "link-table",
    textOnNoData: emptySubText(liveMode, withdrawalLink, tab),
    subTextOnNoData: emptyWithdrawalLinkSubText,
    noDataImage: emptyCheckoutLink,
    createButtonText: createWithdrawalLinkMsg,
    handleCreate: () => dispatch(setIsModalOpen(true)),
  };

  return (
    <>
      {showHorizontalTable && fromSearchPage ? (
        <HorizontalSearchPageTable
          moduleName="withdrawal_link"
          columns={tableHeader}
          rows={tableProps.rows}
          searchQueryUrl={searchQueryUrl}
          inputString={inputString}
        />
      ) : isDataAvailable ? (
        <Box className="main-content">
          <CustomTable {...tableProps} />
        </Box>
      ) : (
        (tab || caughtErrorInStrapi) && (
          <Box className="main-content">
            <Box
              minHeight="calc(100vh - 201px)"
              width="100%"
              display="flex"
              justifyContent="center"
            >
              <NoDataAvailable
                text={emptySubText(liveMode, withdrawalLink, tab)}
                image={emptyCheckoutLink}
                subText={emptyWithdrawalLinkSubText}
                buttonText={createWithdrawalLinkMsg}
                handleCreate={() => dispatch(setIsModalOpen(true))}
              />
            </Box>
          </Box>
        )
      )}
      <CustomPopper
        disablePortal={true}
        open={openPopper}
        anchorEl={anchorEl}
        position="bottom"
        handleClose={() => setAnchorEl(null)}
      >
        <List>{renderAction()}</List>
      </CustomPopper>
    </>
  );
}

export default WithdrawalLinkTable;
