import { Box, Grid } from "@mui/material";
import { useFormik, FieldArray, FormikProvider } from "formik";
import * as yup from "yup";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import Label from "@speed/common/src/components/Label/Label";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import {
  getFormTotalAmount,
  showAmount,
  uniqueMethod,
} from "@speed/common/src/components/constants";
import InputMask from "@speed/common/src/components/MaskInput";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Text from "@speed/common/src/components/Text/Text";
import AddIcon from "@mui/icons-material/Add";
import {
  addAmountOptions,
  amountLabel,
  satsValidationMsg,
  uniqueAmountValue,
  usdt,
} from "../messages";
import {
  checkDisableButton,
  getExchangeRate,
} from "../Common/AllLinkFormConstant";
import { checkZeroAmount } from "../constants";
import { amountExceedMsg, amountValidate } from "@speed/common/src/messages";

const AmountOptions = forwardRef(
  (
    {
      formikValues,
      linkType,
      isCheckedOtherInfo,
      handleButtonDisable,
      parentDisable,
      selectedAssetCurrencyState,
    },
    ref
  ) => {
    const currencyCode = formikValues?.currency?.symbol;
    const [satsValidate, setSatsValidate] = useState({});
    const [amountExceed, setAmountExceed] = useState("");
    const [exchangeRate, setExchangeRate] = useState(false);
    const [currentExchangeRate, setCurrentExchangeRate] = useState();

    // For unique field validation in formik
    uniqueMethod();

    // To check zero amount validation
    checkZeroAmount();

    const validationSchema = yup.object({
      amountOptions: yup
        .array()
        .of(
          yup
            .object()
            .zeroAmountCheck("option", amountValidate)
            .uniqueProperty("option", uniqueAmountValue)
        ),
    });

    const amountOptionsInitialValue = { option: "" };
    const formik = useFormik({
      initialValues: {
        amountOptions: Array(2).fill(amountOptionsInitialValue),
      },
      validationSchema: validationSchema,
    });

    const commonErrorBlock = (errors, index) => {
      return errors?.amountOptions?.[index] ? (
        <Grid item lg={9.5} marginLeft="108px">
          <Box className="error-msg-box">
            {Object.entries(errors.amountOptions[index]).map(
              (item, optionIndex) => {
                const errorMsg = item[1];
                return (
                  Boolean(errorMsg) && (
                    <AlertMessage
                      key={`option_error_${optionIndex}`}
                      message={errorMsg}
                      severity="error"
                    />
                  )
                );
              }
            )}
          </Box>
        </Grid>
      ) : null;
    };

    const ErrorMessageBlock = ({ index, type }) => {
      return commonErrorBlock(type, index);
    };

    const addField = (index, arrayHelpers) => {
      if (index === values.amountOptions.length - 1) {
        arrayHelpers.push(amountOptionsInitialValue);
      }
    };

    const removeField = (index, arrayHelpers) => {
      if (values.amountOptions.length > 1) {
        arrayHelpers.remove(index);
      } else arrayHelpers.replace(index, amountOptionsInitialValue);
    };

    const handleKeypress = (e, index, arrayHelpers) => {
      if (e.keyCode === 13) {
        removeField(index, arrayHelpers);
      }
    };

    const { values, errors, isValid, isSubmitting, dirty, setFieldValue } =
      formik;

    const getLabel = (index) => {
      return (
        <>
          {amountLabel} {index} ({currencyCode})
        </>
      );
    };

    const formValues = { ...formikValues, ...values };

    const showAmountExceededMsg = (item, amountExceedError) => {
      const totalAmount = getFormTotalAmount(
        { amount: item.option, currency: formValues.currency },
        currentExchangeRate
      );
      if (totalAmount.split(" ")[0] > 5)
        amountExceedError.amountOptions.push({
          option: amountExceedMsg(linkType),
        });
      else amountExceedError.amountOptions.push({ option: "" });
    };

    const isValue = values.amountOptions.filter(
      (item) => item.option !== "0" && item.option !== ""
    );

    useEffect(() => {
      if (formValues?.currency) {
        let satValidationObj = { amountOptions: [] };
        let amountExceedError = { amountOptions: [] };

        values.amountOptions.map((item) => {
          const conditionCheck =
            formValues.currency?.code === "SATS" && item.option.includes(".");

          satValidationObj.amountOptions.push({
            option: conditionCheck ? satsValidationMsg : "",
          });
          showAmountExceededMsg(item, amountExceedError);
        });
        setSatsValidate(satValidationObj);
        setAmountExceed(amountExceedError);
      }
    }, [values, formValues.currency, currentExchangeRate]);

    useEffect(() => {
      const timeOut = setTimeout(() => {
        if (values) {
          isValue.length &&
            getExchangeRate({
              amountRef: { current: { amount: isValue[0]?.option } },
              values: formValues,
              errors,
              setCurrentExchangeRate,
              setExchangeRate,
              linkType,
              targetCurrency: selectedAssetCurrencyState,
            });
        }
      }, 500);
      return () => clearTimeout(timeOut);
    }, [values, errors, selectedAssetCurrencyState]);

    useEffect(() => {
      const satsResult = satsValidate?.amountOptions?.filter(
        (item) => item.option
      );
      const amountExceedResult = amountExceed?.amountOptions?.filter(
        (item) => item.option
      );
      const defaultCheck = !(isValid && dirty) || isSubmitting;
      const disable = defaultCheck || parentDisable;
      checkDisableButton({
        amountExceed: amountExceedResult?.length > 0,
        satsValidate: satsResult?.length > 0,
        values,
        exchangeRate,
        handleButtonDisable,
        from: "amountOptions",
        defaultCheck: disable,
      });
    }, [
      dirty,
      isValid,
      isSubmitting,
      formValues,
      exchangeRate,
      isCheckedOtherInfo,
    ]);

    useImperativeHandle(ref, () => ({
      getAmountOption: () => {
        return {
          amountOptionValue: {
            current: { amount: isValue[0]?.option },
          },
          amountOptions: values.amountOptions,
          currentExchangeRate,
        };
      },
      getOptionPayload: () => {
        const currencyCode = formikValues?.currency?.code;
        const optionArr = values.amountOptions.map((item) =>
          showAmount({
            amount: item.option,
            currency: currencyCode,
            divideAmount: currencyCode === usdt,
          })
        );
        const params = {
          amount: showAmount({
            amount: isValue[0]?.option,
            currency: currencyCode,
            divideAmount: currencyCode === usdt,
          }),
          type: "options",
          options: optionArr,
        };
        return params;
      },
    }));

    const handleChangeInput = (e, index) => {
      setExchangeRate(true);
      setFieldValue(`amountOptions[${index}].option`, e.target.value);
    };

    const getErrorCheck = (errors, index) => {
      return (
        errors?.amountOptions?.[index] &&
        Boolean(errors.amountOptions[index].option)
      );
    };

    return (
      <FormikProvider value={formik}>
        <Box className="preset-type-box">
          <FieldArray
            name="amountOptions"
            render={(arrayHelpers) => (
              <Box>
                {values.amountOptions?.length > 0
                  ? values.amountOptions.map((_option, index) => {
                      const optionError = getErrorCheck(errors, index);
                      const optionErrorCustomSats = getErrorCheck(
                        satsValidate,
                        index
                      );
                      const optionErrorCustomBtc = getErrorCheck(
                        amountExceed,
                        index
                      );

                      const isError =
                        optionError ||
                        optionErrorCustomBtc ||
                        optionErrorCustomSats;

                      const customError = optionErrorCustomBtc
                        ? amountExceed
                        : satsValidate;

                      return (
                        <React.Fragment key={`amount_option_${index}`}>
                          <Grid
                            container
                            justifyContent="space-between"
                            className="margin-top15"
                            pl="3px"
                          >
                            <Grid item lg={2.5} className="preset-amount-label">
                              <Label>{getLabel(index + 1)}</Label>
                            </Grid>
                            <Grid item lg={index === 2 ? 7.8 : 9}>
                              <InputMask
                                onChange={(e) => handleChangeInput(e, index)}
                                value={values.amountOptions[index].option}
                                error={isError}
                                name={`amountOptions[${index}].option`}
                                placeholder="0"
                              />
                            </Grid>
                            {index === 2 && (
                              <Grid
                                item
                                lg={0.6}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <RemoveCircleOutlinedIcon
                                  onClick={() =>
                                    removeField(index, arrayHelpers)
                                  }
                                  className="remove-circle-icon"
                                  tabIndex={0}
                                  onKeyUp={(e) =>
                                    handleKeypress(e, index, arrayHelpers)
                                  }
                                />
                              </Grid>
                            )}
                          </Grid>
                          {isError ? (
                            <ErrorMessageBlock
                              index={index}
                              type={optionError ? errors : customError}
                            />
                          ) : null}
                        </React.Fragment>
                      );
                    })
                  : null}
                {values.amountOptions.length < 3 && (
                  <>
                    <CustomDivider className="margin-top25" />
                    <Text
                      withIcon="start"
                      size={14}
                      font="semibold"
                      className="margin-top15"
                      variant="subtitle1"
                      sx={{ color: "#2A67FF", cursor: "pointer" }}
                      onClick={() => addField(1, arrayHelpers)}
                    >
                      <AddIcon
                        sx={{
                          height: "24px",
                          width: "24px",
                          color: "#2A67FF !important",
                        }}
                      />{" "}
                      {addAmountOptions}
                    </Text>
                  </>
                )}
              </Box>
            )}
          />
        </Box>
      </FormikProvider>
    );
  }
);

export default AmountOptions;
