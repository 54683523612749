import React from "react";
import GetGuides from "./GetGuides";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Box } from "@mui/material";

const CustomersGuide = () => {
  const { obCustomersguideFe } = useFlags();

  return obCustomersguideFe ? (
    <GetGuides
      moduleName="customers"
      redirectUrl="/customers"
      guideApiPath="customers-guide"
      getStartedApiPath="Customers"
      needHelpGuidePath="docs/receive-payments/customers"
    />
  ) : (
    <Box height="70vh" display="flex" alignItems="center">
      <AccessDenied />
    </Box>
  );
};

export default CustomersGuide;
