import React, { useContext, useState, useEffect } from "react";
import { Box } from "@mui/material";
import {
  btcToSatsAmount,
  getCurrencyFormat,
  getPreviewTotalAmount,
  handleUpdateCheckoutSession,
} from "./constants";
import Text from "./Text/Text";
import CustomSelect from "./Select/Select";
import MenuItem from "@mui/material/MenuItem";
import { PaymentPageContext } from "../contexts/contexts";

const AmountOptionsPreview = ({
  paymentInfo,
  fontStyle,
  checkoutSessionId,
  visibleInPaymentPage,
  displayAmountInCurrency,
  paymentStatus,
}) => {
  const [amountOptions, setAmountOptions] = useState(null);
  const [currencyValue, setCurrencyValue] = useState("");
  const [amountOptionValue, setAmountOptionValue] = useState({
    label: "",
    value: "",
  });
  const [apiCalled, setApiCalled] = useState(false);

  // Get context value using consumer
  const paymentPageData = useContext(PaymentPageContext);

  const setDefaultValue = () => {
    if (paymentInfo?.amountOptions) {
      const result = paymentInfo.amountOptions.map((item) => {
        const totalAmount = getPreviewTotalAmount({
          ...paymentInfo,
          amount: parseFloat(item.option || item) || 0.0,
        });

        const convertedAmount =
          displayAmountInCurrency?.toLowerCase() === "sats"
            ? `${btcToSatsAmount(totalAmount?.split(" ")[0])} SATS`
            : totalAmount;
        return {
          label: convertedAmount,
          value: convertedAmount,
          amount: item.option || item,
        };
      });
      if (result.length > 0) {
        const activeIndex = result.findIndex(
          (item) => parseFloat(item.amount) === paymentInfo?.amount
        );
        setAmountOptionValue(result[activeIndex]);
        setCurrencyValue(result[activeIndex]?.amount);
        setAmountOptions(result);
      }
    }
  };

  useEffect(() => {
    !visibleInPaymentPage && setDefaultValue();
  }, [paymentInfo]);

  useEffect(() => {
    !apiCalled && setDefaultValue();
  }, [apiCalled]);

  const currencyRender = (value) => (
    <Text
      className="checkout-acc-currency"
      size={18}
      font="regular"
      variant="subtitle1"
      sx={fontStyle}
    >
      {getCurrencyFormat({
        ...paymentInfo,
        amount: parseFloat(value) || 0.0,
      })}
    </Text>
  );

  const updateCheckoutSession = (amount) => {
    if (visibleInPaymentPage) {
      const { setIsToastOpen, setToastMessage } = paymentPageData;
      handleUpdateCheckoutSession(amount, paymentPageData, checkoutSessionId)
        .then(() => {
          setApiCalled(true);
        })
        .catch((errorMessage) => {
          if (errorMessage) {
            setToastMessage(errorMessage);
            setIsToastOpen(true);
          }
          setDefaultValue();
          setApiCalled(false);
        });
    }
  };

  const handleCustomSelect = (e) => {
    setAmountOptionValue({
      label: e.target.value,
      value: e.target.value,
    });
    const result = amountOptions.find((item) => {
      return item.value === e.target.value;
    });
    setCurrencyValue(result?.amount);
    updateCheckoutSession(result?.amount);
  };

  return (
    paymentInfo?.amountOptions?.length > 0 &&
    amountOptions && (
      <Box display="flex" flexDirection="column">
        <CustomSelect
          disabled={paymentStatus === "paid"}
          name="amount_options"
          displayEmpty
          className="amount-options-preview"
          value={amountOptionValue?.value}
          MenuProps={{
            id: "amount-options",
          }}
          onChange={handleCustomSelect}
          renderValue={(value) => (
            <Text component="span" size={36} noWrap>
              {value}
            </Text>
          )}
        >
          {amountOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </CustomSelect>
        {currencyRender(currencyValue)}
      </Box>
    )
  );
};

export default AmountOptionsPreview;
