import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import { Box } from "@mui/system";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import Text from "@speed/common/src/components/Text/Text";
import InputSelectionBox from "@speed/common/src/components/InputSelectionBox/InputSelectionBox";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import {
  generateBreadcrumbs,
  redirectWhenEntityNotFound,
} from "@speed/common/src/components/constants";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { Tag } from "@speed/common/src/components/Tag/Tag";

import {
  apiVersion,
  ipAddress,
  origin,
  reqBodyLabel,
  resBodyLabel,
  source,
  status,
  time,
  details,
  noPostBody,
  noResponseBody,
  entityNotFoundMessage,
  subtitleForIdNotFound,
  buttonTextForIdNotFound,
} from "../messages";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  JsonDataView,
  originURL,
  RouteBreadcrumbs,
} from "../constants";
import LogsSkeleton from "./LogsSkeleton";
import "../../assets/styles/developers.scss";
import { useFlags } from "launchdarkly-react-client-sdk";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";

const LogDetails = (props) => {
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const liveMode = useSelector((state) => state.auth.liveMode);
  const urlParams = useParams();

  const [showSkeleton, setShowSkeleton] = useState(false);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [logDetailData, setLogDetailData] = useState();
  const { logDetailFe } = useFlags();

  const getLogDetail = async () => {
    setShowSkeleton(true);
    await callAPIInterface("GET", `/api-logs/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setLogDetailData(res);
        }
        setShowSkeleton(false);
      })
      .catch((err) => {
        setShowSkeleton(false);
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  useEffect(() => {
    getLogDetail();
  }, [liveMode]);

  const errorCode = logDetailData?.response?.status_code >= 400;

  const headerContent = () => {
    return (
      <Box>
        <Text
          font="regular"
          className="default-text"
          variant="h1"
          sx={{
            marginTop: "18px",
            marginBottom: "12px",
            fontWeight: "600",
            lineHeight: "120% !important",
          }}
        >
          {`${logDetailData?.request?.method} ${logDetailData?.request?.resource_path}`}
        </Text>
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          className="input-selection-box"
          sx={{ marginTop: "18px", marginBottom: "77px" }}
        >
          <InputSelectionBox
            copyText
            customHeight={30}
            customWidth={200}
            disabled
            text={logDetailData?.id}
          />
          <Clipboard text={logDetailData?.id} />
        </Box>
      </Box>
    );
  };

  const detailsKey = (key) => (
    <Text className="grey-text" size={16} variant="body1" font="regular">
      {key}
    </Text>
  );

  const detailValue = (value) => (
    <Text className="default-text" font="regular" size={16} variant="body2">
      {value || "-"}
    </Text>
  );

  const showDetailPage = () => {
    return logDetailFe ? (
      <Grid className={`log-details-wrapper`} container spacing={1}>
        <Box className="section-wrapper" sx={{ position: "relative" }}>
          <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
          {logDetailData ? (
            <>
              {headerContent()}
              <Box className="details-content">
                <Text
                  className="default-text"
                  size={20}
                  variant="h2"
                  marginBottom={2}
                >
                  {details}
                </Text>
                <CustomDivider />
                <Box
                  gap="18px"
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-between"
                  sx={{
                    marginTop: "18px !important",
                    marginBottom: "48px !important",
                  }}
                >
                  <Grid xs={5} item>
                    <Box display="flex">
                      <Grid xs={4} item>
                        {detailsKey(status)}
                      </Grid>
                      <Grid item xs={8}>
                        <Tag
                          text={`${logDetailData?.response?.status_code} ${
                            errorCode ? "ERR" : logDetailData?.response?.status
                          }`}
                          variant={errorCode ? "error" : "success-res"}
                        />
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box display="flex">
                      <Grid item xs={4}>
                        {detailsKey(source)}
                      </Grid>
                      <Grid item xs={8}>
                        {detailValue(logDetailData?.request?.api_source)}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box display="flex">
                      <Grid item xs={4}>
                        {detailsKey(time)}
                      </Grid>
                      <Grid item xs={8}>
                        {detailValue(
                          dateTimeFormatInApp(logDetailData?.request_time)
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box display="flex">
                      <Grid item xs={4}>
                        {detailsKey(origin)}
                      </Grid>
                      <Grid item xs={8}>
                        <CustomLink style={{ cursor: "unset" }}>
                          {originURL(
                            logDetailData?.request?.api_source,
                            logDetailData?.request?.resource_path
                          )}
                        </CustomLink>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box display="flex">
                      <Grid item xs={4}>
                        {detailsKey(ipAddress)}
                      </Grid>
                      <Grid item xs={8}>
                        {detailValue(logDetailData?.request?.client_ip)}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={5}></Grid>
                  <Grid item xs={5}>
                    <Box display="flex">
                      <Grid item xs={4}>
                        {detailsKey(apiVersion)}
                      </Grid>
                      <Grid item xs={8} sx={{ display: "-webkit-inline-box" }}>
                        <CustomLink
                          alignIcon="end"
                          withIcon={
                            <OpenInNewOutlinedIcon
                              htmlColor="#2A67FF"
                              sx={{ height: 18, width: 18 }}
                            />
                          }
                          target="_blank"
                          href={`${process.env.REACT_APP_SPEED_API_URL}reference/versioning#speed-versions`}
                        >
                          <Text
                            className="default-text"
                            font="regular"
                            size={16}
                            variant="body2"
                            sx={{ color: "#2A67FF !important" }}
                          >
                            {moment(
                              logDetailData?.request?.request_headers
                                ?.speed_version
                            ).format("YYYY-MM-DD")}
                          </Text>
                        </CustomLink>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
                <CustomDivider />

                {logDetailData?.response?.response_body?.errors && (
                  <>
                    <Box marginBottom="48px" marginTop="48px" display={"flex"}>
                      <Grid item xs={5.8}>
                        <Box
                          borderRadius="5px"
                          bgcolor="#FFE8E6"
                          py={2}
                          gap={1}
                          width="720px"
                          height="84px"
                        >
                          <Box display={"flex"} sx={{ paddingLeft: "16px" }}>
                            <WarningIcon
                              sx={{
                                color: "#CE3B2C",
                                height: 18,
                                width: 18,
                              }}
                            />
                            <Text
                              font="regular"
                              size={14}
                              variant="body2"
                              color="#CE3B2C"
                              paddingLeft="10px"
                            >
                              {logDetailData?.response?.response_body?.errors[0]
                                ?.type ||
                                logDetailData?.response?.response_body
                                  ?.errors[0]?.message}
                            </Text>
                          </Box>
                          <Box sx={{ paddingLeft: "44px" }}>
                            <Text
                              className="grey-text"
                              font="regular"
                              size={14}
                              variant="body2"
                              sx={{ paddingTop: "8px" }}
                            >
                              {
                                logDetailData?.response?.response_body
                                  ?.errors[0]?.message
                              }
                            </Text>
                          </Box>
                        </Box>
                      </Grid>
                    </Box>
                    <CustomDivider />
                  </>
                )}

                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-between"
                  marginY={6}
                >
                  <Grid item xs={5.8}>
                    <JsonDataView
                      label={reqBodyLabel(logDetailData?.request?.method)}
                      body={logDetailData?.request?.request_body}
                      noDataText={noPostBody(logDetailData?.request?.method)}
                    />
                    <CustomDivider />
                  </Grid>
                  <Grid item xs={5.8}>
                    <JsonDataView
                      label={resBodyLabel}
                      body={logDetailData?.response?.response_body}
                      noDataText={noResponseBody}
                    />
                  </Grid>
                </Box>
              </Box>
            </>
          ) : (
            showSkeleton && <LogsSkeleton />
          )}
        </Box>
      </Grid>
    ) : (
      <AccessDenied />
    );
  };

  return !isShowNotFound ? (
    showDetailPage()
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("Log")}
        subTitle={subtitleForIdNotFound("Log", urlParams.id)}
        btnText={buttonTextForIdNotFound("logs", liveMode)}
      />
    </Box>
  );
};

export default LogDetails;
