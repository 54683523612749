import { captureException } from "@sentry/browser";
import {
  errorStatusCodes,
  serverErrorStatusCodes,
  speedVersion,
} from "@speed/common/src/components/constants";
import axios from "axios";
import axiosRetry from "axios-retry";
import { connectStore } from "../redux/connectStore";
import history from "@speed/common/src/components/history";
import {
  generateToken,
  logInConnectAction,
  setIsLoggedIn,
  showToast,
} from "../redux/authConnect/actions";
import { sessionService } from "redux-react-session";
import {
  errorMessage,
  somethingWrong,
} from "@speed/common/src/components/messages";

export const expireSession = async () => {
  localStorage.clear();
  sessionStorage.clear();
  // connectStore.dispatch({
  //   type: RESET_STORE,
  // });
  sessionService.deleteSession();
  connectStore.dispatch(setIsLoggedIn(false));
};

const allowedMethods = ["GET", "POST", "DELETE", "PUT"];

// need to change as per API
const openAPIEndPoints = ["/verify-user", "/login", "/generate-token"];
const dynamicGetApiEndPoints = ["/link/"];

// need to change as per API
const onlyAuthTokenAPIEndpoints = [
  "/login/mfa/phone-number/resend-otp",
  "/login/mfa/phone-number/verify-otp",
];

export const getTimestamp = () => {
  const date = new Date();
  date.setSeconds(date.getSeconds() + 10);
  return date.getTime();
};

const getHeaders = async (method, path, data, file, fileName) => {
  const host = process.env.REACT_APP_API_ENDPOINT;
  let jwtOptions = {
    method,
    url: `${host}${path}`,
  };

  const formData = file ? new FormData() : null;
  if (file) {
    formData.append("file", file, fileName);
  }

  if (data || allowedMethods.includes(method)) {
    const speedUser = data?.speedUser;
    if (speedUser) {
      delete data.speedUser;
    }

    jwtOptions.data = file ? formData : data;
    jwtOptions.headers = jwtOptions.headers || {};
    jwtOptions.headers["Content-Type"] = file
      ? "multipart/form-data"
      : "application/json";
    jwtOptions.headers["Accept"] = "application/json";

    if (speedUser) {
      const loggedInUser = connectStore.getState().authConnect?.user;
      if (loggedInUser) {
        jwtOptions.headers["speed-user"] = loggedInUser?.id;
      }
    }
    const isEndPointIncluded = (endPointsList, endPoint) =>
      endPointsList.includes(endPoint);

    const isOpenAPI =
      isEndPointIncluded(openAPIEndPoints, path) ||
      (method === "GET" &&
        dynamicGetApiEndPoints.some((endpoint) => path.includes(endpoint)));
    const isOnlyAuthRequired = isEndPointIncluded(
      onlyAuthTokenAPIEndpoints,
      path
    );

    if (!isOpenAPI) {
      await sessionService
        .loadSession()
        .then(async (session) => {
          if (session.access_token) {
            jwtOptions.headers["Access"] = session.access_token;
            jwtOptions.headers["speed-version"] = speedVersion;
          }
          if (session.id_token) {
            jwtOptions.headers["Authorization"] = session.id_token;
          }

          if (!isOnlyAuthRequired) {
            jwtOptions.headers["speed-livemode"] =
              session.livemode !== undefined ? session.livemode : false;

            if (session.speed_acc_id) {
              jwtOptions.headers["speed-account"] = session.speed_acc_id;
            }
          }
        })
        .catch((_err) => {
          jwtOptions = {};
          expireSession();
        });
    }
  }
  return jwtOptions;
};

const redirectAfterError = (err, reject) => {
  captureException(err);
  connectStore.getState().connectSession.authenticated
    ? expireSession()
    : history.push("/connect-account");
  connectStore.dispatch(
    showToast({
      isToastOpen: true,
      toastMessage: somethingWrong,
      toastVariant: "error",
    })
  );
  reject(503);
};

export const callAPIInterfaceConnect = (
  method,
  path,
  data = "",
  file = null,
  fileName = null
) => {
  return new Promise(async (resolve, reject) => {
    let jwtOptions = await getHeaders(method, path, data, file, fileName);
    if (Object.keys(jwtOptions)?.length) {
      let apiCall = axios({
        ...jwtOptions,
      });
      apiCall
        .then((res) => {
          resolve(res?.data);
        })
        .catch(async (err) => {
          if (err.response) {
            const errors = err.response.data?.errors;
            const errorStatus = err.response?.status;
            const errorType = errors?.[0]?.type?.toLowerCase();
            const errorParam = errors?.[0]?.param?.toLowerCase();

            if (serverErrorStatusCodes.includes(errorStatus)) {
              captureException(err);
              connectStore.getState().connectSession.authenticated
                ? await expireSession()
                : history.push("/connect-account");
              errors?.[0]?.message &&
                connectStore.dispatch(
                  showToast({
                    isToastOpen: true,
                    toastMessage: errors[0].message,
                    toastVariant: "error",
                  })
                );
              reject(errorStatus);
            } else if (errorStatusCodes.includes(errorStatus)) {
              if (errorType === "token_expired") {
                connectStore
                  .dispatch(generateToken())
                  .then(async (res) => {
                    jwtOptions.headers["Access"] = res.access_token;
                    jwtOptions.headers["Authorization"] = res.id_token;
                    axiosRetry(
                      axios({
                        ...jwtOptions,
                      })
                        .then((response) => {
                          resolve(response?.data);
                        })
                        .catch(async (retryError) => {
                          // This is for: After new tokens were generated if any error will be occurred in the current API then the user will be logged out.
                          const pathNotRedirectToLogin = ["/mfa"];
                          if (
                            pathNotRedirectToLogin.some((pathName) => {
                              return path.includes(pathName);
                            })
                          ) {
                            const searchAPIError =
                              retryError.response.data.errors;
                            searchAPIError?.[0]?.message &&
                              (await connectStore.dispatch(
                                showToast({
                                  isToastOpen: true,
                                  toastMessage: searchAPIError[0].message,
                                  toastVariant: "error",
                                })
                              ));
                            reject(retryError);
                          } else {
                            expireSession();
                            connectStore.dispatch(
                              showToast({
                                isToastOpen: true,
                                toastMessage: errorMessage,
                                toastVariant: "error",
                              })
                            );
                          }
                        }),
                      { retries: 1 }
                    );
                  })
                  .catch((tokenError) => {
                    const tokenErrors = tokenError?.response?.data?.errors[0];
                    if (tokenErrors) {
                      // If any error occurs during generate token then user will be logged out.
                      expireSession();
                      path !== "/logout" &&
                        connectStore.dispatch(
                          showToast({
                            isToastOpen: true,
                            toastMessage: errorMessage,
                            toastVariant: "error",
                          })
                        );
                    }
                  });
              } else if (errorType === "unauthorized") {
                expireSession();
              } else {
                reject(err);
              }
              const errorTypesArr = [
                "invalid_refresh_token",
                "token_expired",
                "verification_link",
              ];

              const shouldShowToast =
                errorTypesArr.includes(errorType) ||
                errorParam === "verification_link";

              errors?.[0]?.message &&
                !shouldShowToast &&
                (await connectStore.dispatch(
                  showToast({
                    isToastOpen: true,
                    toastMessage: errors[0].message,
                    toastVariant: "error",
                  })
                ));
            } else {
              redirectAfterError(err, reject);
            }
          } else if (err.request) {
            redirectAfterError(err, reject);
          }
        });
    } else {
      reject({});
    }
  });
};

export const callStrapiConnectAPIInterface = (method, path) => {
  return new Promise((resolve, reject) => {
    const host = process.env.REACT_APP_STRAPI_API_ENDPOINT;
    const token = process.env.REACT_APP_STRAPI_API_TOKEN;
    const jwtOptions = {
      method,
      url: `${host}${path}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const apiCall = axios(jwtOptions);
    apiCall
      .then((res) => {
        resolve(res.data);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};

export const logInConnect = (data) => {
  return connectStore.dispatch(logInConnectAction(data));
};
