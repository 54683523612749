import { AppBar, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import { back } from "@speed/common/src/components/messages";
import Text from "@speed/common/src/components/Text/Text";
import React, { forwardRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  fail,
  finalInstallationHoverText,
  finishInstallation,
  loadingMsg,
  notStartedYet,
  shopifyInstallation,
  speedPayment,
  success,
  testConnection,
} from "../../messages";
import InstallationStep1 from "./InstallationStep1";
import InstallationStep2 from "./InstallationStep2";
import InstallationStep3 from "./InstallationStep3";
import InstallationStep4 from "./InstallationStep4";
import InstallationStep5 from "./InstallationStep5";
import CloseIcon from "@mui/icons-material/Close";
import history from "@speed/common/src/components/history";
import Button from "@speed/common/src/components/Button/Button";
import "../../../assets/styles/manage-apps.scss";
import {
  callAPIInterface,
  getLabelForButton,
  getManageAppFireStoreAuthenticateData,
  getManageAppFireStoreVerifyData,
  validateURL,
} from "../../constants";
import { CustomTooltip } from "@speed/common/src/components/Tooltip/Tooltip";
import { Box } from "@mui/system";
import { sessionService } from "redux-react-session";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const shopifySteps = ["1", "2", "3", "4", "5"];

const Shopify = () => {
  const urlParams = useParams();
  const [isLoading, setLoading] = useState(false);
  const [testingConnection, setTestingConnection] = useState(false);
  const [session, setSession] = useState(null);
  const [installButtonDisabled, setInstallButtonDisabled] = useState(true);
  const [stepOneVerified, setStepOneVerified] = useState(notStartedYet);
  const [verifySteps, setVerifySteps] = useState(notStartedYet);
  const [webhookDetails, setWebhookDetails] = useState(null);

  useEffect(() => {
    sessionService.loadSession().then((newSession) => {
      setSession(newSession);
    });
  }, []);

  useEffect(() => {
    if (!shopifySteps.includes(urlParams.step)) {
      history.push("/dashboard");
    }
  }, [urlParams]);

  const validationSchema = [
    yup.object({
      store_name: yup.string().required(""),
      store_url: yup
        .mixed()
        .required()
        .test({
          name: "storeUrl",
          test: (storeUrl) => validateURL(storeUrl),
        }),
    }),
    yup.object({
      webhook_secret_key: yup.string().required(""),
    }),
    yup.object({
      custom_payment_method_name: yup.string().required("").min(1).max(100),
    }),
  ];

  const formik = useFormik({
    initialValues: {
      store_name: "",
      store_url: "",
      webhook_secret_key: "",
      custom_payment_method_name: speedPayment,
      event_name: "Order",
      webhook_api_version: "2023-01",
    },
    validationSchema:
      urlParams.step === "1"
        ? validationSchema[0]
        : urlParams.step === "3"
        ? validationSchema[1]
        : urlParams.step === "4" && validationSchema[2],
    onSubmit: () => {},
  });

  const { values, setFieldValue, resetForm, touched, errors, setTouched } =
    formik;
  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const handleModalClose = () => {
    resetForm();
    history.push("/manage-apps");
  };

  const handleButtonDisableCondition = (step) => {
    switch (step) {
      case "1": {
        return !(values.store_name && values.store_url && !errors.store_url);
      }
      case "2":
        return false;
      case "3":
        return !values.webhook_secret_key;
      case "4":
        return !values.custom_payment_method_name;
      default:
        return false;
    }
  };

  const handleAuthenticateStore = () => {
    const authenticateStoreData = {
      authentication_step: {
        store_name: values.store_name,
        store_url: values.store_url,
      },
    };
    callAPIInterface(
      "POST",
      `/apps/shopify/step/1?state=${session?.appState}`,
      JSON.stringify(authenticateStoreData)
    )
      .then((result) => {
        setLoading(false);
        getManageAppFireStoreAuthenticateData(values.store_url);
        window.open(result?.shopify_authentication_url, "_blank");
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSubscribeWebhook = async () => {
    const subscribeWebhookData = {
      subscribe_webhook_step: {
        event_name: values.event_name,
        webhook_api_version: values.webhook_api_version,
      },
    };
    const session = await sessionService.loadSession();
    callAPIInterface(
      "POST",
      `/apps/shopify/step/2?state=${session?.appState}`,
      JSON.stringify(subscribeWebhookData)
    )
      .then((result) => {
        setWebhookDetails(result);
      })
      .catch((error) => {});
  };

  const handleAddWebhookSecretKey = () => {
    const addSubscribeWebhookData = {
      webhook_secret_step: {
        secret: values.webhook_secret_key,
      },
    };
    callAPIInterface(
      "POST",
      `/apps/shopify/step/3?state=${session?.appState}`,
      JSON.stringify(addSubscribeWebhookData)
    )
      .then((result) => {
        setLoading(false);
        history.push("/manage-apps/shopify/4");
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleAddPaymentMethod = () => {
    const addPaymentMethodData = {
      payment_method_step: {
        payment_method: values.custom_payment_method_name,
      },
    };
    callAPIInterface(
      "POST",
      `/apps/shopify/step/4?state=${session?.appState}`,
      JSON.stringify(addPaymentMethodData)
    )
      .then((result) => {
        setLoading(false);
        history.push("/manage-apps/shopify/5");
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleTestConnection = () => {
    callAPIInterface("POST", `/apps/shopify/verify?state=${session?.appState}`)
      .then(async (result) => {
        setStepOneVerified(success);
        setTestingConnection(false);
        getManageAppFireStoreVerifyData(
          session?.appState,
          result?.order_id,
          setVerifySteps,
          setInstallButtonDisabled
        );
      })
      .catch((error) => {
        setStepOneVerified(fail);
        setVerifySteps(fail);
        setTestingConnection(false);
        setInstallButtonDisabled(true);
      });
  };

  const handleApiCallSteps = (step) => {
    if (step === "1") {
      handleAuthenticateStore();
    } else if (step === "2") {
      history.push("/manage-apps/shopify/3");
      setLoading(false);
    } else if (step === "3") {
      handleAddWebhookSecretKey();
    } else if (step === "4") {
      handleAddPaymentMethod();
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={true}
      TransitionComponent={Transition}
      className="fullscreen-modal"
      onClose={handleModalClose}
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {shopifyInstallation}
          </Text>
          {urlParams.step !== "5" ? (
            <>
              {urlParams.step !== "1" && (
                <Button
                  variant="outlined"
                  color="primary"
                  label={back}
                  onClick={() => {
                    history.push(
                      `/manage-apps/shopify/${parseInt(urlParams.step) - 1}`
                    );
                  }}
                  sx={{ marginRight: "18px" }}
                />
              )}
              <Button
                label={getLabelForButton(urlParams.step)}
                variant="contained"
                color="primary"
                loading={isLoading}
                className="payout-link-btn"
                disabled={handleButtonDisableCondition(urlParams.step)}
                onClick={() => {
                  setLoading(true);
                  handleApiCallSteps(urlParams.step);
                }}
              />
            </>
          ) : (
            <>
              {installButtonDisabled && (
                <Button
                  label={testConnection}
                  variant="contained"
                  color="primary"
                  loading={testingConnection}
                  className="payment-link-btn"
                  onClick={() => {
                    setTestingConnection(true);
                    setStepOneVerified(loadingMsg);
                    setVerifySteps(loadingMsg);
                    handleTestConnection();
                  }}
                />
              )}
              {installButtonDisabled ? (
                <CustomTooltip
                  className="custom-tooltip payments-status-tooltip"
                  arrow={true}
                  placement={"bottom-end"}
                  text={
                    <Text size={14} font="regular" className="default-text">
                      {finalInstallationHoverText}
                    </Text>
                  }
                >
                  <Box>
                    <Button
                      component="div"
                      label={finishInstallation}
                      variant="contained"
                      color="primary"
                      className="payment-link-btn button-tooltip"
                      disabled={installButtonDisabled}
                    />
                  </Box>
                </CustomTooltip>
              ) : (
                <Button
                  label={finishInstallation}
                  variant="contained"
                  color="primary"
                  className="payment-link-btn"
                  onClick={() => history.push("/manage-apps")}
                />
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
      {urlParams.step === "1" ? (
        <InstallationStep1
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          setTouched={setTouched}
          defaultErrorMessageProps={defaultErrorMessageProps}
        />
      ) : urlParams.step === "2" ? (
        <InstallationStep2
          handleSubscribeWebhook={handleSubscribeWebhook}
          webhookDetails={webhookDetails}
        />
      ) : urlParams.step === "3" ? (
        <InstallationStep3
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          setTouched={setTouched}
        />
      ) : urlParams.step === "4" ? (
        <InstallationStep4
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          setTouched={setTouched}
        />
      ) : (
        <InstallationStep5
          stepOneVerified={stepOneVerified}
          verifySteps={verifySteps}
        />
      )}
    </Dialog>
  );
};

export default Shopify;
