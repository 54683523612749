import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, TableCell, TableRow } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import Button from "@speed/common/src/components/Button/Button";
import {
  createNew,
  createTransfer,
  emptyTransferSubText,
  moduleAmountQty,
  transferUniqueDestinationAccount,
  transferValidDestinationAccount,
} from "../messages";
import CustomTable from "@speed/common/src/components/Table/Table";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { emptyTransfers } from "@speed/common/src/components/images";
import {
  clipboardElement,
  fetchPrecisedInteger,
  fetchRawBalanceData,
  getCurrencyObj,
  integerWithCurrency,
} from "@speed/common/src/components/constants";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  getAccNameFromId,
  isTransferAccountValid,
  minimumSatsAmountToTransfer,
  noOfRecords,
  renderSenderReceiverOfTransfer,
  transferType,
} from "../constants";
import { emptySubText } from "@speed/common/src/components/messages";
import CreateTransferModal from "./CreateTransferModal";
import { setIsDataAvailable, setIsModalOpen } from "../../redux/common/actions";
import { validAmount } from "@speed/common/src/messages";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";

const TransferList = () => {
  const amountRef = useRef(null);
  const queryParam = "?limit=" + noOfRecords;
  const [rows, setRows] = useState([]);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [disableCreateButton, setDisableCreateButton] = useState(true);
  const [goToNextPage, setGoToNextPage] = useState(false);
  const [disableConfirm, setDisableConfirm] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);

  const { currentAccount, liveMode } = useSelector((state) => state.auth);
  const { history, isDataAvailable, isModalOpen, caughtErrorInStrapi } =
    useSelector((state) => state.common);
  const dispatch = useDispatch();
  const { trListingFe, trCreateFe } = useFlags();

  useEffect(() => {
    if (isModalOpen) {
      handleCreateTransfer();
    }
  }, [isModalOpen]);

  const validationSchema = yup.object({
    amount: yup.number().when(["balance", "currency"], (balance, currency) => {
      if (balance && currency) {
        return yup
          .number()
          .typeError(validAmount)
          .min(
            minimumSatsAmountToTransfer,
            moduleAmountQty(
              minimumSatsAmountToTransfer,
              fetchPrecisedInteger(balance, 8),
              "Transfer",
              currency?.code
            )
          )
          .max(
            balance,
            moduleAmountQty(
              minimumSatsAmountToTransfer,
              fetchPrecisedInteger(balance, 8),
              "Transfer",
              currency?.code
            )
          )
          .required("");
      }
    }),
    destination_account: yup
      .string()
      .required("")
      .matches(isTransferAccountValid, transferValidDestinationAccount)
      .not([currentAccount?.account?.id], transferUniqueDestinationAccount),
  });

  const initialValues = {
    amount: "",
    destination_account: "",
    balance: "",
    currency: getCurrencyObj("SATS"),
    description: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (value) => {
      setDisableConfirm(true);
      const transferData = {
        amount: value?.amount,
        currency: value?.currency?.code,
        destination_account: value?.destination_account,
        description: value?.description,
      };
      callAPIInterface(
        "POST",
        "/balances/transfer",
        JSON.stringify(transferData)
      )
        .then((result) => {
          result && history.push(`/transfers/${result.id}`);
          setDisableConfirm(false);
          handleModalClose();
        })
        .catch((err) => {
          setDisableConfirm(false);
          handleModalClose();
        });
    },
  });

  const {
    values,
    setFieldValue,
    touched,
    setTouched,
    isValid,
    dirty,
    handleSubmit,
    errors,
    resetForm,
  } = formik;

  const getTransfers = (lines, params) => {
    setTableRowSkeleton(true);
    callAPIInterface("GET", "/balances/transfer" + params, {})
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1].id
            );
          }
          setRows(lines.concat(res.data));
          dispatch(setIsDataAvailable(res.data.length > 0));
        }
      })
      .catch((error) => {
        setTableRowSkeleton(false);
      });
  };

  const fetchTransfers = () => {
    setRows([]);
    setHasMore(true);
    getTransfers([], queryParam);
  };

  useEffect(() => trListingFe && fetchTransfers(), [liveMode, trListingFe]);

  useEffect(() => {
    setDisableCreateButton(true);
    const timer = setTimeout(() => setDisableCreateButton(false), 500);
    return () => clearTimeout(timer);
  }, [liveMode]);

  const loadMore = useCallback(() => {
    getTransfers(rows, queryParams);
  }, [rows]);

  const getBalance = () => {
    return new Promise((resolve, reject) => {
      callAPIInterface("GET", "/balances")
        .then((response) => {
          resolve(response);
        })
        .catch((_error) => {
          reject(_error);
        });
    });
  };

  const handleCreateTransfer = () => {
    dispatch(setIsModalOpen(true));
    setBalanceLoading(true);
    getBalance()
      .then((res) => {
        setBalanceLoading(false);
        const resultData = fetchRawBalanceData(res);
        const data = resultData.map((item) => {
          return { ...item, amount: Math.floor(item.amount) };
        });
        const amountBalance =
          data?.find(
            (value) => value?.target_currency === values?.currency?.code
          )?.amount || 0;
        setFieldValue("balance", amountBalance);
      })
      .catch((error) => {
        setBalanceLoading(false);
      });
  };

  const handleModalClose = () => {
    dispatch(setIsModalOpen(false));
    resetForm(initialValues);
    setGoToNextPage(false);
    setIsChecked(false);
  };

  const handleBackButton = () => setGoToNextPage(false);
  const handleNextButton = () => setGoToNextPage(true);

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const columns = [
    {
      title: "Transfer ID",
      paddingLeft: "38px !important",
      width: "200px",
    },
    { title: "From Account", width: "200px" },
    { title: "Destination Account", width: "200px" },
    { title: "Type", width: "130px" },
    { title: "Transfer Amount", align: "right", width: "230px" },
    { title: "Created on", width: "225px" },
  ];

  const columnsData = columns.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{
        minWidth: column.width,
        height: "50px",
        paddingLeft: column.paddingLeft,
      }}
    >
      {column.title}
    </TableCell>
  ));

  const rowsData = rows?.map((rowItem) => {
    const getAccountId = (isDestinationAccount = false) =>
      renderSenderReceiverOfTransfer(rowItem, currentAccount?.account?.id)?.[
        isDestinationAccount ? "receiver" : "sender"
      ];

    return (
      <TableRow
        key={rowItem?.id}
        className="clickable"
        onClick={() => history.push(`/transfers/${rowItem?.id}`)}
      >
        <TableCell sx={{ paddingLeft: "38px !important" }}>
          {clipboardElement(
            rowItem?.id,
            true,
            "payments-listing-id-clipboard",
            "inputBox",
            false
          )}
        </TableCell>
        <TableCell sx={[{ height: "50px" }]}>
          <TruncatedTextTooltip
            textValue={getAccNameFromId(getAccountId())}
            cellWidth="300px"
          />
        </TableCell>

        <TableCell>
          <TruncatedTextTooltip
            textValue={getAccNameFromId(getAccountId(true))}
            cellWidth="300px"
          />
        </TableCell>
        <TableCell>{transferType[rowItem?.type]?.label}</TableCell>
        <TableCell>
          <Box display="flex" justifyContent="end" gap={0.2}>
            {integerWithCurrency(rowItem?.currency, rowItem?.amount)}
          </Box>
        </TableCell>
        <TableCell>{dateTimeFormatInApp(rowItem?.created)}</TableCell>
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    textOnNoData: emptySubText(liveMode, "Transfer"),
    subTextOnNoData: emptyTransferSubText,
    noDataImage: emptyTransfers,
    createButtonText: trCreateFe && disableCreateButton && createTransfer,
    handleCreate: handleCreateTransfer,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
  };
  return (
    <>
      <Box className="action-btn-wrapper mui-fixed">
        {trCreateFe
          ? (isDataAvailable || caughtErrorInStrapi) && (
              <Button
                icon="addIcon"
                className="add-icon"
                label={createNew}
                variant="outlined"
                color="primary"
                onClick={() => dispatch(setIsModalOpen(true))}
                disabled={disableCreateButton}
              />
            )
          : null}
      </Box>
      <Box className="main-content">
        {trListingFe ? (
          (isDataAvailable || caughtErrorInStrapi) && (
            <CustomTable {...tableProps} />
          )
        ) : (
          <AccessDenied />
        )}
        <CreateTransferModal
          openModal={isModalOpen}
          handleModalClose={handleModalClose}
          goToNextPage={goToNextPage}
          handleBackButton={handleBackButton}
          handleNextButton={handleNextButton}
          disableConfirm={disableConfirm}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          isValid={isValid}
          dirty={dirty}
          handleSubmit={handleSubmit}
          touched={touched}
          setTouched={setTouched}
          amountRef={amountRef}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          defaultErrorMessageProps={defaultErrorMessageProps}
          balanceLoading={balanceLoading}
          currentAccountId={currentAccount?.account?.id}
          minimumAmount={minimumSatsAmountToTransfer}
        />
      </Box>
    </>
  );
};

export default TransferList;
