import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Grid from "@mui/material/Grid";
import { Input } from "@speed/common/src/components/Input/Input";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  invalidEmail,
  emailLabel,
  emailPlaceholder,
} from "@speed/common/src/components/messages";
import AlertMessage from "../../AlertMessage/AlertMessage";
import { ConnectContext } from "../../../contexts/contexts";

const EmailScreen = forwardRef(({ emailScreen, setEmail }, ref) => {
  const [opacity, setOpacity] = useState(0);
  const validationSchema = yup.object({
    email: yup.string().email(invalidEmail),
  });

  useState(
    () =>
      setTimeout(() => {
        setOpacity(emailScreen ? 1 : 0);
      }, 300),
    [emailScreen]
  );

  // Get context value using consumer
  const connectPageData = useContext(ConnectContext);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
  });

  const {
    values,
    touched,
    errors,
    setTouched,
    setFieldValue,
    isValid,
    isSubmitting,
    dirty,
    setSubmitting,
    resetForm,
  } = formik;

  useImperativeHandle(ref, () => ({
    resetForm,
  }));

  useEffect(() => {
    setEmail(values.email);
  }, [values]);

  useEffect(() => {
    connectPageData?.setButtonDisabled(!(isValid && dirty) || isSubmitting);
  }, [isValid, dirty, isSubmitting]);

  return (
    <Grid
      container
      width="100%"
      sx={{ transition: "opacity 0.7s ease", opacity: opacity }}
    >
      <Input
        fullWidth
        customClass="wrapper-content-email"
        label={emailLabel}
        placeholder={emailPlaceholder}
        name="email"
        value={values.email}
        type="email"
        onBlur={() => setTouched({ ...touched, email: true })}
        onChange={(e) => {
          setSubmitting(false);
          setTouched({ ...touched, email: false });
          setFieldValue("email", e.target.value);
        }}
        error={touched.email && Boolean(errors.email)}
      />
      {touched.email && Boolean(errors.email) && (
        <AlertMessage
          message={errors.email}
          className="margin-top15"
          severity="error"
          sx={{ width: "100%" }}
        />
      )}
    </Grid>
  );
});

export default EmailScreen;
