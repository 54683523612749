import React from "react";
import GetGuides from "./GetGuides";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Box } from "@mui/material";

const PointOfSaleGuide = () => {
  const { obPointofsaleguideFe } = useFlags();

  return obPointofsaleguideFe ? (
    <GetGuides
      moduleName="point_of_sale"
      redirectUrl="/speed-point-of-sale"
      guideApiPath="point-of-sale"
      getStartedApiPath="Point"
    />
  ) : (
    <Box height="70vh" display="flex" alignItems="center">
      <AccessDenied />
    </Box>
  );
};

export default PointOfSaleGuide;
