import React, { useMemo } from "react";
import CommonMobileView from "./CommonMobileView";
import LatestMobileDesign from "@speed/common/src/components/LatestMobileDesign/LatestMobileDesign";
import { useDispatch, useSelector } from "react-redux";
import { shipping } from "../messages";
import { SET_CUSTOM_FIELD_UPDATED_FROM } from "../../redux/common/types";
import { updatePaymentInfo } from "../../redux/common/actions";

const RenderLinkMobilePreview = ({
  paymentInfo,
  respectedLinksProps,
  dummyDomain,
  componentProps,
  showPageLoader,
  marginTop,
  marginBottom,
}) => {
  const dispatch = useDispatch();
  const { currentAccount, liveMode } = useSelector((state) => state.auth);
  const {
    brandingPageUI,
    paymentMessage,
    checkoutLoader,
    isMobile,
    onChainPaymentMethodOn,
    paymentPageSettings,
    customFieldUpdatedFrom,
  } = useSelector((state) => state.common);

  const globalStates = {
    currentAccount,
    paymentInfo,
    brandingPageUI,
    liveMode,
    paymentMessage,
    isMobile,
    customFieldUpdatedFrom,
  };

  const updatePaymentInfoFn = (paymentInfo) => {
    dispatch(updatePaymentInfo(paymentInfo));
  };

  const mobileViewProps = useMemo(() => {
    return {
      ...respectedLinksProps,
      headerSectionCustomClass: "branding-header-section",
      paymentPageSettings,
      updatePaymentInfoFn,
      setCustomFiledUpdatedFrom: (value) =>
        dispatch({ type: SET_CUSTOM_FIELD_UPDATED_FROM, payload: value }),
    };
  }, [paymentInfo, respectedLinksProps]);

  return (
    <CommonMobileView
      marginTop={marginTop}
      marginBottom={marginBottom}
      dummyDomain={dummyDomain}
    >
      <LatestMobileDesign
        {...componentProps}
        {...globalStates}
        mobileViewProps={mobileViewProps}
        checkoutLoader={checkoutLoader}
        type="phone"
        onchainPaymentMethod={onChainPaymentMethodOn}
        showPageLoader={showPageLoader}
        checkedBillingAddress={paymentInfo?.isCheckedBilling}
        checkedShippingAddress={paymentInfo?.addressType === shipping}
        checkedEmailAddress={paymentInfo?.isCheckedEmail}
        checkedPhoneAddress={paymentInfo?.isCheckedPhone}
      />
    </CommonMobileView>
  );
};

export default RenderLinkMobilePreview;
