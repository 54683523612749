import React, { useEffect, useState, memo } from "react";
import { Modal } from "./Modal/Modal";
import { Box, MenuItem } from "@mui/material";
import {
  issueWithTrans,
  paymentMethodLabel,
  submitButtonLabel,
} from "../messages";
import Text from "./Text/Text";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextAreaComponent } from "./TextArea/TextArea";
import Button from "./Button/Button";
import { tickIcon } from "./images";
import CustomSelect from "./Select/Select";
import {
  callAPIInterfaceCommon,
  getWalletsData,
  paymentMethodsArr,
} from "./constants";
import WalletsWithOtherInput from "./WalletsWithOtherInput";

const CommonReportFeedbackModal = ({
  visibleInPaymentPage,
  isOnchainAvailable = true,
  activeSection = null,
  successMsg,
  openModal,
  setOpenModal,
  modalTitle,
  from,
  walletInputLabel,
  commentInputLabel,
  commentInputPlaceholder,
  sourceId,
  businessName,
  targetCurrency,
}) => {
  const [walletData, setWalletData] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const validationSchema = yup.object({
    wallets: yup.mixed().required(""),
    reason: yup.string().required(""),
    other_wallet: yup.string().when("wallets", {
      is: (value) => value === "other",
      then: yup.string().required(""),
      otherwise: yup.string().notRequired(),
    }),
  });

  const validationSchemaForError = yup.object({
    reason: yup.string().required(""),
  });

  const fromError = from === "under-maintenance";

  const handleSave = (values) => {
    setButtonLoader(true);
    const { wallets, reason, payment_method, other_wallet } = values;
    const walletName = wallets === "other" ? other_wallet : wallets?.name;
    const fromArr = ["under-maintenance", "report"];
    let params = {
      wallet_name: fromError ? "NA" : walletName,
      comment: reason,
      type: fromArr.includes(from) ? "REPORT_ISSUE" : "FEEDBACK",
      source_id: sourceId,
      target_currency: targetCurrency,
      business_name: businessName,
      payment_method: fromError ? "NA" : payment_method,
    };

    callAPIInterfaceCommon("POST", "/utility/consumer-data", params)
      .then(() => {
        setSubmitted(true);
      })
      .finally(() => {
        setButtonLoader(false);
        setSubmitting(false);
      });
  };

  const paymentMethodOptions = isOnchainAvailable
    ? paymentMethodsArr
    : Array(paymentMethodsArr[0]);

  const activeSectionCheck =
    paymentMethodOptions[activeSection === "1" ? 0 : 1]?.value;

  const formik = useFormik({
    initialValues: {
      wallets: null,
      reason: "",
      payment_method: isOnchainAvailable
        ? activeSectionCheck
        : paymentMethodOptions[0]?.value,
      other_wallet: "",
    },
    validationSchema: fromError ? validationSchemaForError : validationSchema,
    onSubmit: handleSave,
    enableReinitialize: true,
  });

  const handleClose = () => {
    setOpenModal(false);
    setTimeout(() => {
      setSubmitted(false);
      resetForm({});
    }, 200);
  };

  useEffect(() => {
    submitted && setTimeout(() => handleClose(), 5000);
  }, [submitted]);

  const {
    values,
    setFieldValue,
    resetForm,
    handleSubmit,
    setSubmitting,
    isValid,
    dirty,
    isSubmitting,
  } = formik;

  useEffect(() => {
    from !== "under-maintenance" && getWalletsData(setWalletData);
  }, []);

  const successBody = (
    <Box className="content-center" flexDirection="column">
      <img
        src={tickIcon}
        style={{ height: "44px", width: "44px" }}
        alt="tick-icon-img"
      />
      <Text
        size={20}
        className="default-text margin-top15"
        variant="subtitle1"
        font="semibold"
        align="center"
      >
        {successMsg}
      </Text>
    </Box>
  );

  const body = submitted ? (
    successBody
  ) : (
    <Box width="100%">
      {from === "report" && (
        <Text
          size={16}
          font="semibold"
          className="default-text"
          variant="subtitle1"
          sx={{ mb: "26px" }}
        >
          {issueWithTrans}
        </Text>
      )}

      {from !== "under-maintenance" && (
        <>
          <WalletsWithOtherInput
            walletData={walletData}
            name="wallets"
            value={values.wallets}
            setFieldValue={setFieldValue}
            otherWalletValue={values.other_wallet}
            label={walletInputLabel}
          />
          {from === "report" && (
            <CustomSelect
              native={false}
              showLabel={true}
              fullWidth={true}
              name="payment_method"
              label={paymentMethodLabel}
              value={values.payment_method}
              onChange={(e) => setFieldValue("payment_method", e.target.value)}
              MenuProps={{
                id: "account-type-select",
              }}
              sx={{
                mb: "16px",
              }}
            >
              {paymentMethodOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </CustomSelect>
          )}
        </>
      )}
      <TextAreaComponent
        labelSx={{ whiteSpace: "unset" }}
        customClass={visibleInPaymentPage && "reason-text-area"}
        showLabel={true}
        label={commentInputLabel}
        maxLength={250}
        minRows={3}
        maxRows={3}
        name="reason"
        placeholder={commentInputPlaceholder}
        value={values.reason}
        fullWidth
        onChange={(e) => {
          setFieldValue("reason", e.target.value);
        }}
      />
    </Box>
  );

  const footer = (
    <Button
      label={submitButtonLabel}
      type="submit"
      onClick={handleSubmit}
      disabled={!(isValid && dirty) || isSubmitting}
      loading={buttonLoader}
    />
  );

  return (
    <Modal
      body={body}
      footer={!submitted && footer}
      handleClose={handleClose}
      open={openModal}
      title={!submitted && modalTitle}
      className={submitted && "end-user-feedback-success"}
    />
  );
};

export default memo(CommonReportFeedbackModal);
