import React, { useState } from "react";
import RightSideCommonPreview from "../Common/RightSideCommonPreview";
import { previewURL, priceMsg, shipping } from "../messages";
import { content, sharedPropsBetweenCLPL } from "../constants";
import RenderLinkWebPreview from "../Common/RenderLinkWebPreview";
import { useSelector } from "react-redux";
import RenderLinkMobilePreview from "../Common/RenderLinkMobilePreview";

const PaymentLinkDisplay = (props) => {
  const { linkType, confirmPayment } = props;

  const [selected, setSelected] = useState("laptop");

  const { paymentInfo, accountAsset } = useSelector((state) => state.common);

  const handleChange = (_e, value) => {
    if (value) {
      setSelected(value);
    }
  };

  const commonProps = {
    confirmPayment,
    linkType,
    checkedBillingAddress:
      paymentInfo?.isCheckedBilling || paymentInfo?.addressType === shipping,
    checkedEmailAddress: paymentInfo?.isCheckedEmail,
    checkedPhoneAddress: paymentInfo?.isCheckedPhone,
    checkedShippingAddress: paymentInfo?.addressType === shipping,
    addressType: paymentInfo?.addressType,
  };

  const commonPropsForBothPreview = {
    TIDVisible: paymentInfo?.otherInfo,
    accountAsset: accountAsset,
    ...sharedPropsBetweenCLPL,
  };

  const respectedLinkWebProps = {
    ...commonPropsForBothPreview,
  };

  const respectedLinkMobileProps = {
    ...commonPropsForBothPreview,
  };

  const renderProps = {
    componentProps: commonProps,
    dummyDomain: previewURL,
    paymentInfo: paymentInfo,
    showPageLoader: false,
    marginTop: "24px",
    marginBottom: 0,
  };

  const renderContent = () => {
    return selected === "laptop" ? (
      <RenderLinkWebPreview
        {...renderProps}
        respectedLinksProps={respectedLinkWebProps}
      />
    ) : (
      <RenderLinkMobilePreview
        {...renderProps}
        respectedLinksProps={respectedLinkMobileProps}
      />
    );
  };

  return (
    <RightSideCommonPreview
      handleChange={handleChange}
      priceMsg={priceMsg}
      content={content}
      selected={selected}
      brandingURL="/settings/checkout-payment"
    >
      {renderContent()}
    </RightSideCommonPreview>
  );
};

export default PaymentLinkDisplay;
