import React from "react";
import GetGuides from "./GetGuides";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Box } from "@mui/material";

const ProductsGuide = () => {
  const { obProductsguideFe } = useFlags();

  return obProductsguideFe ? (
    <GetGuides
      moduleName="products"
      redirectUrl="/products"
      guideApiPath="product-guide"
      getStartedApiPath="Products"
      needHelpGuidePath="docs/receive-payments/products"
    />
  ) : (
    <Box height="70vh" display="flex" alignItems="center">
      <AccessDenied />
    </Box>
  );
};

export default ProductsGuide;
