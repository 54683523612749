import { createRef, forwardRef, useEffect, useState } from "react";
import CommonHeader from "../Common/CommonHeader";
import "../../assets/styles/customer.scss";
import { AppBar, Box, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import {
  addCustomerBtnLabel,
  headerCustomerMsg,
  testModeTextForCustomer,
  testModeTitle,
} from "../messages";
import {
  setCaughtErrorInStrapi,
  setIsDataAvailable,
  setIsModalOpen,
  setLoading,
} from "../../redux/common/actions";
import { useDispatch, useSelector } from "react-redux";
import Button from "@speed/common/src/components/Button/Button";
import CloseIcon from "@mui/icons-material/Close";
import TestModeHeader from "../Payments/TestModeHeader";
import Text from "@speed/common/src/components/Text/Text";
import { back, next } from "@speed/common/src/components/messages";
import CreateCustomer from "./CreateCustomer";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import CustomerTable from "./CustomerTable";
import { callAPIInterface, callStrapiAPIInterface } from "../constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import GetStartedHeader from "@speed/common/src/components/GetStartedHeader/GetStartedHeader";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Customer = () => {
  const ref = createRef();
  const confirmRef = createRef();
  const [disabled, setDisabled] = useState(true);
  const [disableBackButton, setDisableBackButton] = useState(false);
  const [isNextStep, setIsNextStep] = useState(false);
  const [accountFormData, setAccountFormData] = useState(null);
  const [customerGuide, setCustomerGuide] = useState(null);
  const [isGetHeaderInfoLoading, setGetHeaderInfoLoading] = useState(true);

  const dispatch = useDispatch();
  const { history, isModalOpen, isDataAvailable, caughtErrorInStrapi } =
    useSelector((state) => state.common);
  const liveMode = useSelector((state) => state.auth.liveMode);
  const { cusListFe, cusAddFe } = useFlags();

  useEffect(() => {
    dispatch(setIsDataAvailable(true));
    getCustomerGuide();
    return () => {
      dispatch(setIsModalOpen(false));
      dispatch(setCaughtErrorInStrapi(false));
    };
  }, [liveMode]);

  const getCustomerGuide = () => {
    setGetHeaderInfoLoading(true);
    callStrapiAPIInterface(
      "GET",
      "/api/customers-guide?populate[header][populate][use_cases][populate]=*"
    )
      .then((response) => {
        setCustomerGuide(response?.data?.attributes?.header);
        setGetHeaderInfoLoading(false);
      })
      .catch(() => {
        dispatch(setCaughtErrorInStrapi(true));
        setGetHeaderInfoLoading(false);
      });
  };

  const handleModalClose = () => {
    dispatch(setIsModalOpen(false));
    setIsNextStep(false);
  };

  const resetActions = () => {
    setDisabled(false);
    setDisableBackButton(false);
    dispatch(setLoading(false));
  };

  const handleNext = () => {
    const accountFormData = ref?.current?.handleSubmitAccountData();
    setAccountFormData(accountFormData);
    setIsNextStep(true);
  };

  const handleBackButton = () => {
    setDisabled(false);
    setIsNextStep(false);
  };

  const createNewCustomer = () => {
    const billingFormData = confirmRef?.current?.handleSubmit();
    const customerFormData = {
      ...billingFormData,
      ...accountFormData,
    };

    setDisabled(true);
    setDisableBackButton(true);
    callAPIInterface("POST", "/customers", JSON.stringify(customerFormData))
      .then((response) => {
        response && history.push(`/customers/${response.id}`);
        dispatch(setIsModalOpen(false));
        resetActions();
      })
      .catch(() => {
        resetActions();
      });
  };

  const renderHeaderStepsButton = () => {
    return !isNextStep ? (
      <Button
        label={next}
        variant="contained"
        color="primary"
        className="payment-link-btn"
        disabled={disabled}
        onClick={handleNext}
      />
    ) : (
      <>
        <Button
          variant="outlined"
          color="primary"
          label={back}
          onClick={handleBackButton}
          sx={{ marginRight: "18px" }}
          disabled={disableBackButton}
        />
        <Button
          label={addCustomerBtnLabel}
          variant="contained"
          color="primary"
          className="payment-link-btn"
          onClick={createNewCustomer}
          disabled={disabled}
        />
      </>
    );
  };

  return (
    <Box
      bgcolor={!isDataAvailable && !caughtErrorInStrapi ? "#f7fafc" : ""}
      className="section-wrapper"
    >
      {isDataAvailable || caughtErrorInStrapi ? (
        <>
          <CommonHeader />
          <CustomDivider />
        </>
      ) : (
        <GetStartedHeader
          setIsModalOpen={setIsModalOpen}
          loading={isGetHeaderInfoLoading}
          getHeaderInfo={customerGuide}
        />
      )}

      <Box className="customer-wrapper">
        {cusAddFe && (isDataAvailable || caughtErrorInStrapi) && (
          <Box className="action-btn-wrapper mui-fixed">
            <Button
              icon="addIcon"
              className="add-icon"
              label={addCustomerBtnLabel}
              variant="outlined"
              color="primary"
              onClick={() => {
                dispatch(setIsModalOpen(true));
              }}
            />
          </Box>
        )}

        {cusListFe ? <CustomerTable /> : <AccessDenied />}

        <Dialog
          disableEscapeKeyDown
          fullScreen
          open={isModalOpen}
          TransitionComponent={Transition}
          className="fullscreen-modal"
        >
          {!liveMode && (
            <TestModeHeader
              buttonText={testModeTitle}
              captionText={testModeTextForCustomer}
            />
          )}
          <AppBar sx={{ position: "relative" }} className="modal-app-bar">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleModalClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Text
                size={20}
                font="semibold"
                sx={{ flex: 1 }}
                className="default-text divider"
                variant="h6"
              >
                {headerCustomerMsg}
              </Text>

              {renderHeaderStepsButton()}
            </Toolbar>
          </AppBar>
          <Box className="fullscreen-modal-box">
            <CreateCustomer
              ref={ref}
              confirmRef={confirmRef}
              accountFormData={accountFormData}
              isNextStep={isNextStep}
              handleButtonDisable={(value) => setDisabled(value)}
            />
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Customer;
