import {
  affiliatePartnerData,
  callAPIInterface,
  cookieOptions,
  expireSession,
  getTimestamp,
  isAffiliatePartnerDataAvailable,
  isOneClickIntegrationAvailable,
  serverErrorStatusCodes,
} from "../../components/constants";
import {
  accepted,
  invitee,
  passwordSuccess,
  pending,
  resendVerificationMsg,
} from "../../components/messages";
import {
  setBrandingPageUI,
  setShowAutomaticLogoutModal,
  showToast,
  updatePaymentPageSettings,
} from "../common/actions";
import * as Types from "./types";
import { sessionService } from "redux-react-session";
import history from "@speed/common/src/components/history";
import moment from "moment";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { app } from "@speed/common/src/util/firebase";
import { Cookies } from "react-cookie";
import { privateRoute, walletRoute } from "../../components/menu";
import { Merchant, Wallet } from "@speed/common/src/components/messages";
import {
  getDefaultWalletCurrencyObj,
  getFormattedWalletsData,
} from "@speed/common/src/components/constants";

export const setLocalVariables = async (
  response,
  dispatch,
  isFromSocialMediaLogin = false,
  isUserLoggedIn = true
) => {
  dispatch(setInitialState(response));
  const res = await dispatch(getUser(isUserLoggedIn));
  if (res) {
    const account = res?.accounts_details?.[0]?.account;
    localStorage.setItem("site_auth", "1");
    const defaultCurrencyObj = getDefaultWalletCurrencyObj(
      res?.accounts_details?.[0]?.account?.country
    );
    localStorage.setItem(
      "wallet_default_currency",
      JSON.stringify(defaultCurrencyObj)
    );

    if (isFromSocialMediaLogin) {
      setUpdatedSessionData(response, { email: res.email });
      removeSessionStorageInvitee(affiliatePartnerData(), res);
    }

    await dispatch(setIsLoggedIn(true));
    if (account?.account_type === Wallet) {
      setLocalStorageMenu(Wallet);
      await dispatch(setLiveMode(true));
    } else {
      const profiling_completed = res?.profiling_completed;
      if (!profiling_completed) {
        history.push("/onboarding");
      }
    }

    history.location.pathname === "/dashboard" &&
      dispatch(setBrandingPageUI(account?.account_public_info?.branding));
    return res;
  }
};

export const getAccountFirebaseToken = async () => {
  try {
    const res = await callAPIInterface("GET", "/account/firebase-token");
    return res;
  } catch (err) {
    throw err;
  }
};

export const setInitialState = (data) => async (dispatch) => {
  dispatch({
    type: Types.SET_INITIAL_DATA,
    payload: data,
  });
};

export const setIsLoggedIn = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_IS_LOGGED_IN,
    payload: data,
  });
};

export const updateUser = (data) => async (dispatch) => {
  dispatch({
    type: Types.SET_USER,
    payload: data,
  });
};

export const setLiveMode = (data) => async (dispatch) => {
  return await callAPIInterface(
    "POST",
    "/change-account-mode",
    JSON.stringify({ is_last_viewed_livemode: data })
  )
    .then(async (_response) => {
      let session = await sessionService.loadSession();
      await sessionService.saveSession({ ...session, liveMode: data });

      dispatch({
        type: Types.SET_LIVE_MODE,
        payload: data,
      });

      return true;
    })
    .catch((_e) => {
      dispatch({
        type: Types.SET_LIVE_MODE,
        payload: !data,
      });
      return false;
    });
};

export const signUp = (data) => async () => {
  const fromPartnerDataAvailable = isAffiliatePartnerDataAvailable();
  let url = "/signup";
  if (fromPartnerDataAvailable) {
    url = "/signup-affiliate-partner";
  } else if (data.invitee_id) {
    url = "/signup-invite";
  }
  return await callAPIInterface("POST", url, JSON.stringify(data))
    .then((response) => {
      sessionStorage.removeItem("affiliate-partner-invitee");
      sessionStorage.removeItem("inviteLocationData");
      history.push({
        pathname: "/verify-email",
        state: { email: data.email },
      });
      return response;
    })
    .catch((error) => {
      const errors = error.response && error.response.data.errors[0].message;
      return errors;
    });
};

export const verifyEmail = (data) => async (dispatch) => {
  return await callAPIInterface("POST", "/verify-email", JSON.stringify(data))
    .then(async (response) => {
      response.timestamp = getTimestamp();
      await sessionService.saveSession({ ...response, is_user_verified: true });
      if (response?.mfa_enabled) {
        await sessionService.deleteUser();
        await dispatch(setIsLoggedIn(false));
        localStorage.clear();
        const redirect2faPage = response?.mfa_configurations
          ? "/two-factor"
          : "/two-factor-setup";
        history.replace({
          pathname: redirect2faPage,
          state: {
            from: "/login",
            isFromVerifyEmailPage: true,
          },
        });
        return;
      }
      const authObj = await getAuth(app);
      await signInWithCustomToken(authObj, response?.firebase_token).catch(
        (_e) => {}
      );
      await setLocalVariables(response, dispatch);
      await sessionService.deleteUser();
      return response;
    })
    .catch((_e) => false);
};

export const resendVerification = (data) => async (dispatch) => {
  return await callAPIInterface(
    "POST",
    "/resend-verification-code",
    JSON.stringify(data)
  )
    .then((_response) => {
      dispatch(
        showToast({
          isToastOpen: true,
          toastMessage: resendVerificationMsg,
          toastVariant: "success",
        })
      );
      return true;
    })
    .catch((_e) => false);
};

const setDefaultPaymentPageSettings = async (accountPublicInfo, dispatch) => {
  if (accountPublicInfo) {
    const params = {
      display_amount_in_currency: accountPublicInfo?.display_amount_in_currency,
      show_payment_page_speed_branding:
        !accountPublicInfo?.show_payment_page_speed_branding,
      terms_of_service_url: accountPublicInfo?.terms_of_service_url,
      privacy_policy_url: accountPublicInfo?.privacy_policy_url,
    };
    if (accountPublicInfo?.wallets?.length > 0) {
      const formattedWalletsObj = await getFormattedWalletsData(
        accountPublicInfo?.wallets
      );
      params["walletsLogo"] = formattedWalletsObj;
    }
    dispatch(updatePaymentPageSettings(params));
  }
};

const handleOrphanUserRedirection = (
  isUserLoggedIn,
  response,
  dispatch,
  isFromLayout
) => {
  if (isUserLoggedIn) {
    dispatch(isFromLayout ? logOut() : setShowAutomaticLogoutModal(true));
  } else {
    const invitee_details =
      localStorage.getItem(invitee) &&
      JSON.parse(localStorage.getItem(invitee));
    const initialState = {
      isOrphanUser: true,
    };
    const getRedirectionObject = () => {
      switch (invitee_details?.inviteeStatus) {
        case pending:
          return {
            path: "/join-team",
            state: {
              ...initialState,
              invitationForDiffAccount:
                invitee_details.inviteeEmail !== response.email,
            },
          };
        case accepted:
          return {
            path: "/join-team",
            state: {
              ...initialState,
              invitationForRedeemAccount: true,
            },
          };
        default:
          return {
            state: initialState,
            path: "/create-account",
          };
      }
    };

    const { path, state } = getRedirectionObject();
    history.push({ pathname: path, state });
  }
};

export const getUser =
  (isUserLoggedIn = true, isFromLayout = false) =>
  (dispatch) => {
    return callAPIInterface("GET", "/user", "")
      .then(async (response) => {
        if (response?.accounts_details?.length) {
          setDefaultPaymentPageSettings(
            response?.accounts_details?.[0]?.account?.account_public_info,
            dispatch
          );
          dispatch(updateUser(response));
          const session = await sessionService.loadSession();
          await sessionService
            .saveSession({
              ...session,
              speed_acc_id: response?.accounts_details?.[0]?.account?.id,
              liveMode: response.accounts_details[0].is_last_viewed_livemode,
            })
            .then(() => {
              dispatch(
                setInitialState({
                  ...session,
                  current_acc: response.accounts_details[0],
                  liveMode:
                    response.accounts_details[0].is_last_viewed_livemode,
                })
              );
            });
          return response;
        } else {
          handleOrphanUserRedirection(
            isUserLoggedIn,
            response,
            dispatch,
            isFromLayout
          );
          return false;
        }
      })
      .catch((_e) => false);
  };

export const setUpdatedSessionData = async (response, authData = null) => {
  let currentSession = null;
  await sessionService
    .loadSession()
    .then((res) => (currentSession = res))
    .catch((_e) => {});
  const updatedSession = {
    ...currentSession,
    ...response,
    last_action_time: moment().valueOf(),
  };
  if (authData?.email) updatedSession.email = authData.email;
  await sessionService.saveSession(updatedSession);

  // Set session cookie to access across subdomains.
  if (updatedSession.session) {
    const cookies = new Cookies();
    cookies.set("session", updatedSession.session, cookieOptions);
    cookies.set("firebase_token", updatedSession.firebase_token, cookieOptions);
  }
};

const handleRedirection = () => {
  const payoutWalletPath = sessionStorage.getItem("verify-payout-wallet");
  if (payoutWalletPath) {
    history.push(payoutWalletPath);
    return;
  }

  const fromOneClickDataAvailable = isOneClickIntegrationAvailable();
  if (fromOneClickDataAvailable) {
    history.push("/authorize/connect");
    return;
  }

  const fromPartnerDataAvailable = isAffiliatePartnerDataAvailable();
  if (fromPartnerDataAvailable) {
    history.push("/partners/affiliate-partners-connect");
    return;
  }

  const documentationDetails = localStorage.getItem("documentation_details");
  const result = documentationDetails && JSON.parse(documentationDetails);
  if (result?.from === "documentation" && result?.redirect) {
    history.push("/documentation", {
      redirectDetails: { from: result.from, redirect: result.redirect },
    });
  }
};

export const setAfterLoginProcess = async (
  response,
  dispatch,
  isFromSocialMediaLogin,
  isUserLoggedIn
) => {
  const authObj = getAuth(app);
  signInWithCustomToken(authObj, response.firebase_token).catch((_e) => {});
  await setLocalVariables(
    response,
    dispatch,
    isFromSocialMediaLogin,
    isUserLoggedIn
  ).then((res) => res && handleRedirection());
};

const removeSessionStorageInvitee = (affiliatePartnerData, loginData) => {
  if (
    !affiliatePartnerData?.is_default &&
    affiliatePartnerData?.email !== loginData?.email
  ) {
    sessionStorage.removeItem("affiliate-partner-invitee");
  }
};

export const callAfterLoginFlow = async (
  response,
  data,
  resolve,
  dispatch,
  isFromSignUpPage = false
) => {
  const isFromSocialMediaLogin = !data;
  response.timestamp = getTimestamp();
  sessionStorage.removeItem("loginInviteData");
  await setUpdatedSessionData(response, data);
  (!isFromSocialMediaLogin || isFromSignUpPage) &&
    removeSessionStorageInvitee(affiliatePartnerData(), data);

  if (response?.mfa_enabled) {
    const redirect2faPage = response?.mfa_configurations
      ? "/two-factor"
      : "/two-factor-setup";
    history.replace({
      pathname: redirect2faPage,
      state: {
        from: "/login",
        isFromSocialMediaLogin,
      },
    });
    resolve?.(true);
    return;
  }
  await setAfterLoginProcess(response, dispatch, isFromSocialMediaLogin, false);
  resolve?.(true);
};

export const logIn = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    return callAPIInterface("POST", "/login", JSON.stringify(data))
      .then((response) => {
        callAfterLoginFlow(response, data, resolve, dispatch);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const verifyUserPassword = (data) => async () => {
  return await callAPIInterface(
    "POST",
    "/verify-user-password",
    JSON.stringify(data)
  )
    .then((_response) => {
      return true;
    })
    .catch((_e) => {
      return false;
    });
};

export const verifyEmailOTP = (data) => async () => {
  return await callAPIInterface(
    "POST",
    "/critical-operation/otp/verify",
    JSON.stringify(data)
  )
    .then((_response) => {
      return true;
    })
    .catch((_e) => {
      return false;
    });
};

export const logOut =
  (setLoading = null) =>
  async () => {
    const { session } = await sessionService.loadSession();
    return await callAPIInterface(
      "POST",
      "/logout",
      JSON.stringify({ session })
    )
      .then(() => {
        expireSession();
      })
      .catch(() => {
        if (setLoading) setLoading(false);
      });
  };

export const verifyUser = (data) => async () => {
  return await callAPIInterface("POST", "/verify-user", JSON.stringify(data))
    .then((res) => res)
    .catch((error) => {
      const errors = error.response && error.response.data.errors;
      if (errors) {
        if (errors[0]?.type?.toLowerCase() === "verification_code_sent") {
          history.push({
            pathname: "/verify-email",
            state: { email: data.email },
          });
        }
      }
      return serverErrorStatusCodes.includes(error) ? error : false;
    });
};

export const generateToken = () => async (dispatch) => {
  const session = await sessionService.loadSession();
  return new Promise(async (resolve, reject) => {
    return await callAPIInterface(
      "POST",
      "/generate-token",
      JSON.stringify({
        session: session.session,
      })
    )
      .then(async (response) => {
        const updatedSession = {
          refresh_token: session.refresh_token,
          access_token: response.access_token,
          id_token: response.id_token,
          timestamp: getTimestamp(),
        };
        await sessionService.saveSession({ ...session, ...updatedSession });
        dispatch(setInitialState({ ...session, ...updatedSession }));
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const forgotPassword = (data) => async () => {
  return await callAPIInterface(
    "POST",
    "/forgot-password",
    JSON.stringify(data)
  )
    .then((_res) => true)
    .catch((error) => {
      const errors = error.response && error.response.data.errors;
      if (errors) {
        if (errors?.[0]?.type?.toLowerCase() === "verification_code_sent") {
          history.push({
            pathname: "/verify-email",
            state: { email: data.email },
          });
        }
      }
    });
};

export const verifyForgotPassword = (data, isLoggedIn) => async (dispatch) => {
  return callAPIInterface(
    "POST",
    "/verify-forgot-password",
    JSON.stringify(data)
  )
    .then(async (response) => {
      sessionService.deleteUser();
      if (isLoggedIn) {
        history.push("/dashboard");
      } else {
        history.push("/login");
      }
      dispatch(
        showToast({
          isToastOpen: true,
          toastMessage: passwordSuccess,
          toastVariant: "success",
        })
      );
      return response;
    })
    .catch((_e) => false);
};

export const checkLinkStatus = (link) => async () => {
  return new Promise(async (resolve, reject) => {
    return await callAPIInterface("GET", `/link/${link}`, "")
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const verifyChangeEmail = (data) => async () => {
  return await callAPIInterface(
    "POST",
    "/verify-change-email",
    JSON.stringify(data)
  )
    .then((_res) => true)
    .catch((_e) => false);
};

export const setLocalStorageMenu = (accType = Merchant) => {
  // For Wallet -> take wallet app route
  // For Merchant -> take dashboard route
  const wallet = walletRoute["My Assets"];
  const merchant = privateRoute["Dashboard"];

  localStorage.setItem(
    "back_to_menu",
    JSON.stringify({
      menuUrl: accType === Wallet ? wallet?.route : merchant?.route,
      menuName: accType === Wallet ? wallet?.header : merchant?.header,
      expandTab: "",
    })
  );
};
