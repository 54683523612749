import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TableCell,
  TableRow,
} from "@mui/material";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  RouteBreadcrumbs,
  callAPIInterface,
  concatedMobileNumber,
  dateTimeFormatInApp,
  getAddressData,
  languageObject,
  moduleMetaDataRows,
} from "../constants";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import WarningIcon from "@mui/icons-material/Warning";
import {
  add,
  details,
  edit,
  events as eventsText,
  entityNotFoundMessage,
  metaDataText,
  noCustomerFoundMsg,
} from "../messages";
import { useSelector } from "react-redux";
import classNames from "classnames";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import {
  clipboardElement,
  generateBreadcrumbs,
  redirectWhenEntityNotFound,
  removeAllEmptyFieldsFromNestedObj,
} from "@speed/common/src/components/constants";
import Text from "@speed/common/src/components/Text/Text";
import DeleteCustomer from "./DeleteCustomer";
import ErrorWarningModal from "./ErrorWarningModal";
import MetaDataForm from "../MetaDataForm";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import { events } from "../Events/Events";
import { KeyValueGrid } from "@speed/common/src/components/KeyValueGrid";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { useFlags } from "launchdarkly-react-client-sdk";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import DetailPagePaymentSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import PaymentsSkeleton from "@speed/common/src/components/Skeleton/PaymentsSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";

const CustomerDetails = (props) => {
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteCustomerModal, setOpenDeleteCustomerModal] = useState(false);
  const [openErrorModal, setopenErrorModal] = useState(false);
  const [metaDataSkeleton, setMetaDataSkeleton] = useState(true);
  const [isMetaDataEditable, setIsMetaDataEditable] = useState(false);
  const [metaDataList, setMetaDataList] = useState([]);
  const [eventSkeleton, setEventSkeleton] = useState(false);
  const [eventsList, setEventsList] = useState([]);

  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const urlParams = useParams();
  const liveMode = useSelector((state) => state.auth.liveMode);
  const { history } = useSelector((state) => state.common);
  const { cusDetailFe } = useFlags();
  const linkNotFoundBtnText = liveMode ? "live" : "test";
  const showEventSection = false;
  const showActionButton = false;

  useEffect(() => {
    if (customerData?.metadata) {
      const convertedMetaDataToArray = Object.entries(
        customerData?.metadata
      )?.map((e) => ({
        key: e[0],
        value: e[1],
      }));
      setMetaDataList(convertedMetaDataToArray);
      setMetaDataSkeleton(false);
    }
  }, [customerData?.metadata]);

  const getCustomerDetail = () => {
    setMetaDataSkeleton(true);
    callAPIInterface("GET", `/customers/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setCustomerData({
            ...res,
            billing: removeAllEmptyFieldsFromNestedObj(res.billing),
            shipping: removeAllEmptyFieldsFromNestedObj(res.shipping),
          });
          showEventSection && getCustomerEventsList();
        }
      })
      .catch((err) => {
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const loadDetailPage = () => {
    setCustomerData(null);
    setIsShowNotFound(false);
    getCustomerDetail();
  };

  useEffect(
    () => cusDetailFe && loadDetailPage(),
    [liveMode, cusDetailFe, urlParams?.id]
  );

  const getCustomerEventsList = () => {
    setEventSkeleton(true);
    const filterEvents = {
      ids: [urlParams?.id],
    };
    callAPIInterface("POST", "/events/filters", JSON.stringify(filterEvents))
      .then((res) => {
        if (res) {
          setEventSkeleton(false);
          setEventsList(res?.data);
        }
      })
      .catch(() => {
        setEventSkeleton(false);
      });
  };

  const commonActionListElement = (actionList) => {
    return actionList?.map((action) => (
      <ListItem key={actionList.actionName} disablePadding>
        <ListItemButton onClick={action.onClickAction}>
          <ListItemText
            primary={action.actionName}
            className={classNames(action.className)}
          />
        </ListItemButton>
      </ListItem>
    ));
  };

  const renderActionButton = () => {
    const actionData = [
      {
        actionName: "Create Invoice",
        onClickAction: () => setAnchorEl(null),
      },
      {
        actionName: "Delete",
        onClickAction: () => {
          setOpenDeleteCustomerModal(true);
          setAnchorEl(null);
        },
        className: "text-danger",
      },
    ];

    return (
      actionData?.length > 0 && (
        <Box className="action-btn-wrapper">
          <MoreHorizIcon
            className="horizontal-dots-icon pointer-cursor"
            onClick={(event) => {
              event.stopPropagation();
              setAnchorEl(anchorEl ? null : event.currentTarget);
            }}
          />
          <CustomPopper
            disablePortal={true}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            position="bottom"
            handleClose={() => setAnchorEl(null)}
          >
            <List>{commonActionListElement(actionData)}</List>
          </CustomPopper>
        </Box>
      )
    );
  };

  const headerContent = () => {
    return (
      <Box
        className="header-content"
        display="flex"
        justifyContent="space-between"
      >
        <Box>
          <TruncatedTextTooltip
            textValue={customerData.name}
            cellWidth="750px"
            textProps={{ className: "customer-name" }}
          />

          {customerData?.description && (
            <Text
              className="grey-text customer-description"
              variant="h4"
              size={14}
              font="regular"
            >
              {customerData.description}
            </Text>
          )}
        </Box>

        <Box className="clipboard-element">
          {clipboardElement(
            customerData?.id,
            true,
            "clipboard-text",
            "text",
            true
          )}
        </Box>
      </Box>
    );
  };

  const renderTextElement = (textElement) => (
    <Text
      variant="h4"
      size={16}
      className="default-text customer-info-text"
      font="regular"
      sx
    >
      {textElement}
    </Text>
  );

  const renderDetailContent = () => {
    return (
      <Box className="customer-detail-content">
        <Box marginTop="60px">
          <Text size={20} className="default-text">
            {details}
          </Text>
        </Box>
        <CustomDivider />
        <Box
          className="customer-details-box"
          gap={3}
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {customerData?.email && (
            <KeyValueGrid
              keyName="Email"
              value={renderTextElement(customerData.email)}
            />
          )}
          {customerData?.phone && (
            <KeyValueGrid
              keyName="Phone Number"
              value={renderTextElement(
                formatPhoneNumberIntl(
                  concatedMobileNumber(
                    customerData?.calling_code,
                    customerData?.phone
                  )
                )
              )}
            />
          )}
          {customerData?.created && (
            <KeyValueGrid
              keyName="Created on"
              value={renderTextElement(
                dateTimeFormatInApp(customerData?.created)
              )}
            />
          )}
          {customerData?.preferred_locales?.length > 0 && (
            <KeyValueGrid
              keyName="Language"
              value={renderTextElement(
                customerData?.preferred_locales?.map(
                  (language, index) =>
                    `${index > 0 ? ", " : ""}${languageObject[language]}`
                )
              )}
            />
          )}
          {((customerData?.shipping?.address &&
            Object.keys(customerData?.shipping?.address)?.length > 0) ||
            customerData?.shipping?.name ||
            customerData?.shipping?.phone) && (
            <KeyValueGrid
              keyName="Shipping Details"
              value={
                <>
                  {renderTextElement(customerData.shipping.name || "")}
                  {renderTextElement(
                    getAddressData(customerData, "shipping")?.line1
                  ) || ""}
                  {renderTextElement(
                    getAddressData(customerData, "shipping")?.line2
                  ) || ""}
                  {renderTextElement(
                    getAddressData(customerData, "shipping")?.line3
                  ) || ""}
                  {customerData?.shipping?.phone &&
                    renderTextElement(
                      formatPhoneNumberIntl(
                        concatedMobileNumber(
                          customerData?.shipping?.calling_code,
                          customerData?.shipping?.phone
                        )
                      )
                    )}
                </>
              }
            />
          )}
          {((customerData?.billing?.address &&
            Object.keys(customerData?.billing?.address)?.length > 0) ||
            customerData?.billing?.email) && (
            <KeyValueGrid
              keyName="Billing Details"
              value={
                <>
                  {renderTextElement(
                    getAddressData(customerData, "billing")?.line1
                  ) || ""}
                  {renderTextElement(
                    getAddressData(customerData, "billing")?.line2
                  ) || ""}
                  {renderTextElement(
                    getAddressData(customerData, "billing")?.line3
                  ) || ""}
                  {customerData?.billing?.email &&
                    renderTextElement(
                      "Billing Email: " + customerData.billing.email
                    )}
                </>
              }
            />
          )}
          {customerData?.billing?.email_cc?.length && (
            <KeyValueGrid
              keyName="Emails to CC"
              value={renderTextElement(
                customerData?.billing?.email_cc?.map(
                  (email, index) => `${index > 0 ? ", " : ""}${email}`
                )
              )}
            />
          )}
          {customerData?.currency && (
            <KeyValueGrid
              keyName="Currency"
              value={renderTextElement(customerData.currency)}
            />
          )}
        </Box>
      </Box>
    );
  };

  const renderMetaDataContent = () => {
    return (
      <>
        {!metaDataSkeleton ? (
          <Box marginTop="50px">
            {isMetaDataEditable ? (
              <MetaDataForm
                metaDataList={metaDataList}
                setIsMetaDataEditable={setIsMetaDataEditable}
              />
            ) : (
              <HorizontalTable
                label={metaDataText}
                rows={moduleMetaDataRows(metaDataList)}
                rowsPerPage={51}
                isColumnShown={moduleMetaDataRows(metaDataList)?.length > 0}
                tableBodyClassName="border-none"
                isShowButton={false}
                displayShowButton={false}
                viewAllButtonLabel={metaDataList?.length > 0 ? edit : add}
                viewAllButtonIcon="editIcon"
                handleViewAllClick={() => setIsMetaDataEditable(true)}
              />
            )}
          </Box>
        ) : (
          <DetailPagePaymentSkeleton />
        )}
      </>
    );
  };

  const eventRows = eventsList?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/events/${rowItem?.id}`)}
      >
        <TableCell>{events(rowItem)}</TableCell>
        <TableCell align="right" sx={{ color: "#848b9e" }}>
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
      </TableRow>
    );
  });

  const renderEventDataContent = () => {
    return (
      <>
        {!eventSkeleton ? (
          <Box marginTop="50px">
            <HorizontalTable
              label={eventsText}
              isColumnShown={eventRows?.length > 0}
              rows={eventRows}
              rowsPerPage={3}
              handleViewAllClick={() => {
                history.push(`/customers/${urlParams?.id}/events`);
              }}
              isShowButton={eventRows?.length > 3}
              tableBodyClassName="border-none"
            />
          </Box>
        ) : (
          <DetailPagePaymentSkeleton />
        )}
      </>
    );
  };

  return !isShowNotFound ? (
    <Grid className="customer-detail-wrapper" container spacing={1}>
      <Box className="section-wrapper">
        {customerData && showActionButton && renderActionButton()}
        {openDeleteCustomerModal && (
          <DeleteCustomer
            openModal={openDeleteCustomerModal}
            setOpenModal={(value) => setOpenDeleteCustomerModal(value)}
          />
        )}
        {openErrorModal && (
          <ErrorWarningModal
            openModal={openErrorModal}
            setOpenModal={(value) => setopenErrorModal(value)}
            modalHeaderText="Delete Customer"
            errorMessage={
              <>
                <Text
                  variant="h4"
                  size={18}
                  className="default-text"
                  font="regular"
                >
                  {noCustomerFoundMsg}
                  {"\u00A0"}
                  <Text component="span" size={18} className="grey-text">
                    {"\u2018"}
                    {customerData.id}
                    {"\u2019"}
                  </Text>
                </Text>
              </>
            }
          />
        )}
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        {cusDetailFe ? (
          <>
            {customerData ? (
              <>
                {headerContent()}
                {renderDetailContent()}
                {renderMetaDataContent()}
                {showEventSection && renderEventDataContent()}
              </>
            ) : (
              <PaymentsSkeleton props={props} />
            )}
          </>
        ) : (
          <AccessDenied />
        )}
      </Box>
    </Grid>
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("Customer")}
        subTitle={`Customer ${urlParams.id} is not found.`}
        btnText={`View ${linkNotFoundBtnText} mode customers`}
      />
    </Box>
  );
};

export default CustomerDetails;
