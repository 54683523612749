import { Box } from "@mui/material";
import React from "react";
import {
  addPaymentMethod,
  addStoreDetailsAndAuthenticate,
  addWebhookSecretKey,
  storeDetailsMsg,
  subscribeWebhook,
} from "../../messages";
import VerifySteps from "./VerifySteps";

const TestConnection = ({ stepOneVerified, verifySteps }) => {
  const verifyStepsJson = [
    {
      stepNo: "1",
      stepName: addStoreDetailsAndAuthenticate,
      status: stepOneVerified,
    },
    {
      stepNo: "2",
      stepName: subscribeWebhook,
      status: verifySteps,
    },
    {
      stepNo: "3",
      stepName: addWebhookSecretKey,
      status: verifySteps,
    },
    {
      stepNo: "4",
      stepName: addPaymentMethod,
      status: verifySteps,
    },
  ];
  return (
    <Box
      mt="50px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {verifyStepsJson?.map((steps) => (
        <VerifySteps
          key={steps?.stepNo}
          stepNo={steps?.stepNo}
          stepName={steps?.stepName}
          verifyStatus={steps?.status}
          text={storeDetailsMsg}
        />
      ))}
    </Box>
  );
};

export default TestConnection;
