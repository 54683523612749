import { FlatfileListener } from "@flatfile/listener";
import { bulkRecordHook } from "@flatfile/plugin-record-hook";
import api from "@flatfile/api";

import {
  setFlatfileName,
  setFlatSheetData,
  WLImportFile,
} from "../../../redux/common/actions";
import { store } from "../../../redux/store";
import { uploadSuccessWLMsg, uploadWLErrorMsg } from "../../messages";
import {
  handleValidateFFAmount,
  handleValidateFFEmail,
  handleValidateFFName,
  handleValidateFFPassword,
  handleValidateFFSuccessMessage,
  handleValidateFFSuccessUrl,
  setCurrencyRatesForNewCurrency,
} from "./constants";

export const listener = FlatfileListener.create((listener) => {
  listener.use(
    bulkRecordHook("links", async (records) => {
      for (let record of records) {
        const email = record.get("email");
        const name = record.get("name");
        const currencyCode = record.get("currency")?.toUpperCase();
        const amount = record.get("amount");
        const password = record.get("password");
        const successMessage = record.get("success_message");
        const successURL = record.get("success_url");

        // Validate email
        email && handleValidateFFEmail({ email, record });

        // Validate name
        name && handleValidateFFName({ name, record });

        // Validate password
        password && handleValidateFFPassword({ password, record });

        // Validate success message
        successMessage &&
          handleValidateFFSuccessMessage({ successMessage, record });

        // Validate success URL
        successURL &&
          handleValidateFFSuccessUrl({ successURL, successMessage, record });

        // Validate currency
        if (currencyCode) {
          await setCurrencyRatesForNewCurrency({
            currencyCode,
          });
        }

        // Validate amount
        handleValidateFFAmount({ amount, currencyCode, record });
      }

      return records;
    })
  );

  /// Retrive file name /////
  listener.on("file:created", async ({ context: { fileId } }) => {
    const file = await api.files.get(fileId);
    store.dispatch(setFlatfileName(file.data.name));
  });

  ///// Submit File Action /////
  listener.filter({ job: "workbook:submitActionFg" }, (configure) => {
    configure.on("job:ready", async ({ context: { jobId, workbookId } }) => {
      let workbookSheetData = {};
      const { data: workbookSheets } = await api.sheets.list({ workbookId });

      for (const [_, element] of workbookSheets.entries()) {
        const { data: sheetData } = await api.records.get(element.id);

        const requiredFormatData = sheetData?.records?.map?.((record) => {
          let updatedRecord = {};
          for (const [key, value] of Object.entries(record?.values)) {
            updatedRecord = {
              ...updatedRecord,
              ...{ [key]: value.value },
            };
          }

          return updatedRecord;
        });

        const flatfileName = store.getState()?.common?.flatfileName;

        workbookSheetData = {
          ...workbookSheetData,
          [element.slug]: requiredFormatData,
        };

        if (flatfileName) {
          workbookSheetData.filename = flatfileName;
        } else {
          const currentTimestamp = new Date().getTime();
          workbookSheetData.filename = `import_wl_${currentTimestamp}.csv`;
        }
      }
      store.dispatch(setFlatSheetData(workbookSheetData));

      try {
        await api.jobs.ack(jobId, {
          info: "Getting started.",
          progress: 10,
        });

        // Make changes after cells in a Sheet have been updated
        await api.jobs.complete(jobId, {
          outcome: {
            acknowledge: true,
            message: uploadSuccessWLMsg,
            next: {
              type: "wait",
            },
          },
        });
      } catch (error) {
        console.error("Error:", error.stack);

        await api.jobs.fail(jobId, {
          outcome: {
            message: uploadWLErrorMsg,
          },
        });
      }
    });
  });

  ///// On Success button click //////
  listener.on("job:outcome-acknowledged", async () => {
    const sheetData = store.getState()?.common?.flatfileWorkbookSheetData;
    // Your code to handle the click event
    store
      .dispatch(WLImportFile(sheetData))
      .then(() => {})
      .catch(() => {});
  });
});
