import React from "react";
import GetGuides from "./GetGuides";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Box } from "@mui/material";

const InvoiceGuide = () => {
  const { obInvoiceguideFe } = useFlags();

  return obInvoiceguideFe ? (
    <GetGuides
      moduleName="invoice"
      redirectUrl="/invoices"
      guideApiPath="invoice-guide"
      getStartedApiPath="Invoice"
      needHelpGuidePath="docs/receive-payments/invoicing"
    />
  ) : (
    <Box height="70vh" display="flex" alignItems="center">
      <AccessDenied />
    </Box>
  );
};

export default InvoiceGuide;
