import React from "react";
import { lightningLabel } from "@speed/common/src/components/messages";
import {
  dummyLightningAddress,
  dummyOnchainAddress,
  staticInvoiceData,
} from "@speed/common/src/components/constants";
import { invoicePreviewURL } from "../../messages";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import RenderLinkWebPreview from "../../Common/RenderLinkWebPreview";
import RenderLinkMobilePreview from "../../Common/RenderLinkMobilePreview";
import { getTimeZoneAndDateFormat } from "../../constants";

const InvoicePaymentPage = () => {
  const { brandingSelectedView, accountAsset } = useSelector(
    (state) => state.common
  );

  const currentAccount = useSelector((state) => state.auth.currentAccount);
  const { timezone } = getTimeZoneAndDateFormat();
  const commonInBothViewProps = {
    onChainAvailable: true,
    lightningLabel: lightningLabel,
    dummyLightningAddress,
    dummyOnchainAddress,
    visibleInPaymentPage: false,
    headerSectionCustomClass: "branding-header-section",
    confirmPageProps: null,
    isShowInvoiceInfo: true,
    invoicePreviewData: staticInvoiceData,
    isShowProductsList: true,
    productsData: {
      products: staticInvoiceData?.payment?.invoice_line_items || [],
      isShowViewMoreDetails:
        staticInvoiceData?.payment?.invoice_line_items?.length > 2,
    },
    isGetTimezoneFromSystem: true,
    currentAccount,
    timezone,
    accountAsset: accountAsset,
  };

  const webViewProps = {
    ...commonInBothViewProps,
    boxSx: {
      padding: "0 30px",
      alignItems: "unset",
      borderBottomLeftRadius: "10px",
    },
    rightBoxWidth: "0 20px",
    commonWidth: "unset",
    qrSize: "41.5% !important",
  };

  const mobileViewProps = {
    ...commonInBothViewProps,
    isShowTotal: true,
    isShowInvoiceInfoSection: true,
  };

  const renderProps = {
    dummyDomain: invoicePreviewURL,
    paymentInfo: staticInvoiceData,
    showPageLoader: true,
    marginTop: 0,
    marginBottom: "80px",
    componentProps: { isFromStaticData: true },
  };
  return (
    <>
      <Grid item lg={8} className="payment-checkout invoice-payment-page">
        {brandingSelectedView === "laptop" ? (
          <RenderLinkWebPreview
            {...renderProps}
            respectedLinksProps={webViewProps}
          />
        ) : (
          <RenderLinkMobilePreview
            {...renderProps}
            respectedLinksProps={mobileViewProps}
          />
        )}
      </Grid>
    </>
  );
};

export default InvoicePaymentPage;
