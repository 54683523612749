import React from "react";
import GetGuides from "./GetGuides";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Box } from "@mui/material";

const ConnectGuide = () => {
  const { obConnectguideFe } = useFlags();

  return obConnectguideFe ? (
    <GetGuides
      moduleName="connect"
      redirectUrl="/connect"
      guideApiPath="connect-guide"
      getStartedApiPath="Connect"
      needHelpGuidePath="docs/receive-payments/connect"
    />
  ) : (
    <Box height="70vh" display="flex" alignItems="center">
      <AccessDenied />
    </Box>
  );
};

export default ConnectGuide;
