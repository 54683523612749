import React from "react";
import GetGuides from "./GetGuides";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Box } from "@mui/material";

const CheckoutLinkGuide = () => {
  const { obCheckoutlinkguideFe } = useFlags();

  return obCheckoutlinkguideFe ? (
    <GetGuides
      moduleName="checkout_link"
      redirectUrl="/checkout-links"
      guideApiPath="checkout-link-guide"
      getStartedApiPath="Checkout"
      needHelpGuidePath="docs/receive-payments/checkout-link"
    />
  ) : (
    <Box height="70vh" display="flex" alignItems="center">
      <AccessDenied />
    </Box>
  );
};

export default CheckoutLinkGuide;
