import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import RenderLinkWebPreview from "../Common/RenderLinkWebPreview";
import RenderLinkMobilePreview from "../Common/RenderLinkMobilePreview";
import { invoicePreviewURL } from "../messages";
import { lightningLabel } from "@speed/common/src/components/messages";
import {
  dummyLightningAddress,
  dummyOnchainAddress,
} from "@speed/common/src/components/constants";
import { getTimeZoneAndDateFormat } from "../constants";

const PaymentPage = ({ selected }) => {
  const { invoicePreviewData, accountAsset } = useSelector(
    (state) => state.common
  );
  const currentAccount = useSelector((state) => state.auth.currentAccount);
  const { timezone } = getTimeZoneAndDateFormat();
  const products = invoicePreviewData?.payment?.invoice_line_items || [];

  const renderProps = {
    dummyDomain: invoicePreviewURL,
    paymentInfo: invoicePreviewData,
    showPageLoader: false,
    marginTop: "24px",
    marginBottom: 0,
  };

  const commonPropsForBothPreview = {
    visibleInPaymentPage: false,
    onChainAvailable: true,
    lightningLabel: lightningLabel,
    dummyLightningAddress,
    dummyOnchainAddress,
    isShowInvoiceInfo: true,
    isShowProductsList: true,
    productsData: {
      products: products,
      isShowViewMoreDetails: products.length > 2,
    },
    confirmPageProps: null,
    isGetTimezoneFromSystem: true,
    currentAccount,
    timezone,
    isDisplayStaticData: true,
    getDataFromReducer: true,
    accountAsset: accountAsset,
  };

  const respectedLinkWebProps = {
    ...commonPropsForBothPreview,
  };

  const respectedLinkMobileProps = {
    ...commonPropsForBothPreview,
    isShowTotal: true,
    isShowInvoiceInfoSection: true,
    shouldBackgroundBlur: true,
  };

  return (
    <Box className="invoice-payment-page">
      {selected === "laptop" ? (
        <RenderLinkWebPreview
          {...renderProps}
          respectedLinksProps={respectedLinkWebProps}
        />
      ) : (
        <RenderLinkMobilePreview
          {...renderProps}
          respectedLinksProps={respectedLinkMobileProps}
        />
      )}
    </Box>
  );
};

export default PaymentPage;
