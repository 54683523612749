import { excludedCode } from "@speed/common/src/components/constants";
import { invalidExchangeRate, satsValidationMsg } from "../messages";
import * as yup from "yup";
import { callAPIInterface } from "../constants";
import { store } from "../../redux/store";
import {
  setCheckoutLoader,
  showToast,
  updatePaymentInfo,
} from "../../redux/common/actions";
import { amountValidate, validAmount } from "@speed/common/src/messages";
import isEmpty from "lodash/isEmpty";

export const commonStyle = { marginLeft: "2px" };

export const showSatsValidate = (values, setSatsValidate) => {
  if (values.currency?.code === "SATS" && values.amount?.includes(".")) {
    setSatsValidate(satsValidationMsg);
  } else setSatsValidate("");
};

export const handleClick = (event, setAnchorEl, anchorEl) => {
  setAnchorEl(anchorEl ? null : event.currentTarget);
};

export const handleClose = (setAnchorEl) => {
  setAnchorEl(null);
};

// Validation schema -- START --
export const currencyValidationSchema = yup.mixed().when(["selectType"], {
  is: (selectType) => selectType?.value === "app",
  then: yup.mixed().required(),
  otherwise: yup.mixed().notRequired(),
});

export const amountValidationSchema = yup.number().when(["selectType"], {
  is: (selectType) => selectType?.value === "app",
  then: yup
    .number()
    .typeError(validAmount)
    .moreThan(0, amountValidate)
    .required(""),
  otherwise: yup.number().notRequired(),
});
// Validation schema -- END --

export const updateCheckoutData = (
  targetAmount,
  amountValue,
  linkType,
  values
) => {
  const params = {
    amount: amountValue,
    payment: { exchange_rate: targetAmount },
    currency: values.currency,
    linkType: linkType,
  };
  if (linkType === "payment link") {
    if (values?.selectType?.value === "customer") {
      params["customer"] = true;
      params["amountType"] = values?.amountType?.value;
      if (values?.amountType?.value === "preset") {
        params["presetAmount"] = amountValue;
        params["presetDetails"] = values.presetAmountData;
      }
      if (values?.amountType?.value === "amountOptions") {
        const convertedAmountOptions = values.amountOptions?.filter((item) =>
          parseFloat(item.option)
        );
        params["amountOptions"] = convertedAmountOptions;
      }
    }
  }
  if (linkType === "payment link" || linkType === "checkout link") {
    if (values?.otherInfo) {
      params["details"] = {
        pageTitle: values.pageTitle,
        pageDescription: values.pageDescription,
        image: values.image,
      };
    } else {
      params["details"] = {};
    }
    params["otherInfo"] = values.otherInfo;
    if (values?.customFieldsDetails?.length > 0) {
      params["custom_fields"] = values.customFieldsDetails;
      params["customFieldUpdatedFrom"] = values.customFieldUpdatedFrom;
    }
  }
  params["isCheckedEmail"] = values?.isCheckedEmail;
  params["isCheckedPhone"] = values?.isCheckedPhone;
  params["isCheckedBilling"] = values?.isCheckedBilling;
  params["addressType"] = values?.addressType;
  params["customer_collections"] = values?.customerCollections;
  store.dispatch(updatePaymentInfo(params));
};

export const getExchangeRate = ({
  amountRef,
  values,
  errors,
  setCurrentExchangeRate,
  setExchangeRate,
  linkType,
  amountType = "",
  presetAmountRef = null,
}) => {
  const enteredAmount = amountRef?.current;
  const data = {
    currency: values.currency.code,
    target_currency: "SATS",
  };
  const presetTypeCheck =
    amountType !== "minimumAmount" && amountType !== "maximumAmount";

  const amountForUpdateData = presetTypeCheck
    ? enteredAmount?.amount
    : presetAmountRef?.current?.amount;

  const amountErrors =
    !errors.amount ||
    !errors.presetAmount ||
    !errors.minimumAmount ||
    !errors.maximumAmount;

  if (
    !excludedCode.includes(values.currency.code) &&
    enteredAmount?.amount &&
    (amountErrors || isEmpty(errors))
  ) {
    presetTypeCheck && store.dispatch(setCheckoutLoader(true));
    callAPIInterface("POST", "/utility/exchange-rate", data).then((res) => {
      store.dispatch(setCheckoutLoader(false));
      const rateOfExchange =
        linkType === "withdrawal link"
          ? res.target_lowest_rate
          : res.target_highest_rate;
      const targetAmount = res
        ? rateOfExchange
        : parseFloat(enteredAmount.amount) || 0.0;
      setCurrentExchangeRate(targetAmount);
      updateCheckoutData(
        targetAmount,
        parseFloat(amountForUpdateData) || 0.0,
        linkType,
        values
      );
      if (res) {
        if (res.target_highest_rate === null || res.target_highest_rate === 0) {
          store.dispatch(
            showToast({
              isToastOpen: true,
              toastMessage: invalidExchangeRate(values.currency.code, linkType),
              toastVariant: "warning",
              toastAutoHideDuration: 7000,
            })
          );
        } else {
          setExchangeRate(false);
        }
      }
    });
  } else {
    setExchangeRate(false);
    updateCheckoutData(
      parseFloat(amountForUpdateData) || 0.0,
      parseFloat(amountForUpdateData) || 0.0,
      linkType,
      values
    );
  }
};

const getAmountValidate = (from, values) => {
  const returnAmount = (type) =>
    !values[type] || values[type]?.slice(-1) === ".";

  let isAmountNotValid;
  switch (from) {
    case "common-form":
      isAmountNotValid = returnAmount("amount");
      break;
    case "pl-form":
      isAmountNotValid = returnAmount("amount");
      break;
    case "preset":
      isAmountNotValid =
        returnAmount("presetAmount") ||
        (values.limits && returnAmount("minimumAmount")) ||
        (values.limits && returnAmount("maximumAmount"));
      break;
    case "amountOptions":
      const result = values?.amountOptions?.filter((item) => {
        return !item.option || item.option.slice(-1) === ".";
      });
      isAmountNotValid = result?.length > 0;
      break;
  }
  return isAmountNotValid;
};

const validateCashback = (formValues) => {
  if (!formValues?.checkedCashback) return true;
  else return Object.keys(formValues?.cashback)?.length ? true : false;
};

export const cashbackValidation = {
  cashback: yup.mixed().test({
    name: "cashback",
    test: function () {
      return validateCashback(this?.parent);
    },
  }),
};

export const allowCashbackFor = ["checkout link", "payment link"];

export const checkDisableButton = ({
  amountExceed,
  satsValidate,
  values,
  exchangeRate,
  handleButtonDisable,
  from,
  defaultCheck,
  cbListallcashbackFe,
}) => {
  const isAmountNotValid = getAmountValidate(from, values);
  const checkDisable = isAmountNotValid || amountExceed || satsValidate;
  const shouldDisable = checkDisable || defaultCheck;

  let disable;
  if (values?.selectType?.value === "customer") {
    const cashbackValidate = cbListallcashbackFe
      ? !validateCashback(values)
      : false;
    disable = cashbackValidate || defaultCheck;
  } else {
    disable = !exchangeRate ? shouldDisable : exchangeRate;
  }
  handleButtonDisable(disable);
};
