import React from "react";
import GetGuides from "./GetGuides";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Box } from "@mui/material";

const PayoutGuide = () => {
  const { obPayoutguideFe } = useFlags();

  return obPayoutguideFe ? (
    <GetGuides
      moduleName="payout"
      redirectUrl="/payouts"
      guideApiPath="payout-guide"
      getStartedApiPath="Payout"
      needHelpGuidePath="docs/send-payments/payouts"
    />
  ) : (
    <Box height="70vh" display="flex" alignItems="center">
      <AccessDenied />
    </Box>
  );
};

export default PayoutGuide;
