import { AppBar, Box, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Text from "@speed/common/src/components/Text/Text";
import React, { forwardRef, useEffect, useState } from "react";
import {
  ethereumLabel,
  lightning,
  on_chain,
  onchain,
  paid,
  receive,
  sats,
} from "../../messages";
import { getDisplayAmount } from "@speed/common/src/components/constants";
import ReceivePayment from "./ReceivePayment";
import {
  callAPIInterface,
  defaultPaymentMethods,
  generateAmountToPass,
  getExchangeRate,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { app } from "@speed/common/src/util/firebase";
import history from "@speed/common/src/components/history";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getAccountFirebaseToken } from "../../../redux/auth/actions";
import EnterCustomAmount from "./../EnterCustomAmount";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { setPaymentProcessingRecallApis } from "../../../redux/common/actions";
import { fill } from "@speed/common/src/components/images";
import { bitCoinSymbol, usdtLightning, usdtSymbol } from "../../images";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let unsubscribe;
const ReceiveModal = ({
  openReceiveModal,
  setOpenReceiveModal,
  selectedCurrencyType,
  accountAssets,
}) => {
  const currency = selectedCurrencyType?.uuid || sats;
  const walletDefaultCurrency = JSON.parse(
    localStorage.getItem("wallet_default_currency")
  );
  const targetedCurrency = walletDefaultCurrency?.code;
  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);
  const currentAccount = useSelector((state) => state.auth.currentAccount);
  const dispatch = useDispatch();

  const [amount, setAmount] = useState(null);
  const [fiatAmount, setFiatAmount] = useState(null);
  const [isEnterCustomAmount, setIsEnterCustomAmount] = useState(false);
  const [note, setNote] = useState("");
  const [receiveResponse, setReceiveResponse] = useState({});
  const [exchangeRate, setExchangeRate] = useState(1);
  const [exchangeRateLoading, setExchangeRateLoading] = useState(false);
  const [switchCurrency, setSwitchCurrency] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState("1");
  const [isNoteClicked, setIsNoteClicked] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [generatedPaymentMethodArr, setGeneratedPaymentMethodArr] = useState(
    []
  );
  const [selectedMethodName, setSelectedMethodName] = useState();
  const [selectedTab, setSelectedTab] = useState(1);
  const [paymentId, setPaymentId] = useState(null);
  const [statementDesc, setStatementDesc] = useState(null);

  const taprootCondition =
    currency === sats || (currency !== sats && receiveResponse?.amount > 0);
  const showEnterCustomAmount =
    (currency !== sats && !receiveResponse?.amount) ||
    (isEnterCustomAmount && step === "2");

  useEffect(() => {
    setPaymentMethods([]);
    const selectedCurrencyPaymentMethod = accountAssets?.find(
      (curr) => curr.uuid === currency
    )?.payment_method_details;

    let paymentMethodArr = [];
    selectedCurrencyPaymentMethod?.length &&
      selectedCurrencyPaymentMethod.forEach((payment) => {
        if (payment?.is_payment_method_enabled) {
          paymentMethodArr.push(payment.payment_method_name);
        }
      });

    if (paymentMethodArr.length === 0) {
      paymentMethodArr = defaultPaymentMethods[currency];
    }

    setPaymentMethods(paymentMethodArr);
    setSelectedMethodName(
      paymentMethodArr?.[0] === onchain ? on_chain : paymentMethodArr?.[0]
    );
  }, [accountAssets, currency]);

  useEffect(() => {
    if (openReceiveModal) {
      fetchExchangeRate();
      currency === sats ? fetchPayment() : setIsEnterCustomAmount(true);
    }
  }, [openReceiveModal]);

  const fetchExchangeRate = () => {
    setExchangeRateLoading(true);
    getExchangeRate(targetedCurrency ?? "USD", currency)
      .then((res) => {
        setExchangeRate(res?.target_highest_rate);
        setExchangeRateLoading(false);
      })
      .catch((_error) => {
        setExchangeRateLoading(false);
      });
  };

  const getPaymentFirestoreData = (paymentId) => {
    const db = getFirestore(app);
    const userRef = doc(
      db,
      "account",
      currentAccount?.account?.id,
      "payments",
      paymentId
    );
    try {
      unsubscribe = onSnapshot(userRef, (querySnapshot) => {
        const data = querySnapshot.data();
        if (data?.status === paid.toUpperCase()) {
          history.push({
            pathname: "/success-page",
            data: { paymentData: data, fromReceived: true },
          });
        }
        unsubscribe();
      });
    } catch (err) {}
  };

  const getPaymentMethodIcon = (type) => {
    if (type === lightning) {
      return selectedCurrencyType?.uuid === sats ? fill : usdtLightning;
    } else if (type === on_chain) {
      return bitCoinSymbol;
    } else if (type === ethereumLabel) {
      return usdtSymbol;
    }
  };

  const generatePaymentMethodsObj = (methodObj) => {
    const replacedArray = paymentMethods?.map((item) =>
      item === onchain ? on_chain : item
    );

    const methodArr = replacedArray?.map((method, index) => {
      return {
        tab: index + 1,
        name: method,
        icon: getPaymentMethodIcon(method),
        address:
          methodObj[method]?.address || methodObj[method]?.payment_request,
      };
    });

    return methodArr;
  };

  const fetchPayment = async (amount = 0, note = "") => {
    setIsLoading(true);
    const data = {
      amount: generateAmountToPass(amount, currency, showBtcAmount),
      currency: currency,
      ttl: 600,
      target_currency: currency,
      payment_methods: paymentMethods,
    };

    note && selectedTab === 1 && (data.statement_descriptor = note);

    if (
      receiveResponse?.amount === data.amount &&
      receiveResponse?.statement_descriptor === data.statement_descriptor
    ) {
      setIsEnterCustomAmount(false);
      setStep("1");
      setIsLoading(false);
    } else {
      try {
        const response = await callAPIInterface("POST", "/payments", data);
        setPaymentId(response?.id);
        setStatementDesc(response?.statement_descriptor);
        const generatedArr = generatePaymentMethodsObj(
          response?.payment_method_options
        );

        setGeneratedPaymentMethodArr(generatedArr);

        const token = await getAccountFirebaseToken();
        const authObj = getAuth(app);

        if (token?.firebase_token) {
          try {
            await signInWithCustomToken(authObj, token?.firebase_token);
            getPaymentFirestoreData(response?.id);
          } catch (_err) {}
        }
        setReceiveResponse(response);
        setIsEnterCustomAmount(false);
        setStep("1");
      } catch (_err) {
        handleModalClose();
      }
      setIsLoading(false);
    }
  };

  const handleModalClose = () => {
    setSwitchCurrency(false);
    setFiatAmount(null);
    setAmount(null);
    setNote("");
    setIsNoteClicked(false);
    dispatch(setPaymentProcessingRecallApis(true));
    setIsEnterCustomAmount(false);
    if (step === "1") {
      setPaymentMethods([]);
      setGeneratedPaymentMethodArr([]);
      setSelectedMethodName();
      setPaymentId(null);
      setStatementDesc("");
      setOpenReceiveModal(false);
      setReceiveResponse({});
      setExchangeRateLoading(false);
      setIsLoading(false);
      setSelectedTab(1);
      unsubscribe?.();
    } else {
      setStep("1");
    }
  };

  const displayAmount = (showFiat) => {
    return getDisplayAmount(
      showFiat,
      showFiat ? fiatAmount : amount,
      showFiat ? targetedCurrency : currency,
      showBtcAmount
    );
  };

  const getHeaderTitle = () => {
    if (currency === sats) {
      return `${receive} BTC`;
    }
    return `${receive} ${currency}`;
  };

  const handleReceiveSubmit = (amt, text) => {
    fetchPayment(amt, text);
  };

  const commonProps = {
    selectedTab,
    switchCurrency,
    amount,
    isLoading,
    displayAmount,
  };

  const enterCustomAmountProps = {
    ...commonProps,
    setAmount,
    onSubmit: handleReceiveSubmit,
    fiatAmount,
    setFiatAmount,
    note,
    setNote,
    setSwitchCurrency,
    exchangeRateLoading,
    exchangeRate,
    currency,
    isNoteClicked,
  };

  const receivePaymentProps = {
    ...commonProps,
    setIsEnterCustomAmount,
    receiveResponse,
    selectedCurrencyType,
    setStep,
    setIsNoteClicked,
    generatedPaymentMethodArr,
    setSelectedMethodName,
    selectedMethodName,
    paymentId,
    statementDesc,
    setSelectedTab,
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={openReceiveModal}
      TransitionComponent={Transition}
      className={`fullscreen-modal ${
        step === "2" ? "send-modal-dialog-section" : "receive-modal-dialog"
      }`}
      onClose={handleModalClose}
      sx={{ overflow: "unset" }}
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {getHeaderTitle()}
          </Text>
        </Toolbar>
      </AppBar>
      <Box className="receive-modal-container">
        {showEnterCustomAmount ? (
          <EnterCustomAmount {...enterCustomAmountProps} />
        ) : (
          taprootCondition && <ReceivePayment {...receivePaymentProps} />
        )}
      </Box>
    </Dialog>
  );
};

export default ReceiveModal;
