export const currency = [
  { name: "Bitcoin", code: "BTC", symbol: "฿" },
  {
    name: "Satoshi",
    code: "SATS",
    symbol: (
      <span
        style={{
          fontFamily: "Satoshi-Symbol",
          height: "24px",
          paddingTop: "1.5px",
        }}
      >
        !
      </span>
    ),
    isDivider: true,
  },
  { name: "United Arab Emirates Dirham", code: "AED", symbol: "إ.د" },
  { name: "Afghan Afghani", code: "AFN", symbol: "؋" },
  { name: "Albanian Lek", code: "ALL", symbol: "Lek" },
  { name: "Armenian Dram", code: "AMD", symbol: "֏" },
  { name: "Netherlands Antillean Guilder", code: "ANG", symbol: "ƒ" },
  { name: "Angolan Kwanza", code: "AOA", symbol: "Kz" },
  { name: "Argentine Peso", code: "ARS", symbol: "ARS" },
  { name: "Australian Dollar", code: "AUD", symbol: "AU$" },
  { name: "Aruban Florin", code: "AWG", symbol: "ƒ" },
  { name: "Azerbaijani Manat", code: "AZN", symbol: "m" },
  { name: "Bosnia-Herzegovina Convertible Mark", code: "BAM", symbol: "KM" },
  { name: "Barbadian Dollar", code: "BBD", symbol: "Bds$" },
  { name: "Bangladeshi Taka", code: "BDT", symbol: "৳" },
  { name: "Bulgarian Lev", code: "BGN", symbol: "Лв." },
  { name: "Bahraini dinar", code: "BHD", symbol: ".د.ب" },
  { name: "Burundian Franc", code: "BIF", symbol: "FBu" },
  { name: "Bermudan Dollar", code: "BMD", symbol: "BD$" },
  { name: "Brunei Dollar", code: "BND", symbol: "B$" },
  { name: "Bolivian Boliviano", code: "BOB", symbol: "Bs." },
  // { name: "Bolivian Mvdol", code: "BOV", symbol: "$b" },
  { name: "Brazilian Real", code: "BRL", symbol: "R$" },
  { name: "Bahamian Dollar", code: "BSD", symbol: "B$" },
  { name: "Bhutanese ngultrum", code: "BTN", symbol: "Nu" },
  { name: "Botswanan Pula", code: "BWP", symbol: "P" },
  { name: "Belarusian Ruble", code: "BYN", symbol: "Br" },
  // { name: "Belarusian ruble", code: "BYR", symbol: "Br" },
  { name: "Belize Dollar", code: "BZD", symbol: "BZ$" },
  { name: "Canadian Dollar", code: "CAD", symbol: "CA$" },
  { name: "Congolese Franc", code: "CDF", symbol: "FC" },
  { name: "Swiss Franc", code: "CHF", symbol: "CHf" },
  { name: "Unidad de Fomento", code: "CLF", symbol: "UF" },
  { name: "Chilean Peso", code: "CLP", symbol: "CLP" },
  { name: "Chinese Yuan", code: "CNY", symbol: "¥" },
  { name: "Colombian Peso", code: "COP", symbol: "COP" },
  { name: "Costa Rican ColÃ³n", code: "CRC", symbol: "₡" },
  { name: "Cuban convertible peso", code: "CUC", symbol: "CUC$" },
  { name: "Cuban peso", code: "CUP", symbol: "₱" },
  { name: "Cape Verdean Escudo", code: "CVE", symbol: "Esc" },
  { name: "Czech Republic Koruna", code: "CZK", symbol: "Kč" },
  { name: "Djiboutian Franc", code: "DJF", symbol: "Fdj" },
  { name: "Danish Krone", code: "DKK", symbol: "Kr." },
  { name: "Dominican Peso", code: "DOP", symbol: "RD$" },
  { name: "Algerian Dinar", code: "DZD", symbol: "دج" },
  { name: "Egyptian Pound", code: "EGP", symbol: "ج.م" },
  { name: "Eritrean nakfa", code: "ERN", symbol: "ናቕፋ" },
  { name: "Ethiopian Birr", code: "ETB", symbol: "Nkf" },
  { name: "Euro", code: "EUR", symbol: "€" },
  { name: "Fijian Dollar", code: "FJD", symbol: "FJ$" },
  { name: "Falkland Islands Pound", code: "FKP", symbol: "£" },
  { name: "British Pound Sterling", code: "GBP", symbol: "£" },
  { name: "Georgian Lari", code: "GEL", symbol: "ლ" },
  { name: "Ghanaian cedi", code: "GHS", symbol: "GH₵" },
  { name: "Gibraltar Pound", code: "GIP", symbol: "£" },
  { name: "Gambian Dalasi", code: "GMD", symbol: "D" },
  { name: "Guinean Franc", code: "GNF", symbol: "FG" },
  { name: "Guatemalan Quetzal", code: "GTQ", symbol: "Q" },
  { name: "Guyanaese Dollar", code: "GYD", symbol: "GY$" },
  { name: "Hong Kong Dollar", code: "HKD", symbol: "HK$" },
  { name: "Honduran Lempira", code: "HNL", symbol: "L" },
  { name: "Croatian Kuna", code: "HRK", symbol: "kn" },
  { name: "Haitian Gourde", code: "HTG", symbol: "G" },
  { name: "Hungarian Forint", code: "HUF", symbol: "Ft" },
  { name: "Indonesian Rupiah", code: "IDR", symbol: "Rp" },
  { name: "Israeli New Sheqel", code: "ILS", symbol: "₪" },
  { name: "Indian Rupee", code: "INR", symbol: "₹" },
  { name: "Iraqi dinar", code: "IQD", symbol: " ع.د" },
  { name: "Iranian rial", code: "IRR", symbol: "﷼" },
  { name: "Icelandic KrÃ³na", code: "ISK", symbol: "kr" },
  { name: "Jamaican Dollar", code: "JMD", symbol: "J$" },
  { name: "Jordanian dinar", code: "JOD", symbol: "د.ا" },
  { name: "Japanese Yen", code: "JPY", symbol: "¥" },
  { name: "Kenyan Shilling", code: "KES", symbol: "KSh" },
  { name: "Kyrgystani Som", code: "KGS", symbol: "лв" },
  { name: "Cambodian Riel", code: "KHR", symbol: "KHR" },
  { name: "Comorian Franc", code: "KMF", symbol: "CF" },
  { name: "North Korean won", code: "KPW", symbol: "₩" },
  { name: "South Korean Won", code: "KRW", symbol: "₩" },
  { name: "Kuwaiti dinar", code: "KWD", symbol: "KD" },
  { name: "Cayman Islands Dollar", code: "KYD", symbol: "CI$" },
  { name: "Kazakhstani Tenge", code: "KZT", symbol: "лв" },
  { name: "Laotian Kip", code: "LAK", symbol: "₭" },
  { name: "Lebanese Pound", code: "LBP", symbol: "£" },
  { name: "Sri Lankan Rupee", code: "LKR", symbol: "Rs" },
  { name: "Liberian Dollar", code: "LRD", symbol: "L$" },
  { name: "Lesotho Loti", code: "LSL", symbol: "L" },
  { name: "Libyan dinar", code: "LYD", symbol: "ل.د" },
  { name: "Moroccan Dirham", code: "MAD", symbol: "MAD" },
  { name: "Moldovan Leu", code: "MDL", symbol: "L" },
  { name: "Malagasy Ariary", code: "MGA", symbol: "Ar" },
  { name: "Macedonian Denar", code: "MKD", symbol: "ден" },
  { name: "Myanmar Kyat", code: "MMK", symbol: "K" },
  { name: "Mongolian Tugrik", code: "MNT", symbol: "₮" },
  { name: "Macanese Pataca", code: "MOP", symbol: "MOP$" },
  { name: "Mauritanian Ouguiya", code: "MRO", symbol: "MRU" },
  { name: "Mauritian Rupee", code: "MUR", symbol: "₨" },
  { name: "Maldivian Rufiyaa", code: "MVR", symbol: "Rf" },
  { name: "Malawian Kwacha", code: "MWK", symbol: "MK" },
  { name: "Mexican Peso", code: "MXN", symbol: "Mex$" },
  { name: "Malaysian Ringgit", code: "MYR", symbol: "RM" },
  { name: "Mozambican metical", code: "MZN", symbol: "MT" },
  { name: "Namibian Dollar", code: "NAD", symbol: "N$" },
  { name: "Nigerian Naira", code: "NGN", symbol: "₦" },
  { name: "Nicaraguan CÃ³rdoba", code: "NIO", symbol: "C$" },
  { name: "Norwegian Krone", code: "NOK", symbol: "kr" },
  { name: "Nepalese Rupee", code: "NPR", symbol: "₨" },
  { name: "New Zealand Dollar", code: "NZD", symbol: "NZ$" },
  { name: "Omani rial", code: "OMR", symbol: "ر.ع" },
  { name: "Panamanian Balboa", code: "PAB", symbol: "B/." },
  { name: "Peruvian Nuevo Sol", code: "PEN", symbol: "S/." },
  { name: "Papua New Guinean Kina", code: "PGK", symbol: "K" },
  { name: "Philippine Peso", code: "PHP", symbol: "₱" },
  { name: "Pakistani Rupee", code: "PKR", symbol: "₨" },
  { name: "Polish Zloty", code: "PLN", symbol: "zł" },
  { name: "Paraguayan Guarani", code: "PYG", symbol: "₲" },
  { name: "Qatari Rial", code: "QAR", symbol: "ق.ر" },
  { name: "Romanian Leu", code: "RON", symbol: "lei" },
  { name: "Serbian Dinar", code: "RSD", symbol: "din" },
  { name: "Russian Ruble", code: "RUB", symbol: "₽" },
  { name: "Rwandan Franc", code: "RWF", symbol: "FRw" },
  { name: "Saudi Riyal", code: "SAR", symbol: "﷼" },
  { name: "Solomon Islands Dollar", code: "SBD", symbol: "Si$" },
  { name: "Seychellois Rupee", code: "SCR", symbol: "SRe" },
  { name: "Sudanese pound", code: "SDG", symbol: "ج.س" },
  { name: "Swedish Krona", code: "SEK", symbol: "kr" },
  { name: "Singapore Dollar", code: "SGD", symbol: "S$" },
  { name: "St. Helena Pound", code: "SHP", symbol: "£" },
  { name: "Sierra Leonean Leone", code: "SLL", symbol: "Le" },
  { name: "Somali Shilling", code: "SOS", symbol: "Sh.so." },
  { name: "Surinamese Dollar", code: "SRD", symbol: "SRD" },
  // { name: "South Sudanese pound", code: "SSP", symbol: "£" },
  { name: "São Tomé and Príncipe Dobra", code: "STD", symbol: "Db" },
  { name: "Salvadoran colón", code: "SVC", symbol: "SVC" },
  { name: "Syrian pound", code: "SYP", symbol: "£S" },
  { name: "Swazi Lilangeni", code: "SZL", symbol: "E" },
  { name: "Thai Baht", code: "THB", symbol: "฿" },
  { name: "Tajikistani Somoni", code: "TJS", symbol: "SM" },
  { name: "Turkmenistani manat", code: "TMT", symbol: "T" },
  { name: "Tunisian dinar", code: "TND", symbol: "DT" },
  { name: "Tongan Pa'anga", code: "TOP", symbol: "T$" },
  { name: "Turkish Lira", code: "TRY", symbol: "₺" },
  { name: "Trinidad & Tobago Dollar", code: "TTD", symbol: "TT$" },
  { name: "New Taiwan Dollar", code: "TWD", symbol: "NT$" },
  { name: "Tanzanian Shilling", code: "TZS", symbol: "TSh" },
  { name: "Ukrainian Hryvnia", code: "UAH", symbol: "₴" },
  { name: "Ugandan Shilling", code: "UGX", symbol: "USh" },
  { name: "US Dollar", code: "USD", symbol: "$" },
  // { name: "US Dollar (Next Day)", code: "USN", symbol: "$" },
  { name: "Uruguayan Peso", code: "UYU", symbol: "$U" },
  { name: "Uzbekistan Som", code: "UZS", symbol: "лв" },
  { name: "Venezuelan bolívar", code: "VEF", symbol: "Bs" },
  { name: "Vietnamese Dong", code: "VND", symbol: "₫" },
  { name: "Vanuatu Vatu", code: "VUV", symbol: "VT" },
  { name: "Samoan Tala", code: "WST", symbol: "SAT" },
  { name: "CFA Franc BEAC", code: "XAF", symbol: "FCFA" },
  { name: "Silver Ounce", code: "XAG", symbol: "Oz" },
  { name: "Gold Ounce", code: "XAU", symbol: "Oz" },
  { name: "East Caribbean Dollar", code: "XCD", symbol: "EC$" },
  { name: "Special Drawing Rights", code: "XDR", symbol: "XDR" },
  { name: "CFA Franc BCEAO", code: "XOF", symbol: "CFA" },
  // { name: "Palladium Ounce", code: "XPD", symbol: "Oz" },
  { name: "CFP Franc", code: "XPF", symbol: "₣" },
  // { name: "Platinum Ounce", code: "XPT", symbol: "Oz" },
  { name: "Yemeni Rial", code: "YER", symbol: "﷼" },
  { name: "South African Rand", code: "ZAR", symbol: "R" },
  { name: "Zambian Kwacha", code: "ZMW", symbol: "ZK" },
  { name: "Zimbabwean dollar", code: "ZWL", symbol: "ZWL" },
];
