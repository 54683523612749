import React, { useEffect, useState } from "react";
import { Box, ThemeProvider, Container } from "@mui/material";
import theme from "@speed/common/src/theme";
import Main from "../container/Main";
import Sidebar from "@speed/common/src/components/SideBar";
import Header from "../components/Header/Header";
import { useSelector, useDispatch } from "react-redux";
import { sessionService } from "redux-react-session";
import { getUser, setInitialState } from "../redux/auth/actions";
// import { serviceWorkerRegistration } from "@speed/common/src/util/firebase";
// import NotificationBlock from "../components/Common/NotificationBlock";
import { setUser } from "@sentry/browser";
import Notification from "../components/Common/Notification";
import { withRouter } from "react-router";
import {
  callStrapiAPIInterface,
  getFirestoreData,
  getRouteForOnboarding,
  isFromEmailRoutes,
  callAPIInterface,
  noOfRecords,
  getScheduledPayout,
} from "../components/constants";
import PageLoader from "@speed/common/src/components/PageLoader";
import ConfirmPassword from "../components/Common/ConfirmPassword";
import {
  getAccountAsset,
  getPaymentMethodsList,
  setBrandingPageUI,
  setIsDataAvailable,
  setMaintenanceDetails,
  setRouteDetails,
  setSettingFormChange,
  setShowMaintenanceBar,
  setWalletAssets,
} from "../redux/common/actions";
import {
  setPageTitle,
  checkMainMenu,
  getMainMenuRoute,
  DiagonalDiv,
} from "@speed/common/src/components/constants";
import { privateRoute, publicRoute, walletRoute } from "../components/menu";
import _ from "lodash";
import GoogleAnalytics from "@speed/common/src/components/GoogleAnalytics";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { requestGrantPaths, wallet } from "../components/messages";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { Merchant, Wallet } from "@speed/common/src/components/messages";
import { vectorImage } from "@speed/common/src/components/images";
import VerifyOTPModal from "../components/Common/VerifyOTPModal";
import AutomaticLogoutModal from "../components/SignIn/AutomaticLogoutModal";
import AccountRestrictedModal from "../components/Common/AccountRestrictedModal";
import useIntercomScript from "../hooks/useIntercomScript";
import {
  scheduledStatus,
  underMaintenanceStatus,
} from "@speed/common/src/messages";

const Layout = () => {
  const queryParam = "?limit=" + noOfRecords;
  const dispatch = useDispatch();
  useIntercomScript();
  const [showSidebar, setShowSidebar] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [fsLoader, setFsLoader] = useState(true);
  const [isTokenUpdate, setIsTokenUpdate] = useState(false);
  const [getStartedSubmenu, setGetStartedSubmenu] = useState(null);
  const [autoPayoutScheduling, setAutoPayoutScheduling] = useState(false);

  const {
    isMobile,
    history,
    currentRouteDetails,
    openVerifyOTPModal,
    isPayoutSchedulingEnabled,
    accountRestrictedModalData,
    maintenanceDetails,
  } = useSelector((state) => state.common);
  const { isLoggedIn, user, currentAccount, liveMode } = useSelector(
    (state) => state.auth
  );
  const accountType = currentAccount?.account?.account_type;

  const pageRoute = history && history.location && history.location.pathname;
  const listOfPublicRoutes = publicRoute.map((page) => page.route);
  const { setPathPageView, setIdentity } = useTrackingCode();
  const ldClient = useLDClient();

  const loadInitialData = async () => {
    await sessionService
      .loadSession()
      .then((session) => {
        dispatch(setInitialState(session));
      })
      .catch((_e) => {});
  };

  if (user) {
    setUser({ id: user.id, username: user.name, email: user.email });
  }

  // Fire-store implementation for real-time updates
  useEffect(async () => {
    setLoading(true);
    if (isLoggedIn) {
      if (user) {
        // serviceWorkerRegistration();
        await getFirestoreData(user, setFsLoader, setIsTokenUpdate);
        setLoading(false);
      }
    } else setLoading(false);
  }, [isLoggedIn, user]);

  useEffect(() => {
    if (!isLoggedIn) setAutoPayoutScheduling(false);
    if (
      isLoggedIn &&
      currentAccount?.account?.account_type === Merchant &&
      liveMode
    )
      getScheduledPayout({ setAutoPayoutScheduling });
  }, [currentAccount?.account?.account_type, isLoggedIn, liveMode]);

  const checkUserSession = async () => {
    await sessionService
      .loadUser()
      .then((_res) => {
        const pathname = ["/verify-email", "/new-password", "/confirm-email"];
        if (
          history &&
          history.location &&
          !pathname.includes(history.location.pathname)
        )
          sessionService.deleteUser();
      })
      .catch((e) => e);
  };

  useEffect(() => {
    if (user) {
      dispatch(
        setBrandingPageUI(
          user?.accounts_details?.[0]?.account?.account_public_info?.branding
        )
      );
      // if (!JSON.parse(localStorage.getItem("invitee"))) {
      //   dispatch(getPaymentMethodsList());
      // }
    }
  }, [user]);

  const fetchGetStartedSpeedData = () => {
    callStrapiAPIInterface("GET", "/api/get-started-speeds?sort[0]=menu_order")
      .then((res) => {
        const response = res?.data;
        const getStartedSpeed = [];
        response?.forEach((attr) => {
          const menu = attr.attributes;
          menu.subMenu = [];
          getStartedSpeed.push(menu);
        });
        setGetStartedSubmenu(getStartedSpeed);
      })
      .catch((e) => false);
  };

  useEffect(() => {
    if (currentAccount) {
      dispatch(getPaymentMethodsList());
      dispatch(getAccountAsset());
    }
  }, [currentAccount]);

  useEffect(() => {
    if (currentAccount?.account?.account_type === wallet) {
      getAllAssets([], queryParam);
      fetchMaintenanceDetails();
    }
  }, [liveMode, currentAccount]);

  useEffect(() => {
    checkUserSession();
    const siteAuth = localStorage.getItem("site_auth");
    siteAuth && siteAuth === "0" && localStorage.setItem("site_auth", "1");
    loadInitialData();
    fetchGetStartedSpeedData();
    if (isLoggedIn) {
      setLoading(true);
      dispatch(getUser(true, true));
    }
  }, []);

  useEffect(() => {
    if (history) {
      //To Set the path to the current page and identify a visitor to your site in Hubspot
      setPathPageView(history.location.pathname);
      user &&
        setIdentity({ id: user.id, username: user.name, email: user.email });

      window.scrollTo(0, 0);
      const { location } = history;
      const isMainMenu = checkMainMenu(location.pathname);
      if (isMainMenu) {
        let mainMenuRoute = getMainMenuRoute(location.pathname);
        if (currentAccount?.account?.account_type === Wallet) {
          for (const item in walletRoute) {
            if (mainMenuRoute === walletRoute[item].route) {
              dispatch(setRouteDetails(walletRoute[item]));
            }
          }
        } else {
          for (const item in privateRoute) {
            if (mainMenuRoute === privateRoute[item]?.route) {
              dispatch(setRouteDetails(privateRoute[item]));
            }
            if (privateRoute[item]?.subMenu?.length) {
              // for accordion in main menu
              for (const subItem in privateRoute[item]?.subMenu) {
                if (
                  mainMenuRoute === privateRoute[item]?.subMenu[subItem]?.route
                ) {
                  dispatch(
                    setRouteDetails(privateRoute[item]?.subMenu[subItem])
                  );
                }
              }
            }
          }
        }
      } else {
        Object.entries(privateRoute)?.forEach(([_rootKey, rootValue]) => {
          Object.entries(rootValue?.subMenu)?.forEach(([_key, value]) => {
            if (value?.subMenu?.length > 0) {
              _.forEach(value.subMenu, (subMenuChild) => {
                if (subMenuChild.subMenu) {
                  let item = _.find(subMenuChild.subMenu, {
                    route: location.pathname,
                  });
                  if (item) {
                    dispatch(setRouteDetails(subMenuChild));
                  }
                } else {
                  location.pathname.includes(subMenuChild.route) &&
                    dispatch(setRouteDetails(subMenuChild));
                }
              });
            } else {
              if (location.pathname.includes(value.route)) {
                dispatch(setRouteDetails(value));
              }
            }
          });
        });
      }
    }
  }, [history?.location?.pathname, currentAccount]);

  useEffect(() => {
    if (currentAccount?.account?.id) {
      const ldUser = {
        key: `${currentAccount.account.id}-anonymous`,
      };
      ldClient.identify(ldUser);
    }
  }, [currentAccount?.account?.id]);

  const fetchMaintenanceDetails = () => {
    callAPIInterface("GET", "/settings/maintenance/web_wallet")
      .then((res) => {
        if (res) {
          dispatch(setMaintenanceDetails(res));
          dispatch(setShowMaintenanceBar(res?.status === scheduledStatus));
          if (res?.status === underMaintenanceStatus) {
            history.push("/under-maintenance");
          }
        }
      })
      .catch((_error) => {});
  };

  const getAllAssets = (lines, params) => {
    callAPIInterface("GET", "/assets" + params, {})
      .then((res) => {
        if (res?.data) {
          dispatch(setWalletAssets(lines.concat(res.data)));
        }
      })
      .catch((_e) => {});
  };

  const isFromEmail =
    history &&
    history.location &&
    isFromEmailRoutes.includes(history.location.pathname)
      ? true
      : false;

  const closureFlag = currentAccount?.account?.closure_requested_at;

  const openPSHelloBar = localStorage.getItem("open_ps_hello_bar");
  const autoPayoutSchedulingEnabled =
    liveMode &&
    !closureFlag &&
    autoPayoutScheduling &&
    !isPayoutSchedulingEnabled &&
    !openPSHelloBar;
  const isWalletSideBarVisible =
    accountType === Wallet
      ? maintenanceDetails?.status !== underMaintenanceStatus
      : true;

  const renderLayout = () => {
    let titleText;
    if (pageRoute) {
      if (listOfPublicRoutes.includes(pageRoute)) {
        titleText = _.find(publicRoute, { route: pageRoute }).title;
      } else {
        if (currentAccount?.account) {
          let titlePrefix;

          if (pageRoute.includes("/success-page")) {
            titlePrefix = "Success";
          } else if (pageRoute.includes("/verify-payout-wallet")) {
            titlePrefix = "Verify Payout Wallet";
          } else if (pageRoute.includes("guide")) {
            titlePrefix = getRouteForOnboarding(pageRoute);
          } else if (pageRoute === "/search") {
            titlePrefix = "Search";
          } else if (pageRoute === "/authorize/connect") {
            titlePrefix = "One Click";
          } else if (pageRoute === "/under-maintenance") {
            titlePrefix = "Under Maintenance";
          } else if (
            currentRouteDetails &&
            Object.keys(currentRouteDetails).length > 0
          ) {
            titlePrefix =
              currentRouteDetails.header === "Dashboard"
                ? "Home"
                : currentRouteDetails.header;
          } else if (pageRoute.split("/")[1] === "prices") {
            titlePrefix = "Products";
          }

          titleText = titlePrefix
            ? `${titlePrefix} - ${currentAccount.account?.name} - Speed`
            : "Speed";
        }
      }
    }

    const renderView = () => {
      const closureCheck = autoPayoutSchedulingEnabled || closureFlag;
      return (
        <Box
          className={`app-root-container ${
            history.location.pathname === "/wallet-transactions" &&
            "wallet-root-container"
          } ${closureCheck && "closure-container"} `}
          marginTop={
            (isLoggedIn && !isFromEmail) || (!isLoggedIn && isMobile)
              ? closureCheck
                ? "102px"
                : "61px"
              : isMobile
              ? "40px"
              : "70px"
          }
        >
          {setPageTitle(titleText ? titleText : "Speed")}
          {titleText && <GoogleAnalytics />}
          {isLoggedIn &&
            !requestGrantPaths.includes(history.location.pathname) &&
            (!isMobile || showSidebar) &&
            !isFromEmail &&
            isWalletSideBarVisible && (
              <Box component="nav" sx={{ width: "250px" }}>
                {" "}
                <Sidebar
                  user={user}
                  menuItems={
                    accountType === Merchant ? privateRoute : walletRoute
                  }
                  getStartedSubmenu={getStartedSubmenu}
                  onClickItem={(value) => dispatch(setSettingFormChange(value))}
                  setIsDataAvailable={setIsDataAvailable}
                  autoPayoutSchedulingEnabled={autoPayoutSchedulingEnabled}
                />
              </Box>
            )}
          <Box
            className="section-container"
            component="main"
            sx={{ width: "calc(100% - 250px)", flexGrow: 1 }}
          >
            <AutomaticLogoutModal />
            <Main />
          </Box>
        </Box>
      );
    };
    return !isLoggedIn ? renderView() : !fsLoader && renderView();
  };

  const hideRoutes = ["/settings/payment-page", "/settings/connect-branding"];

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="app-root"
        sx={hideRoutes.includes(pageRoute) ? { overflowY: "hidden" } : {}}
      >
        <Header
          setShowSidebar={(displaySidebar) => setShowSidebar(displaySidebar)}
          isFromEmail={isFromEmail}
          autoPayoutSchedulingEnabled={autoPayoutSchedulingEnabled}
          setAutoPayoutScheduling={setAutoPayoutScheduling}
          autoPayoutScheduling={autoPayoutScheduling}
        />
        {!isLoggedIn || isFromEmail ? (
          <Box className="main-wrapper-container">
            <DiagonalDiv
              mainDivSx={{
                backgroundImage: `url(
                ${vectorImage}
              )`,
              }}
            >
              <Container maxWidth="xl" sx={{ paddingBottom: "5%" }}>
                {renderLayout()}
              </Container>
            </DiagonalDiv>
          </Box>
        ) : (
          (user || !isLoading) && (
            <>
              <meta name="viewport" content="width=1279" />
              {renderLayout()}
            </>
          )
        )}
        <Notification />
        <ConfirmPassword />
        {accountRestrictedModalData?.openRestrictedModal && (
          <AccountRestrictedModal />
        )}
        {openVerifyOTPModal && <VerifyOTPModal />}
        <PageLoader
          open={isLoading}
          alt="loading..."
          customClass="page-loader"
        />
        {isTokenUpdate && (
          <PageLoader
            open={fsLoader}
            alt="loading..."
            customClass="page-loader"
          />
        )}
        {/* {isLoggedIn && <NotificationBlock />} */}
      </Box>
    </ThemeProvider>
  );
};

export default withRouter(Layout);
