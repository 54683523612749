import ReactPlayer from "react-player/lazy";

const VideoPlayer = ({ width, height, url, setLoading }) => {
  return (
    <ReactPlayer
      width={width}
      height={height}
      url={url}
      onReady={() => setLoading(false)}
    />
  );
};

export default VideoPlayer;
