import React, { useEffect, useState } from "react";
import { Box, Grid, TableCell, TableRow } from "@mui/material";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import {
  RouteBreadcrumbs,
  callAPIInterface,
  checkStatus,
  currencyAmountValidation,
  dateTimeFormatInApp,
  editProduct,
  fetchProductDetails,
  moduleMetaDataRows,
  renderStatus,
  updateMetadata,
} from "../constants";
import Text from "@speed/common/src/components/Text/Text";
import {
  clipboardElement,
  generateBreadcrumbs,
  getAmountByCurrency,
  getCurrencyObj,
  linkStatus,
  redirectWhenEntityNotFound,
} from "@speed/common/src/components/constants";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import { useSelector } from "react-redux";
import {
  add,
  addAnotherPrice,
  dateCreated,
  dateUpdated,
  edit,
  editPrice,
  entityNotFoundMessage,
  events,
  imageLabel,
  metaDataText,
  noPrices,
  pricesLabel,
  product,
  productLinkNotFoundBtnTxt,
  productLinkNotFoundSubtitle,
  status,
  unArchive,
} from "../messages";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import history from "@speed/common/src/components/history";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MetaDataForm from "../MetaDataForm";
import { useFormik } from "formik";
import Button from "@speed/common/src/components/Button/Button";
import CommonActionPopper from "./CommonActionPopper";
import { useFlags } from "launchdarkly-react-client-sdk";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import SinglePriceModal from "./SinglePriceModal";
import WarningIcon from "@mui/icons-material/Warning";
import * as yup from "yup";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import DetailPagePaymentSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import LinkSkeleton from "@speed/common/src/components/Skeleton/LinkSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";

const priceColumnData = [
  { title: "Price", width: "315px !important" },
  { title: "Price ID", width: "135px" },
  { title: "Created on", width: "230px" },
  { title: "Status", width: "120px" },
  // { title: "Action", with: "100px" },
];

const ProductDetail = (props) => {
  const [priceList, setPriceList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [metaDataSkeleton, setMetaDataSkeleton] = useState(true);
  const [priceSkeleton, setPriceSkeleton] = useState(true);
  const [isMetaDataEditable, setIsMetaDataEditable] = useState(false);
  const [metaDataList, setMetaDataList] = useState([]);
  const [eventSkeleton, setEventSkeleton] = useState(true);
  const [eventsList, setEventsList] = useState([]);
  const [title, setTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFrom, setIsFrom] = useState(null);
  const [data, setData] = useState(null);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [actionLoader, setActionLoader] = useState(false);

  const urlParams = useParams();
  const total_count = history?.location?.state?.total_count;

  const liveMode = useSelector((state) => state.auth.liveMode);

  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });

  const {
    prodMetadataFe,
    prodDetailFe,
    prodListpricesFe,
    prodAddpricesFe,
    prodArchiveFe,
  } = useFlags();

  const initialValues = {
    modal: "standard",
    currency: { name: "", code: "", symbol: "" },
    amount: "",
    type: "",
  };

  const validationSchema = yup.object().shape({
    modal: yup.string().required(""),
    ...currencyAmountValidation,
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    prodDetailFe && loadDetailPage();
  }, [liveMode, urlParams?.id, prodDetailFe]); // add event metadata flag in dependency

  useEffect(() => {
    if (prodMetadataFe && data?.metadata) {
      const convertedMetaDataToArray = Object.entries(data?.metadata)?.map(
        (e) => ({
          key: e[0],
          value: e[1],
        })
      );
      setMetaDataList(convertedMetaDataToArray);
      setMetaDataSkeleton(false);
    }
  }, [prodMetadataFe, data?.metadata]);

  const getEventsList = () => {
    setEventSkeleton(true);
    const filterEvents = {
      ids: [urlParams?.id],
    };
    callAPIInterface("POST", "/events/filters", JSON.stringify(filterEvents))
      .then((res) => {
        if (res) {
          setEventSkeleton(false);
          setEventsList(res?.data);
        }
      })
      .catch(() => {
        setEventSkeleton(false);
      });
  };

  const loadDetailPage = () => {
    setData(null);
    setIsShowNotFound(false);
    getProductDetails();
  };

  const getProductPriceList = () => {
    setPriceSkeleton(true);
    callAPIInterface("POST", `/prices/filter`, { product: urlParams.id })
      .then((res) => {
        if (res) {
          setPriceSkeleton(false);
          setPriceList(res.data);
        }
      })
      .catch(() => {
        setPriceSkeleton(false);
      });
  };

  const getProductDetails = () => {
    setMetaDataSkeleton(true);
    setPriceSkeleton(true);
    fetchProductDetails(urlParams.id)
      .then((res) => {
        if (res) {
          // clEventFe && getEventsList();
          prodListpricesFe ? getProductPriceList() : setPriceSkeleton(false);

          setData(res);
        }
      })
      .catch((err) => {
        setMetaDataSkeleton(false);
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const renderDetailPrice = (rowItem) => {
    if (rowItem.length === 0) {
      return noPrices;
    } else if (rowItem.length === 1) {
      return (
        <>
          {getCurrencyObj(rowItem?.[0]?.currency)?.symbol}
          {getAmountByCurrency(
            rowItem?.[0]?.unit_amount,
            rowItem?.[0]?.currency
          )}
        </>
      );
    } else {
      return `${total_count || priceList?.length} prices`;
    }
  };

  const headerContent = () => {
    return (
      <Box className="header-content">
        <Box display="flex" justifyContent="space-between">
          <TruncatedTextTooltip
            textValue={data?.name}
            cellWidth="555px"
            textProps={{
              size: 28,
              variant: "h6",
              className: "default-text",
              font: "semibold",
            }}
            maxHeight="25px"
          />
          {prodArchiveFe && (
            <>
              {data?.active ? (
                <Box display="flex" flexDirection="column" alignItems="end">
                  <MoreHorizIcon
                    className="horizontal-dots-icon pointer-cursor"
                    tabIndex={0}
                    onClick={(event) => {
                      handleClick(event, "product");
                    }}
                  />
                  {clipboardElement(
                    data?.id,
                    true,
                    "product-detail",
                    "text",
                    true
                  )}
                </Box>
              ) : (
                <Button
                  label={unArchive}
                  color="primary"
                  variant="outlined"
                  sx={{ marginBottom: "15px" }}
                  onClick={handleUnarchive}
                  iconPosition="start"
                  icon="unarchiveIcon"
                  loading={actionLoader}
                />
              )}
            </>
          )}
        </Box>

        <Box className="header-price-content">
          {prodListpricesFe && (
            <Box className="fiat-price">
              {!priceSkeleton ? (
                <Text className="default-text" size={20}>
                  {renderDetailPrice(priceList)}
                </Text>
              ) : (
                <CommonSkeleton sx={{ borderRadius: "8px" }} color="#8ba7ec" />
              )}
            </Box>
          )}
        </Box>
        <Box width="555px" marginTop="10px" sx={{ wordBreak: "break-all" }}>
          <Text
            className="grey-text product-desc"
            size={14}
            variant="subtitle"
            font="regular"
          >
            {data?.description}
          </Text>
        </Box>
      </Box>
    );
  };

  const productDetailTableLeftSideData = () => {
    return [
      {
        header: status,
        cell: (
          <Tag
            text={linkStatus[checkStatus(data)].label}
            variant={linkStatus[checkStatus(data)].variant}
          />
        ),
      },
      {
        header: dateCreated,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {dateTimeFormatInApp(data?.created)}
          </Text>
        ),
      },
      {
        header: dateUpdated,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {dateTimeFormatInApp(data?.modified)}
          </Text>
        ),
      },
    ];
  };

  const productDetailTableRightSideData = () => {
    return [
      {
        header: imageLabel,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <img
            src={data?.images?.[0]}
            className="product-image-preview"
            height={150}
            width={150}
          />
        ),
      },
    ];
  };

  const renderDetailContent = () => {
    let verticalTableData = {
      rowData: productDetailTableLeftSideData(),
    };

    if (data?.images?.length) {
      verticalTableData.rightSideRowData = productDetailTableRightSideData();
    }

    return (
      <Box className="details-content" marginTop="60px">
        <VerticalTable label="Details" {...verticalTableData} />
      </Box>
    );
  };

  const handleAddModal = () => {
    setTitle(addAnotherPrice);
    setIsModalOpen(true);
  };

  const handleViewAllPrice = () => {
    history.push(`/products/${urlParams?.id}/prices`);
  };

  const renderPriceTable = () => {
    return !priceSkeleton ? (
      <Box className="product-content">
        <HorizontalTable
          label={pricesLabel}
          isColumnShown={rows.length > 0}
          isShowButton={rows.length > 3}
          columns={priceColumnData}
          rows={rows}
          rowsPerPage={3}
          isShowPagination={false}
          isAddRow={prodAddpricesFe}
          handleAction={handleAddModal}
          tableBodyClassName="border-none"
          handleViewAllClick={handleViewAllPrice}
          isDisable={!data?.active}
        />
      </Box>
    ) : (
      <DetailPagePaymentSkeleton />
    );
  };

  const handleClick = (event, from) => {
    setIsFrom(from);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onSubmit = (payload) => {
    setMetaDataSkeleton(true);
    const finalPayload = { metadata: payload };
    updateMetadata(finalPayload, `/products/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setData(res);
        }
        setMetaDataSkeleton(false);
        setIsMetaDataEditable(false);
      })
      .catch(() => {
        setMetaDataSkeleton(false);
        setIsMetaDataEditable(false);
      });
  };

  const onCancel = () => setIsMetaDataEditable(false);
  const rows = priceList?.map((rowItem) => (
    <TableRow
      key={rowItem?.id}
      className="clickable"
      onClick={() => history.push(`/prices/${rowItem?.id}`)}
    >
      <TableCell>
        {getCurrencyObj(rowItem?.currency).symbol}
        {getAmountByCurrency(rowItem?.unit_amount, rowItem?.currency)}
      </TableCell>
      <TableCell>
        {clipboardElement(rowItem?.id, true, "product-detail", "text", true)}
      </TableCell>
      <TableCell align="left">
        {dateTimeFormatInApp(rowItem?.created)}
      </TableCell>
      <TableCell align="left">{renderStatus(checkStatus(rowItem))}</TableCell>
      {/* <TableCell>
        <MoreHorizIcon
          className="horizontal-dots-icon pointer-cursor"
          tabIndex={0}
          onClick={(event) => {
            event.stopPropagation();
            handleClick(event, "price");
          }}
        />
      </TableCell> */}
    </TableRow>
  ));

  const renderMetadata = () => {
    return (
      <>
        {!metaDataSkeleton ? (
          <Box className="product-content meta-data-content">
            {isMetaDataEditable ? (
              <MetaDataForm
                metaDataList={metaDataList}
                setIsMetaDataEditable={setIsMetaDataEditable}
                onSubmit={onSubmit}
                onCancel={onCancel}
              />
            ) : (
              <HorizontalTable
                label={metaDataText}
                rows={moduleMetaDataRows(metaDataList)}
                rowsPerPage={51}
                isColumnShown={moduleMetaDataRows(metaDataList)?.length > 0}
                tableBodyClassName="border-none"
                isShowButton={!isMetaDataEditable}
                displayShowButton={true}
                viewAllButtonLabel={metaDataList?.length > 0 ? edit : add}
                viewAllButtonIcon="editIcon"
                handleViewAllClick={() => setIsMetaDataEditable(true)}
              />
            )}
          </Box>
        ) : (
          <DetailPagePaymentSkeleton />
        )}
      </>
    );
  };

  const eventRows = eventsList?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/events/${rowItem?.id}`)}
      >
        <TableCell>{events(rowItem)}</TableCell>
        <TableCell align="right" sx={{ color: "#848b9e" }}>
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
      </TableRow>
    );
  });

  const renderEvents = () => {
    return (
      <>
        {!eventSkeleton ? (
          <Box className="product-content">
            <HorizontalTable
              label={events}
              isColumnShown={eventRows?.length > 0}
              rows={eventRows}
              rowsPerPage={3}
              handleViewAllClick={() => {
                history.push(`/checkout-links/${urlParams?.id}/events`);
              }}
              isShowButton={eventRows?.length > 3}
              tableBodyClassName="border-none"
            />
          </Box>
        ) : (
          <DetailPagePaymentSkeleton />
        )}
      </>
    );
  };

  // const handleEditAction = () => {
  //   setTitle(editPrice);
  //   if (isFrom === "product") {
  //   } else {
  //     setIsModalOpen(true);
  //   }
  // };

  const handleUnarchive = () => {
    setActionLoader(true);
    editProduct(data?.id, { active: true })
      .then(() => {
        setActionLoader(false);
        loadDetailPage();
      })
      .catch(() => {
        setActionLoader(false);
      });
  };

  return !isShowNotFound ? (
    <Grid
      className="payment-link-detail-wrapper product-detail-wrapper"
      container
      spacing={1}
    >
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      {prodDetailFe ? (
        <>
          {data ? (
            <>
              {headerContent()}

              {renderDetailContent()}
            </>
          ) : (
            <LinkSkeleton props={props} />
          )}
          {prodListpricesFe && renderPriceTable()}
          <CommonActionPopper
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            // handleEdit={() => handleEditAction()}
            from="productDetail"
            currentRow={data}
            getProductDetails={loadDetailPage}
          />
          {prodMetadataFe && renderMetadata()}

          {/* {renderEvents()} */}

          <SinglePriceModal
            setIsModalOpen={(val) => setIsModalOpen(val)}
            formik={formik}
            isModalOpen={isModalOpen}
            title={title}
            getProductPriceList={getProductPriceList}
            urlParams={urlParams}
            initialValues={initialValues}
          />
        </>
      ) : (
        <AccessDenied />
      )}
    </Grid>
  ) : (
    <Box className="not-found-wrapper product-invalid-link">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage(product)}
        subTitle={productLinkNotFoundSubtitle(urlParams.id)}
        btnText={productLinkNotFoundBtnTxt(liveMode)}
      />
    </Box>
  );
};

export default ProductDetail;
