import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@speed/common/src/components/Button/Button";
import CustomTable from "@speed/common/src/components/Table/Table";
import { CustomAvatar } from "@speed/common/src/components/Avatar/Avatar";
import {
  Box,
  TableRow,
  TableCell,
  ListItemText,
  ListItem,
  ListItemButton,
  List,
} from "@mui/material";
import { defaultUser } from "../../images";
import {
  transferOwnershipLabel,
  editMemberTitle,
  removeMemberTitle,
  RemoveMemberMsg,
  removeMemberBtn,
  transferingOwnershipText,
  remove,
  userRemovedSuccessMsg,
} from "../../messages";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  noOfRecords,
} from "../../constants";
import RoleMember from "./RoleMember";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useFlags } from "launchdarkly-react-client-sdk";
import TransferOwnership from "./TransferOwnership";
import { continueBtn } from "@speed/common/src/messages";
import { showToast } from "../../../redux/common/actions";

const Members = ({ roles, rolesName }) => {
  const dispatch = useDispatch();
  const queryParam = "?limit=" + noOfRecords;
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [hasMore, setHasMore] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [rows, setRows] = useState([]);
  const [editOpenModal, setEditOpenModal] = useState(false);
  const [transferOwnershipModal, setTransferOwnershipModal] = useState(false);
  const [removeUserLoader, setRemoveUserLoader] = useState(false);
  const [loaderTransfer, setLoaderTransfer] = useState(false);

  const { user, currentAccount } = useSelector((state) => state.auth);
  const { useracctTransferownershipFe, mfaAuthFe, usracctRemoveFe } =
    useFlags();

  const showActionColumn = useracctTransferownershipFe || usracctRemoveFe;
  const showTransferOwnershipListItem =
    useracctTransferownershipFe && currentAccount?.role?.id === "role_owner";
  const showRemoveUserListItem =
    usracctRemoveFe && user?.id !== currentRow?.user_id;

  let columns = [
    { title: "Team Member", width: "410px" },
    { title: "Email address", width: "260px" },
    { title: "Role", width: "205px" },
    { title: "Last login", width: "235px" },
  ];
  mfaAuthFe && columns.splice(3, 0, { title: "Auth", width: "200px" });

  showActionColumn &&
    columns.push({ title: "Action", width: "120px", align: "center" });
  const getMemberDetails = () => {
    setRows([]);
    setHasMore(true);
    getUsers([], queryParam);
  };

  useEffect(() => {
    getMemberDetails();
  }, []);

  useEffect(() => {
    setLoaderTransfer(false);
  }, [currentAccount?.role?.id]);

  const handleClick = (event, rowData) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setCurrentRow(rowData);
  };

  const columnsData = columns.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{ minWidth: column.width }}
    >
      {column.title}
    </TableCell>
  ));

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick(e, currentRow);
    }
  };

  const openPopper = Boolean(anchorEl);

  const removeUserFromAccount = () => {
    setRemoveUserLoader(true);
    callAPIInterface("DELETE", "/account-users", {
      ids: [currentRow?.user_id],
    })
      .then(() => {
        setAnchorEl(null);
        setDeleteFlag(false);
        getMemberDetails();
        setRemoveUserLoader(false);
        dispatch(
          showToast({
            isToastOpen: true,
            toastMessage: userRemovedSuccessMsg,
            toastVariant: "success",
          })
        );
      })
      .catch(() => setRemoveUserLoader(false));
  };

  const loadMore = useCallback(() => {
    getUsers(rows, queryParams);
  }, [rows]);

  const getUsers = async (lines, params) => {
    setTableRowSkeleton(true);
    const response = await callAPIInterface(
      "GET",
      "/account-users" + params,
      ""
    );
    if (response) {
      setTableRowSkeleton(false);
      if (!response.has_more) {
        setHasMore(false);
      } else {
        setQueryParams(
          queryParam +
            "&ending_before=" +
            response.data[response.data.length - 1].id
        );
      }
      setRows(lines.concat(response.data));
    }
  };

  const rowsData =
    rolesName &&
    rows?.map((rowItem) => {
      const teamMember =
        rowItem?.first_name != null || rowItem?.last_name != null
          ? rowItem?.first_name + " " + rowItem?.last_name
          : "";

      return (
        <TableRow key={rowItem.id}>
          <TableCell>
            <Box className="flex-inline">
              <CustomAvatar
                src={
                  rowItem.profile_image ? rowItem.profile_image : defaultUser
                }
                userName={teamMember}
              />
              <Box sx={{ paddingLeft: "15px" }}>
                {teamMember} {user?.id === rowItem.user_id && "(You)"}
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            <Box className="flex-inline">
              <Box sx={{ paddingRight: "15px", margin: "2.5px 0px" }}>
                {rowItem.email}
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            <Box className="flex-inline">
              <Box sx={{ paddingRight: "15px", margin: "2.5px 0px" }}>
                {rowItem.role === "role_owner"
                  ? "Administrator(Owner)"
                  : rolesName[rowItem.role]}
              </Box>
            </Box>
          </TableCell>
          {mfaAuthFe && (
            <TableCell>
              <Box className="flex-inline">
                <Box sx={{ paddingRight: "15px", margin: "2.5px 0px" }}>
                  {rowItem.is_mfa_configured ? "Two-step" : ""}
                </Box>
              </Box>
            </TableCell>
          )}
          <TableCell>{dateTimeFormatInApp(rowItem.last_login)}</TableCell>

          {showActionColumn && (
            <TableCell sx={{ textAlign: "center" }}>
              {(showTransferOwnershipListItem ||
                (usracctRemoveFe && user?.id !== rowItem?.user_id)) &&
                rowItem?.role !== "role_owner" && (
                  <MoreHorizIcon
                    className="horizontal-dots-icon pointer-cursor"
                    tabIndex={0}
                    onKeyDown={handleKeyDown}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClick(event, rowItem);
                    }}
                  />
                )}
            </TableCell>
          )}
        </TableRow>
      );
    });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    className: "link-table",
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
  };

  return (
    <Box className="payment-links-wrapper">
      <Box className="main-content">
        <CustomTable {...tableProps} />
      </Box>
      <CustomPopper
        disablePortal={true}
        open={openPopper}
        anchorEl={anchorEl}
        position="bottom"
        handleClose={() => setAnchorEl(null)}
      >
        <List>
          {/* {usracctUpdaterole && currentRow && currentRow.last_login ? (<ListItem disablePadding>
            <ListItemButton>
              <ListItemText
                primary={editPreference}
                onClick={() => {
                  setEditOpenModal(true);
                }}
              />
            </ListItemButton>
          </ListItem>
          ) : null} */}
          {showTransferOwnershipListItem && (
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  primary={transferOwnershipLabel}
                  onClick={() => {
                    setTransferOwnershipModal(true);
                  }}
                />
              </ListItemButton>
            </ListItem>
          )}
          {showRemoveUserListItem && (
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText
                  primary={remove}
                  className="text-danger"
                  onClick={() => setDeleteFlag(true)}
                />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </CustomPopper>
      <Modal
        maxWidth="sm"
        className="user-team-wrapper"
        body={<RoleMember roles={roles} currentRow={currentRow} />}
        footer={<Button label={continueBtn} />}
        handleClose={() => setEditOpenModal(false)}
        open={editOpenModal}
        title={editMemberTitle(
          currentRow?.first_name + " " + currentRow?.last_name
        )}
      />
      {transferOwnershipModal && (
        <TransferOwnership
          email={currentRow?.email}
          setTransferOwnershipModal={setTransferOwnershipModal}
          transferOwnershipModal={transferOwnershipModal}
          newOwnerId={currentRow?.user_id}
          setLoaderTransfer={setLoaderTransfer}
          getUsers={getMemberDetails}
        />
      )}
      <BackdropLoader
        open={loaderTransfer}
        alt={transferingOwnershipText}
        text={transferingOwnershipText}
        customClass="loader"
      />
      <Modal
        maxWidth="xs"
        body={RemoveMemberMsg(currentRow?.email)}
        footer={
          <Button
            loading={removeUserLoader}
            label={removeMemberBtn}
            color="error"
            onClick={removeUserFromAccount}
          />
        }
        handleClose={() => setDeleteFlag(false)}
        open={deleteFlag}
        title={removeMemberTitle}
      />
    </Box>
  );
};

export default Members;
