import React, { useState, useEffect, useContext, useRef } from "react";
import { Box } from "@mui/material";
import { connectSubMsg, continueBtn, loginBtn } from "../../../messages";
import Text from "../../Text/Text";
import EmailScreen from "./EmailScreen";
import PasswordScreen from "./PasswordScreen";
import { ConnectContext } from "../../../contexts/contexts";
import {
  connectSSOSource,
  handleSSOLoginRedirection,
  serverErrorStatusCodes,
} from "../../constants";
import {
  callAPIInterfaceConnect,
  logInConnect,
} from "@speed/connect/src/components/constants";

const ConnectLogin = ({ textFontFamily }) => {
  const [emailScreen, setEmailScreen] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifiedUserData, setVerifiedUserData] = useState({});
  const [token, setToken] = useState("");
  const emailRef = useRef();

  // Get context value using consumer
  const connectPageData = useContext(ConnectContext);

  const handleLogin = () => {
    let postObject = {
      email: email,
      password: password,
    };

    logInConnect(postObject).catch((e) => {
      if (serverErrorStatusCodes.includes(e)) props.handleEmailScreen();
      connectPageData?.setButtonLoader(false);
    });
  };

  const verifyEmail = (email) => {
    const verifyUserData = {
      email,
      turnstileToken: token,
    };
    callAPIInterfaceConnect("POST", "/verify-user", verifyUserData)
      .then((res) => {
        if (res?.signup_method !== "email") {
          const connectSSoState =
            res.signup_method === "apple"
              ? {
                  source: connectSSOSource,
                }
              : {};
          handleSSOLoginRedirection(res.signup_method, connectSSoState, false);

          return;
        }
        if (serverErrorStatusCodes.includes(res))
          emailRef?.current?.resetForm?.();
        else if (res) {
          setEmailScreen(false);
          connectPageData?.setButtonLoader(false);
          const obj = {
            name: res.first_name + " " + res.last_name,
            profile_images: res.profile_images,
          };
          setVerifiedUserData(obj);
        }
      })
      .catch((err) => {
        connectPageData?.setButtonLoader(false);
        setToken("");
        if (serverErrorStatusCodes.includes(err))
          emailRef?.current?.resetForm?.();
      });
  };

  const handleLoginButtonClick = () => {
    connectPageData?.setButtonLoader(true);
    if (email && password === "") {
      verifyEmail(email);
    } else {
      handleLogin();
    }
    connectPageData?.setIsButtonClicked(false);
  };

  useEffect(() => {
    if (connectPageData?.isButtonClicked) {
      handleLoginButtonClick();
    }
  }, [connectPageData?.isButtonClicked]);

  useEffect(() => connectPageData?.setShouldGoBackVisible(false), []);

  useEffect(() => {
    emailScreen
      ? connectPageData?.setSecondBtnLabel(continueBtn)
      : connectPageData?.setSecondBtnLabel(loginBtn);
  }, [emailScreen]);

  return (
    <Box className="connect-login">
      <Text
        font="regular"
        variant="subtitle1"
        className="default-text wrapper-content"
        align="center"
        sx={{ fontFamily: `${textFontFamily} !important` }}
      >
        {connectSubMsg}
      </Text>

      {emailScreen ? (
        <EmailScreen
          setEmail={setEmail}
          emailScreen={emailScreen}
          setToken={setToken}
          token={token}
          ref={emailRef}
        />
      ) : (
        <PasswordScreen
          email={email}
          setPassword={setPassword}
          setEmailScreen={setEmailScreen}
          verifiedUserData={verifiedUserData}
          emailScreen={emailScreen}
        />
      )}
    </Box>
  );
};

export default ConnectLogin;
