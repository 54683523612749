import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import Text from "../Text/Text";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  btcToSatsAmount,
  convertExponentialToDecimal,
  excludedCode,
  handleUpdateCheckoutSession,
  satsToBtcAmount,
  satsToFiatAmount,
  showAmountExceedError,
} from "../constants";
import {
  amountExceedMsg,
  amountValidate,
  presetAmount,
  presetAmountGreaterInvalid,
  presetAmountLesserInvalid,
} from "../../messages";
import CustomNotification from "../Notification/Notification";
import InputMask from "../MaskInput";
import Label from "../Label/Label";
import { PaymentPageContext } from "../../contexts/contexts";
import CommonSkeleton from "../CommonSkeleton";
import { isEmpty } from "lodash";

let timeout;
const PresetAmountPreview = ({
  paymentInfo,
  totalAmount,
  fontStyle,
  visibleInPaymentPage,
  checkoutSessionId,
  resolution,
  paymentStatus,
  selectedAssetCurrency,
  assetAmountLoader,
  paymentDetailsObj,
  selectedPaymentMethod,
}) => {
  const [presetAmountValue, setPresetAmountValue] = useState(0);
  const [totalDisplayAmount, setTotalDisplayAmount] = useState();
  const [isSwapCurrency, setIsSwapCurrency] = useState(true);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [fiatValue, setFiatValue] = useState();
  const [apiCalled, setApiCalled] = useState(false);
  const [currency, setCurrency] = useState("SATS");
  // Get context value using consumer
  const paymentPageData = useContext(PaymentPageContext);

  useEffect(() => {
    if (selectedAssetCurrency) {
      setCurrency(selectedAssetCurrency);
    }
  }, [selectedAssetCurrency]);

  const getSwapCurrencyFormatting = () => {
    return (
      <>
        (≈{" "}
        {currency !== "SATS" ? (
          `${selectedAssetCurrency} `
        ) : (
          <span
            style={{
              fontFamily: "Satoshi-Symbol",
              height: "24px",
              paddingTop: "1.5px",
            }}
          >
            !
          </span>
        )}
        {totalDisplayAmount || 0})
      </>
    );
  };

  const setDefaultValue = () => {
    const splittedAmount = totalAmount.split(" ");
    const btcAmount = splittedAmount[0];
    const checkForBtcAmount =
      splittedAmount[1] === "BTC" ? btcToSatsAmount(btcAmount) : btcAmount;

    let satsAmount;
    if (!isEmpty(paymentDetailsObj) && selectedAssetCurrency !== "SATS") {
      satsAmount = paymentDetailsObj?.[selectedAssetCurrency]?.target_amount;
    } else {
      satsAmount =
        paymentInfo?.currency?.code === "SATS"
          ? paymentInfo?.presetDetails?.presetAmount || 0
          : checkForBtcAmount;
    }

    setTotalDisplayAmount(satsAmount);
    return satsAmount;
  };

  const handleSetAmounts = (paymentInfo) => {
    const { presetDetails } = paymentInfo;
    setIsSwapCurrency(true);
    const satsAmount = setDefaultValue();
    setPresetAmountValue(satsAmount);
    setFiatValue(presetDetails?.presetAmount);
  };
  useEffect(() => {
    if (!apiCalled && paymentInfo) {
      handleSetAmounts(paymentInfo);
    }
  }, [paymentInfo, apiCalled, totalAmount]);

  useEffect(() => {
    if (paymentInfo && selectedAssetCurrency) {
      handleSetAmounts(paymentInfo);
    }
  }, [selectedAssetCurrency, paymentDetailsObj, paymentInfo]);

  const handleSwapCurrency = () => {
    isSwapCurrency
      ? setPresetAmountValue(fiatValue)
      : setPresetAmountValue(totalDisplayAmount || 0);
    setIsSwapCurrency(!isSwapCurrency);
  };

  const calculateSatsFromFiat = (amount) => {
    if (!isEmpty(paymentDetailsObj)) {
      return paymentDetailsObj?.[selectedAssetCurrency]?.exchange_rate * amount;
    }
    return Math.ceil(paymentInfo?.payment?.exchange_rate * parseFloat(amount));
  };

  const setValuesAfterError = () => {
    const satsAmount = setDefaultValue();
    const fiatAmountOfSats = satsToFiatAmount(
      satsAmount,
      paymentInfo?.payment?.exchange_rate
    );
    if (!isSwapCurrency) {
      setPresetAmountValue(paymentInfo?.presetDetails?.presetAmount);
    } else {
      setPresetAmountValue(satsAmount);
      setFiatValue(fiatAmountOfSats);
    }
  };
  const updateCheckoutSession = (amount) => {
    if (amount != paymentInfo?.amount) {
      handleUpdateCheckoutSession(
        amount,
        paymentPageData,
        checkoutSessionId,
        currency,
        [selectedPaymentMethod]
      )
        .then(() => {
          const satsAmount = calculateSatsFromFiat(amount);
          setTotalDisplayAmount(satsAmount);
          if (!isSwapCurrency) {
            setPresetAmountValue(amount);
            setFiatValue(amount);
            setIsSwapCurrency(false);
          } else {
            // set SATS value
            setPresetAmountValue(satsAmount);
            setIsSwapCurrency(true);
          }
          setApiCalled(true);
        })
        .catch((errorMessage) => {
          if (errorMessage) {
            setToastMessage(errorMessage);
            setIsToastOpen(true);
          }
          setValuesAfterError();
          setApiCalled(false);
        });
    }
  };

  const makeAPICall = (value) => {
    if (visibleInPaymentPage) {
      // Api call for entered preset amount on payment page
      updateCheckoutSession(value);
    }
  };

  const helperSetMessage = (errorMsg, value) => {
    if (errorMsg) {
      setIsToastOpen(true);
      setToastMessage(errorMsg);
      setValuesAfterError();
    } else {
      setIsToastOpen(false);
      makeAPICall(value);
    }
  };

  const setErrorMessage = (errorMsg, value) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      helperSetMessage(errorMsg, value);
    }, 1500);
  };

  const getCurrentSatsAmount = (value) => {
    return isSwapCurrency ? value : calculateSatsFromFiat(value);
  };

  const targetCurrencyToFiat = (value) => {
    return value / paymentDetailsObj?.[selectedAssetCurrency]?.exchange_rate;
  };

  const checkMinMaxValidation = (value) => {
    const presetData = paymentInfo?.presetDetails;
    if (presetData && value) {
      const { limits, minimumAmount, maximumAmount } = presetData;
      let errorMsg = "";
      let exchangeRate = selectedAssetCurrency
        ? paymentDetailsObj?.[selectedAssetCurrency]?.exchange_rate
        : paymentInfo?.payment?.exchange_rate;

      const fiatAmountOfSats =
        currency !== "SATS"
          ? targetCurrencyToFiat(value)
          : satsToFiatAmount(value, exchangeRate || 0.0);

      const amountForAPI = isSwapCurrency ? fiatAmountOfSats : value;
      if (limits) {
        const isOnlyFiat = !excludedCode.includes(paymentInfo?.currency?.code);
        const minAmount =
          isOnlyFiat && isSwapCurrency
            ? calculateSatsFromFiat(minimumAmount)
            : minimumAmount;
        const maxAmount =
          isOnlyFiat && isSwapCurrency
            ? calculateSatsFromFiat(maximumAmount)
            : maximumAmount;

        if (parseFloat(value) < minAmount || parseFloat(value) > maxAmount) {
          errorMsg =
            parseFloat(value) < minAmount
              ? presetAmountGreaterInvalid(
                  minimumAmount,
                  paymentInfo?.currency?.code
                )
              : presetAmountLesserInvalid(
                  maximumAmount,
                  paymentInfo?.currency?.code
                );
        }
        setErrorMessage(errorMsg, amountForAPI);
      } else {
        if (isSwapCurrency) {
          const btcValue = satsToBtcAmount(value);
          errorMsg =
            parseFloat(btcValue) > 5 ? amountExceedMsg(presetAmount) : "";
        } else {
          const values = {
            currency: paymentInfo?.currency,
            amount: value,
          };
          errorMsg = showAmountExceedError(
            values,
            undefined,
            paymentInfo?.payment?.exchange_rate,
            presetAmount,
            "checkout"
          );
        }
        setErrorMessage(errorMsg, amountForAPI);
      }
      const currentAmount = getCurrentSatsAmount(value);

      setPresetAmountValue(value);
      setTotalDisplayAmount(currentAmount);
      isSwapCurrency && setFiatValue(fiatAmountOfSats);
      !visibleInPaymentPage && !isSwapCurrency && setFiatValue(value);
    }
  };

  const handleChangeInput = (e) => {
    const value = e.target.value;
    const currentSatsAmount = getCurrentSatsAmount(value);
    if (timeout) clearTimeout(timeout);

    if (value <= 0) {
      setPresetAmountValue(value);
      setTotalDisplayAmount(currentSatsAmount);
      setFiatValue(value);

      timeout = setTimeout(() => {
        setIsToastOpen(true);
        setToastMessage(amountValidate);
        setValuesAfterError();
      }, 1500);
    } else {
      checkMinMaxValidation(value);
    }
  };

  return (
    <>
      <CustomNotification
        open={isToastOpen}
        onClose={(_event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setIsToastOpen(false);
        }}
        severity="error"
        message={toastMessage}
        className=""
        autoHideDuration={4000}
      />
      {assetAmountLoader ? (
        <CommonSkeleton animation="square" width={250} height={50} />
      ) : (
        <Box
          className={`preset-amount-preview ${
            resolution !== "web" && "content-center"
          }`}
        >
          <Label
            data-currency={
              isSwapCurrency ? currency : paymentInfo?.currency?.code
            }
            className={`label-type ${
              paymentStatus === "paid" && "mask-label-disabled"
            }`}
          >
            <InputMask
              extraClass="input-adornment"
              onChange={handleChangeInput}
              name="presetAmount"
              value={presetAmountValue}
              onKeyDown={(event) => {
                isSwapCurrency &&
                  currency === "SATS" &&
                  event.key === "." &&
                  event.preventDefault();
              }}
              disabled={
                paymentStatus === "paid" || !paymentInfo?.payment?.exchange_rate
              }
            />
          </Label>
          {!excludedCode.includes(paymentInfo?.currency?.code) && (
            <SwapVertIcon
              onClick={handleSwapCurrency}
              sx={{
                width: "34px",
                height: "34px",
                color: fontStyle?.color,
                marginLeft: "14px",
              }}
            />
          )}
        </Box>
      )}

      <Text
        className="checkout-acc-currency"
        size={18}
        font="regular"
        // align="center"
        variant="subtitle1"
        sx={fontStyle}
      >
        {isSwapCurrency
          ? !excludedCode.includes(paymentInfo?.currency?.code) && (
              <>
                (≈ {paymentInfo.currency.symbol}
                {(fiatValue &&
                  convertExponentialToDecimal(
                    +parseFloat(fiatValue).toFixed(16)
                  )) ||
                  0}
                )
              </>
            )
          : getSwapCurrencyFormatting()}
      </Text>
    </>
  );
};

export default PresetAmountPreview;
