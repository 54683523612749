import { isInteger } from "lodash";
import { validateEmail } from "@speed/common/src/components/constants";
import {
  enterValidSuccessDetailMsg,
  invalidAmountMsg,
  invalidLabel,
  invalidWLMsg,
  lengthValidationMsg,
  maxWLSatAmountMsg,
  urlWithHttpValidate,
  wlPasswordLengthErrMsg,
  minWLSatAmountMsg,
  invalidSatAmount,
} from "../../messages";
import { callAPIInterface, isAlphabet, validateURL } from "../../constants";
import { currency } from "@speed/common/src/components/currency";
import { store } from "../../../redux/store";
import { setFlatfileCurrencyRates } from "../../../redux/common/actions";

const minimumSATSAmount = 1;
const maximumSATSAmount = process.env.REACT_APP_LIGHTNING_MAX_LIMIT;

const getExchangeRate = async ({ currencyCode }) => {
  const data = {
    currency: currencyCode,
    target_currency: "SATS",
  };

  return await callAPIInterface("POST", "/utility/exchange-rate", data)
    .then((res) => {
      const newRateObj = { [currencyCode]: res?.target_lowest_rate };
      return newRateObj;
    })
    .catch(() => {});
};

export const setCurrencyRatesForNewCurrency = async ({ currencyCode }) => {
  const { flatfileCurrencyRates } = { ...store?.getState?.()?.common };
  if (!flatfileCurrencyRates[currencyCode]) {
    const rateObjForNewCurrency = await getExchangeRate({
      currencyCode,
    });

    const updatedCurrencyRates = {
      ...flatfileCurrencyRates,
      ...rateObjForNewCurrency,
    };

    store.dispatch(setFlatfileCurrencyRates(updatedCurrencyRates));
  }
};

const handleValidateStringAmount = ({ amount, record }) => {
  if (isNaN(amount)) {
    record.addError("amount", invalidAmountMsg);
    return false;
  }
  return true;
};

const validateSATSAmount = ({ currencyCode, satsAmount, record }) =>
  currencyCode === "SATS" &&
  !isInteger(satsAmount) &&
  record.addError("amount", invalidSatAmount);

const validateMinRequiredAmount = ({
  currencyCode,
  amountSatsValue,
  exchangeRate,
  record,
}) => {
  if (amountSatsValue < minimumSATSAmount) {
    const minimunRequiredFiatAmount = 1 / exchangeRate;
    record.addError(
      "amount",
      minWLSatAmountMsg(
        ["BTC", "SATS"].includes(currencyCode)
          ? minimunRequiredFiatAmount
          : minimunRequiredFiatAmount.toFixed(2),
        currencyCode
      )
    );
  }
};

const validateMaxAmount = ({
  currencyCode,
  amountSatsValue,
  exchangeRate,
  record,
}) => {
  if (amountSatsValue > maximumSATSAmount) {
    const maximumAllowedFiatAmount = maximumSATSAmount / exchangeRate;
    record.addError(
      "amount",
      maxWLSatAmountMsg(
        currencyCode === "BTC"
          ? maximumAllowedFiatAmount
          : Math.floor(maximumAllowedFiatAmount),
        currencyCode
      )
    );
  }
};

const handleValidateCurrencyBasedAmount = ({
  amount,
  currencyCode,
  record,
}) => {
  const { flatfileCurrencyRates } = { ...store?.getState?.()?.common };
  const exchangeRate = flatfileCurrencyRates[currencyCode];
  const amountSatsValue = Math.floor(exchangeRate * amount);

  validateSATSAmount({ currencyCode, satsAmount: +amount, record });
  validateMinRequiredAmount({
    currencyCode,
    amountSatsValue,
    exchangeRate,
    record,
  });
  validateMaxAmount({ currencyCode, amountSatsValue, exchangeRate, record });
};

export const ratesForCurrencyCode = {
  SATS: 1,
  BTC: 100000000,
};

export const validateLength = ({ string, minCharLength, maxCharLength }) => {
  if (!string) return false;

  const stringLength = string.trim()?.length;
  const isMaxThenMinCharLength = minCharLength <= stringLength;
  const isMinThenMaxCharLength = stringLength <= maxCharLength;

  return minCharLength
    ? isMaxThenMinCharLength && isMinThenMaxCharLength
    : isMinThenMaxCharLength;
};

export const handleValidateFFEmail = ({ email, record }) => {
  !validateEmail(email) && record.addError("email", invalidWLMsg);
  !validateLength({
    string: email,
    maxCharLength: 255,
  }) && record.addError("email", lengthValidationMsg());
};

export const handleValidateFFName = ({ name, record }) => {
  !isAlphabet.test(name) && record.addError("name", invalidLabel("Name"));
  !validateLength({
    string: name,
    maxCharLength: 255,
  }) && record.addError("name", lengthValidationMsg());
};

export const handleValidateFFAmount = async ({
  amount,
  currencyCode,
  record,
}) => {
  const isValidAmount = handleValidateStringAmount({ amount, record });
  amount &&
    isValidAmount &&
    currencyCode &&
    handleValidateCurrencyBasedAmount({ amount, currencyCode, record });
};

export const handleValidateFFPassword = ({ password, record }) => {
  !validateLength({
    string: password,
    minCharLength: 8,
    maxCharLength: 20,
  }) && record.addError("password", wlPasswordLengthErrMsg);
};

export const handleValidateFFSuccessMessage = ({ successMessage, record }) => {
  !validateLength({
    string: successMessage,
    maxCharLength: 255,
  }) && record.addError("success_message", lengthValidationMsg());
};

export const handleValidateFFSuccessUrl = ({
  successMessage,
  successURL,
  record,
}) => {
  successMessage && record.addError("success_url", enterValidSuccessDetailMsg);
  !validateURL(successURL, true) &&
    record.addError("success_url", urlWithHttpValidate);
  !validateLength({
    string: successURL,
    maxCharLength: 2048,
  }) && record.addError("success_url", lengthValidationMsg(2048));
};

export const getFlatfileCurrencyOptions = () => {
  return currency.map((item) => ({
    label: item.code,
    value: item.code,
  }));
};
