import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { Box, Grid, List, ListItem, MenuItem } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import {
  yes,
  no,
  tolerence,
  paymentMethodSubMsg,
  btcToleranceTooltipContentMain,
  btcToleranceTooltipContentSub,
  paymentMethodUpdateMsg,
} from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useDispatch, useSelector } from "react-redux";
import { btcOnchainTolerenceArray, callAPIInterface } from "../constants";
import React, { useState, useEffect } from "react";
import { Skeleton } from "@mui/lab";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { setOnchainTolerance, showToast } from "../../redux/common/actions";
import {
  SET_ACCOUNT_ASSETS,
  SET_ONCHAIN_PAYMENT_METHOD,
} from "../../redux/common/types";
import classNames from "classnames";
import CustomSelect from "@speed/common/src/components/Select/Select";
import _ from "lodash";
import { save } from "@speed/common/src/messages";
import { CustomSwitch } from "@speed/common/src/components/Switch/Switch";
import { showAmount } from "@speed/common/src/components/constants";

const PaymentMethod = () => {
  const { liveMode, user } = useSelector((state) => state.auth);

  const [gridData, setGridData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [rowId, setRowId] = useState(null);
  const { pmListpayment, pmPaymentmode, pmTolerance } = useFlags();
  const [toleranceLoading, setToleranceLoading] = useState();
  const [paymentMethodsEnabled, setPaymentMethodsEnabled] = useState(null);
  const [paymentMethodsTolerance, setPaymentMethodsTolerance] = useState(null);
  const [actionLoader, setActionLoader] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setGridData([]);
    getAssetForAccount();
  }, [liveMode, user]);

  useEffect(() => {
    const switchObj = {};
    const toleranceObj = {};
    if (gridData?.length) {
      gridData.map((data) => {
        data?.payment_method_details?.map((item) => {
          switchObj[item?.id] = item?.is_payment_method_enabled;
          toleranceObj[item?.id] = item?.amount_paid_tolerance;
        });
      });
    }
    setPaymentMethodsEnabled(switchObj);
    setPaymentMethodsTolerance(toleranceObj);
  }, [gridData]);

  const handleChange = (status, id, updateTolerance = false) => {
    const submitData = {
      status: status,
    };
    setRowId(id);
    if (updateTolerance) {
      setToleranceLoading(true);
      submitData.amount_paid_tolerance = paymentMethodsTolerance[id];
    }
    setActionLoader(true);
    callAPIInterface(
      "POST",
      `/assets-account/payment-methods/${id}`,
      submitData
    )
      .then((response) => {
        if (response) {
          setActionLoader(false);
          dispatch(
            showToast({
              isToastOpen: true,
              toastMessage: paymentMethodUpdateMsg,
              toastVariant: "success",
            })
          );
          const btcTolerence = response.amount_paid_tolerance?.toString();
          if (
            id === "pm_2" &&
            btcTolerence &&
            response.status === yes.toLowerCase()
          )
            dispatch(setOnchainTolerance(btcTolerence));
          dispatch({
            type: SET_ONCHAIN_PAYMENT_METHOD,
            payload: response.status,
          });

          const updatedResponse = gridData?.map((grid) => {
            const updatedPaymentMethodDetail =
              grid?.payment_method_details?.map((detail) => {
                return detail.id == response.id
                  ? {
                      ...detail,
                      is_payment_method_enabled:
                        response?.status === yes?.toLowerCase(),
                      amount_paid_tolerance: response?.amount_paid_tolerance,
                    }
                  : detail;
              });

            return {
              ...grid,
              payment_method_details: updatedPaymentMethodDetail,
            };
          });

          //update account asset for data used in CL/PL etc preview
          const sortedData = updatedResponse?.sort(
            (a, b) => b.is_default - a.is_default
          );
          const result = sortedData?.filter((asst) => {
            return asst.payment_method_details?.some(
              (a) => a.is_payment_method_enabled
            );
          });

          dispatch({ type: SET_ACCOUNT_ASSETS, payload: result });

          setGridData(updatedResponse);

          updateTolerance && setToleranceLoading(false);
          setRowId(null);
        }
      })
      .catch((_e) => {
        setActionLoader(false);
        updateTolerance && setToleranceLoading(false);
        setRowId(null);
      });
  };

  const getAssetForAccount = async () => {
    try {
      setLoader(true);
      const assets = await callAPIInterface("GET", "/assets-account");
      setGridData(assets?.data);
    } catch (err) {
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  const paymentMethodLoader = (showTolerence = false) => {
    return (
      <Box
        className={classNames(
          "custom-domain-wrapper",
          showTolerence && "tolerence-section-wrapper"
        )}
      >
        <Grid display="flex" alignItems="center">
          <Box
            className={classNames(showTolerence && "on-chain-section-img")}
            sx={{ paddingLeft: "20px", top: "5px !important" }}
          >
            <Skeleton
              height="100px"
              width="60px"
              sx={{ borderRadius: "60%" }}
            />
          </Box>
          <Box width="100%">
            <Box
              display="flex"
              justifyContent="space-between"
              paddingRight="20px"
            >
              <List disablePadding>
                <ListItem disablePadding className="loader-wrapper">
                  <Box>
                    <Box display="flex" sx={{ paddingBottom: "16px" }}>
                      <Text
                        className="card-text"
                        size={18}
                        font="bold"
                        align="left"
                        sx={{ paddingRight: "10px" }}
                      >
                        <Skeleton
                          height="21px"
                          width="211.3px"
                          sx={{ backgroundColor: "#c4ccd2 !important" }}
                        />
                      </Text>
                    </Box>
                    <Text
                      className="grey-text"
                      size={16}
                      font="regular"
                      variant="subtitle1"
                    >
                      <Skeleton height="16px" width="317px" />
                    </Text>
                  </Box>
                </ListItem>
              </List>
            </Box>
            {showTolerence && (
              <Box className="tolerence-main-box">
                <Box className="form-section">
                  <Box>
                    <Skeleton
                      height="16px"
                      width="100px"
                      sx={{ marginBottom: "17px" }}
                    />
                    <Skeleton height="40px" width="350px" />
                  </Box>
                  <Box className="save-tolerence-btn">
                    <Skeleton height="40px" width="100px" />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Box>
    );
  };

  const labelInfoTooltipContent = (currency) => (
    <Box className="btc-paid-tolerence-text-box">
      <Text variant="subtitle1" size={14} font="regular">
        {btcToleranceTooltipContentMain}
      </Text>
      <Text
        className="grey-text"
        size={12}
        font="regular"
        variant="subtitle1"
        sx={{ marginTop: "13px" }}
      >
        {btcToleranceTooltipContentSub(
          showAmount({
            amount: 10000,
            currency,
            showCommaSeparated: true,
          }),
          showAmount({
            amount: 9500,
            currency,
            showCommaSeparated: true,
          }),
          currency
        )}
      </Text>
    </Box>
  );

  const gridsWrapper =
    gridData?.length > 0 &&
    gridData?.map((gridItem) => {
      const { icon, uuid, name } = gridItem;
      return (
        <Box
          className="payment-method-wrapper"
          key={gridItem.id}
          padding="20px 29px"
        >
          <Box display="flex">
            <img src={icon} alt={uuid} height={40} width={40} />
            <Box display="flex" flexDirection="column">
              <Text
                className="card-text"
                size={20}
                font="bold"
                align="left"
                sx={{ marginLeft: "10px" }}
              >
                {name}
              </Text>
              <Text
                className="grey-text"
                size={16}
                font="regular"
                align="left"
                sx={{ marginLeft: "10px" }}
                variant="subtitle1"
              >
                {paymentMethodSubMsg}
              </Text>
            </Box>
          </Box>
          <CustomDivider
            sx={{ margin: "10px 36px 0", borderColor: "#F2F5F7" }}
          />
          {gridItem?.payment_method_details?.map((item, index) => {
            const { payment_method_name } = item;
            const showTolerence =
              payment_method_name !== "lightning" &&
              pmTolerance &&
              paymentMethodsEnabled[item?.id];
            const isBTCLightning =
              uuid === "SATS" && payment_method_name === "lightning";

            return (
              <>
                <Box className="payment-method-item">
                  <Box display="flex" justifyContent="space-between">
                    <Text
                      className="card-text"
                      size={18}
                      font="bold"
                      align="left"
                      sx={{ paddingRight: "10px" }}
                    >
                      {payment_method_name}
                    </Text>
                    {pmPaymentmode && (
                      <CustomSwitch
                        disabled={actionLoader || isBTCLightning}
                        checked={paymentMethodsEnabled[item?.id]}
                        name="payment_method_switch"
                        onChange={() => {
                          setPaymentMethodsEnabled({
                            ...paymentMethodsEnabled,
                            [item?.id]: !paymentMethodsEnabled[item?.id],
                          });
                          handleChange(
                            paymentMethodsEnabled[item?.id]
                              ? no.toLowerCase()
                              : yes.toLowerCase(),
                            item.id
                          );
                        }}
                        sx={{
                          marginTop: "10px",
                          ...(isBTCLightning && { opacity: "0.4" }),
                        }}
                      />
                    )}
                  </Box>
                  {showTolerence && (
                    <Box className="tolerence-main-box">
                      <Box className="form-section">
                        <CustomSelect
                          disabled={actionLoader}
                          showLabelInfoIcon
                          labelInfoTooltipContent={labelInfoTooltipContent(
                            gridItem?.uuid
                          )}
                          customClass="btc-tolerance-autocomplete"
                          name="amount_paid_tolerance"
                          displayEmpty
                          showLabel
                          label={tolerence}
                          value={paymentMethodsTolerance?.[item?.id]}
                          MenuProps={{
                            id: "tolerence-select",
                          }}
                          onChange={(e) => {
                            setPaymentMethodsTolerance({
                              ...paymentMethodsTolerance,
                              [item?.id]: Number(e.target.value),
                            });
                          }}
                          renderValue={(value) => {
                            if (value >= 0) {
                              let typeObj = _.find(btcOnchainTolerenceArray, {
                                value:
                                  typeof value === "object"
                                    ? value.id
                                    : value?.toString(),
                              });
                              return typeObj?.label;
                            }
                          }}
                        >
                          {btcOnchainTolerenceArray?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                        <Box className="save-tolerence-btn">
                          <Button
                            loading={toleranceLoading && rowId === item?.id}
                            type="submit"
                            disabled={
                              item?.amount_paid_tolerance ===
                              paymentMethodsTolerance[item?.id]
                            }
                            variant="contained"
                            label={save}
                            onClick={() => {
                              handleChange(yes.toLowerCase(), item?.id, true);
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
                {gridItem?.payment_method_details?.length - 1 !== index && (
                  <CustomDivider
                    sx={{ margin: "0 36px", borderColor: "#F2F5F7" }}
                  />
                )}
              </>
            );
          })}
        </Box>
      );
    });

  return (
    <>
      {pmListpayment ? (
        <>
          <CustomDivider />
          <Box className="box-container">
            {loader ? paymentMethodLoader() : gridsWrapper}
          </Box>
        </>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};
export default PaymentMethod;
