import React, { useState, useEffect } from "react";
import {
  FormControlLabel,
  FormGroup,
  IconButton,
  AppBar,
  Box,
  Grid,
  Toolbar,
  CssBaseline,
  Container,
} from "@mui/material";
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { SearchBar } from "@speed/common/src/components/SearchBar/SearchBar";
import { privateRoute } from "../menu";
import { Text } from "@speed/common/src/components/Text/Text";
import _ from "lodash";
// import { CustomBadge } from "@speed/common/src/components/Badge/Badge";
import { useSelector, useDispatch } from "react-redux";
import AccountSelection from "./AccountSelection";
import HeaderUserProfile from "@speed/common/src/components/HeaderUserProfile";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import { Link } from "react-router-dom";
import { CustomSwitch } from "@speed/common/src/components/Switch/Switch";
import { setLiveMode, logOut } from "../../redux/auth/actions";
import {
  setDefaultSelectedAsset,
  setPayoutModalOpen,
  setWebPageSearchResults,
} from "../../redux/common/actions";
import { useFlags } from "launchdarkly-react-client-sdk";
import SupportCenterDrawer from "./SupportCenterDrawer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  callAPIInterface,
  getTimeZoneAndDateFormat,
  getWebSearchAPIResults,
} from "../constants";
import { displaySidebarItem } from "@speed/common/src/components/constants";
import history from "@speed/common/src/components/history";
import {
  autoPayoutSetupMsg,
  closureInProgress,
  closureRequestMsg,
  payoutScheduling,
  requestAccepted,
  requestGrantPaths,
  revoke,
  testModeTitle,
} from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import { HelloBar } from "@speed/common/src/components/HelloBar/HelloBar";
import { calenderSvg, personCancelSvg } from "../images";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import CancelIcon from "@mui/icons-material/Cancel";
import PayoutWalletSetupDialog from "../Settings/PayoutWallets/AutoPayoutSetup/PayoutWalletSetupDialog";

const Header = ({
  setShowSidebar,
  isFromEmail,
  autoPayoutSchedulingEnabled,
  setAutoPayoutScheduling,
  autoPayoutScheduling,
}) => {
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [showSupportCenterDrawer, setShowSupportCenterDrawer] = useState(false);
  const [revokeLoading, setRevokeLoading] = useState(false);

  const { isLoggedIn, user, liveMode, currentAccount } = useSelector(
    (state) => state.auth
  );
  const isWalletAccount = currentAccount?.account?.account_type === "Wallet";
  const { isMobile, currentRouteDetails, payoutModalOpen } = useSelector(
    (state) => state.common
  );
  const [openSidebar, setOpenSidebar] = useState(false);
  const [liveModeLocal, setLiveModeLocal] = useState(isWalletAccount);
  const [switchLoader, setSwitchLoader] = useState(false);
  const [closureRequestFlag, setClosureRequestFlag] = useState(false);

  const toogleSidebarOpen = () => {
    setOpenSidebar(!openSidebar);
    setShowSidebar && setShowSidebar(!openSidebar);
  };

  const flags = useFlags();
  const { gserBar, wwTestmodeFe } = flags;
  const showTestModeLiveModeSwitchBtn = wwTestmodeFe || !isWalletAccount;
  const isClosureRequested = currentAccount?.account?.closure_requested_at;

  useEffect(() => {
    setLiveModeLocal(liveMode);
  }, [liveMode]);

  useEffect(() => {
    if (closureRequestFlag) {
      setTimeout(() => {
        window.location.reload();
        setRevokeLoading(false);
      }, 2000);
    }
  }, [closureRequestFlag]);

  const revokeRequest = async () => {
    setRevokeLoading(true);
    await callAPIInterface("POST", "/close-account/revoke");
    setClosureRequestFlag(true);
  };

  const closureInProgressBody = (
    <Grid item className="closure-box">
      <CheckCircleIcon className="check-icon" />
      <Text size={18} align="center" font="semibold" className="default-text">
        {requestAccepted}
      </Text>
      <Text
        align="center"
        font="regular"
        size={12}
        className="text"
        variant="caption"
        sx={{ backgroudColor: "#667085", padding: "0 60px" }}
      >
        {closureRequestMsg}
      </Text>
    </Grid>
  );

  const HighlightedText = ({ title, highlight }) => {
    const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, "gi");
    const parts = title.split(regex);
    return (
      <React.Fragment>
        {parts
          .filter((part) => part)
          .map((part, i) =>
            regex.test(part) ? (
              <Text key={i} component="span" size={14}>
                {part}
              </Text>
            ) : (
              <Text
                variant="h4"
                key={i}
                component="span"
                size={14}
                font="regular"
              >
                {part}
              </Text>
            )
          )}
      </React.Fragment>
    );
  };

  const isContainString = (inputString, searchString) => {
    return searchString.toLowerCase().includes(inputString.toLowerCase());
  };
  const isExternalSearchURL = (searchObject) => {
    if (searchObject.isExternalLink) return searchObject.isExternalLink;
    return false;
  };

  const inputChange = (inputString) => {
    if (inputString) {
      let resultItems = [];
      _.each(privateRoute, (pageObj, pageKey) => {
        if (
          isContainString(inputString, pageKey) &&
          !pageObj?.subMenu?.length
        ) {
          displaySidebarItem(pageObj, flags, currentAccount, user) &&
            resultItems.push({
              isExternalLink: isExternalSearchURL(pageObj),
              href: pageObj.route,
              icon: pageObj.icon,
              text: <HighlightedText title={pageKey} highlight={inputString} />,
            });
        }
        _.each(pageObj, (subPageObj, subPageKey) => {
          if (subPageKey === "subMenu") {
            _.each(subPageObj, (sectionObj) => {
              if (sectionObj?.subMenu?.length) {
                const foundResultItems = sectionObj.subMenu.filter((item) => {
                  return isContainString(inputString, item.title);
                });
                foundResultItems.map((item) => {
                  displaySidebarItem(item, flags, currentAccount, user) &&
                    resultItems.push({
                      isExternalLink: isExternalSearchURL(item),
                      href: item.route,
                      icon: pageObj.icon,
                      text: (
                        <HighlightedText
                          title={`${pageKey} › ${sectionObj.title} > ${item.title}`}
                          highlight={inputString}
                        />
                      ),
                    });
                });
              } else {
                if (isContainString(inputString, sectionObj.title)) {
                  displaySidebarItem(sectionObj, flags, currentAccount, user) &&
                    resultItems.push({
                      isExternalLink: isExternalSearchURL(sectionObj),
                      href: sectionObj.route,
                      icon: pageObj.icon,
                      text: (
                        <HighlightedText
                          title={`${pageKey} › ${sectionObj.title}`}
                          highlight={inputString}
                        />
                      ),
                    });
                }
              }
              setResult(_.uniqWith(resultItems, _.isEqual));
            });
          }
        });
      });
      return _.uniqWith(resultItems, _.isEqual);
    } else {
      setResult(null);
    }
  };

  // const handleClickNotification = () => {
  //     // code while notification will be clicked
  // }

  const changeLiveMode = () => {
    setSwitchLoader(true);
    dispatch(setDefaultSelectedAsset(null));
    setLiveModeLocal(!liveModeLocal);
    dispatch(setLiveMode(!liveMode))
      .then((res) => {
        setSwitchLoader(false);
        if (!res) {
          setLiveModeLocal(liveModeLocal);
        }
      })
      .catch((_err) => {
        setSwitchLoader(false);
      });
  };

  const commonFlexStyles = {
    flexGrow: 1,
    flexBasis: "0px",
  };

  const isTestMode =
    isLoggedIn &&
    currentRouteDetails &&
    currentRouteDetails.mode &&
    !liveModeLocal;

  const webAppSearchResults = (inputString) => {
    return getWebSearchAPIResults(inputString, null, user?.date_format);
  };

  const clearMemoizedCache = () => {
    getWebSearchAPIResults.cache.clear();
  };

  const { timezone } = getTimeZoneAndDateFormat();

  return (
    <Box
      className={` ${isLoggedIn && !isFromEmail ? "header user-in" : ""} ${
        isTestMode && "test-mode"
      } mui-fixed`}
      sx={{ minHeight: (isLoggedIn || !isFromEmail) && "0 !important" }}
      id="test-data-id"
    >
      {isLoggedIn &&
        !isFromEmail &&
        isTestMode &&
        showTestModeLiveModeSwitchBtn && (
          <Box
            className={`
            ${
              (isClosureRequested || autoPayoutSchedulingEnabled) &&
              "test-mode-closure-box"
            } test-mode-box`}
          >
            {testModeTitle}
          </Box>
        )}
      <CssBaseline />
      {isLoggedIn && !isFromEmail && (
        <Container maxWidth="xl">
          {isClosureRequested && (
            <HelloBar
              id="revoke-hello-bar"
              descriptionText={closureInProgress}
              besideComponent={
                currentAccount?.role?.id === "role_owner" && (
                  <Button
                    className="revoke-btn"
                    label={revoke}
                    onClick={revokeRequest}
                    loading={revokeLoading}
                  />
                )
              }
              startIcon={<img src={personCancelSvg} alt="person-cancel" />}
            />
          )}
          {autoPayoutSchedulingEnabled && (
            <HelloBar
              hide={!autoPayoutScheduling}
              descriptionText={
                <>
                  {autoPayoutSetupMsg}&nbsp;
                  <CustomLink
                    size="small"
                    sx={{ color: "inherit", textDecoration: "underline" }}
                    onClick={() => dispatch(setPayoutModalOpen(true))}
                  >
                    {payoutScheduling}.
                  </CustomLink>
                </>
              }
              besideComponent={
                <CancelIcon
                  className="pointer-cursor"
                  onClick={() => {
                    setAutoPayoutScheduling(false);
                    localStorage.setItem("open_ps_hello_bar", "false");
                  }}
                />
              }
              startIcon={<img src={calenderSvg} alt="calendar" />}
            />
          )}
          <AppBar position="static" id="app-bar">
            <Toolbar>
              {isLoggedIn &&
                !isFromEmail &&
                !requestGrantPaths.includes(history.location.pathname) && (
                  <React.Fragment>
                    {isMobile && (
                      <IconButton onClick={toogleSidebarOpen} edge="start">
                        {(openSidebar && <CloseIcon />) || <MenuIcon />}
                      </IconButton>
                    )}
                    {user && (
                      <>
                        <Box
                          style={{
                            ...commonFlexStyles,
                            display: "flex",
                          }}
                        >
                          <AccountSelection />
                        </Box>
                        {gserBar && (
                          <Box
                            style={{
                              ...commonFlexStyles,
                              marginLeft: "160px",
                            }}
                          >
                            <SearchBar
                              searchTipsUrl={`${process.env.REACT_APP_SPEED_DEV_URL}docs/business-operations/search-tips`}
                              liveMode={liveMode}
                              user={user}
                              currentAccount={currentAccount}
                              timezone={timezone}
                              resultData={result}
                              onChangeInput={inputChange}
                              component={Link}
                              setResultData={(resultData) =>
                                dispatch(setWebPageSearchResults(resultData))
                              }
                              webAppSearchResults={(inputString) =>
                                webAppSearchResults(inputString)
                              }
                              clearMemoizedCache={clearMemoizedCache}
                              ldFlags={flags}
                            />
                          </Box>
                        )}
                        <Box
                          style={{
                            ...commonFlexStyles,
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {showTestModeLiveModeSwitchBtn && (
                            <FormGroup
                              style={{
                                justifyContent: "center",
                                marginRight: "25px",
                              }}
                            >
                              <FormControlLabel
                                className="switch-label header-switch"
                                labelPlacement="start"
                                control={
                                  <CustomSwitch
                                    checked={!liveModeLocal}
                                    onChange={changeLiveMode}
                                    disabled={switchLoader}
                                  />
                                }
                                label="Test Mode"
                              />
                            </FormGroup>
                          )}
                          <>
                            <Box className="support-center-box-wrapper">
                              <ContactSupportIcon
                                onClick={() => setShowSupportCenterDrawer(true)}
                              />
                            </Box>
                            <SupportCenterDrawer
                              openSupportCenterDrawer={showSupportCenterDrawer}
                              closeSupportCenterDrawer={() =>
                                setShowSupportCenterDrawer(false)
                              }
                            />
                          </>
                          {/* <CustomBadge
                        content={1}
                        icon={<NotificationsIcon />}
                        onClick={handleClickNotification}
                      /> */}
                          <HeaderUserProfile
                            profile={true}
                            user={user}
                            isLoading={isLoading}
                            logOut={() => {
                              setLoading(isLoggedIn);
                              dispatch(logOut(setLoading)).then(() =>
                                setLoading(false)
                              );
                            }}
                          />
                        </Box>
                      </>
                    )}
                  </React.Fragment>
                )}
            </Toolbar>
          </AppBar>
        </Container>
      )}
      <Modal
        body={closureInProgressBody}
        open={closureRequestFlag}
        className="closure-modal"
      />
      {payoutModalOpen && (
        <PayoutWalletSetupDialog
          isModalOpen={payoutModalOpen}
          handleClose={() => {
            const previousPath = JSON.parse(
              localStorage.getItem("back_to_menu")
            );
            history.push(previousPath?.menuUrl);
            dispatch(setPayoutModalOpen(false));
          }}
        />
      )}
    </Box>
  );
};

export default Header;
