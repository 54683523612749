import { Box, TableCell, TableRow } from "@mui/material";
import { showAmount } from "@speed/common/src/components/constants";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  noOfRecords,
} from "../constants";
import { emptyTransactionSubText } from "../messages";
import { emptySubText } from "@speed/common/src/components/messages";
import { emptyTransaction } from "../images";
import CustomTable from "@speed/common/src/components/Table/Table";

const TransactionTable = ({ targetCurrency }) => {
  const columns = [
    { title: "Type", paddingLeft: "38px !important", width: 167 },
    { title: `Net (${targetCurrency})`, align: "right", width: 113 },
    { title: `Amount  (${targetCurrency})`, align: "right", width: 144 },
    { title: `Fee  (${targetCurrency})`, align: "right", width: 105 },
    { title: "Description", width: 229 },
    { title: "Date", width: 100 },
  ];

  const queryParam =
    "?limit=" +
    noOfRecords +
    (targetCurrency === "USDT" ? "&target_currency=" + targetCurrency : "");

  const [tableRowSkeleton, setTableRowSkeleton] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [rows, setRows] = useState([]);
  const [queryParams, setQueryParams] = useState(queryParam);

  const liveMode = useSelector((state) => state.auth.liveMode);

  useEffect(() => {
    setRows([]);
    setHasMore(true);
    getTransactionsList([], queryParam);
  }, [targetCurrency, liveMode]);

  const getTransactionsList = (lines, params) => {
    setTableRowSkeleton(true);
    callAPIInterface("GET", `/balance-transactions${params}`)
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1].id
            );
          }
          setRows(lines.concat(res.data));
        }
      })
      .catch((_err) => {
        setTableRowSkeleton(false);
      });
  };

  const columnsData = columns.map((column, index) => (
    <TableCell
      key={index}
      align={column.align}
      sx={{ width: column.width, paddingLeft: column.paddingLeft }}
    >
      {column.title}
    </TableCell>
  ));

  let rowsData = rows?.map((rowItem, index) => {
    const rowCurrency = rowItem.target_currency;
    return (
      <TableRow key={index}>
        <TableCell sx={{ paddingLeft: "38px !important" }}>
          {rowItem?.type}
        </TableCell>
        <TableCell style={{ textAlign: "right" }}>
          {showAmount({
            amount: rowItem?.net,
            currency: rowCurrency,
            targetedCurrency: targetCurrency,
          })}
        </TableCell>
        <TableCell style={{ textAlign: "right" }}>
          {showAmount({
            amount: rowItem?.amount,
            currency: rowCurrency,
            targetedCurrency: targetCurrency,
          })}
        </TableCell>
        <TableCell style={{ textAlign: "right" }}>
          {showAmount({
            amount: rowItem?.fee,
            currency: rowCurrency,
            targetedCurrency: targetCurrency,
          })}
        </TableCell>
        <TableCell style={{ textAlign: "left" }}>{rowItem?.source}</TableCell>
        <TableCell>{dateTimeFormatInApp(rowItem?.created)}</TableCell>
      </TableRow>
    );
  });

  const loadMore = useCallback(() => {
    getTransactionsList(rows, queryParams);
  }, [rows]);

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
    textOnNoData: emptySubText(liveMode, "transactions"),
    subTextOnNoData: emptyTransactionSubText,
    noDataImage: emptyTransaction,
  };

  return (
    <Box className="payment-links-wrapper main-content">
      <CustomTable {...tableProps} />
    </Box>
  );
};

export default TransactionTable;
