import React, { useCallback, useEffect, useState } from "react";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  noOfRecords,
} from "../../constants";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { emptySubText } from "@speed/common/src/components/messages";
import CustomTable from "@speed/common/src/components/Table/Table";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TableCell,
  TableRow,
} from "@mui/material";
import {
  setConfirmAction,
  setExecuteAPICall,
  setIsDataAvailable,
  setLoading,
} from "../../../redux/common/actions";
import { useDispatch, useSelector } from "react-redux";
import { emptyConnectedExtSubText, removeAccount } from "../../messages";
import { connectImg } from "../../images";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import RemoveAccountModal from "../RemoveAccountModal";
import { useFlags } from "launchdarkly-react-client-sdk";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";

const ConnectedAccountTable = () => {
  const { caRemoveplatformaccFe } = useFlags();

  const dispatch = useDispatch();
  const columns = [
    {
      title: "Account ID",
      width: "280px",
      align: "left",
      paddingLeft: "38px !important",
    },
    { title: "Owner Name", width: "171px" },
    { title: "Owner Email", width: "211px" },
    { title: "Type", width: "150px", align: "left" },
    { title: "Created On", width: "230px", align: "left" },
  ];
  caRemoveplatformaccFe &&
    columns.push({ title: "Actions", width: "25px", align: "center" });

  const queryParam = "?limit=" + noOfRecords;

  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const openPopper = Boolean(anchorEl);
  let actions = [];

  const liveMode = useSelector((state) => state.auth.liveMode);
  const { executeAPICall, history, isDataAvailable, caughtErrorInStrapi } =
    useSelector((state) => state.common);

  const getConnectedAccountList = (lines, params) => {
    setTableRowSkeleton(true);
    callAPIInterface("GET", "/connect" + params, {})
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1]?.id
            );
          }
          setRows(lines.concat(res.data));
          dispatch(setIsDataAvailable(res.data.length > 0));
        }
      })
      .catch((_err) => {
        setTableRowSkeleton(false);
      });
  };

  const loadInitialData = () => {
    setRows([]);
    setHasMore(true);
    getConnectedAccountList([], queryParam);
  };
  useEffect(() => {
    loadInitialData();
  }, [liveMode]);

  const loadMore = useCallback(() => {
    getConnectedAccountList(rows, queryParams);
  }, [rows]);

  const handleClick = (event, rowData) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setCurrentRow(rowData);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick(e, currentRow);
    }
  };

  useEffect(() => {
    if (executeAPICall) {
      dispatch(setLoading(false));
      dispatch(setConfirmAction(false));
      dispatch(setExecuteAPICall(false));
    }
  }, [executeAPICall]);

  let columnsData = columns.map((column, index) => (
    <TableCell
      key={index}
      align={column.align}
      sx={{ width: column.width, paddingLeft: column.paddingLeft }}
    >
      {column.title}
    </TableCell>
  ));

  let rowsData =
    rows.length > 0 &&
    rows.map((rowItem, index) => {
      return (
        <TableRow
          key={index}
          className="pointer-cursor"
          onClick={() => history.push(`/connect/${rowItem.id}`)}
        >
          <TableCell sx={{ paddingLeft: "38px !important" }}>
            {rowItem.account_id}
          </TableCell>
          <TableCell sx={{ wordBreak: "break-word" }}>
            <TruncatedTextTooltip
              textValue={rowItem?.account_name}
              cellWidth="200px"
            />
          </TableCell>
          <TableCell sx={{ wordBreak: "break-word" }}>
            {rowItem.owner_email}
          </TableCell>
          <TableCell className="text-capitalize">{rowItem.type}</TableCell>
          <TableCell>{dateTimeFormatInApp(rowItem.created)}</TableCell>
          {caRemoveplatformaccFe && (
            <TableCell style={{ textAlign: "center" }}>
              <MoreHorizIcon
                className="horizontal-dots-icon"
                tabIndex={0}
                onKeyDown={handleKeyDown}
                onClick={(event) => {
                  event.stopPropagation();
                  handleClick(event, rowItem);
                }}
              />
            </TableCell>
          )}
        </TableRow>
      );
    });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
    textOnNoData: emptySubText(liveMode, "Connected account"),
    subTextOnNoData: emptyConnectedExtSubText,
    noDataImage: connectImg,
  };

  const renderAction = () => {
    const actionsObj = {
      remove_account: {
        title: removeAccount,
        action: () => setOpenRemoveModal(true),
      },
    };
    actions.push(actionsObj.remove_account);

    return actions.map((action, index) => {
      return (
        <ListItem disablePadding key={index}>
          <ListItemButton onClick={action.action}>
            <ListItemText
              primary={action.title}
              className="text-danger"
              sx={{ padding: "0px 10px !important" }}
            />
          </ListItemButton>
        </ListItem>
      );
    });
  };

  return (
    <Box className="connected-extensions-box">
      <Box className="main-content">
        {(isDataAvailable || caughtErrorInStrapi) && (
          <CustomTable {...tableProps} />
        )}
      </Box>
      <CustomPopper
        disablePortal={true}
        open={openPopper}
        anchorEl={anchorEl}
        position="bottom"
        handleClose={() => setAnchorEl(null)}
      >
        <List>{renderAction()}</List>
      </CustomPopper>
      <RemoveAccountModal
        apiPath="/connect"
        callBackFn={() => {
          loadInitialData();
          setOpenRemoveModal(false);
        }}
        handleClose={() => setOpenRemoveModal(false)}
        idToRemove={currentRow?.id}
        open={openRemoveModal}
      />
    </Box>
  );
};

export default ConnectedAccountTable;
