import React, { useEffect, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { infoIcon } from "../../images";
import {
  amountLabel,
  gasFeeMsg,
  gasFees,
  lightningNetworkFeeInfo,
  maxAmount,
  minAmount,
  networkFeeHighTip,
  networkFeeInfo,
  networkFees,
  routingFeeInfo,
  routingFeeText,
  sats,
  speedFee,
  totalAmountToSend,
  usdt,
} from "../../messages";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import { useSelector } from "react-redux";
import {
  displayAmount,
  displayTaprootCurrency,
  displaySelectedCurrencyAmount,
} from "../../constants";

const SendTotalAmount = ({
  amount,
  networkFeeAmount,
  switchCurrency,
  isOnchainMethod,
  exchangeRate,
  assetBalance,
  isNetworkFeeLoading,
  isReceiveWithdrawReq,
  lnUrlMinAmount,
  lnUrlMaxAmount,
  speedFees,
  calculatedSpeedFee,
  isEthereumMethod,
  decodedCurrency,
}) => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [messageOnInfoIcon, setMessageOnInfoIcon] = useState("");
  const open = Boolean(anchorEl);
  const walletDefaultCurrency = JSON.parse(
    localStorage.getItem("wallet_default_currency")
  );
  const fiatCode = walletDefaultCurrency?.code;
  const fiatSymbol = walletDefaultCurrency?.symbol;
  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);
  const toFixedDecimal = showBtcAmount ? 8 : 2;

  useEffect(() => {
    handleCalculateTotalAmount();
  }, [amount, networkFeeAmount, speedFees]);

  const handleClick = (event, type) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    switch (type) {
      case "gas":
        setMessageOnInfoIcon(gasFeeMsg);
        break;
      case "network":
        setMessageOnInfoIcon(networkFeeInfo);
        break;
      case "speed":
        setMessageOnInfoIcon(lightningNetworkFeeInfo);
        break;
      default:
        setMessageOnInfoIcon(routingFeeInfo);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCalculateTotalAmount = () => {
    const finalAmount =
      Number(amount) + Number(calculatedSpeedFee) + Number(networkFeeAmount);
    setTotalAmount(
      decodedCurrency === sats
        ? finalAmount.toFixed(toFixedDecimal)
        : finalAmount.toFixed(2)
    );
  };

  const handleVisibleTotalAmountSection = () => {
    let isVisible = false;
    if (isOnchainMethod && decodedCurrency === usdt) {
      isVisible = Number(amount) > 0 && Number(amount) < Number(assetBalance);
    } else if (isOnchainMethod) {
      const minRequiredAmount = displaySelectedCurrencyAmount(
        1000,
        showBtcAmount
      );
      isVisible =
        Number(amount) >= minRequiredAmount &&
        Number(amount) < Number(assetBalance);
    } else {
      isVisible = Number(amount) < Number(assetBalance) && amount > 0;
    }

    return isVisible;
  };

  const isVisibleTotalAmountSection = handleVisibleTotalAmountSection();
  const isShowLnurlMinMaxAmount =
    lnUrlMaxAmount !== lnUrlMinAmount && isReceiveWithdrawReq;

  const renderAnyAmountType = (label, amt, imgType = "") => {
    return (
      <Box display="flex">
        <Text
          size={16}
          font="regular"
          style={{ display: "flex", alignItems: "center" }}
          className="grey-text"
        >
          {label}
          {imgType && (
            <img
              onClick={(e) => handleClick(e, imgType)}
              src={infoIcon}
              alt="info-icon"
              style={{ marginLeft: "4px", cursor: "pointer" }}
            />
          )}
        </Text>
        {isNetworkFeeLoading ? (
          <Skeleton width={100} height={20} />
        ) : (
          <Text size={16} font="semibold" className="default-text">
            {imgType ? "+" : ""}
            {switchCurrency && fiatSymbol}
            {displayAmount(
              Number(amt),
              exchangeRate,
              fiatCode,
              decodedCurrency,
              switchCurrency,
              showBtcAmount
            )}{" "}
            {!switchCurrency &&
              displayTaprootCurrency(amt, decodedCurrency, showBtcAmount)}
          </Text>
        )}
      </Box>
    );
  };

  const lnurlAmountShowJsx = (text, amt) => {
    return (
      <Box display="flex">
        <Text
          size={16}
          font="regular"
          style={{ display: "flex", alignItems: "center" }}
          className="grey-text"
        >
          {text}
        </Text>
        {isNetworkFeeLoading ? (
          <Skeleton width={100} height={20} />
        ) : (
          <Text size={16} font="semibold" className="default-text">
            {switchCurrency && fiatSymbol}
            {displayAmount(
              Number(amt),
              exchangeRate,
              fiatCode,
              decodedCurrency,
              switchCurrency,
              showBtcAmount
            )}{" "}
            {!switchCurrency &&
              displayTaprootCurrency(amt, decodedCurrency, showBtcAmount)}
          </Text>
        )}
      </Box>
    );
  };

  return isShowLnurlMinMaxAmount ? (
    <Box className="send-total-amount-section">
      {lnurlAmountShowJsx(minAmount, lnUrlMinAmount)}
      <CustomDivider />
      {lnurlAmountShowJsx(maxAmount, lnUrlMaxAmount)}
    </Box>
  ) : (
    isVisibleTotalAmountSection && !isReceiveWithdrawReq && (
      <Box className="send-total-amount-section">
        {renderAnyAmountType(amountLabel, amount)}
        <CustomDivider />
        {renderAnyAmountType(speedFee, calculatedSpeedFee, "speed")}
        <CustomDivider />
        {!isOnchainMethod && !isEthereumMethod && (
          <Box display="flex">
            <Text
              size={16}
              font="regular"
              style={{ display: "flex", alignItems: "center" }}
              className="grey-text"
            >
              {routingFeeText}
              <img
                onClick={(e) => handleClick(e, "routing")}
                src={infoIcon}
                alt="info-icon"
                style={{ marginLeft: "4px", cursor: "pointer" }}
              />
            </Text>
            {isNetworkFeeLoading ? (
              <Skeleton width={100} height={20} />
            ) : (
              <Text size={16} font="semibold" className="default-text">
                {`+${switchCurrency ? fiatSymbol : ""}0 ${
                  !switchCurrency
                    ? displayTaprootCurrency(
                        networkFeeAmount,
                        decodedCurrency,
                        showBtcAmount
                      )
                    : ""
                } to ${switchCurrency ? fiatSymbol : ""}${displayAmount(
                  Number(networkFeeAmount),
                  exchangeRate,
                  fiatCode,
                  decodedCurrency,
                  switchCurrency,
                  showBtcAmount
                )}`}{" "}
                {!switchCurrency &&
                  displayTaprootCurrency(
                    networkFeeAmount,
                    decodedCurrency,
                    showBtcAmount
                  )}
              </Text>
            )}
          </Box>
        )}
        {isEthereumMethod && (
          <>
            {renderAnyAmountType(gasFees, networkFeeAmount, "gas")}
            <CustomDivider />
          </>
        )}
        {isOnchainMethod && (
          <>
            {renderAnyAmountType(networkFees, networkFeeAmount, "network")}
            {decodedCurrency !== usdt && (
              <Text
                size={14}
                font="regular"
                variant="h6"
                sx={{
                  color: "#D88A2C",
                  paddingLeft: "10px",
                  paddingBottom: "12px",
                }}
              >
                {networkFeeHighTip}
              </Text>
            )}
            <CustomDivider />
          </>
        )}
        {(isOnchainMethod || isEthereumMethod) &&
          renderAnyAmountType(totalAmountToSend, totalAmount)}
        <CustomPopper
          id="info-msg"
          disablePortal={true}
          open={open}
          anchorEl={anchorEl}
          position="top"
          handleClose={handleClose}
          sx={{ width: "332px", padding: "0px 10px" }}
        >
          <Text variant="inherit" font="regular" size={14}>
            {messageOnInfoIcon}
          </Text>
        </CustomPopper>
      </Box>
    )
  );
};

export default SendTotalAmount;
