import { Box } from "@mui/system";
import PageLoader from "@speed/common/src/components/PageLoader";
import Text from "@speed/common/src/components/Text/Text";
import VideoPlayer from "@speed/common/src/components/VideoPlayer";
import React, { useState } from "react";
import PreviewHeader from "../../Common/PreviewHeader";
import { goToYourText } from "../../messages";

const VideoSection = (props) => {
  const [isLoading, setLoading] = useState(true);
  return (
    <Box className="inner-grid inner-grid-width">
      <Box sx={{ display: "flex", m: "50px 0 30px 80px" }}>
        <Text size={20} className="grey-text">
          {goToYourText}
        </Text>
        <Text
          style={{ marginLeft: "8px" }}
          size={20}
          className="dark-indigo-text"
        >
          {props.headerText}
        </Text>
      </Box>
      <Box
        sx={{
          m: "31px 105px 0 80px",
          width: "768px",
          height: "478px",
          position: "relative",
        }}
      >
        <PreviewHeader from="web" hidePreviewUrl={true} />
        {isLoading && (
          <PageLoader
            open={isLoading}
            alt="Loading..."
            customClass="page-loader preview-loader"
            sx={{ position: "absolute", margin: "auto" }}
          />
        )}
        <VideoPlayer
          width="100%"
          height="100%"
          url="https://www.youtube.com/watch?v=Gc2en3nHxA4"
          setLoading={setLoading}
        />
      </Box>
    </Box>
  );
};

export default VideoSection;
