import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import {
  RouteBreadcrumbs,
  callAPIInterface,
  dateTimeFormatInApp,
  renderStatus,
  returnQRCodeMethod,
} from "../constants";
import {
  clipboardElement,
  generateBreadcrumbs,
  linkStatus,
  redirectWhenEntityNotFound,
  satsToBtcAmount,
} from "@speed/common/src/components/constants";
import { Grid, TableCell, TableRow } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import {
  details,
  entityNotFoundMessage,
  latestPayment,
  viewLabel,
} from "../messages";
import { useSelector } from "react-redux";
import WarningIcon from "@mui/icons-material/Warning";
import Button from "@speed/common/src/components/Button/Button";
import "../../assets/styles/oneQR.scss";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import { useFlags } from "launchdarkly-react-client-sdk";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import history from "@speed/common/src/components/history";
import ViewQRModal from "./ViewQRModal";
import DetailPagePaymentSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import LinkSkeleton from "@speed/common/src/components/Skeleton/LinkSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";

const OneQRDetail = (props) => {
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const liveMode = useSelector((state) => state.auth.liveMode);
  const { oqrFetchoneqr, oqrViewoneqr } = useFlags();

  const [data, setData] = useState(null);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [lastPaymentList, setLastPaymentList] = useState([]);
  const [paymentTableSkeleton, setPaymentTableSkeleton] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [latestPaymentId, setLatestPaymentId] = useState(null);
  const [viewQR, setViewQR] = useState(false);

  const urlParams = useParams();

  useEffect(() => {
    setData(null);
    setIsShowNotFound(false);
    getOneQRDetail();
  }, [liveMode]);

  const getLastPaymentList = async (id) => {
    setPaymentTableSkeleton(true);
    await callAPIInterface("GET", `/oneqrs/${id}/payments`)
      .then((res) => {
        if (res) {
          setLastPaymentList(res?.data);
          setPaymentTableSkeleton(false);
        }
      })
      .catch(() => {
        setPaymentTableSkeleton(false);
      });
  };

  const getOneQRDetail = async () => {
    setShowSkeleton(true);
    await callAPIInterface("GET", `/oneqrs/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setData(res);
        }
        getLastPaymentList(res?.id);
        setLatestPaymentId(res?.id);
        setShowSkeleton(false);
      })
      .catch((err) => {
        setShowSkeleton(false);
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const lastPaymentColumnData = [
    { title: "Payment ID", width: "288px" },
    { title: "Amount (BTC)", width: "200px", align: "right" },
    { title: "Date", width: "249px" },
    { title: "Status", width: "84px" },
  ];

  const rows =
    lastPaymentList &&
    lastPaymentList.map((rowItem) => {
      return (
        <TableRow
          key={rowItem.id}
          className="clickable"
          onClick={() => history.push(`/payments/${rowItem?.id}`)}
        >
          <TableCell>{rowItem.id}</TableCell>
          <TableCell align="right">
            {satsToBtcAmount(
              rowItem.status === "paid"
                ? rowItem.target_amount_paid
                : rowItem.target_amount
            )}
          </TableCell>
          <TableCell align="left">
            {dateTimeFormatInApp(rowItem?.created)}
          </TableCell>
          <TableCell align="left">{renderStatus(rowItem?.status)}</TableCell>
        </TableRow>
      );
    });

  const detailsTableData = () => {
    return [
      {
        header: "One QR type",
        cell: returnQRCodeMethod.find(
          (item) => item.method === data?.oneqr_method
        )?.label,
      },
      {
        header: "Status",
        cell: (
          <Tag
            text={linkStatus[data?.status]?.label}
            variant={linkStatus[data?.status]?.variant}
          />
        ),
      },
      {
        header: "Date created",
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {dateTimeFormatInApp(data?.created)}
          </Text>
        ),
      },
    ];
  };

  const handleView = () => {
    setViewQR(true);
  };

  const headerContent = () => {
    return (
      <Box className="header-content">
        {oqrViewoneqr && (
          <Box
            className="header-download-btn"
            sx={{ position: "absolute", right: "2px", top: "23px" }}
          >
            <Button
              icon="visibilityIcon"
              className="download-icon"
              label={viewLabel}
              variant="outlined"
              color="primary"
              loading={downloadLoader}
              onClick={() => handleView()}
            />
          </Box>
        )}
        <Text className="btc-amount" variant="h1" size={24}>
          {data?.name}
        </Text>
        <Box className="header-detail-content">
          <Text
            className="grey-text"
            size={16}
            font="regular"
            variant="subtitle"
          >
            {data?.description}
          </Text>
        </Box>

        <Box sx={{ marginTop: "15px" }}>
          {clipboardElement(data?.id, true, "oneQR-detail", "text", true)}
        </Box>
      </Box>
    );
  };

  const viewAllPayments = () => {
    history.push(`/one-qr/${latestPaymentId}/payments`);
  };

  return !isShowNotFound ? (
    <Grid className="one-qr-detail-wrapper" container spacing={1}>
      <Box className="section-wrapper" sx={{ position: "relative" }}>
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        {data ? (
          <>
            {oqrFetchoneqr ? (
              <>
                {headerContent()}

                <Box className="one-qr-details-content">
                  <VerticalTable label={details} rowData={detailsTableData()} />
                </Box>

                <Box className="last-payment-section">
                  {!paymentTableSkeleton ? (
                    <HorizontalTable
                      label={latestPayment}
                      isColumnShown={lastPaymentList.length > 0 ? true : false}
                      isShowButton={rows.length > 3}
                      columns={lastPaymentColumnData}
                      rows={rows.slice(0, 3)}
                      rowsPerPage={10}
                      isShowPagination={false}
                      handleViewAllClick={() => viewAllPayments()}
                      tableBodyClassName="border-none"
                    />
                  ) : (
                    <DetailPagePaymentSkeleton />
                  )}
                </Box>
              </>
            ) : (
              <AccessDenied className="access-denied-one-qr" />
            )}
          </>
        ) : (
          showSkeleton && <LinkSkeleton from="oneQR" />
        )}
      </Box>
      <ViewQRModal
        data={data}
        handleClose={() => setViewQR(false)}
        viewQR={viewQR}
        className="view-qr-modal"
      />
    </Grid>
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("OneQR")}
        subTitle={`OneQR ${urlParams.id} is not found.`}
        btnText={`View ${(liveMode && "live") || "test"} mode OneQR`}
      />
    </Box>
  );
};

export default OneQRDetail;
