import React from "react";
import GetGuides from "./GetGuides";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Box } from "@mui/material";

const WithdrawalLinkGuide = () => {
  const { obWithdrawallinkguideFe } = useFlags();

  return obWithdrawallinkguideFe ? (
    <GetGuides
      moduleName="withdrawal_link"
      redirectUrl="/withdrawal-links"
      guideApiPath="withdrawal-link-guide"
      getStartedApiPath="Withdrawal"
      needHelpGuidePath=""
    />
  ) : (
    <Box height="70vh" display="flex" alignItems="center">
      <AccessDenied />
    </Box>
  );
};

export default WithdrawalLinkGuide;
