import AccessTimeFilledOutlinedIcon from "@mui/icons-material/AccessTimeFilledOutlined";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import LaptopIcon from "@mui/icons-material/Laptop";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import {
  Box,
  Grid,
  MenuItem,
  Skeleton,
  TableCell,
  TableRow,
} from "@mui/material";
import { captureException } from "@sentry/react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { validate as btcValidate } from "bitcoin-address-validation";
import classNames from "classnames";
import { AES, enc } from "crypto-js";
import { saveAs } from "file-saver";
import { getAuth, signOut } from "firebase/auth";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import _ from "lodash";
import moment from "moment-timezone";
import { validate } from "postal-codes-js";
import { Cookies } from "react-cookie";
import { isEmail } from "react-multi-email";
import { isValidPhoneNumber } from "react-phone-number-input/input";
import { sessionService } from "redux-react-session";
import { isURL } from "validator";
import * as yup from "yup";

import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import history from "@speed/common/src/components/history";
import {
  speedVersion,
  linkStatus,
  satsToBtcAmount,
  webHookStatus,
  dummyPaymentID,
  dummyLightningAddress,
  dummyOnchainAddress,
  convertExponentialToDecimal,
  commonDateFormatLogic,
  getTimezoneMomentObj,
  defaultDateFormat,
  isAlphaNumeric,
  redirectWhenEntityNotFound,
  fetchRawBalanceData,
  validAddress,
  isValidLnUrlFormatRegex,
  checkCurrentAndExpireTime,
  removeTrailingZeros,
  btcToSatsAmount,
  fetchPrecisedInteger,
  getNumberFormatAccordingToCurrency,
  regexToTruncateZeros,
  getDisplayAmount,
} from "@speed/common/src/components/constants";
import { app } from "@speed/common/src/util/firebase";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { Input } from "@speed/common/src/components/Input/Input";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import NoDataAvailable from "@speed/common/src/components/NoDataAvailable";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import Text from "@speed/common/src/components/Text/Text";
import { CustomTooltip } from "@speed/common/src/components/Tooltip/Tooltip";
import { whiteLogo } from "@speed/common/src/components/images";
import {
  Merchant,
  Wallet,
  errorMessage,
  lightningLabel,
  next,
  onChainLabel,
  somethingWrong,
} from "@speed/common/src/components/messages";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import WarningIcon from "@mui/icons-material/Warning";
import { CustomAvatar } from "@speed/common/src/components/Avatar/Avatar";
import Button from "@speed/common/src/components/Button/Button";
import { ColorPicker } from "@speed/common/src/components/ColorPicker/ColorPicker";
import CustomSelect from "@speed/common/src/components/Select/Select";
import UploadFile from "@speed/common/src/components/UploadFile";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import {
  alphaNumName,
  authenticate,
  letter,
  letterAndNumber,
  min8Char,
  oneNumber,
} from "@speed/common/src/messages";
import { generateToken, getUser, setIsLoggedIn } from "../redux/auth/actions";
import { RESET_STORE } from "../redux/auth/types";
import {
  setAccountRestrictedModalData,
  setConfirmAction,
  setPathname,
  setPayoutModalOpen,
  setPayoutSchedulingEnabled,
  setVerifyOTP,
  showToast,
} from "../redux/common/actions";
import { store } from "../redux/store";
import {
  clearIcon,
  icLighting,
  icOnchain,
  linkNotFoundImg,
  shopifyIcon,
  wooCommerceIcon,
} from "./images";
import jsonDataToShow from "./jsonLogic";
import {
  add,
  addWallet,
  agreement,
  alreadyVerified,
  cancel,
  checkoutPaymentLinkTabLabel,
  confirmBtn,
  customerChoose,
  decimalLimitError,
  emailSent,
  emailSentToastMsg,
  emailVerifictionLinkSent,
  emptyKey,
  enterCodeMsg,
  fail,
  failed,
  fiatCurrency,
  fontsLabel,
  googleAuthenticator,
  httpStatusCode,
  installed,
  invalidWalletAddress,
  invoice,
  linkAlreadyVerifiedSubMsg,
  linkNotFoundDesc,
  linkNotFoundTitle,
  lowerValidate,
  minBalanceGreaterError,
  minimumBalanceLessThanErr,
  minimumPayoutAmountGreaterErrorMsg,
  minimumPayoutAmountLessThanErr,
  numberValidate,
  onchain,
  oneQR,
  partnerInvitationExpired,
  payment,
  payoutWalletAlreadyVerified,
  publicInfoUrlErrorMsg,
  removePayoutMsg,
  satsValidationMsg,
  scanQRMsg,
  setup2faSubMsgMobile,
  setup2faSubMsgQR,
  status,
  succeeded,
  success,
  termsAndPrivacyMsg,
  time,
  timezoneLabel,
  timezonePlaceholder,
  unconfirmed,
  unconfirmedPaymentDescription,
  upperValidate,
  verificationSuccessful,
  verificationSuccessfulMsg,
  withdraw,
  withdrawalLinkTabLabel,
  yetToBeInstalled,
  sats,
  ethereum,
  lightning,
  lnurl,
  testnetErrorMsg,
  mainnetErrorMSg,
  invoiceExpired,
  eth,
  usdt,
  btc,
} from "./messages";
import { Timezones } from "./timezone";

const allowedMethods = ["GET", "POST", "DELETE", "PUT"];
const openAPIEndPoints = [
  "/sso",
  "/login",
  "/signup",
  "/signup-invite",
  "/signup-affiliate-partner",
  "/verify-email",
  "/resend-verification-code",
  "/verify-user",
  "/verify-forgot-password",
  "/forgot-password",
  "/generate-token",
  "/verify-change-email",
];
const onlyAuthTokenAPIEndpoints = [
  "/login/mfa/phone-number/verify-otp",
  "/login/mfa/phone-number/resend-otp",
  "/login/mfa/phone-number",
  "/login/mfa/phone-number/verify",
  "/login/mfa/phone-number/resend-verification-code",
];
const dynamicGetApiEndPoints = [
  "/invite-users/",
  "/link/",
  "/affiliate-partner/invite-link",
];
const excludedWalletAPIEndpoints = ["/account"];

const getHeaders = async (method, path, data, file, fileName) => {
  const authStore = store.getState().auth;
  const currentAccountType = authStore?.currentAccount?.account?.account_type;

  const host =
    currentAccountType === Wallet && !excludedWalletAPIEndpoints.includes(path)
      ? process.env.REACT_APP_WALLET_API_ENDPOINT
      : process.env.REACT_APP_API_ENDPOINT;
  let jwtOptions = {
    method,
    url: `${host}${path}`,
  };

  const formData = file ? new FormData() : null;
  if (file) {
    formData.append("file", file, fileName);
  }

  if (data || allowedMethods.includes(method)) {
    const speedUser = data?.speedUser;
    if (speedUser) {
      delete data.speedUser;
    }

    jwtOptions.headers = jwtOptions.headers || {};
    jwtOptions.headers["Content-Type"] = file
      ? "multipart/form-data"
      : "application/json";
    jwtOptions.headers["Accept"] = "application/json";
    jwtOptions.headers["speed-source"] = "c6e190b284633c48e39e55049da3cce8";
    if (data?.turnstileToken) {
      jwtOptions.headers["speed-cf-ts-token"] = data.turnstileToken;
      delete data.turnstileToken;
    }
    jwtOptions.data = file ? formData : data;

    if (path === "/settings/documentation/auth-token" || speedUser) {
      const loggedInUser = authStore?.user;
      if (loggedInUser) {
        jwtOptions.headers["speed-user"] = loggedInUser?.id;
      }
    }

    const isEndPointIncluded = (endPointsList, endPoint) =>
      endPointsList.includes(endPoint);

    const isOpenAPI =
      isEndPointIncluded(openAPIEndPoints, path) ||
      (method === "GET" &&
        dynamicGetApiEndPoints.some((endpoint) => path.includes(endpoint)));

    const isOnlyAuthRequired = isEndPointIncluded(
      onlyAuthTokenAPIEndpoints,
      path
    );

    if (!isOpenAPI) {
      await sessionService
        .loadSession()
        .then(async (session) => {
          if (session.access_token) {
            jwtOptions.headers["Access"] = session.access_token;
            jwtOptions.headers["speed-version"] = speedVersion;
          }
          if (session.id_token) {
            jwtOptions.headers["Authorization"] = session.id_token;
          }

          if (!isOnlyAuthRequired) {
            jwtOptions.headers["speed-livemode"] =
              session.liveMode !== undefined ? session.liveMode : false;

            if (session.speed_acc_id) {
              jwtOptions.headers["speed-account"] = session.speed_acc_id;
            }
          }
        })
        .catch((_err) => {
          jwtOptions = {};
          expireSession();
        });
    }
  }
  return jwtOptions;
};

export const serverErrorStatusCodes = [500, 502, 503, 504, 417];
export const errorStatusCodes = [400, 401, 402, 403, 404, 408, 415, 428, 429];
export const checkValidationPaths = ["checkout-links", "payments"];
export const publicInfoUrlErrorParams = [
  "business_url",
  "support_url",
  "privacy_policy_url",
  "terms_of_service_url",
];

const redirectAfterError = (err, reject) => {
  captureException(err);
  store.getState().session.authenticated
    ? expireSession()
    : history.push("/login");
  store.dispatch(
    showToast({
      isToastOpen: true,
      toastMessage: somethingWrong,
      toastVariant: "error",
    })
  );
  reject(503);
};

export const getEventId = (eventType, data) => {
  switch (eventType) {
    case "checkout_session.payment_paid":
      return data?.data?.object?.checkout_session_id;
    default:
      return data?.data?.object?.id;
  }
};

export const paymentMethodStatus = {
  lightning: {
    imageURL: icLighting,
    label: "BTC Lightning",
    description: "Transactions conducted on the Lightning Network are faster",
  },
  onchain: {
    imageURL: icOnchain,
    label: "BTC On-chain",
    description: "Transactions that occur on the blockchain",
    showTolerence: true,
  },
};

export const withdrawStatus = {
  paid: {
    label: "Paid",
    variant: "success",
    value: "paid",
    icon: <CheckCircleIcon className="status-icon" />,
  },
  expired: {
    label: "Expired",
    variant: "default",
    value: "expired",
    tooltip: "The withdraw expired because the ttl expired.",
    icon: <AccessTimeFilledOutlinedIcon className="status-icon" />,
  },
  unpaid: {
    label: "Unpaid",
    variant: "warning",
    value: "unpaid",
    tooltip: "The withdraw is still pending.",
    icon: <ErrorOutlinedIcon className="status-icon" />,
  },
  in_progress: {
    label: "Unpaid",
    variant: "warning",
    value: "unpaid",
    tooltip: "The withdraw is still pending.",
    icon: <ErrorOutlinedIcon className="status-icon" />,
  },
  cancelled: {
    label: "Cancelled",
    variant: "error-primary",
    value: "cancelled",
    tooltip: "The withdraw has been failed.",
    icon: <CancelIcon className="status-icon" />,
  },
  failed: {
    label: "Failed",
    variant: "error-primary",
    value: "failed",
    tooltip: "The withdraw has been failed.",
    icon: <CancelIcon className="status-icon" />,
  },
};
export const callStrapiAPIInterface = (method, path) => {
  return new Promise((resolve, reject) => {
    const host = process.env.REACT_APP_STRAPI_API_ENDPOINT;
    const token = process.env.REACT_APP_STRAPI_API_TOKEN;
    const jwtOptions = {
      method,
      url: `${host}${path}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const apiCall = axios(jwtOptions);
    apiCall
      .then((res) => {
        resolve(res.data);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};

const pathsToFetchRawData = ["/balances"];

const errorTypesArr = ["invalid_refresh_token", "token_expired"];
const restrictAPIPath = ["/link/", "/invite-users"];

// axios API call
export const callAPIInterface = (
  method,
  path,
  data = "",
  file = null,
  fileName = null,
  signal = null
) => {
  return new Promise(async (resolve, reject) => {
    let jwtOptions = await getHeaders(method, path, data, file, fileName);
    if (Object.keys(jwtOptions)?.length) {
      let axiosObj = {
        ...jwtOptions,
        transformResponse: (r) =>
          r && (pathsToFetchRawData.includes(path) ? r : JSON.parse(r)),
      };
      if (signal) axiosObj.signal = signal;
      let apiCall = axios(axiosObj);
      apiCall
        .then((res) => {
          resolve(res?.data);
        })
        .catch(async (err) => {
          if (err.response) {
            if (pathsToFetchRawData.includes(path)) {
              err.response.data = JSON.parse(err.response.data || "{}");
            }
            const errors = err.response.data.errors;
            const errorStatus = err.response.status;
            const errorType =
              errors && errors[0].type && errors[0].type.toLowerCase();

            if (serverErrorStatusCodes.includes(errorStatus)) {
              captureException(err);
              // store.getState().session.authenticated
              //   ? await expireSession()
              //   : history.push("/login");
              errorStatus !== 417 &&
                errors?.[0]?.message &&
                store.dispatch(
                  showToast({
                    isToastOpen: true,
                    toastMessage: errors[0].message,
                    toastVariant: "error",
                  })
                );
              reject(errorStatus);
            } else if (errorStatusCodes.includes(errorStatus)) {
              const isAccountRestricted = errorType === "account_restriction";

              let hideToast =
                errorTypesArr.includes(errorType) ||
                restrictAPIPath.some((pathName) => path.includes(pathName));

              if (errorType === "token_expired") {
                store
                  .dispatch(generateToken())
                  .then(async (res) => {
                    jwtOptions.headers["Access"] = res.access_token;
                    jwtOptions.headers["Authorization"] = res.id_token;
                    axiosRetry(
                      axios({
                        ...jwtOptions,
                        transformResponse: (r) =>
                          r &&
                          (pathsToFetchRawData.includes(path)
                            ? r
                            : JSON.parse(r)),
                      })
                        .then((response) => {
                          resolve(response?.data);
                        })
                        .catch(async (retryError) => {
                          if (pathsToFetchRawData.includes(path)) {
                            retryError.response.data = JSON.parse(
                              retryError?.response?.data || "{}"
                            );
                          }
                          // This is for: After new tokens were generated if any error will be occurred in the current API then the user will be logged out.
                          const pathNotRedirectToLogin = [
                            "/search",
                            "/mfa",
                            "/verify-user-password",
                            "/withdraws/estimate",
                          ];
                          if (
                            pathNotRedirectToLogin.some((pathName) => {
                              return path.includes(pathName);
                            })
                          ) {
                            const searchAPIError =
                              retryError?.response?.data?.errors;
                            retryError?.response?.status !== 417 &&
                              searchAPIError?.[0]?.message &&
                              (await store.dispatch(
                                showToast({
                                  isToastOpen: true,
                                  toastMessage: searchAPIError[0].message,
                                  toastVariant: "error",
                                })
                              ));
                            reject(retryError);
                          } else {
                            expireSession();
                            path !== "/logout" &&
                              store.dispatch(
                                showToast({
                                  isToastOpen: true,
                                  toastMessage: errorMessage,
                                  toastVariant: "error",
                                })
                              );
                          }
                        }),
                      { retries: 1 }
                    );
                  })
                  .catch((tokenError) => {
                    const tokenErrors =
                      tokenError &&
                      tokenError.response &&
                      tokenError.response.data.errors[0];
                    if (tokenErrors) {
                      // If any error occurs during generate token then user will be logged out.
                      expireSession();
                      path !== "/logout" &&
                        store.dispatch(
                          showToast({
                            isToastOpen: true,
                            toastMessage: errorMessage,
                            toastVariant: "error",
                          })
                        );
                    }
                  });
              } else if (errorType === "unauthorized") {
                expireSession();
              } else if (isAccountRestricted) {
                hideToast = true;
                store.dispatch(
                  setAccountRestrictedModalData({
                    openRestrictedModal: true,
                    errorMessage: errors?.[0]?.message,
                    callbackErrorFn: () => reject(err),
                  })
                );
              } else {
                reject(err);
              }

              errors?.[0]?.message &&
                !hideToast &&
                (await store.dispatch(
                  showToast({
                    isToastOpen: true,
                    toastMessage:
                      errors[0].param &&
                      publicInfoUrlErrorParams.includes(errors[0].param)
                        ? publicInfoUrlErrorMsg
                        : errors[0].message,
                    toastVariant: "error",
                  })
                ));
            } else {
              redirectAfterError(err, reject);
            }
          } else if (err.request) {
            // redirectAfterError(err, reject);
            captureException(err);
            store.dispatch(
              showToast({
                isToastOpen: true,
                toastMessage: somethingWrong,
                toastVariant: "error",
              })
            );
            reject(503);
          }
        });
    } else {
      reject({});
    }
  });
};

const closeFSLoader = (setFsLoader, setIsTokenUpdate) => {
  setFsLoader(false);
  setIsTokenUpdate(false);
};

export const getFirestoreData = async (user, setFsLoader, setIsTokenUpdate) => {
  const authObj = await getAuth(app);
  const db = getFirestore(app);
  const userRef = doc(db, "user", user.id);
  setIsTokenUpdate(true);
  try {
    await onSnapshot(
      userRef,
      async (querySnapshot) => {
        const data = querySnapshot.data();
        if (data) {
          const session = await sessionService
            .loadSession()
            .then((res) => res)
            .catch(() => {});
          const timestamp = session && session.timestamp;
          if (
            data.global_sign_out_timestamp &&
            timestamp < data.global_sign_out_timestamp
          )
            expireSession();

          if (
            data.token_updated_timestamp &&
            timestamp < data.token_updated_timestamp
          ) {
            await store.dispatch(generateToken()).then(async () => {
              const prevUserData = await store.getState().auth?.user;
              const newUserData = await store.dispatch(getUser());
              const isRemovedUser =
                prevUserData?.accounts_details?.length >
                newUserData?.accounts_details?.length;
              closeFSLoader(setFsLoader, setIsTokenUpdate);
              if (isRemovedUser) {
                window.location.assign(
                  `${window.location.origin}${"/dashboard"}`
                );
              }
            });
          } else closeFSLoader(setFsLoader, setIsTokenUpdate);
        } else closeFSLoader(setFsLoader, setIsTokenUpdate);
      },
      async (_e) => {
        await authObj.currentUser?.getIdToken();
        closeFSLoader(setFsLoader, setIsTokenUpdate);
      }
    );
  } catch (_e) {
    closeFSLoader(setFsLoader, setIsTokenUpdate);
  }
};

export const cookieOptions = {
  path: "/",
  domain: process.env.REACT_APP_DOMAIN,
  sameSite: "strict",
  secure: true,
};

export const expireSession = async (isRedirect = true) => {
  const authObj = getAuth(app);
  await signOut(authObj);
  sessionService.deleteSession();
  sessionService.deleteUser();
  localStorage.clear();
  sessionStorage.clear();
  store.dispatch({
    type: RESET_STORE,
  });
  store.dispatch(setIsLoggedIn(false));
  store.dispatch(setPathname(""));
  // Remove session cookie
  const cookies = new Cookies();
  cookies.remove("session", cookieOptions);
  cookies.remove("firebase_token", cookieOptions);
  cookies.remove("oneclick-authorize-data");
  // unregisterServiceWorker();
  // deleteFCMToken();
  if (isRedirect) {
    const fromPartnerDataAvailable = isAffiliatePartnerDataAvailable();
    if (fromPartnerDataAvailable) {
      if (affiliatePartnerData()?.is_invitee_exist) {
        history.push("/login");
      } else {
        history.push("/register");
      }
    } else {
      history.push("/login");
    }
  }
};

// export function unregisterServiceWorker() {
//   if ("serviceWorker" in navigator) {
//     navigator.serviceWorker.getRegistrations().then(function (registrations) {
//       for (let registration of registrations) {
//         registration.unregister();
//       }
//     });
//   }
// }

// Regex
export const char8 = /(?=.{8,})/;
export const upperCase = /(?=.*[A-Z])/;
export const lowerCase = /^(?=.*[a-z])/;
export const number = /(?=.*[0-9])/;
export const bothCases = /^(?=.*[a-z])(?=.*[A-Z])/;
export const secretKeyCount = 2;
export const isAlphaNumericWithOutSpace = /^[a-z\d]+$/i;
export const isAlphabet = /^[aA-zZ\s]+$/;
export const isDomainValid =
  /^(?!:\/\/)([a-zA-Z0-9]+\.)[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}?$/i;
export const isEmailDomainValid =
  /^(?!:\/\/)([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}?$/i;
export const isTransferAccountValid = /^acct_(\w).{1,16}$/;
export const isPaymentDomainValid = /[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
export const isPaymentAddressUsernameValid = /^[a-zA-Z0-9]+[-_.]?[a-zA-Z0-9]+$/;
export const fileNameReplaceRegex = /[^\w\d_\-.]+/gi;

export const passwordPolicyState = (value) => {
  let currentState = [];
  char8.test(value)
    ? currentState.push(min8Char)
    : currentState.filter((item) => item !== min8Char);
  bothCases.test(value)
    ? currentState.push(letterAndNumber)
    : currentState.filter((item) => item !== letterAndNumber);
  number.test(value)
    ? currentState.push(oneNumber)
    : currentState.filter((item) => item !== oneNumber);
  if (upperCase.test(value) || lowerCase.test(value)) {
    !currentState.includes(letter) && currentState.push(letter);
  } else {
    currentState.filter((item) => item !== letter);
  }

  return currentState;
};

export const passwordValidationSchema = yup
  .string()
  .matches(char8, min8Char)
  .matches(upperCase, upperValidate)
  .matches(lowerCase, lowerValidate)
  .matches(number, numberValidate)
  .required("");

export const whiteLogoImage = (
  <img alt="main-logo" className="main-white-logo" src={whiteLogo} />
);

const usingGoogleAuthText = (size, linkSize, byQRCode) => (
  <Text className="default-text" font="regular" size={size} variant="caption">
    {setup2faSubMsgQR()[0]}
    <CustomLink size={linkSize}>{googleAuthenticator}</CustomLink>
    {setup2faSubMsgQR(byQRCode)[1]}
  </Text>
);

export const Setup2FASubText = ({
  isFromAuth,
  usingPhone,
  byQRCode,
  ...props
}) => (
  <Text
    font="regular"
    className={props.from !== "profile" ? "margin-top10" : ""}
    size={!isFromAuth ? 14 : 18}
    variant="subtitle1"
    {...props}
  >
    {usingPhone
      ? setup2faSubMsgMobile
      : usingGoogleAuthText(
          !isFromAuth ? 14 : 16,
          !isFromAuth ? "small" : "large",
          byQRCode
        )}
  </Text>
);

export const CodeTextInput = ({ value }) => (
  <Input
    fullWidth
    showLabel={false}
    customClass="setup-2fa-input"
    disabled
    value={value}
  />
);

export const QRCodeAndEnterTextToggle = ({ byQRCode, onClick }) => (
  <Text
    withIcon="start"
    className="two-auth-setup grey-text"
    size={16}
    font="semibold"
    justifyContent="center"
    onClick={onClick}
  >
    {byQRCode ? (
      <>
        <EditSharpIcon />
        {enterCodeMsg}
      </>
    ) : (
      <>
        <QrCodeScannerIcon />
        {scanQRMsg}
      </>
    )}
  </Text>
);

export const linkAlreadySubText = (email) => (
  <Text
    align="center"
    size={16}
    font="regular"
    className="default-text"
    variant="subtitle1"
  >
    {linkAlreadyVerifiedSubMsg[0]}{" "}
    <Text size={16} font="semibold" className="default-text" variant="inline">
      {email}{" "}
    </Text>
    {linkAlreadyVerifiedSubMsg[1]}
  </Text>
);

export const TimeZoneSelect = ({ name, value, onChange }) => (
  <CustomAutoComplete
    name={name}
    options={Timezones}
    getOptionLabel={(option) => {
      let timezone = _.find(Timezones, {
        locale: option && typeof option === "object" ? option.locale : option,
      });

      if (timezone) return timezone.timezone;
    }}
    onChange={(_e, time) => {
      onChange(time && typeof time === "object" ? time.locale : time);
    }}
    value={value}
    customClass="margin-top30"
    placeholder={timezonePlaceholder}
    showLabel
    label={timezoneLabel}
  />
);

export const getTimestamp = () => {
  const date = new Date();
  date.setSeconds(date.getSeconds() + 10);
  return date.getTime();
};

export const validatePhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    try {
      return isValidPhoneNumber(phoneNumber);
    } catch (e) {
      return false;
    }
  } else {
    return true;
  }
};

export const getMultilevelCountryCallingCode = (callingCode) => {
  if (callingCode) {
    return _.countBy(callingCode)["+"] > 0 ||
      (callingCode[0] === "1" && callingCode?.length > 1)
      ? "1"
      : callingCode;
  }
};

export const concatedMobileNumber = (callingCode, mobileNumber) => {
  return callingCode && mobileNumber
    ? `+${getMultilevelCountryCallingCode(callingCode) + mobileNumber}`
    : "";
};

export const mobileNumberWithoutCallingCode = (callingCode, mobileNumber) => {
  return mobileNumber?.replace(
    `+${getMultilevelCountryCallingCode(callingCode)}`,
    ""
  );
};

export const getLowerCamelCaseText = (text) => {
  return text.replace(/ /g, "_")?.toLowerCase();
};

const validateCurrency = (address, networkType, currency) => {
  if (currency?.code === "BTC") {
    return btcValidate(address, networkType);
  } else {
    const regex = new RegExp(`^[a-zA-Z0-9-_.]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6}$`);
    return regex.test(address);
  }
};

export const validateWalletAddress = (
  address,
  currency,
  isLiveMode,
  appEnv
) => {
  const networkType =
    !isLiveMode || appEnv === "development" ? "testnet" : "mainnet";
  if (address && currency) {
    try {
      return validateCurrency(address, networkType, currency);
    } catch (e) {
      return false;
    }
  } else {
    return true;
  }
};

export const validatePostalCode = (postalCode, countryISO) => {
  if (postalCode && countryISO) {
    try {
      return validate(countryISO, postalCode) === true ? true : false;
    } catch (e) {
      return false;
    }
  } else {
    return true;
  }
};

export const validateURL = (
  urlInput,
  requireHttp,
  onlySecureProtocol = false
) => {
  if (urlInput) {
    try {
      return isURL(urlInput, {
        protocols: onlySecureProtocol ? ["https"] : ["http", "https"],
        require_protocol: requireHttp,
      });
    } catch (e) {
      return false;
    }
  } else {
    return true;
  }
};

export const idealTimeCheck = 10;
export const noOfRecords = 20;
export const noOfRecordsWalletTransactionInAssets = 5;
export const languageArray = [
  {
    id: 1,
    label: "English (Australia)",
    value: "english(Australia)",
  },
  {
    id: 2,
    label: "English (Canada)",
    value: "english (Canada)",
  },
  {
    id: 3,
    label: "English (India)",
    value: "english (India)",
  },
];

export const genderArray = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const cashbackTypesArray = [
  {
    id: 1,
    label: "Fixed Amount",
    value: "fixed_amount",
  },
  {
    id: 2,
    label: "Percentage",
    value: "percentage",
  },
];

export const btcOnchainTolerenceArray = [
  {
    id: 1,
    label: "0%",
    value: "0",
  },
  {
    id: 2,
    label: "1%",
    value: "1",
  },
  {
    id: 3,
    label: "2%",
    value: "2",
  },
  {
    id: 4,
    label: "3%",
    value: "3",
  },
  {
    id: 5,
    label: "4%",
    value: "4",
  },
  {
    id: 6,
    label: "5%",
    value: "5",
  },
];

export const createInvoiceDueDates = [
  {
    id: 1,
    value: "today",
    label: "Today",
  },
  {
    id: 2,
    value: "tomorrow",
    label: "Tomorrow",
  },
  {
    id: 3,
    value: "7days",
    label: "7 Days",
  },
  {
    id: 4,
    value: "14days",
    label: "14 Days",
  },
  {
    id: 5,
    value: "30days",
    label: "30 Days",
    isDividerBelow: true,
  },
  {
    id: 6,
    value: "customDueDate",
    label: "Custom Due Date",
    customClass: "blue-text",
  },
];

export const createInvoiceDelivery = [
  {
    id: 1,
    value: "emailInvoice",
    label: "Email invoice to customer",
  },
  {
    id: 2,
    value: "noEmailInvoice",
    label: "Do not email customer",
  },
];

export const dateFormatArray = [
  {
    id: 1,
    label: "yyyy/mm/dd",
    value: "yyyy/MM/dd",
  },
  {
    id: 2,
    label: "mm/dd/yyyy",
    value: "MM/dd/yyyy",
  },
  {
    id: 3,
    label: "dd/mm/yyyy",
    value: "dd/MM/yyyy",
  },
];

export const googleFontArray = [
  {
    id: 1,
    label: "System font (default)",
    fontFamily: "system-ui",
  },
  {
    id: 2,
    label: "Be Vietnam Pro",
    fontFamily: "Be Vietnam Pro",
  },
  {
    id: 3,
    label: "Bitter",
    fontFamily: "Bitter",
  },
  {
    id: 4,
    label: "Chakra Petch",
    fontFamily: "Chakra Petch",
  },
  {
    id: 5,
    label: "Hahmlet",
    fontFamily: "Hahmlet",
  },
  {
    id: 6,
    label: "Inconsolata",
    fontFamily: "Inconsolata",
  },
  {
    id: 7,
    label: "Inter",
    fontFamily: "Inter",
  },
  {
    id: 8,
    label: "Lato",
    fontFamily: "Lato",
  },
  {
    id: 9,
    label: "Lora",
    fontFamily: "Lora",
  },
  {
    id: 10,
    label: "M PLUS 1 Code",
    fontFamily: "MPLUS1Code",
  },
  {
    id: 11,
    label: "Montserrat",
    fontFamily: "Montserrat",
  },
  {
    id: 12,
    label: "Noto Sans",
    fontFamily: "Noto Sans",
  },
  {
    id: 13,
    label: "Noto Serif",
    fontFamily: "Noto Serif",
  },
  {
    id: 14,
    label: "Nunito",
    fontFamily: "Nunito",
  },
  {
    id: 15,
    label: "Open Sans",
    fontFamily: "Open Sans",
  },
  {
    id: 16,
    label: "Pridi",
    fontFamily: "Pridi",
  },
  {
    id: 17,
    label: "PT Sans",
    fontFamily: "PT Sans",
  },
  {
    id: 18,
    label: "PT Serif",
    fontFamily: "PT Serif",
  },
  {
    id: 19,
    label: "Raleway",
    fontFamily: "Raleway",
  },
  {
    id: 20,
    label: "Roboto",
    fontFamily: "Roboto",
  },
  {
    id: 21,
    label: "Roboto Slab",
    fontFamily: "Roboto Slab",
  },
  {
    id: 22,
    label: "Source Sans Pro",
    fontFamily: "Source Sans Pro",
  },
  {
    id: 23,
    label: "Titillium Web",
    fontFamily: "Titillium Web",
  },
  {
    id: 24,
    label: "Ubuntu Mono",
    fontFamily: "Ubuntu Mono",
  },
  {
    id: 25,
    label: "Zen Maru Gothic",
    fontFamily: "Zen Maru Gothic",
  },
];

export const brandingIconSelectionArray = [
  {
    id: 1,
    label: "Icon",
    value: "icon",
  },
  {
    id: 2,
    label: "Logo",
    value: "logo",
  },
];

export const QRTypeArray = [
  {
    id: 1,
    label: "Lightning QR code",
    value: "Lightning QR code",
    apiValue: "lnurl",
  },
  {
    id: 2,
    label: "On-chain QR code",
    value: "On-chain QR code",
    apiValue: "onchain",
  },
];

export const timeAgo = (timestamp, timezone) => {
  if (timezone) {
    return moment(timestamp).tz(timezone).fromNow();
  } else {
    return moment(timestamp).fromNow();
  }
};

export const idealTimeLimitReached = (time) => {
  let lastActionTime = moment(time);
  var duration = moment.duration(moment(new Date()).diff(lastActionTime));
  var minutes = duration.asMinutes();

  return minutes > idealTimeCheck;
};

// Breadcrumbs route constants
export const RouteBreadcrumbs = [
  {
    title: "Checkout Links",
    route: "/checkout-links",
  },
  {
    title: "Payment Links",
    route: "/payment-links",
  },
  {
    title: "Withdrawal Link",
    route: "/withdrawal-links",
  },
  {
    title: "One QR",
    route: "/one-qr",
  },
  {
    title: "Cashbacks",
    route: "/cashbacks",
  },
  {
    title: "Invoices",
    route: "/invoices",
  },
  {
    title: "Details",
    route: "/invoices/:id",
  },
  {
    title: "Details",
    route: "/cashbacks/:id",
  },
  {
    title: "Details",
    route: "/checkout-links/:id",
  },
  {
    title: "Details",
    route: "/payment-links/:id",
  },
  {
    title: "Details",
    route: "/withdrawal-links/:id",
  },
  {
    title: "Import",
    route: "/withdrawal-links/import",
  },
  {
    title: "Imported Files",
    route: "/withdrawal-links/import/:id",
  },
  {
    title: "Details",
    route: "/one-qr/:id",
  },
  {
    title: "Payment Addresses",
    route: "/payment-addresses",
  },
  {
    title: "Details",
    route: "/payment-addresses/:id",
  },
  {
    title: "Sessions",
    route: "/sessions",
  },
  {
    title: "Details",
    route: "/sessions/:id",
  },
  {
    title: "Events",
    route: "/sessions/:id/cs-events",
  },
  {
    title: "Payments",
    route: "/payments",
  },
  {
    title: "Details",
    route: "/payments/:id",
  },
  {
    title: "Transfers",
    route: "/payments/:id/payment-transfers",
  },
  {
    title: "Customers",
    route: "/customers",
  },
  {
    title: "Details",
    route: "/customers/:id",
  },
  {
    title: "Withdraws",
    route: "/withdraws",
  },
  { title: "Details", route: "/withdraws/:id" },
  {
    title: "Payouts",
    route: "/payouts",
  },
  {
    title: "Details",
    route: "/payouts/:id",
  },
  {
    title: "Swaps",
    route: "/swaps",
  },
  {
    title: "Details",
    route: "/swaps/:id",
  },
  {
    title: "Partners",
    route: "/partners/affiliate-partners",
  },
  {
    title: "Merchant Details",
    route: "/partners/affiliate-partners/:id",
  },
  {
    title: "Transfers",
    route: "/transfers",
  },
  {
    title: "Details",
    route: "/transfers/:id",
  },
  {
    title: "Webhooks",
    route: "/webhooks",
  },
  {
    title: "Details",
    route: "/webhooks/:id",
  },
  {
    title: "Manage Apps",
    route: "/manage-apps",
  },
  {
    route: "/logs",
    title: "Logs",
  },
  {
    route: "/logs/:id",
    title: "Details",
  },
  {
    title: "Products",
    route: "/products",
  },
  {
    title: "Details",
    route: "/products/:id",
  },
  {
    title: "Events",
    route: "/events",
  },
  { title: "Details", route: "/events/:id" },
  {
    title: "Webhook Attempts",
    route: "/events/:id/webhook-attempts",
    applySearchParams: true,
  },
  {
    title: "Attempts Details",
    route: "/events/:id/webhook-attempts/:attempt_id",
  },
  {
    title: "Developers",
    route: "/apikeys/restricted-keys",
  },
  {
    title: "API Keys",
    route: "/apikeys/restricted-keys/:id",
  },
  {
    title: "Connect",
    route: "/connect",
  },
  {
    title: "Connected account details",
    route: "/connect/:id",
  },
  {
    title: "Assets",
    route: "/assets",
  },
  { title: "Details", route: "/assets/:id" },
  { title: "Instant Send", route: "/instant-sends" },
  { title: "Details", route: "/instant-sends/:id" },
];

export const isFromEmailRoutes = [
  "/new-password",
  "/verify-email",
  "/confirm-email",
  "/documentation",
  "/login-success",
  "/onboarding",
];

export const getProductName = (productRowData) => {
  if (productRowData && productRowData.length > 0) {
    let productName;
    if (productRowData.length === 1) {
      productName = productRowData[0].name;
    } else {
      productName =
        productRowData[0].name +
        " and " +
        (productRowData.length - 1) +
        " more";
    }
    return productName;
  }
};

export const updateSessionLastActionTime = () => {
  return sessionService
    .loadSession()
    .then((newSession) => {
      let session = { ...newSession, last_action_time: moment().valueOf() };
      sessionService.saveSession(session);
      return session;
    })
    .catch((_e) => {});
};

export const emailStatus = {
  verified: {
    label: "Verified",
    variant: "success",
    value: "verified",
  },
  pending_verification: {
    label: "Pending Verification",
    variant: "warning",
    value: "pending_verification",
  },
};

export const renderCurrencyOption = (_props, option) => {
  return (
    <>
      <li {..._props}>
        {option.code} ({option.symbol})
      </li>
      {option.isDivider ? (
        <CustomDivider className="currency-divider" text={fiatCurrency} />
      ) : null}
    </>
  );
};

export const renderStatus = (status) => {
  const tagProps = {
    text: linkStatus[status]?.label,
    variant: linkStatus[status]?.variant,
  };

  const iconNotRequiredStatus = [
    "available",
    "deactivated",
    "active",
    "archive",
  ];

  if (!iconNotRequiredStatus.includes(status)) {
    tagProps.deleteIcon = linkStatus[status]?.icon;
    tagProps.onDelete = () => {};
  }

  if (linkStatus[status]?.label) {
    if (!linkStatus[status]?.tooltip) {
      return <Tag {...tagProps} />;
    } else {
      return (
        <CustomTooltip
          arrow={true}
          placement={"top"}
          text={<>{linkStatus[status].tooltip}</>}
          className="custom-tooltip payments-status-tooltip"
        >
          <Box sx={{ width: "fit-content", boxShadow: "none" }}>
            <Tag className="pointer-cursor" {...tagProps} />
          </Box>
        </CustomTooltip>
      );
    }
  } else {
    return null;
  }
};

export const renderWebhookStatus = (status) => {
  const tagProps = {
    text: webHookStatus[status]?.label,
    variant: webHookStatus[status]?.variant,
    deleteIcon: webHookStatus[status]?.icon,
    onDelete: () => {},
  };

  if (webHookStatus[status]?.label) {
    if (!webHookStatus[status]?.tooltip) {
      return <Tag {...tagProps} />;
    } else {
      return (
        <CustomTooltip
          arrow={true}
          text={<>{webHookStatus[status].tooltip}</>}
          className="custom-tooltip payments-status-tooltip"
        >
          <Box sx={{ width: "fit-content", boxShadow: "none" }}>
            <Tag className="pointer-cursor" {...tagProps} />
          </Box>
        </CustomTooltip>
      );
    }
  } else {
    return null;
  }
};

export const renderWithdrawStatus = (status) => {
  const tagProps = {
    text: withdrawStatus[status]?.label,
    variant: withdrawStatus[status]?.variant,
    deleteIcon: withdrawStatus[status]?.icon,
    onDelete: () => {},
  };

  if (withdrawStatus[status]?.label) {
    if (!withdrawStatus[status]?.tooltip) {
      return <Tag {...tagProps} />;
    } else {
      return (
        <CustomTooltip
          arrow={true}
          placement={"top"}
          text={<>{withdrawStatus[status].tooltip}</>}
          className="custom-tooltip payments-status-tooltip"
        >
          <Box sx={{ width: "fit-content", boxShadow: "none" }}>
            <Tag className="pointer-cursor" {...tagProps} />
          </Box>
        </CustomTooltip>
      );
    }
  } else {
    return null;
  }
};

export const activateDeactivateCheckoutLink = (id) => {
  return new Promise((resolve, reject) => {
    callAPIInterface("POST", `/checkout-links/${id}/deactivate`, {})
      .then((response) => {
        resolve(response);
      })
      .catch((_error) => {
        reject(_error);
      });
  });
};

export const activateDeactivatePaymentLink = (id) => {
  return new Promise((resolve, reject) => {
    callAPIInterface("POST", `/payment-links/${id}/deactivate`, {})
      .then((response) => {
        resolve(response);
      })
      .catch((_error) => {
        reject(_error);
      });
  });
};

export const activateDeactivateSession = (id) => {
  return new Promise((resolve, reject) => {
    callAPIInterface("POST", `/checkout-sessions/${id}/deactivate`, {})
      .then((response) => {
        resolve(response);
      })
      .catch((_error) => {
        reject(_error);
      });
  });
};

export const renderOneQRType = (_props, option) => {
  return (
    <>
      <li {..._props}>{option.value}</li>
    </>
  );
};

export const eventStatus = {
  succeeded: {
    label: "Succeeded",
    value: "succeeded",
    param: "Success",
  },
  failed: {
    label: "Failed",
    value: "failed",
    param: "Fail",
  },
};

function memoizingFunction() {
  // this is a function created for memoizing the all arguments rather than only first one.
  return JSON.stringify(arguments);
}

const getWebSearchResults = async (inputString, page, dateFormat) => {
  const searchObj = {
    query: inputString,
    page,
  };

  if (dateFormat) {
    searchObj.date_format = dateFormat;
  }

  return callAPIInterface("POST", `/search`, searchObj)
    .then((response) => {
      if (response) {
        return response;
      }
    })
    .catch((_e) => {
      return null;
    });
};

export const getWebSearchAPIResults = _.memoize(
  getWebSearchResults,
  memoizingFunction
);

export const returnQRCodeMethod = [
  { method: "onchain", label: onChainLabel },
  { method: "lnurl", label: lightningLabel },
];

export const validateShopifyStoreUrl = (urlInput) => {
  if (urlInput) {
    const regex = new RegExp(
      "/^https?://[a-zA-Z0-9][a-zA-Z0-9-]*.myshopify.com/?/"
    );
    return regex.test(urlInput);
  }
  return false;
};

export const handleDownloadImage = (name, id, date) => {
  return new Promise((resolve, reject) => {
    callAPIInterface("POST", `/oneqrs/${id}/export`, {
      type: "image/png",
    })
      .then((res) => {
        if (res) {
          saveAs(res?.file_name, `${name}_${date}`);
          resolve(false);
        }
      })
      .catch((_err) => {
        reject(false);
      });
  });
};

export const activateDeactivateWithdrawalLink = (id) => {
  return new Promise((resolve, reject) => {
    callAPIInterface("POST", `/withdrawal-links/${id}/deactivate`, {})
      .then((response) => {
        resolve(response);
      })
      .catch((_error) => {
        reject(_error);
      });
  });
};
export const manageAppsStatus = {
  active: {
    label: installed,
    variant: "success",
  },
  draft: {
    label: yetToBeInstalled,
    variant: "warning",
  },
};
export const manageAppIcon = (iconName) => {
  switch (iconName) {
    case "Shopify":
      return shopifyIcon;
    case "Woocommerce":
      return wooCommerceIcon;
    default:
      return;
  }
};

export const generatePassword = () => {
  let length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const getLabelForButton = (step) => {
  switch (step) {
    case "1":
      return authenticate;
    case "2":
    case "3":
      return next;
    case "4":
      return add;
    default:
      return "";
  }
};

export const getManageAppFireStoreAllowData = async (appId) => {
  const db = getFirestore(app);
  const manageAppSpeedCallback = doc(db, "speed_callback_verifier", appId);

  await onSnapshot(
    manageAppSpeedCallback,
    async (querySnapshot) => {
      const data = querySnapshot.data();
      if (data?.flag) {
        history.push("/manage-apps/shopify/1");
      }
    },
    async (_e) => {}
  );
};

export const getManageAppFireStoreAuthenticateData = async (appId) => {
  const db = getFirestore(app);
  const manageAppShopifyCallback = doc(db, "shopify_callback_verifier", appId);

  await onSnapshot(
    manageAppShopifyCallback,
    async (querySnapshot) => {
      const data = querySnapshot.data();
      if (data?.flag) {
        history.push("/manage-apps/shopify/2");
      }
    },
    async (_e) => {}
  );
};

export const getManageAppFireStoreVerifyData = async (
  appId,
  orderId,
  setVerifySteps,
  setInstallButtonDisabled
) => {
  const db = getFirestore(app);
  const manageAppVerifyOrder = doc(db, "hookdeck_callback_verifier", appId);

  await onSnapshot(
    manageAppVerifyOrder,
    async (querySnapshot) => {
      const data = await querySnapshot.data();
      if (data?.order == orderId) {
        setVerifySteps(success);
        setInstallButtonDisabled(false);
      } else {
        setVerifySteps(fail);
        setInstallButtonDisabled(true);
      }
    },
    async (_e) => {
      setVerifySteps(fail);
      setInstallButtonDisabled(true);
    }
  );
};

export const showNeedHelpRoutes = ["/login-success"];
export const appStoreWalletURL =
  "https://apps.apple.com/us/app/speed-bitcoin-wallet/id6462426281";
export const playStoreWalletURL =
  "https://play.google.com/store/apps/details?id=com.app.speedwallet";
export const chromeExtensionURL =
  "https://chrome.google.com/webstore/detail/miccfnlbijkmbckaagllchcfknjhgfnk";
export const allowedRedirectionHostname = [
  "localhost",
  "dev.ln.dev",
  "www.ln.dev",
];
export const handleSessionSharingRedirection = (queryParams) => {
  const searchURL = queryParams.replace("+", "%2B");
  const data = new URLSearchParams(searchURL);
  const redirect = data.get("redirect");
  const from = data.get("from");
  const isFirestore = data.get("require_firebase_token");

  if (!from && redirect) {
    sessionStorage.setItem("redirect", redirect);
    sessionStorage.setItem("require_firebase_token", isFirestore);
    return redirect;
  }

  return null;
};

export const convertArrayToObjectForMetadata = (array) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item["key"]]: item["value"],
    };
  }, initialValue);
};

export const updateMetadata = (payload, url) => {
  return new Promise((resolve, reject) => {
    callAPIInterface("POST", url, JSON.stringify(payload))
      .then((response) => {
        resolve(response);
      })
      .catch((_error) => {
        reject(_error);
      });
  });
};

export const redirectToLogin = () => history.push("/login");

export const moduleMetaDataRows = (metaDataList) =>
  metaDataList?.map((rowItem, rowIndex) => {
    return (
      <TableRow key={rowIndex} sx={{ display: "flex", wordBreak: "break-all" }}>
        <TableCell width="35%">{rowItem?.key}</TableCell>
        <TableCell width="65%">{rowItem?.value}</TableCell>
      </TableRow>
    );
  });

export const sourceLnDev = "ln.dev";
export const sourceWebApp = "Webapp";

export const decryptPassword = (cipherText, speedAccountId) => {
  let bytes = AES.decrypt(cipherText, speedAccountId);
  let originalText = bytes.toString(enc.Utf8);

  return originalText;
};

export const showPassword = (id, accountId) => {
  return new Promise((resolve, reject) => {
    callAPIInterface(
      "GET",
      `/withdrawal-links/get-withdrawal-link-password/${id}`
    )
      .then((res) => {
        const decryptedPassword = decryptPassword(res?.password, accountId);
        resolve(decryptedPassword);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const transferType = {
  TRANSFER_IN: {
    label: "Transfer In",
    value: "TRANSFER_IN",
  },
  TRANSFER_OUT: {
    label: "Transfer Out",
    value: "TRANSFER_OUT",
  },
};

export const originURL = (source, resourcePath) => {
  if (source === "API") {
    return process.env.REACT_APP_ENVIRONMENT === "production"
      ? "https://api.tryspeed.com" + resourcePath
      : "https://api.tryspeed.dev" + resourcePath;
  } else if (source === "APP")
    return process.env.REACT_APP_API_ENDPOINT + resourcePath;
  else return resourcePath;
};

export const renderSenderReceiverOfTransfer = (response, currentAccountId) => {
  const result = {
    sender: "",
    receiver: "",
  };
  if (response?.type === transferType.TRANSFER_IN.value) {
    result["sender"] = response?.destination_account;
    result["receiver"] = currentAccountId;
  } else if (response?.type === transferType.TRANSFER_OUT.value) {
    result["sender"] = currentAccountId;
    result["receiver"] = response?.destination_account;
  }
  return result;
};

export const renderTermsAgreementCheckbox = (isChecked, setIsChecked) => {
  return (
    <Box className="margin-top30" display="flex" alignItems="center">
      <CustomCheckbox
        label=""
        onChange={() => setIsChecked(!isChecked)}
        sx={{ marginRight: 0 }}
        checked={isChecked}
      />
      <Text className="grey-text" size={14} variant="body1">
        {`${agreement} `}
        <CustomLink
          size="small"
          href={process.env.REACT_APP_PROMO_WEBSITE + "terms"}
          target="_blank"
        >
          {termsAndPrivacyMsg[0]}
        </CustomLink>
      </Text>
    </Box>
  );
};

export const typeOptions = [
  { label: "You choose what to pay", value: "app" },
  { label: "Customer choose what to pay", value: "customer" },
];

export const amountTypeOptions = [
  { label: "Any amount", value: "anyAmount" },
  { label: "Preset amount", value: "preset" },
  { label: "Amount options", value: "amountOptions" },
];

export const getCustomerChooseStatus = (amount, fromDetail = false) => {
  return amount === 0
    ? fromDetail
      ? `${customerChoose} (BTC)`
      : customerChoose
    : fromDetail
    ? `${satsToBtcAmount(amount)} BTC`
    : satsToBtcAmount(amount);
};

export const checkZeroAmount = () =>
  yup.addMethod(
    yup.object,
    "zeroAmountCheck",
    function (propertyName, message) {
      return this.test("zeroCheck", message, function (value) {
        if (value?.option <= 0) {
          throw this.createError({
            path: `${this.path}.${propertyName}`,
          });
        }
        return true;
      });
    }
  );

export const currencySupportedByTransaction = ["BTC", "USDT"];
export const currencySupportedByWalletAssets = ["SATS", "BTC"];

export const deactivateCashback = (id) => {
  return new Promise((resolve, reject) => {
    callAPIInterface("POST", `/cashbacks/${id}/deactivate`, {})
      .then((response) => {
        resolve(response);
      })
      .catch((_error) => {
        reject(_error);
      });
  });
};

const getCashbackMenuSearchResults = async (inputString, status, params) => {
  const searchObj = {
    name: inputString,
    status,
  };
  return callAPIInterface("POST", `/cashbacks/filter${params}`, searchObj)
    .then((response) => {
      if (response) {
        return response;
      }
    })
    .catch((_e) => {
      return null;
    });
};

export const getCashbackSearchResults = _.memoize(
  getCashbackMenuSearchResults,
  memoizingFunction
);
export const minimumSatsAmountToSwap = 1000;
export const minimumUsdcAmountToSwap = 1;
export const minimumCommissionAmountForAffiliatePartner = 0;
export const maximumCommissionAmountForAffiliatePartner = 5;
export const minimumSatsAmountToTransfer = 1;

export const renderModuleRequestSummaryKeyValuePair = (key, value) => (
  <Box display="flex" justifyContent="space-between" className="margin-top30">
    <Text className="grey-text" size={16} variant="body1">
      {key}
    </Text>
    <Text className="default-text" size={16} variant="body1">
      {value}
    </Text>
  </Box>
);

export const hexToRgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const getEmailLabel = (email, index, removeEmail) => {
  return (
    <div data-tag key={email} className="email-multi-tags">
      <span className="email-multi-tag-data">{email}</span>
      <span
        data-tag-handle
        onClick={() => removeEmail(index)}
        style={{ marginLeft: "5px" }}
      >
        <img
          src={clearIcon}
          alt="icons-clear"
          height="16px"
          width="16px"
          style={{ marginTop: "2px" }}
        />
      </span>
    </div>
  );
};
export const getRouteForOnboarding = (route) => {
  switch (route) {
    case "/initial-setup-guide":
      return "Initial Setup Guide";
    case "/checkout-link-guide":
      return "Checkout Link Guide";
    case "/payment-link-guide":
      return "Payment Link Guide";
    case "/oneqr-guide":
      return "One QR Guide";
    case "/payout-guide":
      return "Payout Guide";
    case "/swaps-guide":
      return "Swaps Guide";
    case "/transfer-guide":
      return "Transfer Guide";
    case "/withdrawal-link-guide":
      return "Withdrawal Link Guide";
    case "/point-of-sale-guide":
      return "Point of Sale Guide";
    case "/affiliate-partners-guide":
      return "Affiliate Partners";
    case "/invoice-guide":
      return "Invoice Guide";
    case "/cashback-guide":
      return "Cashback Guide";
    case "/customers-guide":
      return "Customers Guide";
    case "/products-guide":
      return "Product Guide";
    case "/payment-address-guide":
      return "Payment Address Guide";
    case "/connect-guide":
      return "Connect Guide";
    case "/session-guide":
      return "Sessions Guide";
    case "/instant-send-guide":
      return "Instant Send Guide";
    default:
      return;
  }
};

export const getTypeOfCashback = (data) => {
  let cashbackType = "";
  if (data?.fixed_amount) {
    cashbackType = `${data?.fixed_amount?.amount} ${data?.fixed_amount?.currency}`;
  }
  if (data?.percentage) {
    cashbackType = `${data?.percentage?.value}% of payment`;
  }
  return cashbackType;
};

export const customPaymentDomainStatus = {
  ready: {
    label: "Ready",
    variant: "success",
    value: "ready",
    message: "Your custom payment domain is connected and ready to use.",
    icon: <CheckCircleIcon className="status-icon" />,
  },
  failed: {
    label: "Failed",
    variant: "error-primary",
    value: "failed",
    icon: <CancelIcon className="status-icon" />,
    message:
      "Your custom payment domain is failed please reconnect and verify.",
  },
  connecting: {
    message: "Set up your DNS records (may take up to 1 hour to verify)",
    value: "connecting",
  },
};

export const getCashbackDetails = (cashbackId) => {
  return new Promise((resolve, reject) => {
    callAPIInterface("GET", `/cashbacks/${cashbackId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const paymentSettingsOptions = [
  {
    id: 1,
    name: checkoutPaymentLinkTabLabel,
  },
  {
    id: 2,
    name: oneQR,
  },
  {
    id: 3,
    name: withdrawalLinkTabLabel,
  },
  {
    id: 4,
    name: invoice,
  },
];

export const checkStatus = (data) => {
  return data?.active ? "available" : "archive";
};

export const getPricingModal = (data) => {
  if (
    data?.billing_scheme === "per_unit" &&
    data?.transform_quantity === null
  ) {
    return "Standard Pricing";
  }
};
export const getPricingType = (value) => {
  switch (value) {
    case "one_time":
      return "One Time";
    default:
      return "";
  }
};

export const editProduct = (id, data) => {
  return new Promise((resolve, reject) => {
    callAPIInterface("POST", `/products/${id}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchProductDetails = (id) => {
  return new Promise((resolve, reject) => {
    callAPIInterface("GET", `/products/${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const archiveUnarchiveActions = ["archive", "unarchive"];
export const isFromPRoduct = ["product", "productDetail"];
export const noOfEmails = 5;
export const memberBenefits = [
  {
    id: 1,
    title: "Special Rates",
    description:
      "By joining Speed through our affiliate partner, you gain access to exclusive discounted rates on our services via affiliate partner. This means you can enjoy our cutting-edge payment solutions at a more cost-effective price.",
  },
  {
    id: 2,
    title: "On-Site or Online Support",
    description:
      "Our affiliate partner offers dedicated on-site or online support to ensure a seamless experience for you. Their expert team will be readily available to assist with any queries or technical support you may need during the implementation of Speed.",
  },
  {
    id: 3,
    title: "Implementation Assistance",
    description:
      "When you join Speed through our affiliate partner, they will provide hands-on assistance in the implementation process. Their team will guide you through the setup and configuration of Speed, helping you maximize its features and capabilities.",
  },
  {
    id: 4,
    title: "Collaborative Partnership",
    description:
      "By affiliating with our partner, you become part of a collaborative partnership network. This opens up opportunities for knowledge sharing, networking, and potential collaborations with other businesses associated with our affiliate partner.",
  },
];

export const keyEventListenerForMultiEmail = (
  e,
  setInValidEmailData,
  setInValidEmailFlag,
  setDisabled,
  emails
) => {
  if (e.target.value && !isEmail(e.target.value)) {
    setInValidEmailData(e.target.value);
    setInValidEmailFlag(true);
    setDisabled(true);
  } else {
    setInValidEmailData("");
    setInValidEmailFlag(false);
    setDisabled(emails.length > noOfEmails || !emails.length > 0);
  }
};

export const affiliatePartnerData = () =>
  sessionStorage.getItem("affiliate-partner-invitee") &&
  JSON.parse(sessionStorage.getItem("affiliate-partner-invitee"));

export const isAffiliatePartnerDataAvailable = () =>
  affiliatePartnerData() && Object.keys(affiliatePartnerData())?.length > 0;

export const handleRemoveVerifyPayoutWallet = () => {
  sessionStorage.removeItem("verify-payout-wallet");
};

export const affiliatePartnerDataNotAvailable = (location) =>
  location?.data?.flag === partnerInvitationExpired;

export const getTrimmedString = (string) => {
  return string ? string.replace(/\s+/g, " ").trim() : "";
};

const currencyValidation = yup.object().shape({
  name: yup.string().required(""),
  code: yup.string().required(""),
  symbol: yup.mixed().required(""),
});

export const currencyAmountValidation = {
  currency: yup.object().when(["modal"], {
    is: (modal) => modal,
    then: currencyValidation,
    otherwise: yup.object().notRequired,
  }),
  amount: yup.number().when(["modal"], {
    is: (modal) => modal,
    then: yup
      .number()
      .required("")
      .test("amount", function (value) {
        if (
          this.parent?.currency?.code === "SATS" &&
          value?.toString().includes(".")
        ) {
          return this.createError({
            path: this.path,
            message: satsValidationMsg,
          });
        } else {
          return true;
        }
      }),
    otherwise: yup.number().notRequired(),
  }),
};

export const languageObject = {
  en_US: "English",
};

export const invoiceTimeAgo = (time) => {
  moment.updateLocale("en", {
    relativeTime: {
      m: "1 minute",
      h: "1 hour",
      d: "1 day",
      M: "1 month",
      y: "1 year",
    },
  });
  const secondsElapsed = moment().diff(time, "seconds");
  const dayStart = moment("2018-01-01").startOf("day").seconds(secondsElapsed);
  if (secondsElapsed === 0) {
    return "now";
  } else if (secondsElapsed < 60) {
    return (
      dayStart.format("s") + ` second${secondsElapsed === 1 ? "" : "s"} ago`
    );
  } else {
    return moment(time).fromNow();
  }
};

export const sendInvoiceAndEmail = (id) => {
  return new Promise((resolve, reject) => {
    callAPIInterface("POST", `/invoices/${id}/send`, {})
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const finalizeInvoice = (id) => {
  return new Promise((resolve, reject) => {
    callAPIInterface("POST", `/invoices/${id}/finalize`, {})
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const symbolsToExcludeForNumberInput = ["e", "E", "+", "-", "."];

const createAddressLine3 = (line3) => {
  let newLine3 = "";
  line3?.forEach((element, index) => {
    let joinChar = element.includes("-") ? "" : ", ";
    newLine3 = `${newLine3}${index !== 0 ? joinChar : ""}${element}`;
  });
  return newLine3;
};

export const getAddressData = (customerData, addressType) => {
  const addressField = customerData?.[addressType]?.address;
  if (addressField) {
    const { line1, line2, city, state, zipcode, country } = addressField;
    let line3 = [];
    let addressDetails = {
      line1: line1 || "",
      line2: line2 || "",
      line3: "",
    };

    if (city) line3 = [...line3, city];
    if (state) line3 = [...line3, state];
    if (zipcode) line3 = [...line3, `${state ? " - " : ""}${zipcode}`];
    if (country) line3 = [...line3, country];

    addressDetails = {
      ...addressDetails,
      line3: createAddressLine3(line3),
    };
    return addressDetails;
  }
};

export const paymentDomainServers = [
  {
    title: "Cloudflare Page Rules",
    description:
      "To configure your custom domain in Cloudflare, kindly follow the instructions outlined below for a seamless setup process.",
    route: "cloudflare-page-rules",
  },
  {
    title: "APACHE Server ( wordpress )",
    description:
      "To configure your custom domain in Apache server, kindly follow the instructions outlined below for a seamless setup process.",
    route: "apache-server-wordpress",
  },
  {
    title: "Nginx Web Server",
    description:
      "To configure your custom domain in Nginx web server, kindly follow the instructions outlined below for a seamless setup process.",
    route: "nginx-web-server",
  },
  {
    title: "Application Load Balancer",
    description:
      "To configure your custom domain in Application load balancer, kindly follow the instructions outlined below for a seamless setup process.",
    route: "application-load-balancer",
  },
];

export const content = [
  {
    value: "laptop",
    children: <LaptopIcon style={{ width: 24, height: 24 }} />,
  },
  {
    value: "phone",
    children: <PhoneAndroidIcon style={{ width: 24, height: 24 }} />,
  },
];

export const sharedPropsBetweenCLPL = {
  visibleInPaymentPage: false,
  onChainAvailable: true,
  lightningLabel: lightningLabel,
  dummyLightningAddress,
  dummyOnchainAddress,
  confirmPageProps: {
    confirmPageLinkID: dummyPaymentID,
    successMessage: "ThanksPaymentMessage",
    amountTypeTitle: "amount",
    idTitle: "paymentId",
    shouldShowSubTitle: false,
  },
};
export const JsonDataView = ({ label, body, noDataText }) => (
  <>
    <Text className="default-text" size={18} variant="h2" marginBottom="20px">
      {label}
    </Text>
    <Box marginBottom="40px">
      {body ? (
        jsonDataToShow(body)
      ) : (
        <NoDataAvailable text={noDataText} className="no-availble-logs-block" />
      )}
    </Box>
  </>
);

export const addNewPayoutWallet = (textWalletAddress) => (
  <Box
    marginY={1}
    padding={1}
    display="flex"
    alignItems="center"
    gap={1}
    className="pointer-cursor"
    onClick={() => {
      store.getState()?.common?.payoutModalOpen &&
        store.dispatch(setPayoutModalOpen(false));
      history.push("/settings/payout-wallets");
    }}
    sx={{
      color: "#2a67ff",
      ":hover": {
        color: "#2758d4 !important",
      },
    }}
  >
    <AddIcon
      sx={{
        width: "16px",
        height: "16px",
        color: "inherit",
      }}
    />
    <Text
      className="default-text"
      size={16}
      variant="body1"
      sx={{
        color: "inherit !important",
      }}
      component="span"
    >
      {textWalletAddress}
    </Text>
  </Box>
);

export const CustomPopperElement = ({ ...props }) => {
  const { id, desc, setAnchor, anchor, open, handleClose } = props;
  return (
    <CustomPopper
      id={id}
      disablePortal={true}
      open={open}
      anchorEl={anchor}
      position="top"
      handleClose={() => handleClose(setAnchor)}
    >
      <Text variant="inherit" font="regular" size={16}>
        {desc}
      </Text>
    </CustomPopper>
  );
};

export const detailPageCFRender = (cfArray) => {
  return cfArray.map((customField, index) => {
    return (
      <Text
        size={16}
        className="default-text"
        variant="subtitle1"
        font="regular"
        key={index}
      >
        <b style={{ textTransform: "capitalize" }}>{customField?.type}</b>
        <b>{`${customField?.is_optional ? " (optional)" : ""}`}</b>:{" "}
        {customField?.label}
      </Text>
    );
  });
};
export const payoutType = [
  { id: 1, name: "Onchain Address", value: "onchain" },
  { id: 2, name: "LN Address", value: "lightning" },
];

export const feesType = [
  { id: 1, name: "High (Fastest Transaction)", value: "high" },
  { id: 2, name: "Medium (Faster Transaction)", value: "medium" },
  { id: 3, name: "Low (Slow Transaction)", value: "low" },
];

export const validateAllowedDecimal = (num, allowedDecimal) => {
  const convertedNumber = convertExponentialToDecimal(+num);
  const numToStr = convertedNumber?.toString();
  if (numToStr?.includes(".")) {
    const splitedValue = numToStr.split(".");
    return splitedValue[1].length <= allowedDecimal;
  }
  return true;
};

export const getListObj = (key, value, list) => {
  return _.find(list, { [key]: value });
};

export const getCustomFieldInfo = (customFieldsDetails, customFields) => {
  let customFieldsInfo = [];
  if (customFieldsDetails.length === customFields?.length) {
    const result = customFieldsDetails.map((item) => {
      const isAvailable = customFields?.find(
        (child) =>
          child.id === item.id && item.type === child.type && child.value
      );
      return {
        ...item,
        value: isAvailable?.value || "",
      };
    });
    customFieldsInfo = result;
  } else customFieldsInfo = customFieldsDetails;
  return customFieldsInfo;
};

export const reformCFArr = (arr) => {
  return arr?.map((item) => {
    const { label, type, is_optional, options } = item;
    let params = { label, type, is_optional };
    if (type === "dropdown") {
      const optionsArr = options.map((option) => option.option);
      params["options"] = optionsArr;
    }
    return params;
  });
};

export const payoutSchedulingCurrency = [
  { id: "1", name: "BTC", value: "BTC" },
  { id: "2", name: "SATS", value: "SATS" },
];

export const uploadSection = ({
  id,
  uploadType,
  isLoading,
  setLoader,
  labelText,
  values,
  onChangeFormField,
}) => {
  const handleEnterKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.currentTarget.click();
    }
  };

  const responseImage = (type) => {
    onChangeFormField(uploadType, {
      original: type.file_name,
      small: type.file_name,
      thumb: type.file_name,
      large: type.file_name,
    });
    uploadType === "logos" && onChangeFormField("use_logo_or_icon", "logo");
    setLoader(false);
  };

  const handleUploadTempImage = async (file, fileName) => {
    try {
      const apiRes = await callAPIInterface(
        "POST",
        "/upload-image",
        null,
        file,
        fileName
      );
      responseImage(apiRes);
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <Box className="upload-brand-details">
      <Box
        className={classNames(
          "box-icon",
          uploadType === "logos" && "upload-logo"
        )}
      >
        <Box
          sx={{
            display:
              values[uploadType]?.original || isLoading ? "block" : "none",
          }}
        >
          <Box className="branding-image">
            <CustomAvatar
              className="brand-icon-avatar"
              isLoading={isLoading}
              src={values[uploadType]?.original}
            />
            {values[uploadType]?.original && (
              <Box
                tabIndex={0}
                onKeyDown={handleEnterKeyPress}
                title="Remove"
                className="remove-icon"
                onClick={() => {
                  onChangeFormField(uploadType, null);
                  uploadType === "logos" &&
                    onChangeFormField("use_logo_or_icon", "icon");
                }}
              >
                <CloseIcon />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display:
              values[uploadType]?.original || isLoading ? "none" : "block",
          }}
        >
          <label htmlFor={id}>
            <UploadFile
              id={id}
              showLoader={(status) => setLoader(status)}
              imgDimension={40}
              apiFunc={handleUploadTempImage}
              showToast={showToast}
            />
            <Box
              className="upload-brand-icon"
              tabIndex={0}
              onKeyDown={handleEnterKeyPress}
            >
              <AddIcon />
            </Box>
          </label>
        </Box>
      </Box>
      <Text className="default-text" size={14}>
        {labelText}
      </Text>
    </Box>
  );
};

export const labelElement = (label) => {
  return (
    <Text className="default-text" size={14} sx={{ marginBottom: "7px" }}>
      {label}
    </Text>
  );
};

export const uploadColorSection = ({
  label,
  colorType,
  defaultThemeColor,
  currentBrandUIColors,
  setBrandUIColors,
  values,
  intervalValue,
  setIntervalValue,
  onChangeFormField,
  from = "",
  getSelectedColorCallback,
}) => {
  return (
    <Box className="upload-brand-details">
      {labelElement(label)}
      <Box sx={{ height: "30px", width: "120px" }}>
        <Box
          className={classNames(
            "branding-color-picker",
            values[colorType] !== defaultThemeColor && "reset-color"
          )}
        >
          <ColorPicker
            id={colorType}
            color={values[colorType]}
            getSelectedColor={(selectedColor, savedColor) => {
              setBrandUIColors({ selectedColor, savedColor });
              if (!intervalValue) {
                setIntervalValue(true);
                let interval = setInterval(() => {
                  const checkForColor =
                    currentBrandUIColors.current.savedColor !==
                    defaultThemeColor;
                  getSelectedColorCallback({
                    checkForColor,
                  });
                  onChangeFormField(
                    colorType,
                    currentBrandUIColors.current.selectedColor
                  );
                  clearInterval(interval);
                  setIntervalValue(false);
                }, 1000);
              }
            }}
          />
          <Box
            title="Reset to default"
            className="reset-icon"
            onClick={() => onChangeFormField(colorType, defaultThemeColor)}
          >
            <RotateLeftIcon />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const fontFamilySection = ({ values, fieldName, onChangeFormField }) => {
  return (
    <Box className="upload-brand-details">
      {labelElement(fontsLabel)}
      <CustomSelect
        className="select-branding-fonts"
        name={fieldName}
        value={values[fieldName]}
        MenuProps={{
          id: "branding-font-select",
        }}
        onChange={(e) => {
          onChangeFormField(fieldName, e.target.value);
        }}
      >
        {googleFontArray.map((option) => (
          <MenuItem key={option.fontFamily} value={option.fontFamily}>
            {option.label}
          </MenuItem>
        ))}
      </CustomSelect>
    </Box>
  );
};
export const accountSettingsRoutes = {
  [Wallet]: ["account-info", "close-wallet-account"],
  [Merchant]: ["account-info", "public-info", "close-account"],
};

export const walletAccountRoute = ["/my-assets", "/close-wallet-account"];

export const convertToSixDigitHex = (threeDigitHexColor) => {
  return threeDigitHexColor
    .split("")
    .map((digit) => {
      if (digit === "#") {
        return digit;
      }
      return digit + digit;
    })
    .join("");
};
export const getPaidPaymentMethod = (rowItem) => {
  if (rowItem?.status === "paid") {
    if (
      rowItem?.target_amount_paid_by ===
      rowItem?.payment_method_options?.lightning?.id
    ) {
      return lightningLabel;
    } else if (
      rowItem?.target_amount_paid_by ===
      rowItem?.payment_method_options?.on_chain?.id
    ) {
      return onChainLabel;
    } else if (
      rowItem?.target_amount_paid_by ===
      rowItem?.payment_method_options?.ethereum?.id
    ) {
      return ethereum;
    }
  }
  return "-";
};

export const getWithdrawPaymentMethod = (rowItem) => {
  if (rowItem?.withdraw_method === onchain) {
    return onChainLabel;
  } else if (
    rowItem?.withdraw_method === lightning ||
    rowItem?.withdraw_method === lnurl
  ) {
    return lightningLabel;
  } else if (rowItem?.withdraw_method === ethereum.toLowerCase()) {
    return ethereum;
  }

  return "-";
};

export const receivedOnPaymentDetails = (data) => {
  const methodType = getPaidPaymentMethod(data);
  const methodValue =
    methodType === lightningLabel
      ? data?.payment_method_options?.lightning?.payment_request
      : methodType === ethereum
      ? data?.payment_method_options?.ethereum?.address
      : data?.payment_method_options?.on_chain?.address;

  return { methodType, methodValue };
};

export const renderPaymentUnconfirmed = (rowItem) => {
  if (
    rowItem?.status === "paid" &&
    rowItem?.confirmations === 0 &&
    getPaidPaymentMethod(rowItem) === onChainLabel
  ) {
    return (
      <CustomTooltip
        arrow={true}
        placement={"top"}
        text={unconfirmedPaymentDescription}
        className="custom-tooltip payments-status-tooltip"
      >
        <Box sx={{ width: "fit-content", boxShadow: "none" }}>
          <Tag
            className="pointer-cursor"
            text={unconfirmed}
            variant="warning"
          />
        </Box>
      </CustomTooltip>
    );
  }
  return <></>;
};

export const closeAccountPaths = [
  "/settings/close-account",
  "/settings/close-wallet-account",
];

export const actionsArray = [
  {
    id: "copyQrCode",
    icon: <QrCodeIcon className="actions-icon" />,
    text: "Copy QR Code",
  },
  {
    id: "copyInvoice",
    icon: <ReceiptOutlinedIcon className="actions-icon" />,
    text: "Copy Invoice",
  },
  {
    id: "downloadQR",
    icon: <FileDownloadOutlinedIcon className="actions-icon" />,
    text: "Download QR",
  },
];

export const transactionTypeValues = {
  Payment: "Received",
  "Network Fees": "Network fee",
  Withdraw: "Sent",
  "Withdraw Reversal": "Refund",
  "Network Fees Reversal": "Network fee refund",
  Unconfirmed: "Payment",
  "Reward Redemption": "Reward redemption",
  Pending_Payment: "Receiving...",
  Pending_Withdraw: "Sending...",
};

export const clickableTxnType = [payment, withdraw];

export const settingsNotificationEmailFrequency = [
  {
    id: 1,
    value: "Daily",
  },
  {
    id: 2,
    value: "Weekly",
  },
  {
    id: 3,
    value: "Monthly",
  },
];

export const redirectToObjPath = (filterObj = {}, statusQueryParams = "") => {
  const baseUrl = history?.location?.pathname + statusQueryParams;
  if (Object.keys(filterObj)?.length > 0)
    history.push(
      `${baseUrl + (statusQueryParams ? "&" : "?")}${encodeURIComponent(
        JSON.stringify(filterObj)
      )}`
    );
  else history.push(baseUrl);
};

export const validatePaymentAddressUsername = (username) =>
  /^[a-z0-9\-_.]+$/.test(username);

export const createRedirectionAuthURL = (authType, state = {}) => {
  const redirectURL = new URL(
    authType === "google"
      ? process.env.REACT_APP_GOOGLE_SSO_URL
      : process.env.REACT_APP_APPLE_SSO_URL
  );
  if (Object.keys(state).length) {
    const rawStr = JSON.stringify(state);
    const encodedState = enc.Base64.stringify(enc.Utf8.parse(rawStr));
    redirectURL.searchParams.append("state", encodedState);
  }
  return redirectURL;
};

export const decodeBase64EncodedString = (encodedString) => {
  if (encodedString) {
    const decodedString = enc.Utf8.stringify(enc.Base64.parse(encodedString));
    return JSON.parse(decodedString);
  }
  return encodedString;
};

export const handleSSOLoginRedirection = (
  authMethod,
  inviteeAuthState,
  localhostSsoFe = false
) => {
  if (localhostSsoFe) {
    inviteeAuthState = {
      ...inviteeAuthState,
      redirectToLocalhostUrl: true,
    };
  }

  const redirectionURL = createRedirectionAuthURL(authMethod, inviteeAuthState);
  window.open(redirectionURL, "_self");
};

export const setShowCrucialActionModal = (user, showModal) => {
  if (user?.signup_method !== "email") {
    store.dispatch(setVerifyOTP(showModal));
  } else {
    store.dispatch(setConfirmAction(showModal));
  }
};

export const lightningFees = 3;
export const getStatusCode = (statusCode, responseStatus) => {
  if (
    responseStatus?.toLowerCase() === "success" ||
    serverErrorStatusCodes.includes(statusCode) ||
    errorStatusCodes.includes(statusCode)
  ) {
    return statusCode;
  } else {
    if (statusCode != 0) return statusCode;
    return "ERR";
  }
};

export const getWhAttemptDetails = (currentRow) => {
  let detailsTableData = [];
  if (Object.keys(currentRow)?.length > 0) {
    detailsTableData = [
      {
        header: status,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {renderWebhookStatus(
              currentRow?.response_status?.toLowerCase() == success
                ? succeeded.toLowerCase()
                : failed.toLowerCase()
            )}
          </Text>
        ),
      },
      {
        header: time,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {dateTimeFormatInApp(currentRow.timestamp)}
          </Text>
        ),
      },
      {
        header: httpStatusCode,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            <Tag
              text={getStatusCode(
                currentRow?.response_status_code,
                currentRow?.response_status
              )}
              variant={
                currentRow?.response_status?.toLowerCase() === success
                  ? "success-res"
                  : "error"
              }
            />
          </Text>
        ),
      },
    ];
  }
  return detailsTableData;
};
export const sourceWalletApp = "ChromeWallet";

export const payoutWalletCurrencies = [
  {
    name: "BTC (On-chain)",
    code: "BTC",
    value: "onchain",
  },
  {
    name: "LN address",
    code: "LN",
    value: "lightning",
  },
];

export const payoutWalletValidationSchema = yup.object({
  wallet_name: yup
    .string()
    .required("")
    .matches(isAlphaNumeric, alphaNumName)
    .trim(),
  wallet_address: yup
    .mixed()
    .required("")
    .test({
      name: "wallet_address",
      message: invalidWalletAddress,
      test: function (address) {
        return validateWalletAddress(
          address,
          this.parent.currency,
          store.getState()?.auth.liveMode,
          process.env.REACT_APP_ENVIRONMENT
        );
      },
    }),
});

export const addWalletButton = (handleSubmit, disableCheck) => {
  return (
    <Button
      label={addWallet}
      onClick={handleSubmit}
      disabled={disableCheck}
      type="submit"
    />
  );
};

export const addWalletSubmitFunc = ({
  values,
  setDisableAddWallet,
  fetchWallets,
  closeModal,
  successCallBack,
  additionalQuery = "",
}) => {
  const payoutWalletData = {
    address: values?.wallet_address,
    name: values?.wallet_name,
    type: values?.currency?.value,
  };
  setDisableAddWallet(true);
  callAPIInterface(
    "POST",
    `/payout-wallets${additionalQuery}`,
    JSON.stringify(payoutWalletData)
  )
    .then((result) => {
      setDisableAddWallet(false);
      result && fetchWallets?.();
      closeModal?.();
      successCallBack?.();
    })
    .catch((err) => {
      setDisableAddWallet(false);
      closeModal?.();
      if (redirectWhenEntityNotFound(err)) {
        const errorMessage = err?.response?.data?.errors?.[0]?.message;
        store.dispatch(
          showToast({
            isToastOpen: true,
            toastMessage: errorMessage,
            toastVariant: "error",
            toastAutoHideDuration: 7000,
          })
        );
      }
    });
};

export const PSValidationSchema = yup.object({
  payout_type: yup.string().required(""),
  wallet_address: yup.string().required(""),
  currency_type_balance: yup.string(),
  minimum_balance: yup
    .number()
    .min(0, minBalanceGreaterError)
    .max(500000000, minimumBalanceLessThanErr)
    .test({
      name: "minimum_balance",
      message: decimalLimitError,
      test: (minimum_balance) => validateAllowedDecimal(minimum_balance, 8),
    }),
  auto_payout_enabled: yup.string(),
});

export const PSOnchainValidationSchema = yup.object({
  payout_type: yup.string().required(""),
  wallet_address: yup.string().required(""),
  auto_payout_enabled: yup.string(),
  currency_type_payout: yup.string(),
  currency_type_balance: yup.string(),
  // fees_type: yup.string().required(""),
  min_payout_amount: yup
    .number()
    .min(50000, minimumPayoutAmountGreaterErrorMsg)
    .max(500000000, minimumPayoutAmountLessThanErr)
    .required(emptyKey("minimum payout amount"))
    .test({
      name: "min_payout_amount",
      message: decimalLimitError,
      test: (min_payout_amount) => validateAllowedDecimal(min_payout_amount, 8),
    }),
  minimum_balance: yup
    .number()
    .min(0, minBalanceGreaterError)
    .max(500000000, minimumBalanceLessThanErr)
    .test({
      name: "minimum_balance",
      message: decimalLimitError,
      test: (minimum_balance) => validateAllowedDecimal(minimum_balance, 8),
    }),
});

export const setupAutoPayoutSchedulingFunc = ({
  values,
  isDeactivating,
  scheduledPayoutData,
  setIsPayoutCreating,
  isEditing,
  setScheduledPayoutData,
  payoutScheduleId,
  parentCallback,
}) => {
  let payoutSchedulingData = {
    wallet_id: isDeactivating
      ? scheduledPayoutData?.wallet?.id
      : values.wallet_address,
    min_balance: isDeactivating
      ? scheduledPayoutData?.min_balance
      : values.minimum_balance || 0,
    type: isDeactivating
      ? scheduledPayoutData?.wallet?.type
      : values.payout_type,
    status: values.auto_payout_enabled || "active",
    min_payout_amount: isDeactivating
      ? scheduledPayoutData?.min_payout_amount
      : values.min_payout_amount,
  };

  if (values.payout_type === onchain || isDeactivating) {
    const onchainFields = {
      fees_preference: isDeactivating
        ? scheduledPayoutData?.fees_preference
        : values.fees_type,
    };

    payoutSchedulingData = { ...payoutSchedulingData, ...onchainFields };
  }

  setIsPayoutCreating(true);
  callAPIInterface(
    "POST",
    isEditing || isDeactivating
      ? "/payouts/scheduling/" + payoutScheduleId
      : "/payouts/scheduling",
    payoutSchedulingData
  )
    .then((res) => {
      if (res) {
        setScheduledPayoutData(res);
        store.dispatch(setPayoutSchedulingEnabled(true));
      }
      setIsPayoutCreating(false);
      parentCallback?.();
    })
    .catch((e) => {
      setIsPayoutCreating(false);
    });
};

export const getScheduledPayout = (props) => {
  const { setIsLoading, setScheduledPayoutData, setAutoPayoutScheduling } = {
    ...props,
  };
  setIsLoading?.(true);
  callAPIInterface("GET", "/payouts/scheduling")
    .then((res) => {
      if (res) {
        setScheduledPayoutData?.(res);
        store.dispatch(setPayoutSchedulingEnabled(true));
      }
      setIsLoading?.(false);
    })
    .catch((err) => {
      store.dispatch(setPayoutSchedulingEnabled(false));
      setAutoPayoutScheduling?.(true);
      const errorType = err?.response?.data?.errors?.[0]?.type?.toLowerCase();
      if (errorType === "invalid_request_error") {
        store.dispatch(
          showToast({
            isToastOpen: false,
            toastVariant: "error",
          })
        );
      }
      setIsLoading?.(false);
    });
};

export const setPayoutSchDefaultVal = ({
  scheduledPayoutData,
  isEditing,
  setFieldValue,
  setSelectedPayout,
}) => {
  if (scheduledPayoutData?.id && isEditing) {
    setSelectedPayout(scheduledPayoutData?.wallet?.type);
    setFieldValue("auto_payout_enabled", scheduledPayoutData?.status);
    setFieldValue("payout_type", scheduledPayoutData?.wallet?.type);
    setFieldValue("fees_type", scheduledPayoutData?.fees_preference);
    setFieldValue("wallet_address", scheduledPayoutData?.wallet?.id);
    setFieldValue("min_payout_amount", scheduledPayoutData?.min_payout_amount);
    setFieldValue("minimum_balance", scheduledPayoutData?.min_balance);
  }
};

export const payoutLoaderComponent = () => {
  return (
    <Box
      borderBottom="none"
      className="custom-domain-wrapper payout-schedule-wrapper"
      padding="30px"
    >
      <Box mb="8px" display="flex" alignItems="center">
        <Skeleton
          animation="wave"
          width="300px"
          height="30px"
          sx={{ bgcolor: "#C4CCD2 !important" }}
        />
      </Box>
      <Box display="flex" flexDirection="row">
        <Skeleton
          animation="wave"
          width="400px"
          height="20px"
          sx={{ bgcolor: "#eaeef1 !important" }}
        />
      </Box>
    </Box>
  );
};

export const emailVerificationContent = (isShowMessage) => {
  return (
    <Grid alignItems="center" display="flex" flexDirection="column">
      <CheckCircleIcon
        style={{ color: "#58c93f", height: "62px", width: "62px" }}
      />
      <Text
        variant="h5"
        font="semibold"
        size={16}
        className="default-text margin-top14"
      >
        {isShowMessage ? verificationSuccessful : emailSent}
      </Text>
      <Text
        align="center"
        variant="subtitle1"
        font="regular"
        size={14}
        className="grey-text margin-top14 margin-bottom12"
      >
        {isShowMessage ? verificationSuccessfulMsg : emailVerifictionLinkSent}
      </Text>
    </Grid>
  );
};
export const confirmationModalContent = (
  <Text className="grey-text" size={16} font="regular" variant="subtitle1">
    {removePayoutMsg}
  </Text>
);

export const confirmationModalFooter = (
  setOpenConfirmModal,
  handleRemovePayoutWallet
) => {
  return (
    <Box display="flex">
      <Button
        label={cancel}
        variant="outlined"
        color="primary"
        onClick={() => {
          setOpenConfirmModal(false);
        }}
      />
      <Button
        color={"error"}
        style={{ width: "100px", marginLeft: "16px" }}
        label={confirmBtn}
        onClick={() => {
          handleRemovePayoutWallet();
        }}
        disabled={false}
      />
    </Box>
  );
};

export const linkNotFoundContent = (isAlreadyVerified) => {
  return (
    <Grid alignItems="center" display="flex" flexDirection="column">
      <img
        src={linkNotFoundImg}
        alt="linkNotFound"
        style={{ height: "52px", width: "60px" }}
      />
      <Text
        variant="h5"
        font="semibold"
        size={16}
        className="default-text margin-top14"
      >
        {isAlreadyVerified ? alreadyVerified : linkNotFoundTitle}
      </Text>
      <Text
        align="center"
        variant="subtitle1"
        font="regular"
        size={14}
        className="grey-text margin-top14 margin-bottom12"
      >
        {isAlreadyVerified ? payoutWalletAlreadyVerified : linkNotFoundDesc}
      </Text>
    </Grid>
  );
};

export const getAccNameFromId = (
  accountId,
  onlyName = false,
  userList = store.getState()?.auth?.user?.accounts_details
) => {
  const filterAssociatedAccName = userList?.filter(
    (accountData) => accountData?.account?.id === accountId
  )?.[0]?.account?.name;

  let accountName = accountId;
  if (filterAssociatedAccName) {
    accountName = onlyName
      ? filterAssociatedAccName
      : `${filterAssociatedAccName} (${accountId})`;
  }
  return accountName;
};

export const getTimeZoneAndDateFormat = () => {
  const userGlobalState = store.getState()?.auth?.user;
  const dateFormat = userGlobalState?.date_format;
  const timezone =
    userGlobalState?.timezone ||
    userGlobalState?.accounts_details?.[0]?.account?.timezone;
  return { dateFormat, timezone };
};

export const dateTimeFormatInApp = (timestamp) => {
  const { dateFormat, timezone } = getTimeZoneAndDateFormat();
  return commonDateFormatLogic(timestamp, dateFormat, timezone);
};

export const dateFromTimestampInApp = (
  timestamp,
  customDateFormat = null,
  customTimeZone = null
) => {
  const { dateFormat, timezone } = getTimeZoneAndDateFormat();
  const passingDateFormat = customDateFormat || dateFormat;
  const passingTimezone = customTimeZone || timezone;
  return getTimezoneMomentObj(timestamp, passingTimezone).format(
    passingDateFormat ? passingDateFormat.toUpperCase() : defaultDateFormat
  );
};
export const payoutVerticalLine = (sx = {}) => (
  <CustomDivider
    flexItem
    orientation="vertical"
    sx={{ ...sx, height: "22px" }}
  />
);

export const commonCustomDivider = (padding) => (
  <CustomDivider
    sx={{
      width: "599px",
      paddingTop: padding,
      color: "#E4E9EE",
    }}
  />
);

export const makeBoldString = (data, count) => {
  const address = data?.slice(0, count) + "..." + data?.slice(-count);
  const boldStart = address?.substring(0, 4);
  const boldEnd = address?.substring(address.length - 4);
  const middle = address?.substring(4, address.length - 4);
  return (
    <>
      <span style={{ color: "#0A193E", fontWeight: "600" }}>{boldStart}</span>
      <span style={{ color: "#848B9E" }}>{middle}</span>
      <span style={{ color: "#0A193E", fontWeight: "600" }}>{boldEnd}</span>
    </>
  );
};

export const displayTaprootCurrency = (amount, currency, amountInBtc) => {
  const satsCheck = amount > 1 ? sats : "SAT";
  if (currency === sats) {
    return amountInBtc ? btc : satsCheck;
  } else {
    return currency;
  }
};

export const oneClickIntegrationData = () => {
  const cookies = new Cookies();
  return cookies.get("oneclick-authorize-data");
};

export const isOneClickIntegrationAvailable = () =>
  oneClickIntegrationData() &&
  Object.keys(oneClickIntegrationData())?.length > 0;

export const oneClickCommonModalBody = (label) => (
  <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
    <WarningIcon sx={{ color: "#E74C3C", height: 60, width: 60 }} />
    <Text
      align="center"
      className="default-text"
      font="regular"
      size={18}
      variant="body1"
    >
      {label}
    </Text>
  </Box>
);

export const availableBalanceTextComponent = ({
  preText,
  balance,
  loading,
  calculatedSATS = null,
  sx = {},
}) => (
  <Text
    sx={{ display: "flex", gap: 0.5, ...sx }}
    className="grey-text"
    size={14}
    variant="subtitle1"
    font="regular"
  >
    {preText}
    <Text className="default-text" size={14} variant="caption">
      {loading ? (
        <Skeleton width={100} />
      ) : (
        <>
          {balance} {calculatedSATS && `( ${calculatedSATS} )`}
        </>
      )}
    </Text>
  </Text>
);

export const getPayoutBalanceAPI = () => {
  return new Promise((resolve, reject) => {
    callAPIInterface("GET", "/balances")
      .then((resData) => {
        if (resData) {
          const data = fetchRawBalanceData(resData);
          const satsBalanceObject = data?.find(
            (data) => data?.target_currency === "SATS"
          );
          const satsBalance = satsToBtcAmount(
            Math.floor(satsBalanceObject?.amount)
          );
          resolve(satsBalance);
        }
      })
      .catch((_error) => {
        reject(_error);
      });
  });
};

export const isValidAddress = (address) =>
  validAddress.test(address) || isValidLnUrlFormatRegex.test(address);

export const instantSendStatus = {
  paid: {
    label: "Paid",
    variant: "success",
    value: "paid",
  },
  unpaid: {
    label: "Unpaid",
    variant: "default",
    value: "unpaid",
  },
  failed: {
    label: "Failed",
    variant: "error-primary",
    value: "failed",
  },
};

export const ISWithdrawMethodsObj = {
  lightning_invoice: { label: "Lightning Invoice" },
  onchain_address: { label: "Onchain Invoice" },
  lightning_url: { label: "Lightning URL" },
  lightning_address: { label: "Lightning Address" },
  tapd_address: { label: "Tapd Address" },
};
export const allowedFourAfterDecimalRegex = /^\d+(\.\d{0,4})?$/;
export const allowedTwoAfterDecimalRegex = /^\d+(\.\d{0,2})?$/;

export const reSendWithdrawalLinkEmail = (id) => {
  return new Promise((resolve, reject) => {
    callAPIInterface("POST", `/withdrawal-links/${id}/resend-email`, {})
      .then((res) => {
        store.dispatch(
          showToast({
            isToastOpen: true,
            toastMessage: emailSentToastMsg,
            toastVariant: "success",
          })
        );
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const instantSendSupportedCurrency = ["SATS"]; // It may replaced by the dynamic currency in future; [USDT,SATS]
export const instantSendAllowedLnUrl = ["payRequest"];

export const verifyAddress = (
  res,
  liveMode,
  from = "",
  invalidAddress = ""
) => {
  const { network, expires_at = undefined } = res;
  const env = process.env.REACT_APP_ENVIRONMENT;
  const isCurrentEnvDev = env === "development";
  const isFromSend = from === "send_modal";

  // Check added for the env, network and mode
  const isDevWithMainnet = isCurrentEnvDev && network === "mainnet";
  if (
    isDevWithMainnet ||
    (!isCurrentEnvDev &&
      ((liveMode && network === "testnet") ||
        (!liveMode && network === "mainnet")))
  ) {
    const message = isDevWithMainnet ? testnetErrorMsg : mainnetErrorMSg;
    return {
      isAllowed: false,
      message: isFromSend ? invalidAddress : message,
      subMsg: isFromSend ? message : "",
    };
  }

  // Check if method is lightning; Check for given address is expired or not
  if (expires_at && checkCurrentAndExpireTime(expires_at)) {
    return {
      isAllowed: false,
      message: isFromSend ? invalidAddress : invoiceExpired,
      subMsg: isFromSend ? invoiceExpired : "",
    };
  }

  return {};
};

export const removeNonEngChar = /[^\w?:&=#.@\-+/]\s*/g;

const generateRegex = (arr) => {
  const params = arr
    .reduce((acc, cur) => [...acc, cur], []) // query field and negated query field
    .join("|");
  return new RegExp("^(" + params + ")[:|=]", "i");
};

// Helper function which is used to extract info from the withdraw request
export const handleSplitWithdrawRequest = (address) => {
  const withdrawRequest = address.replace(removeNonEngChar, "");
  const addresses = {};
  let extractedAddress, amount, note, url;

  try {
    url = new URL(withdrawRequest);
    extractedAddress = `${url?.pathname}${url?.search}`;
  } catch (err) {
    extractedAddress = withdrawRequest;
  }

  const arr = extractedAddress?.split(/[?#&]/g)?.filter(Boolean);
  arr?.forEach((elem) => {
    let addr = null;

    const lightningRegex = generateRegex([
      "lightning",
      "lnurl",
      "lnurlp",
      "lnurlw",
    ]);
    if (lightningRegex.test(elem)) {
      addr = elem.split(lightningRegex)?.pop();
      if (addr) addresses.lightning = addr;
      return;
    }

    const bitcoinRegex = generateRegex(["bitcoin"]);
    if (bitcoinRegex.test(elem)) {
      addr = elem.split(bitcoinRegex)?.pop();
      if (addr) addresses.bitcoin = addr;
      return;
    }

    const ethereumRegex = generateRegex(["ethereum", "address"]);
    if (ethereumRegex.test(elem)) {
      addr = elem.split(ethereumRegex)?.pop();
      if (addr) addresses.ethereum = addr;
      return;
    }

    const amountRegex = generateRegex(["amount", "uint256"]);
    if (amountRegex.test(elem)) {
      amount = elem.split(amountRegex)[2];
      return;
    }

    const noteRegex = generateRegex(["label", "note"]);
    if (noteRegex.test(elem)) {
      note = elem.split(noteRegex)[2];
      return;
    }

    if (!(elem?.includes("https") || elem?.includes("/transfer")))
      addresses.default = elem;
  });

  return {
    amount,
    note: note?.replaceAll("+", " ") || "",
    addresses,
  };
};

export const onlyWalletRoutes = [
  "/select-country",
  "/download",
  "/my-assets",
  "/assets",
  "/buy-bitcoin",
  "/wallet-transactions",
  "/success-page",
  "/under-maintenance",
];

export const getCurrencyForSatsAndBtc = (currency, isShowBTC) => {
  const curEth = currency === eth ? usdt : currency;
  const curSat = currency === sats && isShowBTC ? btc : currency;
  return currency !== sats ? curEth : curSat;
};

export const getShowAmountBtcToSats = (amount, isShowBtc, targetCurrency) => {
  if (!amount || amount === 0) {
    return 0;
  } else {
    return !isShowBtc
      ? removeTrailingZeros(amount, targetCurrency)
      : satsToBtcAmount(amount);
  }
};

export const BTCToFiatAmount = (amount, exchangeRate) => {
  const satsAmount = btcToSatsAmount(amount);
  const fiatAmount = satsAmount / exchangeRate;
  let tempConvertedAmount = fetchPrecisedInteger(fiatAmount, 2);
  tempConvertedAmount === "0" && (tempConvertedAmount = fiatAmount.toFixed(8));
  return tempConvertedAmount;
};

export const getAmountFormatted = (amount, currency, amountInBtc) => {
  return amountInBtc
    ? amount
    : getNumberFormatAccordingToCurrency(
        convertExponentialToDecimal(+amount?.toFixed(8)),
        currency
      )?.replace(regexToTruncateZeros, "$1");
};

export const displaySelectedCurrencyAmount = (amount, showBtcAmount) => {
  return showBtcAmount ? satsToBtcAmount(amount) : amount;
};

export const getCurrencyAmount = (amount, amountInBtc, currency = null) => {
  if (currency === sats) {
    return amountInBtc ? satsToBtcAmount(amount) : Math.floor(amount);
  } else if (currency === usdt) {
    return amount / 100;
  } else {
    return amount;
  }
};

export const generateAmountToPass = (amount, currency, showBtcAmount) => {
  let newAmount;
  switch (currency) {
    case sats:
      if (showBtcAmount) {
        newAmount = btcToSatsAmount(amount);
      } else {
        newAmount = Math.round(amount);
      }
      break;
    case usdt:
      newAmount = amount * 100;
      break;
    default:
      newAmount = amount;
  }
  return newAmount;
};

export const displayAmount = (
  amount,
  exchangeRate,
  targetedCurrency,
  currency,
  checkExchange,
  showBtcAmount
) => {
  let fiatAmount = "0";
  let convertedFiatAmount = 0;
  if (checkExchange) {
    if (showBtcAmount) {
      convertedFiatAmount = btcToSatsAmount(amount) / exchangeRate;
      fiatAmount = fetchPrecisedInteger(convertedFiatAmount, 2);
    } else {
      convertedFiatAmount = amount / exchangeRate;
      fiatAmount = fetchPrecisedInteger(convertedFiatAmount, 2);
    }

    if (fiatAmount === "0") {
      fiatAmount = convertedFiatAmount?.toFixed(currency === sats ? 8 : 2);
    }

    return getDisplayAmount(
      checkExchange,
      fiatAmount,
      targetedCurrency,
      showBtcAmount
    );
  } else {
    return getDisplayAmount(checkExchange, amount, currency, showBtcAmount);
  }
};

export const defaultPaymentMethods = {
  SATS: ["lightning", "onchain"],
  USDT: ["ethereum"],
};

export const getExchangeRate = (currency, targetCurrency) => {
  const data = {
    currency: currency,
    target_currency: targetCurrency,
  };
  return callAPIInterface("POST", "/utility/exchange-rate", data);
};

export const getAccountFees = async (method, currency, transactionCode) => {
  try {
    const params = `?payment_method=${method}&transaction_code=${transactionCode}&target_currency=${currency}`;
    const speedFees = await callAPIInterface("GET", `/account-fees` + params);
    return speedFees?.fee_percentage;
  } catch (err) {}
};

export const handleAmountChangeCommon = (
  enteredAmount,
  checkExchange,
  exchangeRate,
  currency,
  setAmount,
  setConvertedAmount,
  showBtcAmount
) => {
  const amount = showBtcAmount ? enteredAmount?.slice(0, 10) : enteredAmount;

  const toFixedAmount = currency === sats ? 8 : 2;

  if (showBtcAmount && amount?.toString()?.length > 0 && currency === sats) {
    const amt = amount;

    if (checkExchange) {
      const mainAmount = amt * exchangeRate;
      setConvertedAmount(amt);
      setAmount(satsToBtcAmount(mainAmount));
    } else {
      setAmount(convertExponentialToDecimal(amt));
      setConvertedAmount(BTCToFiatAmount(amt, exchangeRate));
    }
  } else if (amount?.toString().length <= 10) {
    const isNumeric = !isNaN(amount) && !isNaN(parseFloat(amount));
    const finalAmt =
      isNumeric && (checkExchange || currency !== sats)
        ? Math.abs(amount)
        : Math.abs(amount).toFixed(0);
    const amt = !isNumeric ? amount : finalAmt;

    if (checkExchange) {
      setConvertedAmount(amt);
      setAmount((amt * exchangeRate).toFixed(currency === sats ? 0 : 2));
    } else {
      setAmount(amt);
      let tempConvertedAmount = fetchPrecisedInteger(amt / exchangeRate, 2);
      if (tempConvertedAmount === "0") {
        tempConvertedAmount = (amt / exchangeRate).toFixed(toFixedAmount);
      }

      setConvertedAmount(tempConvertedAmount);
    }
  }
};
export const instantSendLimits = [
  {
    id: 1,
    amountInputName: "lightningLimit",
    currencyName: "lightningCurrency",
    label: "Per transaction limit for Lightning",
    infoText:
      "The amount you are entering is the maximum you can send instantly for each lightning transactions.",
  },
  {
    id: 2,
    amountInputName: "onchainLimit",
    currencyName: "onchainCurrency",
    label: "Per transaction limit for On-chain",
    infoText:
      "The amount you are entering is the maximum you can send instantly for each on-chain transactions.",
  },
  {
    id: 3,
    amountInputName: "transactionLimit",
    currencyName: "transactionCurrency",
    label: "Account's daily transaction limit",
    infoText:
      "The amount you are entering is the maximum amount, you can send instantly on daily basis.",
  },
];

export const minimumLightningUsdtNetFee = 0.01;
