import { Box } from "@mui/material";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import { Input } from "@speed/common/src/components/Input/Input";
import InputSelectionBox from "@speed/common/src/components/InputSelectionBox/InputSelectionBox";
import Label from "@speed/common/src/components/Label/Label";
import Text from "@speed/common/src/components/Text/Text";
import React from "react";
import {
  addDetailsToYourStore,
  apiVersionText,
  copyUrlForShopifyStoreMsg,
  eventField,
  format,
  json,
  orderCreation,
  selectEventCreation,
  selectJsonFromDropdown,
  subscribeWebhook,
  url,
  webhookApiVersion,
} from "../../messages";

const SubscribeWebhook = ({ webhookDetails }) => {
  return (
    <Box sx={{ mt: "50px" }} display="inline-flex">
      <Box className="grid-box-step">
        <Text style={{ color: "#2a67ff", textAlign: "center" }}>2</Text>
      </Box>
      <Box>
        <Text
          style={{ marginTop: "4px" }}
          className="default-text"
          size={20}
          variant="h2"
        >
          {subscribeWebhook}
        </Text>
        <Text
          className="grey-text margin-top15"
          size={20}
          variant="body1"
          font="regular"
        >
          {addDetailsToYourStore}
        </Text>
        <Box
          className="shopify-disabled-input"
          sx={{ mt: "50px", alignItems: "center" }}
          width={600}
        >
          <Box display="flex" justifyContent="space-between">
            <Label>{eventField}</Label>
          </Box>
          <Input
            name="eventField"
            showLabel={false}
            fullWidth
            type="text"
            value={orderCreation}
            disabled={true}
          />
          <Text
            sx={{ marginTop: "13px", display: "flex", gap: 0.5 }}
            className="grey-text"
            size={14}
            variant="body1"
            font="regular"
          >
            <span style={{ color: "#e74c3c" }}>*</span> {selectEventCreation}
          </Text>
        </Box>
        <Box width={600} className="margin-top30 shopify-disabled-input">
          <Box display="flex" justifyContent="space-between">
            <Label>{format}</Label>
          </Box>
          <Input
            name="format"
            showLabel={false}
            fullWidth
            type="text"
            value={json}
            disabled={true}
          />
          <Text
            sx={{ marginTop: "13px", display: "flex", gap: 0.5 }}
            className="grey-text"
            size={14}
            variant="body1"
            font="regular"
          >
            <span style={{ color: "#e74c3c" }}>*</span> {selectJsonFromDropdown}
          </Text>
        </Box>
        <Box width={630} className="margin-top30">
          <Box display="flex" justifyContent="space-between">
            <Label>{url}</Label>
          </Box>
          <Box
            className="shopify-disabled-input"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <InputSelectionBox
              copyText
              text={webhookDetails?.hookdeck_url}
              variant="square"
              className="custom-btc-address-text custom-input-copy-text"
            />
            <Clipboard
              style={{ marginLeft: "10px", cursor: "pointer" }}
              text={webhookDetails?.hookdeck_url}
            />
          </Box>
          <Text
            sx={{ marginTop: "13px", display: "flex", gap: 0.5 }}
            className="grey-text"
            size={14}
            variant="body1"
            font="regular"
          >
            <span style={{ color: "#e74c3c" }}>*</span>{" "}
            {copyUrlForShopifyStoreMsg}
          </Text>
        </Box>

        <Box width={600} className="margin-top30 shopify-disabled-input">
          <Box display="flex" justifyContent="space-between">
            <Label>{webhookApiVersion}</Label>
          </Box>
          <Input
            name="webhookApiVersion"
            showLabel={false}
            fullWidth
            type="text"
            value={apiVersionText}
            disabled={true}
          />
          <Text
            sx={{ marginTop: "13px", display: "flex", gap: 0.5 }}
            className="grey-text"
            size={14}
            variant="body1"
            font="regular"
          >
            <span style={{ color: "#e74c3c" }}>*</span>{" "}
            {copyUrlForShopifyStoreMsg}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default SubscribeWebhook;
